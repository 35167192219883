Object.defineProperty(exports, "__esModule", {
  value: true,
});


// Customizable Area Start
exports.ACCOUNT_TYPE_EMAIL = "EmailAccount";
exports.ACCOUNT_TYPE_SOCIAL = "SocialAccount";
exports.ACCOUNT_TYPE_PHONE = "SmsAccount";

exports.contentTypeApiUpdateUser = "application/json";
exports.apiEndPointUpdateUser = "profile/profile";
exports.apiUpdateUserType = "PUT";

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";

exports.contenttypeApiValidateMobileNo = "application/json";
exports.endPointApiValidateMobileNo = "profile/change_phone_validation";
exports.callTypeApiValidateMobileNo = "POST";

exports.endPointApiGetUserProfile = "profile/profile";
exports.contentTypeApiGetUserProfile = "application/json";
exports.methodTypeApiGetUserProfile = "GET";

exports.endPointApiUploadProfilePicture = "bx_block_profile/profiles/upload_profile_pic";
exports.contentTypeApiUploadProfilePicture = "multipart/form-data";
exports.methodTypeApiUploadProfilePicture = "POST";

exports.uploadImageApi = "bx_block_profile/profiles/upload_profile_pic";
exports.uploadImageApiContentType = "multipart/form-data";
exports.uploadImageApiMethodType = "POST";

exports.initialDetailsApi = "bx_block_categories/categories";
exports.initialDetailsApiContentType = "application/json";
exports.initialDetailsApiMethodType = "GET";

exports.personalProfileApi = "bx_block_profile/personal_profiles";
exports.personalProfileApiContentType = "application/json";
exports.personalProfileApiMethodType = "POST";

exports.businessProfileApi = "bx_block_profile/business_profiles";
exports.businessProfileApiContentType = "application/json";
exports.businessProfileApiMethodType = "POST";

exports.businessCatAPI = "bx_block_categories/sub_categories?category_name=";

exports.placeHolderEmail = "Email";
exports.labelHeader =
  "This is your profile, Here you can see and update your personal information.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelArea = "Area";
exports.labelMobile = "Mobile";
exports.labelEmail = "Email";
exports.labelCurrentPassword = "Current password";
exports.labelNewPassword = "New Password";
exports.labelRePassword = "Re-Type Password";
exports.btnTextCancelPasswordChange = "Cancel";
exports.btnTextSaveChanges = "Save Changes";
exports.btnTextChangePassword = "Change Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorMobileNoNotValid = "Phone number is not valid.";
exports.errorTitle = "Error";
exports.errorBothPasswordsNotSame = "Passwords must match.";
exports.errorCurrentNewPasswordMatch =
  "New password cannot match current password.";
exports.errorCurrentPasswordNotValid = "Current password not valid.";
exports.errorNewPasswordNotValid = "New password not valid.";
exports.errorReTypePasswordNotValid = "Re-type password not valid.";
exports.hintCountryCode = "Select Country";
exports.errorBlankField = "can't be blank";
exports.errorEmailNotValid = "Email not valid.";
//download Resume
exports.downloadResumeEndPoint = "bx_block_cvresumecandidatemanagement/resume";

exports.addProfileAPIEndPoint = "bx_block_profile/business_profiles";
exports.getCategoryAPIEndPoint = "bx_block_categories/categories";
exports.addPersonalProfileAPIEndPoint = "bx_block_profile/personal_profiles";
exports.addBusinessProfileAPIEndPoint = "bx_block_profile/business_profiles";


// get own profile API
exports.getProfileInfoContentType = "application/json";
exports.getProfileInfoEndPoint = "bx_block_profile/profiles";
exports.getProfileInfoMethod = "GET";

// get other business profile API
exports.getOtherBusinessProfileInfoContentType = "application/json";
exports.getOtherBusinessProfileInfoEndPoint = "account_block/accounts";
exports.getOtherBusinessProfileInfoMethod = "GET";

exports.followContentType = "application/json";
exports.followEndPoint = "bx_block_follow/follows";
exports.followMethodType = "POST";

exports.categoryContentType = "application/json";
exports.categoryEndPoint = "bx_block_categories/categories";
exports.categoryMethodType = "GET";
exports.getOtherBusinessProfileAccountPostsEndPoint = 'bx_block_posts/posts/other_user_posts'

exports.connectContentType = "application/json";
exports.connectEndPoint = "bx_block_request_management/requests";
exports.connectMethodType = "POST";

exports.addReviewContentType = "application/json";
exports.addReviewEndPoint = "bx_block_reviews/reviews";
exports.addReviewMethodType = "POST";


exports.ReviewContentType = "application/json";
exports.ReviewEndPoint = "bx_block_reviews/reviews";
exports.ReviewMethodType = "GET";

exports.getRequestApiContentType = "application/json";
exports.getRequestEndPoint = "bx_block_request_management/requests?status=pending";
exports.getRequestApiMethod = "GET";

exports.getNetworkRelation = "bx_block_request_management/requests/relations";
exports.getCancelConnectionRequest = "bx_block_request_management/requests/sent_requests"
// post blocked account
exports.blockedAccountPostApiContentType = "application/json";
exports.blockedAccountPostApiEndpoint = "account_block/accounts";
exports.blockedAccountPostApiMethod = "POST";

// report User
exports.reportPostApiContentType = "application/json";
exports.reportPostApiEndpoint = "account_block/accounts";
exports.reportPostApiMethod = "POST";

// report User
exports.removeConnectionApiContentType = "application/json";
exports.removeConnectionApiEndpoint = "bx_block_request_management/requests";
exports.removeConnectionApiMethod = "DELETE";
// Customizable Area End