import React, { Suspense } from "react";
import PostFeedController, { Props } from "./PostFeedController.web";
import {
  connectionsIcon,
  followersIcon,
  followingIcon,
  postsImgsIcon,
  ratingSentIcon,
  ratingsReceivedIcon,
} from "./assets";
import CommonListItem from "./commonListItem.web";
import "./userFeed.css";
import moment from 'moment';
import {
  Card,
  Row,
  Col,
  List,
  Input,
  Typography,
  Avatar,
  Button,
  Select,
  Form,
  Radio,
  Space, Modal, Spin,
} from "antd";

import { Dimensions, PixelRatio } from "react-native";
import { Grid } from "@material-ui/core";

const CustomPost = React.lazy(() => import('./CustomPost.web'));

const { Title, Text } = Typography;
const { TextArea } = Input;

let screenWidth = Dimensions.get('window').width;
let screenHeight = Dimensions.get('window').height;
//Artboard Dimension 
let artBoardHeightOrg = 1024;
let artBoardWidthOrg = 1440;
//Re calculated Artboard Dimension 
let artBoardWidth = isSameRatio() ? artBoardWidthOrg : screenWidth;
let artBoardHeight = isSameRatio() ? artBoardHeightOrg : screenHeight;
// To check if Artboard and Device screen has same ratio 
function isSameRatio(): boolean {
  return artBoardWidthOrg / artBoardHeightOrg < 1 && screenWidth / screenHeight < 1
}

export default class PostFeedBlock extends PostFeedController {

  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start
  renderUSerProfile = () => {
    var startDate = moment("2018-10-10", "YYYY.MM.DD"), // $('[name="date-start"]').val() === "13.04.2016"
      endDate = moment("2020-11-12", "YYYY.MM.DD"); // $('[name="date-end"]').val() === "28.04.2016"

    var diff = startDate.diff(endDate);

    // console.log( 'date-->',moment(diff).format('E') );
    const { userProfile } = this.state
    return (
      <Card bordered={false}
        style={{ borderRadius: 4 }}
        bodyStyle={{
          textAlign: "center",
          paddingTop: "12px",
          paddingBottom: "0px",
        }}><Avatar src={userProfile?.attributes?.profile?.data?.attributes?.photo} size={60} style={{ backgroundColor: "grey" }} />
        <p>
          {" "}
          {/* <Text style={{ fontSize: 16, fontWeight: "bold" }}>{this.state.full_name ? this.state.full_name: "No name"}</Text> */}

          <Text style={{ fontSize: 16, fontWeight: "bold" }}>
            {userProfile?.attributes?.profile?.data?.attributes?.full_name}</Text>
        </p>
        <p>
          {/* {this.state.loginUser.role === "personal" &&
            userProfile &&
            userProfile.attributes &&
            userProfile.attributes.designation &&
            userProfile.attributes.designation.title ? (
              <>
              <Text >
                {userProfile.attributes.designation.title}</Text>
                  <Text style={{ color: "#757575" }}>  " at " </Text> 
                  <Text>{
                  userProfile.attributes.designation.company}
              </Text></>
          ) : null} */}
          {/* {userProfile?.attributes?.profile?.data?.attributes?.role === "personal" && userProfile?.attributes?.profile?.data?.attributes?.designation?.title ? (
              <>
              <Text>
                {userProfile?.attributes?.profile?.data?.attributes?.designation?.title}</Text>
                  <Text style={{ color: "#757575" }}>  " at " </Text> 
                  <Text>{userProfile?.attributes?.profile?.data?.attributes?.designation?.company}
              </Text>
            </>
            ) : null} */}
          {this.state.loginUser.role &&
            this.state.loginUser.role !== "personal" &&
            userProfile &&
            userProfile.attributes &&
            userProfile.attributes.business ? (
            <Text >
              {userProfile.attributes.business +
                ", " +
                userProfile.attributes.business_industry}
            </Text>
          ) : null}
          {this.state.loginUser.role &&
            this.state.loginUser.role !== "personal" &&
            userProfile?.attributes?.profile?.data?.attributes?.business ? (
            <Text >
              {userProfile?.attributes?.profile?.data?.attributes?.business +
                ", " +
                userProfile?.attributes?.profile?.data?.attributes?.business_industry}
            </Text>
          ) : null}
          {userProfile?.attributes?.profile?.data?.attributes ? (
            <Text style={{ color: "#757575" }}>
              {/* {JSON.stringify(userProfile?.attributes?.profile?.data?.attributes?.domain_skills)} */}
              {this.getDomainSkills(userProfile?.attributes?.profile?.data?.attributes?.domain_skills)}
            </Text>
          ) : null}
          <br />
          {this.state.loginUser.role &&
            this.state.loginUser.role !== "personal" &&
            userProfile &&
            userProfile.attributes &&
            userProfile.attributes.location ? (
            <Text style={{ color: "#757575" }}>
              {userProfile.attributes.location}
            </Text>
          ) : null}
          {userProfile?.attributes?.profile?.data?.attributes?.role !== "personal" &&
            userProfile?.attributes?.profile?.data?.attributes?.location ? (
            <Text style={{ color: "#757575" }}>
              {userProfile?.attributes?.profile?.data?.attributes?.location}
            </Text>
          ) : null}
        </p>

        <p>
          {" "}
          {userProfile?.attributes?.profile?.data?.attributes?.experience_years ? (
            <Text style={{ fontSize: 16 }}>{userProfile?.attributes?.profile?.data?.attributes?.experience_years}+ years of experience</Text>
          ) : null}
        </p>
      </Card>
    );
  };

  renderPostConnectionDetails = () => {
    const postData = [
      {
        key: "Posts",
        count: this.state.accountDetails?.attributes?.posts_count ? this.state.accountDetails?.attributes?.posts_count :
          this.state?.userProfile?.attributes?.posts_count ? this.state?.userProfile?.attributes?.posts_count : 0,
        image: postsImgsIcon,
        // description: "UI/Ux Designer Community",
      },
      {
        key: "Connections",
        count: this.state.accountDetails?.attributes?.friends_count ? this.state.accountDetails?.attributes?.friends_count : 0,
        image: connectionsIcon,
        // description: "UI/Ux Designer Community",
      },
      {
        key: "Followers",
        count: this.state.accountDetails?.attributes?.followers_count ? this.state.accountDetails?.attributes?.followers_count : 0,
        image: followersIcon,
        // description: "UI/Ux Designer Community",
      },
      {
        key: "Following",
        count: this.state.accountDetails?.attributes?.following_count ? this.state.accountDetails?.attributes?.following_count : 0,
        image: followingIcon,
        // description: "UI/Ux Designer Community",
      },
      {
        key: "Rating Received",
        count: this.state.accountDetails?.attributes?.ratings_received ? this.state.accountDetails?.attributes?.ratings_received : 0,
        image: ratingsReceivedIcon,
        // description: "UI/Ux Designer Community",
      },
      {
        key: "Rating Sent",
        count: this.state.accountDetails?.attributes?.ratings_given ? this.state.accountDetails?.attributes?.ratings_given : 0,
        image: ratingSentIcon,
        // description: "UI/Ux Designer Community",
      },

    ];
    return (
      <Card style={{ borderRadius: 4 }}>
        <List
          grid={{ column: 2 }}
          dataSource={postData}
          renderItem={(item) => (
            <List.Item style={{
              cursor: "pointer",
              // border:'1px solid red'
            }} onClick={() => this.handleFollowersAndFollowingOpen(item.key)}>
              <List.Item.Meta
                avatar={
                  <div className={"post-connection-content ant-row-middle"}>
                    <Avatar style={{ padding: "5px" }} src={item.image} size={40} />
                  </div>
                }
                title={<h4 className="post-connection-title">{item.key}</h4>}
                description={
                  <h2 className="post-connection-count">
                    <b>{this.state.loading ? <Spin /> : item.count}</b>
                  </h2>
                }
                style={{ alignItems: "center", backgroundColor: "rgb(221,230,235)", marginRight: "5px", borderRadius: "4px" }}
              />
            </List.Item>
          )}
        />
      </Card>
    );
  };

  showReportModal = () => {
    return (
      <Modal
        width={350}
        style={{ borderWidth: "0px", borderRadius: "4px", overflow: "hidden" }}
        centered
        // placement="bottomRight"
        title={
          <div style={{ width: "100%", cursor: "move", fontWeight: "bold", borderWidth: "0px" }}
            onFocus={() => { }}
            onBlur={() => { }}
          // end
          >
            Report Post
          </div>
        }
        visible={this.state.isReportIssueVisible}
        onCancel={this.handleCancel}
        footer={[
          <Button size="large" className="report-button" style={{ width: "100%" }} key="report"
            onClick={() => this.handleOk(this.state.value)}>
            Report
          </Button>
        ]}
      >
        <Radio.Group onChange={this.onChangeValue} value={this.state.value} buttonStyle="outline">
          <Space direction="vertical">
            <Radio style={{ color: "grey" }} value='Fraude or pyramid Marketing'>
              Fraude or pyramid Marketing
            </Radio>
            <Radio style={{ color: "grey" }} value='Promoting/Selling illegal or regulated goods'>
              Promoting/Selling illegal or regulated goods
            </Radio>
            <Radio style={{ color: "grey" }} value='Promotes Terrorism'>
              Promotes Terrorism
            </Radio>
            <Radio style={{ color: "grey" }} value='Discrimination, Harrassment or Bullying'>
              Discrimination, Harrassment or Bullying
            </Radio>
            <Radio style={{ color: "grey" }} value='Child Abuse'>
              Child Abuse
            </Radio>
          </Space>
        </Radio.Group>
      </Modal>
    )
  }

  showInvitationModal = () => {
    return (
      <Modal
        width={450}
        style={{ borderWidth: "0px", borderRadius: "4px", overflow: "hidden" }}
        centered
        // placement="bottomRight"
        title={
          <div style={{ width: "100%", cursor: "move", fontWeight: "bold", borderWidth: "0px", paddingRight: '24px' }}
            onFocus={() => { }}
            onBlur={() => { }}
          // end
          >
            {this.state?.invitationUserInfo?.name} wants to connect with you as a
            {/* {this.displayUserRelation(this.state?.relationValue)} */}
          </div>
        }
        visible={this.state.isShowInvitationModal}
        onCancel={() => this.setState({ isShowInvitationModal: false })}
        footer={[
          <div style={{ display: 'flex', justifyContent: 'space-between', margin: '0 10px' }}>
            <Button size="large" className="report-button" style={{ width: "45%" }} key="accept"
              onClick={() => this.handleInvitations(this.state?.relationValue, 'accepted',
                this.state.invitationUserInfo?.req_id, this.state?.invitationUserInfo?.cat_id)}>
              Accept Request
            </Button>
            <Button size="large" className="reject-button" style={{ width: "45%" }} key="reject"
              onClick={() => this.handleInvitations(this.state?.relationValue, 'rejected',
                this.state.invitationUserInfo?.req_id,
                this.state?.invitationUserInfo?.cat_id)}>
              Reject Request
            </Button>
          </div>
        ]}
      >
        <Row gutter={12}>
          <Col
            xs={24}
          >
            <p>Change relationship to</p>
            <Form.Item
              //  validateStatus={this.state?.relationValue ? 'success' : "error"}
              rules={[
                {
                  required: true,
                  message: "Required field"
                }
              ]}
              style={{ borderWidth: 1 }}
              name="relationValue"
            >
              <Select
                id={"select"}
                style={{
                  padding: "0 !important",
                  borderRadius: '15px'
                }}
                placeholder="Select Relationship"
                bordered={true}
                dropdownStyle={{
                  borderRadius: "15px",
                  border: "1px solid #7aacd6",
                  paddingTop: "0px",
                  paddingBottom: "0px"
                }}
                // allowClear
                // notFoundContent={
                //   this.state.loadInvitationData ? (
                //     <Spin size="small" />
                //   ) : (
                //     "No Data Found "
                //   )
                // }
                onChange={this.handleChange}
              >
                {this.state.loadInvitationData &&
                  this.state.loadInvitationData.map((item: any) => {
                    // console.log("item32232332====", item);
                    return (
                      <option key={item}
                        style={{
                          borderBottom: "1px solid #7aacd6",
                          padding: "10px 20px 10px 20px"
                        }}
                        value={item}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%"
                            // paddingTop: "0.7rem",
                          }}
                        >
                          <div className="capital_options">{item}</div>
                        </div>
                      </option>
                    );
                  })}
              </Select>
            </Form.Item>
            {/* </Form> */}
            {/* )} */}
          </Col>
        </Row>
      </Modal>
    )
  }

  showConnectionModal = () => {
    return (
      <Modal
        width={450}
        style={{ borderWidth: "0px", borderRadius: "4px", overflow: "hidden" }}
        bodyStyle={{ paddingTop: "5px" }}
        centered
        title={<div style={{ fontWeight: "bold", borderWidth: "0px", fontSize: "18px", cursor: "move", paddingRight: '24px' }}>
          Select Relationship with {this.state?.invitationUserInfo?.name}
        </div>}
        visible={this.state.isShowConnectionModal}
        onCancel={() => this.setState({ isShowConnectionModal: false })}
        footer={null}
      >
        <Grid container style={{ padding: "0px" }}>
          <Grid item xs={12}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Required field"
                }
              ]}
              style={{ borderWidth: 1, marginBottom: "0px" }}
              name="relationValue"
            >
              <Select
                id={"select"}
                style={{
                  padding: "0 !important",
                  borderRadius: '15px',
                  width: "100%"
                }}
                placeholder="Select Relationship"
                bordered={true}
                dropdownStyle={{
                  paddingTop: "0px",
                  paddingBottom: "0px",
                  width: "100%"
                }}
                onChange={this.handleChange}
              >
                {this.state.loadInvitationData &&
                  this.state.loadInvitationData.map((item: any) => {
                    return (
                      <option key={item.id}
                        style={{
                          borderBottom: "1px solid #7aacd6",
                          padding: "10px 20px 10px 20px"
                        }}
                        value={item.id}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%"
                          }}
                        >
                          <div className="capital_options">{item.name}</div>
                        </div>
                      </option>
                    );
                  })}
              </Select>
            </Form.Item>
          </Grid>
          <Grid item xs={12} style={{ paddingTop: "15px" }}>
            <Grid container>
              <Grid item xs={6}>
                <Button className="report-button btn-accept-req-modal" key="accept"
                  onClick={() => this.sendRequest(this.state?.relationValue, this.state.invitationUserInfo?.req_id)}>
                  Send Request
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  type="primary"
                  shape="round"
                  className="btn-reject-req-modal"
                  danger
                  key="reject"
                  onClick={() => this.setState({ isShowConnectionModal: false })}>
                  Cancel Request
                </Button>
              </Grid>
            </Grid>
          </Grid>

        </Grid>
      </Modal>
    )
  }

  renderPost = (item: any) => {

    return (
      // <>
      //   {JSON.stringify(this.state.followedItems)}
      <Suspense fallback={<Card style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
        <Spin />
      </Card>} >
        <CustomPost
          menu={{ isCommunity: false, isCommentloader: this.state.isCommentloader }}
          handlePostList={this.handlePostList}
          loginUserId={this.state?.loginUser?.id ? this.state?.loginUser?.id : this.state?.userProfile?.attributes?.profile?.data?.attributes?.account_id}
          data={item?.attributes}
          handleMenuClick={(e: any) => this.handleMenuClick(e, item.id)}
          isFollowed={this.state.followedItems.includes(item.attributes?.account?.data?.id)}
          toggleFollow={() => this.onClickFollow(item.attributes?.account?.data?.id, this.state.followedItems.includes(item.attributes?.account?.data?.id))}
          handleEditPostOpen={(id: any) => this.handleEditPostOpen(id)}
        />
      </Suspense>
      // </>
    );
  };


  //@ts-ignore
  onChange = (value) => {
    let data = this.state.createPostDescription + value

    this.setState({ createPostDescription: value });
  };

  // Customizable Area End

  render() {
    const { navigation } = this.props;

    return (
      <div style={{ backgroundColor: "#f6f6f6 !important" }}>
        {this.redirectUser()}
        <Row>
          <Col xs={24} sm={24}>
            <div className="customHome_container">
              <Col
                style={{ padding: "16px" }}>
                {this.renderUSerProfile()}
                {this.renderPostConnectionDetails()}
                {this.state.userProfile?.attributes?.profile?.data?.attributes?.description ? (<Card bodyStyle={{ textAlign: "center" }}
                  style={{ borderRadius: 4 }}
                >
                  <Card.Meta
                    description={this.state.userProfile?.attributes?.profile?.data?.attributes?.description ?
                      this.state.userProfile?.attributes?.profile?.data?.attributes?.description : null}
                  />
                  {this.state.userProfile?.attributes?.profile?.data?.attributes?.description?.length > 100 && (
                    <Button type="link">
                      <Text className="learn-more">{this.btnLearnMore}</Text>
                    </Button>
                  )}
                </Card>) : <></>}

                <CommonListItem
                  isLoading={this.state.loadcommunity}
                  data={this.state?.joinnedCommunities ? this.state?.joinnedCommunities?.slice(0, 4) : []}
                  onViewAll={this.communityForum}
                  label={this.labelCommunityForum}
                  isCommunityForum={true}
                  description={true}
                  count={true}
                  title={true}
                  onItemPress={(id: any) => this.redirectCommunity(id)}
                />
                <CommonListItem
                  isLoading={this.state.loadUpcoming}
                  data={this.state?.workAnniversaries ?
                    this.state?.workAnniversaries : []}
                  onViewAll={this.workAnniversaries}
                  label={this.labelWorkAnniversaries}
                  isFromWorkAnniversaries={true}
                  description={true}
                  count={true}
                  title={true}
                  onItemPress={(id: any) => this.onItemPress(id)}

                />
                <CommonListItem
                  isLoading={this.state.loadUpcoming}
                  data={this.state?.birthAnniversaries ?
                    this.state?.birthAnniversaries : []}
                  onViewAll={this.birthAnniversaries}
                  label={this.labelBirthAnniversaries}
                  isBirthAnniversaries={true}
                  description={true}
                  count={true}
                  title={true}
                  onItemPress={(id: any) => this.onItemPress(id)}

                />
              </Col>
              <Col
                style={{ paddingTop: "16px" }}>
                {this.state.isloader ? (<div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Spin />
                </div>) : (
                  <div >
                    {this.renderPost(this.state.getPostBYIdForHidePost)}
                  </div>

                )}
                {this.showReportModal()}
                {this.showInvitationModal()}
                {this.showConnectionModal()}
              </Col>
              <Col
                style={{ paddingRight: "16px", paddingLeft: "16px" }}>
                <CommonListItem
                  isLoading={this.state.loadpeopleKnow}
                  data={this.state?.peoplemayKnow?.data ? this.state?.peoplemayKnow?.data : []}
                  onReload={() => this.getPeopleYouknow()}
                  onViewAll={this.onViewAllInvite}
                  label={this.labelKnownPeople}
                  isFromKnownPeople={true}
                  onClickInvitation={this.handleConnectionModal}
                  onItemPress={(id: any) => this.onItemPress(id)}
                />
                <CommonListItem
                  isLoading={this.state.loadInvitation}
                  data={this.state?.allInvitations?.data ? this.state?.allInvitations.data : []}
                  // data={this.state.knownPeople}
                  onViewAll={this.onViewAllInvitations}
                  label={this.labelInvitations}
                  isInvitation={true}
                  onClickInvitation={this.handleInvitationModal}
                  onItemPress={(id: any) => this.onItemPress(id)}
                />
              </Col>
            </div>
          </Col>
        </Row>
      </div>

    );
  }
}
// Customizable Area Start
// Customizable Area End