import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { message } from "antd";
import React from "react"
import { getStorageData, isEmpty } from "../../../../framework/src/Utilities";
import axios from "axios";
const googleAPIkey = "AIzaSyCBH92YX2oCbI7PChqFNrgzw-PolHAiKZc";
import { RouterProps } from "react-router";
import { createRef } from "react";
// import {initPaymentSheet} from '@stripe/stripe-js';
import { loadStripe } from "@stripe/stripe-js";

export const configJSON = require("./../config");
// @ts-ignore
import { baseURL } from '../../../../framework/src/config.js';
import { Redirect } from "react-router-dom";
export interface Props extends RouterProps {
  navigation: any;
  id: string;
  location?: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isCreatePostEnable: boolean;
  isMenuVisible: boolean;
  isBusinessUser: boolean;
  createPostDescription: string;
  postList: any[];
  videoArrayList: any;
  isloader: boolean;
  token: string | null;
  isCreatePostVisible: boolean;
  fullAddress: string;
  createPostImages: any;
  loading: boolean;
  likedItems: number[];
  followedItems: number[];
  userProfile: any;
  loginUser: any;
  accountDetails: any
  isReportIssueVisible: boolean;
  reportPostID: any;
  selectedFile: any;
  value: any;
  mentionInputValue: string;
  GetConnectionArrayAcc: any;
  tagged_connection_ids: any;
  isEmojiPickerEnable: boolean;
  postComments: any;
  AllMedia: any
  knownPeople: any
  redirect: boolean;
  ratingSends: any;
  reviews: any;
  isEditReview: boolean;
  isEditRating: boolean;
  editedReviewId: any;
  editRatingId: any;
  editRatingData: any;
  goCheckout: any;
  isUpgraded: any;
  upgradedAccount: any;
  radioValue: any;
  paymentMethodData?: any;
  stripe?: any;
  cardHolderName: any;
  getSubscribealldata: any;
  getCustomerINFOdata: any;
  customerId: string;
  clientSecret: string;
  stripePromise: any;
  isLoading: any;
  succeeded: any;
  message: any;
  userRating: any;
  openUserRating: boolean;
  account_id: any;
  EditRatingLoader: boolean;
  ratingReceived: any;
  basicPlan: any;
  premiumPlan: any
  ReceivedRatingLoader: boolean
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class USerFeedController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  labelLogOut: string;
  labelProfile: string;
  labelSetting: string;
  labelApplied: string;
  labelHome: string;
  labelMyNetwork: string;
  labelCommunity: string;
  labelChat: string;
  labelAt: string;
  labelCommunityForum: string;
  labelWorkAnniversaries: string;
  labelBirthAnniversaries: string;
  labelKnownPeople: string;
  labelInvitations: string;
  placeHolderSearchContact: string;
  placeHolderWhatOnMind: string;
  btnLearnMore: string;

  isMenuVisible: string = "";

  SearchUserDataGetId: any;
  PostID: any;
  getHomeFeedPostsApiCallId: any;
  getPendingInvitationsApiCallId: any;
  getKnownPeopleApiCallId: any;
  getNetworkConnectionAppliedId: any;
  addCommentInPost: any;
  getCommentInPostApiCallID: any;
  unsubscribe: any;
  getRatingSendApiCallId: any;
  getReviewsApiCallId: any;
  EditReviewId: string = "";
  PayApiId: string = "";
  createStripeAPICallId: string = "";
  confrimStripeAPICallId: string = "";
  transactionSucceessAPICallId: string = "";
  getALLSubscribersAPICall: any;
  getCustomerINFOAPICall: any;
  getRatingRecivedApiCallId: any
  // Customizable Area End
  contentRef = createRef<any>();
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      isCreatePostEnable: false,
      isMenuVisible: false,
      isBusinessUser: false,
      createPostDescription: "",
      postList: [],
      videoArrayList: [],
      isloader: false,
      token: localStorage.getItem("token") ? localStorage.getItem("token") : "",
      isCreatePostVisible: false,
      fullAddress: "",
      createPostImages: [],
      loading: false,
      likedItems: [],
      followedItems: [],
      loginUser: {},
      // @ts-ignore
      userProfile: getStorageData("userProfile")
        ? getStorageData("userProfile")
        : {},
      isReportIssueVisible: false,
      reportPostID: "",
      selectedFile: null,
      value: 1,
      GetConnectionArrayAcc: [],
      mentionInputValue: "",
      tagged_connection_ids: [],
      isEmojiPickerEnable: false,
      postComments: [],
      AllMedia: [],
      accountDetails: {},
      knownPeople: [],
      redirect: false,
      ratingSends: [],
      ratingReceived: [],
      getSubscribealldata: [],
      getCustomerINFOdata: {},
      reviews: [],
      isEditReview: false,
      isEditRating: false,
      editedReviewId: null,
      editRatingId: null,
      editRatingData: null,
      userRating: null,
      openUserRating: false,
      goCheckout: true,
      isUpgraded: false,
      upgradedAccount: false,
      radioValue: null,
      cardHolderName: "",
      customerId: "",
      clientSecret: "",
      stripePromise: {},
      isLoading: false,
      succeeded: false,
      message: null,
      account_id: undefined,
      EditRatingLoader: false,
      ReceivedRatingLoader: false,
      basicPlan: {},
      premiumPlan: {}
    };
    this.btnLearnMore = configJSON.btnLearnMore;
    this.placeHolderWhatOnMind = configJSON.placeHolderWhatOnMind;
    this.placeHolderSearchContact = configJSON.placeHolderSearchContact;
    this.labelLogOut = configJSON.labelLogOut;
    this.labelProfile = configJSON.labelProfile;
    this.labelSetting = configJSON.labelSetting;
    this.labelApplied = configJSON.labelApplied;
    this.labelHome = configJSON.labelApplied;
    this.labelMyNetwork = configJSON.labelMyNetwork;
    this.labelCommunity = configJSON.labelCommunity;
    this.labelChat = configJSON.labelChat;
    this.labelAt = configJSON.labelAt;
    this.labelCommunityForum = configJSON.labelCommunityForum;
    this.labelWorkAnniversaries = configJSON.labelWorkAnniversaries;
    this.labelBirthAnniversaries = configJSON.labelBirthAnniversaries;
    this.labelKnownPeople = configJSON.labelKnownPeople;
    this.labelInvitations = configJSON.labelInvitations;

    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }
  async componentDidMount() {
    super.componentDidMount();
    let token = await localStorage.getItem("token");
    if (token && token !== null && token !== undefined) {
      this.setState({ redirect: false });
    } else {
      this.setState({ redirect: true });
    }
    this.getHomeFeedPosts();
    this.handleInvitations();
    this.getKnownPeople();
    // this.unsubscribe = this.props.navigation.addListener("focus", async () => {
    //   this.getHomeFeedPosts();
    // });
    let data: any = await getStorageData("loginUser").then((res) => {
      this.setState({
        loginUser: res,
        isBusinessUser: res.role === "business",
      });
      console.log("loginUser", res)
    });
    await getStorageData("userProfile").then((res) => {
      console.log("res-->", res);
      this.setState({ userProfile: res });
    });
    await getStorageData("accountData").then((res) => {
      console.log("accountDetails-->", res);
      this.setState({ accountDetails: res });
    });
    this.getNetworkConnectionApplied();
    this.getRatingSend();
    this.getRatingRecived();
    this.getReviews();
    this.getAllAcountSubscripition();
    this.getCustomerINFO()
  }

  redirectUser() {
    if (this.state.redirect) {
      return <Redirect to="/" />;
    }
  }

  editRatingDataChange = async (obj: any) => {
    const editRatingData = { ...this.state.editRatingData };
    editRatingData["array"][obj.index]["value"] = obj.value
    this.setState({ editRatingData: editRatingData });
  }

  userRatingChange = async (obj: any) => {
    const userRating = { ...this.state.userRating };
    userRating["array"][obj.index]["value"] = obj.value;
    console.log("userRating", userRating);
    // this.setState({ generalRating: generalRating});
  }

  async receive(from: String, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.getHomeFeedPostsApiCallId) {
          this.setState({
            postList: responseJson.data,
            isloader: false,
          });
        } else if (apiRequestCallId === this.PostID) {
          console.log("create posts: ", JSON.stringify(responseJson));
          this.setState({
            AllMedia: [], createPostImages: [], videoArrayList: [], createPostDescription: '', isCreatePostEnable: false, isCreatePostVisible: false,
            // postList: responseJson.data,
            isloader: false,
          });
        } else if (apiRequestCallId === this.addCommentInPost) {
          this.setState({
            isloader: false,
          });
          this.getHomeFeedPosts();
        } else if (apiRequestCallId === this.getCommentInPostApiCallID) {
          console.log("get comments: ", responseJson);
          let list = this.state.postList.map((value) =>
            value.id == responseJson.data[0]?.attributes?.post_id
              ? { ...value, comments: responseJson.data }
              : value
          );

          this.setState({
            isloader: false,
            postComments: responseJson.data,
            postList: list
          });
        } else if (apiRequestCallId === this.getPendingInvitationsApiCallId) {
          console.log("network request: ", JSON.stringify(responseJson));
          this.setState({
            // postList: responseJson.data,
            isloader: false,
          });
        } else if (apiRequestCallId === this.getKnownPeopleApiCallId) {
          console.log("konown people: ", JSON.stringify(responseJson));
          this.setState({
            knownPeople: responseJson.data,
            isloader: false,
          });
        } else if (apiRequestCallId === this.getNetworkConnectionAppliedId) {
          let receivedData = responseJson.data;
          let finalFriendsData: any = [];
          receivedData &&
            receivedData.forEach((item: any, index: any) => {
              if (item && item.attributes && item.attributes.full_name) {
                let finalItem = item;
                finalItem["name"] = item.attributes.full_name;
                finalFriendsData.push(finalItem);
              }
            });
          console.log("finalFriendsData - ", finalFriendsData);
          this.setState(
            {
              GetConnectionArrayAcc: finalFriendsData,
            },
            () => {
              console.log("responseJson.data rcvd = ", responseJson.data);
              if (
                this.state.GetConnectionArrayAcc &&
                this.state.GetConnectionArrayAcc.length > 0
              ) {
                // console.log("categories: ", this.state.GetConnectionArrayAcc);
                const connectedFriends = this.state.GetConnectionArrayAcc.map(
                  (item: any) => item.attributes.connected_as
                )
                  .filter((v: any, i: any, a: any) => a.indexOf(v) == i)
                  .filter((x: any) => x !== null)
                  .map((x: any) => {
                    return { value: x };
                  });
                console.log("connectedFriends: ", connectedFriends);
                // this.setState({
                //   categoryFilterArray: connectedFriends,
                // });
                // console.log("@@@ connectedFriends: ", connectedFriends);
              }
            }
          );
          console.log(
            JSON.stringify(
              "GetConnectionArray Applied" + this.state.GetConnectionArrayAcc
            )
          );

          // this.setState({GetCategoriesArray:responseJson.data})
        }
      } else {
        this.parseApiErrorResponse(responseJson);
      }
      // get ratingSend res
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.getRatingSendApiCallId) {
          this.setState({ ratingSends: responseJson.data, EditRatingLoader: false });
        }
      } else {
        this.parseApiErrorResponse(responseJson);
      } if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.getRatingRecivedApiCallId) {
          this.setState({ ratingReceived: responseJson.data, ReceivedRatingLoader: false });
        }
      } else {
        this.parseApiErrorResponse(responseJson);
      }
      // get reviews res
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.getReviewsApiCallId) {
          this.setState({ reviews: responseJson.data });
        }
      } else {
        this.parseApiErrorResponse(responseJson);
      }
      // edit reviews res
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.EditReviewId) {
          this.getReviews();
        }
      } else {
        this.parseApiErrorResponse(responseJson);
      }
      // 
      if (apiRequestCallId === this.createStripeAPICallId) {
        if (responseJson && responseJson.data) {
          this.setState({ loading: false });
          this.createStripePaymentSuccess(responseJson?.data, this.state.paymentMethodData, this.state.stripe)
          // @ts-ignore
          window.notify([{ type: "success", message: responseJson?.message || 'something went wrong!' }])
        }
        if (responseJson && responseJson.error) {
          this.setState({ loading: false });
          this.props.history.push("/transactionfailed");
          // @ts-ignore
          window.notify([{ type: "success", message: responseJson?.error?.message || 'something went wrong!' }])
        }
      }

      if (apiRequestCallId === this.transactionSucceessAPICallId) {
        if (responseJson && responseJson.data) {
          this.setState({ loading: false });
          // this.toggleIsOpen();
          // this.props.history.push({ pathname: '/' });
          // @ts-ignore
        }
        if (responseJson && responseJson.error) {
          this.setState({ loading: false });
          // this.props.history.push("/transactionfailed");
          // @ts-ignore
          window.notify([{ type: "danger", message: responseJson?.error?.message || "Something went wrong" }])
        }
      }


      // getallsubscribe response data
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.getALLSubscribersAPICall) {
          this.setState({
            getSubscribealldata: responseJson.data,
            basicPlan: responseJson.data.find((item: any) => item.attributes.subscription_type === "free"),
            premiumPlan: responseJson.data.find((item: any) => item.attributes.subscription_type === "premium")
          });
          console.log(this.state.getSubscribealldata, " getSubscribealldata")
        }
      } else {
        this.parseApiErrorResponse(responseJson);
      }

      //getCustomerinfo
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.getCustomerINFOAPICall) {
          if (!responseJson.errors) {
            localStorage.setItem("customer_info", JSON.stringify(responseJson));
            this.setState({
              getCustomerINFOdata: responseJson,
              customerId: responseJson.customer,
              clientSecret: responseJson.payment_intent,
              stripePromise: loadStripe('pk_live_51JiHt7FBrafFVJq5Ph4qkAOvA7yNOLQDFZiHl1Z1ovcMy3UAMl2Ar3Bu94pYDguogGaovWAhOR6UlyBWvVBzjMD500EalInuDR'),
              // stripePromise: loadStripe(responseJson.publishableKey),
            });
            this.getEphemeralKey(responseJson);

            console.log(this.state.getCustomerINFOdata, "getCustomerINFO")
          }
        }
      } else {
        this.parseApiErrorResponse(responseJson);
      }

      // payment responce
      // if (apiRequestCallId && responseJson) {
      //   if (apiRequestCallId === this.PayApiId) {
      //     if (responseJson && responseJson.data) {
      //       this.createStripePaymentSuccess(responseJson?.data,this.state.paymentMethodData,this.state.stripe)
      //       // @ts-ignore
      //       window.notify([{ type: "success", message: responseJson?.message || 'something went wrong!' }])
      //     }
      //     if (responseJson && responseJson.error) {
      //       // @ts-ignore
      //       window.notify([{ type: "success", message: responseJson?.error?.message || 'something went wrong!' }])
      //     }
      //   }
      // } else {
      //   this.parseApiErrorResponse(responseJson);
      // }
    } else if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let requesterId = message.getData(
        getName(MessageEnum.SessionRequestedBy)
      );
    }

    // Customizable Area End
  }

  // Customizable Area Start

  getDomainSkills(userDataHere: any) {
    if (
      isEmpty(userDataHere) ||
      isEmpty(userDataHere.attributes) ||
      isEmpty(userDataHere.attributes.domain_skills)
    )
      return "";
    const domainSkillsPresent = userDataHere.attributes.domain_skills;
    const domainSkillsStr = domainSkillsPresent.join(", ");
    console.log("domainSkillsStr = ", domainSkillsStr);
    return domainSkillsStr;
  }

  submitRating = async () => {

    const editRatingData = { ...this.state.editRatingData };
    const userRating = { ...this.state.userRating };
    const ratingObj = editRatingData?.array.reduce((prevObj: any, newObject: any) => {
      if (newObject.name) {
        return ({ ...prevObj, [newObject.name]: newObject.value || 0 });
      } else {
        return prevObj;
      }
    }, {});
    // console.log("ratingObj", ratingObj)

    const userRatingObj = userRating.array.reduce((prevObj: any, newObject: any) => {
      if (newObject.name) {
        return ({ ...prevObj, [newObject.name]: newObject.value || 0 });
      } else {
        return prevObj;
      }
    }, {});

    const httpBody = {
      data: {
        attributes: {
          account_id: this.state.account_id,
          rating: {
            [userRating.name]: userRatingObj,
            [editRatingData.name]: ratingObj
          }
        }
      }
    }
    try {
      const response = await axios({
        url: `${baseURL}/bx_block_accountscoreranking/ratings`,
        method: 'post',
        headers: {
          'token': this.state.token,
          'Content-Type': 'application/json'
        },
        data: JSON.stringify(httpBody)
      })
      if (response && response?.data) {
        // console.log(response.data, this.state.ratingSends)

        this.setState({

          isEditRating: false,
          editRatingData: null,
          userRating: null,
          editRatingId: null,
          openUserRating: false,
          EditRatingLoader: true,
        })
        this.getRatingSend()
        // this.GetOtherBusinessProfile();
      }
    } catch (error) {
      console.error(error);
    }

  }

  getHomeFeedPosts = async () => {
    this.setState({ isloader: true });
    // const token = await getStorageData("token");
    // token= localStorage.getItem("token") ? localStorage.getItem("token"):"",
    const header = {
      "Content-Type": configJSON.getPostsApiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getHomeFeedPostsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPostsApiEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getPostsApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getUserLocattion = async () => {
    await navigator.geolocation.getCurrentPosition(
      async (position) => {
        let googleMapApi = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.coords.latitude
          },${position.coords.longitude}&key=${googleAPIkey}`;
        let cityName = "",
          stateName = "",
          country = "",
          postalCode = "";
        let locationResult: any = await axios.get(googleMapApi);
        locationResult.data.results[0].address_components.forEach((rs: any) => {
          if (rs.types.includes("locality")) {
            cityName = rs.long_name;
          }

          if (rs.types.includes("administrative_area_level_1")) {
            stateName = rs.long_name;
          }

          if (rs.types.includes("country")) {
            country = rs.long_name;
          }

          if (rs.types.includes("postal_code")) {
            postalCode = rs.long_name;
          }
        });

        const fullAddress = locationResult.data.results[0].formatted_address;
        // console.log("fullAddress", JSON.stringify(fullAddress));
        this.setState({
          fullAddress: JSON.stringify(fullAddress),
        });
      },
      (err) => console.log(err)
    );
  };
  getBase64(img: any, callback: any) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }
  beforeUpload(file: any) {
    console.log("filetype", file);
    const isJpgOrPng =
      file.type.slice(0, 5) == "image" ||
      file.type.slice(0, 5) == "video";

    let isLt2M = false;
    if (file.type.slice(0, 5) == "image") {
      isLt2M = file.size / 1024 / 1024 < 5;
      if (!isLt2M) {
        message.error('Image must smaller than 5MB!');
      }
    } else if (file.type.slice(0, 5) == "video") {
      isLt2M = file.size / 1024 / 1024 < 10;
      if (!isLt2M) {
        message.error('Video must smaller than 10MB!');
      }
    }

    this.getBase64(file, (imageUrl: string) => {
      let ImageArrayList = [...this.state.createPostImages];
      let VideoArray = [...this.state.videoArrayList];
      let AllMedia = [...this.state.AllMedia]
      if (isLt2M && (file.type.slice(0, 5) == "video" || file.type.slice(0, 5) == "image")) {

        AllMedia.push({
          filePath: imageUrl,
          // filePath: "data:image/jpeg;base64," + info.file.originFileObj,
          uri: imageUrl,
          type: file.type,
          name: file.name,
          data: file,
        });
      }
      if (file.type.slice(0, 5) == "video" && isLt2M) {
        VideoArray.push({
          filePath: imageUrl,
          // filePath: "data:image/jpeg;base64," + info.file.originFileObj,
          uri: imageUrl,
          type: file.type,
          name: file.name,
          data: file,
        });
      } else if (file.type.slice(0, 5) == "image" && isLt2M) {
        ImageArrayList.push({
          filePath: imageUrl,
          // filePath: "data:image/jpeg;base64," + info.file.originFileObj,
          uri: imageUrl,
          type: file.type,
          name: file.name,
          data: file,
        });
      } else {
        message.error("invalid file type!!!");
      }

      this.setState({
        createPostImages: ImageArrayList,
        videoArrayList: VideoArray,
        AllMedia,
        loading: false,
      });

      //  this.state.ImageArrayList.push
      // this.setState({ImageArrayList:'data:image/jpeg;base64,' + response.data})
      console.log("arrays" + JSON.stringify(this.state.createPostImages));

      // this.setState({
      //   createPostImages: [...this.state.createPostImages, imageUrl],
      //   loading: false,
      // });
    });
    // if (!isJpgOrPng) {
    //   message.error("You can only upload JPG/PNG file!");
    // }
    // const isLt2M = file.size / 1024 / 1024 < 2;
    // if (!isLt2M) {
    //   message.error("Image must smaller than 2MB!");
    // }
    // return isJpgOrPng && isLt2M;

    return true;
  }
  handleImageChange = (info: any) => {
    console.log("info--===>", info.file.originFileObj);
    // if (info.file.status === "uploading") {
    //   this.setState({ loading: true });
    //   message.error("uploading");
    //   return;
    // }
    if (info.file.status === "done") {

    } else if (info.file.status === "error") {
      console.log("FILE INFO", info, info.file);
      // message.error(`${info.file.name} file upload failed.`);
    } else {
      // message.error("failed");
    }
  };
  onRemoveImage = (index: number) => {
    let data = this.state.AllMedia;
    data.splice(index, 1);
    this.setState({ AllMedia: data });
  };

  handleSubmit = async () => {
    // console.log("value",value)
    const header = {
      // "Content-Type": configJSON.createPostApiContentType,
      // "Content-Type": 'multipart/form-data; boundary=----WebKitFormBoundaryqTqJIxvkWFYqvP5s',
      token: this.state.token,
    };

    let data = {
      status: "pending",
    };
    console.log("header: ", header);
    console.log("description: ", this.state.createPostDescription);
    let trimmedStr = this.state.createPostDescription.trim();

    var form = new FormData();
    console.log("trim", typeof trimmedStr);

    await form.append("body", trimmedStr.toString());
    console.log("FormData2-->", form);

    // formdata.append("name", 'ABC');
    console.log("FormData3-->", form.get("body"));
    if (this.state.fullAddress && this.state.fullAddress !== "") {
      await form.append("location", this.state.fullAddress);
    }
    // if (this.state.tagged_connection_ids) {
    //   const uniqueTaggedIds = Array.from(
    //     new Set(this.state.tagged_connection_ids)
    //   );
    //   uniqueTaggedIds.forEach((taggedId: any) => {
    //     formdata.append("taggings_attributes[]account_id", taggedId);
    //   });
    // }

    if (this.state.createPostImages.length > 0) {
      this.state.createPostImages.forEach((item: any) => {
        form.append("media[]", item.data);
      });
    }
    if (this.state.videoArrayList.length > 0) {
      this.state.videoArrayList.forEach((item: any) => {
        form.append("media[]", item.data);
      });
    }

    // const httpBody = {
    //   data: data,
    // };
    var options = { content: form };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.PostID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postendpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      form
      // {"_parts": [["body", "Parth"]]}
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    console.log("Getconnection--->" + JSON.stringify(requestMessage));
    // return true;
  };
  likePost = async (id: number) => {
    this.setState({ isloader: true });
    const token = this.state.token;
    const header = {
      "Content-Type": configJSON.getPostsApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getPostsApiEndpoint}/${id}/like`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.likeDislikeApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  dislikePost = async (id: number) => {
    this.setState({ isloader: true });
    const token = await this.state.token;
    const header = {
      "Content-Type": configJSON.getPostsApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getPostsApiEndpoint}/${id}/dislike`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.likeDislikeApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  toggleLike = (id: number, liked: boolean) => {
    if (liked) {
      console.log("Unlike");
      this.setState({
        likedItems: this.state.likedItems.filter(function (el) {
          return el !== id;
        }),
      });
      this.dislikePost(id);
    } else {
      this.setState((prevState) => ({
        likedItems: [...prevState.likedItems, id],
      }));
      this.likePost(id);
    }
  };

  onClickFollow = async (id: number, isFollowed: boolean) => {
    // console.log(id,isFollowed,'follow fun')
    try {
      const response = await axios({
        url:
          `${baseURL}/bx_block_follow/follows?account_id=${id}`,
        method: "POST",
        headers: {
          token: await localStorage.getItem('token'),
          "Content-Type": "application/json"
        }
      });
      if (isFollowed) {
        this.setState({
          // postLike: false
          followedItems: this.state.followedItems.filter(function (el) {
            return el !== id;
          }),
        });
      } else {
        this.setState((prevState) => ({
          followedItems: [...prevState.followedItems, id],
        }));
      }
      // setFollow(!isFollow);
      // window.location.reload();
      console.log(response, "following post");
    } catch (error) {
      console.error(error, 'error while calling api follow REQUEST');
    }
  };

  addComment = (id: number, comment: any) => {
    console.log(id, comment);
    this.setState({ isloader: true });
    const token = this.state.token;
    const header = {
      "Content-Type": configJSON.getPostsApiContentType,
      token: token,
    };
    let body = {
      data: {
        attributes: {
          post_id: id,
          comment: comment,
          // "taggings_attributes": [
          //     {
          //         "account_id": 2
          //     }
          // ]
        },
      },
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addCommentInPost = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createComment}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  getComments = (id: number) => {
    console.log(id);
    this.setState({ isloader: true });
    const token = this.state.token;
    const header = {
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCommentInPostApiCallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createComment}?post_id=${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getPostsApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };
  toggleCommentLike = (id: number, liked: boolean) => {
    if (liked) {
      this.dislikedComment(id);
    } else {
      this.likedComment(id);
    }
  };
  likedComment = async (id: number) => {
    this.setState({ isloader: true });
    const token = await this.state.token;
    const header = {
      // "Content-Type": configJSON.getPostsApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createComment}/${id}/like`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.likeDislikeApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  dislikedComment = (id: number) => {
    this.setState({ isloader: true });
    const token = this.state.token;
    const header = {
      // "Content-Type": configJSON.getPostsApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createComment}/${id}/dislike`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.likeDislikeApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  handleInvitations = () => {
    const header = {
      "Content-Type": "application/json",
      token: this.state.token,
    };
    const json_data = {
      status: "pending",
      page: "1",
      per_page: "10",
    };
    console.log("json_data....");
    const httpBody = json_data;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getPendingInvitationsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getNetworkEndPoint + "?status=pending"
      // configJSON.getNetworkEndPoint+ "?status=pending"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      // JSON.stringify(header)
      header
    );
    // requestMessage.addData(
    //     getName(MessageEnum.RestAPIRequestBodyMessage),
    //     // JSON.stringify(httpBody)
    //     json_data
    // );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getNetworkApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    console.log(
      "Getconnection Invitations--->" + JSON.stringify(requestMessage)
    );
    return true;
  };

  getKnownPeople = () => {
    const header = {
      token: this.state.token,
    };

    console.log("getKnownPeople");
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getKnownPeopleApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getKnownPeopleEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getNetworkApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    console.log(
      "Getconnection Invitations--->" + JSON.stringify(requestMessage)
    );
  };

  handleMenuClick(e: any, id: any) {
    console.log("click", e);
    if (e.key === "save") {
      // setDropDownVisible(true);
      this.savePost(id, "save");
    }
    if (e.key === "hide") {
      this.savePost(id, "hide");
    }
    if (e.key === "report") {
      // setDropDownVisible(true);
      this.setState({ isReportIssueVisible: true, reportPostID: id });
    }
  }

  handlePostList = (data: any, status: string) => {
    const postData = this.state.postList;
    if (status === "update") {
      console.log(status, data);
      const updatePostList = postData?.map((item: any) => {
        if (item.id === data.id) {
          return data
        } else {
          return item
        }
      })
      this.setState({ postList: updatePostList });
    } else if (status === "delete") {
      const updatePostList = postData?.filter((item: any) => {
        return item.id != data?.id
      })
      this.setState({ postList: updatePostList });
    }
  }

  handleOk = async (e: any) => {
    console.log(e);
    await this.savePost(this.state.reportPostID, "report");
    this.setState({ isReportIssueVisible: false, reportPostID: "" });
    // setDropDownVisible(false);
  };

  handleCancel = (e: any) => {
    console.log(e);
    this.setState({ isReportIssueVisible: false, reportPostID: "" });
  };
  onChangeValue = (e: any) => {
    console.log("radio checked", e.target.value);
    this.setState({ value: e.target.value });
  };
  savePost = async (id: number, endString: string) => {
    this.setState({ isloader: true });
    const token = this.state.token;
    const header = {
      "Content-Type": configJSON.getPostsApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getPostsApiEndpoint}/${id}/${endString}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  getNetworkConnectionApplied = async () => {
    console.log(
      "Getting new friends list, token = ",
      await getStorageData("token")
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
      // token: 'eyJhbGciOiJIUzUxMiJ9.eyJpZCI6MzUxLCJleHAiOjE2MjIyODE3OTV9.VeU0sy4U9YiEJXyATrMzJWwFPlgaSm9KqwQVwoX7ZmLJ8SXW7oQQY3NgQ5oolFrKyhron8Bha9RZ0XCP7QNPnQ',
    };
    //   let formdata: any = new FormData();
    //   formdata.append("[token]", 'eyJhbGciOiJIUzUxMiJ9.eyJpZCI6NTI4LCJleHAiOjE2MjIxMjU0MDd9.GxLPNGdjH_WraIR5jIR4eURIPVsXogq2VKcYebGSvTfC407qLv2PKtDW6c9IuFXneT0lKsV_POuSQJepNErovA');
    let data = {
      status: "pending",
    };
    const httpBody = {
      data: data,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getNetworkConnectionAppliedId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getNetworkEndPoint + "?status=accepted"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    //   requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),httpBody);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getNetworkApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    console.log("Getconnection--->" + requestMessage);
    return true;
  };

  //get ratingRecived
  getRatingRecived = async () => {
    this.setState({ isloader: true, ReceivedRatingLoader: true });
    // const token = await getStorageData("token");
    // token= localStorage.getItem("token") ? localStorage.getItem("token"):"",
    const header = {
      "Content-Type": configJSON.getPostsApiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getRatingRecivedApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getRatingReceivedApiEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getPostsApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  // get ratingsend
  getRatingSend = async () => {
    this.setState({ isloader: true, EditRatingLoader: true });
    // const token = await getStorageData("token");
    // token= localStorage.getItem("token") ? localStorage.getItem("token"):"",
    const header = {
      "Content-Type": configJSON.getPostsApiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getRatingSendApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getRatingSendApiEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getPostsApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  // get reviews
  getReviews = async () => {
    this.setState({ isloader: true });
    // const token = await getStorageData("token");
    // token= localStorage.getItem("token") ? localStorage.getItem("token"):"",
    const header = {
      "Content-Type": configJSON.getPostsApiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getReviewsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getReviewsApiEndpoint
      // configJSON.getReviewsApiEndpoint+'?account_id=' + id
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getPostsApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  // edit review 
  onFinishAddReview = (values: any, id: number) => {
    this.setState({ isloader: true, isEditReview: false });
    const header = {
      "Content-Type": configJSON.addReviewContentType,
      token: this.state.token,
    };
    let body = {
      "data": {
        "attributes": {
          // "anonymous": values.anonymous,
          // "account_id": this.state.profileData?.profile?.data?.attributes?.account_id,
          "title": values.title,
          "description": values.description
        }
      }
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.EditReviewId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addReviewEndPoint + "/" + id
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.addReviewMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    console.log(body);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleClose = () => {
    this.setState({
      isEditRating: false,
      // editRatingData: null,
      editRatingId: null,
      openUserRating: true
    })
  }

  // toggleIsOpen = () => {
  //   this.setState(prevState => ({
  //     isUpgraded: !prevState.isUpgraded
  //   }));
  //   this.props.history.push({ pathname: '/Premium/Account' })
  // }

  redirectUserToplan = () => {
    console.log("oooooooo")
  }
  // callback method to handle the response
  handleResponse = (response?: any) => {
    // setLoading(false);
    if (response?.error) {
      console.log(response?.error?.message);
      return;
    }
    // props.setPaymentCompleted(response.success ? true : false);
  };

  stripePaymentMethodHandler = async (data: { result: any; amount: any; stripe: any; }) => {
    const { amount, result, stripe } = data;
    if (result.error) {
      // show error in payment form
      this.handleResponse(result);
    } else {
      const paymentResponse = await this.stripePayment({
        payment_method_id: result.paymentMethod.id,
        // name: result.paymentMethod.billing_details.name,
        // email: result.paymentMethod.billing_details.email,
        amount: amount,
        stripe: stripe
      });
      console.log(paymentResponse, "payyyy");
      this.handleResponse(paymentResponse);
    }
  }

  stripePayment = (data: { payment_method_id: any, amount: any, stripe: any }) => {
    console.log(data, "daaaaaa")
    const { amount, payment_method_id, stripe } = data;
    this.setState({
      paymentMethodData: payment_method_id,
      stripe: stripe
    });
    const header = {
      // "Content-Type": configJSON.APPLICATIONJSONContentType,
      token: this.state.token,
    };
    let d = { price_id: amount, subscription_id: payment_method_id }
    // let body = { amount, payment_method_id }
    let body = d
    // const requestMessage = new Message(
    //   getName(MessageEnum.RestAPIRequestMessage)
    // );
    // this.PayApiId = requestMessage.messageId;
    // requestMessage.addData(
    //   getName(MessageEnum.RestAPIResponceEndPointMessage),
    //   configJSON.payAndSubscribeEndPoint
    // );
    // requestMessage.addData(
    //   getName(MessageEnum.RestAPIRequestHeaderMessage),
    //   header
    // );
    // requestMessage.addData(
    //   getName(MessageEnum.RestAPIRequestMethodMessage),
    //   configJSON.addReviewMethodType
    // );
    // requestMessage.addData(
    //   getName(MessageEnum.RestAPIRequestBodyMessage),
    //   JSON.stringify(body)
    // );
    // console.log(body, "baaaaa");
    // runEngine.sendMessage(requestMessage.id, requestMessage);
    return this.handleCreateSTrip(body, header);
  }

  handleCreateSTrip = (data: any, headers: any) => {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.createStripeAPICallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.stripePaymentPPIEndPoint);


    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(data));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpPostType);

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  createStripePaymentSuccess = async (res: any, token: any, stripe: any) => {
    try {
      // @ts-ignore
      const result = await stripe.confirmPaymentIntent(res.client_secret, { payment_method: token })
      if (result?.paymentIntent?.status === 'succeeded') {
        // this.setState({ isFetching: true });
        let stripeData = {
          data: {
            stripe_payment_id: token,
            payment_intent_id: result?.paymentIntent?.id,
          }
        };
        this.confirmPaymentSuccess(stripeData);
      }
    } catch (e) {
      // console.log('@@@ Stripe Confirm Payment Error ============', e);
      // this.releaseBlockQuantity();
    }
  }

  confirmPaymentSuccess = (data: any) => {
    const token = this.state.token;
    const headers = {
      // 'Content-Type': 'application/json',
      token
    };
    return this.handleConfrimStripe(data, headers);
  }

  handleConfrimStripe = (data: any, headers: any) => {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.confrimStripeAPICallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.stripeConfirmPaymentAPIEndPoint);


    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(data));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypePut);

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getAllAcountSubscripition = () => {
    this.setState({ isloader: true });
    const header = {
      "Content-Type": configJSON.getPostsApiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getALLSubscribersAPICall = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllSubscribers
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAllSubscribersMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  //get customerinfo
  getCustomerINFO = () => {
    this.setState({ isloader: true });
    const header = {
      "Content-Type": configJSON.getPostsApiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCustomerINFOAPICall = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCustomerINFOEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getCustomerINFOMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  async getEphemeralKey(data: { customer: string | Blob }) {
    // const body = new URLSearchParams();
    // body.append("customer", this.state.customerId);
    const genCard = {
      customer: this.state.customerId
    }
    await axios({
      url: "https://api.stripe.com/v1/ephemeral_keys",
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
        "Stripe-Version": "2020-08-27",
        Authorization:
          "Bearer " +
          "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"
      },
      data: `customer=${this.state.customerId}`
    })
      .then(async (response) => {
        console.log(
          "dedededdeded",
          this.state.customerId,
          this.state.clientSecret
        );

        const billingDetails = {
          name: "",
          email: "",
          phone: "555-555-555",
          address: {
            city: "Faridabad",
            country: "IN",
            line1: "510 Townsend St.",
            line2: "123 Street",
            postalCode: "121001",
            state: "Haryana",
          },
        };
        // await initPaymentSheet({
        //   customerId: this.state.customerId,
        //   customerEphemeralKeySecret: response.data.secret,
        //   paymentIntentClientSecret: this.state.clientSecret,
        //   // allowsDelayedPaymentMethods: true,
        //   merchantDisplayName: "Anish Antony",
        //   // testEnv: true,
        //   // billingDetails: billingDetails
        // });
        this.setState({ loading: false });
      })
      .catch((error) => {
        console.log("error", error.response);
      });
  }

  transactionSucceess = () => {
    console.log("transactionSucceessAPICallId")
    const header = {
      "Content-Type": configJSON.MULTIPARTFormData,
      token: this.state.token,
    };
    let body = new FormData();
    body.append("transaction_result", "successful");

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.transactionSucceessAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.transactionSucceessEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.addReviewMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    )
    console.log(body);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // Customizable Area End
}
