import React, { Component } from "react";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import axios from "axios";
import { Redirect } from "react-router-dom";
import { message } from "antd"
// @ts-ignore
import { baseURL } from '../../../framework/src/config.js';
import { getStorageData } from "../../../framework/src/Helpers";
export const configJSON = require("./config");
import { RouterProps } from "react-router";

export interface Props extends RouterProps {
  // Customizable Area Start
  navigation: any;
  id: string;
  route?: any;
  location: any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string | null;
  isPrivate: boolean;
  isPostView: boolean
  isRatingVisible: boolean
  account_id: any;
  loginId: number;
  isModal: boolean;
  usertype: string;
  activeTab: any;
  isLoading: boolean;
  profileData: any;
  isAddReview: boolean;
  isAddAppliedViewRating: boolean;
  isAddRatingRateAsCustomer: boolean;
  category_id: number | null;
  ratingView: Array<any>;
  isRatingViewShow: boolean;
  ratingViewName: string;
  ratingViewName_2: {
    name: string,
    npsData: any
  };
  redirect: boolean;
  isReportIssueVisible: boolean;
  postsPerPage: number
  reviewData: Array<any>;
  status: string;
  subCategory: Array<any>
  isSelectCategoryModalVisible: boolean;
  reviewErrorData: string;
  alertReview: boolean;
  errorMessageOnOk: boolean;
  followUnfollowAlert: boolean;
  viewAllModal: boolean;
  viewAllTitle: String;
  viewAllBody: any;
  postsDataArray: any;
  isPostLoading: boolean
  activeTabForPost: string;
  sendRequestRelations: any;
  generalRating: any;
  userRateItems: any;
  isShowInvitationModal: boolean;
  loadInvitationData: any;
  blockAlertOpen: boolean;
  reportOpen: boolean;
  getMessage: string;
  nextPageLoader: boolean;
  isCompleteReview: boolean;
  changeRelationShipAccept: boolean;
  checkModalForAccept: boolean;
  downloadResumeLoading: boolean;
  suspendedAccount: boolean;
  suspendedAccountData: any;
  hideAnonymous: boolean;
  disableAnonymous: boolean;
  noOfPostData: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class OtherProfileController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  GetCategoriesId: string = "";
  getBusinessProfileAccountPosts: string = "";
  GetReviewsId: string = "";
  unsubscribe: any;
  playerRef: string = "";
  backHandler: any;
  GetProfielInformationId: string = "";
  GetCategorySelectionByNetwork: string = "";
  GetOtherBusinessProfileId: string = "";
  getCancelConnectionRequest: string = "";
  followId: string = "";
  connectId: string="";
  addReviewId: string="";
  getRequestStatusId: any;
  postBlockedAPIId: any;
  addReport: any;
  removeConnectionId: any;

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // this.GetCategories("");

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      // Customizable Area Start
      hideAnonymous: false,
      disableAnonymous: false,
      isPostView: false,
      account_id: "",
      loginId: 0,
      activeTab: 1,
      token: localStorage.getItem("token") ? localStorage.getItem("token") : "",
      isModal: false,
      usertype: "business",
      isLoading: true,
      profileData: [],
      isAddReview: false,
      isAddAppliedViewRating: false,
      isAddRatingRateAsCustomer: false,
      category_id: null,
      ratingView: [],
      isPrivate: false,
      isRatingViewShow: false,
      ratingViewName: "",
      ratingViewName_2: {
        name: "",
        npsData: []
      },
      redirect: false,
      isReportIssueVisible: false,
      reviewData: [],
      status: "",
      subCategory: [],
      isSelectCategoryModalVisible: false,
      reviewErrorData: "",
      alertReview: false,
      errorMessageOnOk: false,
      followUnfollowAlert: false,
      viewAllModal: false,
      viewAllTitle: "",
      viewAllBody: [],
      postsDataArray: [],
      postsPerPage: 0,
      isPostLoading: false,
      activeTabForPost: '1',
      sendRequestRelations: [],
      generalRating: { array: [] },
      userRateItems: { array: [] },
      isShowInvitationModal: false,
      loadInvitationData: [],
      isRatingVisible: true,
      blockAlertOpen: false,
      reportOpen: false,
      getMessage: "",
      nextPageLoader: false,
      isCompleteReview: false,
      changeRelationShipAccept: false,
      checkModalForAccept: false,
      downloadResumeLoading: false,
      suspendedAccount: false,
      suspendedAccountData: undefined,
      noOfPostData: true,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area End
  }

  redirectUser() {
    if (this.state.redirect) {
      return <Redirect to="/" />;
    }
  }

  handleRatingViewCancel = () => {
    this.setState({ isRatingViewShow: false });
  }

  handleViewAllCancel = () => {
    this.setState({ viewAllModal: false });
  }

  handleViewAllOpen = (title: string, body: any) => {
    this.setState({ viewAllModal: true, viewAllTitle: title, viewAllBody: body });
  }

  handleReportOpen = () => {
    this.setState({ reportOpen: true });
  }

  handleReportClose = () => {
    this.setState({ reportOpen: false });
  }

  handleBlockCancel = () => {
    this.setState({ blockAlertOpen: false });
  }

  handleBlockOpen = () => {
    this.setState({ blockAlertOpen: true });
  }


  handleFollowUnfollowCancel = () => {
    this.setState({ followUnfollowAlert: false });
  }

  handleFollowUnfollowOpen = () => {
    this.setState({ followUnfollowAlert: true });
  }

  handleRatingView = (data: any, name: string) => {
    this.setState({ ratingView: data, isRatingViewShow: true, ratingViewName: name })
  }

  handleRatingNPSChart = (name: string, value: any) => {
    if (this.state.ratingViewName_2.name === "") {
      this.setState({ ratingViewName_2: { ...this.state.ratingViewName_2, name: name, npsData: value } });
    }
  }

  handleNPSChartCancel = () => {
    this.setState({ ratingViewName_2: { ...this.state.ratingViewName_2, name: "", npsData: "" } });
  }

  handleOpenModal = () => {
    this.setState({ isModal: true });
  }

  handleCancel = () => {
    this.setState({ isModal: false });
  }

  handleAddReviewCancel = () => {
    this.setState({ isAddReview: false, disableAnonymous: false, hideAnonymous: false })
  }

  handleOpenAddReviewModal = () => {
    if (this.state.reviewData.length === 0) {
      this.setState({ isAddReview: true });
    } else {
      const checkReViewFiltered = this.state.reviewData?.filter((item) => item?.attributes?.reviewer_id === this.state.loginId)
      // const checkReviewerId = this.state.reviewData?.map((item) => {
      //   console.log(item?.attributes?.reviewer_id, "djkhaaaf", this.state.loginId, checkReViewFiltered)
      //   // if (item?.attributes?.reviewer_id === this.state.loginId) {
      //   //   return true;
      //   // }
      // })
      console.log(checkReViewFiltered, "sd;ldgaklgjal adskald", this.state.loginId, checkReViewFiltered.length, checkReViewFiltered[0]?.attributes?.anonymous)
      checkReViewFiltered?.length >= 2 ? this.setState({ alertReview: true }) :
        checkReViewFiltered &&
          checkReViewFiltered?.length === 0 ?
          this.setState({ isAddReview: true })
          :
          checkReViewFiltered[0]?.attributes?.anonymous ?
            this.setState({
              isAddReview: true,
              hideAnonymous: true
            }) :
            this.setState({
              isAddReview: true,
              disableAnonymous: true
            })

      // }
    }
  }

  handleAddAppliedViewRatingCancel = () => {
    this.setState({ isAddAppliedViewRating: false })
  }

  handleAddAppliedViewRatingOpen = () => {
    this.setState({ isAddAppliedViewRating: true })
  }

  handleSaveAndNext = () => {
    console.log("svae")
    this.setState({ isAddRatingRateAsCustomer: true })
    this.setState({ isAddAppliedViewRating: false })
  }

  handleAddRatingBack = () => {
    this.setState({ isAddRatingRateAsCustomer: false })
    this.setState({ isAddAppliedViewRating: true })
  }

  handleSelectChange = (value: any) => {
    this.setState({ category_id: value })
  }

  handleSelectCategoryCancel = () => {
    this.setState({ isSelectCategoryModalVisible: false });
  }

  onClickConnect = () => {
    this.handleNetworkRelation()
    // this.setState({ isSelectCategoryModalVisible: true });
  }

  handleAlertReviewOk = () => {
    this.setState({ alertReview: false, isCompleteReview: false });
  }

  handleAddRatingRateAsCustomerCancel = () => {
    this.setState({ isAddRatingRateAsCustomer: false })
  }

  handleNetworkRelation = () => {
    const header = {
      "Content-Type": configJSON.getOtherBusinessProfileInfoContentType,
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GetCategorySelectionByNetwork = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getNetworkRelation}?account_id=${this.state.account_id}&action_type=send`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getOtherBusinessProfileInfoMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleCancelConnectionRequest = () => {
    this.setState({ nextPageLoader: true })
    const header = {
      "Content-Type": configJSON.getOtherBusinessProfileInfoContentType,
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCancelConnectionRequest = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCancelConnectionRequest}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getOtherBusinessProfileInfoMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async componentDidMount() {
    let token = await localStorage.getItem("token");
    if (token && token !== null && token !== undefined) {
      this.setState({ redirect: false });
    } else {
      this.setState({ redirect: true });
    }
    const loginUser = await getStorageData("loginUser")
    this.setState({ isLoading: true });
    this.setState({
      account_id: this.props?.location?.state,
      loginId: loginUser?.id
    });
    this.getRelations("send");
    // this.GetOtherBusinessProfile();
    // this.getuserRateDetails();
  }

  async componentDidUpdate(prevProps: any, prevState: any) {
    if (this.state.account_id !== prevState.account_id) {
      this.GetOtherBusinessProfile();
    }
    if (this.state.profileData?.invited !== prevState?.profileData?.invited) {
      this.setState({ nextPageLoader: false })
    }
  }

  getBusinessPosts = async (account_id: number) => {
    // console.log("asdjandslg;", configJSON.getOtherBusinessProfileAccountPostsEndPoint)
    // debugger


    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")
    };

    this.setState({
      isPostLoading: true
    })

    var httpData = {
      'id': 1961
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getBusinessProfileAccountPosts = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getOtherBusinessProfileAccountPostsEndPoint + `?id=${account_id}&page=${this.state.postsPerPage + 1}&per_page=4`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      // 'POST'
      configJSON.ReviewMethodType
    );
    // console.log(requestMessage)
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  async receive(_from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    var responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    var errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      if (apiRequestCallId && responseJson && !responseJson?.errors) {
        if (apiRequestCallId == this.GetOtherBusinessProfileId) {
          this.getuserRateDetails({ data: responseJson });
          // console.log("businessProfile", responseJson.data)
          let isPrivate = responseJson?.data?.attributes?.profile_view?.includes("private_not_connected") ? true : false
          let isRatingVisible = responseJson?.data?.attributes?.profile_view?.includes("public_connected") ||
            responseJson?.data?.attributes?.profile_view?.includes("private_connected") ? false : true
          let isPostView = responseJson?.data?.attributes?.post_view
          this.setState({
            profileData: responseJson?.data?.attributes?.account?.data?.attributes,
            account_id: responseJson?.data?.attributes?.account?.data?.attributes?.account_id,
            isPrivate: isPrivate,
            isPostView: isPostView,
            isRatingVisible: isRatingVisible,
            activeTabForPost: isPostView ? "1" : "2"
          });
          if (responseJson?.data?.attributes?.status === "suspended") {
            this.setState({
              suspendedAccount: true,
              suspendedAccountData: responseJson?.data?.attributes
            })
          } else {
            this.getCategory();
            this.getReview();
          }
          this.setState({
            isLoading: false,
            activeTabForPost: isPostView ? "1" : "2"
          });
          if (!isPrivate) {
            this.getBusinessPosts(this.props?.location?.state)
          }
        } else if (apiRequestCallId == this.getBusinessProfileAccountPosts) {
          if (responseJson?.data) {
            if (responseJson?.data?.length < 4) {
              this.setState({ noOfPostData: false });
            } else {
              this.setState({ noOfPostData: true });
            }
            this.setState({
              postsDataArray: [...this.state.postsDataArray, ...responseJson.data],
              postsPerPage: this.state.postsPerPage + 1,
              isPostLoading: false
            })

          } else {
            this.setState({
              isPostLoading: false,
              noOfPostData: false
            })
          }
        } else if (apiRequestCallId == this.followId) {
          this.GetOtherBusinessProfile();
        }
        else if (apiRequestCallId == this.GetCategorySelectionByNetwork) {
          console.log(responseJson)
          if (responseJson) {
            this.setState({
              subCategory: responseJson.relations,
              isSelectCategoryModalVisible: true

            })
          }

        } else if (apiRequestCallId == this.getCancelConnectionRequest) {
          if (responseJson) {
            console.log(responseJson.data, "getCancelConnectionRequest")
            const RemoveRequestId = responseJson?.data?.find((item: any) => item.attributes.account_id === this.state.account_id)
            // console.log(RemoveRequestId,"askaj")
            this.handleRemoveConnection(RemoveRequestId.id)
          }
        }
        else if (apiRequestCallId == this.GetCategoriesId) {
          const filterData = responseJson?.data.filter((item: any) => {
            if (this.state.profileData?.profile?.data?.attributes?.role === "personal") {
              return item?.attributes?.name === "Personal Connection"
            } else if (this.state.profileData?.profile?.data?.attributes?.role === "business") {
              return item?.attributes?.name === "Business Connection"
            }
          })
          this.setState({ subCategory: filterData[0]?.attributes?.sub_categories });
        }
        else if (apiRequestCallId == this.connectId) {
          this.setState({
            category_id: null,
            errorMessageOnOk: false
          });
          this.GetOtherBusinessProfile();
        }
        else if (apiRequestCallId == this.GetReviewsId) {
          this.setState({ reviewData: responseJson?.data });
        }
        else if (apiRequestCallId === this.postBlockedAPIId) {
          this.setState({ getMessage: responseJson.message });
          this.GetOtherBusinessProfile();
        }
        else if (apiRequestCallId === this.addReport) {
          this.setState({ getMessage: responseJson?.message })
          this.GetOtherBusinessProfile();
        }
        else if (apiRequestCallId === this.removeConnectionId) {
          this.setState({ getMessage: responseJson?.message })
          this.GetOtherBusinessProfile();
        }
        else if (apiRequestCallId == this.addReviewId) {
          if (!responseJson?.error) {
            this.setState({ reportOpen: false })
            this.getReview();
          } else {
            this.handleReviewAlert()
          }
        }
        else {
          this.setState({
            isLoading: false
          })
        }
      }
    }

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let requesterId = message.getData(
        getName(MessageEnum.SessionRequestedBy)
      );
    }
    // Customizable Area End
  }

  GetOtherBusinessProfile = () => {
    const header = {
      "Content-Type": configJSON.getOtherBusinessProfileInfoContentType,
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GetOtherBusinessProfileId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getOtherBusinessProfileInfoEndPoint}/${this.state.account_id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getOtherBusinessProfileInfoMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // Customizable Area Start
  downloadResumeWithAxios = async () => {
    this.setState({
      downloadResumeLoading: true
    })
    const id = this.state?.profileData?.account_id
    const str = this.state?.profileData?.role === "business" ? `business?account_id=${id}` : `personal?account_id=${id}`

    try {
      var axios = require('axios');
      var config = {
        method: 'get',
        url: `${baseURL}/${configJSON.downloadResumeEndPoint}/${str}`,
        headers: {
          'token': this.state.token
        },
        responseType: "blob",
      };

      const response = await axios(config)
      console.log(response, "asdflkada")
      if (!response.error) {
        const file = new Blob([response.data], {
          type: "application/pdf",
        });
        const fileURL = URL.createObjectURL(file);
        this.download(fileURL, "Resume")
      }

    } catch (error) {

      this.setState({
        downloadResumeLoading: false
      })
      message.error("Something went wrong")
    }
  }

  download(dataurl: any, filename: any) {
    var link = document.createElement('a');
    link.href = dataurl;
    console.log(link)
    var fileName = filename;
    link.download = fileName;
    link.click();
    this.setState({
      downloadResumeLoading: false
    })
  }
  // Customizable Area End

  getRelations = async (action_type: any) => {
    try {
      const response = await axios({
        url: `${baseURL}/bx_block_request_management/requests/relations?account_id=${this.state.account_id}&action_type=${action_type}`,
        method: 'get',
        headers: {
          'token': this.state.token,
          'Content-Type': 'application/json'
        }
      })
      if (response && response?.data) {
        const reltionsArray = response?.data.relations.sort().map((item: any) => ({ name: item, id: item }))
        if (action_type == "accept") {
          this.setState({ loadInvitationData: reltionsArray });
        } else {
          this.setState({ sendRequestRelations: reltionsArray });
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  changeInviteRelationship = (val: any) => {
    const profileData = { ...this.state.profileData };
    const reqData = profileData.received_request;
    reqData["sender_relation"] = val;
    this.setState({ profileData: profileData, changeRelationShipAccept: true });
  }

  acceptRequest = async (dataObj: any) => {
    if (dataObj.type == "openAcceptModal") {
      this.getRelations("accept");
      this.setState({ isShowInvitationModal: true, loadInvitationData: [] });
    } else {
      const relationinDropdown = dataObj?.sender_relation;
      try {
        const response = await axios({
          url: `${baseURL}/bx_block_request_management/requests/${dataObj?.id}`,
          method: 'put',
          headers: {
            'token': this.state.token,
            'Content-Type': 'application/json'
          },
          data: {
            data: {
              "sender_relation": relationinDropdown,
              "status": dataObj.type
            }
          }

        })
        if (response && response?.data) {
          if (dataObj.type) {
            message.success('Request Successfully Accept')
          }
          this.setState({
            changeRelationShipAccept: false
          })
          this.GetOtherBusinessProfile();
        }
      } catch (error) {
        console.error(error);
      }
    }

  }

  getuserRateDetails = async (response: any) => {
    try {
      // const response = await axios({
      //   url: `${baseURL}/account_block/accounts/${this.state.account_id}`,
      //   method: 'get',
      //   headers: {
      //     'token': this.state.token,
      //     'Content-Type': 'application/json'
      //   }
      // })
      if (response && response?.data) {
        const rateName = response.data.data.attributes.account.data.attributes.rate_as;
        const ratingArray = response.data.data.attributes.rating.data.attributes.rating;
        let first_rating, second_rating = {};
        Object.entries(ratingArray).map((rateObj: any) => {
          if (rateObj[0] === "General Personal Rating") {
            const getFirstRatingItems = rateObj[1].map((item: any) => ({ value: item.score ? Number(item.score) : 0, ...item }));
            first_rating = { name: "General Personal Rating", array: getFirstRatingItems }
          }
          if (rateObj[0] === "General Business Rating") {
            const getFirstRatingItems = rateObj[1].map((item: any) => ({ value: item.score ? Number(item.score) : 0, ...item }));
            first_rating = { name: "General Business Rating", array: getFirstRatingItems }
          }
          if (rateObj[0] === rateName) {
            const getSecondRatingItems = rateObj[1].map((item: any) => ({ value: item.score ? Number(item.score) : 0, ...item }))
            second_rating = { name: rateName, array: getSecondRatingItems }
          }
          if (rateName === "Leadership") {
            if (rateObj[0] === "Leader") {
              const getSecondRatingItems = rateObj[1].map((item: any) => ({ value: item.score ? Number(item.score) : 0, ...item }))
              second_rating = { name: "Leader", array: getSecondRatingItems }
            }
          }
          if (rateName === "Community") {
            if (rateObj[0] === "Personal Community") {
              const getSecondRatingItems = rateObj[1].map((item: any) => ({ value: item.score ? Number(item.score) : 0, ...item }))
              second_rating = { name: "Personal Community", array: getSecondRatingItems }
            }
            if (rateObj[0] === "Business Community") {
              const getSecondRatingItems = rateObj[1].map((item: any) => ({ value: item.score ? Number(item.score) : 0, ...item }))
              second_rating = { name: "Business Community", array: getSecondRatingItems }
            }
          }
          if (rateName === "Customer") {
            if (rateObj[0] === "Business Customer") {
              const getSecondRatingItems = rateObj[1].map((item: any) => ({ value: item.score ? Number(item.score) : 0, ...item }))
              second_rating = { name: "Business Customer", array: getSecondRatingItems }
            }
            if (rateObj[0] === "Personal Customer") {
              const getSecondRatingItems = rateObj[1].map((item: any) => ({ value: item.score ? Number(item.score) : 0, ...item }))
              second_rating = { name: "Personal Customer", array: getSecondRatingItems }
            }
          }
        })
        this.setState({ generalRating: first_rating, userRateItems: second_rating });
        // console.log("accounts_rating", response, ratingArray, this.state.generalRating, this.state.userRateItems)
      }
    } catch (error) {
      console.error(error);
    }
  }

  generalRatingChange = async (obj: any) => {
    const generalRating = { ...this.state.generalRating };
    generalRating["array"][obj.index]["value"] = obj.value;
    // console.log("generalRating", generalRating);
    this.setState({ generalRating: generalRating });
  }

  userRatingChange = async (obj: any) => {
    const userRating = { ...this.state.userRateItems };
    userRating["array"][obj.index]["value"] = obj.value;
    console.log("userRating", userRating);
    // this.setState({ generalRating: generalRating});
  }

  handlePostList = (data: any, status: string) => {
    const postData = this.state.postsDataArray;
    if (status === "update") {
      console.log(status, data);
      const updatePostList = postData?.map((item: any) => {
        if (item.id === data.id) {
          return data
        } else {
          return item
        }
      })
      this.setState({ postsDataArray: updatePostList });
    } else if (status === "delete") {
      const updatePostList = postData?.filter((item: any) => {
        return item.id != data?.id
      })
      this.setState({ postsDataArray: updatePostList });
    }
  }


  submitUsersRating = async () => {
    this.setState({ isAddRatingRateAsCustomer: false });
    const generalRating = { ...this.state.generalRating };
    const userRating = { ...this.state.userRateItems };


    const ratingObj = generalRating.array.reduce((prevObj: any, newObject: any) => {
      if (newObject.name) {
        return ({ ...prevObj, [newObject.name]: newObject.value || 0 });
      } else {
        return prevObj;
      }
    }, {});
    console.log("ratingObj", ratingObj)

    const userRatingObj = userRating.array.reduce((prevObj: any, newObject: any) => {
      if (newObject.name) {
        return ({ ...prevObj, [newObject.name]: newObject.value || 0 });
      } else {
        return prevObj;
      }
    }, {});

    const httpBody = {
      data: {
        attributes: {
          account_id: this.state.account_id,
          rating: {
            [userRating.name]: userRatingObj,
            [generalRating.name]: ratingObj
          }
        }
      }
    }
    try {
      const response = await axios({
        url: `${baseURL}/bx_block_accountscoreranking/ratings`,
        method: 'post',
        headers: {
          'token': this.state.token,
          'Content-Type': 'application/json'
        },
        data: JSON.stringify(httpBody)
      })
      if (response && response?.data) {
        this.GetOtherBusinessProfile();
      }
    } catch (error) {
      console.error(error);
    }
  }

  getCategory = () => {
    const header = {
      "Content-Type": configJSON.categoryContentType,
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GetCategoriesId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.categoryEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.categoryMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getReview = () => {
    const header = {
      "Content-Type": configJSON.ReviewContentType,
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GetReviewsId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.ReviewEndPoint}?account_id=${this.state.account_id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.ReviewMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  onFinishAddReview = (values: any) => {
    this.setState({ isAddReview: false, isCompleteReview: true })
    const header = {
      "Content-Type": configJSON.addReviewContentType,
      token: localStorage.getItem("token")
    };
    let body = {
      "data": {
        "attributes": {
          "anonymous": values.anonymous ,
          "account_id": this.state.profileData?.profile?.data?.attributes?.account_id,
          "title": values.title,
          "description": values.description
        }
      }
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addReviewId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addReviewEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.addReviewMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  onFinishReport = (values: any) => {
    const header = {
      "Content-Type": configJSON.reportPostApiContentType,
      token: localStorage.getItem("token")
    };
    let body = values;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addReport = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.reportPostApiEndpoint}/${this.state.account_id}/report`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.reportPostApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleRemoveConnection = (requestId?: any) => {
    this.setState({ nextPageLoader: true })
    const header = {
      "Content-Type": configJSON.removeConnectionApiContentType,
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.removeConnectionId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.removeConnectionApiEndpoint}/${requestId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.removeConnectionApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  onHandleFollow = () => {
    this.setState({ followUnfollowAlert: false });
    const header = {
      "Content-Type": configJSON.followContentType,
      token: localStorage.getItem("token")
    };
    let body = {
      "account_id": this.state.profileData?.profile?.data?.attributes?.account_id
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.followId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.followEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.followMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleUnblock = (value: number) => {
    const token = localStorage.getItem("token");
    this.setState({ blockAlertOpen: false });
    const header = {
      "Content-Type": configJSON.blockedAccountPostApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    this.postBlockedAPIId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.blockedAccountPostApiEndpoint}/${value}/block`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.blockedAccountPostApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleOnClickConnect = async () => {
    if (this.state.category_id !== null) {
      try {
        const response = await axios({
          url: `${baseURL}/bx_block_request_management/requests`,
          method: 'post',
          headers: {
            'token': this.state.token,
            'Content-Type': 'application/json'
          },
          data: {
            data: {
              "sender_relation": this.state.category_id,
              "account_id": this.state.account_id
            }
          }

        })
        if (response && response?.data) {
          this.setState({ isSelectCategoryModalVisible: false });
          this.GetOtherBusinessProfile();
          this.setState({ nextPageLoader: true })
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      this.setState({ errorMessageOnOk: true })
    }
  }

  handleChatPush = () => {
    return this.props.history.push({ pathname: '/chat', state: { userId: this.state.account_id } })
  }
  handleReviewAlert = () => {
    message.error("you already Reviewed")
  }

  // Customizable Area End
}
