import React, { Suspense } from "react";
import UpgradeAccountController, { Props } from "./UpgradeAccountController.web";
import { orengepoint } from "./../assets";
import "./../userFeed.css";
import {
    Card,
    Row,
    Col,
    Typography,
    Avatar,
    Spin
} from "antd";
import moment from "moment";
// import CustomHeader from "../CustomHeader.web";
const { Text } = Typography;
export default class UpgradeAccount extends UpgradeAccountController {
    render() {
        const { navigation } = this.props;
        // if (this.state.accountDetails?.attributes?.account?.data?.attributes?.account_plan !== "premium") {
        //     this.props.history.push('/Premium/Account')
        // }
        return (
            this.state.isLoading
                ? <Card style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                    <Spin />
                </Card>
                :
                <>
                    {/* {this.redirectUser()} */}
                    {this.state.accountDetails?.attributes?.account?.data?.attributes?.account_plan === "premium" &&
                        <div style={{ backgroundColor: "#f6f6f6", height: "100vh" }}>
                            <Row style={{ paddingTop: "15px", marginBottom: 7, marginLeft: 20 }}>
                                <Text
                                    className="textlabel_subheadingText"
                                >
                                    My Profile /
                                </Text>
                                <Text
                                    className="textlabel_subheadingText2"
                                >
                                    {" "}
                                    Premium Account
                                </Text>
                            </Row>
                            <Col style={{ marginLeft: "20px", marginTop: "10px" }}>
                                <Text
                                    className="textlabel_headingText"
                                    style={{ width: 500, marginBottom: 10 }}
                                >
                                    My Plan Details
                                </Text>
                                <Card style={{ width: 500, padding: 10, marginTop: 10, backgroundColor: "#ffffff" }}>
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <div>
                                            <Text
                                                className="attrbuted_textlabel_UpgradetoPremiumText4"
                                                style={{ display: 'block' }}
                                            >
                                                Plan:
                                            </Text>
                                            <Text className="textlabel_UpgradetoPremiumText3">{this.state.accountDetails?.attributes?.account?.data?.attributes?.account_subscription?.data?.attributes?.name}</Text>
                                        </div>
                                        <div>
                                            <Text
                                                className="attrbuted_textlabel_UpgradetoPremiumText4"
                                                style={{ display: 'block' }}
                                            >
                                                Price:
                                            </Text>
                                            <Text
                                                className="textlabel_UpgradetoPremiumText3"
                                            >
                                                $ {this.state.accountDetails?.attributes?.account?.data?.attributes?.account_subscription?.data?.attributes?.yearly_plan}    <span className="attrbuted_textlabel_UpgradetoPremiumText4">/ Year</span>
                                            </Text>
                                        </div>
                                        <div>
                                            <Text
                                                className="attrbuted_textlabel_UpgradetoPremiumText4"
                                                style={{ display: 'block' }}
                                            >
                                                Ends on:
                                            </Text>
                                            <Text className="textlabel_UpgradetoPremiumText3">
                                            {moment(this.state.accountDetails?.attributes?.account?.data?.attributes?.subscribed_until).format('DD-MM-YYYY')}
                                                
                                                </Text>
                                        </div>
                                    </div>
                                    <div>
                                        <Col style={{ marginTop: 20 }}>
                                            <Text
                                                className="attrbuted_textlabel_UpgradetoPremiumText4"
                                                style={{ display: 'block' }}
                                            >
                                                Benefits:
                                            </Text>
                                            <div className="pre_row">
                                                <Avatar
                                                    size={14}
                                                    src={orengepoint}
                                                    style={{ marginTop: 2 }}
                                                />
                                                <Text
                                                    className="pre_point"
                                                >
                                                    Includes all features of basic plan
                                                </Text>
                                            </div>
                                            <div className="pre_row">
                                                <Avatar
                                                    size={14}
                                                    src={orengepoint}
                                                    style={{ marginTop: 2 }}
                                                />
                                                <Text
                                                    className="pre_point"
                                                >
                                                    Posted picture and videos will appear on the <br /> user page for a period of 365 days.
                                                </Text>
                                            </div>
                                            <div className="pre_row">
                                                <Avatar
                                                    size={14}
                                                    src={orengepoint}
                                                    style={{ marginTop: 2 }}
                                                />
                                                <Text
                                                    className="pre_point"
                                                >
                                                    Create personalized questionnaire for each <br /> category
                                                </Text>
                                            </div>
                                            <div className="pre_row">
                                                <Avatar
                                                    size={14}
                                                    src={orengepoint}
                                                    style={{ marginTop: 2 }}
                                                />
                                                <Text
                                                    className="pre_point"
                                                >
                                                    Advanced analytics on the ratings
                                                </Text>
                                            </div>
                                        </Col>
                                    </div>
                                </Card>
                            </Col>
                        </div>
                    }
                </>
        );
    }
}