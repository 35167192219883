import React, { FunctionComponent } from 'react';
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { Typography, Button, Modal, Row, Col } from 'antd';
import 'antd/dist/antd.css';
const { Title, Text } = Typography;

export const CommonDialog = (props: any) => {
    const { isModalVisible, body, onOk } = props;
    return (
        <>
            <Modal
                width={350}
                className="Logout_Popup"
                style={{ borderWidth: "0px", borderRadius: "4px", overflow: "hidden" }}
                centered
                title={
                    <div
                        style={{
                            width: "100%",
                            cursor: "move",
                            fontWeight: "bold",
                            borderWidth: "0px"
                        }}
                        onFocus={() => { }}
                        onBlur={() => { }}
                    // end
                    >
                        Add Review
                    </div>
                }
                visible={isModalVisible}
                onOk={onOk}
                onCancel={onOk}
                footer={[
                    <Row>
                        <Col span={24}>
                            <Button
                                size="large"
                                className="report-button"
                                key="report"
                                onClick={onOk}
                            >
                                Ok
                            </Button>
                        </Col>
                    </Row>
                ]}
            >
                <Text>{body ? body : 'Something went Wrong'}</Text>
            </Modal>
        </>
    );
};
