import React from 'react';
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
export const configJSON = require("./../config");
// Customizable Area Start
import axios from "axios";
import { Redirect } from "react-router-dom";
// @ts-ignore
import { baseURL } from "../../../framework/src/config.js";
// Customizable Area End

export interface Props extends RouterProps {
    navigation: any;
    id: string;
    // Customizable Area Start
    classes: any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    redirect: boolean;
    tabValue: string;
    pageLoader: boolean;
    tabData: any;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    // Customizable Area End
}

export default class AnniversariesController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getAnniversariesApiId: string;
    // Customizable Area End 
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.CountryCodeMessage),
        ];

        this.state = {
            redirect: false,
            tabValue: '',
            pageLoader: true,
            tabData: []
        };


        // Customizable Area End
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }
    async componentDidMount() {
        super.componentDidMount();
        let token = await localStorage.getItem("token");
        if (token && token !== null && token !== undefined) {
            this.setState({ redirect: false });
            this.getAnniversaries();
        } else {
            this.setState({ redirect: true });
        }
    }

    redirectUser() {
        if (this.state.redirect) {
            return <Redirect to="/" />;
        }
    }

    async receive(from: String, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("on recieive==>" + JSON.stringify(message));

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            var errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if (apiRequestCallId && responseJson) {
                if (apiRequestCallId === this.getAnniversariesApiId) {
                    console.log("getData", responseJson);
                    if (responseJson.data) {
                        const dataObj = {
                            "work": {
                                "today": {
                                    "data": []
                                },
                                "this_month": {
                                    "data": []
                                },
                                "next_month": {
                                    "data": []
                                }
                            },
                            "birth": {
                                "today": {
                                    "data": []
                                },
                                "this_month": {
                                    "data": []
                                },
                                "next_month": {
                                    "data": []
                                }
                            }
                        }
                        const dataArray = Object.entries(dataObj).map((itemObj) => ({ name: [itemObj[0]], items: itemObj[1] }));


                        // this.setState({
                        //     tabData: ,
                        //     tabValue: "work",
                        //     pageLoader: false
                        // })
                    }
                }
            }
        } else if (getName(MessageEnum.SessionResponseMessage) === message.id) {
            let requesterId = message.getData(
                getName(MessageEnum.SessionRequestedBy)
            );
        }

        // Customizable Area End

    }

    // getAnniversaries = async () => {
    //     const token = localStorage.getItem("token");
    //     const header = {
    //         "Content-Type": configJSON.getPostsApiContentType,
    //         token: token,
    //     };
    //     const requestMessage = new Message(
    //         getName(MessageEnum.RestAPIRequestMessage)
    //     );       

    //     this.getAnniversariesApiId = requestMessage.messageId;
    //     requestMessage.addData(
    //         getName(MessageEnum.RestAPIResponceEndPointMessage),
    //         'account_block/accounts/anniversaries'
    //     );
    //     requestMessage.addData(
    //         getName(MessageEnum.RestAPIRequestHeaderMessage),
    //         header
    //     );
    //     requestMessage.addData(
    //         getName(MessageEnum.RestAPIRequestMethodMessage),
    //         'get'
    //     );
    //     runEngine.sendMessage(requestMessage.id, requestMessage);
    //     return true;
    // };

    getAnniversaries = async () => {
        const token = localStorage.getItem("token");
        try {
            const response = await axios({
                url: `${baseURL}/account_block/accounts/anniversaries`,
                method: 'get',
                headers: {
                    'token': token,
                    'Content-Type': 'application/json'
                }
            })
            if (response.data) {
                const dataArray = Object.entries(response.data).map((itemObj) => ({ name: itemObj[0], items: itemObj[1] }));
                this.setState({
                    tabData: dataArray,
                    tabValue: "work",
                    pageLoader: false
                })
            }
        } catch (error) {
            console.error(error);
        }
    };

    // Customizable Area Start

    // Customizable Area End
}
