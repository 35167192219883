import React, { Component } from "react";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
export const configJSON = require("./config");
import { RouterProps } from "react-router";
import { getStorageData } from "../../../framework/src/Helpers";
export interface Props extends RouterProps {
  navigation: any;
  id: string;
}
interface S {
  loading: boolean;
  isRegistration: boolean;
  bussiness_profile: boolean;
  data: any;
  seconds: number;
  isResendOtp: boolean;
  isErrorModalShow: boolean;
  errorContent: any;
  time: any;
  token: string;
  _isMounted: boolean;
}
interface SS {
  id: any;
}

export default class VerifyCodeController extends BlockComponent<Props, S, SS> {
  createAccountApiCallId: any;
  resendForgotPasswordOTPApiCallId: any;
  formref = React.createRef();
  timer: any;

  constructor(props: Props) {
    super(props);
    this.timer = 0;
    this.startTimer = this.startTimer.bind(this);
    this.countDown = this.countDown.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.receive = this.receive.bind(this);
    this.state = {
      bussiness_profile: true,
      loading: false,
      isRegistration: true,
      data: this.props.history ? this.props.history.location.state : "",
      seconds: 60,
      isResendOtp: false,
      isErrorModalShow: false,
      errorContent: {},
      time: {},
      token: "",
      _isMounted: false
    };
  }
  async componentDidMount() {
    this.getTimer();

    let initialToken: any = this.props.history
      ? this.props.history.location.state
      : {};
    if (this.props.history) {
      this.setState({ token: initialToken.meta.token });
      console.log("this.props.history.location.state", initialToken.meta.token);
    }
    this.setState({ _isMounted: true });
    this.state._isMounted && this.getTimer();
  }

  async componentWillUnmount() {

    return this.setState({ _isMounted: false });
  }
  getTimer() {
    let timeLeftVar = this.secondsToTime(this.state.seconds);
    this.setState({ time: timeLeftVar });
    this.startTimer();
  }
  startTimer() {
    if (this.timer == 0 && this.state.seconds > 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
  }

  async countDown() {
    // Remove one second, set state so a re-render happens.
    let seconds = this.state.seconds - 1;
    this.setState({
      time: this.secondsToTime(seconds),
      seconds: seconds,
    });
    // Check if we're at zero.
    if (seconds == 0) {
      await clearInterval(this.timer);
      this.timer = 0;
    }
  }
  secondsToTime(secs: any) {
    let hours = Math.floor(secs / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    let obj = {
      h: hours,
      m: minutes,
      s: seconds,
    };
    return obj;
  }
  handleOtp = (data: any) => {
    console.log(data);
    this.setState({ loading: true });
    const header = {
      "Content-Type": "application/json",
    };
    console.log(this.state.data.meta.token);
    Object.assign(data, {
      token: this.state.token,
    });

    const json_data = {
      data: data,
    };
    const httpBody = json_data;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.verifyOtpAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  async onResendOTP() {
    this.setState({
      isResendOtp: true,
      seconds: 0,
    });
    const res = await getStorageData("pn");
    // const values = JSON.parse(res);

    const isPN = isNaN(res);
    console.log("isPN: ", isPN, res);

    const header = {
      "Content-Type": configJSON.apiResendOtpContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    console.log("requestMessage = ", JSON.stringify(requestMessage));

    console.log(
      "requestMessage messageId = ",
      JSON.stringify(requestMessage.messageId)
    );

    this.resendForgotPasswordOTPApiCallId = requestMessage.messageId.toString();

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiResendOtpEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    let otpData = { data: {} };
    if (!isPN) {
      otpData.data = {
        // attributes: {
        full_phone_number: res,
        // }
      };
    } else {
      otpData.data = {
        // attributes: {
        email: res,
        // }
      };
    }

    const httpBody = otpData;
    console.log("httpBody", httpBody);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiResendOtpMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  async receive(from: string, message: Message) {
    console.log("receive");
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.createAccountApiCallId) {
          this.setState({ loading: false });
          console.log("code", responseJson);
          if (responseJson.messages) {
            this.props.history.push({
              pathname: "/reset/password",
              state: responseJson,
            });
          } else if (responseJson.errors) {
            console.log(responseJson.errors[0].otp);
            if (responseJson.errors && responseJson.errors[0]?.otp) {

              this.setState({
                isErrorModalShow: true,
                errorContent: {
                  title: "error",
                  isError: true,
                  body: responseJson.errors[0].otp,
                },
              });
            } else if (responseJson.errors && responseJson.errors[0]?.pin) {
              this.setState({
                isErrorModalShow: true,
                errorContent: {
                  title: "error",
                  isError: true,
                  body: responseJson.errors[0].pin,
                },
              });
            } else {
              this.setState({
                isErrorModalShow: true,
                errorContent: {
                  title: "error",
                  isError: true,
                  body: "something went wrong",
                },
              });
            }
          } else {
            console.log("something went wrong");
          }
        } else if (apiRequestCallId === this.resendForgotPasswordOTPApiCallId) {
          console.log("res==>", responseJson);
          if (responseJson.errors) {
            this.setState({
              isResendOtp: false,
              seconds: 0,
            });
            this.setState({
              isErrorModalShow: true,
              errorContent: {
                title: "error",
                isError: true,
                body: responseJson.errors[0].pin,
              },
            });
          } else if (responseJson.email_otp) {
            this.setState({ token: responseJson.meta.token });
            this.setState({
              isResendOtp: false,
              seconds: 60,
            });
            this.getTimer();
          }
        }
      } else {
        console.log("error");
      }
    }
  }
  handleSignup = () => {
    this.props.navigation.navigate("CreateAccount");
  };
}
