import React, { Suspense } from 'react'
import ViewPersonalPostController, { Props } from "./ViewPersonalPostController.web";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import {
  Row, Col, Card, Spin
} from "antd";
const CustomPost = React.lazy(() => import('./../CustomPost.web'));
export default class viewPersonalPost extends ViewPersonalPostController {

  renderPost = (item: any) => {
    return (
      <Suspense fallback={<Card style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
        <Spin />
      </Card>} >
        <CustomPost
          menu={{ isCommunity: false }}
          loginUserId={this.state?.loginUser?.id ? this.state?.loginUser?.id : this.state?.userProfile?.attributes?.profile?.data?.attributes?.account_id}
          handlePostList={this.handlePostList}
          data={item.attributes}
          handleMenuClick={(e: any) => this.handleMenuClick(e, item.id)}
          isFollowed={this.state.followedItems.includes(item.attributes?.account?.data?.id)}
          toggleFollow={() => this.onClickFollow(item.attributes?.account?.data?.id, this.state.followedItems.includes(item.attributes?.account?.data?.id))}
          handleEditPostOpen={() => console.log('handleEditPostOpen method')}
        />
      </Suspense>
    )
  }
  render() {

    return (
      <>
        {this.redirectUser()}
        <div style={{ backgroundColor: "#f6f6f6", height: '100%', minHeight: 680 }}>
          {this.state.getPostBYIdForShare ? <>
            <Row style={{ backgroundColor: "#f6f6f6", justifyContent: "center" }}>
              <Col span={8} xs={24} sm={24} md={24} lg={12} xl={12} xxl={12} style={{ paddingLeft: "16px", paddingRight: '16px' }}>
                {this.renderPost(this.state.getPostBYIdForShare)}
              </Col>
            </Row>
          </>
            : "no post is available"}
        </div>
      </>

    )
  }
}
