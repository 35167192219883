import React, { Component } from "react";
// Customizable Area Start
import {
  PageHeader,
  Card,
  Row,
  Col,
  Form,
  Input,
  InputNumber,
  Typography,
  Button,
  Spin,
  Space,
  Select,
  Avatar,
  Upload,
  message,
} from "antd";
const { Title } = Typography;
import { logo } from "../assets";
const style = require(`../../../social-media-account/src/login.module.css`);
import "../profile.css";
import { UserAddOutlined } from "@ant-design/icons";
import CreateProfileBasicController from "../ProfileSetupBasicController.web";
const { Option } = Select;
// Customizable Area End
export default class ProfileSetupBasicBusiness extends CreateProfileBasicController {
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    return (
      <div>
        <PageHeader
          className="site-page-header"
          title={
            <span style={{ color: "white" }}>
              <img style={{ height: "32px", marginRight: "8px" }} src={logo} />
              applied
            </span>
          }
          style={{
            backgroundImage: "linear-gradient(#005574, #7aacd6)",
            color: "white",
          }}
        />
        <Row
          style={{ minHeight: "90vh", alignItems: "center", marginTop: "60px" }}
        >
          <Col xs={0} sm={0} md={3} lg={7} xl={7} />
          <Col xs={24} sm={24} md={18} lg={10} xl={10}>
            <Title
              level={2}
              style={{ textAlign: "center", marginBottom: "15px" }}
            >
              Setup Your Basic Profile
            </Title>
            <Form
              initialValues={{
                company_name: "",
                business: this.state.industry_type
                  ? JSON.parse(this.state.industry_type).industry
                  : "",
                business_industry: null,
                ownership_typer: "",
                established_year: "",
                founder: "",
                contact_person: "",
                num_of_employees: "",
                location: "",
                website: "",
              }}
              onFinish={this.handleAddProfileData}
            >
              <Card>
                {console.log(this.state.industry_type)}
                <div
                  style={{
                    justifyContent: "center",
                    display: "flex",
                    marginBottom: "16px",
                  }}
                >
                  <Upload>
                    <Avatar
                      size={64}
                      icon={<UserAddOutlined translate={{}} />}
                    />
                  </Upload>
                </div>
                <Form.Item
                  rules={[{ required: true, message: "Required field" }]}
                  name="company_name"
                >
                  <Input
                    className={style.input_fields}
                    name="company_name"
                    placeholder="Company Name"
                    size="large"
                  />
                </Form.Item>
                <Form.Item
                  rules={[{ required: true, message: "Required field" }]}
                  name="business"
                >
                  <Input
                    className={style.input_fields}
                    name="business"
                    placeholder="Business"
                    size="large"
                  />
                </Form.Item>
                <Form.Item
                  rules={[{ required: true, message: "Required field" }]}
                  name="business_industry"
                >
                  <Select
                    id={"select"}
                    className={style.input_fields}
                    style={{ padding: "0 !important" }}
                    placeholder="Business Industry / Category"
                    notFoundContent={
                      this.state.loading ? (
                        <Spin size="small" />
                      ) : (
                        "No Data Found "
                      )
                    }
                    onChange={this.handleCategoryChange}
                  >
                    {this.state.children}
                  </Select>
                </Form.Item>
                <Form.Item
                  rules={[{ required: true, message: "Required field" }]}
                  name="ownership_type"
                >
                  <Select
                    className={style.input_fields}
                    placeholder="Ownership Type"
                    style={{ padding: "0 !important" }}
                    allowClear
                  >
                    {this.state.sub_category_data}
                  </Select>
                </Form.Item>
                <Form.Item
                  rules={[{ required: true, message: "Required field" }]}
                  name="established_year"
                >
                  <Select
                    className={style.input_fields}
                    placeholder="Established Year"
                    style={{ padding: "0 !important" }}
                    allowClear
                  >
                    <Option value="2000">2000</Option>
                    <Option value="2001">2001</Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  rules={[{ required: true, message: "Required field" }]}
                  name="founder"
                >
                  <Input
                    className={style.input_fields}
                    name="founder"
                    placeholder="Founder"
                  />
                </Form.Item>
                <Form.Item
                  rules={[
                    { required: true, message: "Required field" }
                  ]}
                  name="contact_person"
                >
                  <InputNumber
                    className={style.input_fields}
                    name="contact_person"
                    placeholder="Contact Person"
                  />
                </Form.Item>
                <Form.Item
                  rules={[{ required: true, message: "Required field" }]}
                  name="num_of_employees"
                >
                  <Select
                    className={style.input_fields}
                    placeholder="Number of Employees"
                    style={{ padding: "0 !important" }}
                    allowClear
                  >
                    <Option value="lorem">Lorem</Option>
                    <Option value="text">Text</Option>
                    <Option value="ipsum">Ipsum</Option>
                    <Option value="other">Other</Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  rules={[{ required: true, message: "Required field" }]}
                  name="location"
                >
                  <Select
                    className={style.input_fields}
                    placeholder="Location"
                    style={{ padding: "0 !important" }}
                    allowClear
                  >
                    <Option value="lorem">Lorem</Option>
                    <Option value="text">Text</Option>
                    <Option value="ipsum">Ipsum</Option>
                    <Option value="other">Other</Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  rules={[{ required: true, message: "Required field" }]}
                  name="website"
                >
                  <Input
                    className={style.input_fields}
                    name="website"
                    placeholder="Website"
                  />
                </Form.Item>
              </Card>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "15px",
                  background: "whitesmoke",
                }}
              >
                <Button
                  className={style.btn_colors}
                  type="primary"
                  htmlType="submit"
                  shape="round"
                  size={"large"}
                  style={{ width: "200px" }}
                >
                  Next
                </Button>
              </div>
            </Form>
          </Col>
          <Col xs={0} sm={0} md={3} lg={7} xl={7} />
        </Row>
      </div>
    );
    // Customizable Area End
  }
}
// Customizable Area Start
// Customizable Area End