
import React from "react";
import { Tabs } from "antd";
import { Grid, withStyles, Button, Box, CircularProgress } from "@material-ui/core";
const { TabPane } = Tabs;
import {
    Row,
    Col,
    Typography
} from "antd";
import AnniversariesController, { Props } from "./AnniversariesController.web";
import CustomHeader from "../../CommunityForum/src/CustomHeader.web";
import AnniversarieCard from "./AnniversarieCard.web";
const { Text } = Typography;

class Anniversaries extends AnniversariesController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start   
        // Customizable Area End
    }

    render() {
        const { classes } = this.props;
        const { pageLoader, tabData } = this.state;


        const pageLoading = <Grid container style={{ display: "flex", width: '100%', minHeight: "200px" }}>
            <Box style={{ margin: 'auto' }}>
                <CircularProgress />
            </Box>
        </Grid>;

        return (
            <React.Fragment>
                <div style={{ backgroundColor: "#f6f6f6" }}>
                    {this.redirectUser()}
                    {/* <CustomHeader
                        dotVisible={true}
                        {...this.props}
                    /> */}
                    <Grid container className={classes.fullContainer}>
                        <Row style={{ padding: "10px 20px" }}>
                            <Text
                                style={{
                                    fontSize: "14px",
                                    opacity: 0.9,
                                    color: "#dde6eb",
                                    fontWeight: "bold"
                                }}
                            >
                                My Profile /
                            </Text>
                            <Text
                                style={{
                                    fontSize: "14px",
                                    opacity: 0.8,
                                    color: "#5D8EC1",
                                    fontWeight: "bold"
                                }}
                            >
                                Anniversaries
                            </Text>
                        </Row>
                        <Grid item xs={12} style={{ padding: "10px 20px" }}>
                            <Text
                                style={{
                                    fontSize: "18px",
                                    opacity: 0.8,
                                    color: "#000000",
                                    fontWeight: "bold"
                                }}
                            >
                                Anniversaries
                            </Text>
                        </Grid>
                        <Grid item xs={12} style={{ padding: "20px" }}>
                            <Tabs
                                activeKey={this.state.tabValue}
                                style={{ backgroundColor: "#F1F0F5" }}
                                onChange={(event: any) => this.setState({ tabValue: event })}
                            >
                                {
                                    pageLoader ? pageLoading : tabData.map((tabItem: any, index1: any) => (
                                        <TabPane tab={`${tabItem.name == "work" ? "Work" : "Birth"} Anniversaries`} key={tabItem.name} style={{ paddingLeft: 10, fontSize: '18px' }}>
                                            <Grid container>
                                                {tabItem?.items?.today?.data.length > 0 && <Grid item xs={12}>
                                                    <Grid item xs={12}>
                                                        <h5>Today</h5>
                                                    </Grid>
                                                    <Grid container>
                                                        {tabItem?.items?.today?.data.map((eachItem: any, index2: number) => <Grid key={`${tabItem.name}today${index2}`} item xs={3}>
                                                            <AnniversarieCard classes={classes} item={eachItem} today={true} />
                                                        </Grid>)}
                                                    </Grid>
                                                </Grid>}
                                                {tabItem?.items.this_month.data.length > 0 && <Grid item xs={12}>
                                                    <Grid item xs={12}>
                                                        <h5>This Month</h5>
                                                    </Grid>
                                                    <Grid container>
                                                        {tabItem?.items?.this_month?.data.map((eachItem: any, index2: number) => <Grid key={`${tabItem.name}this_month${index2}`} className={classes.cardContainer} >
                                                            <AnniversarieCard classes={classes} item={eachItem} />
                                                        </Grid>)}
                                                    </Grid>
                                                </Grid>}
                                                {tabItem.items.next_month.data.length > 0 && <Grid item xs={12}>
                                                    <Grid item xs={12}>
                                                        <h5>Next Month</h5>
                                                    </Grid>
                                                    <Grid container>
                                                        {tabItem?.items?.next_month?.data.map((eachItem: any, index2: number) => <Grid item xs={3} key={`${tabItem.name}next_month${index2}`}>
                                                            <AnniversarieCard classes={classes} item={eachItem} />
                                                        </Grid>)}
                                                    </Grid>
                                                </Grid>}
                                            </Grid>
                                        </TabPane>
                                    ))
                                }
                            </Tabs>
                        </Grid>
                    </Grid>
                </div>
            </React.Fragment >
        )
    }
}

const styles = () => ({
    fullContainer: {
        padding: '20px 10px'
    },
    cardContainer: {
        padding: "18px",
        background: "white",
        borderRadius: 5,
        marginRight: 10,
        marginBottom: 10,
        width: 350
    },
    image: {
        width: 80,
        height: 80,
        margin: "auto",
        borderRadius: "50%",
        marginBottom: "10px"
    },
    userName: {
        margin: 0,
        width: "100%"
    },
    daysLeft: {
        margin: 0,
        color: "gray"
    },
    viewProfile: {
        border: "2.5px solid #2d9ff9",
        color: "#2d9ff9",
        borderRadius: "25px",
        margin: "auto"
    }
});

export default withStyles(styles)(Anniversaries);