import React from "react";
// Customizable Area Start
import QuestionnaireController, { Props } from "./QuestionnaireController.web";
import { Tabs, Modal, Typography } from "antd";
import QuestionComponent from "./QuestionComponent.web";
import { Grid, withStyles, Button, Box, CircularProgress } from "@material-ui/core";
const { TabPane } = Tabs;

const { Text } = Typography;

// Customizable Area End

export class ProfileQuestionnaireTabs extends QuestionnaireController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start  
        // Customizable Area End
    }
    // Customizable Area Start
    tabChange = (val: any) => {
        this.setState({
            tabValue: val
        })
        if (!val) {
            this.handleAlert(this.state.tabValue);
        }
    }

    getTabName = (tabItem: any) => {
        switch (tabItem[0]) {
            case "Customer":
                return "Customer"
            case "Community":
                return "Community"
            case "Leader":
                return "Leadership"
            default:
                return tabItem[0]
        }
    }
    // Customizable Area End
    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const { pageLoader, tabData, alertView } = this.state;

        const pageLoading = <Grid container style={{ display: "flex", width: '100%', minHeight: "200px" }}>
            <Box style={{ margin: 'auto' }}>
                <CircularProgress />
            </Box>
        </Grid>;
        return (
            <React.Fragment>
                <div style={{ backgroundColor: "#FFFFFF", borderRadius: "5px", width: "100%" }}>
                    {this.redirectUser()}
                    <Grid container className={classes.fullContainer}>
                        <Grid item xs={12} style={{ padding: "10px 20px" }}>
                            <Text
                                className="textlabel_headingText"
                                style={{ fontSize: "14px" }}
                            >
                                Update Questionnaire
                            </Text>
                        </Grid>
                        <Grid item xs={12} style={{ padding: "10px 20px" }}>
                            <Tabs
                                activeKey={this.state.tabValue}
                                data-testid="tabchange"
                                onChange={this.tabChange}
                                style={{ backgroundColor: "#F1F0F5" }}
                            >
                                {
                                    pageLoader ? pageLoading : tabData.map((tabItem: any, index1: any) => {
                                        const nextKey = tabData[index1 + 1] && tabData[index1 + 1][0];
                                        let backButton;
                                        if (index1 > 0) {
                                            const backKey = tabData[index1 - 1][0];
                                            backButton = <Button data-testid={`backBtn-${index1 + 1}`} onClick={() => this.tabChange(backKey)} variant="contained" className={classes.backTab} disabled={this.state.saveAndNextButton}>Back</Button>;
                                        }

                                        const tabname = this.getTabName(tabItem)
                                        return (
                                            <TabPane tab={tabname + " View"} key={tabItem[0]} style={{ padding: 20, fontSize: '18px' }}>
                                                <Grid container spacing={2}>
                                                    {
                                                        tabItem[1].map((val: any, index: number) =>
                                                            <Grid item xs={12} sm={6} md={6} xl={4} key={"item" + tabItem[0] + index} >
                                                                <QuestionComponent
                                                                    classes={classes}
                                                                    questObj={val}
                                                                    index={index}
                                                                    saveChanges={this.saveChangedQuestionnaire}
                                                                    handleSaveNext={this.handleSaveAndNext}
                                                                    profileDetails={this.state.accountDetails}
                                                                    tabname={this.state.tabValue}
                                                                />
                                                            </Grid>)

                                                    }
                                                    <Grid item xs={12} className={classes.nextTabContainer}>
                                                        {backButton}
                                                        <Button
                                                            data-testid={`saveNextBtn-${index1 + 1}`}
                                                            onClick={() => this.tabChange(nextKey)}
                                                            variant="contained"
                                                            className={classes.saveNextTab}
                                                            disabled={
                                                                
                                                                this.state.accountDetails?.attributes?.account?.data?.attributes?.account_plan !== "premium" ||
                                                                this.state.saveAndNextButton
                                                            }
                                                        >
                                                            {tabData.length == index1 + 1 ? "Save" : "Save & Next"}
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </TabPane>
                                        )

                                    })
                                }
                            </Tabs>
                            <Modal
                                data-testid="updateDataModal"
                                title="Update Questionnaire"
                                visible={alertView}
                                footer={
                                    <Button
                                        key="submit"
                                        onClick={() => {
                                            this.handleAlert(this.state.tabValue);
                                            this.handleSaveAndNext(true);
                                        }}
                                    >
                                        Ok
                                    </Button>}
                                onOk={() => {
                                    this.handleAlert(this.state.tabValue)
                                    this.handleSaveAndNext(true);
                                }}
                                onCancel={() => {
                                    this.handleAlert(this.state.tabValue)
                                    this.handleSaveAndNext(true);
                                }}
                            >
                                <Text>Questionnaire Updated Successfully</Text>
                            </Modal>
                        </Grid>
                    </Grid>
                </div>
            </React.Fragment >
        )
        //Customizable Area End
    }
}

// Customizable Area Start
const styles = (theme: any) => ({
    formController: {
        marginBottom: '20px',
        padding: "5px 10px",
        [theme.breakpoints.down('281')]: {
            padding: 0,
        },
    },
    fullContainer: {
        padding: '20px 10px'
    },
    labelProps: {
        color: '#c3c3c3!important',
        fontSize: "16px",
        fontWeight: 400,
    },
    textFieldRoot: {
        padding: "5px",
        // backgroundColor: "#00000000",
    },
    cssOutlinedInput1: {
        marginTop: "25px!important",
        padding: "4px 20px",
        // backgroundColor: "#e6e6e6",
        borderRadius: 27,
        border: '1px solid #b4c2ce'
        ,
        [theme.breakpoints.up('xs')]: {
            marginTop: "33px!important",
        },
    },
    cssOutlinedInput2: {
        marginTop: "25px!important",
        padding: "7px 20px",
        // backgroundColor: "#e6e6e6",
        borderRadius: 6,
        border: '1px solid #b4c2ce'
        ,
        [theme.breakpoints.up('xs')]: {
            marginTop: "33px!important",
        },
    },
    editButton: {
        borderColor: '#007491',
        borderRadius: 20,
        color: "white",
        backgroundImage: "linear-gradient(#00536c, #007491)!important",
        padding: "7px 47px",
        marginLeft: 'auto',
        marginRight: '10px',
        fontSize: 14,
        fontWeight: 600
    },
    saveNextTab: {
        borderColor: '#007491',
        borderRadius: 20,
        color: "white",
        // backgroundColor: "#045878!important",
        backgroundImage: "linear-gradient(#00536c, #007491)!important",
        padding: "7px 53px",
        marginRight: '10px',
        fontSize: 14,
        fontWeight: 500,
        [theme.breakpoints.up('xs')]: {
            padding: "7px 25px",
        },
    },
    backTab: {
        border: "1px solid #045878!important",
        borderRadius: 20,
        padding: "7px 65px",
        marginRight: '10px',
        color: "#045878",
        [theme.breakpoints.up('xs')]: {
            padding: "7px 26px",
        },
    },
    nextTabContainer: {
        display: 'flex',
        justifyContent: 'end',
        margin: '25px 0px',
        [theme.breakpoints.down('281')]: {
            flexDirection: "row",
            flexWrap: "wrap-reverse",
            gap: "10px"
        },
    }
});
// Customizable Area End

export default withStyles(styles)(ProfileQuestionnaireTabs);
