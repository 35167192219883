// Customizable Area Start
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { RouterProps } from "react-router";
import { Redirect } from "react-router-dom";
import { getStorageData, isEmpty } from "../../../../framework/src/Utilities";
export const configJSON = require("./../config");
// Customizable Area End

export interface Props extends RouterProps {
    navigation: any;
    id: string;
    // Customizable Area Start
    classes: any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    redirect: boolean;
    pageLoader: boolean;
    hidePosts: any;
    userProfile: any;
    loginId: any;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    // Customizable Area End
}

export default class HidePostsController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getMyHidePostsApiId: string;
    deletePostAPiId: string;
    // Customizable Area End 
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.CountryCodeMessage),
        ];

        this.state = {
            redirect: false,
            pageLoader: true,
            hidePosts: [],
            userProfile: null,
            loginId: null
        };


        // Customizable Area End
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }
    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        let token = await localStorage.getItem("token");
        if (token && token !== null && token !== undefined) {
            this.setState({ redirect: false });
        } else {
            this.setState({ redirect: true });
        }

        await getStorageData("userProfile").then((res) => {
            console.log("res-->", res);
            this.setState({ userProfile: res, loginId: res?.attributes?.account?.data?.attributes?.account_id });
        });
    }

    redirectUser() {
        if (this.state.redirect) {
            return <Redirect to="/" />;
        }
    }
    // Customizable Area End

    async receive(from: String, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("on recieive==>" + JSON.stringify(message));

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            var errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if (apiRequestCallId && responseJson) {
                if (apiRequestCallId === this.getMyHidePostsApiId) {
                    console.log("getData", responseJson);
                    if (responseJson.data) {
                        this.setState({ pageLoader: false, hidePosts: responseJson.data });
                    }
                } else if (apiRequestCallId === this.getMyHidePostsApiId) {
                    this.setState({ pageLoader: true });
                    this.getMyHidePosts();
                }

                if (apiRequestCallId === this.deletePostAPiId) {
                    console.log("getDataaaaaa", responseJson);
                    // this.setState({ delId: null });
                    this.getMyHidePosts();
                }

            }
        } else if (getName(MessageEnum.SessionResponseMessage) === message.id) {
            let requesterId = message.getData(
                getName(MessageEnum.SessionRequestedBy)
            );
        }
        // Customizable Area End
    }
    // Customizable Area Start
    getMyHidePosts = async () => {
        const token = localStorage.getItem("token");
        const header = {
            "Content-Type": configJSON.getPostsApiContentType,
            token: token,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getMyHidePostsApiId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getMyHidePostsApiEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            header
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getRequestsApiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    };

    deletePost = (id: any) => {
        const token = localStorage.getItem("token");
        const header = {
            "Content-Type": configJSON.getPostsApiContentType,
            token: token,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.deletePostAPiId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_posts/posts/${id}/hide`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            header
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            'post'
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }
    // Customizable Area End

    // Customizable Area Start

    // Customizable Area End
}
