import React, { Component } from "react";
// Customizable Area Start
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import confirm from "../../alert/src/alert.web";
import { RouterProps } from "react-router";
import { Redirect } from "react-router-dom";
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Helpers";
import { Dimensions } from "react-native";
import { isInteger } from "lodash";
export const configJSON = require("./config");
const { width, height } = Dimensions.get("window");
// console.log(height, width);
// Customizable Area End
export interface Props extends RouterProps {
  navigation: any;
  id: string;
  // Customizable Area Start
  location: any;
  match: any;
  // Customizable Area End
}
interface S {
  loading: boolean;
  isRegistration: boolean;
  bussiness_profile: boolean;
  data: any;
  button: string;
  isRemember: boolean
  countryCode: any | string;
  isMobile: boolean;
  countryCodePlaceholder: any | string
  isErrorModalShow: boolean
  errorContent: any;
  redirect: boolean;
  isLoading: boolean;
  previousLoginData: {
    email: string,
    password: string
  }
  // Customizable Area Start
  postId: any;
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class LoginController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  createAccountApiCallId: any;
  GetProfielInformationId: any;
  formref = React.createRef();
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.CountryCodeMessage),
      // Customizable Area End
    ];
    // Customizable Area Start
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.receive = this.receive.bind(this);
    this.state = {
      bussiness_profile: true,
      loading: false,
      isRegistration: true,
      data: this.props.history ? this.props.history.location.state : "",
      button: "business",
      isRemember: false,
      countryCode: "+91",
      countryCodePlaceholder: '🇮🇳 | +91',
      isMobile: false,
      isErrorModalShow: false,
      errorContent: {},
      redirect: false,
      isLoading: true,
      previousLoginData: {
        email: "",
        password: ""
      },
      postId: null
    };
    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount() {
    let token = localStorage.getItem('token');
    if (token && token !== null && token !== undefined) {
      const tokenParts = token.split('.');
      const payload = JSON.parse(atob(tokenParts[1]));
      const currentTime = Math.floor(Date.now() / 1000);
      if (payload.exp < currentTime) {
        this.setState({
          isLoading: false
        });
      } else {
        this.setState({
          redirect: true,
          isLoading: false
        })
      }
    } else {
      setTimeout(() => {
        this.setState({
          isLoading: false
        });
      }, 1000);
    }
    let loginData = await getStorageData("RememberCredential");
    if (loginData) {
      if (Number.isInteger(parseInt(loginData?.email))) {
        this.setState({
          previousLoginData: loginData,
          isMobile: true
        })
      } else {
        this.setState({
          previousLoginData: loginData,
          isMobile: false
        })
      }
    }
    let id = this.props.location.state;
    let idd = await localStorage.getItem("sharePost");
    this.setState({ postId: id });
  }
  redirectUser() {
    if (this.state.redirect) {
      return <Redirect to='/home' />;
    }
  };
  handleOnChange = ({ target: { value } }: { target: any }) => {
    if (parseInt(value).toString() !== value) {
      this.setState({ isMobile: false });
    } else {
      this.setState({ isMobile: true });
    }
  }
  onChange = (e: any) => {
    // console.log('checked = ', e.target.checked);
    this.setState({
      isRemember: e.target.checked,
    });
  };

  handleCloseModal = () => {
    this.setState({ isErrorModalShow: false, errorContent: {} })
  }

  handleLogin = (data: {} | any) => {
    if (this.state.isRemember) {
      setStorageData("RememberCredential", { email: data.email, password: data.password })
    } else {
      removeStorageData("RememberCredential")
    }
    this.setState({ loading: true })
    const header = {
      "Content-Type": "application/json",
    };

    const isPN = !isNaN(Number(data.email.toString()));
    console.log("handle Login", isPN, data.email);
    let attrs = {
      data: { type: isPN ? "sms_account" : "email_account" },
      full_phone_number: isPN ? this.state.countryCode + data.email : null,
      email: !isPN ? data.email : null,
      password: data.password,
    };


    const httpBody = attrs;
    // const httpBody = data;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  async receive(from: string, message: Message) {
    console.log("receive");
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.createAccountApiCallId) {
          this.setState({ loading: false })
          if (responseJson?.token) {
            localStorage.setItem("token", responseJson.token);
            await setStorageData('loginUser', responseJson);
            await setStorageData('role', responseJson.role);
            if (responseJson.profile_setup) {
              this.setState({
                isErrorModalShow: true,
                errorContent: {
                  title: "Success",
                  isError: false,
                  body: "Login successfully",
                },
              });
              if (this.state.postId) {
                this.props.history.push({
                  pathname: `/viewPost/${this.state.postId}`
                });
              } else {
                this.props.history.push({
                  pathname: "/home",
                  state: this.state.data,
                });
              }
            }
            else if (
              !responseJson.profile_setup &&
              responseJson.role == "business"
            ) {
              this.props.history.push({
                pathname: "/select/industry",
                state: this.state.data,
              });
            } else {
              let data = { name: responseJson.first_name + " " + responseJson.last_name, email: responseJson.email }
              this.props.history.push({
                pathname: "/setup/profile/personal",
                state: data,
              });
            }
          } else if (responseJson.errors) {
            console.log("failed", responseJson.errors[0].failed_login);
            await this.setState({ data: responseJson.errors[0] });
            if (
              responseJson &&
              responseJson.errors &&
              responseJson.errors[0] &&
              (!responseJson.errors[0].email_otp_verified ||
                !responseJson.errors[0].sms_otp_verified)
            ) {
              if (
                responseJson.errors[0] && responseJson.errors[0]?.email_otp_verified != undefined &&
                !responseJson.errors[0].email_otp_verified
              ) {
                this.setState({ isErrorModalShow: true, errorContent: { title: "error", isError: true, body: responseJson.errors[0].failed_login } })
                setStorageData("isFromLogin", true);
                this.props.history.push({
                  pathname: "/email/varification",
                  state: { ...this.state.data, isFromLogin: true },
                });
              } else if (
                responseJson.errors[0] && responseJson.errors[0]?.email_otp_verified === true
              ) {
                this.setState({ isErrorModalShow: true, errorContent: { title: "error", isError: true, body: responseJson.errors[0].failed_login } })
                setStorageData("isFromLogin", true);
                this.props.history.push({
                  pathname: "/phone/varification",
                  state: { ...this.state.data, isFromLogin: true },
                });
              } else if (responseJson.errors[0] && responseJson.errors[0]?.sms_otp_verified != undefined && !responseJson.errors[0].sms_otp_verified) {
                this.setState({ isErrorModalShow: true, errorContent: { title: "error", isError: true, body: responseJson.errors[0].failed_login } })

                this.props.history.push({
                  pathname: "/phone/varification",
                  state: {
                    ...this.state.data,
                    isFromLogin: true,
                  },
                });
              } else {
                this.setState({ isErrorModalShow: true, errorContent: { title: "error", isError: true, body: responseJson.errors[0].failed_login } })

              }
            }
          } else {
            console.log("something went wrong");
          }
        }
      } else {
        console.log("something went wrong");
      }
    }
    if (message.id === getName(MessageEnum.CountryCodeMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.CountyCodeDataMessage)
      );
      if (apiRequestCallId.isValue) {
        this.setState({
          countryCodePlaceholder: `${apiRequestCallId.result.flag}   | +${apiRequestCallId.result.value
            }`, countryCode: `+${apiRequestCallId.result.value}`
        });
      }
    }
  }
  // Customizable Area End
}
