import React, { useState } from "react";
import CustomHeader from "../../CommunityForum/src/CustomHeader.web";
// @ts-ignore
import { baseURL } from '../../../framework/src/config.js';
import { imgPasswordVisible, user } from "./assets";
import { Typography, Row, Empty, Button, Avatar, Col, List, Tabs, message, Divider, Form, Modal, Select } from "antd";
const { Text, Title } = Typography;
import {
  ReloadOutlined
} from '@ant-design/icons';
import "./search.css";
import CommonListItem from "../../CommunityForum/src/commonListItem.web";
import { Link, Redirect } from 'react-router-dom';
import CustomPost from "../../CommunityForum/src/CustomPost.web";
import { getStorageData, isEmpty } from "../../../framework/src/Utilities";
import axios from "axios";
import { useHistory } from 'react-router-dom';
const { TabPane } = Tabs;

const SearchBlockWeb = (props: any) => {
  // console.log("props to the search screen", props?.location?.state?.searchResults?.communities?.data);

  const history = useHistory();

  const [searchTxt, setSearchTxt] = useState(props?.location?.state?.searchTxt);
  const [allPosts, setAllPosts] = useState<Array<any>>([]);
  const [personalProfiles, setPersonalProfiles] = useState(props?.location?.state?.searchResults?.personal_accounts);
  const [businessProfiles, setBusinessProfiles] = useState(props?.location?.state?.searchResults?.business_accounts);
  const [communityProfiles, setCommunityProfiles] = useState(props?.location?.state?.searchResults?.communities.data);
  const [values, setValues] = useState(props?.location?.state?.searchResults);
  const [redirect, setRedirect] = useState<boolean>(false);
  const [activeKeys, setActiveKey] = useState('1');
  const [loginId, setLoginId] = useState('0');
  const [peoplemayKnow, setPeoplemayKnow] = useState<any>([]);
  const [invitations, setInvitations] = useState<any>([]);
  const [likedItems, setLikedItems] = useState<any>([]);
  const [followedItems, setFollowedItems] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [invitationUserInfo, setinvitationUserInfo] = useState<any>({});
  const [isShowInvitationModal, setisShowInvitationModal] = useState<boolean>(false);
  const [isShowConnectionModal, setShowConnectionModal] = useState<boolean>(false);
  const [relationValue, setRelationvalue] = useState<string>('');
  const [businessConnection, setbusinessConnection] = useState([]);
  const [personalConnection, setpersonalConnection] = useState([]);
  const [loadInvitationData, setloadInvitationData] = useState([]);
  const [loadpeoplemayKnow, setLoadpeoplemayKnow] = useState<boolean>(true);
  const [loadInvitations, setLoadInvitations] = useState<boolean>(true);

  // initial api calls****************
  const getSearchReasults = async () => {
    // console.log(value)
    setLoading(true);
    try {
      const response = await axios({
        url:
          `${baseURL}/bx_block_search/search?search=${props?.location?.state?.searchTxt}&type=all`,
        method: "get",
        headers: {
          token: await localStorage.getItem('token'),
          "Content-Type": "application/json"
        }
      });
      if (response.data?.posts) {
        setAllPosts(response.data?.posts.data);
        const likedData = response.data?.posts?.data?.map((c: any) => c.attributes.liked && setLikedItems((prevState: any) => [...prevState, c.id]));
        let valueFollowed = response.data?.posts?.data?.map((c: any) => c.attributes.account.data.attributes.following && setFollowedItems((prevState: any) => [...prevState, c?.attributes?.account?.data?.id]));
        // console.log(likedData,'hello wrold');
      }
      // console.log(response, "reload search results");
      setLoading(false);
    } catch (error) {
      console.error(error, 'error while calling api');
      setLoading(false);
    }
    setLoading(false);
  };

  //People you know
  const getPeopleYouknow = async () => {
    setPeoplemayKnow([]);
    setLoadpeoplemayKnow(true);
    try {
      const response = await axios({
        url: `${baseURL}/bx_block_request_management/requests/suggestions?per_page=5&page=1`,
        method: 'get',
        headers: {
          'token': await localStorage.getItem('token'),
          'Content-Type': 'application/json'
        }
      })
      // const response = await axios.get('');
      setPeoplemayKnow(response.data);
      setLoadpeoplemayKnow(false);
      // console.log(response.data,'all getPeopleYouknow');
    } catch (error) {
      console.error(error);
      setLoadpeoplemayKnow(false);
    }
  }

  // Invitations
  const getInvitations = async () => {
    setLoadInvitations(true);
    setInvitations([]);
    try {
      const response = await axios({
        url: `${baseURL}/bx_block_request_management/requests?status=pending`,
        method: 'get',
        headers: {
          'token': await localStorage.getItem('token'),
          'Content-Type': 'application/json'
        },
        // data: JSON.stringify(httpBody)
      })
      // const response = await axios.get('');
      setInvitations(response.data);
      setLoadInvitations(false);
      await getCategories();
    } catch (error) {
      console.error(error);
      setLoadInvitations(false)
    }
  }

  const getCategories = async () => {
    try {
      const response = await axios({
        url: `${baseURL}/bx_block_categories/categories`,
        method: 'get',
        headers: {
          'token': await localStorage.getItem('token'),
          'Content-Type': 'application/json'
        },
        // data: JSON.stringify(httpBody)
      })
      if (response && response?.data) {
        let dropDownDetail = response && response.data;
        // console.log(dropDownDetail,' ****')
        let checkBusinessConnc = dropDownDetail.data.filter(
          (item: any) => item.attributes.name === "Business Connection");

        let checkPersonalConnc = dropDownDetail.data.filter(
          (item: any) => item.attributes.name === "Personal Connection");

        setbusinessConnection(checkBusinessConnc[0].attributes.sub_categories);
        setpersonalConnection(checkPersonalConnc[0].attributes.sub_categories);

      }
      // console.log(response.data,'****** all categories ***')
    } catch (error) {
      console.error(error);
    }
  }
  // add connection
  const addConnection = async (value: any, status: any, req_id: any, cat_id: any) => {
    let httpBody = {
      data: {
        account_id: req_id.toString(),
        category_id: value.toString()
      }
    }
    // console.log(value,'******', status,'******', id);
    try {
      const response = await axios({
        url: `${baseURL}/bx_block_request_management/requests`,
        method: 'post',
        headers: {
          'token': await localStorage.getItem('token'),
          'Content-Type': 'application/json'
        },
        data: JSON.stringify(httpBody)
      })
      if (response?.data) {
        getPeopleYouknow();
        // window.location.reload();
        return setShowConnectionModal(false);
      }
      // console.log(response.data, 'report submitted');
    } catch (error) {
      console.error(error);
      setShowConnectionModal(false);
    }
    setShowConnectionModal(false);
  }

  // Invitations
  const updateInvitations = async (value: any, status: any, req_id: any, cat_id: any) => {
    let httpBody = {
      data: {
        status: status,
        sender_category_id: cat_id
      }
    }
    // console.log(value,'******', status,'******', id);
    try {
      const response = await axios({
        url: `${baseURL}/bx_block_request_management/requests/${req_id}`,
        method: 'put',
        headers: {
          'token': await localStorage.getItem('token'),
          'Content-Type': 'application/json'
        },
        data: JSON.stringify(httpBody)
      })
      if (response?.data) {
        getInvitations();
        // window.location.reload();
        return setisShowInvitationModal(false);
      }
    } catch (error) {
      console.error(error);
      setisShowInvitationModal(false);
    }
    setisShowInvitationModal(false);
  }

  const handleInvitationModal = (data: any, role: any) => {
    // console.log(role , '*****************');
    setisShowInvitationModal(true);
    if (role && role === 'business') {
      setloadInvitationData(businessConnection);
      setinvitationUserInfo(data);

    } else {
      setloadInvitationData(personalConnection);
      setinvitationUserInfo(data);

    }
  }

  const handleConnectionModal = (data: any, role: any) => {
    // console.log(role , '*****************');
    setShowConnectionModal(true);
    if (role && role === 'business') {
      setloadInvitationData(businessConnection);
      setinvitationUserInfo(data);

    } else {
      setloadInvitationData(personalConnection);
      setinvitationUserInfo(data);

    }
  }

  const handleUserRequest = (value: any) => {
    if (value?.account_id === loginId) {
      return null
    } else {
      if (value?.role === "personal") {
        return history.push({ pathname: "/other/personal/profile", state: value?.account_id });
      } else if (value?.role === "business") {
        return history.push({ pathname: "/other/business/profile", state: value?.account_id });
      }
    }
  }

  React.useEffect(() => {
    let token = localStorage.getItem("token");
    if (token && token !== null && token !== undefined) {
      setRedirect(false);
    } else {
      setRedirect(true);
    }
    let data: any = getStorageData("loginUser").then((res) => {
      if (res) {
        setLoginId(res.id);
      }
      // console.log("loginUser",res)
    });
    getPeopleYouknow();
    getInvitations();

  }, [searchTxt]);

  React.useEffect(() => {
    getSearchReasults();
  }, [])

  const redirectUser = () => {
    if (redirect) {
      return <Redirect to="/" />;
    }
  }

  const toggleLike = (id: number, liked: boolean) => {
    if (liked) {
      console.log("Unlike");
      setLikedItems(likedItems.filter(function (el: any) {
        return el !== id;
      }));
      updatelikePost(id, 'dislike');
    } else {
      console.log("like");
      setLikedItems((prevState: any) => [...prevState, id])
      updatelikePost(id, 'like');
    }
  };

  const updatelikePost = async (id: number, updateLikes: any) => {
    // this.setState({ isloader: true });
    try {
      const response = await axios({
        url:
          `${baseURL}/bx_block_posts/posts/${id}/${updateLikes}`,
        method: "POST",
        headers: {
          token: await localStorage.getItem('token'),
          "Content-Type": "application/json"
        }
      });
      console.log(response, "toggle likes");
    } catch (error) {
      console.error(error, 'error while calling api follow REQUEST');
    }
  };
  const addComment = async (id: number, comment: any) => {
    let httpBody = {
      data: {
        attributes: {
          post_id: id,
          comment: comment,
          // "taggings_attributes": [
          //     {
          //         "account_id": 2
          //     }
          // ]
        },
      },
    };
    try {
      const response = await axios({
        url:
          `${baseURL}/bx_block_comments/comments`,
        method: "POST",
        headers: {
          token: await localStorage.getItem('token'),
          "Content-Type": "application/json"
        },
        data: JSON.stringify(httpBody)
      });
      window.location.reload();
      console.log(response, "comments added");
    } catch (error) {
      console.error(error, 'error while calling api follow REQUEST');
    }
  };
  const handleMenuClick = (e: any, id: any) => {
    // console.log("click", e);
    // if (e.key === "save") {
    //   // setDropDownVisible(true);
    //   this.savePost(id, "save");
    // }
    // if (e.key === "hide") {
    //   this.savePost(id, "hide");
    // }
    // if (e.key === "report") {
    //   // setDropDownVisible(true);
    //   this.setState({ isReportIssueVisible: true, reportPostID: id });
    // }
  }


  const handlePostList = (data: any, status: string) => {
    const postData = allPosts;
    if (status === "update") {
      console.log(status, data);
      const updatePostList = postData?.map((item: any) => {
        if (item.id === data.id) {
          return data
        } else {
          return item
        }
      })
      setAllPosts(updatePostList);
    } else if (status === "delete") {
      const updatePostList = postData?.filter((item: any) => {
        return item.id != data?.id
      })
      setAllPosts(updatePostList);
    }
  }



  const handleOpenOtherBusinessView = (account_id: number) => {
    return props.history.push({ pathname: "/business/profile/other", state: account_id });
  }

  const handleOpenOtherPersonalView = (account_id: number) => {
    return props.history.push({ pathname: "/personal/profile/other", state: account_id });
  }

  const toggleCommentLike = (id: number, liked: boolean) => { };
  const getComments = (id: number) => { };

  const handleChange = (event: any) => {
    console.log("@@@ e======hello", event);
    setRelationvalue(event);
  }

  const onClickFollow = async (id: number, isFollowed: boolean) => {
    // console.log(id,isFollowed,'follow fun')
    try {
      const response = await axios({
        url:
          `${baseURL}/bx_block_follow/follows?account_id=${id}`,
        method: "POST",
        headers: {
          token: await localStorage.getItem('token'),
          "Content-Type": "application/json"
        }
      });
      if (isFollowed) {
        setFollowedItems(followedItems.filter(function (el: any) {
          return el !== id;
        }));
      } else {
        setFollowedItems((prevState: any) => [...prevState, id]);
      }
      console.log(response, "following post");
    } catch (error) {
      console.error(error, 'error while calling api follow REQUEST');
    }
  };

  const getDomainSkills = (domain_skills: any) => {
    if (
      isEmpty(domain_skills)
    )
      return "";
    const domainSkillsPresent = domain_skills;
    const domainSkillsStr = domainSkillsPresent.join(", ")
    return domainSkillsStr;
  }

  const onItemPress = (id: string) => {
    console.log(id, 'press item');
    // console.log(this.state.allCommunity.data[+id - 1])
  }

  const renderPost = (item: any) => {
    // console.log(item,'props');
    return (
      <CustomPost
        menu={{ isCommunity: false }}
        handlePostList={handlePostList}
        loginUserId={loginId}
        data={item?.attributes}
        handleMenuClick={(e: any) => handleMenuClick(e, item.id)}
        isFollowed={followedItems.includes(item.attributes?.account?.data?.id)}
        toggleFollow={() => onClickFollow(item.attributes?.account?.data?.id, followedItems.includes(item.attributes?.account?.data?.id))}
        handleEditPostOpen={() => console.log('handleEditPostOpen method')}
      />
    );
  };

  const showInvitationModal = () => {
    return (
      <Modal
        width={450}
        style={{ borderWidth: "0px", borderRadius: "4px", overflow: "hidden" }}
        centered
        className="Logout_Popup"
        // placement="bottomRight"
        title={
          <div style={{ width: "100%", cursor: "move", fontWeight: "bold", borderWidth: "0px", paddingRight: '24px' }}
            onFocus={() => { }}
            onBlur={() => { }}
          // end
          >
            {invitationUserInfo?.name} wants to connect with you as a
            {/* {this.displayUserRelation(this.state?.relationValue)} */}
          </div>
        }
        visible={isShowInvitationModal}
        onCancel={() => setisShowInvitationModal(false)}
        footer={[
          <div style={{ display: 'flex', justifyContent: 'space-between', margin: '0 10px' }}>
            <Button size="large" className="report-button" style={{ width: "45%" }} key="accept"
              onClick={() => updateInvitations(relationValue, 'accepted',
                invitationUserInfo?.req_id, invitationUserInfo?.cat_id)}>
              Accept Request
            </Button>
            <Button size="large" className="reject-button" style={{ width: "45%" }} key="reject"
              onClick={() => updateInvitations(relationValue, 'rejected',
                invitationUserInfo?.req_id,
                invitationUserInfo?.cat_id)}>
              Reject Request
            </Button>
          </div>
        ]}
      >
        <Row gutter={12}>
          <Col
            xs={24}
          ><p>Change relationship to</p>
            <Form.Item
              //  validateStatus={this.state?.relationValue ? 'success' : "error"}
              rules={[
                {
                  required: true,
                  message: "Required field"
                }
              ]}
              style={{ borderWidth: 1 }}
              name="relationValue"
            >
              <Select
                id={"select"}
                style={{
                  padding: "0 !important",
                  borderRadius: '15px'
                }}
                placeholder="Select Relationship"
                bordered={true}
                dropdownStyle={{
                  borderRadius: "15px",
                  border: "1px solid #7aacd6",
                  paddingTop: "0px",
                  paddingBottom: "0px"
                }}
                // allowClear
                // notFoundContent={
                //   this.state.loadInvitationData ? (
                //     <Spin size="small" />
                //   ) : (
                //     "No Data Found "
                //   )
                // }
                onChange={handleChange}
              >
                {loadInvitationData &&
                  loadInvitationData.map((item: any) => {
                    // console.log("item32232332====", item);
                    return (
                      <option key={item.id}
                        style={{
                          borderBottom: "1px solid #7aacd6",
                          padding: "10px 20px 10px 20px"
                        }}
                        value={item.name}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%"
                            // paddingTop: "0.7rem",
                          }}
                        >
                          <div className="capital_options">{item.name}</div>
                        </div>
                      </option>
                    );
                  })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Modal>
    )
  }

  const showConnectionModal = () => {
    return (
      <Modal
        width={450}
        style={{ borderWidth: "0px", borderRadius: "4px", overflow: "hidden" }}
        centered
        className="Logout_Popup"
        // placement="bottomRight"
        title={
          <div style={{ width: "100%", cursor: "move", fontWeight: "bold", borderWidth: "0px", paddingRight: '24px' }}
            onFocus={() => { }}
            onBlur={() => { }}
          // end
          >
            Select a relationship with {invitationUserInfo?.name}
          </div>
        }
        visible={isShowConnectionModal}
        onCancel={() => setShowConnectionModal(false)}
        footer={[
          <div style={{ display: 'flex', justifyContent: 'space-between', margin: '0 10px' }}>
            <Button size="large" className="report-button" style={{ width: "45%" }} key="accept"
              onClick={() => addConnection(relationValue, 'accepted',
                invitationUserInfo?.req_id, invitationUserInfo?.cat_id)}>
              Send Request
            </Button>
            <Button size="large" className="reject-button" style={{ width: "45%" }} key="reject"
              onClick={() => setShowConnectionModal(false)}>
              Cancel Request
            </Button>
          </div>
        ]}
      >
        <Row gutter={12}>
          <Col
            xs={24}
          ><p>Change relationship to</p>
            <Form.Item
              //  validateStatus={this.state?.relationValue ? 'success' : "error"}
              rules={[
                {
                  required: true,
                  message: "Required field"
                }
              ]}
              style={{ borderWidth: 1 }}
              name="relationValue"
            >
              <Select
                id={"select"}
                style={{
                  padding: "0 !important",
                  borderRadius: '15px'
                }}
                placeholder="Select Relationship"
                bordered={true}
                dropdownStyle={{
                  borderRadius: "15px",
                  border: "1px solid #7aacd6",
                  paddingTop: "0px",
                  paddingBottom: "0px"
                }}
                // allowClear
                // notFoundContent={
                //   this.state.loadInvitationData ? (
                //     <Spin size="small" />
                //   ) : (
                //     "No Data Found "
                //   )
                // }
                onChange={handleChange}
              >
                {loadInvitationData &&
                  loadInvitationData.map((item: any) => {
                    // console.log("item32232332====", item);
                    return (
                      <option key={item.id}
                        style={{
                          borderBottom: "1px solid #7aacd6",
                          padding: "10px 20px 10px 20px"
                        }}
                        value={item.id}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%"
                            // paddingTop: "0.7rem",
                          }}
                        >
                          <div className="capital_options">{item.name}</div>
                        </div>
                      </option>
                    );
                  })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Modal>
    )
  }

  const redirectUserCondition = (path: any, data: any) => {
    if (path === 'community') {
      return props.history.push({ pathname: '/community/block', state: { communityId: data } })
    }
  }

  const infoComingSoon = () => {
    message.info('Coming soon');
  };


  return (
    <div className="searchStyles" style={{ background: "#f6f6f6" }}>
      {/* <CustomHeader {...props} dotVisible={false} /> */}
      {redirectUser()}
      <div>
        <Row style={{}}>
          <Col
            span={17}
            xs={24}
            xl={17}
            lg={17}
            md={24}
            style={{ padding: "1rem", color: "gray" }}
          >
            <div style={{ background: "#dee5eb", }}>
              <Tabs
                // defaultActiveKey="1"
                activeKey={activeKeys}
                onChange={setActiveKey}
                style={{ margin: "0 auto" }}
                centered
              >
                <TabPane
                  tab="All"
                  key="1"
                  style={{ background: "#f6f6f6", padding: '1rem' }}
                >
                  {/* view all posts */}
                  <Row
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "1rem"
                    }}
                  >
                    <Col span={12}><h3>{values?.post_count} posts for {searchTxt}</h3></Col>
                    {values?.post_count > 0 && <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <span style={{ color: '#005574', cursor: 'pointer' }} onClick={() => setActiveKey('2')}>View All Posts</span>
                    </Col>}
                  </Row>
                  {allPosts ? (
                    <List
                      dataSource={allPosts.slice(0, 5)}
                      renderItem={renderPost}
                    />
                  ) : (
                    <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    </div>
                  )}
                  {/* <Row style={{ margin: '1rem 0' }}>
                                        <Col style={{ display: 'flex', justifyContent: 'center',width:'100%',color:'#3f82a6' }}>
                                            <p><ReloadOutlined translate={undefined} /> Load More Posts</p>
                                        </Col>
                                    </Row> */}
                  <Divider />
                  {/* view all profiles */}
                  <Row
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      //   padding: "1rem",
                      marginTop: '1rem'
                    }}>
                    <Col span={12}><h3>{values?.personal_accounts_count} Personal Profiles for {searchTxt}</h3></Col>
                    {values?.personal_accounts_count > 0 && <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>

                      <span style={{ color: '#005574', cursor: 'pointer' }} onClick={() => setActiveKey('3')}>View All Personal Profiles</span>
                    </Col>}
                  </Row>
                  <Row>
                    {personalProfiles ? (
                      <List
                        style={{ backgroundColor: values?.personal_accounts_count > 0 ? "white" : '', borderRadius: 4, marginTop: 10, width: '100%' }}
                        dataSource={personalProfiles.slice(0, 5)}
                        renderItem={(item: any) => {
                          return (
                            <List.Item
                              className="listStyles"
                              onClick={() => handleUserRequest(item?.account?.data?.attributes)}
                              style={{ width: '100%' }}
                            >
                              <List.Item.Meta
                                avatar={
                                  <Avatar
                                    src={item?.account?.data?.attributes?.photo ? item?.account?.data?.attributes?.photo : user}
                                    size={40}
                                    style={{ marginLeft: "16px", backgroundColor: "lightgrey" }}
                                  />
                                }
                                title={
                                  <Title
                                    level={4}
                                    onClick={item?.account?.data?.attributes?.role === "business" ? () => handleOpenOtherBusinessView(item?.account?.data?.attributes?.account_id) : () => handleOpenOtherPersonalView(item?.account?.data?.attributes?.account_id)}

                                    className="margin-top5"
                                    style={{ marginRight: "5px" }}
                                  >
                                    {item?.account?.data?.attributes?.first_name ? item?.account?.data?.attributes?.first_name : item?.account?.data?.attributes?.full_name}
                                  </Title>
                                }
                                description={
                                  <p className="margin-top-10">{item?.account?.data?.attributes?.profile?.data?.attributes?.domain_skills ? getDomainSkills(item?.account?.data?.attributes?.profile?.data?.attributes?.domain_skills) : ' Lorem Ipsum has been the industry.'}</p>
                                  // item?.account?.data?.attributes?.designation && (
                                  // <p className="margin-top-10">{item?.account?.data?.attributes?.designation ? item?.account?.data?.attributes?.designation : ' Lorem Ipsum has been the industry'}</p>
                                  // )
                                }
                                style={{ alignItems: "center", width: '100%' }}
                              />
                            </List.Item>
                          );
                        }}
                      />) : (
                      <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                      </div>
                    )}
                  </Row>
                  {/* <Row style={{ margin: '1rem 0' }}>
                                        <Col style={{ display: 'flex', justifyContent: 'center', width: '100%',color:'#3f82a6' }}>
                                            <p><ReloadOutlined translate={undefined} /> Load More Personal Profiles</p>
                                        </Col>
                                    </Row> */}
                  <Divider />
                  {/* <hr /> */}
                  {/* view all business profiles */}
                  <Row
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "1rem",
                      marginTop: '1rem'
                    }}
                  >
                    <Col span={12}><h3>{values?.business_accounts_count} Business Profiles for {searchTxt}</h3></Col>
                    {values?.business_accounts_count > 0 && <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      {/* <Link to='/MyBusiness/Profile'>View All Business Profiles</Link> */}
                      <span style={{ color: '#005574', cursor: 'pointer' }} onClick={() => setActiveKey('4')}>View All Business Profiles</span>
                    </Col>}
                  </Row>
                  <Row>
                    {businessProfiles ? (
                      <List
                        style={{ backgroundColor: values?.business_accounts_count > 0 ? "white" : '', borderRadius: 4, marginTop: 10, width: '100%' }}
                        dataSource={businessProfiles.slice(0, 5)}
                        renderItem={(item: any) => {
                          return (
                            <List.Item
                              className="listStyles"
                              onClick={() => handleUserRequest(item?.account?.data?.attributes)}
                              style={{ width: '100%' }}
                            >
                              <List.Item.Meta
                                avatar={
                                  <Avatar
                                    src={item?.account?.data?.attributes?.photo ? item?.account?.data?.attributes?.photo : user}
                                    size={40}
                                    style={{ marginLeft: "16px", backgroundColor: "lightgrey" }}
                                  />
                                }
                                title={
                                  <Title
                                    level={4}
                                    onClick={item?.account?.data?.attributes?.role === "business" ? () => handleOpenOtherBusinessView(item?.account?.data?.attributes?.account_id) : () => handleOpenOtherPersonalView(item?.account?.data?.attributes?.account_id)}

                                    className="margin-top5"
                                    style={{ marginRight: "5px" }}
                                  >
                                    {item?.account?.data?.attributes?.profile?.data?.attributes?.company_name ? item?.account?.data?.attributes?.profile?.data?.attributes?.company_name : item?.account?.data?.attributes?.first_name}
                                  </Title>
                                }
                                description={
                                  <p className="margin-top-10">{item?.account?.data?.attributes?.profile?.data?.attributes.business ? item?.account?.data?.attributes?.profile.data.attributes.business + ', ' + item?.account?.data?.attributes?.profile?.data?.attributes?.business_industry : ' Lorem Ipsum has been the industry'}</p>
                                  // item?.account?.data?.attributes?.designation && (
                                  // <p className="margin-top-10">{item?.account?.data?.attributes?.profile?.data?.attributes.business ? item?.account?.data?.attributes?.profile?.data?.attributes.business + ' and ' + item?.account?.data?.attributes?.profile?.data?.attributes.business_industry : ' Lorem Ipsum has been the industry'}</p>
                                  // )
                                }
                                style={{ alignItems: "center", width: '100%' }}
                              />
                            </List.Item>
                          );
                        }}
                      />) : (
                      <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                      </div>
                    )}
                  </Row>
                  {/* <Row style={{ margin: '1rem 0' }}>
                                        <Col style={{ display: 'flex', justifyContent: 'center',width:'100%',color:'#3f82a6' }}>
                                            <p><ReloadOutlined translate={undefined} /> Load More Business Profiles</p>
                                        </Col>
                                    </Row> */}
                  <Divider />

                  {/* view all communitie profiles */}
                  <Row
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "1rem",
                      marginTop: '1rem'
                    }}
                  >
                    <Col span={12}><h3>{values?.community_count} Communities for {searchTxt}.</h3></Col>
                    {values?.community_count > 0 && <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      {/* <Link to='/MyBusiness/Profile'>View All Communitie Profiles</Link> */}
                      <span style={{ color: '#005574', cursor: 'pointer' }} onClick={() => setActiveKey('5')}>View All Communities</span>
                    </Col>}
                  </Row>
                  <Row>
                    {communityProfiles ? (
                      <List
                        style={{ backgroundColor: values?.community_count ? "white" : '', borderRadius: 4, marginTop: 10, width: '100%' }}
                        dataSource={communityProfiles ? communityProfiles.slice(0, 5) : null}
                        renderItem={(item: any) => {
                          return (
                            <List.Item className="listStyles" onClick={() => redirectUserCondition('community', item.id)} style={{ width: '100%' }}>
                              <List.Item.Meta
                                avatar={
                                  <Avatar
                                    src={item?.attributes?.profile_pic ? item?.attributes?.profile_pic : user}
                                    size={40}
                                    style={{ marginLeft: "16px", backgroundColor: "lightgrey" }}
                                  />
                                }
                                title={
                                  <Title
                                    level={4}
                                    className="margin-top5"
                                    style={{ marginRight: "5px" }}
                                  >
                                    {item?.attributes?.name ? item?.attributes?.name : 'name'}
                                  </Title>
                                }
                                description={
                                  <p className="margin-top-10">{item?.attributes?.topics ? item?.attributes?.topics : 'description'}</p>
                                  // item?.data?.attributes?.designation && (
                                  //   <p className="margin-top-10">{item?.account?.data?.attributes?.profile?.data?.attributes.business ? item?.account?.data?.attributes?.profile?.data?.attributes.business+' and '+ item?.account?.data?.attributes?.profile?.data?.attributes.business_industry: ' Lorem Ipsum has been the industry'}</p>
                                  // )
                                }
                                style={{ alignItems: "center", width: '100%' }}
                              />
                            </List.Item>
                          );
                        }}
                      />
                    ) : (
                      <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                      </div>
                    )}
                  </Row>
                  {/* <Row style={{ margin: '1rem 0' }}>
                                        <Col style={{ display: 'flex', justifyContent: 'center',width:'100%',color:'#3f82a6' }}>
                                            <p><ReloadOutlined translate={undefined} /> Load More Business Profiles</p>
                                        </Col>
                                    </Row> */}
                </TabPane>
                <TabPane tab="Posts" key="2" style={{ background: "#f6f6f6", minHeight: '78vh', height: '100%' }}>
                  <Row
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "1rem",
                      marginTop: '1rem',

                    }}
                  >
                    <Col span={12}><h3>{values?.post_count} Posts for {searchTxt}.</h3></Col>
                  </Row>
                  {allPosts ? (
                    <List
                      dataSource={allPosts}
                      renderItem={renderPost}
                    />
                  ) : (
                    <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    </div>
                  )}
                  {/* <div style={{display:'flex',justifyContent:'center',width:'100%'}}>
                                              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                                    </div> */}
                </TabPane>
                <TabPane
                  tab="Personal"
                  key="3"
                  style={{ background: "#f6f6f6", minHeight: '78vh', height: '100%' }}
                >
                  <Row
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "1rem",
                      marginTop: '1rem'
                    }}
                  >
                    <Col span={12}><h3>{values?.personal_accounts_count} Personal Profiles for {searchTxt}</h3></Col>
                  </Row>
                  {personalProfiles ? (
                    <List
                      style={{ backgroundColor: values?.personal_accounts_count > 0 ? "white" : '', borderRadius: 4, marginTop: 10, width: '100%' }}
                      dataSource={personalProfiles}
                      renderItem={(item: any) => {
                        return (
                          <List.Item
                            className="listStyles"
                            onClick={() => handleUserRequest(item?.account?.data?.attributes)}
                            style={{ width: '100%' }}
                          >
                            <List.Item.Meta
                              avatar={
                                <Avatar
                                  src={item?.account?.data?.attributes?.photo ? item?.account?.data?.attributes?.photo : user}
                                  size={40}
                                  style={{ marginLeft: "16px", backgroundColor: "lightgrey" }}
                                />
                              }
                              title={
                                <Title
                                  level={4}
                                  onClick={item?.account?.data?.attributes?.role === "business" ? () => handleOpenOtherBusinessView(item?.account?.data?.attributes?.account_id) : () => handleOpenOtherPersonalView(item?.account?.data?.attributes?.account_id)}
                                  className="margin-top5"
                                  style={{ marginRight: "5px" }}
                                >
                                  {item?.account?.data?.attributes?.first_name ? item?.account?.data?.attributes?.first_name : item?.account?.data?.attributes?.full_name}
                                </Title>
                              }
                              description={
                                <p className="margin-top-10">{item?.account?.data?.attributes?.profile?.data?.attributes?.domain_skills ? getDomainSkills(item?.account?.data?.attributes?.profile?.data?.attributes?.domain_skills) : ' Lorem Ipsum has been the industry.'}</p>
                                // item?.account?.data?.attributes?.designation && (
                                // <p className="margin-top-10">{item?.account?.data?.attributes?.designation ? item?.account?.data?.attributes?.designation : ' Lorem Ipsum has been the industry'}</p>
                                // )
                              }
                              style={{ alignItems: "center", width: '100%' }}
                            />
                          </List.Item>
                        );
                      }}
                    />) : (
                    <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    </div>
                  )}
                  {/* <div style={{display:'flex',justifyContent:'center',width:'100%'}}>
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        </div> */}
                </TabPane>
                <TabPane
                  tab="Business"
                  key="4"
                  style={{ background: "#f6f6f6", minHeight: '78vh', height: '100%' }}
                >
                  <Row
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "1rem",
                      marginTop: '1rem'
                    }}
                  >
                    <Col span={12}><h3>{values?.business_accounts_count} Business Profiles for {searchTxt}</h3></Col>
                    {/* <Col span={12} style={{display:'flex',justifyContent:'flex-end'}}><Link to='/MyBusiness/Profile'>View All Communitie Profiles</Link></Col> */}
                  </Row>
                  {businessProfiles ? (
                    <List
                      style={{ backgroundColor: values?.business_accounts_count > 0 ? "white" : '', borderRadius: 4, marginTop: 10, width: '100%' }}
                      dataSource={businessProfiles}
                      renderItem={(item: any) => {
                        return (
                          <List.Item
                            className="listStyles"
                            onClick={() => handleUserRequest(item?.account?.data?.attributes)}
                            style={{ width: '100%' }}
                          >
                            <List.Item.Meta
                              avatar={
                                <Avatar
                                  src={item?.account?.data?.attributes?.photo ? item?.account?.data?.attributes?.photo : user}
                                  size={40}
                                  style={{ marginLeft: "16px", backgroundColor: "lightgrey" }}
                                />
                              }
                              title={
                                <Title
                                  onClick={item?.account?.data?.attributes?.role === "business" ? () => handleOpenOtherBusinessView(item?.account?.data?.attributes?.account_id) : () => handleOpenOtherPersonalView(item?.account?.data?.attributes?.account_id)}
                                  level={4}
                                  className="margin-top5"
                                  style={{ marginRight: "5px" }}
                                >
                                  {item?.account?.data?.attributes?.first_name ? item?.account?.data?.attributes?.first_name : item?.account?.data?.attributes?.full_name}
                                </Title>
                              }
                              description={
                                <p className="margin-top-10">{item?.account?.data?.attributes?.profile?.data?.attributes.business ? item?.account?.data?.attributes?.profile.data.attributes.business + ', ' + item?.account?.data?.attributes?.profile?.data?.attributes?.business_industry : ' Lorem Ipsum has been the industry'}</p>
                                // item?.account?.data?.attributes?.designation && (
                                // <p className="margin-top-10">{item?.account?.data?.attributes?.profile?.data?.attributes.business ? item?.account?.data?.attributes?.profile?.data?.attributes.business + ' and ' + item?.account?.data?.attributes?.profile?.data?.attributes.business_industry : ' Lorem Ipsum has been the industry'}</p>
                                // )
                              }
                              style={{ alignItems: "center", width: '100%' }}
                            />
                          </List.Item>
                        );
                      }}
                    />) : (
                    <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    </div>
                  )}
                  {/* <div style={{display:'flex',justifyContent:'center',width:'100%'}}>
                                              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                                    </div> */}
                </TabPane>
                <TabPane
                  tab="Community"
                  key="5"
                  style={{ background: "#f6f6f6", minHeight: '78vh', height: '100%' }}
                >
                  {/* view all communitie profiles */}
                  <Row
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "1rem",
                      marginTop: '1rem'
                    }}
                  >
                    <Col span={12}><h3>{values?.community_count} Communities for {searchTxt}</h3></Col>
                    {/* <Col span={12} style={{display:'flex',justifyContent:'flex-end'}}><Link to='/MyBusiness/Profile'>View All Communitie Profiles</Link></Col> */}
                  </Row>
                  <Row>
                    {communityProfiles ? (
                      <List
                        style={{ backgroundColor: values?.community_count > 0 ? "white" : '', borderRadius: 4, marginTop: 10, width: '100%' }}
                        dataSource={communityProfiles ? communityProfiles : null}
                        renderItem={(item: any) => {
                          return (
                            <List.Item className="listStyles" onClick={() => redirectUserCondition('community', item.id)} style={{ width: '100%' }}>
                              <List.Item.Meta
                                avatar={
                                  <Avatar
                                    src={item?.attributes?.profile_pic ? item?.attributes?.profile_pic : user}
                                    size={40}
                                    style={{ marginLeft: "16px", backgroundColor: "lightgrey" }}
                                  />
                                }
                                title={
                                  <Title
                                    level={4}
                                    className="margin-top5"
                                    style={{ marginRight: "5px" }}
                                  >
                                    {item?.attributes?.name ? item?.attributes?.name : 'name'}
                                  </Title>
                                }
                                description={
                                  <p className="margin-top-10">{item?.attributes?.topics ? item?.attributes?.topics : 'description'}</p>
                                  // item?.data?.attributes?.designation && (
                                  //   <p className="margin-top-10">{item?.account?.data?.attributes?.profile?.data?.attributes.business ? item?.account?.data?.attributes?.profile?.data?.attributes.business+' and '+ item?.account?.data?.attributes?.profile?.data?.attributes.business_industry: ' Lorem Ipsum has been the industry'}</p>
                                  // )
                                }
                                style={{ alignItems: "center", width: '100%' }}
                              />
                            </List.Item>
                          );
                        }}
                      />
                    ) : (
                      <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                      </div>
                    )}
                  </Row>
                  {/* <Row style={{ margin: '1rem 0' }}>
                                        <Col style={{ display: 'flex', justifyContent: 'center',width:'100%',color:'#3f82a6' }}>
                                            <p><ReloadOutlined translate={undefined} /> Load More Business Profiles</p>
                                        </Col>
                                    </Row> */}
                </TabPane>
              </Tabs>
            </div>
            {/* <Row>
                            </Row> */}
          </Col>
          <Col
            md={24}
            span={7}
            xl={7}
            lg={7}
            xs={24}
            style={{
              paddingRight: "16px",
              paddingLeft: "16px",
            }}
          >
            <CommonListItem
              isLoading={loadpeoplemayKnow}
              data={peoplemayKnow?.accounts?.data ? peoplemayKnow?.accounts?.data : []}
              onReload={() => getPeopleYouknow()}
              onViewAll={() => {
                infoComingSoon()
                // props.history.push({
                //   pathname: "/AllView/InvitePeople",
                //   // state: newData,
                // });
                props.history.push('/myNetwork/you_may_know')
              }}
              label={"People you know"}
              isFromKnownPeople={true}
              onClickInvitation={handleConnectionModal}
              onItemPress={(id: any) => onItemPress(id)}

            />
            <CommonListItem
              isLoading={loadInvitations}
              data={invitations?.data ? invitations?.data : []}
              // data={this.state.knownPeople}
              onViewAll={() => {
                // infoComingSoon()
                // props.history.push({
                //   pathname: "/AllView/Invitation",
                //   // state: newData,
                // });
                props.history.push('/myNetwork/invitation')
              }}
              label={"Invitations"}
              isInvitation={true}
              onClickInvitation={handleInvitationModal}
              onItemPress={(id: any) => onItemPress(id)}

            />
            {showInvitationModal()}
            {showConnectionModal()}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default SearchBlockWeb;
