// Customizable Area Start
import React from "react";
import {
  Badge,
  Row,
  Empty,
  Avatar,
  List,
  Button,
  Typography,
  Spin
} from "antd";
const { Text } = Typography;

import AllCommunityController from "./AllCommunityController";
import CreateAndEditCommunity from "./CreateAndEditCommunity.web";
import { ApiDataType } from "./interface";
// Customizable Area End


export default class AllCommunity extends AllCommunityController {
  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      <div
        className="searchStyles"
        style={{ background: "#f6f6f6", minHeight: "100vh" }}
      >
        {this.redirectUser()}

        <div style={{ padding: '1rem' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <h2>{this.state.communityHeading ? this.state.communityHeading : 'All Community Forum'}</h2>
            </div>
            {this.state.communityHeading === 'All Community Forum' && (
              <div data-testid="buttonView">
                <Button
                  size="large"
                  data-testid="submitbutton"
                  className="create-community-button"
                  style={{ marginTop: 0 }}
                  onClick={() => { this.handleModalOpen() }}
                >
                  Create Community
                </Button>

                {this.state.isCreateCommunityVisible && <div data-testid="modaltest">
                  <CreateAndEditCommunity
                    isEditCommunityVisible={false}
                    isCreateCommunityVisible={this.state.isCreateCommunityVisible}
                    handleCancel={this.handleCancelOfCreateCommunity}
                    handleOk={this.handleOkOfCreateCommunity}
                    isCreateCommunity={this.state.isCreateCommunity}
                    handleCreateAndEditCommunity={this.handleCreateCommunity}
                  />
                </div>}
              </div>
            )}
          </div>

          <Row >
            {this.state.communityList.length > 0 ? (
              <div
                style={{
                  width: "100%"
                }}
                data-testid="showCommunityList"
              >
                <List
                  style={{
                    borderRadius: 4,
                    width: "100%",
                  }}
                  data-testid="List"

                  dataSource={this.state.communityList}
                  renderItem={(item: ApiDataType, index: number) => {
                    return (
                      <List.Item
                        key={item.id}
                        data-testid={`ListItem${index}`}
                        style={{ width: "100%", cursor: "pointer", padding: "10px 15px" }}
                        onClick={() => this.props.history?.push({
                          pathname: '/community/block',
                          state: {
                            communityId: item.id,
                            communityData: item
                          }
                        })
                        }
                      >
                        <List.Item.Meta
                          className="AllCommunityList"
                          avatar={
                            <Avatar
                              src={item.attributes.profile_pic}
                              size={40}
                            />
                          }
                          title={item.attributes.name}
                          description={
                            item.attributes.topics
                          }
                          style={{ alignItems: "center", width: "100%" }}
                        />
                        {this.state.communityHeading == 'Community people also follow' || 'All Community Forum' ? (
                          <Button
                            type="default"
                            shape="round"
                            className="follow-outline"
                          >
                            <Text className="follow-label">Visit</Text>
                          </Button>
                        ) : (
                          item.attributes.posts_data.unread > 0 && (
                            <Badge
                              style={{
                                alignItems: "center",
                                padding: 0,
                                marginRight: "16px",
                              }}
                              count={item.attributes.posts_data.unread}
                            />
                          )
                        )}
                      </List.Item>
                    );
                  }}
                />
                <div
                  style={{
                    width: "100%",
                    padding: "20px 0px 0px 0px",
                    textAlign: "center",
                    cursor: "pointer",
                    color: "#005574"
                  }}
                  onClick={this.hanleLoadmore}
                  data-testid="load-more-communities"

                >
                  {this.state.clickToSeeMoreLoading ?
                    <Spin /> :
                    this.state.clickToSeeMoreShow && "Click to see more community"}
                </div>
              </div>
            ) :
              this.state.loadCommunity ?
                <Spin />
                : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "100%"
                    }}
                  >
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  </div>
                )
            }
          </Row>
        </div>

      </div>
    );
  }
}
