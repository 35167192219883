import React, { useState, useEffect } from 'react'
import { Row, Col, Modal, Button } from 'antd';
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import ProfileRatingViewsPercentage from './ProfileRatingViewsPercentage.web';
import ProfileRatingAppliedProgressBar from './profileRatingAplliedProgressBar.web';
import { AlertMessage } from "../../../alert/src/AlertMessage.web";
import NPSChartView from './NPS_Chart.web';
import {
    Typography,
    CircularProgress,
    Box
} from "@material-ui/core";
// Customizable Area Start
import { Progress } from 'antd';
//Customizable Area End

const RatingView = (props: any) => {
    // Customizable Area Start

    let initialRating = {
        detractors: 0,
        passives: 0,
        promoters: 0,
        noValue: 1,
        score: '12'
    }
    const [modal, setModal] = useState(false)
    const [activeTab, setActiveTab] = useState<any>({})
    const [npsData, setNpsData] = useState<any>({})
    const { data, isPrivate } = props;
    const progressLinearBarData = data?.role === "business" ? data?.rating?.data?.attributes?.rating['General Business Rating'] : data?.rating?.data?.attributes?.rating['General Personal Rating'];
    const progressCircleBarData = [
        {
            id: 1,
            title: data?.role === "business" ? "Customer View" : "Leadership View",
            ratingName: data?.role === "business" ? "Customer Rating" : "Leadership View",
            subTitle: data?.role === "business" ? "Customer View" : "Leadership View",
            value: data?.role === "business" ? Math.round(data?.rating?.data?.attributes?.rating['Business Customer'][7]?.score) : Math.round(data?.rating?.data?.attributes?.rating['Leader'][7]?.score),
            size: 108,
            data: data?.role === "business" ? data?.rating?.data?.attributes?.rating['Business Customer'] : data?.rating?.data?.attributes?.rating['Leader']
        },
        {
            id: 2,
            title: data?.role === "business" ? "Supplier View" : "Team View",
            ratingName: data?.role === "business" ? "Supplier Rating" : "Team View",
            subTitle: data?.role === "business" ? "Supplier View" : "Team View",
            value: data?.role === "business" ? Math.round(data?.rating?.data?.attributes?.rating['Supplier'][7]?.score) : Math.round(data?.rating?.data?.attributes?.rating['Team'][7]?.score),
            size: 108,
            data: data?.role === "business" ? data?.rating?.data?.attributes?.rating['Supplier'] : data?.rating?.data?.attributes?.rating['Team']
        },
        {
            id: 3,
            title: data?.role === "business" ? "Employees View" : "Customer View",
            ratingName: data?.role === "business" ? "Employees Rating" : "Customer View",
            subTitle: data?.role === "business" ? "Employees View" : "Customer View",
            value: data?.role === "business" ? Math.round(data?.rating?.data?.attributes?.rating['Employee'][7]?.score) : Math.round(data?.rating?.data?.attributes?.rating['Personal Customer'][7]?.score),
            size: 108,
            data: data?.role === "business" ? data?.rating?.data?.attributes?.rating['Employee'] : data?.rating?.data?.attributes?.rating['Personal Customer']
        },
        {
            id: 4,
            title: data?.role === "business" ? "Community View" : "Community View",
            ratingName: data?.role === "business" ? "Community Rating" : "Community View",
            subTitle: data?.role === "business" ? "Community View" : "Community View",
            value: data?.role === "business" ? Math.round(data?.rating?.data?.attributes?.rating['Business Community'][7]?.score) : Math.round(data?.rating?.data?.attributes?.rating['Personal Community'][7]?.score),
            size: 108,
            data: data?.role === "business" ? data?.rating?.data?.attributes?.rating['Business Community'] : data?.rating?.data?.attributes?.rating['Personal Community']
        }
    ];

    useEffect(() => {
        setNpsData(initialRating)
    }, [])


    return (
        <>
            <div
                style={{
                    width: "100%",
                    height: "100%",
                    marginBottom: "1rem"
                }}
            >
                <Row justify="center" style={{ width: "100%", flexDirection: "column" }}>
                    {/* <Col xs={{ span: 24 }} lg={{ span: 24 }} style={{ width: "100%" }}>
                    <div style={{ width: "100%", display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "space-around" }}>
                        {
                            progressCircleBarData.map((item) => {
                                return (
                                    <div
                                        style={{
                                            cursor: "pointer",
                                        }}
                                        key={item.id}
                                        onClick={()=>{
                                            
                                            if(isPrivate){
                                                setModal(true)
                                                // AlertError({
                                                //     title:''
                                                // })
                                            }
                                        }}
                                    // onClick={() => handleRatingView(item.data, item.ratingName)}
                                    >
                                        <ProfileRatingViewsPercentage
                                            title={item.title}
                                            value={item.value}
                                            subTitle={item.subTitle}
                                            color={item.value < 59 ? "#b24040" : item.value < 79 ? "#3a87a8" : "#3c9b83"}
                                            size={item.size}
                                        />
                                    </div>)
                            })
                        }
                        <NPSChartView />
                    </div>
                </Col> */}
                </Row>
                <br />
                <div style={{ height: '100%' }}>
                    <Row gutter={16}>
                        <Col
                            xs={{ span: 24 }}
                            lg={{ span: 12 }}

                        >
                            <div
                                style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}
                            >
                                <h4 style={{ color: "rgba(0, 0, 0)", fontWeight: 600, fontSize: "15px" }}>Applied View</h4>
                                <Typography variant='body2' style={{ opacity: 0.9, fontSize: "14px" }}>
                                    Applied Score: <span style={{ color: "#3a87a8", fontWeight: "bold" }}>
                                        {
                                            data?.role === "business" ?
                                                Math.round(data?.rating?.data?.attributes?.rating['General Business Rating'][7]?.score)
                                                : Math.round(data?.rating?.data?.attributes?.rating['General Personal Rating'][7]?.score)
                                        }%</span>
                                </Typography>
                            </div>
                            <div
                                style={{
                                    width: "100%",
                                    borderRadius: "5px",
                                    overflow: "hidden",
                                    padding: "25px 30px 13px 30px",
                                    backgroundColor: "rgba(255, 255, 255, 1)"
                                }}
                            >
                                {
                                    progressLinearBarData?.map((item: any, id: number) => {
                                        if (item.name) {
                                            return (
                                                <div
                                                    className="other_Rating"
                                                    key={id}
                                                    onClick={() => {
                                                        // console.log(item.score, "asdgadghal llasdla")
                                                        if (item.score) {
                                                            setNpsData({ ...item.nps_rating[0], score: item.score })
                                                        } else {
                                                            setNpsData(initialRating)
                                                        }
                                                    }}
                                                >
                                                    <ProfileRatingAppliedProgressBar
                                                        key={id}
                                                        description={item.description}
                                                        title={item.name}
                                                        value={item.score === null ? 0 : Math.round(item.score)}
                                                        color={item.score < 59 ? "#b24040" : item.score < 79 ? "#3a87a8" : "#3c9b83"}
                                                    />
                                                </div>
                                            )
                                        } else {
                                            return null
                                        }
                                    })
                                }
                            </div>
                        </Col>
                        <Col
                            xs={{ span: 24 }}
                            lg={{ span: 12 }}

                        >

                            <NPSChartView
                                npsData={npsData}
                            />
                        </Col>
                    </Row>
                </div>
                <div style={{ height: '100%', width: "100%", marginTop: '24px' }} >
                    <Row>
                        <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 22 }} xl={{ span: 22 }}>
                            <Row>

                                {
                                    progressCircleBarData.map((item) => {
                                        return (
                                            <Col xs={24} md={12} xl={5} lg={6} key={item.id}>
                                                <div
                                                    style={{
                                                        background: activeTab.title === item.title ? '#dee5eb' : '#fff',
                                                        cursor: "pointer",
                                                        border: '1px solid rgb(0,0,0,0.05)'
                                                    }}
                                                    key={item.id}
                                                    onClick={() => {
                                                        setActiveTab(item)
                                                        // console.log(isPrivate)
                                                        if (isPrivate) {
                                                            setModal(true)
                                                            // AlertError({
                                                            //     title:''
                                                            // })
                                                        }
                                                    }}
                                                // onClick={() => handleRatingView(item.data, item.ratingName)}
                                                >

                                                    <ProfileRatingViewsPercentage
                                                        title={item.title}
                                                        value={item.value}
                                                        subTitle={item.subTitle}
                                                        color={item.value < 59 ? "#b24040" : item.value < 79 ? "#3a87a8" : "#3c9b83"}
                                                        size={item.size}
                                                    />

                                                </div>
                                            </Col>
                                        )
                                    })
                                }
                            </Row>
                        </Col>
                        {!isPrivate && activeTab?.data &&
                            <Col xs={24} md={24} xl={16} lg={22} >

                                <div style={{ background: '#fff', height: '100%', width: '99%' }}>
                                    <div style={{ background: "rgb(245, 245, 245)", padding: '8px 24px' }}>
                                        <h3 style={{ color: "#1890ff", fontSize: 14, }}>{activeTab.ratingName}</h3>
                                    </div>

                                    {activeTab?.data?.slice(0, activeTab?.data?.length - 1).map((item: any) => {
                                        return (
                                            <div style={{ padding: '0px 24px', display: 'flex', alignItems: 'center', marginTop: 16 }}>

                                                <Box position="relative" display="inline-flex"
                                                    onClick={() => {
                                                        // console.log(item.nps_rating[0])
                                                        // console.log(item.score, "asdgadghal llasdla")
                                                        if (item.score) {
                                                            setNpsData({ ...item.nps_rating[0], score: item.score })
                                                        } else {
                                                            setNpsData(initialRating)
                                                        }
                                                    }}
                                                >
                                                    {/* <CircularProgress
                            variant="determinate"
                            value={item.score}
                            // color="red"
                            onClick={()=>{
                                // console.log(item.nps_rating[0])
                                setNpsData({...item.nps_rating[0],score:item.score})
                            }}
                            size={45}
                            thickness={3}
                            style={{
                                color: item.score < 59 ? "#b24040" : item.score < 79 ? "#3a87a8" : "#3c9b83",
                                zIndex: 1,
                                cursor:'pointer'
                            }}
                            {...props}
                        // className={classes.bottom}
                        /> */}
                                                    <Progress
                                                        type="circle"
                                                        strokeColor={item.score < 59 ? "#b24040" : item.score < 79 ? "#3a87a8" : "#3c9b83"}
                                                        strokeWidth={9}
                                                        width={50}

                                                        className="rating-progress"
                                                        percent={Math.round(item.score)}
                                                        style={{
                                                            cursor: 'pointer',
                                                            fontSize: 11,
                                                            fontWeight: 600
                                                        }}
                                                    />
                                                    {/* <Box
                            top={0}
                            left={0}
                            bottom={0}
                            right={0}
                            position="absolute"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            style={{
                                background: '#fff',
                                borderRadius: '50%',
                            }}
                        > */}
                                                    {/* <Box
                                component="div"
                                style={{ lineHeight: "1rem", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}
                            // color="textSecondary"
                            >
                             

                                <Typography
                                    // variant="h6"
                                    style={{ fontWeight: "bold",fontSize:12}}
                                >
                                    {Math.round(item.score)}%
                                </Typography> */}
                                                    {/* </Box> */}
                                                    {/* </Box> */}
                                                </Box>
                                                <h3 style={{ marginLeft: '20px', color: "rgba(0, 0, 0, 0.6)", fontSize: 14, fontWeight: 'bold' }}>{item?.name}</h3>

                                            </div >

                                        )
                                    })}
                                    <div style={{ marginBottom: '16px' }}></div>

                                    {/* <div style={{padding:'16px 24px',display:'flex',alignItems:'center'}}>
                 <Box position="relative" display="inline-flex">
                        <CircularProgress
                            variant="determinate"
                            value={45}
                            // color="red"
                            size={60}
                            thickness={3}
                            style={{
                                border:'yellow',
                                // color: 'red',
                                zIndex: 1
                            }}
                        // className={classes.bottom}
                        />
                        <Box
                            top={0}
                            left={0}
                            bottom={0}
                            right={0}
                            position="absolute"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            style={{
                                background: '#cfcece',
                                borderRadius: '50%'
                            }}
                        >
                            <Box
                                component="div"
                                style={{ lineHeight: "1rem", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}
                            // color="textSecondary"
                            >
                                {/* {
                                    subTitle.split(" ").map((item: string, id: number) => {
                                        return (
                                            <Typography
                                                key={id}
                                                variant="caption"
                                                style={{ fontSize: "11px" }}
                                            >
                                                {item}
                                            </Typography>
                                        )
                                    })
                                } */}

                                    {/* <Typography
                                    variant="h6"
                                    style={{ fontWeight: "bold",fontSize:14}}
                                >
                                    {Math.round(0)}%
                                </Typography>
                            </Box>
                        </Box> */}
                                    {/* </Box>
                    <h4 style={{marginLeft:'20px', color: "rgba(0, 0, 0, 0.85)",fontWeight:500}}>asdkjfasl ajdkjla  asdjahsjdlg lasdgla jdga ajdlka</h4>
                    </div>  */}
                                </div >


                            </Col >
                        }
                    </Row >
                </div >

            </div >

            <Modal
                width={375}
                style={{
                    borderRadius: "4px",
                }}

                onCancel={() => { setModal(false) }}
                footer={null}
                // placement="bottomRight"
                visible={modal}

                bodyStyle={{
                    marginTop: '12px',
                    textAlign: 'center',
                    paddingBottom: '16px',
                }}
            >
                <>
                    <CloseCircleFilled
                        translate={{}}
                        style={{
                            fontSize: '44px',
                            paddingBottom: '16px',
                            color: '#ea0c0c',
                        }}
                    />
                    <br />
                    <Typography style={{ fontSize: 18, fontWeight: 'bold' }}>
                        Establish Permission to view ratings
                    </Typography>

                    <Typography style={{ color: 'grey', opacity: 0.6, fontSize: 14, padding: '0px 10px' }}>
                        You don't have permission to view ratings.
                        Please establish connection to view ratings.
                    </Typography>
                </>
            </Modal>
        </>
    )
    // Customizable Area End
}

export default RatingView;
