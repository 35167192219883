import React from "react";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Redirect } from "react-router-dom";
import { RouterProps } from "react-router";
export const configJSON = require("./config");

export interface Props extends RouterProps {
    // Customizable Area Start
    communityId?: number | string;
    // Customizable Area End
}

interface S {
    // Customizable Area Star
    redirect: boolean,
    isLoading: boolean,
    radioValues: boolean,
    token: string,
    deleteCommunityId: string | number;

    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    // Customizable Area End
}



export default class DeleteCommunityController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    deleteCommunityID: string = "";

    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage)
        ];

        this.state = {
            // Customizable Area Start
            redirect: false,
            isLoading: false,
            deleteCommunityId: this.props.communityId ? this.props.communityId : "",
            radioValues: true,
            token: "",
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area End
    }

    async componentDidMount() {
        let token = await localStorage.getItem("token");
        if (token && token !== null && token !== undefined) {
            this.setState({ redirect: false, token: token });
        } else {
            this.setState({ redirect: true, token: "" });
        }

    }

    redirectUser() {
        if (this.state.redirect) {
            return <Redirect to="/" />;
        }
    }

    optionList = [
        {
            id: 1,
            name: "No, Don't Delete",
            value: "no"
        },
        {
            id: 2,
            name: "Yes, Delete Community",
            value: "yes"
        }
    ]


    deleteCommunityClick = () => {
        this.setState({ isLoading: true })
        this.deleteCommunity(this.state.deleteCommunityId)
    }

    handleRadioButton = (value: string) => {
        if (value === "Yes, Delete Community") {
            this.setState({ radioValues: true });
        }
        if (value === "No, Don't Delete") {
            this.setState({ radioValues: false });
        }
    }

    deleteCommunity = (id: number | string) => {
        const token = this.state.token
        const header = {
            "Content-Type": configJSON.deleteCommunityApiContentType,
            token: token,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.deleteCommunityID = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.deleteCommunityApiEndPoint}/${id}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            header
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.deleteCommunityApiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    async receive(_from: string, message: Message) {
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );

        let responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            if (apiRequestCallId && responseJson && !responseJson?.errors) {
                if (apiRequestCallId == this.deleteCommunityID) {
                    if (responseJson.message) {
                        this.setState({
                            isLoading: false,
                        });
                        this.props.history.push({ pathname: '/community/block' });
                    } else {
                        this.setState({
                            isLoading: false,
                        });
                    }
                }
            }
        }
        // Customizable Area End
    }

}
