import React, { FunctionComponent } from 'react';
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { Typography, Button, Modal, Row, Col } from 'antd';
import 'antd/dist/antd.css';
const { Title, Text } = Typography;

export const FollowUnfollowAlert = (props: any) => {
    const { visible, handleOk, handleCancel } = props;
    return (
        <Modal
            width={350}
            style={{ borderWidth: "0px", borderRadius: "4px", overflow: "hidden" }}
            centered
            className='Logout_Popup'
            // placement="bottomRight"
            title={
                <div
                    style={{
                        width: "100%",
                        cursor: "move",
                        fontWeight: "bold",
                        borderWidth: "0px"
                    }}
                    onFocus={() => { }}
                    onBlur={() => { }}
                // end
                >
                    Unfollow
                </div>
            }
            visible={visible}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={[
                <Row>
                    <Col span={12} style={{ marginLeft: -28 }}>
                        <Button
                            size="large"
                            className="report-button"
                            key="report"
                            onClick={handleCancel}
                        >
                            Cancel
                        </Button>
                    </Col>
                    <Col span={12}>
                        <Button
                            size="large"
                            className="follow-label"
                            style={{ borderWidth: "0px" }}
                            key="report"
                            onClick={handleOk}
                        >
                            Unfollow
                        </Button>
                    </Col>
                </Row>
            ]}
        >
            <Text>Are you sure want to Unfollow?</Text>
        </Modal>
    );
};
