import React, { Component } from 'react';
import { runEngine } from '../../../framework/src/RunEngine';
import { Message } from '../../../framework/src/Message';
import MessageEnum, {
  getName,
} from '../../../framework/src/Messages/MessageEnum';
import { BlockComponent } from '../../../framework/src/BlockComponent';
import { IBlock } from '../../../framework/src/IBlock';
import confirm from '../../alert/src/alert.web';
import AlertError from '../../alert/src/alertError.web';
import { FormInstance } from "antd/lib/form";
import { RouterProps } from 'react-router';
export const configJSON = require('./config');
import { Select, message } from 'antd';
import { getStorageData, setStorageData } from '../../../framework/src/Helpers';
const { Option } = Select;
export interface Props extends RouterProps {
  navigation: any;
  id: string;
}
interface S {
  loading: boolean;
  profileLoading: boolean;
  data: any;
  industry_type: any;
  token: string | null;
  businessIndustryList: any;
  businessOwnership: any;
  noOfEmployee: any;
  selectedNoOfEmployee: any;
  profileUrl: string;
  file: any;
  company_nane: any;
  value: any;
  prev_data: any;
  category_data: [];
  category_list: object[];
  sub_category: any[];
  sub_category_data: object[];
  loadLocation: boolean;
  loadSubCategory: boolean;
  latitude: string | null;
  longitude: string | null;
  updateProfile: any;
  locationReq: boolean;
  currentYear: number;
  yearList: number[];
}
interface SS {
  id: any;
}

export default class EditBusinessBasicController extends BlockComponent<
  Props,
  S,
  SS
> {
  getBusinessCategoryCallId: any;
  postUploadProfileImageApiCallId: any;
  getBusinessPrfileCallId: any;

  formref = React.createRef();
  profile_formref = React.createRef<FormInstance>();
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.receive = this.receive.bind(this);
    this.state = {
      loading: false,
      profileLoading: true,
      data: {},
      industry_type: localStorage.getItem('industry_type')
        ? localStorage.getItem('industry_type')
        : '',
      token: localStorage.getItem('token') ? localStorage.getItem('token') : '',
      businessIndustryList: [],
      businessOwnership: [],
      noOfEmployee: [],
      selectedNoOfEmployee: 'no.',
      profileUrl: '',
      file: {},
      company_nane: '',
      value: '',
      prev_data: {},
      category_data: [],
      category_list: [],
      sub_category_data: [],
      sub_category: [],
      loadLocation: false,
      loadSubCategory: false,
      latitude: null,
      longitude: null,
      updateProfile: {},
      locationReq: true,
      currentYear: new Date().getFullYear(),
      yearList: [],
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubCategory = this.handleSubCategory.bind(this);
    this.updateSubCategories = this.updateSubCategories.bind(this);
    this.getLocation = this.getLocation.bind(this);
    this.getCordinates = this.getCordinates.bind(this);
    this.reverseGeoCodeCordinates = this.reverseGeoCodeCordinates.bind(this);
    this.handleLocationError = this.handleLocationError.bind(this);
  }

  async componentDidMount() {
    // this.getCategories();
    // let details: any = this.props.history.location.state;
    // console.log(details, "hello");
    // if (details && details != null) {
    //   this.setState({
    //     prev_data: details && details.profile,
    //     profileLoading:false
    //   }); 
    // let newData = Object.assign(
    //   {},
    //   {
    //     industry_type:
    //     details && details.industry_type
    //   },
    //   { history: details && details.history },
    //   { overview: details && details.overview }
    // );
    // this.setState({ data: newData,})
    // if (details.profile) {
    //   this.setState({
    //     prev_data: details && details.profile,
    //     profileUrl: details.profile.profileUrl,
    //   }); 
    // }
    // }else{
    let startYear = 1801;
    let year = [];
    while (startYear <= this.state.currentYear) {
      year.push(startYear);
      startYear = startYear + 1;
    }
    this.setState({ yearList: year.reverse() });
    this.getProfileData();
    this.getCategories();
    // }

  }

  handleChange(event: any) {
    console.log('@@@ e======', event);
    this.setState({ selectedNoOfEmployee: event });
  }

  updateSubCategories() {
    console.log("striking data====up");
    this.setState({ loadSubCategory: true });

    // console.log(this.state.category_data, 'demo',
    //   this.state.updateProfile.business_industry, 'business_industry', this.state.updateProfile,
    //   this.state.updateProfile.business, 'business')

    if (this.state.updateProfile?.business_industry && this.state.category_data) {
      let checkIndustry: any = this.state.category_data?.filter(
        (item: any) => item.attributes.name === this.state.updateProfile.business
      );
      // console.log('demo', checkIndustry, this.state.updateProfile?.business_industry)
      this.setState({
        sub_category: checkIndustry[0]?.attributes.sub_categories,
      });
    } else if (this.state.data.industry_type && this.state.category_data) {
      let checkIndustry: any = this.state.category_data?.filter(
        (item: any) =>
          item.attributes.name === this.state.data?.industry_type
      );
      // console.log(checkIndustry, "update data", this.state.category_data?);
      this.setState({
        sub_category: checkIndustry[0]?.attributes?.sub_categories,
      });
    } else {
      let checkIndustry: any = this.state.category_data?.filter(
        (item: any) => item.attributes.name === this.state.updateProfile?.business_industry
      );
      this.setState({
        sub_category: checkIndustry[0]?.attributes?.sub_categories,
      });
      // console.log(this.state.category_data?, 'demo',
      //   this.state.updateProfile?.business_industry, this.state.updateProfile,
      //   this.state.updateProfile.business)
      // console.log("error in loading sub_category_data");
    }
    setTimeout(() => {
      this.setState({ loadSubCategory: false });
    }, 200);
  }

  handleSubCategory(data: any) {
    console.log("striking data ==");
    let checkIndustry: any = this.state.category_data.filter(
      (item: any) => item.attributes.name === data
    );
    this.setState(
      {
        sub_category: checkIndustry[0].attributes.sub_categories,
      }
    );

    this.profile_formref?.current?.setFieldsValue({ business_industry: undefined })
    // }
  }

  getLocation() {
    this.setState({ loadLocation: true });
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        this.getCordinates,
        this.handleLocationError
      );
    } else {
      console.log("geo location is not supported in browser");
      this.setState({ loadLocation: false });
    }
  }

  getCordinates(position: any) {
    console.log(position.coords.latitude);
    this.setState({
      latitude: position.coords.latitude,
      longitude: position.coords.longitude,
      // userAddress
    });
    this.reverseGeoCodeCordinates();
  }

  async reverseGeoCodeCordinates() {
    let key = "AIzaSyCBH92YX2oCbI7PChqFNrgzw-PolHAiKZc";
    await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${this.state.latitude
      },${this.state.longitude}&sensor=false&key=${key}`
    )
      .then((response) => response.json())
      .then((data) => {
        let newData = data.results[0].address_components;
        let city = newData.filter(
          (ac: { types: string | string[] }) => ~ac.types.indexOf("locality")
        )[0].long_name;
        let country = newData.filter(
          (ac: { types: string | string[] }) => ~ac.types.indexOf("country")
        )[0].long_name;
        this.setState(
          (state) => ((state.updateProfile.location = city + ", " + country), state)
        );
        setTimeout(() => {
          this.profile_formref?.current?.setFieldsValue({
            location: city + ", " + country
          });
        }, 0);
      })
      .catch((error) => {
        this.setState({ loadLocation: false });
        alert(`error while location, ${error}`);
      });
    this.setState({ loadLocation: false });
  }

  handleLocationError(error: any) {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        this.setState({ loadLocation: false });
        alert("User denied the request for Geolocation.");
        break;
      case error.POSITION_UNAVAILABLE:
        this.setState({ loadLocation: false });
        alert("Location information is unavailable.");
        break;
      case error.TIMEOUT:
        this.setState({ loadLocation: false });
        alert("The request to get user location timed out.");
        break;
      case error.UNKNOWN_ERROR:
        this.setState({ loadLocation: false });
        alert("An unknown error occurred.");
        break;
    }
  }

  //=============@@@ Upload Profile Image==========

  getBase64(img: any, callback: any) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  async beforeUpload(file: any) {
    console.log('file==>', file);
    const isJpgOrPng = (await file.type.slice(0, 5)) == 'image';
    if (!isJpgOrPng) {
      //  message.error("You can only upload Image file!");
    } else {
      console.log('isJpgOrPng===>', isJpgOrPng);
      this.getBase64(file, (imageUrl: string) => {
        console.log('imageUrl===>', imageUrl);
        this.setState(
          {
            profileUrl: imageUrl,
            loading: false,
          },
          () => { }
        );
      });
    }
    return isJpgOrPng;
  }

  handleImageChange = (info: any) => {
    console.log("@@@ business profile data ====== info", info);
    this.setState({
      file: info.file.originFileObj,
    });
    console.log("@@@ business profile data ====== Image", this.state.file);
    // this.uploadProfileImage();
  };

  //======================@@@@ Receive Function =======================

  async receive(from: string, message: Message) {
    console.log('@@@ API Response ==========', message);
    // Get category Data=====================
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getBusinessCategoryCallId !== null &&
      this.getBusinessCategoryCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      // this.apiGetUserOtpCallId = null;

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      console.log('get all buisness', responseJson);

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      console.log('get all buisness', errorReponse);

      if (responseJson) {
        this.getbusinessCategorySuccessCallBack(responseJson);
      } else {
        this.getBusinessCategoryFailureCallBack(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }
    // Upload Profile Picture=====================
    else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.postUploadProfileImageApiCallId !== null &&
      this.postUploadProfileImageApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      // this.apiGetUserOtpCallId = null;

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      console.log("adgalgadal", responseJson.data)
      const usedata = await getStorageData('loginUser');
      console.log(usedata)
      const setUseData = { ...usedata, profile_pic: responseJson.data.attributes.photo }

      setStorageData('loginUser', setUseData)
      console.log('get all buisness', responseJson);

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      console.log('get all buisness', errorReponse);

      if (responseJson) {
        this.postUploadImageSuccessCallBack(responseJson);
      } else {
        this.postUploadImageFailureCallBack(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }
    // Get Profile Data
    else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getBusinessPrfileCallId !== null &&
      this.getBusinessPrfileCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      // this.apiGetUserOtpCallId = null;

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      console.log('get all buisness ==', responseJson);

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      console.log('get all buisness', errorReponse);

      if (responseJson) {
        this.getbusinessProfileSuccessCallBack(responseJson);
      } else {
        this.getBusinessProfileFailureCallBack(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }
  }

  //======================@@@ Success And Failure Call Back ======================

  getbusinessCategorySuccessCallBack = (responseJson: any) => {
    // console.log("@@@ initial success call============", responseJson);
    let dropDownDetail = responseJson && responseJson.data;
    // console.log("@@@@@@checkEmployment dropDownDetail=======", dropDownDetail);
    let checkBusinessInds = dropDownDetail.filter(
      (item: any) => item.attributes.name === "Business Industry"
    );
    console.log("@@@@@@checkEmployment=======", checkBusinessInds);

    let checkOwnerShip = dropDownDetail.filter(
      (item: any) => item.attributes.name === "Ownership"
    );
    console.log("@@@@@@checkEmployment=======", checkOwnerShip);

    let checkOfEmployee = dropDownDetail.filter(
      (item: any) => item.attributes.name === "No of Employees"
    );
    console.log("@@@@@@checkEmployment=======", checkOfEmployee);

    this.setState(
      {
        category_data: dropDownDetail,
        businessIndustryList: checkBusinessInds[0].attributes.sub_categories,
        businessOwnership: checkOwnerShip[0].attributes.sub_categories,
        noOfEmployee: checkOfEmployee[0].attributes.sub_categories,
      },
      () => {
        console.log(
          "@@@@@@checkEmployment=======212222222222",
          this.state.businessIndustryList,
          this.state.category_data
        );
      }
    );
    setTimeout(() => {
      console.log('calling updateSub');
      this.updateSubCategories();
    }, 1000)
  };

  getBusinessCategoryFailureCallBack = (responseJson: any) => {
    console.log(
      '@@@ Get Business Profile Failure call Back ========',
      responseJson
    );
  };

  //==========@@@ Success And Failure Call Back For upload Image==================

  postUploadImageSuccessCallBack = (responseJson: any) => {
    console.log(
      '@@@ Post Upload Profile Image success call Back ========',
      responseJson
    );
  };

  postUploadImageFailureCallBack = (responseJson: any) => {
    console.log(
      '@@@ Post Upload Profile Image Failure call Back ========',
      responseJson
    );
  };

  // @@@@ Success And Failure Call Back For Get Profile Data=================

  getbusinessProfileSuccessCallBack = (responseJson: any) => {
    // console.log(
    //   '@@@ Get Profile Data success call Back ========',
    //   responseJson?.data?.attributes?.profile?.data?.attributes
    // );
    // let company_nane =
    //   responseJson?.data?.attributes?.profile?.data?.attributes.company_name;
    // this.setState({ company_nane: company_nane });
    console.log(responseJson.data.attributes.profile.data.attributes)
    this.setState({ updateProfile: responseJson.data.attributes.profile.data.attributes, }, () => {
      // setTimeout(() => {
      //   console.log('calling updateSub');
      //   this.updateSubCategories();
      // }, 1000)
      this.updateSubCategories();
      this.setState({ profileLoading: false })
    })
  };
  getBusinessProfileFailureCallBack = (responseJson: any) => {
    console.log(
      '@@@ Get Profile Data Failure call Back ========',
      responseJson
    );
  };

  // @@@@ Handle Date of Basic Screen, navigation to next page ===================

  handleAddProfileData = (data: any) => {
    if (data.profile_pic) {
      data.profile_pic = {
        profileUrl: this.state.profileUrl,
        picFile: this.state.file
      };
    }
    // console.log('@@@ business profile data ====== data', data);
    // // console.log('@@@ business profile data ====== updateProfile', this.state.updateProfile);
    // // console.log('@@@ business profile data ====== profileUrl', this.state.profileUrl);
    // // console.log('@@@ business profile data ====== file', this.state.file);
    this.props.history.push({
      pathname: '/edit/BusinessProfile/about',
      state: { data, oldData: this.state.updateProfile },
    });
  };

  companyName = (event: any) => {
    this.setState({ value: event.target.company_nane });
  };

  //=========================@@@@ Call Get Api ============================

  getCategories = async () => {
    const header = {
      'Content-Type': 'application/json',
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getBusinessCategoryCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_categories/categories'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    console.log(
      '@@@ Request ===========',
      requestMessage,
      this.getBusinessCategoryCallId
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  //================ @@@@ Get Profile Get Api===================

  getProfileData = async () => {
    const header = {
      'Content-Type': 'application/json',
      "token": localStorage.getItem('token')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getBusinessPrfileCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_profile/profiles'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    console.log(
      '@@@ Request ===========',
      requestMessage,
      this.getBusinessPrfileCallId
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  //====================@@@ Post APi For Upload Image=======================
  uploadProfileImage = () => {
    const header = {
      // "Content-Type": "multipart/form-data",
      token: this.state.token,
    };

    console.log("this.state.profileUrl", this.state.file);

    const formData = new FormData();
    formData.append("profile_pic", this.state.file);

    console.log("@@@ upload==", formData);
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.postUploadProfileImageApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_profile/profiles/upload_profile_pic"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    console.log(
      "@@@ Request ===========",
      requestMessage,
      this.postUploadProfileImageApiCallId
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  // Customizable Area Start
  handleFormValuesChange = (changedValues: any) => {
    const formFieldName = Object.keys(changedValues)[0];
    console.log(formFieldName, 'test===')
    // if (formFieldName === "typeCeso") {
    //   setValue(changedValues[formFieldName]);
    // }
  };
  // Customizable Area End
}
