import React, { Component } from "react";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import confirm from "../../alert/src/alert.web";
import AlertError from "../../alert/src/alertError.web";
import { RouterProps } from "react-router";
export const configJSON = require("./config");
import { Select } from "antd";
const { Option } = Select;
export interface Props extends RouterProps {
  navigation: any;
  id: string;
}
interface S {
  loading: boolean;
  isRegistration: boolean;
  bussiness_profile: boolean;
  data: any;
  industry_type: any;
  token: string | null;
  category_data: [];
  category_list: object[];
  sub_category: any[];
  sub_category_data: object[];
  children: any[];
}
interface SS {
  id: any;
}

export default class CreateProfileBasicController extends BlockComponent<
  Props,
  S,
  SS
> {
  createAccountApiCallId: any;
  formref = React.createRef();
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.receive = this.receive.bind(this);
    this.state = {
      bussiness_profile: true,
      loading: true,
      isRegistration: true,
      data: {},
      industry_type: localStorage.getItem("industry_type")
        ? localStorage.getItem("industry_type")
        : "",
      token: localStorage.getItem("token") ? localStorage.getItem("token") : "",
      category_data: [],
      category_list: [],
      children: [],
      sub_category: [],
      sub_category_data: [],
    };
  }

  async componentDidMount() {
    this.getCategories();
  }
  handleCategory = () => {
    let category_list = [...this.state.category_list];
    const children = [...this.state.children];
    this.state.category_data?.map((data: any) => {
      if (data.attributes.name === "Business Industry") {
        category_list = data.attributes.sub_categories;
      }
    });
    category_list.forEach((data: any, index) => {
      children.push(
        <Option value={data.name} key={index}>
          {data.name}
        </Option>
      );
    });
    this.setState({
      children: children,
      loading: false,
    });
  };
  handleCategoryChange = (value: string) => {
    let sub_category = [...this.state.sub_category];
    let sub_category_data = [...this.state.sub_category_data];
    sub_category = [];
    sub_category_data = [];
    this.state.category_data.forEach((data: any) => {
      if (data.attributes.name === value) {
        sub_category_data = data.attributes.sub_categories;
      }
    });

    sub_category_data.forEach((data: any, index) => {
      sub_category.push(
        <Option value={data.name} key={index}>
          {data.name}
        </Option>
      );
    });
    this.setState({
      sub_category,
      sub_category_data,
    });
  };
  getCategories = () => {
    const header = {
      "Content-Type": "application/json",
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCategoryAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  async receive(from: string, message: Message) {
    console.log("receive");
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson) {
        console.table(responseJson);
        if (responseJson.data) {
          this.setState(
            {
              category_data: responseJson.data,
            },
            this.handleCategory
          );
        } else if (responseJson.errors) {
          console.log(responseJson.errors[0].token);
          AlertError(responseJson.errors[0].token);
        } else {
          console.log("something went wrong");
        }
      } else {
        console.log("hello");
      }
    }
  }
  handleAddProfileData = (data: object) => {
    this.props.history.push({
      pathname: "/profile_overview",
      state: data,
    });
  };
}
