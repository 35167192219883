import React from 'react';
import { Modal, Row, Col, Button, Typography } from 'antd';

const { Text } = Typography;

const Logout = (props: any) => {
    const { visible, handleOk, handleCancel } = props;
    return (
        <Modal
            width={350}
            style={{ borderWidth: "0px", borderRadius: "4px", overflow: "hidden" }}
            centered
            title={"Logout"}
            className="Logout_Popup"
            visible={visible}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={[
                <Row>
                    <Col span={12}>
                        <Button
                            size="large"
                            className="report-button"
                            key="report"
                            onClick={handleCancel}
                        >
                            No, Don't logout
                        </Button>
                    </Col>
                    <Col span={12}>
                        <Button
                            size="large"
                            className="follow-label"
                            style={{ borderWidth: "0px" }}
                            key="report"
                            onClick={handleOk}
                        >
                            Yes, logout
                        </Button>
                    </Col>
                </Row>
            ]}
        >
            <Text>Are you sure want to logout?</Text>
        </Modal>
    )
}

export default Logout;