import React, { Component } from "react";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { getStorageData, setStorageData } from "../../../framework/src/Helpers";
import { message } from "antd";
export const configJSON = require("./config");
import { RouterProps } from "react-router";
export interface Props extends RouterProps {
  navigation: any;
  id: string;
}
interface S {
  isFromEmail: boolean;
}
interface SS {
  id: any;
}

export default class ErrorController extends BlockComponent<Props, S, SS> {
  createAccountApiCallId: any;
  otpResendApiCallId: any;
  formref = React.createRef();
  timer: any;
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.receive = this.receive.bind(this);
    this.state = {
      isFromEmail: false,
    };
  }
  async componentDidMount() {
    let history: any = (await this.props.history)
      ? this.props.history.location.state
      : "";
    if (history) {
      this.setState({ isFromEmail: history?.isFromMail });
    }
  }
  onContinueClick = async () => {
    // if (this.state.verificationData?.role_id == 2) {
    //   this.props.history.push("/select/industry");
    // } else {
    //   this.props.history.push("/add_personal_profile");
    // }
    message.info("Coming Soon");
  };
  async receive(from: string, message: Message) {
    console.log("receive");
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId && responseJson) {
      } else {
        console.log("hello");
      }
    }
  }
}
