export const businessIndustryImages = [
  {
    id: 1,
    name: "manufacturing",
    path: require("../assets/ReactNative_BusinessIndustry_assets_f604347dd0e6f1f775860c7aebaccb1b391a5147.png"),
  },
  {
    id: 2,
    name: "education",
    path: require("../assets/ReactNative_BusinessIndustry_assets_e265699de27f038ca8ff71de8e7f4feaff812580.png"),
  },
  {
    id: 3,
    name: "Healthcare",
    path: require("../assets/ReactNative_BusinessIndustry_assets_da7d326b96c55b324bb8143c9ae3d784aad03b22.png"),
  },
  {
    id: 4,
    name: "Hospitality",
    path: require("../assets/ReactNative_BusinessIndustry_assets_76298c803d573187a4867024812c6b08685a91f7.png"),
  },
  {
    id: 5,
    name: "transportation",
    path: require("../assets/ReactNative_BusinessIndustry_assets_dbc21fa1f0972652a75a5dba35e3f210cc29eae2.png"),
  },
  {
    id: 6,
    name: "construction",
    path: require("../assets/ReactNative_BusinessIndustry_assets_516fb63e54c1f0b57dbc52bf3f4ee3399a33d132.png"),
  },
  {
    id: 7,
    name: "real estste",
    path: require("../assets/ReactNative_BusinessIndustry_assets_14cf2f1aaf26d3978bb9dc08e592c51012223ed2.png"),
  },
  {
    id: 8,
    name: "technology",
    path: require("../assets/ReactNative_BusinessIndustry_assets_3a7177310af83f9afa1bb55a7ee2bef71ff9360b.png"),
  },
  {
    id: 9,
    name: "food processing",
    path: require("../assets/ReactNative_BusinessIndustry_assets_2d19cb2ad6ccb8d55626895ed4220574b97c50dd.png"),
  },
  {
    id: 10,
    name: "retail outlet",
    path: require("../assets/ReactNative_BusinessIndustry_assets_da5f3f8c1ff21ef687bf875640f250f0ead65c93.png"),
  },
  {
    id: 11,
    name: "banking & investment",
    path: require("../assets/ReactNative_BusinessIndustry_assets_2d3e0c31984083f0127836d0e4cf07c119ed1aa0.png"),
  },
  {
    id: 12,
    name: "travel agents",
    path: require("../assets/ReactNative_BusinessIndustry_assets_c33aba43364a08bea8a1dee6759c4d3b833ec7fb.png"),
  },
];

export const mailIcon = require("../assets/mail.png");
export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const logo = require("../assets/logo1.png");
export const editLogo = require("../assets/edit-community.png");
export const connections = require("../assets/connections.png");
export const followers = require("../assets/followers.png");
export const posts = require("../assets/posts1.png");
export const following = require("../assets/following.png");
export const ratingSent = require("../assets/rating-sent.png");
export const ratingsReceived = require("../assets/ratings-received.png");


export const profileImage = require("../assets/image_profile.png");
export const editImage = require("../assets/posts-active.png");
export const unEditImage = require("../assets/posts.png");
export const saveImage = require("../assets/ratings-active.png");
export const unSaveImage = require("../assets/ratings.png");
export const userImage = require("../assets/reviews-active.png");
export const unUserImage = require("../assets/reviews.png");
export const menuImage = require("../assets/menu-black.png");
export const heartImage = require("../assets/like.png");
export const commentImage = require("../assets/comment.png");
export const shareImage = require("../assets/share.png");
export const hiringImage = require("../assets/weAreHiring.jpeg");
export const plusImage = require("../assets/ReactNative_CreateProfile01_assets_087471ac86ac0254093072aa75020d374917661c.png");
export const settingIcon = require("../assets/settings.png");
export const backIcon = require("../assets/icons8-left-24.png");
//// Edit Profile Screen
export const userProfile = require("../assets/icons8-test-account-48.png");
export const dropDown = require("../assets/icons8-expand-arrow-24.png");
export const checkBox = require("../assets/icons8-checked-checkbox-48.png");
export const unCheckBox = require("../assets/icons8-unchecked-checkbox-24.png");
export const upArrow = require("../assets/icons8-collapse-arrow-24.png");
export const cross = require("../assets/icons8-multiply-50.png");
//// Edit Experience Screen
export const checkMark = require("../assets/icons8-checkmark-24.png");
export const NETWORK_PROFILE_ING = require("../assets/createProfile.png")

export const createProfile = require("../assets/createProfile.png");

export const homeIcon = require("../assets/image_home.png");
export const chatIcon = require("../assets/image_chat.png");
export const networkIcon = require("../assets/image_network.png");
export const communityIcon = require("../assets/image_community.png");
export const user = require("../assets/workStation.jpg");

export const horizentalTripleDot = require("../assets/image_view-more.png")
