import React from "react";
// Customizable Area Start
import { horizentalTripleDot } from "../assets";
import "../profile.css";
import OtherProfileController from "../OtherProfileController.web";
import SliderView from "../CommonUI/SliderView.web";
import NPSChartView from "../CommonUI/NPS_Chart.web";
import ReviewDataView from "../CommonUI/ReviewDataView.web";
import AddReviewView from "../CommonUI/AddReviewView.web";
import { UserOutlined } from '@ant-design/icons';
import { Layout, Space, Row, Col, Image, Card, Avatar, Typography, Button, Tabs, Divider, Modal, Spin, Progress, List, Empty, Menu, Dropdown, Select } from 'antd';
import SelectCategory from "../CommonUI/SelectCategory.web";
import SendRequestModal from "../CommonUI/SendRequestModal.web";
import UserPost from "../CommonUI/UserPost.web";
import { CommonDialog } from "../CommonUI/CommonDialog.web";
import { FollowUnfollowAlert } from "../CommonUI/FollowUnfollowAlert";
import RatingView from "../CommonUI/RatingView.web";
import moment from "moment";
import { message } from "antd"
import AboutAndStats from "../CommonUI/AboutAndStats.web";
import BlockUnblockAlert from "../CommonUI/BlockUnblockAlert.web";
import ReportUser from "../CommonUI/ReportUser.web";
import { StopOutlined } from '@ant-design/icons';
// Customizable Area End
const { Text, Title, Paragraph } = Typography;
const { TabPane } = Tabs;

export default class OtherPersonalProfile extends OtherProfileController {
    // Customizable Area Start
    render() {
        const { profileData, isModal, isAddReview, isAddAppliedViewRating, isAddRatingRateAsCustomer, isRatingViewShow, ratingView, ratingViewName, ratingViewName_2, reviewData, isLoading, errorMessageOnOk, subCategory, isSelectCategoryModalVisible, nextPageLoader } = this.state;
        const account_id = profileData?.account_id;
        const { navigation } = this.props;
        console.log(profileData);
        return (
            <Layout>
                {this.redirectUser()}
                <div style={{ backgroundColor: "rgba(245, 245, 245, 1)", minHeight: '90vh', height: '100%' }}>
                    {isLoading ?
                        <div style={{ margin: 12 }}>
                            <Spin />
                        </div> :
                        <>
                            {!this.state.suspendedAccount ?
                                <Row>
                                    <Col xs={{ span: 24 }} lg={{ span: 18 }}>
                                        <Profile
                                            data={profileData}
                                            handleClick={this.handleOpenModal}
                                            handleReviewClick={this.handleOpenAddReviewModal}
                                            handleFollow={this.onHandleFollow}
                                            handleFollowing={this.handleFollowUnfollowOpen}
                                            handleConnect={this.onClickConnect}
                                            handleAddAppliedViewRatingOpen={this.handleAddAppliedViewRatingOpen}
                                            isLoading={isLoading}
                                            handleChat={this.handleChatPush}
                                            acceptRequest={this.acceptRequest}
                                            handleBlock={this.handleBlockOpen}
                                            handleReportOpen={this.handleReportOpen}
                                            handleRemoveConnection={this.handleRemoveConnection}
                                            handleSentConnectionsList={this.handleCancelConnectionRequest}
                                            nextPageLoader={nextPageLoader}
                                            downloadResumeLoading={this.state.downloadResumeLoading}
                                            downloadResumeWithAxios={this.downloadResumeWithAxios}
                                        />
                                        {!isLoading ? <UserPostRatingReview
                                            isPostView={this.state.isPostView}
                                            data={profileData}
                                            handleFollow={this.onHandleFollow}
                                            handleFollowing={this.handleFollowUnfollowOpen}
                                            isRatingVisible={this.state.isRatingVisible}
                                            activeTab={this.state.activeTabForPost}
                                            getPosts={this.getBusinessPosts}
                                            postDataArray={this.state.postsDataArray}
                                            isPrivate={this.state.isPrivate}
                                            accountId={this.state.account_id}
                                            handleRatingView={this.handleRatingView}
                                            reviewData={reviewData}
                                            isLoading={this.state.isPostLoading}
                                            noOfPostData={this.state.noOfPostData}
                                            handlePostList={this.handlePostList}
                                        /> : <Spin />}
                                    </Col>
                                    {!this.state.isPrivate &&
                                        <Col xs={{ span: 24 }} lg={{ span: 6 }}>
                                            <Experience
                                                data={profileData}
                                                isLoading={isLoading}
                                            />
                                            <Education
                                                data={profileData}
                                                isLoading={isLoading}
                                            />
                                            <Certification
                                                data={profileData}
                                                isLoading={isLoading}
                                            />
                                            <AwardsRecognition
                                                data={profileData}
                                                isLoading={isLoading}
                                            />
                                            <Patent
                                                data={profileData}
                                                isLoading={isLoading}
                                            />
                                            <Achievements
                                                data={profileData}
                                                isLoading={isLoading}
                                            />
                                            <Languages
                                                data={profileData}
                                                isLoading={isLoading}
                                            />
                                        </Col>
                                    }
                                </Row> :
                                <Row style={{ marginTop: 24, marginLeft: 18, marginRight: 18 }} gutter={[6, 6]}>
                                    <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                                        <div style={{
                                            background: "#fff",
                                            height: '100%',
                                            padding: 20,
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}>
                                            <Avatar style={{ backgroundColor: "#919191" }}
                                                size={60}
                                                src={this.state.suspendedAccountData?.photo ? this.state.suspendedAccountData?.photo :
                                                    <UserOutlined translate={undefined} />} />
                                            <div style={{ margin: "10px" }}>
                                                <Text style={{
                                                    fontSize: 17,
                                                    fontWeight: 500,
                                                }}>{this.state.suspendedAccountData?.full_name}</Text>
                                                <div>
                                                    <span style={{ color: "#da0e2a", fontSize: 13 }}><StopOutlined translate={""} /> This account is suspended by user</span>
                                                </div>

                                            </div>
                                        </div>
                                    </Col>
                                </Row>

                            }
                        </>

                    }
                </div>

                <Modal
                    width={450}
                    style={{ borderWidth: "0px", borderRadius: "4px", overflow: "hidden" }}
                    centered
                    className="Accept-request-modal"
                    title={
                        <div style={{ width: "100%", cursor: "move", fontWeight: "bold", borderWidth: "0px", paddingRight: '24px' }}>
                            {profileData?.full_name} wants to connect with you as{' '}
                            {profileData?.received_request?.sender_relation}
                        </div>}
                    visible={this.state.isShowInvitationModal}
                    bodyStyle={{ paddingTop: "5px" }}
                    onCancel={() => this.setState({ isShowInvitationModal: false })}
                    footer={[
                        <div style={{ display: 'flex', justifyContent: 'space-between', margin: '0 10px' }}>
                            <Button size="large" className="report-button" style={{ width: "45%" }} key="accept"
                                onClick={() => {
                                    if (this.state.changeRelationShipAccept) {
                                        this.setState({ isShowInvitationModal: false });
                                        this.acceptRequest({ type: "accepted", ...profileData.received_request })
                                    } else {
                                        this.setState({
                                            checkModalForAccept: true
                                        })
                                    }
                                }}
                            >Accept Request</Button>
                            <Button size="large" className="reject-button" style={{ width: "45%" }} key="reject"
                                onClick={() => {

                                    this.setState({ isShowInvitationModal: false, checkModalForAccept: false });
                                    this.acceptRequest({ type: "rejected", ...profileData.received_request })
                                }}
                            >Reject Request</Button>
                        </div>
                    ]}
                >
                    <Row gutter={12}>
                        <Col xs={24} >
                            <p>Change relationship to</p>
                            <Select
                                id={"select"}
                                style={{
                                    padding: "0 !important",
                                    border: "2px solid #a9bac8",
                                    borderRadius: '27px',
                                    overflow: "hidden",
                                    width: "100%"
                                }}
                                placeholder="Select Relationship"
                                bordered={true}
                                dropdownStyle={{
                                    border: "2px solid #a9bac8",
                                    borderRadius: "10px",
                                    paddingTop: "0px",
                                    paddingBottom: "0px",
                                    width: "100%"
                                }}
                                value={profileData?.received_request?.sender_relation}
                                onChange={this.changeInviteRelationship}
                            >
                                {this.state.loadInvitationData && this.state.loadInvitationData.map((item: any, id: number) => <option key={item.id}
                                    style={{
                                        borderBottom: this.state.loadInvitationData?.length - 1 > id ? "1px solid #a9bac8" : "none",
                                        padding: "10px 20px 10px 20px"
                                    }}
                                    value={item.name}>
                                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                        <div className="capital_options" style={{ color: "#a9bac8" }}>{item.name}</div>
                                    </div>
                                </option>)}
                            </Select>
                            {this.state.checkModalForAccept
                                && !this.state.changeRelationShipAccept &&
                                <Text style={{ color: 'red', fontSize: 14 }}>please change relationship </Text>
                            }
                        </Col>
                    </Row>
                </Modal>
                {isAddReview && <ModalAddReview
                    open={isAddReview}
                    handleCancel={this.handleAddReviewCancel}
                    data={account_id}
                    disableAnonymous={this.state.disableAnonymous}
                    hideAnonymous={this.state.hideAnonymous}
                    onFinish={this.onFinishAddReview}
                />}
                <ModalAbout
                    data={profileData}
                    open={isModal}
                    handleCancel={this.handleCancel}
                />
                <ModalAddAppliedViewRating
                    open={isAddAppliedViewRating}
                    handleCancel={this.handleAddAppliedViewRatingCancel}
                    handleSave={this.handleSaveAndNext}
                    generalRating={this.state.generalRating}
                    generalRatingChange={this.generalRatingChange}
                />
                <ModalAddRatingRateAsCustomer
                    open={isAddRatingRateAsCustomer}
                    handleCancel={this.handleAddRatingRateAsCustomerCancel}
                    handleBack={this.handleAddRatingBack}
                    userRateItems={this.state.userRateItems}
                    userRatingChange={this.userRatingChange}
                    submitUsersRating={this.submitUsersRating}
                />
                <ModalRatingView
                    open={isRatingViewShow}
                    ratingViewData={ratingView}
                    ratingViewName={ratingViewName}
                    handleRatingNPSChart={this.handleRatingNPSChart}
                    handleCancel={this.handleRatingViewCancel}
                />
                <ModalNPSChart
                    openNPS={ratingViewName_2}
                    handleCancel={this.handleNPSChartCancel}
                />
                <SendRequestModal
                    sendRequestRelations={this.state.sendRequestRelations}
                    handleChange={this.handleSelectChange}
                    isModalVisible={isSelectCategoryModalVisible}
                    handleOk={this.handleOnClickConnect}
                    handleCancel={this.handleSelectCategoryCancel}
                    errorMessage={errorMessageOnOk}
                    userName={profileData?.full_name}
                />
                <CommonDialog
                    body={"Already Reviewed!"}
                    isModalVisible={this.state.alertReview}
                    onOk={this.handleAlertReviewOk}
                />
                <CommonDialog
                    body={"Review Submitted Successfully"}
                    isModalVisible={this.state.isCompleteReview}
                    onOk={this.handleAlertReviewOk}
                />
                <FollowUnfollowAlert
                    handleOk={this.onHandleFollow}
                    handleCancel={this.handleFollowUnfollowCancel}
                    visible={this.state.followUnfollowAlert}
                />
                <BlockUnblockAlert
                    visible={this.state.blockAlertOpen}
                    handleUnblock={this.handleUnblock}
                    handleCancel={this.handleBlockCancel}
                    fullName={profileData?.full_name}
                    accountId={account_id}
                    blocked={profileData?.is_blocked}
                />
                <ReportUser
                    visible={this.state.reportOpen}
                    handleCancel={this.handleReportClose}
                    onFinish={this.onFinishReport}
                />
            </Layout >
        );
    }
    // Customizable Area End
}

//Customizable Area Start


interface MyNPSChartProps {

    openNPS?: {
        name: string,
        npsData: any
    }
    handleCancel: () => void

}

class ModalNPSChart extends React.Component<MyNPSChartProps> {

    render() {
        return <Modal
            title={
                <Space
                    style={{
                        width: "100%",
                        cursor: "move",
                        fontWeight: "bold",
                        color: "rgba(0, 0, 0, 0.85)"
                    }}
                >
                    {this.props.openNPS?.name}
                </Space>
            }
            visible={this.props?.openNPS?.name !== ""}
            footer={null}
            bodyStyle={{
                width: "100%",
                height: "100%",
                borderRadius: "5px",
                overflowY: "auto",
                backgroundColor: "#f1f0f5"
            }}
            centered
            onCancel={() => this.props.handleCancel()}
        >
            <NPSChartView npsData={this.props.openNPS?.npsData} />
        </Modal>
    }

}

interface IUnfollow {
    visible: boolean
    handleOk: any
    handleCancel: any
}


class ModaUnFollow extends React.Component<IUnfollow> {

    render() {
        const { visible, handleOk, handleCancel } = this.props
        return <Modal
            width={350}
            style={{ borderWidth: "0px", borderRadius: "4px", overflow: "hidden" }}
            centered
            // placement="bottomRight"
            title={
                <div
                    style={{
                        width: "100%",
                        cursor: "move",
                        fontWeight: "bold",
                        borderWidth: "0px"
                    }}
                    onFocus={() => { }}
                    onBlur={() => { }}
                // end
                >
                    Unfollow
                </div>
            }
            visible={visible}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={[
                <Row>
                    <Col span={12}>
                        <Button
                            size="large"
                            className="report-button"
                            style={{ padding: '3px 30px' }}
                            key="report"
                            onClick={handleCancel}
                        >
                            Cancel
                        </Button>
                    </Col>
                    <Col span={12}>
                        <Button
                            size="large"
                            className="follow-label"
                            style={{ borderWidth: "0px" }}
                            key="report"
                            onClick={handleOk}
                        >
                            Unfollow
                        </Button>
                    </Col>
                </Row>
            ]}
        >
            <Text style={{ textAlign: 'center' }}>Are you sure want to Unfollow?</Text>
        </Modal>
    }

}

interface AddRatingRateAsCustomerProps {

    open?: boolean
    handleCancel: () => void
    handleBack: () => void
    userRateItems: any
    userRatingChange: any
    submitUsersRating: any

}

class ModalAddRatingRateAsCustomer extends React.Component<AddRatingRateAsCustomerProps> {

    render() {

        return <Modal
            footer={null}
            centered
            bodyStyle={{
                width: "100%",
                height: "80vh",
                borderRadius: "5px",
                overflowY: "scroll",
                overflowX: "hidden"
            }}
            title={
                <Space
                    style={{
                        width: "100%",
                        cursor: "move",
                        fontWeight: "bold",
                        color: "rgba(0, 0, 0, 0.85)"
                    }}
                >
                    Add Rating
                </Space>
            }
            visible={this.props.open}
            onCancel={() => this.props.handleCancel()}
        >
            <div
                style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <Title
                    style={{
                        color: "rgba(0, 0, 0, 0.85)",
                        marginBottom: "1rem"
                    }}
                    level={5}
                >
                    Rate as {this.props.userRateItems?.name}
                </Title>
                {
                    this.props.userRateItems?.array?.map((item: any, index: Number) => item.name && <Space
                        direction="vertical"
                        key={item.name}
                    >
                        <SliderView
                            description={item?.description}
                            name={item.name}
                            changeHandler={(val: any) => this.props.userRatingChange({ name: item.name, value: val, index: index })}
                            value={item.value}
                        />
                    </Space>)
                }
            </div>
            <div
                style={{
                    width: "100%",
                    marginTop: "1rem",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between"
                }}
            >
                <Button
                    type="default"
                    style={{
                        width: "45%",
                        height: "2.5rem",
                        borderRadius: "25px",
                        border: "1px solid #4080c0",
                        color: "#006285"
                    }}
                    onClick={() => this.props.handleBack()}
                >
                    Back
                </Button>
                <Button
                    type="primary"
                    style={{
                        width: "45%",
                        height: "2.5rem",
                        borderRadius: "25px",
                        backgroundColor: "#006285",
                        color: "#ffffff"
                    }}
                    onClick={this.props.submitUsersRating}
                >
                    Submit
                </Button>
            </div>
        </Modal>
    }

}

interface AddRatingAppliedViewProps {

    open?: boolean
    handleCancel: () => void
    handleSave: () => void
    generalRating: any
    generalRatingChange: any

}

class ModalAddAppliedViewRating extends React.Component<AddRatingAppliedViewProps> {

    render() {

        return <Modal
            footer={null}
            centered
            bodyStyle={{
                width: "100%",
                height: "80vh",
                borderRadius: "5px",
                overflowY: "scroll",
                overflowX: "hidden"
            }}
            title={
                <Space
                    style={{
                        width: "100%",
                        cursor: "move",
                        fontWeight: "bold",
                        color: "rgba(0, 0, 0, 0.85)"
                    }}
                >
                    Add Rating
                </Space>
            }
            visible={this.props.open}
            onCancel={() => this.props.handleCancel()}
        >
            <div
                style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",

                }}
            >
                <Title
                    style={{
                        color: "rgba(0, 0, 0, 0.85)",
                        marginBottom: "1rem"
                    }}
                    level={5}
                >
                    Applied View Rating
                </Title>
                {
                    this.props.generalRating?.array?.map((item: any, index: any) => item.name && <Space
                        direction="vertical"
                        key={item.name}
                    >
                        <SliderView
                            description={item.description}
                            name={item.name}
                            changeHandler={(val: any) => this.props.generalRatingChange({ name: item.name, value: val, index: index })}
                            value={Math.round(item.value)}
                        />
                    </Space>
                    )
                }
            </div>
            <Button
                style={{
                    width: "100%",
                    height: "2.5rem",
                    borderRadius: "25px",
                    backgroundColor: "#006285",
                    color: "#ffffff",
                    marginTop: "1rem"
                }}
                onClick={() => this.props.handleSave()}
            >
                Save & Next
            </Button>
        </Modal>
    }

}

interface MyProps {

    data?: any
    accId?: number
    isLoading: boolean

}
interface MyModalProps {

    open?: boolean
    data?: any
    handleCancel: () => void

}
interface MyAddReviewProps {

    open?: boolean
    handleCancel: () => void
    data?: string
    disableAnonymous: boolean
    hideAnonymous: boolean
    onFinish: (value: any) => void

}

class ModalAddReview extends React.Component<MyAddReviewProps> {

    render() {
        return <Modal
            footer={null}
            bodyStyle={{
                width: "100%",
                height: "62vh",
                borderRadius: "5px",
            }}
            title={
                <Space
                    style={{
                        width: "100%",
                        cursor: "move",
                        fontWeight: "bold",
                        color: "rgba(0, 0, 0, 0.85)"
                    }}
                >
                    Add Review
                </Space>
            }
            visible={this.props.open}
            onCancel={() => this.props.handleCancel()}
        >
            <AddReviewView
                disableAnonymous={this.props.disableAnonymous}
                hideAnonymous={this.props.hideAnonymous}
                data={this.props.data}
                onFinish={this.props.onFinish}
            />
        </Modal>
    }

}

interface RatingViewProps {

    open?: boolean
    ratingViewData?: Array<any>
    ratingViewName?: string
    handleRatingNPSChart: (name: string, data: any) => void
    handleCancel: () => void

}
class ModalRatingView extends React.Component<RatingViewProps> {


    render() {
        return <Modal
            title={
                <Space
                    style={{
                        width: "100%",
                        cursor: "move",
                        fontWeight: "bold",
                        color: "rgba(0, 0, 0, 0.85)"
                    }}
                >
                    {this.props.ratingViewName}
                </Space>
            }
            visible={this.props.open}
            footer={null}
            bodyStyle={{
                width: "100%",
                height: "80vh",
                borderRadius: "5px",
                overflowY: "auto",
                backgroundColor: "#f1f0f5"
            }}
            centered
            onCancel={() => this.props.handleCancel()}
        >
            {
                this.props?.ratingViewData?.map((item, id) => {
                    if (id !== 7) {
                        const score = 0
                        if (item.score === null) {
                            return <div
                                key={id}
                            >
                                <Card
                                    style={{ width: "100%", height: "100%", cursor: "pointer", marginTop: "1rem" }}
                                    onClick={() => this.props.handleRatingNPSChart(item.name, item.nps_rating)}
                                >
                                    <Progress type="circle" width={110} percent={score} />
                                    <Text style={{ marginLeft: "2rem", color: "rgba(0, 0, 0, 0.85)" }}>{item.name}</Text>
                                </Card>
                            </div>
                        } else {
                            return (
                                <div
                                    key={id}
                                >
                                    <Card
                                        style={{ width: "100%", height: "100%", cursor: "pointer", marginTop: "1rem" }}
                                    >
                                        <Progress type="circle" width={110} percent={item.score} />
                                        <Text style={{ marginLeft: "2rem" }}>{item.name}</Text>
                                    </Card>
                                </div>
                            )
                        }
                    }
                })
            }
        </Modal>
    }

}

class ModalAbout extends React.Component<MyModalProps> {

    render() {
        return <Modal
            title={
                <Space
                    style={{
                        width: "100%",
                        cursor: "move",
                        fontWeight: "bold",
                        color: "rgba(0, 0, 0, 0.85)"
                    }}
                >
                    About {this.props?.data?.first_name}
                </Space>
            }
            visible={this.props?.open}
            footer={null}
            bodyStyle={{
                width: "100%",
                maxHeight: "80vh",
                borderRadius: "5px",
                overflowY: "auto",
                padding: "0px 24px 16px 24px"
            }}
            centered
            onCancel={() => this.props.handleCancel()}
        >
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <Paragraph style={{ color: "#919191" }}>{this.props?.data?.profile?.data?.attributes?.description}</Paragraph>
            </div>
        </Modal>
    }

}

interface MyProfileProps {

    data?: any
    handleClick: () => void
    handleReviewClick: () => void
    handleFollow: () => void
    handleFollowing: () => void
    handleConnect: () => void
    handleAddAppliedViewRatingOpen: () => void
    isLoading: boolean
    handleChat: () => void
    acceptRequest: any
    handleBlock: () => void
    handleReportOpen: () => void
    handleRemoveConnection: () => void
    handleSentConnectionsList: () => void
    downloadResumeWithAxios: () => void
    downloadResumeLoading: boolean
    nextPageLoader?: boolean

}

interface MyProfileState {
    visible: boolean
}

class Profile extends React.Component<MyProfileProps, MyProfileState> {
    constructor(props: any) {
        super(props)

        this.state = {
            visible: false
        }
    }

    render() {
        const isFollowing = this.props?.data?.following;
        const isConnected = this.props?.data?.friend;
        const isInvited = this.props?.data?.invited;
        const isBlocked = this.props?.data?.is_blocked;
        const isRequested = Object.entries(this.props?.data?.received_request || {}).length > 0;
        const requestedObj = this.props?.data?.received_request || {};

        const menu = (
            <Menu>
                {/* {
                    isConnected ?
                        <Menu.Item onClick={() => message.info("Comming Soon!")} key="remove">Remove Connection</Menu.Item>
                        : null
                } */}
                {!this.props?.data?.is_blocked && <Menu.Item
                    onClick={() => this.props.handleBlock()}
                    key="block"
                >
                    {this.props?.data?.is_blocked ? "Unblock" : "Block"}
                </Menu.Item>}
                <Menu.Item
                    onClick={() => this.props.handleReportOpen()}
                    key="report"
                >
                    Report
                </Menu.Item>
                {isInvited && this.props?.data?.connected_as === null && < Menu.Item
                    onClick={() => this.props.handleSentConnectionsList()}
                    key="cancel Request"
                >
                    Cancel Connection Request
                </Menu.Item>}
            </Menu>
        );

        // const isFollowing = true;
        // const isConnected = true;
        // const isInvited = true;
        const delay = (ms: any) => new Promise(res => setTimeout(res, ms));
        const domainSkills = this.props?.data?.profile?.data?.attributes?.domain_skills;
        return (
            <div id="profile" style={{ width: "100%", display: "flex", justifyContent: "end", paddingTop: "1rem" }}>
                <div style={{ width: "96%", backgroundColor: "#ffffff", margin: "auto" }}>
                    <div className="cover-img">
                        <Image
                            style={{ borderTopRightRadius: "5px", borderTopLeftRadius: "5px", objectFit: "cover" }}
                            width="100%"
                            height="250px"
                            src="https://timelinecovers.pro/facebook-cover/download/stunning-little-flowers-facebook-cover.jpg"
                        />
                        <Avatar className="avatar-position" style={{ backgroundColor: "#919191" }} size={64} src={this.props?.data?.photo ? this.props?.data?.photo : <UserOutlined translate={undefined} />} />
                    </div>
                    <div className="user-details">
                        <Row>
                            <Col xs={{ span: 24 }} lg={{ span: 9 }} style={{ padding: "0px 0px 0px 16px" }}>
                                {
                                    this.props.isLoading ? <Spin />
                                        :
                                        <div style={{ display: "flex", flexDirection: 'column' }}>
                                            <Text style={{ display: "flex", flexWrap: "wrap", flexDirection: 'row', alignItems: 'baseline' }}>
                                                <span style={{ fontSize: "18px", fontWeight: 500, textTransform: "capitalize" }}>
                                                    {this.props.data?.profile?.data?.attributes?.full_name}
                                                </span>
                                                {
                                                    this.props.data?.profile?.data?.attributes?.designation?.title ?
                                                        <>
                                                            <span style={{ width: "6px", height: "6px", backgroundColor: "#dfe5eb", borderRadius: "50%", margin: "0px 4px 0px 7px", position: "relative", bottom: "1px" }}></span>
                                                            <>
                                                                <span
                                                                    style={{ fontSize: "12px", fontWeight: 500, textTransform: "capitalize" }}
                                                                >
                                                                    {this.props.data?.profile?.data?.attributes?.designation?.title}
                                                                </span>
                                                                {
                                                                    this.props.data?.profile?.data?.attributes?.designation?.company ?
                                                                        <span><span style={{ margin: "0px 3px" }}> at </span>{this.props.data?.profile?.data?.attributes?.designation?.company}</span> : null
                                                                }
                                                            </>
                                                        </>
                                                        : null
                                                }
                                            </Text>
                                            <Text type="secondary" style={{ fontSize: "12px", display: "flex", flexDirection: 'row', flexWrap: "wrap", alignItems: 'baseline', textTransform: "capitalize" }}>
                                                {
                                                    domainSkills ?
                                                        <>
                                                            <span style={{ width: "6px", height: "6px", backgroundColor: "#dfe5eb", borderRadius: "50%", margin: "0px 4px 0px 0px", position: "relative", bottom: "1px" }}></span>
                                                            <>
                                                                {
                                                                    domainSkills?.map((item: any, id: number) => {
                                                                        if (id + 1 < domainSkills.length) {
                                                                            return `${item}, `
                                                                        } else {
                                                                            return `${item}`
                                                                        }
                                                                    })
                                                                }
                                                            </>
                                                        </>
                                                        : null
                                                }
                                                {
                                                    this.props.data?.profile?.data?.attributes?.experience_years ?
                                                        <>
                                                            <span style={{ width: "6px", height: "6px", backgroundColor: "#dfe5eb", borderRadius: "50%", margin: "0px 4px 0px 7px", position: "relative", bottom: "1px" }}></span>
                                                            <>
                                                                {this.props.data?.profile?.data?.attributes?.experience_years}+ year of Experience
                                                            </>
                                                        </>
                                                        : null
                                                }
                                            </Text>
                                        </div>
                                }
                            </Col>
                            <Col xs={{ span: 24 }} lg={{ span: 15 }} style={{ padding: "10px 16px 0px 0px", }}>
                                {
                                    this.props.isLoading ? <Spin />
                                        : <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", flexWrap: "wrap" }}>
                                            <div style={{ marginBottom: "5px", }}>
                                                <Button
                                                    shape="round"
                                                    style={{ border: "1px solid #4080c0", width: isFollowing ? "7rem" : "6rem", color: isFollowing ? "#ffffff" : "#4080c0", backgroundColor: isFollowing ? "#4080c0" : "#ffffff" }}
                                                    onClick={isFollowing ? () => this.props.handleFollowing() : () => this.props.handleFollow()}
                                                    disabled={isBlocked}
                                                >
                                                    {
                                                        isFollowing ? "Following" : "Follow"
                                                    }
                                                </Button>
                                            </div>
                                            <div style={{ marginLeft: "10px", marginBottom: "5px", display: (isConnected || isRequested) ? "none" : "block" }}>
                                                {
                                                    this.props?.data?.is_blocked ?
                                                        <Button
                                                            type="default"
                                                            shape="round"
                                                            style={{ border: "1px solid #4080c0", width: isInvited ? "7rem" : "6.5rem", color: "#4080c0" }}
                                                            onClick={() => this.props.handleBlock()}
                                                        // disabled={isInvited ? true : false}
                                                        >
                                                            Unblock
                                                        </Button>
                                                        : <Button
                                                            type="default"
                                                            shape="round"
                                                            style={{ border: "1px solid #4080c0", width: isInvited ? "7rem" : "6.5rem", color: "#4080c0" }}
                                                            onClick={() => this.props.handleConnect()}
                                                            disabled={isInvited ? true : false}
                                                        >
                                                            {this.props.nextPageLoader ? <Spin /> : isInvited ? "Requested" : "Connect"}
                                                        </Button>
                                                }
                                            </div>
                                            <div style={{ marginLeft: "10px", display: (isRequested) ? "block" : "none" }}>

                                                <Button
                                                    style={{ border: "1px solid #6ccc3e", color: "#6ccc3e" }}
                                                    shape="round"
                                                    onClick={() => this.props.acceptRequest({ type: "openAcceptModal" })}
                                                >
                                                    Accept
                                                </Button>
                                            </div>
                                            <div style={{ marginLeft: "10px", display: (isRequested) ? "block" : "none" }}>
                                                <Button
                                                    shape="round"
                                                    danger
                                                    onClick={() => this.props.acceptRequest({ type: "rejected", ...requestedObj })}
                                                >
                                                    Reject
                                                </Button>
                                            </div>
                                            <div style={{ marginLeft: "10px", marginBottom: "5px", display: isConnected ? "block" : "none" }}>
                                                <Button
                                                    style={{ backgroundColor: "#4080c0", width: "5rem", color: "#ffffff" }}
                                                    shape="round"
                                                    onClick={() => this.props.handleAddAppliedViewRatingOpen()}
                                                // disabled={true}
                                                >
                                                    Rate
                                                </Button>
                                            </div>
                                            <div style={{ marginLeft: "10px", marginBottom: "5px", display: isConnected ? "block" : "none" }}>
                                                <Button
                                                    style={{ backgroundColor: "#4080c0", width: "5.5rem", color: "#ffffff" }}
                                                    shape="round"
                                                    onClick={() => this.props.handleReviewClick()}
                                                >
                                                    Review
                                                </Button>
                                            </div>
                                            <div style={{ marginLeft: "10px", marginBottom: "5px", display: isConnected ? "block" : "none" }}>
                                                <Button
                                                    style={{
                                                        backgroundColor: "#4080c0", width: "9rem", color: "#ffffff"
                                                    }}
                                                    shape="round"
                                                    onClick={() => {
                                                        // message.info('Coming soon')
                                                        if (!this.props.downloadResumeLoading) {
                                                            this.props.downloadResumeWithAxios()
                                                        }
                                                    }}
                                                >
                                                    {this.props.downloadResumeLoading ? <Spin /> : "Download Profile"}
                                                </Button>
                                            </div>
                                            <div style={{ marginLeft: "10px", marginBottom: "5px", display: isConnected ? "block" : "none" }}>
                                                <Button
                                                    style={{ backgroundColor: "#4080c0", width: "5rem", color: "#ffffff" }}
                                                    shape="round"
                                                    onClick={this.props.handleChat}
                                                >
                                                    Chat
                                                </Button>
                                            </div>
                                            <div style={{ marginLeft: "10px", marginBottom: "5px", }}>
                                                <Dropdown overlay={menu} placement="bottomRight" trigger={["click"]}>
                                                    <img src={horizentalTripleDot} width="32px" />
                                                </Dropdown>
                                            </div>
                                        </div>
                                }
                            </Col>
                            <Col span={24} >
                                <AboutAndStats
                                    isLoading={this.props.isLoading}
                                    data={this.props.data}
                                    handleClick={this.props.handleClick}
                                />
                            </Col>
                        </Row>

                    </div>

                </div>
            </div>
        );
    }

}


interface MyPostRatingReviewProps {


    data?: any
    getPosts: (id: number) => any
    postDataArray?: any
    accountId: number
    isPrivate: boolean
    isPostView: boolean
    handleRatingView: (data: any, name: string) => void
    reviewData: Array<any>
    isLoading: boolean
    activeTab: any
    isRatingVisible: boolean
    handleFollow: () => void
    handleFollowing: () => void
    noOfPostData: boolean
    handlePostList: any

}

interface Istate {

    activeTab: any

}

class UserPostRatingReview extends React.Component<MyPostRatingReviewProps, Istate> {


    constructor(props: any) {
        super(props)
        this.state = {
            activeTab: '1'
        }
    }
    componentDidMount = () => {
        console.log(this.props.activeTab, this.props.isPostView)
        this.setState({
            activeTab: this.props.isPostView ? this.props.activeTab : "2"
        })
    }

    changeTab = (activeKey: any) => {
        this.setState({
            activeTab: activeKey
        });
    }

    render() {

        const isFollowing = this.props?.data?.following;
        const isConnected = this.props?.data?.friend;

        return (
            <div style={{ width: "100%", paddingTop: "1rem" }}>
                <Tabs
                    activeKey={this.state.activeTab}
                    defaultActiveKey={this.state.activeTab}
                    onChange={this.changeTab}
                    centered={true}
                    size="large"
                    tabBarStyle={{
                        color: "rgb(117, 117, 117)",
                        backgroundColor: "rgb(221, 230, 235)",
                        height: "3rem"
                    }}
                    style={{
                        width: "96%",
                        margin: "auto",
                        borderRadius: "3px",
                        backgroundColor: "#f1f0f5"
                    }}
                >
                    {this.props.isPostView &&
                        <TabPane
                            tab="Posts"
                            key="1"
                            style={{ width: "96%", height: "100%", margin: "1rem auto" }}
                        >
                            {/* <div onClick={()=>this.changeTab(1)}> */}
                            {
                                <div>
                                    {
                                        this.props.postDataArray?.map((item: any, index: number) => {
                                            return (
                                                <>
                                                    <UserPost item={item}
                                                        following={this.props.data?.following}
                                                        onHandleFollow={this.props.data?.following ? () => this.props.handleFollowing() : () => this.props.handleFollow()}
                                                    />
                                                    {
                                                        (this.props.postDataArray.length - 1 === index) &&
                                                        <>
                                                            {this.props.isLoading ? <Spin /> :
                                                                this.props.noOfPostData &&
                                                                <Text style={{ fontSize: 16, color: "#0e76a8", cursor: 'pointer' }}
                                                                    onClick={() => this.props.getPosts(this.props.accountId)}
                                                                >

                                                                    Load More ...
                                                                </Text>
                                                            }
                                                        </>
                                                    }
                                                </>
                                            )
                                        })
                                    }
                                </div>
                            }
                            {/* </div> */}

                        </TabPane>
                    }
                    <TabPane
                        tab="Ratings"
                        key={this.props.isPrivate ? "2" : "2"}
                        style={{
                            width: "96%",
                            height: "100%",
                            margin: "1rem auto"
                        }}
                    >
                        {
                            this.props.isLoading ? <Spin />
                                : <RatingView data={this.props.data} isPrivate={this.props.isRatingVisible} />
                        }
                    </TabPane>
                    <TabPane
                        tab="Reviews"
                        key={this.props.isPrivate ? "3" : "3"}
                        style={{ width: "96%", height: "100%", margin: "1rem auto" }}
                    >
                        {
                            this.props.isLoading ? <Spin />
                                : this.props?.reviewData?.length ?
                                    <ReviewDataView reviewData={this.props.reviewData} />
                                    : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        }
                    </TabPane>
                </Tabs>
            </div>
        );
    }

}


class Experience extends React.Component<MyProps> {


    render() {
        const experienceList = this.props?.data?.profile?.data?.attributes?.experiences;
        console.log(experienceList)
        return <>
            {
                experienceList?.length > 0 ?
                    <div style={{ margin: "1rem" }}>
                        <Title level={5} style={{ color: "rgba(0, 0, 0, 0.85)" }}>Experience</Title>
                        <List
                            style={{ borderRadius: "5px", overflow: "hidden" }}
                            itemLayout="horizontal"
                            dataSource={
                                experienceList.sort((a: any, b: any) => {
                                    try {
                                        const letet = a.start_date?.split('/')
                                        if (letet.length > 0) {
                                            return a.start_date?.split('/').reverse()?.join().localeCompare(b.start_date.split('/')?.reverse()?.join())
                                        } else {
                                            return a.start_date?.reverse()?.join().localeCompare(b.start_date?.reverse()?.join())
                                        }
                                    } catch (e: any) {
                                        return null
                                    }
                                }).reverse()
                            }
                            renderItem={(item: any, index: number) => (
                                <List.Item className="listInfo" key={`${item.title}${index}`}>
                                    <List.Item.Meta
                                        title={<div><h4 className="ant-list-item-meta-title"
                                            style={{
                                                textTransform: "capitalize",
                                                paddingRight: 8,
                                                wordBreak: 'break-word'
                                            }}>{item?.company}</h4></div>}
                                        description={
                                            <div style={{ display: "flex", flexDirection: "column" }}>
                                                <Text style={{ fontWeight: 500, fontSize: "14px", color: "rgb(117, 117, 117)", display: item?.start_date ? "block" : "none" }}>
                                                    <div style={{ display: 'flex' }}>
                                                        <div

                                                            className="Education_column1"
                                                        >Start Date:</div>
                                                        <div className="Column2_otherProfile">{moment(item?.start_date, "DD/MM/YYYY").format("DD-MM-YYYY") !== 'Invalid date' ? moment(item?.start_date, "DD/MM/YYYY").format("DD-MM-YYYY") :
                                                            moment(item?.start_date).format("DD-MM-YYYY")
                                                        }</div>
                                                    </div>
                                                </Text>
                                                <Text style={{ fontWeight: 500, fontSize: "14px", color: "rgb(117, 117, 117)", display: item?.end_date ? "block" : "none" }}>
                                                    <div style={{ display: 'flex' }}>
                                                        <div

                                                            className="Education_column1"
                                                        >End Date:</div>
                                                        <div className="Column2_otherProfile">{moment(item?.end_date, "DD/MM/YYYY").format("DD-MM-YYYY") !== 'Invalid date' ? moment(item?.end_date, "DD/MM/YYYY").format("DD-MM-YYYY") :
                                                            moment(item?.end_date).format("DD-MM-YYYY")
                                                        }</div>
                                                    </div>
                                                </Text>
                                                <Text style={{
                                                    fontWeight: 500, fontSize: "14px",
                                                    color: "rgb(117, 117, 117)", display: item?.title ? "block" : "none"
                                                }}>
                                                    <div style={{ display: 'flex' }}>
                                                        <div

                                                            className="Education_column1"
                                                        >Title:</div>
                                                        <div className="otherProfile_Experience_Title">{item?.title}</div>
                                                    </div>
                                                </Text>
                                                <Text style={{ fontWeight: 500, fontSize: "14px", color: "rgb(117, 117, 117)", display: item?.description ? "block" : "none" }}>
                                                    <div style={{ display: 'flex' }}>
                                                        <div

                                                            className="Education_column1"
                                                        >Description:</div>
                                                        <div className="Column2_otherProfile">{item?.description}</div>
                                                    </div>
                                                </Text>
                                            </div>
                                        }
                                    />
                                </List.Item>
                            )}
                        />
                    </div>
                    : null
            }
        </>
    }

}

class Education extends React.Component<MyProps> {



    render() {
        const educationList = this.props?.data?.profile?.data?.attributes?.graduations;
        return <>
            {
                educationList?.length > 0 ?
                    <div style={{ margin: "1rem" }}>
                        <Title level={5} style={{ color: "rgba(0, 0, 0, 0.85)" }}>Education</Title>
                        <List
                            style={{ borderRadius: "5px", overflow: "hidden" }}
                            itemLayout="horizontal"
                            dataSource={
                                educationList?.sort((a: any, b: any) => {
                                    return a?.year - b?.year
                                    // try {
                                    //     return a?.year?.split('/')?.reverse()?.join()?.localeCompare(b.year.split('/')?.reverse()?.join())
                                    // } catch (e: any) {
                                    //     return null
                                    // }
                                }
                                ).reverse()

                            }
                            renderItem={(item: any) => (
                                <List.Item className="listInfo">
                                    <List.Item.Meta
                                        title={<span style={{ textTransform: "capitalize" }}>
                                            {item.type ? item.type === "senior_secondary" ? "Sr Secondary" : <span>{item?.degree}{' '}{item.stream && `(${item?.stream})`}</span> : <span>{item?.degree}{' '}{item.stream && `(${item?.stream})`}</span>}
                                        </span>}
                                        description={
                                            (item?.type && item?.stream || item?.university && item?.degree) &&
                                            < div style={{ display: "flex", flexDirection: "column" }}>
                                                <Text style={{ fontWeight: 500, fontSize: "14px", color: "rgb(117, 117, 117)", display: item?.university ? "block" : "none" }}>
                                                    <div style={{ display: 'flex' }}>
                                                        <div

                                                            className="Education_column1"
                                                        >University:</div>
                                                        <div className="Column2_otherProfile">{item?.university}</div>
                                                    </div>
                                                </Text>
                                                <Text style={{ fontWeight: 500, fontSize: "14px", color: "rgb(117, 117, 117)", display: item?.stream && item.type === "senior_secondary" ? "block" : "none" }}>
                                                    <div style={{ display: 'flex' }}>
                                                        <div

                                                            className="Education_column1"
                                                        >Stream:</div>
                                                        <div className="Column2_otherProfile">{item?.stream}</div>
                                                    </div>
                                                </Text>
                                                <Text style={{ fontWeight: 500, fontSize: "14px", color: "rgb(117, 117, 117)", display: item?.board ? "block" : "none" }}>
                                                    <div style={{ display: 'flex' }}>
                                                        <div

                                                            className="Education_column1"
                                                        >Board:</div>
                                                        <div className="Column2_otherProfile">{item?.board}</div>
                                                    </div>
                                                </Text>
                                                <Text style={{ fontWeight: 500, fontSize: "14px", color: "rgb(117, 117, 117)" }}>
                                                    <div style={{ display: 'flex' }}>
                                                        <div

                                                            className="Education_column1"
                                                        >Year:</div>
                                                        <div className="Column2_otherProfile">{item?.year}</div>
                                                    </div>
                                                </Text>
                                                <Text style={{ fontWeight: 500, fontSize: "14px", color: "rgb(117, 117, 117)" }}>
                                                    <div style={{ display: 'flex' }}>
                                                        <div

                                                            className="Education_column1"
                                                        >Score:</div>
                                                        <div className="Column2_otherProfile">{item?.score}</div>
                                                    </div>
                                                </Text>
                                            </div>
                                        }
                                    />
                                </List.Item>
                            )}
                        />
                    </div>
                    : null
            }
        </>
    }

}

class Certification extends React.Component<MyProps> {

    render() {
        const certificationList = this.props?.data?.profile?.data?.attributes?.certifications;
        return <>
            {
                certificationList?.length > 0 ?
                    <div style={{ margin: "1rem" }}>
                        <Title level={5} style={{ color: "rgba(0, 0, 0, 0.85)" }}>Certification</Title>
                        <List
                            style={{ borderRadius: "5px", overflow: "hidden" }}
                            itemLayout="horizontal"
                            dataSource={certificationList}
                            renderItem={(item: any) => (
                                <List.Item className="listInfo">
                                    <List.Item.Meta
                                        title={<span style={{ textTransform: "capitalize" }}>{item.name}</span>}
                                        description={item.description}
                                    />
                                </List.Item>
                            )}
                        />
                    </div>
                    : null
            }
        </>
    }

}

class AwardsRecognition extends React.Component<MyProps> {


    render() {
        const awardsList: Array<any> = this.props?.data?.profile?.data?.attributes?.awards;
        return <>
            {
                awardsList?.length > 0 ?
                    <div style={{ margin: "1rem" }}>
                        <Title level={5} style={{ color: "rgba(0, 0, 0, 0.85)" }}>Awards & Recognition</Title>
                        <List
                            style={{ borderRadius: "5px", overflow: "hidden" }}
                            itemLayout="horizontal"
                            dataSource={awardsList}
                            renderItem={(item: any) => (
                                <List.Item className="listInfo">
                                    <List.Item.Meta
                                        title={<span style={{ textTransform: "capitalize" }}>
                                            {item.title}</span>}
                                        description={item.description}
                                    />
                                </List.Item>
                            )}
                        />
                    </div>
                    : null
            }
        </>
    }

}

class Patent extends React.Component<MyProps> {


    render() {
        const patentsList = this.props?.data?.profile?.data?.attributes?.patents;

        return <>
            {
                patentsList?.length > 0 ?
                    <div style={{ margin: "1rem" }}>
                        <Title level={5} style={{ color: "rgba(0, 0, 0, 0.85)" }}>Patent</Title>
                        <List
                            style={{ borderRadius: "5px", overflow: "hidden" }}
                            itemLayout="horizontal"
                            dataSource={patentsList}
                            renderItem={(item: any) => (
                                <List.Item className="listInfo">
                                    <List.Item.Meta
                                        title={<span style={{ textTransform: "capitalize" }}>{item.name}</span>}
                                        description={item.description}
                                    />
                                </List.Item>
                            )}
                        />
                    </div>
                    : null
            }
        </>

    }

}

class Achievements extends React.Component<MyProps> {

    render() {
        const achievementsList = this.props?.data?.profile?.data?.attributes?.achievements;

        return <>
            {
                achievementsList?.length > 0 ?
                    <div style={{ margin: "1rem" }}>
                        <Title level={5} style={{ color: "rgba(0, 0, 0, 0.85)" }}>Achievements</Title>
                        <List
                            style={{ borderRadius: "5px", overflow: "hidden" }}
                            itemLayout="horizontal"
                            dataSource={achievementsList}
                            renderItem={(item: any) => (
                                <List.Item className="listInfo">
                                    <List.Item.Meta
                                        description={item.description}
                                    />
                                </List.Item>
                            )}
                        />
                    </div>
                    : null
            }
        </>

    }

}

class Languages extends React.Component<MyProps> {


    render() {
        const languagesList = this.props?.data?.profile?.data?.attributes?.languages;

        return <>
            {
                languagesList?.length > 0 ?
                    <div style={{ margin: "1rem" }}>
                        <Title level={5} style={{ color: "rgba(0, 0, 0, 0.85)" }}>Languages</Title>
                        <List
                            style={{ borderRadius: "5px", overflow: "hidden" }}
                            itemLayout="horizontal"
                            dataSource={languagesList}
                            renderItem={(item: any) => (
                                <List.Item className="listInfo">
                                    <List.Item.Meta
                                        title={<span style={{ textTransform: "capitalize" }}>{item.language}</span>}
                                    />
                                </List.Item>
                            )}
                        />
                    </div>
                    : null
            }
        </>

    }

}

// Customizable Area End
