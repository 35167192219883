import React from 'react';
import DeleteCommunityController from './DeleteCommunityController';
import {
    Button,
    List
} from 'antd';
import CustomRadioButton from '../../PrivacySettings/src/Setting/CustomRadioButton';


export default class DeleteCommunity extends DeleteCommunityController {

    render() {
        return (
            <div>
                <div><h2 style={{ color: '#434343' }}>Delete Community</h2></div>
                <div style={{ background: '#fff' }}>
                    <List.Item style={{ padding: '1rem' }}>
                        <List.Item.Meta
                            title={<span>Are you sure want to delete your community?</span>}
                            description={<div>
                                <CustomRadioButton
                                    data-testid="Radiovalue"
                                    handleOnClick={this.handleRadioButton}
                                    data={this.optionList}
                                />
                                <div style={{ marginTop: '16px' }}>
                                    <Button
                                        className="report-button"
                                        key="report"
                                        data-testid="deleteButton"
                                        loading={this.state.isLoading}
                                        size="large"
                                        disabled={!this.state.radioValues}
                                        onClick={this.deleteCommunityClick}
                                    >
                                        Delete Community
                                    </Button>
                                </div>
                            </div>}
                        />
                    </List.Item>
                </div>
            </div>

        );
    }
}
