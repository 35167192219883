import React from 'react';
import { List, Avatar, Typography, Button } from "antd";
import { ArrowLeftOutlined } from '@ant-design/icons';
const { Title, Text } = Typography;

const PurchaseHistory = (props: any) => {
    const { handleBack, purchaseHistoryData } = props;
    console.log(props.purchaseHistoryData, "pppppp")
    const historyList: any[] | undefined = purchaseHistoryData
    return (
        <div>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "baseline" }}>
                <ArrowLeftOutlined translate={{}} style={{ cursor: "pointer" }} onClick={() => handleBack("Purchase History")} />
                <Title level={5} style={{ margin: "5px 20px" }}>
                    Purchase History
                </Title>
            </div>
            <List
                style={{ width: "83.5%" }}
                itemLayout="vertical"
                dataSource={historyList}
                renderItem={item => (
                    <List.Item
                        key={item.id}
                    >
                        <div style={{ width: "100%", padding: "0px 20px", lineHeight: "20px", display: "flex", flexDirection: "column" }}>
                            <Title level={5}>{item.heading}</Title>
                            <Text type='secondary'>Purchased on: <Text strong>{item.attributes.purchase_date}</Text></Text>
                            <Text type='secondary'>Membership ends on: <Text strong>{item.attributes.expiry_date}</Text></Text>
                            <Text type='secondary'>Subscription amount: <Text strong>{item.attributes.amount}</Text></Text>
                        </div>
                    </List.Item>
                )}
            />
        </div>
    )
}

export default PurchaseHistory;