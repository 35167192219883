import React from 'react';
// Customizable Area Start
import {
  PageHeader,
  Card,
  Row,
  Col,
  Form,
  Input,
  Typography,
  Button,
} from 'antd';
const { Title, Text, Link } = Typography;
import { logo } from '../assets';
const style = require(`../../../social-media-account/src/login.module.css`);
import CreateProfileBasicHistoryController from '../ProfileSetupBusinessHistoryController.web';
import '../profile.css';
// Customizable Area End
export default class ProfileSetupBasicBusinessHistory extends CreateProfileBasicHistoryController {
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    return (
      <div style={{ paddingBottom: '5%' }}>
        <PageHeader
          className='site-page-header'
          title={
            <span style={{ color: 'white' }}>
              <img style={{ height: '32px', marginRight: '8px' }} src={logo} />
              applied
            </span>
          }
          style={{
            backgroundImage: 'linear-gradient(#005574, #7aacd6)',
            color: 'white',
          }}
        />
        <Row
          style={{ minHeight: '90vh', alignItems: 'center', marginTop: '60px' }}
        >
          <Col xs={0} sm={0} md={3} lg={7} xl={7} />
          <Col xs={24} sm={24} md={18} lg={10} xl={10}>
            <Title
              level={2}
              style={{ textAlign: 'center', marginBottom: '15px' }}
            >
              Setup Your Basic Profile
            </Title>

            <div style={{ backgroundColor: '#e0eaf4', height: '35px' }}>
              <Title
                level={4}
                style={{
                  marginLeft: '15px',
                  color: 'steelblue',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                History
              </Title>
            </div>
            <Card>
              <Form
                initialValues={{
                  year: '',
                  description: '',
                }}
                onFinish={this.handleHistoryData}
              >
                <Form.Item
                  rules={[{ required: true, message: 'Required field' }]}
                  name='year'
                >
                  <Input
                    className={style.input_fields}
                    name='year'
                    placeholder='Year'
                    style={{ color: "#a9bac8" }}
                    size='large'
                  />
                </Form.Item>
                <Form.Item
                  rules={[{ required: true, message: 'Required field' }]}
                  name='description'
                >
                  <Input
                    className={style.input_fields}
                    name='description'
                    placeholder='Description'
                    style={{ color: "#a9bac8" }}
                    size='large'
                  />
                </Form.Item>
                <Button
                  type='primary'
                  shape='round'
                  htmlType='submit'
                  size={'large'}
                  style={{
                    width: '100%',
                    backgroundColor: 'white',
                    borderColor: '#d9d9d9',
                  }}
                >
                  <Text type='secondary'>Add Additional History</Text>
                </Button>
              </Form>
            </Card>
            <div style={{ backgroundColor: '#e0eaf4', height: '35px' }}>
              <Title
                level={4}
                style={{
                  marginLeft: '15px',
                  color: 'steelblue',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                Expansion / Mergers & Acquisitions
              </Title>
            </div>
            <Card>
              <Form
                initialValues={{
                  year: '',
                  description: '',
                }}
                onFinish={this.handleExpansionData}
              >
                <Form.Item
                  rules={[{ required: true, message: 'Required field' }]}
                  name='year'
                >
                  <Input
                    className={style.input_fields}
                    name='year'
                    placeholder='Year'
                    style={{ color: "#a9bac8" }}
                    size='large'
                  />
                </Form.Item>

                <Form.Item
                  rules={[{ required: true, message: 'Required field' }]}
                  name='description'
                >
                  <Input
                    className={style.input_fields}
                    name='description'
                    placeholder='Description'
                    style={{ color: "#a9bac8" }}
                    size='large'
                  />
                </Form.Item>
                <Button
                  type='primary'
                  shape='round'
                  htmlType='submit'
                  size={'large'}
                  style={{
                    width: '100%',
                    backgroundColor: 'white',
                    borderColor: '#d9d9d9',
                  }}
                >
                  <Text type='secondary'>Add Additional Expansion</Text>
                </Button>
              </Form>
            </Card>
            <div style={{ backgroundColor: '#e0eaf4', height: '35px' }}>
              <Title
                level={4}
                style={{
                  marginLeft: '15px',
                  color: 'steelblue',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                Core Teams
              </Title>
            </div>
            <Card>
              <Form
                initialValues={{
                  name: '',
                  description: '',
                }}
                onFinish={this.handleCoreTeamData}
              >
                <Form.Item
                  rules={[{ required: true, message: 'Required field' }]}
                  name='name'
                >
                  <Input
                    className={style.input_fields}
                    name='name'
                    placeholder='Team Member Name'
                    style={{ color: "#a9bac8" }}
                    size='large'
                  />
                </Form.Item>
                <Form.Item
                  rules={[{ required: true, message: 'Required field' }]}
                  name='designation'
                >
                  <Input
                    className={style.input_fields}
                    name='designation'
                    placeholder='Designation'
                    style={{ color: "#a9bac8" }}
                    size='large'
                  />
                </Form.Item>
                <Button
                  type='primary'
                  shape='round'
                  htmlType='submit'
                  size={'large'}
                  style={{
                    width: '100%',
                    backgroundColor: 'white',
                    borderColor: '#d9d9d9',
                  }}
                >
                  <Text type='secondary'>Add Additional Core Team Members</Text>
                </Button>
              </Form>
            </Card>
            <div style={{ backgroundColor: '#e0eaf4', height: '35px' }}>
              <Title
                level={4}
                style={{
                  marginLeft: '15px',
                  color: 'steelblue',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                Client References
              </Title>
            </div>
            <Card>
              <Form
                initialValues={{
                  reference: '',
                }}
                onFinish={this.handleClientRefData}
              >
                <Form.Item
                  rules={[{ required: true, message: 'Required field' }]}
                  name='reference'
                >
                  <Input
                    className={style.input_fields}
                    name='reference'
                    placeholder='Client Reference'
                    style={{ color: "#a9bac8" }}
                    size='large'
                  />
                </Form.Item>
                <Button
                  type='primary'
                  shape='round'
                  htmlType='submit'
                  size={'large'}
                  style={{
                    width: '100%',
                    backgroundColor: 'white',
                    borderColor: '#d9d9d9',
                  }}
                >
                  <Text type='secondary'>Add Additional Cient Referencess</Text>
                </Button>
              </Form>
            </Card>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                padding: '15px',
                background: 'whitesmoke',
              }}
            >
              <Button
                type='primary'
                shape='round'
                size={'large'}
                style={{
                  width: '200px',
                  backgroundColor: 'white',
                  borderColor: '#d9d9d9',
                }}
              >
                <Text type='secondary'>Previous</Text>
              </Button>
              <Button
                className={style.btn_colors}
                type='primary'
                shape='round'
                onClick={this.handleAddProfileData}
                size={'large'}
                style={{ width: '200px' }}
              >
                Next
              </Button>
            </div>
            <br />

            <Row style={{ justifyContent: 'center' }}>
              <Link style={{ fontSize: '16px' }} href='#'>
                Skip Profile Setup
              </Link>
            </Row>
          </Col>
          <Col xs={0} sm={0} md={3} lg={7} xl={7} />
        </Row>
      </div>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
// Customizable Area End
