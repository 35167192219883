import React, { useEffect, useState } from "react";
// @ts-ignore
import { baseURL } from '../../../../framework/src/config.js';
import CustomPost from "../../../CommunityForum/src/CustomPost.web";
import { getStorageData } from "../../../../framework/src/Helpers";
import axios from "axios";
import { Radio, Space, Modal, Button, message } from "antd"

const UserPost = (props: any) => {
  const { item } = props;
  const [items, setItems] = useState(item)
  const [loginId, setLoginId] = useState('0');
  const [likedItems, setLikedItems] = useState<any>([]);
  const [followedItems, setFollowedItems] = useState<any>([]);
  const [like, setLike] = useState<number>(item?.attributes?.like_count)
  const [liked, setLiked] = useState<boolean>(item?.attributes?.liked)
  const [follow, setFollow] = useState<boolean>(item?.attributes?.account?.data?.attributes?.following)
  const [value, setValue] = useState<any>()
  const [commentLoader, setCommentLoader] = useState(false)
  const [isReportIssueVisible, setisReportIssueVisible] = useState<boolean>(false)
  const [reportPostID, setreportPostID] = useState<any>()
  // initial api calls****************

  const handleCancel = (e: any) => {
    setisReportIssueVisible(false)
    setreportPostID("")
  };
  const onChangeValue = (e: any) => {
    setValue(e.target.value)
  };

  const handleOk = async (value: any) => {
    let httpBody = {
      data: {
        attributes: {
          context: value
        }
      }
    }
    try {
      const response = await axios({
        url: `${baseURL}/bx_block_posts/posts/${reportPostID}/report`,
        method: 'post',
        headers: {
          'token': await localStorage.getItem('token'),
          'Content-Type': 'application/json'
        },
        data: JSON.stringify(httpBody)
      })
      message.success('Post has been reported successfully')
      setisReportIssueVisible(false)
    } catch (error) {
      console.error(error);
      setisReportIssueVisible(false)
    }
    setisReportIssueVisible(false)
    // setDropDownVisible(false);
  };

  const showReportModal = () => {
    return (
      <Modal
        width={350}
        style={{ borderWidth: "0px", borderRadius: "4px", overflow: "hidden" }}
        centered
        title={
          <div style={{ width: "100%", cursor: "move", fontWeight: "bold", borderWidth: "0px" }}
            onFocus={() => { }}
            onBlur={() => { }}
          // end
          >
            Report Post
          </div>
        }
        visible={isReportIssueVisible}
        onCancel={handleCancel}
        footer={[
          <Button size="large" className="report-button" style={{ width: "100%" }} key="report"
            onClick={() => handleOk(value)}>
            Report
          </Button>
        ]}
      >
        <Radio.Group onChange={onChangeValue} value={value} buttonStyle="outline">
          <Space direction="vertical">
            <Radio style={{ color: "grey" }} value='Fraude or pyramid Marketing'>
              Fraude or pyramid Marketing
            </Radio>
            <Radio style={{ color: "grey" }} value='Promoting/Selling illegal or regulated goods'>
              Promoting/Selling illegal or regulated goods
            </Radio>
            <Radio style={{ color: "grey" }} value='Promotes Terrorism'>
              Promotes Terrorism
            </Radio>
            <Radio style={{ color: "grey" }} value='Discrimination, Harrassment or Bullying'>
              Discrimination, Harrassment or Bullying
            </Radio>
            <Radio style={{ color: "grey" }} value='Child Abuse'>
              Child Abuse
            </Radio>
          </Space>
        </Radio.Group>
      </Modal>
    )
  }

  getStorageData("loginUser")
    .then((res) => setLoginId(res.id))
    .catch((err) => console.log(err));

  useEffect(() => {
    console.log(item)
    setFollow(props?.following)
    setLike(item?.attributes?.like_count)
    setLiked(item?.attributes?.liked)
    // toggleLike(item?.id,item?.attributes?.liked)
  }, [props.following])

  const handleMenuClick = (e: any, id: any) => {
    console.log("click =>", e, id);
    if (e === "save") {
      // setDropDownVisible(true);
      savePost(id, "save");
    }
    if (e === "hide") {
      savePost(id, "hide");
    }
    if (e === "report") {
      // setDropDownVisible(true);
      setisReportIssueVisible(true)
      setreportPostID(id)
      //   setState({ isReportIssueVisible: true, reportPostID: id });
    }
  }

  const savePost = async (id: any, action: string) => {
    try {
      const response = await axios({
        url:
          `${baseURL}/bx_block_posts/posts/${id}/${action}`,
        method: "POST",
        headers: {
          token: await localStorage.getItem('token'),
          "Content-Type": "application/json"
        }
      });
      console.log(response, "toggle likes");
    } catch (error) {
      console.error(error, 'error while calling api follow REQUEST');
    }
  }


  const toggleLike = async (id: number, item: any) => {
    // if (liked) {
    let likedToggle: boolean = !liked

    if (likedToggle === item?.attributes?.liked) {
      // console.log(item?.attributes?.like_Count,item)
      let likeCo = item?.attributes?.like_count

      if (likedToggle) {
        setLiked(likedToggle)
        setLike(likeCo)
        await updatelikePost(id, 'like');

      } else {
        setLiked(likedToggle)
        setLike(likeCo)
        await updatelikePost(id, 'dislike');

      }
    } else {
      if (likedToggle) {
        let likeCo = like + 1
        setLiked(likedToggle)
        setLike(likeCo)
        await updatelikePost(id, 'like');

      } else {
        let likeCo = like - 1
        setLiked(likedToggle)
        setLike(likeCo)
        await updatelikePost(id, 'dislike');
      }
    }

  };



  const updatelikePost = async (id: number, updateLikes: any) => {
    try {
      const response = await axios({
        url:
          `${baseURL}/bx_block_posts/posts/${id}/${updateLikes}`,
        method: "POST",
        headers: {
          token: await localStorage.getItem('token'),
          "Content-Type": "application/json"
        }
      });
      console.log(response, "toggle likes");
    } catch (error) {
      console.error(error, 'error while calling api follow REQUEST');
    }
  };

  const addComment = async (id: number, comment: any) => {
    setCommentLoader(false)
    let httpBody = {
      data: {
        attributes: {
          post_id: id,
          comment: comment,
        },
      },
    };
    try {
      const response = await axios({
        url:
          `${baseURL}/bx_block_comments/comments`,
        method: "POST",
        headers: {
          token: await localStorage.getItem('token'),
          "Content-Type": "application/json"
        },
        data: JSON.stringify(httpBody)
      });
      setCommentLoader(false)
      getComments(item.id)

      console.log(response, "comments added");
    } catch (error) {
      console.error(error, 'error while calling api follow REQUEST');
    }
  };

  const commentLikeStatus = async (id: number, itemId: number, status: string) => {
    let httpBody = {
      data: {
        attributes: {
          post_id: id,
        },
      },
    };
    try {
      const response = await axios({
        url:
          `${baseURL}/bx_block_comments/comments/${id}/${status}`,
        method: "POST",
        headers: {
          token: await localStorage.getItem('token'),
          "Content-Type": "application/json"
        },
        data: JSON.stringify(httpBody)
      });
      const list = items?.attributes?.comments?.data?.map((item1: any, index: number) => {
        if (id == item1?.id) {
          return response.data.data
        }
        return item1
      })
      setItems(
        { ...items, attributes: { ...items.attributes, comments: { data: list } } }
      )
    } catch (error) {
      console.error(error, 'error while calling', status, 'REQUEST');
    }
  }

  const toggleCommentLike = async (id: number, liked: boolean, itemId: number) => {
    console.log(id, liked, itemId);
    if (liked) {
      commentLikeStatus(id, itemId, 'dislike')
    } else {
      commentLikeStatus(id, itemId, 'like')
    }
  };

  const addReplyComment = async (id: number, comment: any) => {
    let body = {
      data: {
        attributes: {
          comment_id: id,
          comment: comment,
          // "taggings_attributes": [
          //     {
          //         "account_id": 2
          //     }
          // ]
        },
      },
    };
    try {
      const response = await axios({
        url:
          `${baseURL}/bx_block_comments/comments?post_id=${id}`,
        method: "POST",
        headers: {
          token: await localStorage.getItem('token'),
          "Content-Type": "application/json"
        },
        data: JSON.stringify(body)
      });
      // window.location.reload();
      getComments(item.id)
    } catch (error) {
      console.error(error, 'error while calling comment REQUEST');
    }

  }

  const getComments = async (id: number) => {
    let httpBody = {
      data: {
        attributes: {
          post_id: id,
        },
      },
    };
    try {
      const response = await axios({
        url:
          `${baseURL}/bx_block_comments/comments?post_id=${id}`,
        method: "GET",
        headers: {
          token: await localStorage.getItem('token'),
          "Content-Type": "application/json"
        },
        data: JSON.stringify(httpBody)
      });
      // window.location.reload();

      console.log(items, response.data)
      setItems(
        { ...items, attributes: { ...items.attributes, comments: { data: response.data.data } } }
      )
      console.log(response, "comments added");
    } catch (error) {
      console.error(error, 'error while calling api follow REQUEST');
    }
  };

  const onClickFollow = async (id: number, isFollowed: boolean) => {
    // console.log(id,isFollowed,'follow fun')
    try {
      const response = await axios({
        url:
          `${baseURL}/bx_block_follow/follows?account_id=${id}`,
        method: "POST",
        headers: {
          token: await localStorage.getItem('token'),
          "Content-Type": "application/json"
        }
      });
      console.log(response)
      setFollow(response?.data?.data?.attributes?.following)

      if (isFollowed) {
        setFollowedItems(followedItems.filter(function (el: any) {
          return el !== id;
        }));
      } else {
        setFollowedItems((prevState: any) => [...prevState, id]);
      }
      console.log(response, "following post");
    } catch (error) {
      console.error(error, 'error while calling follow REQUEST');
    }
  };
  console.log(like)

  return (
    <>
      {showReportModal()}
      <div style={{ marginBottom: "20px" }}>
        <CustomPost
          menu={{ isCommunity: false, isCommentloader: commentLoader }}
          handlePostList={props?.handlePostList}
          loginUserId={loginId}
          data={item?.attributes}
          handleMenuClick={(e: any) => handleMenuClick(e, item.id)}
          isFollowed={follow}
          toggleFollow={props.onHandleFollow}
          handleEditPostOpen={() => console.log('handlecreatePostOpen method')}
        />
      </div>
    </>
  );
};

export default UserPost;
