import React from "react";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Redirect } from "react-router-dom";
import { RouterProps } from "react-router";
export const configJSON = require("./config");
import { ApiDataType } from "./interface";

export interface HistoryStateProps {
    heading: string,
    id?: string | number
}

export interface Props extends RouterProps {
    // Customizable Area Start
    communityId: number | string;
    handleCreateAndEditCommunity?: (value: number | string) => void,
    // Customizable Area End
}

interface S {
    // Customizable Area Star
    redirect: boolean,
    isLoading: boolean,
    token: string,
    communityHeading: string | null;
    communityId: string | number | null;
    loadCommunity: boolean;
    whichApiCall: {
        myCommunityForum: boolean,
        communityForum: boolean,
        allCommunityForum: boolean,
        communityPeopleAlsoFollow: boolean,
    };
    paginationOfPageCount: number;
    clickToSeeMoreShow: boolean;
    communityList: Array<ApiDataType>;
    clickToSeeMoreLoading: boolean;
    isCreateCommunityVisible: boolean;
    isCreateCommunity: boolean;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    // Customizable Area End
}



export default class AllCommunityController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getAllCommunityDataId: string = "";

    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage)
        ];

        this.state = {
            // Customizable Area Start
            redirect: false,
            isLoading: false,
            token: "",
            communityHeading: null,
            communityId: null,
            loadCommunity: false,
            whichApiCall: {
                myCommunityForum: false,
                communityForum: false,
                allCommunityForum: false,
                communityPeopleAlsoFollow: false
            },
            paginationOfPageCount: 1,
            clickToSeeMoreShow: false,
            communityList: [],
            clickToSeeMoreLoading: false,
            isCreateCommunityVisible: false,
            isCreateCommunity: false,
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area End
    }

    hanleLoadmore = async () => {
        await this.setState({
            paginationOfPageCount: this.state.paginationOfPageCount + 1,
            clickToSeeMoreLoading: true
        });
        await this.getAllCommunity();
    }
    handleModalOpen() {
        this.setState({
            isCreateCommunity: true,
            isCreateCommunityVisible: true,
        })
    }
    async componentDidMount() {
        let token = await localStorage.getItem("token");
        if (token && token !== null && token !== undefined) {
            this.setState({ redirect: false, token: token });
        } else {
            this.setState({ redirect: true, token: "" });
        }
        const communityInfo = this.props.history.location.state as HistoryStateProps
        if (communityInfo?.heading) {
            this.setState({
                communityHeading: communityInfo?.heading,
                communityId: communityInfo.id ? communityInfo.id : null,
                loadCommunity: true
            });
            if (communityInfo?.heading === "My Community Forum") {
                this.setState({ whichApiCall: { ...this.state.whichApiCall, myCommunityForum: true, communityForum: false, allCommunityForum: false, communityPeopleAlsoFollow: false } });
                this.getAllCommunity();
            }
            if (communityInfo?.heading === "Community Forum") {
                this.setState({ whichApiCall: { ...this.state.whichApiCall, communityForum: true, myCommunityForum: false, allCommunityForum: false, communityPeopleAlsoFollow: false } });
                this.getAllCommunity();
            }
            if (communityInfo?.heading === "All Community Forum") {
                this.setState({ whichApiCall: { ...this.state.whichApiCall, myCommunityForum: false, communityForum: false, allCommunityForum: true, communityPeopleAlsoFollow: false } });
                this.getAllCommunity();
            }
            if (communityInfo?.heading === "Community people also follow") {
                this.setState({ whichApiCall: { ...this.state.whichApiCall, myCommunityForum: false, communityForum: false, allCommunityForum: false, communityPeopleAlsoFollow: true } });
                this.getAllCommunity();
            }
        }
        else {
            this.setState({ whichApiCall: { ...this.state.whichApiCall, myCommunityForum: false, communityForum: false, allCommunityForum: true, communityPeopleAlsoFollow: false } });
            this.getAllCommunity();
        }
    }

    redirectUser() {
        if (this.state.redirect) {
            return <Redirect to="/" />;
        }
    }

    handleCancelOfCreateCommunity = async () => {
        this.setState({
            isCreateCommunityVisible: false,
            isCreateCommunity: false
        });
    }
    handleOkOfCreateCommunity = async () => {
        this.setState({
            isCreateCommunityVisible: false,
            isCreateCommunity: false
        });
    }

    handleCreateCommunity = async (id: string | number) => {
        await this.props.history.replace({
            pathname: '/community/block', state: {
                communityId: id
            }
        });
    }

    getAllCommunity = async () => {
        const token = localStorage.getItem("token");
        const header = {
            "Content-Type": configJSON.getCommunityApiContentType,
            token: token,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getAllCommunityDataId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            this.state.whichApiCall.myCommunityForum ?
                `${configJSON.getMyCommunityForumApiEndPoint}&page=${this.state.paginationOfPageCount}&per_page=12` :
                this.state.whichApiCall.communityForum ?
                    `${configJSON.getCommunityForumApiEndPoint}&page=${this.state.paginationOfPageCount}&per_page=12` :
                    this.state.whichApiCall.allCommunityForum ?
                        `${configJSON.getAllCommunityForumApiEndPoint}/?page=${this.state.paginationOfPageCount}&per_page=12` :
                        this.state.whichApiCall.communityPeopleAlsoFollow ?
                            `${configJSON.getCommunityPeopleAlsoFollowApiEndPoint}/${this.state.communityId}/mutual_communities/?page=${this.state.paginationOfPageCount}&per_page=12` :
                            ""
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            header
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getCommunityApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }


    async receive(_from: string, message: Message) {
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );

        let responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            if (apiRequestCallId && responseJson && !responseJson?.errors) {
                if (apiRequestCallId == this.getAllCommunityDataId) {
                    if (responseJson.communities) {
                        if (responseJson?.communities?.data.length && responseJson?.communities?.data.length % 12 === 0) {
                            this.setState({
                                clickToSeeMoreShow: true
                            })
                        } else {
                            this.setState({
                                clickToSeeMoreShow: false
                            })
                        }
                        this.setState({
                            communityList: [...this.state.communityList, ...responseJson?.communities?.data],
                            loadCommunity: false,
                            clickToSeeMoreLoading: false
                        })
                    } else {
                        this.setState({
                            loadCommunity: false,
                            communityList: [],
                            communityHeading: "No any community"
                        })
                    }
                }
            } else {
                this.setState({
                    loadCommunity: false,
                    communityList: [],
                    communityHeading: "No any community"
                })
            }
        }
        // Customizable Area End
    }

}
