import React from 'react'
import ChatScreenController, { Props } from './ChatScreenController.web';
import {
  Upload,
  Image,
  Avatar,
  Button,
  Input,
  Card,
  Dropdown,
  Menu,
  Space, Badge,
  Row,
  Col,
  Modal,
  Spin,
  Radio,
  AutoComplete,
  Form,
  Divider, Skeleton, Empty, Select
} from "antd";
import {
  // MoreOutlined,
  MoreOutlined,
  SearchOutlined,
  PictureOutlined,
  CheckOutlined,
  SmileOutlined,
  CloseOutlined,
  SendOutlined,
  DownOutlined,
  ArrowLeftOutlined,
  EditOutlined, EllipsisOutlined, SettingOutlined
} from "@ant-design/icons";
const { Option } = Select;
const { Meta } = Card;
import './chatscreen.css';
import moment from "moment";
import Picker from 'emoji-picker-react';
import ReactPlayer from 'react-player';

// Customizable Area Start
import { LinkPreview } from "@dhaiwat10/react-link-preview";

// Customizable Area End

export default class ChatScreenweb extends ChatScreenController {
  constructor(props: Props) {
    super(props);
  }

  renderImage = (id: any, isFullHeight: boolean) => {
    return (
      <Card style={{ width: '100%', padding: 0, height: isFullHeight ? 400 : 200, borderWidth: 0 }} bodyStyle={{ padding: '0px' }} >
        <Image src={this.state.AllMedia[id].filePath} height={isFullHeight ? 380 : 180} width={'100%'}
          style={{ borderRadius: '4px', backgroundColor: 'grey', width: '100%' }}
          wrapperStyle={{ backgroundSize: 'cover', width: '100%' }} />
      </Card>
    )
  }
  renderVideo = (id: any, isFullHeight: boolean) => {
    return (
      <Card style={{ width: '100%', padding: 0, height: isFullHeight ? 400 : 200, borderWidth: 0 }} bodyStyle={{ padding: '0px', backgroundColor: "#FAFAFA" }} >
        <ReactPlayer
          config={{ file: { attributes: { controlsList: 'nodownload', autoplay: false, muted: false } } }}
          url={this.state.AllMedia[id].filePath}
          controls
          height={isFullHeight ? 380 : 180}
          width={'98%'}
          style={{ borderRadius: '4px', backgroundColor: "#FAFAFA" }} />
      </Card>
    )
  }
  checkMedia = (id: any, isFullHeight: boolean) => {
    return this.state.AllMedia[id].type.slice(0, 5) == "video" ? this.renderVideo(id, isFullHeight) : this.renderImage(id, isFullHeight)
  }
  renderMedia = (imgArray: any) => {
    switch (imgArray.length) {
      case 0:
        return null;
      case 1:
        return (
          <Row style={{ width: '100%' }} >
            {/* <Col span={24}  > */}
            {this.checkMedia(0, false)}
            {/* </Col> */}
          </Row>
        )
      case 2:
        return (
          <Row className="flex-row">
            <Col span={12} >{this.checkMedia(0, false)}</Col>
            <Col span={12} >{this.checkMedia(1, false)}</Col>
          </Row>
        )
      case 3:
        return (
          <Row className="flex-row"  >
            <Col span={12} >
              {this.checkMedia(0, true)}
            </Col>
            <Col style={{ flexDirection: 'column' }} span={12} >
              {this.checkMedia(1, false)}
              {this.checkMedia(2, false)}
            </Col>
          </Row>
        )
      case 4:
        return (
          <div >
            <Row style={{ width: '100%' }}>
              <Col span={12} >
                {this.checkMedia(0, false)}
              </Col>
              <Col span={12} >
                {this.checkMedia(1, false)}
              </Col>
            </Row>
            <Row style={{ width: '100%' }}>
              <Col span={12} >{this.checkMedia(2, false)}</Col>
              <Col span={12} > {this.checkMedia(3, false)}</Col>
            </Row>
          </div>
        )
      default:
        return (
          <div >
            <Row style={{ width: '100%' }}>
              <Col span={12} >
                {this.checkMedia(0, false)}
              </Col>
              <Col span={12} >
                {this.checkMedia(1, false)}
              </Col>
            </Row>
            <Row style={{ width: '100%' }}>
              <Col span={12} >{this.checkMedia(2, false)}</Col>
              <Col span={12} >
                {this.checkMedia(3, false)}
                <Button size='large' type='text' style={{ marginTop: -100 }} >{`+${this.state.AllMedia.length - 3} More`} </Button>
              </Col>
            </Row>
          </div>
        )
    }
  };

  renderItem = (response: any, searchPage: any, value: any) => ({
    label: (
      <>
        {(response?.data && response?.data?.length > 0) ? (
          <div style={{}}>
            {response?.data?.map((item: any) => {
              console.log(item, 'demo***')
              return (
                <div
                  key={item?.id}
                  style={{
                    margin: 10,
                  }}
                  className='search_results_user'
                  onClick={() => {
                    this.viewUser(item?.attributes?.account_id?.toString(), this.state.userProfile.attributes?.profile?.data?.attributes?.account_id);
                    this.setState({
                      activeUserTab: {
                        name: item?.attributes?.full_name,
                        msgType: this.state.activeUserTab?.msgType,
                        msg: this.state.activeUserTab?.msg,
                        channelUrl: this.state.activeUserTab?.channelUrl,
                        myMutedState: this.state.activeUserTab?.myMutedState,
                        avatar: item?.attributes?.photo,
                        metaData: item?.attributes.profile.data.attributes.domain_skills?.join(', '),
                        createdAt: this.state.activeUserTab?.createdAt,
                        userId: item?.attributes?.account_id?.toString()
                      }
                    })
                  }}
                >

                  <div style={{ display: 'flex', alignItems: 'center', }}>
                    <SearchOutlined translate={undefined} />
                    {console.log(item, "item")}
                    <span style={{ marginLeft: 8 }}>{item?.attributes?.role === "business" ?
                      item?.attributes?.profile?.data?.attributes.company_name
                      :
                      item?.attributes?.full_name}</span>
                    {item?.attributes?.role == 'business' &&
                      <span style={{ marginLeft: 8, opacity: 0.6, fontSize: 12.5 }}>{
                        item?.attributes?.profile?.data?.attributes?.business_industry
                      }</span>}
                    {item?.attributes?.role == 'personal' &&
                      <>
                        <span style={{ marginLeft: 8, opacity: 0.6, fontSize: 12.5 }}>{
                          item?.attributes?.profile?.data?.attributes?.designation?.title
                        }</span>

                      </>
                    }
                  </div>
                  <div>
                    <span style={{ marginRight: 8 }}>
                      {item?.attributes?.role}
                    </span>
                    <Avatar
                      size="small"
                      src={item?.attributes?.photo}
                    />
                  </div>
                </div>
              )
            })}
          </div>
        ) : (
          <>
            <span style={{ textAlign: 'center' }}>Not seeing your friends here? You need to <br />follow friend in order to start chat</span>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </>
        )}

        {response?.length > 5 && (
          <div style={{ textAlign: 'center' }}>
            <Button type="link" onClick={() => {
              // props.history.push({ pathname: '/search', state: { "searchResults": searchPage?.data, "searchTxt": value } });
              // window.location.reload();
            }}>See All</Button>
          </div>
        )

        }
      </>
    ),
  })

  renderCustomChatHandlers = () => {
    return (
      <Modal width={400}
        style={{ borderWidth: "0px", borderRadius: "4px", overflow: "hidden" }}
        visible={this.state.customchatHandler}
        title={
          <div
            style={{
              width: "100%",
              cursor: "move",
              fontWeight: "bold",
              borderWidth: "0px"
            }}
            onFocus={() => { }}
            onBlur={() => { }}
          // end
          >
            {this.state.textHeadingModal}
          </div>
        }
        onOk={() => {
          this.setState({ customchatHandler: false });
        }}
        onCancel={() => {
          this.setState({
            customchatHandler: false
          });
        }}
        footer={null}>
        <div
          style={{ borderWidth: "0px" }}
        // bodyStyle={{ padding: "0 10px" }}
        >
          {this.state.modalType === 'mute' ? (
            <p>{this.state.subHeadingModal}</p>
          ) : this.state.modalType === 'mark_read' ? (
            <p>{this.state.subHeadingModal}</p>
          ) : (
            <React.Fragment>
              <p>{this.state.subHeadingModal}</p>
              <div style={{ marginTop: '25px' }}>
                <Radio.Group
                  onChange={this.onChangeValue} value={this.state.radioValues}
                  buttonStyle="outline">
                  <Space direction="vertical">
                    <Radio style={{ color: "grey" }} value='Fraude or pyramid Marketing'>
                      Fraude or pyramid Marketing
                    </Radio>
                    <Radio style={{ color: "grey" }} value='Promoting/Selling illegal or regulated goods'>
                      Promoting/Selling illegal or regulated goods
                    </Radio>
                    <Radio style={{ color: "grey" }} value='Promotes Terrorism'>
                      Promotes Terrorism
                    </Radio>
                    <Radio style={{ color: "grey" }} value='Discrimination, Harrassment or Bullying'>
                      Discrimination, Harrassment or Bullying
                    </Radio>
                    <Radio style={{ color: "grey" }} value='Child Abuse'>
                      Child Abuse
                    </Radio>
                  </Space>
                </Radio.Group>
              </div>
            </React.Fragment>
          )}
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '25px' }}>
            <Button size="large"
              className="create-community-button-chat"
              style={{ marginTop: 0, minWidth: '160px', maxWidth: "180" }}
              disabled={this.state.modalType === 'mute' || this.state.modalType === 'mark_read' ? false :
                this.state.radioValues === null ? true : false
              }
              loading={this.state.isLoadingReport}
              onClick={() => {
                if (this.state.modalType === 'mute') {
                  this.setState({ isLoadingReport: true });
                  return this.muteUnMuteHandler();
                } else if (this.state.modalType === 'mark_read') {
                  this.setState({ isLoadingReport: true });
                  return this.markAsUnreadTheUserChat()
                } else {
                  this.setState({ isLoadingReport: true });
                  return this.reportAccount(this.reportAccount(this.state.activeUserTab.userId.toString()))
                }
              }}
            >
              {this.state.modalType === 'mute' ?
                this.state.activeUserTab?.myMutedState == 'off' ? 'UnMute' : 'Mute' :
                this.state.modalType === 'mark_read' ? 'Mark as UnRead' : 'Report User'}
            </Button>
            <Button size="large"
              className="closeModal"
              style={{ marginTop: 0, width: '160px' }}
              onClick={() => {
                this.setState({
                  customchatHandler: false
                });
              }}
            >
              close
            </Button>


          </div>
        </div>
      </Modal>
    );
  }

  onclickMenu = (e: any) => {
    console.log(e, 'onclick');
    if (e.key === 'mute') {
      console.log(this.state.activeUserTab, 'activeUserTab')
      return this.setState({
        modalType: 'mute',
        textHeadingModal: this.state.activeUserTab?.myMutedState == 'off' ? `UnMute ${this.state.activeUserTab?.name}` : `Mute ${this.state.activeUserTab?.name}`,
        subHeadingModal: this.state.activeUserTab?.myMutedState == 'off' ? `Are you sure you want unmute ${this.state.activeUserTab?.name}` : `Are you sure you want mute ${this.state.activeUserTab.name}`,
        customchatHandler: true,
      })
    } else if (e.key === 'mark-unread') {
      return this.setState({
        modalType: 'mark_read',
        textHeadingModal: 'Mark as UnRead',
        subHeadingModal: `Are you sure you want unread messages`,
        customchatHandler: true
      })
    } else {
      return this.setState({
        modalType: 'report',
        textHeadingModal: `Report the ${this.state.activeUserTab.name}`,
        subHeadingModal: `Are you sure you want to report ${this.state.activeUserTab.name}`,
        customchatHandler: true
      })
    }
  }

  menu = () => {
    return (
      <Menu onClick={this.onclickMenu}>
        <Menu.Item key="mute">{this.state.activeUserTab?.myMutedState == 'off' ? 'UnMute' : 'Mute'}</Menu.Item>
        <Menu.Item key="mark-unread">Mark as unread</Menu.Item>
        <Menu.Item key="report-user">Report this user</Menu.Item>
      </Menu>
    )
  }

  deleteDropDown = (msg: any, index: any) => {
    return (
      <Menu>
        <Menu.Item key="delete" onClick={() => this.setState({ deleteModalVisibility: true, deleteMessageData: { msg: msg, index: index } })}>Delete Message</Menu.Item>
      </Menu>
    )
  }

  renderSendFilePopUp = () => {
    return <Modal width={400}
      style={{ borderWidth: "0px", borderRadius: "4px", overflow: "hidden" }}
      visible={this.state.popupSendFile}
      title={
        <div
          style={{
            width: "100%",
            cursor: "move",
            fontWeight: "bold",
            borderWidth: "0px"
          }}
          onFocus={() => { }}
          onBlur={() => { }}
        // end
        >
          Send Message
        </div>
      }
      onOk={() => {
        this.setState({ popupSendFile: false, AllMedia: [], chatImages: [], videoArrayList: [] });
      }}
      onCancel={() => {
        this.setState({
          popupSendFile: false,
          AllMedia: [], chatImages: [], videoArrayList: []
        });
      }}
      footer={null}>
      <div
        style={{ borderWidth: "0px" }}
      >
        {this.state.AllMedia.length ?
          <Row style={{ paddingTop: "10px", }}>
            {this.renderMedia(this.state.AllMedia)}
          </Row> : null}
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '25px' }}>
          <Button size="large"
            className="create-community-button-chat"
            loading={this.state.sendFileLoad}
            style={{ marginTop: 0, width: '160px' }}
            onClick={() => {
              this.setState({ sendFileLoad: true });
              return this.sendfileMessage();
            }}
          >
            send
          </Button>
          <Button size="large"
            className="closeModal"
            style={{ marginTop: 0, width: '160px' }}
            onClick={() => {
              return this.setState({
                AllMedia: [], chatImages: [], videoArrayList: [],
                popupSendFile: false,
              });
            }}
          >
            close
          </Button>
        </div>
      </div>
    </Modal>
  }

  renderDeleteModal = () => {
    return <Modal width={400}
      style={{ borderWidth: "0px", borderRadius: "4px", overflow: "hidden" }}
      visible={this.state.deleteModalVisibility}
      title={
        <div
          style={{
            width: "100%",
            cursor: "move",
            fontWeight: "bold",
            borderWidth: "0px"
          }}
          onFocus={() => { }}
          onBlur={() => { }}
        // end
        >
          Delete Message
        </div>
      }
      onOk={() => {
        this.setState({ deleteModalVisibility: false, });
      }}
      onCancel={() => {
        this.setState({
          deleteModalVisibility: false,
        });
      }}
      footer={null}>
      <div
        style={{ borderWidth: "0px" }}
      >
        Are you sure want to delete message

        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '25px' }}>
          <Button size="large"
            className="report-button"
            loading={this.state.sendFileLoad}
            style={{ marginTop: 0, width: '160px', background: 'red', color: '#fff', border: '1px solid red' }}
            onClick={() => {
              this.setState({ deleteLoad: true });
              return this.deleteHandler(this.state.deleteMessageData?.msg, this.state.deleteMessageData?.index);
            }}
          >
            Delete
          </Button>
          <Button size="large"
            className="closeModal"
            style={{ marginTop: 0, width: '160px' }}
            onClick={() => {
              this.setState({
                deleteModalVisibility: false,
              });
            }}
          >
            close
          </Button>
        </div>
      </div>
    </Modal>
  }

  render() {
    // console.log(this.state?.List, 'list__')
    return (
      <div>
        {this.renderCustomChatHandlers()}
        {this.renderSendFilePopUp()}
        {this.renderDeleteModal()}
        {this.redirectUser()}

        <React.Fragment>
          <Row
          //  style={{ border: '1px solid red' }}
          >
            {/* List of Chat users */}
            <Col xs={24} sm={24} md={12} lg={10} xl={7}
              style={{
                display: this.state.activeUserTab?.userId && this.state.MobileResponsiveWidth ? "none" : "block",
                position: 'relative',
                background: '#f1f5ff',
                padding: '1rem 0',
                maxHeight: '88vh',
              }}>
              <div style={{
                // height: '85vh', 
                width: '100%'
              }}>
                <h2 style={{ paddingLeft: '38px' }}>Messages</h2>

                <div style={{ padding: '0 38px' }}>
                  <AutoComplete
                    dropdownClassName="certain-category-search-dropdown"
                    dropdownStyle={{ borderRadius: "8px", overflow: 'hidden' }}
                    dropdownMatchSelectWidth={300}
                    defaultValue={this.state.searchTxt}
                    value={this.state.searchTxt}
                    style={{
                      width: '100%',
                      borderRadius: '8px'
                    }}
                    options={this.state.searchTxt ? this.state.option : []}
                  >
                    <Input
                      size='middle'
                      placeholder="Search Contact"
                      style={{ width: '100%', borderRadius: '15px', height: '39px' }}
                      value={this.state?.searchTxt ? this.state?.searchTxt : null}
                      prefix={this.state.searchLoading ? <Spin /> : <SearchOutlined translate={undefined} style={{ color: '#d1d1d3', fontSize: 22 }} />}
                      suffix={this.state.searchTxt ? <CloseOutlined translate={undefined} onClick={() => {
                        this.setState({ searchTxt: '', searchData: [] })
                        // this.callDefaultApis();
                      }} /> : <></>}
                      onChange={e => {
                        this.setState({ searchTxt: e.target.value })
                        if (e.target.value.trim().length > 0) {
                          this.callDefaultApis();
                        } else {
                          this.setState({ option: [], searchData: [] })
                        }
                      }}
                    />
                  </AutoComplete>
                </div>
                <Divider style={{ marginBottom: 0 }} />
                {/* side bar */}
                <div className='side_bar' style={{
                  height: '100%'
                }}
                  id='scrollStyle'
                >
                  {/* {this.state.List?.map((c: any) => (console.log(c.members)))} */}
                  {this.state?.List?.length > 0 ? (
                    this.state.List?.sort((a: any, b: any) => b.lastMessage?.createdAt - a.lastMessage?.createdAt).map((c: any, i: any) => {
                      // console.log(c.lastMessage?.createdAt, "lastmessage")
                      let userDetails = c.members.filter((e: any) => e.userId.toString() !== this.state.userProfile?.attributes?.profile?.data?.attributes?.account_id.toString())[0]
                      // console.log(this.state?.List, 'list__')
                      if (this.state.activeUserTab?.userId === userDetails?.userId && c?.unreadMessageCount > 0) {
                        this.readUserMessages(c?.url)
                      }
                      if (c.members.length > 1) {
                        return (
                          <React.Fragment key={c?.url}>
                            {/* {console.log(c)} */}
                            <div style={{ background: this.state.activeUserTab?.userId?.trim() == userDetails?.userId?.trim() ? '#d5dff4' : 'rgb(241, 245, 255)', }}
                              className='custom_ChatUser'
                              onClick={() => {
                                this.viewUser(userDetails?.userId?.toString(), this.state.userProfile.attributes?.profile?.data?.attributes?.account_id, c?.url);
                                return this.setState({
                                  activeUserTab: {
                                    name: userDetails?.nickname,
                                    msgType: c?.lastMessage?.messageType,
                                    msg: c?.lastMessage?.message,
                                    channelUrl: c?.url, myMutedState: c?.myPushTriggerOption,
                                    avatar: userDetails?.plainProfileUrl,
                                    createdAt: c?.lastMessage?.createdAt,
                                    metaData: userDetails?.metaData?.designation,
                                    userId: userDetails?.userId
                                  },
                                  scrollChat: true,
                                })
                              }}>
                              <Skeleton loading={this.state.isLoading} avatar active paragraph={{ rows: 1 }}>
                                <div style={{ display: 'flex' }}>
                                  <div style={{ paddingLeft: '16px' }}><Avatar src={userDetails?.plainProfileUrl ? userDetails?.plainProfileUrl : "https://joeschmoe.io/api/v1/random"} size={'large'} /></div>

                                  <div style={{
                                    position: 'relative',
                                    display: 'flex', justifyContent: 'space-between',
                                    color: '#242424', padding: '0 10px', width: '100%'
                                  }}>
                                    <div style={{ maxWidth: '130px', overflow: 'hidden' }}>
                                      {/* <div style={{ color: '#3f4044' }}>{this.state.activeUserTab?.userId?.trim() +' and  '+ userDetails?.userId?.trim()}</div> */}
                                      <div style={{ color: '#3f4044' }}>{userDetails?.nickname ? userDetails?.nickname : "No Name"}</div>
                                      <div style={{ color: '#b1b3b5', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                        {c?.typing ? 'typing...' : c?.lastMessage?.messageType === 'file' ? c.lastMessage?.type.includes('image') ? 'Image' : 'Video' : c?.lastMessage?.message}
                                      </div>
                                    </div>
                                    <div style={{ color: '#9fa0a6' }}>
                                      <span>{c.lastMessage?.createdAt && moment(c.lastMessage?.createdAt).fromNow()}</span>
                                      <div style={{ textAlign: 'right' }}>
                                        {c?.unreadMessageCount > 0 && (
                                          <Badge
                                            style={{
                                              alignItems: "center",
                                              padding: 0,
                                              marginRight: "16px",
                                            }}
                                            count={c?.unreadMessageCount}
                                          />
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Skeleton>
                            </div>
                            <Divider style={{ margin: 0 }} />
                          </React.Fragment>
                        )
                      }
                    })
                  ) :
                    (
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    )}
                </div>
              </div>
            </Col>

            {/* Chat screen */}
            {/* {console.log("activeUserTab", this.state.activeUserTab)} */}
            {(this.state.activeUserTab && this.state.activeUserTab?.userId) ? (
              <>
                <Col
                  className="chat_screen_visiblity"
                  xs={24} sm={24} md={12} lg={14} xl={17}
                  style={{
                    display: this.state.MobileResponsiveWidth ? this.state.activeUserTab?.userId ? "block" : "none" : 'block',
                    height: '88vh',
                  }}>
                  {/* active user header */}

                  <div
                    className='head_user_short_info'
                    style={{ padding: '16px 16px 0 16px' }}
                  >

                    <Skeleton loading={this.state.isLoading} avatar active paragraph={{ rows: 1 }}>
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            {this.state.MobileResponsiveWidth && <ArrowLeftOutlined
                              translate={""}
                              onClick={() => {
                                this.setState({
                                  activeUserTab: { name: 'test1', msg: 'hii', time: '10:21AM' },
                                })
                              }}
                              style={{ width: 24, height: 20, marginRight: 5 }}
                            />}
                            <div><Avatar src={this.state.activeUserTab?.avatar ? this.state.activeUserTab?.avatar : "https://joeschmoe.io/api/v1/random"} size={'large'} style={{ marginRight: '16px' }} /></div>

                            <div style={{
                              position: 'relative',
                              display: 'flex', justifyContent: 'space-between',
                              color: '#242424',
                            }}>
                              <div
                              >
                                <div style={{ color: '#3f4044' }}>{this.state.activeUserTab?.name ? this.state.activeUserTab?.name : 'No Name'}</div>
                                <div style={{ color: '#b1b3b5', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{this.state.activeUserTab?.metaData ? this.state.activeUserTab?.metaData : 'domain skills or company name'}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <Dropdown overlay={this.menu()}>
                            <a className="ant-dropdown-link" >
                              <MoreOutlined translate={undefined} style={{ fontSize: '18px' }} />
                            </a>
                          </Dropdown>
                        </div>
                      </div>

                    </Skeleton>
                  </div>

                  <div style={{ padding: '0' }}>
                    <Divider style={{ margin: '10px 0 0' }} />
                  </div>
                  {this.state.loadMessages && (
                    <Skeleton avatar paragraph={{ rows: 1 }} active />
                  )}
                  <div className='chat_screen'
                    onScroll={this.handleScroll}
                  >
                    {this.state.MessageList?.length > 0 ? this.state.MessageList?.map((c: any, index: any) => {
                      let userDetail = c?._sender;
                      const nextIndexData = index + 1 < this.state.MessageList?.length ? this.state.MessageList[index + 1] : {}
                      const prevIndexData = index - 1 < this.state.MessageList?.length ? this.state.MessageList[index - 1] : {}
                      // const isFromSameUser = nextIndexData?.sender?.userId == userDetail?.userId
                      const isFromPrevUser = prevIndexData?._sender?.userId != userDetail?.userId;
                      let previousDate = "";
                      let data: any = this.state.MessageList?.length ? this.state.MessageList[index - 1]?.createdAt : new Date();
                      if (index > 0) {
                        previousDate = moment(data).format(
                          "L"
                        );
                      } else {
                        previousDate = moment(c?.createdAt).format("L");
                      }
                      let currentDate = moment(c?.createdAt).format("L");
                      let current_date = new Date();
                      let yesterdayTime = current_date.setDate(current_date.getDate() - 1)

                      // console.log("channel.getUndeliveredMemberCount(message)", c.message, index, this.state.GroupChannel.getUndeliveredMemberCount(c))
                      // console.log("channel.getReadMembers(message)", c.message, index, this.state.GroupChannel.getReadMembers(c))

                      let recieptRead = this.state.GroupChannel.getReadMembers(c).length > 0 ? 'check-all' : this.state.GroupChannel.getUndeliveredMemberCount(c) == 0 ? 'check-all count 0' : c.sendingStatus === "succeeded" ? 'check undeliver' : 'time-outline'

                      return (
                        <div key={index} style={{ padding: '0 1rem' }}>
                          {this.state.unreadMessages > 0 ? this.state.unreadMessages === index && (
                            <div className='custom_divider_main'>
                              {
                                <Divider className='custom_divider' >New Messages</Divider>}
                            </div>
                          ) : <></>}
                          <div className='custom_divider_main'>
                            {previousDate && !moment(currentDate).isSame(previousDate, "day") ?
                              <Divider className='custom_divider' >{moment(c?.createdAt).format("DD/MM/YYYY") === moment(new Date).format("DD/MM/YYYY") ?
                                'Today' : moment(c?.createdAt).format("DD/MM/YYYY") === moment(yesterdayTime).format("DD/MM/YYYY") ? 'Yesterday'
                                  : moment(c?.createdAt).format("DD/MM/YYYY")}</Divider> : <></>}
                          </div>
                          {c?._sender && c?._sender?.userId?.toString() === this.state?.userProfile.attributes?.profile?.data?.attributes?.account_id?.toString() ? (
                            // own message initial render
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'flex-end',
                                flexDirection: 'column',
                                padding: '0 1rem ',
                                // border: '1px solid red',
                                height: '100%'
                              }}>
                              <div style={{ display: 'flex' }}>
                                <div style={{
                                  position: 'relative',
                                  display: 'inline-block',
                                  color: '#242424', background: c.messageType === 'user' ? '#ccd7f2' : 'none',
                                  marginTop: '4px', padding: '10px',
                                  borderBottomRightRadius: '10px',
                                  borderTopLeftRadius: '10px',
                                  borderBottomLeftRadius: '10px',
                                  maxWidth: '280px',
                                }}
                                  className='own_msg_card'
                                >
                                  {/* <div className='own_msg_card_delete'>
                                  <Dropdown overlay={this.deleteDropDown(c, index)}>
                                    <a className="ant-dropdown-link" href="#">
                                      <MoreOutlined translate={undefined} style={{ color: '#000', fontSize: '18px' }} />
                                    </a>
                                  </Dropdown>
                                </div> */}
                                  {c.messageType === 'file' ? c?.type.includes('image') ?
                                    <Image src={c?.url} style={{ height: '100px', width: '100px', borderRadius: '5px' }} /> :
                                    <ReactPlayer
                                      config={{ file: { attributes: { controlsList: 'nodownload', autoplay: false, muted: false } } }}
                                      url={c?.url}
                                      controls
                                      height={180}
                                      width={'98%'}
                                      style={{ borderRadius: '4px', overflow: 'hidden', backgroundColor: "#FAFAFA" }} />
                                    : (
                                      <React.Fragment>
                                        {(c?.message?.split(':')[0] == 'https' || c?.message?.split(':')[0] == 'http') && (
                                          <LinkPreview
                                            margin="30px auto"
                                            width="100%"
                                            url={c?.message}
                                          />
                                        )}

                                        {c?.message?.split(" ").length === 1 && (c?.message?.split(':')[0] == 'https' || c?.message?.split(':')[0] == 'http') ?
                                          <a target='_blank' href={`${c?.message}`}>{c?.message}</a>
                                          :
                                          <span>{c?.message}</span>
                                        }
                                        {/* <span>{recieptRead}</span> */}
                                        {/* <span style={{position:'absolute',left:0}}>{recieptRead ==='check-all' ?<CheckOutlined translate={undefined} style={{fontSize:'10px',color:'blue'}} />: recieptRead ==='check-all' ?'':<CheckOutlined translate={undefined} style={{fontSize:'10px',color:'#000'}} />}</span> */}
                                        {/* <span>{recieptRead ==='check-all' ?<CheckOutlined translate={undefined} style={{fontSize:'10px',color:'blue'}} />: recieptRead ==='check-all' ?'':<CheckOutlined translate={undefined} style={{fontSize:'10px',color:'#000'}} />}</span> */}
                                      </React.Fragment>
                                    )}
                                </div>
                                <div className='_avatar_visibility_' style={{ paddingLeft: '16px', visibility: isFromPrevUser ? 'visible' : 'hidden' }}><Avatar src={this.state.userProfile.attributes?.profile?.data?.attributes?.photo} size={'large'} /></div>
                              </div>
                              <div style={{ marginTop: '4px', textAlign: 'right', color: '#b1b3b5', paddingRight: '58px', fontSize: '12px' }}>
                                <span style={{ paddingRight: '7px' }}>{recieptRead === 'check-all' ?
                                  <span>
                                    <CheckOutlined translate={undefined} style={{ fontSize: '10px', color: 'blue' }} />
                                    <CheckOutlined translate={undefined} style={{ fontSize: '10px', color: 'blue', marginLeft: -6 }} />
                                  </span>

                                  : recieptRead === 'check-all' ? '' :
                                    <span>
                                      <CheckOutlined translate={undefined} style={{ fontSize: '10px', color: '#000' }} />
                                      <CheckOutlined translate={undefined} style={{ fontSize: '10px', color: '#000', marginLeft: -6 }} />
                                    </span>
                                }</span>
                                {c?.createdAt ? moment(c?.createdAt).format('hh:mm A',) : this.formatAMPM(new Date())}</div>
                            </div>
                          ) : (
                            // user message initial render
                            <Skeleton loading={this.state.isLoading} avatar active paragraph={{ rows: 1 }}>
                              <Meta
                                avatar={<div className='_avatar_visibility_' style={{ visibility: isFromPrevUser ? 'visible' : 'hidden' }}>
                                  <Avatar src={this.state.activeUserTab?.avatar ? this.state.activeUserTab?.avatar : "https://joeschmoe.io/api/v1/random"} size={'large'} />
                                </div>}
                                description={<div style={{
                                  display: 'inline-block',
                                  color: '#242424', background: c.messageType === 'user' ? '#ccd7f2' : 'none',
                                  marginTop: '4px', padding: '10px',
                                  borderBottomRightRadius: '10px',
                                  borderTopRightRadius: '10px',
                                  borderBottomLeftRadius: '10px',
                                  maxWidth: '280px'
                                }}
                                  className='user_msg_card'
                                >
                                  {/* <div className='user_msg_card_delete'>
                                  <Dropdown overlay={this.deleteDropDown(c, index)}>
                                    <a className="ant-dropdown-link" href="#">
                                      <MoreOutlined translate={undefined} style={{ color: '#000', fontSize: '18px' }} />
                                    </a>
                                  </Dropdown>
                                </div> */}
                                  {c.messageType === 'file' ? c?.type.includes('image') ?
                                    <Image src={`${c?.url}`} style={{ height: '100px', width: '100px', borderRadius: '5px' }} />
                                    : <ReactPlayer
                                      config={{ file: { attributes: { controlsList: 'nodownload', autoplay: false, muted: false } } }}
                                      url={c?.url}
                                      controls
                                      height={180}
                                      width={'98%'}
                                      style={{ borderRadius: '4px', overflow: 'hidden', backgroundColor: "#FAFAFA" }} />
                                    : (
                                      <React.Fragment>
                                        {(c?.message?.split(':')[0] == 'https' || c?.message?.split(':')[0] == 'http') && (
                                          <LinkPreview
                                            margin="30px auto"
                                            width="100%"
                                            url={c?.message}
                                          />
                                        )}
                                        {

                                          (c?.message?.split(" ").length === 1 &&
                                            (c?.message?.split(':')[0] == 'https' || c?.message?.split(':')[0] == 'http')) ?

                                            <a target='_blank' href={`${c?.message}`}>{c?.message}</a>
                                            :
                                            <span>{c?.message}</span>
                                        }
                                      </React.Fragment>
                                    )}
                                </div>}
                              />
                              <div style={{ marginTop: '4px', color: '#b1b3b5', paddingLeft: '58px', fontSize: '12px' }}>{moment(c?.createdAt).format('hh:mm A',)}</div>
                            </Skeleton>
                          )}
                          {this.state?.MessageList?.length && this.state.MessageList?.length - 1 === index && (
                            <div style={{
                              // position: 'absolute',
                              // bottom: '100px',
                              padding: '1rem',
                              // background:'red'
                            }} ref={this.messagesEnd as React.RefObject<HTMLDivElement>} />
                          )}
                        </div>
                      )
                    }) : (
                      <div style={{ padding: '1rem' }}>
                        <Skeleton loading={this.state.isLoading} avatar active paragraph={{ rows: 1 }}>
                          <div
                            style={{
                              padding: '1rem 1rem 1rem 0',
                              height: '80vh',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center'
                            }}>
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={
                              <span>
                                No Messages Yet
                              </span>
                            } />
                          </div>
                        </Skeleton>
                      </div>
                    )}

                  </div>

                  <div style={{
                    position: 'absolute',
                    bottom: '100px',
                    right: '50px'
                  }}>
                    {this.state.isEmojiPickerEnable && (
                      <span ref={this.customClose}>
                        <Picker onEmojiClick={this.onEmojiClick} />
                      </span>)}
                  </div>
                  {/* chat box */}
                  <div style={{
                    position: 'absolute',
                    bottom: '-6px',
                    width: '100%',
                    boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'
                  }}>
                    <Card
                      className='bottom_message_card'
                      bodyStyle={{
                        flexDirection: "row",
                        display: "flex",
                        margin: "0",
                      }}
                    >
                      <Input.Group >
                        <Form style={{ display: "flex" }}
                          onKeyDown={e => {
                            if ((e.shiftKey || e.ctrlKey) && e.keyCode === 13) {
                              return;
                            }
                            else if (e.keyCode === 13 && (!e.shiftKey || !e.ctrlKey)) {
                              this.handleSubmit(this.state.newOwnMessage)
                              return this.setState({ newOwnMessage: "" });
                            } else {
                              return;
                            }
                          }
                          }
                          onFinish={() => this.handleSubmit(this.state.newOwnMessage)}><div style={{ display: 'flex', position: "relative", width: '100%', }}>
                            <Input.TextArea
                              ref={this.contentRef}
                              rows={1}
                              style={{
                                resize: 'none',
                                overflow: "hidden",
                                padding: "4px 78px 3px 12px",
                                // maxHeight: "84px",
                                height: "42px",
                                fontSize: "16px",
                                marginRight: "16px",
                                backgroundColor: "#FAFAFA",
                                width: '100%',
                                borderRadius: '15px'
                                // color: "grey",
                              }}
                              value={this.state.newOwnMessage}
                              className="chat-input-textArea"
                              placeholder="Write your comment..."
                              onChange={e => {
                                this.state.GroupChannel.startTyping();
                                this.setState({ newOwnMessage: e.target.value })
                              }
                              }
                              onFocus={() => {
                                this.state.GroupChannel.startTyping();
                              }}
                              onBlur={() => {
                                this.state.GroupChannel.endTyping();
                              }}
                            />
                            <div style={{
                              display: 'flex',
                              position: "absolute",
                              justifyContent: 'flex-end',
                              zIndex: 999,
                              right: 0,
                              marginTop: 4,
                              opacity: 0.6,
                            }}>
                              <div className='smiley_icon' style={{ margin: '0px 6px', }}>

                                <Button
                                  style={{ border: 'none' }}
                                  type="ghost"
                                  shape="circle"
                                  icon={<SmileOutlined translate={undefined} />}
                                  onClick={() => this.setState({ isEmojiPickerEnable: !this.state.isEmojiPickerEnable })}
                                />

                              </div>
                              <div className='upload_icon' style={{ margin: '0px 12px 0px 0px' }}>
                                <Upload
                                  style={{ border: 'none' }}
                                  name='file'
                                  showUploadList={false}
                                  action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                  beforeUpload={(file) => this.beforeUpload(file)}
                                  onChange={this.handleImageChange}
                                >
                                  <Button
                                    style={{ marginRight: "10px", border: 'none' }}
                                    type="ghost"
                                    shape="circle"
                                    icon={<PictureOutlined translate={undefined} />}
                                  />
                                </Upload>
                              </div>
                            </div>
                          </div>
                          <Button style={{ marginRight: "10px", color: " #fff", backgroundColor: "#005574", borderWidth: "0px", width: '30px', height: '39px', padding: '0 0 3px 0' }}
                            type="primary"
                            htmlType='submit'
                            shape="circle"
                            icon={<SendOutlined translate={{}} rotate={-60} />}
                            size={"large"}
                            disabled={this.state.newOwnMessage?.length > 0 ? false : true}
                          // onClick={() => this.handleSubmit(this.state.newOwnMessage)}
                          />
                        </Form>
                      </Input.Group>
                    </Card>
                  </div>

                </Col>

              </>
            ) : (
              <Col xs={24} sm={24} md={12} lg={14} xl={17}
                style={{
                  padding: '1rem 1rem 1rem 0',
                  height: '89vh',
                  display: !this.state.MobileResponsiveWidth ? 'flex' : 'none',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}>
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              </Col>
            )}

          </Row>
        </React.Fragment>
      </div >
    )
  }
}
