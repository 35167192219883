import React from "react";
// Customizable Area Start
import {
    Card,
    Row,
    Col,
    Typography,
    Button,
    Avatar,
    Divider,
    Spin,
} from "antd";
import { style, acceptIcon, rejectIcon } from "../../assets";
const { Text } = Typography;
import {
    UserOutlined,
    EnvironmentOutlined,
    CloseCircleOutlined,
    CheckCircleTwoTone,
} from "@ant-design/icons";
import { useHistory } from 'react-router-dom';
// Customizable Area End

const NetworkComponent = (props: any) => {
    // Customizable Area Start

    const { others, showAcceptRequestModal, data, acceptModel, cardid } = props;
    const history = useHistory();

    const handleOpenOtherBusinessView = (account_id: number) => history.push({ pathname: "/other/business/profile", state: account_id });

    const handleOpenOtherPersonalView = (account_id: number) => history.push({ pathname: "/other/personal/profile", state: account_id });

    const pushToChat = () => history.push({ pathname: '/chat', state: { userId: data?.attributes?.account_id } })

    const invitationIcons = <React.Fragment>
        <Col

            style={{
                height: 'fit-content',
                margin: 'auto 0px auto auto'
            }}>
            <Row gutter={[10, 10]}>
                <Col >
                    <Button
                        type="text"
                        shape="circle"
                        size={"small"}
                        onDoubleClick={() => { }}
                        onClick={() => showAcceptRequestModal({ type: "accept", data: data })}
                        icon={
                            <img src={rejectIcon} width={28} />
                        }
                    />
                </Col>
                <Col>
                    <Button
                        type="text"
                        shape="circle"
                        size={"small"}
                        onClick={() => showAcceptRequestModal({ type: "accept", data: data })}
                        icon={
                            <img src={acceptIcon} width={28} />
                        }
                    />
                </Col>
            </Row>
        </Col>
    </React.Fragment>
    const renderDesignationTitle = () => {
        return (
            <>
                {data?.attributes?.profile?.data?.attributes?.designation?.title && <Col span={24} className="card-item">
                    <span style={{ fontWeight: 500, fontSize: "14px", color: "rgba(0, 0, 0, 0.85)", wordBreak: 'break-word' }}>
                        {data.attributes.profile.data.attributes.designation.title}
                        {data.attributes.profile.data.attributes.designation?.company ?
                            <span><span style={{ color: "#919191" }}> at </span>{
                                data.attributes.profile.data.attributes.designation.company
                            }</span> :
                            null}

                    </span>
                </Col>}
            </>
        )
    }

    const renderLocation = () => {
        return (
            <>
                {data?.attributes?.profile?.data?.attributes?.location &&
                    <Col span={24} className="card-item">
                        <Text
                            style={{
                                width: "100%",
                                marginBottom: 0,
                                fontSize: 12,
                                textAlign: "center",
                            }}
                            data-testid="render_location"
                            type="secondary"
                        >
                            {data.attributes.profile.data.attributes.location && <EnvironmentOutlined translate={{}} />}
                            {data.attributes.profile.data.attributes.location}
                        </Text>
                    </Col>}
            </>
        )
    }


    const renderDomainSkills = () => {
        return (
            <>
                {data?.attributes?.profile?.data?.attributes?.domain_skills &&
                    <Col span={24} className="card-item">
                        <span className="domain_skill" data-testid="domain_skill">

                            {data.attributes.profile.data.attributes.domain_skills?.map((item: any, id: number) => {
                                if (id + 1 < data.attributes.profile.data.attributes.domain_skills.length) {
                                    return `${item}, `
                                } else {
                                    return `${item}`
                                }
                            })}

                        </span>
                    </Col>}
            </>
        )
    }

    const typeInvitedButtons = () => {
        const forInvitesButton = (
            (acceptModel && !data?.attributes?.invited)
            && data.id === cardid) ? <Spin /> :
            <Col span={12}>
                <Button
                    className="network_btn"
                    type="text"
                    data-testid="invite_btn"
                    shape="round"
                    onClick={() => showAcceptRequestModal({ type: "people", data: data })}
                >
                    Invite
                </Button>
            </Col>

        const ButtonstypeInvited =
            (data?.attributes?.invited) ?
                <Col span={12}>
                    <Button
                        className="network_btn"
                        type="text"
                        shape="round"
                        disabled
                    >
                        Invited
                    </Button>
                </Col>
                : forInvitesButton

        return (<>
            {others?.type == "you_may_know" ?
                ButtonstypeInvited
                : <Col span={12}>
                    <Button
                        className="network_btn"
                        type="text"
                        shape="round"
                        onClick={pushToChat}
                    >
                        Chat
                    </Button>
                </Col>
            }
        </>
        )
    }


    // Customizable Area End

    // Customizable Area Start
    return (
        <Col xs={24} sm={8} md={6} lg={4} xl={4} xxl={4} className="NetworkComponent">
            <Card
                hoverable
                style={{ borderRadius: "6px" }}
                data-testid="card"
                actions={[
                    <div style={{ padding: "0px 10px" }} key="actionKeys">
                        <Row gutter={[10, 10]}>
                            <Col span={12}>
                                <Button
                                    className="network_btn"
                                    type="text"
                                    shape="round"
                                    onClick={data?.attributes?.role === "business" ?
                                        () => handleOpenOtherBusinessView(data.attributes.account_id) :
                                        () => handleOpenOtherPersonalView(data.attributes.account_id)}
                                >
                                    View Profile
                                </Button>
                            </Col>

                            {others?.type == "invitation" ?
                                invitationIcons
                                : typeInvitedButtons()}
                        </Row>
                    </div>
                ]}
            >
                <Row gutter={[10, 10]}>
                    <Col span={24} className="card-item">
                        <Avatar
                            size={55}
                            src={data?.attributes?.photo ? data.attributes.photo : ""}
                            icon={<UserOutlined translate={{}} />}
                        />
                    </Col>
                    <Col span={24} className="card-item">
                        <Text
                            style={{ fontSize: 14, fontWeight: "bold" }}
                        >
                            {data?.attributes?.role === "business" ?
                                data.attributes.profile?.data?.attributes?.company_name :
                                data.attributes.full_name}

                        </Text>
                    </Col>
                    {renderDesignationTitle()}
                    {renderDomainSkills()}
                    {renderLocation()}
                </Row>
            </Card>
        </Col>
    );
    // Customizable Area End

    // Customizable Area Start
    // Customizable Area End
};
// Customizable Area Start
export default NetworkComponent;
// Customizable Area End