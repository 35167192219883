import React, { useEffect, useState } from 'react';
import { Space, Typography, Row, Col, Spin, Empty } from 'antd';
const { Title, Text } = Typography;
import Helmet from "react-helmet";

const AppliedPolicy = (props: any) => {
    // Customizable Area Start
    const { policiesData, isloader } = props;

    const [readMore, setReadMore] = useState([
        {
            policy: true
        },
        {
            policy: true
        },
        {
            policy: true
        },
        {
            policy: true
        },
        {
            policy: true
        },
        {
            policy: true
        },
    ]);

    return (
        <>
            <Helmet>
                <title>Applied View | About Applied</title>
                <meta name="description" content="Applied View is an online platform to connect with your industry professionals mainly focusing on professional networking and career rating and development, thus enabling to unlock your potential. Applied View also offers business to connect with their customers, employees, suppliers, and the community to evaluate and adapt to business needs." />
                <meta property="og:title" content='Applied View | About Applied' />
                <meta property="description" content='Applied View is an online platform to connect with your industry professionals mainly focusing on professional networking and career rating and development, thus enabling to unlock your potential. Applied View also offers business to connect with their customers, employees, suppliers, and the community to evaluate and adapt to business needs.' />
            </Helmet>
            <Space direction='vertical' style={{ width: "100%", }}>
                <Row gutter={[16, 16]}>

                    <Col xs={{ span: 24 }} lg={{ span: 24 }} style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                        <Title level={5} style={{fontWeight:500,fontSize:16}}>
                            About Applied
                        </Title>
                        <div className = "about_applied_content"style={{  }}>
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <Text strong style={{ fontSize: "15px",fontWeight:500 }}>Applied View</Text>
                                <div style={{ margin: "0px", color: "rgba(151, 151, 151, 1)",fontSize:14,fontWeight:500 }}>
                                    Applied View is an online platform to connect with your industry professionals mainly focusing on professional networking and career rating and development, thus enabling to unlock your potential. Applied View also offers business to connect with their customers, employees, suppliers, and the community to evaluate and adapt to business needs.
                                </div>
                                <div style={{ margin: "0px", color: "rgba(151, 151, 151, 1)",fontSize:14,fontWeight:500  }}>
                                    In today’s world, everyone including employees and businesses are rethinking on how to differentiate from the rest to stay competitive and grow. Our focus is to mainly help employees and companies to evaluate themselves using our seven pillars that we expect to add value in order grow and sustain in this competitive market.
                                </div>
                            </div>
                        </div>
                    </Col>

                    <Row justify="center">
                        <Col xs={{ span: 24 }} lg={{ span: 5 }} className="about_applied_Heading_style"style={{}}>
                            <Title level={5}>
                                Attitude
                            </Title>
                            <div className = "about_applied_content"style={{  }}>
                                <div style={{ display: "flex", flexDirection: "column",fontSize:14,}}>
                                    It is only the right attitude, which gets good results for everyone including individuals and companies
                                </div>
                            </div>
                        </Col>
                        <Col xs={{ span: 24 }} lg={{ span: 5 }} className="about_applied_Heading_style"style={{}}>
                            <Title level={5}>
                                Performance
                            </Title>
                            <div className = "about_applied_content"style={{  }}>
                                <div style={{ display: "flex", flexDirection: "column",fontSize:14,}}>
                                    Performance is key to every individual and company’s growth, anytime and anywhere.
                                </div>
                            </div>
                        </Col>
                        <Col xs={{ span: 24 }} lg={{ span: 5 }} className="about_applied_Heading_style"style={{}}>
                            <Title level={5}>
                                Productivity
                            </Title>
                            <div className = "about_applied_content"style={{  }}>
                                <div style={{ display: "flex", flexDirection: "column",fontSize:14,}}>
                                    Productivity of an individual or a company is as critical as their performance to strive in today’s market
                                </div>
                            </div>
                        </Col>
                        <Col xs={{ span: 24 }} lg={{ span: 5 }} className="about_applied_Heading_style"style={{}}>
                            <Title level={5}>
                                Leadership
                            </Title>
                            <div className = "about_applied_content"style={{  }}>
                                <div style={{ display: "flex", flexDirection: "column",fontSize:14,}}>
                                    Leadership is key to both individuals and companies to bolster and provide purpose and direction
                                </div>
                            </div>
                        </Col>
                        <Col xs={{ span: 24 }} lg={{ span: 5 }} className="about_applied_Heading_style"style={{}}>
                            <Title level={5}>
                                Integrity
                            </Title>
                            <div className = "about_applied_content"style={{  }}>
                                <div style={{ display: "flex", flexDirection: "column",fontSize:14,}}>
                                    Integrity demands truthfulness and honesty whether you are an individual or a business
                                </div>
                            </div>
                        </Col>
                        <Col xs={{ span: 24 }} lg={{ span: 5 }} className="about_applied_Heading_style"style={{}}>
                            <Title level={5}>
                                Expertise
                            </Title>
                            <div className = "about_applied_content"style={{  }}>
                                <div style={{ display: "flex", flexDirection: "column",fontSize:14,}}>
                                    It’s the right expertise that can always deliver results efficiently and economically.
                                </div>
                            </div>
                        </Col>
                        <Col xs={{ span: 24 }} lg={{ span: 5 }} className="about_applied_Heading_style"style={{}}>
                            <Title level={5}>
                                Diversity
                            </Title>
                            <div className = "about_applied_content"style={{  }}>
                                <div style={{ display: "flex", flexDirection: "column",fontSize:14,}}>
                                    A diverse environment produces competitive and productive environment
                                </div>
                            </div>
                        </Col>
                    </Row>


                    <Col xs={{ span: 24 }} lg={{ span: 24 }} style={{ width: "100%", display: "flex", flexDirection: "column" }}>                    <div className = "about_applied_content"style={{  }}>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            {/* <Text strong style={{ fontSize: "14px" }}>Applied View</Text> */}
                            <div style={{ margin: "0px", color: "rgba(151, 151, 151, 1)",fontSize:14,fontWeight:500}}>
                                You can use applied view to display your professional ratings, customer evaluation, manager evaluation, team member evaluation and your friend’s evaluation. You can also display your resume and allow your connections to download your resume, search for professionals, and enhance your professional reputation by posting updates and interacting with other people.
                            </div>
                            <div style={{ margin: "0px", color: "rgba(151, 151, 151, 1)",fontSize:14,fontWeight:500 }}>
                                Applied View is free for both personal profile and business profile users.
                            </div>
                        </div>
                    </div>
                    </Col>

                    <Col xs={{ span: 24 }} lg={{ span: 24 }} style={{ width: "100%", display: "flex", flexDirection: "column" }}>                    <div className = "about_applied_content"style={{  }}>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <div style={{ margin: "0px", color: "rgba(151, 151, 151, 1)",fontSize:14,fontWeight:500 }}>
                                The subscription version for Personal profile users has the option to post pictures and videos for a period of 365 days, create customized questionnaire for each category rating and advanced analytics on your ratings.
                            </div>
                            <div style={{ margin: "0px", color: "rgba(151, 151, 151, 1)",fontSize:14,fontWeight:500 }}>
                                The subscription version of Business profile users will have the option of posting pictures and videos for a period of 365 days, business can create customized questionnaire for each category depending on business needs, goals and objectives. Advanced analytics on the ratings. Connect with different business and personnel. Create Job Postings/Long to job postings on user’s business career page.
                            </div>
                        </div>
                    </div>
                    </Col>
                </Row>
            </Space>
        </>
    )
    // Customizable Area End
}

export default AppliedPolicy;
