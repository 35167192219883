import React from "react";
// Customizable Area Start
import {
  Row,
  Col,
  Image,
  Form,
  Radio,
  Typography,
  Button,
  Checkbox,
  Avatar,
  Spin,
} from "antd";
import "antd/dist/antd.css";
import "./select_industry.css";
import { UserOutlined, CheckCircleOutlined } from "@ant-design/icons";
import {
  bannerImage,
  iconImage,
  style,
  AppImage,
  AndroidImage,
  iosImage,
  manufacture,
  education,
  construction,
  foodPrecessing,
  healthCare,
  hospitality,
  realEstate,
  technology,
  travel,
  bank,
  transportation,
  retailOutlate,
} from "./assets";
import SelectIndustryController from "./selectIndustryController.web";
const { Title, Text, Link } = Typography;
// Customizable Area End

export default class SelectIndustry extends SelectIndustryController {
  // Customizable Area Start
  renderRadioButton = (key: string, img: any, label: string) => {
    return (
      <Col span={8}>
        {this.redirectUser()}
        <Radio.Button
          className={style.select_industry_buttonContainer}
          value={key}
          onClick={() => this.setState({ selectedIndustry: key })}
          style={{
            borderRadius: "5px",
            backgroundColor: key == this.state.selectedIndustry ? "#1b6889" : "transparent",
          }}
        >
          {this.state.selectedIndustry == key ? (
            <CheckCircleOutlined
              translate={{}}
              style={{
                position: "absolute",
                fontSize: "10px",
                left: 10,
                top: 5,
                color: key == this.state.selectedIndustry ? "#fff" : "#1b6889",
              }}
            />
          ) : null}
          <Image
            src={img}
            className={style.industry_avatortSize}
            preview={false}
            style={{
              filter:
                key == this.state.selectedIndustry ?
                  key == "Healthcare" ?
                    '' :
                    'brightness(0) invert()'
                  : key == "Healthcare" ?
                    'invert(62%) sepia(40%) saturate(360%) hue-rotate(152deg) brightness(96%) contrast(99%) grayscale(14%)' :
                    ''
            }

            }
          />
          <br />
          <Text
            style={{ color: key == this.state.selectedIndustry ? "#fff" : "#1b6889" }}
            className={style.industry_text}
          >
            {label}
          </Text>
        </Radio.Button>
      </Col>
    );
  };
  // Customizable Area End
  render() {
    // Customizable Area Start
    return (
      <React.Fragment>
        <Row>
          <Col
            className="gutter-row"
            span={14}
            xs={0}
            sm={6}
            md={10}
            lg={10}
            xl={14}
          >
            <Image
              preview={false}
              style={{ height: "100%", width: "100%" }}
              src={bannerImage}
              wrapperStyle={{ height: "100%", width: "100%" }}
            />
          </Col>
          <Col
            className="gutter-row"
            span={10}
            xs={24}
            sm={18}
            md={14}
            lg={14}
            xl={10}
          >
            <Row>
              <Col span={4} xs={2} sm={2} md={4} lg={4} xl={4} />
              <Col span={16} xs={20} sm={20} md={16} lg={16} xl={16}>
                <Row className={style.alignCenter}>
                  <Col span={6} />
                  <Col span={12}>
                    <Image
                      preview={false}
                      className={style.logopadding}
                      src={iconImage}
                    />
                  </Col>
                  <Col span={6} />
                  <Title
                    level={2}
                    style={{
                      textAlign: "center",
                      marginBottom: "15px",
                      fontSize: "20px",
                    }}
                  >
                    Select Your Business Industry
                  </Title>
                </Row>
                {this.state.loading ? (
                  <Spin />
                ) : (
                  <Form
                    initialValues={{
                      industry: this.state.selectedIndustry,
                    }}
                    onFinish={this.handleIndustry}
                  >
                    <Form.Item
                      rules={[
                        {
                          required:
                            this.state.selectedIndustry &&
                              this.state.selectedIndustry.length > 2
                              ? false
                              : true,
                          message: "Required field",
                        },
                      ]}
                      name="industry"
                    >
                      <Radio.Group defaultValue="a" size="large">
                        <Row gutter={[14, 14]}>
                          {this.renderRadioButton(
                            "Manufacturing",
                            manufacture,
                            "Manufacturing"
                          )}
                          {this.renderRadioButton(
                            "Education",
                            education,
                            "Education"
                          )}
                          {this.renderRadioButton(
                            "Healthcare",
                            healthCare,
                            "Healthcare"
                          )}
                          {this.renderRadioButton(
                            "Hospitality",
                            hospitality,
                            "Hospitality"
                          )}
                          {this.renderRadioButton(
                            "Transportation",
                            transportation,
                            "Transportation"
                          )}
                          {this.renderRadioButton(
                            "Construction",
                            construction,
                            "Construction"
                          )}
                          {this.renderRadioButton(
                            "Financial Services",
                            bank,
                            "Financial Services "
                          )}
                          {this.renderRadioButton(
                            "Information Technology",
                            technology,
                            "Information Technology"
                          )}
                          {this.renderRadioButton(
                            "Food and Beverage",
                            foodPrecessing,
                            "Food and Beverage"
                          )}
                          {this.renderRadioButton(
                            "Retail Outlets",
                            retailOutlate,
                            "Retail Outlets"
                          )}
                          {this.renderRadioButton(
                            "Business Services",
                            realEstate,
                            "Business Services"
                          )}
                          {this.renderRadioButton(
                            "News & Media",
                            travel,
                            "News & Media"
                          )}
                        </Row>
                      </Radio.Group>
                    </Form.Item>
                    <Button
                      className={style.btn_colors}
                      type="primary"
                      htmlType="submit"
                      shape="round"
                      size={"large"}
                    >
                      Save
                    </Button>
                  </Form>
                )}
                <br />
              </Col>
              <Col span={4} xs={2} sm={2} md={4} lg={4} xl={4} />
            </Row>
          </Col>
        </Row>
        <Row style={{ backgroundColor: "#f5f5f5" }}>
          <Col span={12} xs={24} sm={24} md={12} lg={12} xl={14} xxl={14}>
            <Row
              className={style.app_alignCenter}
              style={{ justifyContent: "flex-end", width: "100%" }}
            >
              <Image
                preview={false}
                src={AppImage}
                style={{ objectFit: "fill" }}
              />
            </Row>
          </Col>
          <Col span={12} xs={24} sm={24} md={12} lg={12} xl={10} xxl={10}>
            <Row
              style={{
                height: "100%",
                alignItems: "center",
                paddingRight: "40px",
                paddingLeft: "40px",
              }}
            >
              <Col span={6} xs={0} sm={6} md={3} lg={3} xl={3} xxl={6} />
              <Col span={10} xs={24} sm={24} md={18} lg={18} xl={18} xxl={10}>
                <Row>
                  <Title level={3}>Available for Android and IOS</Title>
                  <Text
                    type="secondary"
                    className={style.w_100}
                    style={{
                      fontSize: "1.1rem",
                      lineHeight: "1.3rem",
                      paddingBottom: "20px",
                    }}
                  >
                    Download applied app from your Play Store or App Store
                  </Text>
                  <Col span={11}>
                    <Image preview={false} src={AndroidImage} />
                  </Col>
                  <Col span={2} />
                  <Col span={11}>
                    <Image preview={false} src={iosImage} />
                  </Col>
                </Row>
              </Col>
              <Col span={8} xs={8} sm={8} md={3} lg={3} xl={3} xxl={8} />
            </Row>
          </Col>
        </Row>
        <Row style={{ justifyContent: "center", paddingTop: "10px" }}>
          <p>©2021 applied. All rights reserved.</p>
          <a href="/privacy-policy">&nbsp;&nbsp;Privacy Policy&nbsp;&nbsp;</a>
          <a href="/terms-and-conditions">&nbsp;&nbsp;Terms of use</a>
        </Row>
      </React.Fragment>
    );
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
}
// Customizable Area Start
// Customizable Area End