import React from "react";
// Customizable Area Start
import {
  Row,
  Col,
  Image,
  Form,
  Input,
  Typography,
  Button,
  Checkbox,
  Spin,
} from "antd";
import "antd/dist/antd.css";
import {
  bannerImage,
  iconImage,
  AppImage,
  AndroidImage,
  iosImage,
} from "./assets";
const style = require("./login.module.css");
import LoginController from "./LoginwebController.web";
import { LinkedinFilled } from "@ant-design/icons";
import CountryCodeSelector from "../../country-code-selector/src/CountryCodeSelector.web";
import { isMap } from "lodash";
import { AlertMessage } from "../../alert/src/AlertMessage.web";
import { Dimensions } from "react-native";
import Helmet from "react-helmet";
// import {isAuthenticated} from '../../../web/src/'

const { Title, Text, Link } = Typography;
const emailReg = RegExp(
  /^([a-zA-Z0-9_\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/
);
// Customizable Area End
export default class LoginWeb extends LoginController {
  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <>
        {!this.state.isLoading &&
          <>
            <Helmet>
              <title>Applied View | Log in or Sign Up</title>
              <meta name="description" content="Create an account or log into Applied View. Stay connected with your industry professionals and businesses and rate them." />
              <meta property="og:title" content='Applied View | Log in or Sign Up' />
              {/* <meta property="og:image" content={ogImage} /> */}
              <meta property="description" content='Create an account or log into Applied View. Stay connected with your industry professionals and businesses and rate them.' />
            </Helmet>
            {this.redirectUser()}
            <Row>
              <Col span={14} xs={0} sm={0} md={12} lg={12} xl={14}>
                <Image
                  className={style.image_width_set}
                  preview={false}
                  src={bannerImage}
                  wrapperStyle={{ height: "100%", width: "100%" }}
                  style={{ height: "100%", width: "100%" }}
                />
              </Col>
              <Col
                span={10}
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xl={10}
                style={{ paddingTop: "2%" }}
              >
                <Row>
                  <Col span={4} />
                  <Col span={16}>
                    <Row>
                      <Col span={6} />
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Image
                          preview={false}
                          className={style.logopadding}
                          // style={{ height: "35px", width: "100px",border:'1px solid red' }}
                          src={iconImage}
                        />
                      </Col>
                      <Col span={6} />
                    </Row>

                    <Row className={style.loginText}>
                      <Title className={style.alignCenter} level={3} style={{ marginBottom: 0 }}>
                        Welcome Back!
                      </Title>
                      <Text type="secondary" className={style.w_100_center} style={{ marginBottom: 24 }}>
                        Please login to your account to continue
                      </Text>
                    </Row>
                    <Form
                      onFinish={this.handleLogin}
                      autoComplete="off"
                      initialValues={{
                        email: this.state.previousLoginData.email,
                        password: this.state.previousLoginData.password
                      }}
                    >
                      <Form.Item
                        data-testid="emailRules"
                        rules={[
                          { required: true, message: "Required field" },
                          this.state.isMobile
                            ? {}
                            :
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                if (!value || emailReg.test(value)) {
                                  return Promise.resolve();
                                }
                                return Promise.reject(
                                  new Error("Please Enter Valid Email!")
                                );
                              },
                            }),
                          this.state.isMobile
                            ? {
                              min: 4,
                              max: 15,
                              message: "invalid Phone Number",
                            }
                            : {},
                        ]}
                        name="email"
                      >
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          {this.state.isMobile && (
                            <CountryCodeSelector
                              id={"CountryCodeSelector"}
                              name="cc"
                              allowPropChange={true}
                              placeHolder={this.state.countryCodePlaceholder}
                              disable={false}
                              value={this.state.countryCode}
                              navigation={this.props.navigation}
                            />
                          )}
                          <div data-testid="LoginAddress"
                            style={{ width: this.state.isMobile ? "68%" : "100%" }}>
                            <Input
                              style={{ color: "#a9bac8" }}
                              className={style.input_fields}
                              size="large"
                              type={this.state.isMobile ? "number" : "email"}
                              name="email"
                              data-testid="email"
                              placeholder="Email Address / Phone Number"
                              allowClear
                              defaultValue={this.state.previousLoginData.email}
                              onChange={(e) => this.handleOnChange(e)}
                            />
                          </div>
                        </div>
                      </Form.Item>
                      <Form.Item
                        rules={[{ required: true, message: "Required field" }]}
                        name="password"
                        style={{ marginTop: 20, marginBottom: 5 }}
                      >
                        <Input.Password
                          type={"password"}
                          className={style.input_fields}
                          name="password"
                          data-testid="password"
                          placeholder="Password"
                          size="large"
                        />
                      </Form.Item>
                      <Row>
                        <Col xl={12} sm={12} md={12} xs={24}>
                          <Form.Item className="Login_RememberText" rules={[{ required: false }]}>
                            <Checkbox
                              checked={this.state.isRemember}
                              onChange={this.onChange}
                              data-testid="RememberMe"
                            >
                              <Text type="secondary" style={{ color: "#a9bac8" }}>Remember me</Text>
                            </Checkbox>
                          </Form.Item>
                        </Col>
                        <Col
                          xl={12} sm={12} md={12} xs={24}
                          className={style.alignRight}
                          style={{ paddingTop: "5px", }}
                        >
                          <Link
                            href="/forgot/password"
                            style={{ color: "#005574" }}
                          >
                            Forgot password?
                          </Link>
                        </Col>
                      </Row>
                      <Spin spinning={this.state.loading}>
                        <Button
                          className={style.btn_colors}
                          type="primary"
                          htmlType="submit"
                          data-testid="Login"
                          shape="round"
                          size={"large"}
                          style={{ width: "100%" }}
                        >
                          Log In
                        </Button>
                      </Spin>
                    </Form>
                    <Row
                      style={{
                        justifyContent: "center",
                        paddingTop: "12px",
                        // fontSize: "1.2rem",
                      }}
                    >
                      <Text type="secondary">Don't have an account?&nbsp; </Text>
                      <Link href="/signup" style={{ color: "#005574" }}>
                        Create an Account
                      </Link>
                    </Row>
                  </Col>
                  <Col span={4} />
                </Row>
              </Col>
            </Row>
            <Row style={{ backgroundColor: "#f5f5f5" }}>
              <Col span={12} xs={24} sm={24} md={12} lg={12} xl={14} xxl={14}>
                <Row
                  className={style.app_alignCenter}
                  style={{ justifyContent: "flex-end", width: "100%" }}
                >
                  <Image
                    preview={false}
                    src={AppImage}
                    style={{ objectFit: "fill" }}
                  // wrapperStyle={{ width: "100%" }}
                  />
                </Row>
              </Col>
              <Col
                span={12}
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xl={10}
                xxl={10}
              // className={style.app_store}
              >
                <Row
                  // className={style.app_alignCenter}
                  style={{
                    height: "100%",
                    alignItems: "center",
                    paddingRight: "40px",
                    paddingLeft: "40px",
                  }}
                >
                  <Col span={6} xs={0} sm={6} md={3} lg={3} xl={3} xxl={6} />
                  <Col span={10} xs={24} sm={24} md={18} lg={18} xl={18} xxl={10}>
                    <Row>
                      <Title level={3}>Available for Android and IOS</Title>
                      <Text
                        type="secondary"
                        className={style.w_100}
                        style={{
                          fontSize: "1.1rem",
                          lineHeight: "1.3rem",
                          paddingBottom: "20px",
                        }}
                      >
                        Download applied app from your Play Store or App Store
                      </Text>
                      <Col span={11}>
                        <Image preview={false} src={AndroidImage} />
                      </Col>
                      <Col span={2} />
                      <Col span={11}>
                        <Image preview={false} src={iosImage} />
                      </Col>
                    </Row>
                  </Col>
                  <Col span={8} xs={8} sm={8} md={3} lg={3} xl={3} xxl={8} />
                </Row>
              </Col>
            </Row>
            <Row
              style={{
                justifyContent: "center",
                paddingTop: "10px",
                paddingLeft: "40px",
                paddingRight: "40px",
              }}
            >
              <p>©2021 applied. All rights reserved.</p>
              <a href="/privacy-policy" target="_blank">
                &nbsp;&nbsp;Privacy Policy&nbsp;&nbsp;
              </a>
              <a href="/terms-and-conditions" target="_blank">
                &nbsp;&nbsp;Terms of use
              </a>
            </Row>
            <AlertMessage
              isModalVisible={this.state.isErrorModalShow}
              title={this.state.errorContent.title}
              body={this.state.errorContent.body}
              isError={this.state.errorContent.isError}
              onOk={this.handleCloseModal}
            />
          </>
        }
      </>
    )
    // Customizable Area End
  }
}

// Customizable Area Start
// Customizable Area End