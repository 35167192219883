import { Route } from "react-router-dom";
import { ModalRoute } from "react-router-modal";
import React from "react";
import PropTypes from "prop-types";
import "react-router-modal/css/react-router-modal.css";
import CustomHeader from "blocks/CommunityForum/src/CustomHeader.web";
function Wrapper({ element, history, match, routeMap, closeModal, type }) {
  const navigate = (to, params) => {
    let url = routeMap[to].path;
    // replace params ids in the url with actual values
    if (params && Object.keys(params).length > 0) {
      Object.keys(params).forEach((param) => {
        const re = RegExp(`\:${param}\\??`); // eslint-disable-line no-useless-escape
        url = url.replace(re, escape(params[param]));
      });
    }
    // removing empty params from url - every string between /: and ?
    url = url.replace(/\/:(.*?)(?=\/|$)/g, "");
    // if the route is not a modal
    if (!routeMap[to].modal) {
      history.push(url);
      // if the route is a modal
    } else {
      // checking if the url ends with a slash or not
      const slash = /\/$/.test(match.url) ? "" : "/";
      // current url in the browser + slash + modal url with parameters
      url = match.url + slash + url;
      // removing the */ from the url
      url = url.replace(/\*\/?/g, "");
    }
  };

  const getParam = (param, alternative) => {
    return match.params[param] || alternative;
  };

  const goBack = () => {
    history.goBack();
  };

  const props = { history, navigate }

  if (type === 'Auth') {
    return React.cloneElement(element, {
      navigation: { navigate, getParam, goBack },
      closeModal,
    })
  } else {
    return (
      <>
        <div
          className="searchStyles"
        // style={{ background: "#f6f6f6", minHeight: "100vh" }}
        >
          <CustomHeader {...props} dotVisible={false} />

          {React.cloneElement(element, {
            navigation: { navigate, getParam, goBack },
            closeModal,
          })}

        </div>
      </>
    )
  }


}

Wrapper.propTypes = {
  element: PropTypes.element,
  history: PropTypes.object,
  routeMap: PropTypes.object,
  closeModal: PropTypes.func,
  match: PropTypes.object,
};

const WebRoutesGenerator = ({ routeMap }) => {
  // console.log(Wrapper.propTypes);
  // console.log(routeMap, 'test');
  const AuthRoute = Object.keys(routeMap).filter(key => routeMap[key].type === "Auth")
  const RouteArray = Object.keys(routeMap).filter(key => routeMap[key].type !== "Auth")

  return <>

    {Object.keys(routeMap).map((route) => {
      const currentRoute = routeMap[route];
      const Component = currentRoute.component;
      const type = currentRoute.type;
      if (currentRoute.modal) {
        return (
          <div key={currentRoute.path}>
            {/* {console.log("asdgajlkdg Current Route without Exact ", routeMap)} */}
            <ModalRoute
              key={currentRoute.path}
              path={currentRoute.path}
              component={(props) => (

                <Wrapper
                  element={<Component {...props} />}
                  {...props}
                  routeMap={routeMap}
                  type={type}
                />
              )}
            />
          </div>
        );
      } else {
        return (
          <div key={currentRoute.path}>
            {/* {console.log("adkgaldsgjklajlajdslag", routeMap)} */}
            <Route
              key={currentRoute.path}
              path={currentRoute.path}
              exact={currentRoute.exact}
              render={(props) => (
                <Wrapper
                  element={<Component {...props} />}
                  {...props}
                  routeMap={routeMap}
                  type={type}
                />
              )}
            />
          </div>
        );
      }
    })}
  </>
};

WebRoutesGenerator.propTypes = {
  routeMap: PropTypes.object,
};

export default WebRoutesGenerator;