import React from 'react';
import { Row, Col, Typography, Avatar, Card, Spin } from 'antd';
import { posts, followers, following, connections, ratingSent, ratingsReceived } from "../assets";

const { Text } = Typography;

const AboutAndStats = (props: any) => {
    // Customizable Area Start
    const { data, isLoading, handleClick } = props;
    const list1 = [
        {
            name: "Posts",
            img: posts,
            no_of_count: data?.posts_count
        },
        {
            name: "Connections",
            img: connections,
            no_of_count: data?.friends_count
        },
        {
            name: "Followers",
            img: followers,
            no_of_count: data?.followers_count
        },
        {
            name: "Following",
            img: following,
            no_of_count: data?.following_count
        },
        {
            name: "Ratings Received",
            img: ratingsReceived,
            no_of_count: data?.ratings_received
        },
        {
            name: "Ratings Sent",
            img: ratingSent,
            no_of_count: data?.ratings_given
        }
    ];
    const company_overview = data?.profile?.data?.attributes?.role === "business" ? data?.profile?.data?.attributes?.company_overview : data?.profile?.data?.attributes?.description;

    return (
        <Row style={{ width: "100%", height: "100%", padding: 8 }}>
            <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
                xl={{ span: 12 }}
                xxl={{ span: 12 }}
                style={{ padding: "0.5rem" }}
            >
                <Card style={{ width: "100%", height: "100%", backgroundColor: "#f1f0f5" }}>
                    <Text strong
                        style={{ color: "rgba(0, 0, 0, 0.85)" }}
                    >
                        Stats
                    </Text>
                    <Row style={{ marginTop: "4px" }}>
                        {
                            list1.map((item: any, id: number) => {
                                return (
                                    <Col
                                        span={8}
                                        xs={12}
                                        md={8}
                                        lg={8}
                                        xl={8}
                                        key={id}
                                        style={{ padding: "2px" }}
                                    >
                                        <Card
                                            style={{
                                                width: "100%",
                                                height: "100%",
                                                backgroundColor: "rgb(221, 230, 235)",
                                                cursor: "pointer"
                                            }}
                                        >
                                            <div
                                                style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
                                            >
                                                <Avatar shape="square" size={26} src={item.img} />
                                                <div style={{ marginLeft: "10px", display: "flex", flexDirection: "column" }}>
                                                    <Text
                                                        className="block-text"
                                                        style={{ fontWeight: 500, fontSize: "10px", color: "#005574" }}
                                                    >
                                                        {item.name}
                                                    </Text>
                                                    <Text
                                                        className="block-text"
                                                        style={{ fontWeight: 500, color: "#005574" }}
                                                    >
                                                        {
                                                            isLoading ? <Spin />
                                                                : item.no_of_count
                                                        }
                                                    </Text>
                                                </div>
                                            </div>
                                        </Card>
                                    </Col>
                                )
                            })
                        }
                    </Row>
                </Card>
            </Col>
            <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
                xl={{ span: 12 }}
                xxl={{ span: 12 }}
                style={{ padding: "0.5rem" }}
            >
                <Card
                    style={{ width: "100%", height: "100%", backgroundColor: "#f1f0f5", display: company_overview ? "block" : "none" }}
                >
                    {
                        isLoading ? <Spin />
                            : <>
                                <Text strong
                                    style={{ color: "rgba(0, 0, 0, 0.85)" }}
                                >
                                    About {data?.role === "business" ? data?.profile?.data?.attributes?.company_name : data?.first_name}
                                </Text>
                                <div
                                    style={{ marginTop: "5px", display: "flex", flexDirection: "row", flexWrap: "wrap", wordBreak: "break-word" }}
                                >
                                    <Text style={{
                                        color: "rgb(117, 117, 117)",
                                        fontWeight: 400,
                                        fontSize: "14px",
                                    }}>
                                        <span>
                                            {
                                                company_overview?.slice(0, 400)
                                            }
                                        </span>
                                        <span
                                            style={{ color: "#005574", cursor: "pointer", marginLeft: "5px" }}
                                            onClick={() => handleClick()}
                                        >
                                            Read More
                                        </span>
                                    </Text>
                                </div>
                            </>
                    }
                </Card>
            </Col>
        </Row>
    )
    // Customizable Area End
}

export default AboutAndStats
