/* App/Lib/GeneralHelpers.js */
import { Platform, PermissionsAndroid } from 'react-native';
import Contacts from 'react-native-contacts';
import StorageProvider from './StorageProvider';

export async function setStorageData(key:string, data:any){
  if (key && data) {
    await StorageProvider.set(key, data);
  }
}

export async function getStorageData(key:string, parseToJson:boolean = false){
  if (StorageProvider && key) {
    const data = await StorageProvider.get(key) || null;
    //console.log('getStorageData data', key, data);
    if(parseToJson && data){
      return JSON.parse(data)
    }else{
      return data;
    }
  }
  return null;
}

export async function removeStorageData(key:string){
  if (StorageProvider && key) {
    await StorageProvider.remove(key);
  }
}
export function getOS(): string {
  return Platform.OS;
}

export const contacts = async () => {
  return await Contacts.getAll().then((contacts) => {
    return contacts.map((contact, i) => ({
      name: contact?.displayName,
      hasThumbnail: contact?.hasThumbnail,
      thumbnailPath: contact?.thumbnailPath,
      mobile: contact?.phoneNumbers[0]?.number.replace(/\s/g, '').replace('+', ''),
      isPhoneContact: true,
    }));
  });
};
