import React, { Component } from 'react';
import { runEngine } from '../../../../framework/src/RunEngine';
import { Message } from '../../../../framework/src/Message';
import MessageEnum, {
  getName,
} from '../../../../framework/src/Messages/MessageEnum';
import { BlockComponent } from '../../../../framework/src/BlockComponent';
import { IBlock } from '../../../../framework/src/IBlock';
import confirm from '../../../alert/src/alert.web';
import AlertError from '../../../alert/src/alertError.web';
import moment from 'moment';
import { RouterProps } from 'react-router';
import { Redirect } from "react-router-dom";
import { FormInstance } from 'antd/lib/form';
export const configJSON = require('../config');
import { Select } from 'antd';
// Customizable Area Start
import { message } from 'antd';
// Customizable Area End
const { Option } = Select;
export interface Props extends RouterProps {
  navigation: any;
  id: string;
}
export interface AllData {
  certificate: [
    {
      name: string | null;
      description: string | null;
    }
  ];
  award: [
    {
      title: string | null;
      description: string | null;
    }
  ];
  acheivement: [
    {
      description: string | null;
    }
  ];
}
interface S {
  loading: boolean;
  isRegistration: boolean;
  bussiness_profile: boolean;
  data: any;
  certificates: any[];
  awards: any[];
  acheivements: any[];
  pre_data: any;
  prev_data: any;
  finalAllData: any;
  all_data: AllData;
  token: string | null;
  employment_type: string | null;
  isCertificateAdded: boolean;
  isAwardAdded: boolean;
  isModalVisible: any;
  modalIndex: number;
  modalString: string;
  redirect: boolean;
  disableNextButton: boolean
}
interface SS {
  id: any;
}

export default class CreateEducationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  createAccountApiCallId: any;
  certificate_formref = React.createRef<FormInstance>();
  award_formref = React.createRef<FormInstance>();
  achievement_formref = React.createRef<FormInstance>();
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.receive = this.receive.bind(this);
    this.state = {
      bussiness_profile: true,
      loading: true,
      isRegistration: true,
      data: [],
      prev_data: {},
      token: localStorage.getItem('token') ? localStorage.getItem('token') : '',
      employment_type: localStorage.getItem('employment_type')
        ? localStorage.getItem('employment_type')
        : null,
      certificates: [],
      finalAllData: {},
      all_data: {
        certificate: [
          {
            name: null,
            description: null,
          },
        ],
        award: [
          {
            title: null,
            description: null,
          },
        ],
        acheivement: [
          {
            description: null,
          },
        ],
      },
      pre_data: {},
      awards: [],
      acheivements: [],
      isCertificateAdded: false,
      isAwardAdded: false,
      isModalVisible: false,
      modalIndex: 0,
      modalString: '',
      redirect: false,
      disableNextButton: false
    };
  }
  async componentDidMount() {
    let token = await localStorage.getItem("token");
    if (token && token !== null && token !== undefined) {
      this.setState({ redirect: false });
    } else {
      this.setState({ redirect: true });
    }
    // console.log(
    //   'employment_type test',
    //   localStorage.getItem('employment_type)')
    // );
    // console.log(
    //   'test  certificate========== ',
    //   this.props.history.location.state
    // );
    // console.log('emp type test =====', this.state.employment_type);
    let certificateDetails: any = this.props.history.location.state;
    if (certificateDetails && certificateDetails != null) {
      let newData = Object.assign(
        {},
        { experience: certificateDetails && certificateDetails.experience },
        { profile: certificateDetails && certificateDetails.profile },
        { graduation: certificateDetails && certificateDetails.graduation },
        { language: certificateDetails && certificateDetails.language },
        { contact: certificateDetails && certificateDetails.contact }
      );
      // console.log(newData, '******152')
      this.setState({ pre_data: newData });
      if (certificateDetails && certificateDetails.certificate) {
        this.setState({
          all_data: certificateDetails && certificateDetails.certificate,
        });
      }
    }
    this.setState({ loading: false });
    // console.log("In experience==>bsic data==>", localStorage.getItem("data"));
  }

  redirectUser() {
    if (this.state.redirect) {
      return <Redirect to="/" />;
    }
  }

  //==================== @@@ Handle Data of History Screen===============

  handleCertificateData = (values: any) => {
    let all_data = this.state.all_data;
    if (all_data.certificate) {
      all_data.certificate.push(values);
    } else {
      Object.assign(all_data, { certificate: [values] });
    }

    // console.log(
    //   '@@@ certificate data====',
    //   values,
    //   '@@@ certificate data====',
    //   all_data
    // ),
    this.setState({ all_data: all_data }, () => {
      this.certificate_formref.current!.resetFields();
    });
  };

  handleAwardData = (values: any) => {
    let all_data = this.state.all_data;
    if (all_data.award) {
      all_data.award.push(values);
    } else {
      Object.assign(all_data, { award: [values] });
    }
    // console.log('@@@ award data=====', values, '@@@ award data=====', all_data),
    this.setState({ all_data: all_data }, () => {
      this.award_formref.current!.resetFields();
    });
  };
  handleAchievementsData = (values: any) => {
    let all_data = this.state.all_data;
    if (all_data.acheivement) {
      all_data.acheivement.push(values);
    } else {
      Object.assign(all_data, { acheivement: [values] });
    }
    // console.log(
    //   '@@@ acheivement data=====',
    //   values,
    //   '@@@ acheivement data=====',
    //   all_data
    // ),
    this.setState({ all_data: all_data }, () => {
      this.achievement_formref.current!.resetFields();
    });
  };
  skipProfile = async () => {
    let all_data = this.state.all_data;
    let newData = Object.assign(
      {},
      { experience: this.state.pre_data && this.state.pre_data.experience },
      { graduation: this.state.pre_data && this.state.pre_data.graduation },
      { language: this.state.pre_data && this.state.pre_data.language },
      { contact: this.state.pre_data && this.state.pre_data.contact },
      { profile: this.state.pre_data && this.state.pre_data.profile },
      { certificate: this.state.pre_data.certificate ? this.state.pre_data.certificate : all_data }
    );
    // console.log(newData, '****');
    this.props.history.replace({
      state: newData,
    });
    this.props.history.push({
      pathname: '/setup/profile/personal/generalinformation',
      state: newData,
    });
  };
  handleNext = async () => {
    const awardError = this.state.awards?.filter(item => item?.patent?.length > 10)
    const acheivementError = this.state.acheivements?.filter(item => item?.patent?.length > 10)
    if (awardError?.length > 0 || acheivementError?.length > 0) {
      message.error("Please enter certificate name and description")
      this.setState({
        disableNextButton: true
      })
      setTimeout(() => {
        this.setState({
          disableNextButton: false
        })
      }, 1450)
    } else {
      // setTimeout(()=>{
      this.onNext();
      // },1500)
    }
  };
  onNext = async () => {
    let all_data = this.state.all_data;

    let newData = Object.assign(
      {},
      { profile: this.state.pre_data && this.state.pre_data.profile },
      { experience: this.state.pre_data && this.state.pre_data.experience },
      { graduation: this.state.pre_data && this.state.pre_data.graduation },
      { language: this.state.pre_data && this.state.pre_data.language },
      { contact: this.state.pre_data && this.state.pre_data.contact },
      { certificate: all_data }
    );
    this.props.history.replace({
      state: newData,
    });
    this.props.history.push({
      pathname: '/setup/profile/personal/education',
      state: newData,
    });

    // console.log("data test", this.state.data.allData);
    // console.log("data test", newData);
  };

  //====================previous================

  handlePrevious = () => {
    let newData = Object.assign(
      {},
      { profile: this.state.pre_data && this.state.pre_data.profile },
      {
        experience:
          this.state.pre_data.experience && this.state.pre_data.experience,
      },
      {
        graduation:
          this.state.pre_data.graduation && this.state.pre_data.graduation,
      },
      {
        language: this.state.pre_data.language && this.state.pre_data.language,
      },
      { contact: this.state.pre_data.contact && this.state.pre_data.contact },
      { certificate: this.state.all_data }
    );
    // console.log(newData,'test');
    this.props.history.replace({
      state: newData,
    });
    this.props.history.push({
      pathname: '/setup/profile/personal/experience',
      state: newData,
    });
  };
  //=================== @@show modal =========================
  showModal = (index: number, data: string) => {
    this.setState({
      modalIndex: index,
      modalString: data,
      isModalVisible: true,
    });
  };
  // ====================@@delete all_data values=======================
  deleteData = (value: any) => {
    if (
      this.state.all_data.certificate != null &&
      this.state.modalString === 'Certificate'
    ) {
      const list: any = [...this.state.all_data.certificate];
      const award: any = [...this.state.awards]
      const achievement = [...this.state.acheivements]
      list.splice(value, 1);
      award.splice(value, 1);
      achievement.splice(value, 1)
      this.setState({
        acheivements: achievement,
        awards: award
      })
      this.setState((state) => ((state.all_data.certificate = list), state));
    } else if (
      this.state.all_data.award != null &&
      this.state.modalString === 'Awards and Recognition'
    ) {
      const list: any = [...this.state.all_data.award];
      list.splice(value, 1);
      this.setState((state) => ((state.all_data.award = list), state));
    } else if (
      this.state.all_data.acheivement != null &&
      this.state.modalString === 'Achievements'
    ) {
      const list: any = [...this.state.all_data.acheivement];
      list.splice(value, 1);
      this.setState((state) => ((state.all_data.acheivement = list), state));
    } else {
      console.log('error while deleting');
    }
    this.setState({
      isModalVisible: false,
    });
  };

  // =================== @@handleChange =========================
  handleInputChange = (e: any, index: any, data: string) => {
    const { name, value } = e.target;
    if (data === 'Certificate') {
      const list: any = [...this.state.all_data.certificate];
      list[index][name] = value;
      this.setState(list);
    } else if (data === 'Award') {
      const list: any = [...this.state.all_data.award];
      list[index][name] = value;
      this.setState(list);
    } else if (data === 'Acheivements') {
      const list: any = [...this.state.all_data.acheivement];
      list[index][name] = value;
      this.setState(list);
    } else {
      console.log('error while handiling data');
    }
  };
  // }
  // Customizable Area End
}
