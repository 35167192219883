export const facebookImage = require("../assets/facebook-circled.png");
export const googleImage = require("../assets/GoogleLogo.png");
export const bannerImage = require("../assets/b444580030c0d0915f7bb98f61f2f9795d96367a.png");
export const iconImage = require("../assets/caca08d4ff459604a37db99b494efdf2fe5dd95b.png");
export const AppImage = require("../assets/3947211be4980c4d0b921467ef00891540e968a7.png");
export const AndroidImage = require("../assets/e99bf2d97e1f0bb8b33707272f0c957018c39ac6.png");
export const iosImage = require("../assets/618fdead5d2b711a9af2a1ed72c6d0dc1c4059b2.png");
export const manufacture = require("../assets/manufacture.png");
export const construction = require("../assets/construction.png");
export const education = require("../assets/education.png");
export const foodPrecessing = require("../assets/foodPrecessing.png");
export const healthCare = require("../assets/healthCare.png");
export const hospitality = require("../assets/hospitality.png");
export const realEstate = require("../assets/realEstate.png");
export const technology = require("../assets/technology.png");
export const travel = require("../assets/travel.png");
export const bank = require("../assets/bank.png");
export const transportation = require("../assets/transportation.png");
export const retailOutlate = require("../assets/retailOutlate.png");
// export const style = require("./login.module.css")
export const style = require("../../social-media-account/src/login.module.css");
