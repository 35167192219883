import React, { Component } from "react";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import confirm from "../../alert/src/alert.web";
import AlertError from "../../alert/src/alertError.web";
import { RouterProps } from "react-router";
export const configJSON = require("./config");

export interface Props extends RouterProps {
  navigation: any;
  id: string;
}
interface S {
  loading: boolean;
  isRegistration: boolean;
  bussiness_profile: boolean;
  data: any;
  all_data: any;
  token: string | null;
}
interface SS {
  id: any;
}

export default class CreateProfileBasicHistoryController extends BlockComponent<
  Props,
  S,
  SS
> {
  createAccountApiCallId: any;
  formref = React.createRef();
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.receive = this.receive.bind(this);
    this.state = {
      bussiness_profile: true,
      loading: true,
      isRegistration: true,
      data: this.props.history ? this.props.history.location.state : "",
      all_data: {},
      token: localStorage.getItem("token"),
    };
  }
  handleHistoryData = (values: any) => {
    console.log(values);
    let all_data = this.state.all_data;
    if (all_data.history) {
      all_data.history = [values];
    } else {
      Object.assign(all_data, { history: [values] });
    }
  };
  handleExpansionData = (values: any) => {
    let all_data = this.state.all_data;
    if (all_data.expansion) {
      all_data.expansion = [values];
    } else {
      Object.assign(all_data, { expansion: [values] });
    }
  };
  handleCoreTeamData = (values: any) => {
    console.log(values);
    let all_data = this.state.all_data;
    if (all_data.core_team) {
      all_data.core_team = [values];
    } else {
      Object.assign(all_data, { core_team: [values] });
    }
  };
  handleClientRefData = (values: any) => {
    let all_data = this.state.all_data;
    if (all_data.client_references) {
      all_data.client_references = [values];
    } else {
      Object.assign(all_data, { client_references: [values] });
    }
  };
  handleAddProfileData = () => {
    if (
      this.state.all_data &&
      Object.keys(this.state.all_data).length > 0 &&
      this.state.token !== null
    ) {
      const header = {
        "Content-Type": "application/json",
        token: this.state.token,
      };
      let updated_data = { ...this.state.data, ...this.state.all_data };
      const json_data = {
        data: { attributes: updated_data },
      };
      console.log(json_data);
      const httpBody = json_data;
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.createAccountApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.addProfileAPIEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "POST"
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };
  async receive(from: string, message: Message) {
    console.log("receive");
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson) {
        console.log(responseJson);
        if (responseJson.data) {
          // console.log("login")
          confirm("Data Saved Successfully");
          // this.props.navigation.getParam(responseJson)
          // this.props.navigation.navigate("VerifyEmail")
        } else if (responseJson.errors) {
          console.log(responseJson.errors[0].failed_login);
          AlertError(responseJson.errors[0]);
        } else {
          console.log("something went wrong");
        }
      } else {
        console.log("hello");
      }
    }
  }
}
