import React, { FunctionComponent, ReactNode } from "react";
import { Typography, List, Avatar, Button, Badge, Row, Col, message, Spin } from "antd";
const { Text } = Typography;
import "./userFeed.css";
import { acceptIcon, rejectIcon, chatIconImage } from "./assets";
import { useHistory } from "react-router-dom";
// import { count } from "console";
interface Props {
  children?: ReactNode;
  data?: any;
  description?: boolean | true;
  title?: boolean | true;
  count?: boolean | true;
  label?: string;
  name?: string;
  isLoading: boolean;
  isCommunityForum?: boolean | false;
  isFromKnownPeople?: boolean | false;
  isFromAnniversaries?: boolean | false;
  isFromWorkAnniversaries?: boolean | false;
  isBirthAnniversaries?: boolean | false;
  isInvitation?: boolean | false;
  isFromCommunityPeopleFollow?: boolean | false;
  onClickInvitation?: any;
  onViewAll?: Function;
  onItemPress: Function;
  onReload?: Function;
  onAccept?: (id: string) => void;
  OnDecline?: (id: string) => void;
  // onFollow?: (id: string) => void;
  onVisit?: (id: String) => void;
  postCount?: any;
}

interface S {
  data: any;
  keyExtractor: any;
  render: any;
  itemSeparatorComponent: any;
  listHeaderComponent: any;
  stickyHeaderIndices: any;
}
const CommonListItem: FunctionComponent<Props> = (props) => {
  let history = useHistory();
  const info = () => {
    message.info('Coming soon');
  };

  const handleUserRequest = (value: number, role: string) => {
    // console.log(value,"Asdaklds by Onclick")
    // alert(value);
    // alert(role);
    if (role === "personal") {
      return history.push({ pathname: "/other/personal/profile", state: value });
    } else if (role === "business") {
      return history.push({ pathname: "/other/business/profile", state: value });
    }
  }


  const customPostUnseenValue = (postId: any, unreadData: any) => {
    let countData = unreadData?.find((c: any) => +c.id === +postId);
    return countData?.posts_data?.unread;
  }

  return (
    <div style={{ marginTop: 10 }}>
      <Row
        className="ant-row-middle"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Col>
          <Text style={{
            fontSize: "16px", fontWeight: "bold", wordWrap: 'break-word',
            width: '-webkit-fill-available'
          }}>
            {props.label}
          </Text>
        </Col>
        <Col>
          <Button
            type="link"
            style={{ paddingRight: 0 }}
            onClick={() => { props.onViewAll && props.onViewAll() }}
          >
            {props?.data?.length > 0 && <Text className="follow-label">View All</Text>}
          </Button>
        </Col>
      </Row>
      {props.isLoading ? <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Spin />
      </div> : (
        <List
          style={{ backgroundColor: "white", borderRadius: 5, marginTop: 10 }}
          dataSource={props?.data}
          renderItem={(item: any) => {
            return (
              <List.Item
                className="commonList_responsive"
                style={{ cursor: 'pointer', padding: "10px 0px 10px 10px" }}
                onClick={() => props.onItemPress(item.id)}
                actions={[
                  <div style={{ padding: "0px" }}>
                    {props?.isCommunityForum && (
                      <Badge
                        style={{
                          alignItems: "center",
                        }}
                        count={customPostUnseenValue(item?.attributes?.id, props.postCount)}
                      />
                    )}
                    {(props?.isBirthAnniversaries || props?.isFromWorkAnniversaries) && item?.days_left === 0 && (
                      <Button
                        type="text"
                        shape="circle"
                        size="large"
                        icon={
                          <img src={chatIconImage} width={28} />
                        }
                        onClick={() => {
                          // console.log(item?.account?.data?.id, "item")
                          history.push({ pathname: "/chat", state: { userId: item?.account?.data?.id } })
                        }}
                      />
                    )}
                    {props.isInvitation && (
                      <div>
                        <Button
                          type="text"
                          shape="circle"
                          size="large"
                          icon={
                            <img src={acceptIcon} width={28} />
                          }
                          onClick={() => {
                            props.onClickInvitation(
                              { name: item?.attributes?.full_name, req_id: item?.id, cat_id: item?.attributes?.category_id, account_id: item?.attributes?.account_id }, item?.attributes?.role)
                          }}
                        />
                        <Button
                          type="text"
                          shape="circle"
                          size="large"
                          icon={
                            <img src={rejectIcon} width={28} />
                          }
                          onClick={() => props.onClickInvitation(
                            { name: item?.attributes?.full_name, req_id: item?.id, cat_id: item?.attributes?.category_id, account_id: item?.attributes?.account_id }, item?.attributes?.role)}
                        />
                      </div>
                    )}
                    {props.isFromKnownPeople
                      &&
                      (
                        item?.attributes?.invited ? (
                          <Button
                            type="text"
                            shape="round"
                            className="addConnection-outline"
                          >
                            Request Sent
                          </Button>) : (
                          <Button
                            type="text"
                            shape="round"
                            className="addConnection-outline"
                            onClick={() =>
                              props.onClickInvitation(
                                {
                                  name: item?.attributes?.role === "business" ?
                                    item?.attributes?.profile?.data?.attributes?.company_name :
                                    item?.attributes?.full_name,
                                  req_id: item?.id,
                                  cat_id: item?.attributes?.category_id,
                                  account_id: item?.attributes?.account_id,
                                }, item?.attributes?.role)}
                          >
                            Add connection
                          </Button>)
                      )}
                    {props.isFromCommunityPeopleFollow && (
                      <Button
                        type="text"
                        shape="round"
                        className="addConnection-outline"
                      >
                        Visit
                      </Button>
                    )}
                  </div>
                ]}
              >
                <List.Item.Meta
                  avatar={
                    <div
                      onClick={() => {
                        handleUserRequest(item?.account?.data ?
                          item?.account?.data?.attributes?.account_id :
                          item?.attributes?.account_id
                          ,
                          item?.account?.data ?
                            item?.account?.data?.attributes?.role : item?.attributes?.role)
                      }}
                    >
                      <Avatar
                        src={item?.attributes?.profile_pic ? item?.attributes?.profile_pic : item?.account?.data?.attributes?.photo ?
                          item?.account?.data?.attributes?.photo : item.attributes?.photo}
                        size={props?.description ? 45 : 40}
                      />
                    </div>
                  }
                  title={
                    <Text
                      className="margin-top5"
                      style={{
                        textTransform: "capitalize",
                      }}
                      onClick={() => {
                        handleUserRequest(item?.account?.data ?
                          item?.account?.data?.attributes?.account_id :
                          item?.attributes?.account_id
                          , item?.account?.data ?
                            item?.account?.data?.attributes?.role : item?.attributes?.role)
                      }}
                    >
                      {item?.attributes?.name ? item?.attributes?.name?.substring(0, 25) :
                        item?.attributes?.role === "business" ? item?.attributes?.profile?.data?.attributes?.company_name?.substring(0, 25) :
                          item.account?.data?.attributes?.full_name ?
                            item.account?.data?.attributes?.full_name?.substring(0, 25) :
                            item.account?.data?.attributes?.first_name ? item.account?.data?.attributes?.first_name?.substring(0, 25) : item.attributes?.full_name?.substring(0, 25)}
                      &nbsp;{item?.days_left > 0 ? `IN ${item?.days_left} DAYS` : item?.days_left === 0 ? `TODAY` : null}
                    </Text>
                  }
                  description={
                    <p
                      style={{
                        textTransform: "capitalize",
                        textAlign: "initial",
                        wordWrap: "break-word",
                        paddingBottom: "0px",
                        marginBottom: "0px"
                      }}
                    >
                      {item?.attributes?.topics ? item?.attributes?.topics?.substring(0, 25) : item?.title?.substring(0, 25)}
                    </p>
                  }
                />
              </List.Item>
            );
          }}
        />)
      }
    </div>
  );
};

export default CommonListItem;

// Customizable Area Start

// Customizable Area End
