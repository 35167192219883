import React from "react";
import FollowersFollowingController, { Props } from "./FollowersFollowingController.web";
import "./../userFeed.css";
import {
  Card,
  Row,
  Typography,
  Avatar,
  Tabs,
  Spin
} from "antd";

const { Text } = Typography;
const { TabPane } = Tabs;

interface UserDataType {
  id: string | number,
  role: string,
  first_name: string,
  last_name: string,
  full_name: string,
  company_name: string,
  profile_pic: string,
  following: boolean
}

export default class FollowersFollowing extends FollowersFollowingController {
  render() {
    return (
      <div
        className="background"
        style={{
          backgroundColor: "rgb(246, 246, 246)",
        }}
      >
        {this.redirectUser()}
        <Row
          style={{
            paddingTop: "15px", marginBottom: 7, marginLeft: 20
          }}
        >
          <Text
            className="textlabel_subheadingText"
          >
            My Profile /
          </Text>
          <Text
            className="textlabel_subheadingText2"
          >
            {" "}
            Followers / Following
          </Text>
        </Row>
        <span className="textlabel_headingText" style={{ marginLeft: 20 }}>Followers / Following</span>
        <Card style={{ width: "100%", backgroundColor: "rgb(246, 246, 246)", border: 'none' }}>
          <Tabs
            activeKey={this.state.keyValue}
            defaultActiveKey="1"
            size="large"
            tabBarStyle={{
              color: "#919191",
              backgroundColor: "#dee5eb",
              height: "3rem"
            }}
            style={{
              borderRadius: "3px",
              backgroundColor: "#f1f0f5"
            }}
            data-test-id="tab-change"
            onChange={this.handleChangeTab}
          >
            <TabPane
              tab={`Followers(${this.state.followers?.length})`}
              data-test-id="followers-tab"
              key="1"
              style={{
                width: "96%",
                height: "100%",
                margin: "auto"
              }}
            >
              {
                this.state.followers.length
                  ? this.state.isLoading.isLoadingfollowers ? <Spin /> : (this.state.followers.map((item: UserDataType) => (
                    <div
                      className="follow_main"
                      key={item.id}
                    >
                      <div className="follow_content">
                        <div
                          className="follow_profile"
                          style={{
                            cursor: 'pointer'
                          }}
                          data-test-id={`followers-tab${item.id}`}
                          onClick={() => this.handleClickOnUser(item)}
                        >
                          <Avatar src={item.profile_pic} size={50} />
                          <Typography>{item.role === 'business' ?
                            item.company_name :
                            item.full_name
                          }</Typography>
                        </div>
                        <div className="follow_button">
                          <button
                            data-test-id={`follow-unfollow-Button${item.id}`}
                            onClick={() => this.onHandleFollow(item.id)}
                          >
                            {
                              item.following
                                ? "Unfollow"
                                : "Follow"
                            }
                          </button>
                        </div>
                      </div>
                    </div>
                  )))
                  :
                  <div><h3>No Followers</h3></div>
              }
            </TabPane>
            <TabPane
              data-test-id="following-tab"
              tab={`Following(${this.state.followings?.length})`}
              key="2"
              style={{ width: "96%", height: "100%", margin: "auto" }}
            >
              {
                this.state.followings.length
                  ? this.state.isLoading.isLoadingfollowing ? <Spin /> : (this.state.followings.map((item: UserDataType) => (
                    <div className="follow_main" key={item.id}>
                      <div className="follow_content">
                        <div className="follow_profile" style={{
                          cursor: 'pointer'
                        }}
                          data-test-id={`following-tab${item.id}`}
                          onClick={() => this.handleClickOnUser(item)}
                        >
                          <Avatar src={item.profile_pic} size={50} />
                          <Typography>{item.role === 'business' ?
                            item.company_name :
                            item.full_name
                          }</Typography>
                        </div>
                        <div className="following_button">
                          <button
                            data-test-id={`follow-unfollow-Button${item.id}`}
                            onClick={() => this.onHandleFollow(item.id)}
                          > Unfollow</button>
                        </div>
                      </div>
                    </div>
                  )))
                  :
                  <div><h3>No Followings</h3></div>
              }
            </TabPane>
          </Tabs>
        </Card>
      </div>
    );
  }
}
