import React from 'react';
import { Modal, Button, Space } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

export default function confirm(msg:string){
    Modal.success({
      title: 'Success',
      content: msg,
      okText: 'Ok',
  });
}
