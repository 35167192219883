import React from "react";
import CommonSettingController from "./CommonSettingsController.web";
import "./settings.css";
import { Space, Typography, Row, Col, Spin, Empty, PageHeader } from 'antd';
import { read } from 'fs';
const { Title, Text } = Typography;
//@ts-ignore
import Logo from "../../assets/logo1.png";

export default class TermsandConditions extends CommonSettingController {

  // Customizable Area Start
  render() {
    const { policiesData } = this.state;

    return (
      <>
        <PageHeader
          className="site-page-header"
          title={
            <span style={{ color: "white" }}>
              <img
                style={{
                  height: "32px",
                  marginRight: "8px"
                }}
                src={Logo}
              />
              applied
            </span>
          }
          style={{
            backgroundImage: "linear-gradient(#005574, #7aacd6)",
            color: "white"
          }}
        />
        <div style={{ background: '#eff8fe' }}>
          <div style={{ width: "80%", margin: '0 auto' }}>
            <Space direction='vertical' style={{ width: "100%" }}>
              <Row gutter={[16, 16]}>
                {
                  <Col xs={{ span: 24 }} lg={{ span: 24 }} style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                    <h2 style={{ textAlign: 'center' }}>
                      {policiesData[0]?.attributes?.title}
                    </h2>
                    <div style={{ width: "100%", backgroundColor: "#ffffff", padding: "20px 20px 10px 20px" }}>
                      {
                        (policiesData[0]?.attributes?.subheadings?.data)?.map((value: any, id: number) => {
                          return (
                            <div style={{ display: "flex", flexDirection: "column" }}>
                              <Text strong style={{ fontSize: "14px" }}>{value?.attributes?.title}</Text>
                              <div dangerouslySetInnerHTML={{ __html: value?.attributes?.description }} style={{ margin: "0px", color: "rgba(151, 151, 151, 1)" }}></div>
                            </div>
                          )
                        })
                      }
                    </div>
                  </Col>
                }
              </Row>
            </Space>
          </div>
        </div>
      </>
    );
  }
  // Customizable Area End
}
