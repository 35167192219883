import React from "react";
import { View } from "react-native";
// import Select from "react-select";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { Select } from "antd";
const { Option } = Select;
const configJSON = require("./config");

interface Props {
  navigation: any;
  style?: any;
  outerStyle?: any;
  id: string;
  disable: boolean;
  allowPropChange: boolean;
  value: string;
  placeHolder: string | any;
  name: string;
}

interface S {
  dataSource: any[];
  countryCodeSelected: string;
  mobileNo: string;
  token: string;
  placeHolder: string | any;
  disable: boolean;
  label: any;
}

interface SS { }

export default class CountryCodeSelector extends BlockComponent<Props, S, SS> {
  countryCodeApiCallId: any;

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    this.receive = this.receive.bind(this);
    this.handleChange = this.handleChange.bind(this);
    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      dataSource: [],
      countryCodeSelected: "",
      mobileNo: "",
      token: "",
      placeHolder: configJSON.countryPlaceHolder,
      disable: this.props.disable,
      label: null
    };
  }

  render() {
    var index = -1;
    var iIndex = -1;
    // console.log(this.props?.placeHolder);
    // console.log(this.props?.value);
    // console.log(this.props?.navigation);
    this.state.dataSource.forEach(item => {
      iIndex += 1;
      if (item.value === this.props.value) {
        index = iIndex;
        return;
      }
    });

    return (
      // <View style={[{ marginBottom: 10, zIndex: 99 }, this.props.outerStyle]}>
      //   <Select
      //     style={this.props.style}
      //     // maxMenuHeight={500}
      //     options={this.state.dataSource}
      //     placeholder={this.props.placeHolder}
      //     onChange={this.handleChange}
      //     value={index >= 0 ? this.state.dataSource[index] : null}
      //     isDisabled={this.state.disable}
      //     name={this.props.name}
      //   />
      // </View>
      <Select
        bordered={false}
        dropdownStyle={{
          borderRadius: "15px",
          border: "1px solid #a9bac8",
          paddingTop: "0px",
          paddingBottom: "0px",
          color: "#a9bac8",
        }}
        style={{
          width: "120px",
          borderRadius: '25px',
          color: "#a9bac8",
          border: "1px solid #a9bac8",
          // backgroundColor: "red"
        }}
        onChange={this.handleChange}
        value={this.props.placeHolder}
        dropdownMatchSelectWidth={300}
        showArrow={true}
        onSearch={this.onSearch}
        size="large"
      >
        {this.state.dataSource.map((item, index) => (
          <option
            style={{
              borderBottom: "1px solid #7aacd6",
              padding: "10px 20px 10px 20px",
            }}
            value={item.label}
            key={index}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                // paddingTop: "0.7rem",
              }}
            >
              <div>{item.label}</div>
            </div>
          </option>
          // <Option value={item.label}>{item.label}</Option>
        ))}
      </Select>
    );
  }
  onSearch(item: any) {
    const msg = new Message(getName(MessageEnum.CountryCodeMessage));
    msg.addData(getName(MessageEnum.CountyCodeDataMessage), {
      result: item,
      isValue: false
    });
    runEngine.sendMessage(getName(MessageEnum.CountryCodeMessage), msg);
  }
  handleChange(item: any) {
    const result = this.state.dataSource.filter(word => word.label == item);
    this.setState({ label: result[0].value });
    const msg = new Message(getName(MessageEnum.CountryCodeMessage));
    msg.addData(getName(MessageEnum.CountyCodeDataMessage), {
      result: result[0],
      isValue: true
    });
    runEngine.sendMessage(getName(MessageEnum.CountryCodeMessage), msg);
  }

  async componentDidMount() {
    this.makeRemoteRequest();
  }

  countryCodesToDropDown = (data: any) => {
    return data.map((item: any) => ({
      label: ` ${item.attributes.emoji_flag} ${item.attributes.name} (${item.id
        }) +${item.attributes.country_code}`,
      value: item.attributes.country_code,
      flag: item.attributes.emoji_flag
    }));
  };

  async receive(from: String, message: Message) {
    // runEngine.debugLog("Country Code", message);

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.countryCodeApiCallId != null &&
      this.countryCodeApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      //
      if (responseJson && !responseJson.errors) {
        this.setState({
          dataSource: this.countryCodesToDropDown(responseJson.data)
        });
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );

        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
  }

  makeRemoteRequest = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.countryCodeApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiEndPointGetCountryCodes
    );

    const header = {
      "Content-Type": configJSON.contentTypeApiGetCountryCodes
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetCountryCodesType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
}
