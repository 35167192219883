import React from "react";
// Customizable Area Start
import {
  Grid,
  withStyles,
  Button,
  Box,
  CircularProgress,
} from "@material-ui/core";
import SavedPostsItems from "./SavedPostsItems.web";
import { Row, Typography, Col, Avatar, Empty } from "antd";
const { Text } = Typography;
import HidePostsController, { Props } from './HidePostsController.web';
// Customizable Area End

class HidePosts extends HidePostsController {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.getMyHidePosts();
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const { pageLoader, hidePosts } = this.state;
    const pageLoading = (
      <Grid
        container
        style={{ display: "flex", width: "100%", minHeight: "200px" }}
      >
        <Box style={{ margin: "auto" }}>
          <CircularProgress />
        </Box>
      </Grid>
    );
    // Customizable Area End

    // Customizable Area Start
    return (
      <React.Fragment>
        <div style={{ backgroundColor: "#f6f6f6", width: "100%" }}>
          {this.redirectUser()}
          <Row
            style={{
              paddingTop: "15px", marginBottom: 7, marginLeft: 20
            }}
          >
            <Text
              className="textlabel_subheadingText"
            >
              My Profile /
            </Text>
            <Text
              className="textlabel_subheadingText2"
            >
              {" "}
              Hide Posts
            </Text>
          </Row>
          <span className="textlabel_headingText" style={{ marginLeft: 20 }}>Hide Posts</span>
          <Grid container style={{ padding: "20px" }}>
            <Grid
              item
              xs={12}
              style={{
                marginTop: "20px",
                backgroundColor: "white",
                borderRadius: "15px",
              }}
            >
              {pageLoader ? (
                pageLoading
              ) : hidePosts.length > 0 ? (
                hidePosts.map((val: any, index: number) => (
                  <Grid item xs={12} key={index + "1"}>
                    <SavedPostsItems
                      classes={classes}
                      item={val}
                      deletePost={this.deletePost}
                      hidePost
                      loginId={this.state.userProfile && this.state.loginId}
                    />
                  </Grid>
                ))
              )
                : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              }
            </Grid>
          </Grid>
        </div>
      </React.Fragment>
    );
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
}
// Customizable Area Start
const styles = () => ({
  deletPost: {
    bottom: 0,
    right: 0,
    background: "white",
    ['@media (max-width:576px)']: { // eslint-disable-line no-useless-computed-key
      minWidth: '26px',
      bottom: 9,
    }
  },
  image: {
    width: 80,
    height: 80,
    borderRadius: 4,
    background: "grey",
  },
  postTitle: {
    marginBottom: 0,
    fontSize: 16,
    fontWeight: 600,
  },
  postContainer: {
    padding: "20px",
    borderBottom: "1px solid #e0e0e0",
  },
  Responsive: {
    ['@media (max-width:576px)']: { // eslint-disable-line no-useless-computed-key
      width: '100%'
    }
  }
});

export default withStyles(styles)(HidePosts);
// Customizable Area End
