import React from "react";
import PostRatingController, { Props } from "./PostRatingController.web";
import { editer } from "../assets";
import CommonListItem from "../commonListItem.web";
import {
  EnvironmentOutlined,
  CloseOutlined,
  MessageFilled,
  PaperClipOutlined,
  PictureOutlined,
  SendOutlined,
  SmileOutlined,
  FormOutlined,
  DeleteOutlined
} from "@ant-design/icons";
import "./../userFeed.css";
import moment from "moment";
import {
  Card,
  Row,
  Col,
  List,
  Input,
  Typography,
  Avatar,
  Button,
  Upload,
  Image,
  Badge,
  Radio,
  Tabs,
  Space,
  Modal, Form, Checkbox, Empty, message, Spin
} from "antd";
import CustomPost from "../CustomPost.web";
import Picker from "emoji-picker-react";
import ReactPlayer from "react-player";
import { Dimensions, PixelRatio } from "react-native";
import { Divider } from "react-native-elements/dist/divider/Divider";
// import CustomHeader from "../CustomHeader.web";
import SliderView from "./SliderView.web";
import ProfileRatingAppliedProgressBar from "./profileRatingAplliedProgressBar.web";
import {
  UserOutlined,
  // EnvironmentOutlined,
  CloseCircleOutlined,
  CheckCircleTwoTone,
  PlusCircleFilled,
  ExclamationCircleOutlined,
} from "@ant-design/icons";

const { Title, Text } = Typography;
const { TextArea } = Input;
const { TabPane } = Tabs;
const onFinishFailed = (errorInfo: any) => {
  console.log('Failed:', errorInfo);
};

const addRatingList = [
  {
    id: 1,
    value: "Attitude",
    color: "green",
  },
  {
    id: 2,
    value: "Performance",
    color: "#b53f3e",
  },
  {
    id: 3,
    value: "Productivity",
    color: "#b53f3e",
  },
  {
    id: 4,
    value: "Leadership",
    color: "#87ceeb",
  },
  {
    id: 5,
    value: "Integrity",
    color: "#87ceeb",
  },
  {
    id: 6,
    value: "Expertise",
    color: "#87ceeb",
  },
  {
    id: 7,
    value: "Diversity",
    color: "#87ceeb",
  },
];

function callback(key: any) {
  console.log(key);
}

export default class RatingReview extends PostRatingController {
  //Customizable Area Start
  render() {
    const { navigation } = this.props;
    console.log(this.state.ratingSends, "............/////////")
    console.log(this.state.reviews, ".........")
    const progressLinearBarData = this.state.ratingSends?.attributes?.rating['General Business Rating']
    return (
      <div
        className="background"
        style={{
          backgroundColor: "rgb(246, 246, 246)",
        }}
      >
        {this.redirectUser()}
        {/* <CustomHeader {...this.props} dotVisible={false} /> */}
        <Row
          style={{
            paddingTop: "15px", marginBottom: 7, marginLeft: 20
          }}
        >
          <Text
            className="textlabel_subheadingText"
          >
            {this?.props?.location?.state?.home ? null : "My Profile /"}
          </Text>
          <Text
            className="textlabel_subheadingText2"
          >
            {" "}
            Ratings
          </Text>
        </Row>
        <span className="textlabel_headingText" style={{ marginLeft: 20 }} >My Ratings</span>
        <Card style={{ width: "100%", backgroundColor: "rgb(246, 246, 246)", border: 'none' }}>
          <Tabs
            defaultActiveKey={`${this?.props?.location?.state?.keyValue}`}
            size="large"
            tabBarStyle={{
              color: "#919191",
              backgroundColor: "#dee5eb",
              height: "3rem"
            }}
            style={{
              borderRadius: "3px",
              backgroundColor: "#f1f0f5"
            }}
          >
            <TabPane
              tab="Rating Sent"
              key="1"
              style={{
                width: "100%",
                height: "100%",
                // margin: "auto"
              }}
            >
              <Card
                style={{
                  width: "100%",
                  height: "100%",
                  // marginBottom: "1rem",
                  backgroundColor: "#f1f0f5"
                }}
              >
                <>
                  {this.state.EditRatingLoader ? <Spin /> : <div>
                    {this.state.ratingSends?.length !== 0 ?
                      this.state.ratingSends &&
                      <>
                        <Row >
                          {this.state.ratingSends?.sort((k: any, l: any) => l?.attributes?.created_at?.localeCompare(k?.attributes?.created_at)).map((a: any, index: any) =>
                            // console.log(a?.attributes?.rating['General Business Rating'].Attitude, "rattttt")
                            <Col
                              key={a.attributes?.id}
                              xs={{ span: 24 }}
                              lg={{ span: 24 }}
                              xl={{ span: 24 }}
                              style={{ padding: '5px 15px', borderBottom: '1px solid #d0d0d0ab' }}
                            >
                              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', margin: "8px 0px" }}>
                                {/* {profile rating information} */}
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '80%' }}>
                                  <Avatar
                                    src={a?.attributes?.account?.data?.attributes?.photo}
                                    size={50}
                                    style={{ marginLeft: "0px", backgroundColor: "lightgrey" }}
                                  />
                                  <div style={{ marginLeft: '1%' }}>
                                    <div><Text style={{
                                      color: 'grey',
                                      fontSize: '15px',
                                      letterSpacing: '0.2px'
                                    }}

                                    >
                                      You have rated<span
                                        style={{
                                          color: '#000',
                                          fontWeight: 600
                                        }}
                                      >{' '}{a?.attributes?.account?.data?.attributes?.full_name}</span> as{' '}
                                      <span
                                        style={{
                                          color: '#000',
                                          fontWeight: 600,
                                          textTransform: 'lowercase'
                                        }}
                                      >{a?.attributes?.account?.data?.attributes?.connected_as}</span></Text></div>
                                    <div><Text
                                      style={{
                                        color: 'grey',
                                        fontSize: '12px',
                                        opacity: '0.5',
                                        letterSpacing: '0.2px'
                                      }}
                                    >{moment(a?.attributes?.created_at).startOf('seconds').fromNow()}</Text></div>
                                  </div>
                                </div>
                                {/* {Edit button } */}
                                <div style={{ fontSize: 16, letterSpacing: '0.2px', cursor: 'pointer' }}>
                                  <Text data-test-id={`RatingViewBtn${index}`} onClick={() => this.props.history.push({
                                    pathname: './Rating/view',
                                    state: { ...a?.attributes?.account?.data?.attributes, isSentRating: true, home: this?.props?.location?.state?.home ? true : false }
                                  })}>
                                    view
                                  </Text>
                                </div>
                              </div>
                            </Col>

                          )}
                        </Row>
                      </> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    }
                  </div>
                  }</>

              </Card>
            </TabPane>
            <TabPane
              tab="Rating Received"
              key="2"
              style={{
                width: "100%",
                height: "100%",
                // margin: "auto"
              }}
            >

              <Card
                style={{
                  width: "100%",
                  height: "100%",
                  // marginBottom: "1rem",
                  backgroundColor: "#f1f0f5"
                }}
              >
                <>
                  {this.state.ReceivedRatingLoader ? <Spin /> : <div>
                    {this.state.ratingReceived?.length !== 0 ?
                      this.state.ratingReceived &&
                      <>
                        <Row >
                          {this.state.ratingReceived?.sort((k: any, l: any) => l?.attributes?.created_at?.localeCompare(k?.attributes?.created_at)).map((a: any, index: any) =>
                            // console.log(a?.attributes?.rating['General Business Rating'].Attitude, "rattttt")
                            <Col
                              key={a.attributes?.id}
                              xs={{ span: 24 }}
                              lg={{ span: 24 }}
                              xl={{ span: 24 }}
                              style={{ padding: '5px 15px', borderBottom: '1px solid #d0d0d0ab' }}
                            >
                              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', margin: "8px 0px" }}>
                                {/* {profile rating information} */}
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '80%' }}>
                                  <Avatar
                                    src={a?.attributes?.rater?.data?.attributes?.photo}
                                    size={50}
                                    style={{ marginLeft: "0px", backgroundColor: "lightgrey" }}
                                  />
                                  <div style={{ marginLeft: '1%' }}>
                                    <div><Text style={{
                                      color: 'grey',
                                      fontSize: '15px',
                                      letterSpacing: '0.2px'
                                    }}

                                    > <span
                                      style={{
                                        color: '#000',
                                        fontWeight: 600
                                      }}
                                    >{a?.attributes?.rater?.data?.attributes?.full_name}</span> has rated you as {' '}
                                      <span
                                        style={{
                                          color: '#000',
                                          fontWeight: 600,
                                          textTransform: 'lowercase'
                                        }}
                                      >{a?.attributes?.rater?.data?.attributes?.connected_as}</span></Text></div>
                                    <div><Text
                                      style={{
                                        color: 'grey',
                                        fontSize: '12px',
                                        opacity: '0.5',
                                        letterSpacing: '0.2px'
                                      }}
                                    >{moment(a?.attributes?.created_at).startOf('seconds').fromNow()}</Text></div>
                                  </div>
                                </div>
                                {/* {Edit button } */}
                                <div style={{ fontSize: 16, letterSpacing: '0.2px', cursor: 'pointer' }}>
                                  <Text data-test-id={`ratingReceivedBtn${index}`} onClick={() => this.props.history.push({
                                    pathname: './Rating/view',
                                    state: { ...a?.attributes?.rater?.data?.attributes, isSentRating: false, ratings: a?.attributes?.rating, home: this?.props?.location?.state?.home ? true : false }
                                  })}>
                                    view
                                  </Text>
                                </div>
                              </div>
                            </Col>

                          )}
                        </Row>
                      </> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    }
                  </div>}
                </>

              </Card>
              {/* {
                this.state.reviews.length !== 0
                  ? this.state.reviews?.map((rev: any) => (
                    <div className="follow_main" key={rev.id}>
                      <div className="review_content">
                        <div className='d-f'>
                          <div className="follow_profile">
                            <Avatar src={rev.attributes?.account?.data?.attributes?.photo} size={40} />
                            <div style={{ marginLeft: 20 }}>
                              <span style={{ display: 'block' }}>{rev.attributes?.account?.data?.attributes?.full_name}</span>
                              <span>{rev.attributes?.account?.data?.attributes?.designation?.title} at {rev.attributes?.account?.data?.attributes?.designation?.company} | {rev.attributes?.account?.data?.attributes?.profile?.data?.attributes?.domain_skills}</span>
                            </div>
                          </div>
                          <div className=''>
                            <span>{moment(rev.attributes?.account?.data?.attributes?.updated_at).format("Do")} {moment(rev.attributes?.account?.data?.attributes?.updated_at).format("MMMM")}, {moment(rev.attributes?.account?.data?.attributes?.updated_at).format("YYYY")}</span>

                            <img
                              src={editer}
                              style={{ width: 18, height: 18, marginLeft: 7, marginBottom: 5 }}
                              onClick={() => this.setState({ isEditReview: true, editedReviewId: rev.id })}
                            />

                          
                            <DeleteOutlined style={{ marginLeft: 7 }} />
                          </div>
                        </div>
                        <div style={{ marginTop: 10 }}>
                          <h4>{rev.attributes?.title}</h4>
                          <p>{rev.attributes?.description}</p>
                        </div>
                      </div>
                      <Modal
                        footer={null}
                        bodyStyle={{
                          width: "100%",
                          height: "62vh",
                          borderRadius: "5px",
                        }}
                        title={
                          <Space
                            style={{
                              width: "100%",
                              cursor: "move",
                              fontWeight: "bold",
                              color: "#414141"
                            }}
                          >
                            Edit Review
                          </Space>
                        }
                        visible={this.state.isEditReview}
                        onCancel={() => this.setState({ isEditReview: false })}
                        onOk={() => this.setState({ isEditReview: false })}
                      >
                        <Form
                          name="basic"
                          // initialValues={{ anonymous: false }}
                          onFinish={(value) => this.onFinishAddReview(value, this.state.editedReviewId)}
                          onFinishFailed={onFinishFailed}
                          autoComplete="off"
                        >
                          <Form.Item
                            name="title"
                            rules={[{ required: true, message: 'please add title!' }]}
                          >
                            <Input
                              placeholder="Add Title"
                              style={{ borderRadius: "25px", color: "#006285" }}
                              value="smart"
                            />
                          </Form.Item>

                          <Form.Item
                            name="description"
                            rules={[{ required: true, message: 'please add description!' }]}
                          >
                            <TextArea
                              placeholder="Add Description"
                              style={{
                                width: "100%",
                                height: "136px",
                                borderRadius: "0.6rem",
                                color: "#006285"
                              }}
                              value="asffbdfbadfb"
                            />
                          </Form.Item>

                          <Form.Item
                            name="anonymous"
                            valuePropName="checked"
                          >
                            <Checkbox
                              style={{ color: "#919191" }}
                            >
                              Post Review Anonymously
                            </Checkbox>
                          </Form.Item>

                          <Form.Item>
                            <Button
                              htmlType="submit"
                              style={{
                                width: "100%",
                                height: "2.5rem",
                                borderRadius: "25px",
                                backgroundColor: "#006285",
                                color: "#ffffff"
                              }}
                            >
                              Post
                            </Button>
                          </Form.Item>
                        </Form>
                      </Modal>
                    </div>
                  ))
                  :
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              } */}
            </TabPane>
          </Tabs>
        </Card>
        {/* <Modal title="Subscribe to our Newsletter"
              footer={null}
              onOk={() => this.setState({ isEditReview: false })}
              onCancel={() => this.setState({ isEditReview: false })}
              visible={this.state.isEditReview} >
              {this.state.isModalNewsletterConfirm
                ?
                <>
                  <p style={{ fontSize: 18, color: '#d9d9d9' }}>Thank You!</p>
                  <p style={{ fontSize: 18, color: '#d9d9d9' }}>Before receiving Newsletter from applied, you need to confirm your email address.</p>
                  <p style={{ fontSize: 18, color: '#d9d9d9' }}>Please check your mailbox and confirm subscription.</p>
                </>
                :
                <>
                  <p style={{ fontSize: 18, color: '#d9d9d9' }}>Get handpicked articles once a week to get latest news from your industry</p>
                  <Row style={{ justifyContent: "space-around", marginBottom: 10 }} >
                    <Col style={{ width: 300 }}><Input placeholder={this.state.userProfile?.attributes?.profile?.data?.attributes?.email} className="model-input" /></Col>
                    <Col style={{ width: 150 }}><Button type="primary" className="model-button"
                      style={{ paddingLeft: 35, paddingRight: 35 }}
                      onClick={() => this.setState({ isModalNewsletterConfirm: true })}
                    >
                      Subscribe
                    </Button></Col>
                  </Row>
                </>
              }
        </Modal> */}
      </div>
    );
  }
  //Customizable Area End
}
