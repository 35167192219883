import React from 'react'
import { Modal, Button, Typography } from 'antd';
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';

const AlertMessage = (props: any) => {
    const { isModalVisible, onOk, body, subBody, failureModal } = props;
    return (
        <Modal
            closable={false}
            width={350}
            style={{ borderWidth: "0px", borderRadius: "4px", overflow: "hidden" }}
            centered
            title={null}
            visible={isModalVisible}
            footer={null}
        >
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                {failureModal ? (
                    <CheckCircleFilled
                        translate={{}}
                        style={{
                            fontSize: '50px',
                            marginBottom: '10px',
                            color: '#52c41a',
                        }}
                    />
                ) : (
                    <CloseCircleFilled
                        translate={{}}
                        style={{
                            fontSize: '50px',
                            marginBottom: '10px',
                            color: '#ea0c0c',
                        }}
                    />
                )}
                <Typography.Text strong>{body ? body : 'Something went Wrong'}</Typography.Text>
                <Typography.Text type='secondary'>{subBody ? subBody : 'Something went Wrong'}</Typography.Text>
                <Button
                    size="large"
                    className="report-button"
                    key="report"
                    onClick={onOk}
                    style={{ width: "50%", marginTop: "10px" }}
                >
                    Ok
                </Button>
            </div>
        </Modal>
    )
}

export default AlertMessage;