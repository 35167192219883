Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start

exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "PrivacySettings";
exports.labelBodyText = "PrivacySettings Body";

exports.getSettingsApiContentType = "application/json";
exports.getSettingsApiEndpoint = "account_block/settings";
exports.getSettingsApiMethod = "GET";

exports.updateSettingsApiContentType = "multipart/form-data";
exports.newUpdateSettingsApiContentType = "application/json";
exports.updateSettingsApiEndpoint = "account_block/settings";
exports.updateSettingsApiMethod = "PUT";

//create contact us
exports.createContactUsApiContentType = "multipart/form-data";
exports.newCreateContactUsApiContentType = "application/json";
exports.createContactUsApiEndpoint = "account_block/issues";
exports.createContactUsApiMethod = "POST";

exports.getPoliciesApiContentType = "application/json";
exports.getPoliciesApiEndpoint = "account_block/policies";
exports.getPoliciesApiMethod = "GET";

exports.getUserAccountsApiEndpoint = "account_block/accounts";

//remove account
exports.removeAccountApiContentType = "multipart/form-data";
exports.removeAccountApiEndpoint = "account_block/accounts/remove_account";
exports.removeAccountApiMethod = "DELETE";

exports.suspendDeleteConfirmApiContentType = "multipart/form-data";
exports.newSuspendDeleteConfirmApiContentType = "application/json";
exports.suspendDeleteConfirmApiEndpoint = "account_block/accounts/remove_account";
exports.suspendDeleteConfirmApiMethod = "DELETE";

// Get blocked account list
exports.blockedAccountApiContentType = "application/json";
exports.blockedAccountApiEndpoint = "account_block/accounts/blocked_list";
exports.blockedAccountApiMethod = "GET";

// get Purchase History
exports.purchaseHistoryApiEndpoint = "purchase_histroy";

// post blocked account
exports.blockedAccountPostApiContentType = "application/json";
exports.blockedAccountPostApiEndpoint = "account_block/accounts";
exports.blockedAccountPostApiMethod = "POST";

// Customizable Area End
