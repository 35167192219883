import React from "react";
// Customizable Area Start
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
export const configJSON = require("./config");
import { ApiDataType, ReplieDataType, ProfileAttributesDataType } from "./Interface";
// Customizable Area End
export interface Props {
    // Customizable Area Start
    item: ApiDataType
    handleUserRequest: (value: ProfileAttributesDataType) => void
    onCommentLike: (commentId: string | number, liked: boolean) => Promise<void>
    // Customizable Area End
}
interface SS {
    // Customizable Area Start
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    rplyPostComment: string;
    userReplyCommentData: Array<ReplieDataType>;
    displayComment: boolean;
    emoji: boolean;
    onSendReplyComment: boolean;
    handleReplyComments: number
    // Customizable Area End
}

export default class RenderCommentController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    commentLikeStatusApi: string = ""
    commentReplycalledApi: string = ''
    // Customizable Area End
    constructor(props: Props) {
        // Customizable Area Start
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage)
        ];
        this.state = {
            rplyPostComment: "",
            userReplyCommentData: this.props.item.attributes.replies.data,
            displayComment: false,
            emoji: false,
            onSendReplyComment: false,
            handleReplyComments: 1
        }
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area End
    }
    // Customizable Area Start
    onRplyChange = (value: string) => {
        this.setState({
            rplyPostComment: value
        })
    };
    onReply = () => {
        this.setState({
            rplyPostComment: "",
            displayComment: !this.state.displayComment
        })
    }

    onReplyEmojiClick = (event: {}, emojiObject: { emoji: string; }) => {
        this.setState({
            rplyPostComment: this.state.rplyPostComment.concat(emojiObject.emoji),
            emoji: !this.state.emoji
        })
    };

    handleEmoji = () => {
        this.setState({
            emoji: !this.state.emoji
        })
    }

    handleViewMore = () => {
        this.setState({
            handleReplyComments: this.state.userReplyCommentData.length
        })
    }

    toggleCommentLike = async (commentId: number | string, liked: boolean) => {
        if (liked) {
            this.commentLikeStatus(commentId, 'dislike')
        } else {
            this.commentLikeStatus(commentId, 'like')
        }
    };

    handleSubmitReplyComment = (commentId: number, message: string) => {
        this.setState({
            onSendReplyComment: true,
            rplyPostComment: '',
            displayComment: false
        })
        this.CommentReplyComment(commentId, message);
    }

    CommentReplyComment = async (commentId: number, comment: string) => {
        let body = {
            data: {
                attributes: {
                    comment_id: commentId,
                    comment: comment
                }
            }
        };
        const token = localStorage.getItem("token");
        const header = {
            token: token,
            "Content-Type": "application/json"
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.commentReplycalledApi = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.commentEndPointApi}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            header
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(body)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.exampleAPiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    commentLikeStatus = async (postId: number | string, status: string) => {
        let httpBody = {
            data: {
                attributes: {
                    post_id: postId,
                },
            },
        };
        const token = localStorage.getItem("token");
        const header = {
            token: token,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.commentLikeStatusApi = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.commentEndPointApi}/${postId}/${status}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            header
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            httpBody
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.exampleAPiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    async receive(_from: string, message: Message) {
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );

        let responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            if (apiRequestCallId && responseJson) {
                if (apiRequestCallId == this.commentLikeStatusApi) {
                    const commentData1 = this.state.userReplyCommentData;
                    const updateCommentData1 = commentData1.map((item: ReplieDataType) => {
                        return (item.id === responseJson.data.id) ? responseJson.data : item
                    })
                    this.setState({
                        userReplyCommentData: updateCommentData1
                    })
                }
                if (apiRequestCallId === this.commentReplycalledApi) {
                    this.setState({
                        userReplyCommentData: [...this.state.userReplyCommentData, responseJson.data],
                        handleReplyComments: 1,
                        onSendReplyComment: false,
                    })
                }

            }


        }
    }
    // Customizable Area End
}
