import React, { useEffect, useState } from 'react';
import { Tabs, Card, Avatar, Typography, Button, Row, Col, List, Spin } from 'antd';
import axios from 'axios';
// @ts-ignore
import { baseURL } from '../../../../framework/src/config.js';
import { useHistory, useLocation } from 'react-router-dom';
import { getStorageData } from "../../../../framework/src/Helpers";

const { TabPane } = Tabs;
const { Text, Title } = Typography;

const FollowersAndFollowing = (props: any) => {
// Customizable Area Start
    const history = useHistory();
    const location: any = useLocation();
    const keyValue = location?.state?.keyValue;

    const [loginId, setLoginId] = useState('0');
    const [followersListData, setFollowersListData] = useState<Array<any>>([]);
    const [followingListData, setfollowingListData] = useState<Array<any>>([]);
    const [key, setKey] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const handleChangeTab = (value: any) => {
        setKey(`${value}`);
    }

    getStorageData("loginUser")
        .then((res) => setLoginId(res.id))
        .catch((err) => console.log(err));

    const handleFollow = (account_id: number) => {
        const data = {
            "account_id": account_id
        }
        axios(`${baseURL}/bx_block_follow/follows`, {
            method: "POST",
            data: JSON.stringify(data),
            headers: {
                token: localStorage.getItem('token'),
                "Content-Type": "application/json"
            },
        })
            .then((res) => {
                followersListValue();
                followingLisrValue();
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const followersListValue = () => {
        axios({
            method: "GET",
            url: `${baseURL}/account_block/accounts/followers_list`,
            headers: {
                token: localStorage.getItem('token'),
                "Content-Type": "application/json"
            }
        })
            .then((res) => {
                setFollowersListData(res.data.data);
                setIsLoading(false);
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const followingLisrValue = () => {
        axios({
            method: "GET",
            url: `${baseURL}/account_block/accounts/following_list`,
            headers: {
                token: localStorage.getItem('token'),
                "Content-Type": "application/json"
            }
        })
            .then((res) => {
                setfollowingListData(res.data.data);
                setIsLoading(false);
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const tabFollowersData = `Followers(${followersListData.length})`;
    const tabFollowingData = `Following(${followingListData.length})`;

    const handleUserRequest = (value: any) => {
        if (value?.account_id === loginId) {
            return null
        } else {
            if (value?.role === "personal") {
                return history.push({ pathname: "/other/personal/profile", state: value?.account_id });
            } else if (value?.role === "business") {
                return history.push({ pathname: "/other/business/profile", state: value?.account_id });
            }
        }
    }

    useEffect(() => {
        setKey(keyValue);
        followersListValue();
        followingLisrValue();
    }, [keyValue])

    return (
        <div style={{ padding: "20px", backgroundColor: "#f6f6f6", minHeight: "86vh" }}>
            <Row>
                <Col span={24}>
                    <Row>
                        <Col span={24} style={{ marginBottom: "10px" }}>
                            <Text style={{ fontSize: "14px", fontWeight: 500 }}><span style={{ color: "#d3d3d3" }}>Home/</span> <span style={{ color: "#5f92c4" }}>Followers/Following</span></Text>
                        </Col>
                        <Col span={24} style={{ marginBottom: "10px" }}>
                            <Title level={5} style={{ fontSize: "16px", fontWeight: 500 }}>Followers/Following</Title>
                        </Col>
                    </Row>
                </Col>
                <Col span={24}>
                    <Tabs
                        activeKey={key}
                        style={{ backgroundColor: "#f0f0f5", fontWeight: 600, borderRadius: "5px" }}
                        onChange={(e) => handleChangeTab(e)}
                    >
                        <TabPane
                            tab={tabFollowersData}
                            key="1"
                            className="tabPane-text"
                            style={{ padding: "20px" }}
                        >
                            {
                                isLoading ? <Spin />
                                    : <List
                                        style={{ width: "100%", borderRadius: "5px", overflow: "hidden" }}
                                        dataSource={followersListData}
                                        renderItem={item => (
                                            <List.Item style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", padding: "20px" }} key={item?.id}>
                                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                                    <Avatar size="large" src={item?.attributes?.photo} />
                                                    <Text
                                                        style={{ marginLeft: "1rem", cursor: "pointer" }}
                                                        onClick={() => handleUserRequest(item?.attributes)}
                                                    >
                                                        {item.attributes?.role==="business"?item.attributes?.profile?.data?.attributes?.company_name:
                                                        item?.attributes?.full_name}
                                                    </Text>
                                                </div>
                                                <div>
                                                    <Button
                                                        type="primary"
                                                        shape="round"
                                                        onClick={() => handleFollow(item?.attributes?.account_id)}
                                                        style={{ backgroundColor: "rgb(0, 85, 116)", display: item?.attributes?.following ? "none" : "block" }}
                                                    >
                                                        Follow
                                                    </Button>
                                                </div>
                                            </List.Item>
                                        )}
                                    />
                            }
                        </TabPane>
                        <TabPane
                            tab={tabFollowingData}
                            key="2"
                            className="tabPane-text"
                            style={{ padding: "20px" }}
                        >
                            {
                                isLoading ? <Spin />
                                    : <List
                                        style={{ width: "100%", borderRadius: "5px", overflow: "hidden" }}
                                        dataSource={followingListData}
                                        renderItem={item => (
                                            <List.Item style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", padding: "20px" }} key={item?.id}>
                                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                                    <Avatar size="large" src={item?.attributes?.photo} />
                                                    <Text
                                                        style={{ marginLeft: "1rem", cursor: "pointer" }}
                                                        onClick={() => handleUserRequest(item?.attributes)}
                                                    >
                                                       {item.attributes?.role==="business"?item.attributes?.profile?.data?.attributes?.company_name:
                                                        item?.attributes?.full_name}
                                                    </Text>
                                                </div>
                                                <div>
                                                    <Button
                                                        style={{ border: "1px solid rgb(0, 85, 116)", color: "rgb(0, 85, 116)", display: item?.attributes?.following ? "block" : "none" }}
                                                        type="default"
                                                        shape="round"
                                                        onClick={() => handleFollow(item?.attributes?.account_id)}
                                                    >
                                                        Unfollow
                                                    </Button>
                                                </div>
                                            </List.Item>
                                        )}
                                    />
                            }
                        </TabPane>
                    </Tabs>
                </Col>
            </Row>
        </div>
    )
// Customizable Area End    
}

export default FollowersAndFollowing;
