import React from "react";
import "./userFeed.css";
// import CustomHeader from "./CustomHeader.web";

// import {
//   Card,
//   Row,
//   Col,
//   List,
//   Input,
//   Typography,
//   Avatar,
//   Button,
//   Tabs
// } from "antd";
// import CustomPost from "./CustomPost.web";
// import CommunityBlock from "./CommunityBlock.web";
import UserFeedBlock from "./UserFeedBlock.web";
import HomeController, { Props } from "./HomeController.web";
// import CommunitySetting from "./CommunitySetting.web";
// import CommunityManegeRequest from "./CommunityManageRequest.web";
// import MyNetwork from "../../invitefriends/src/network/myNetwork.web";

export default class HomeBlock extends HomeController {
  // constructor(props: Props) {
  //   super(props);
  //   // Customizable Area Start
  //   const {} = this.state;
  //   // Customizable Area End
  // }
  // Customizable Area Start
  // Customizable Area End
  render() {
    const { navigation } = this.props;
    return (
      <div style={{ backgroundColor: "#f6f6f6" }}>
        {this.redirectUser()}
        {/* <CustomHeader
          dotVisible={true}
          // user={this.state}
          // tabIndex={"1"}
          // onTabChange={id => this.handleChange(id)}
          // navigation={undefined}
          // history={this.props.history}
          {...this.props}
        // isDropdownVisible={this.state.isDropdownVisible}
        // handleMenuClick={this.handleMenuClick}
        // setState={this.setState}
        // visible={false}
        // // id={undefined}
        /> */}
        <UserFeedBlock {...this.props} />
        {/* {this.state.tabIndex == "1" ? <UserFeedBlock {...this.props} /> : null}
        {this.state.tabIndex == "2" ? <MyNetwork {...this.props} /> : null}
        {this.state.tabIndex == "3" ? <CommunityBlock {...this.props} /> : null}
        {this.state.tabIndex == "4" ? (
          <CommunityManegeRequest {...this.props} />
        ) : null} */}
      </div>
    );
  }
}
// Customizable Area Start

// Customizable Area End
