import React from 'react';
import { Modal, Row, Col, Button, Typography } from "antd";

const BlockUnblockAlert = (props: any) => {
    const { visible, handleUnblock, handleCancel, fullName, accountId, blocked } = props;
    return (
        <Modal
            width={350}
            className="Block_modal"
            style={{ borderWidth: "0px", borderRadius: "4px", overflow: "hidden" }}
            centered
            title={blocked ? "Unblock Confirmation" : "Block Confirmation"}
            visible={visible}
            onOk={() => {
                handleUnblock(accountId);
            }}
            onCancel={handleCancel}
            footer={[
                <Row>
                    <Col span={12}>
                        <Button
                            size="large"
                            className="report-button"
                            key="report"
                            onClick={handleCancel}
                        >
                            {blocked ? "No, Don't unblock" : "No, Don't block"}
                        </Button>
                    </Col>
                    <Col span={12}>
                        <Button
                            size="large"
                            className="follow-label"
                            style={{ borderWidth: "0px" }}
                            key="report"
                            onClick={() => {
                                handleUnblock(accountId);
                            }}
                        >

                            {blocked ? "Yes, unblock" : "Yes, block"}
                        </Button>
                    </Col>
                </Row>
            ]}
        >
            <Typography.Text>Are you sure want to {blocked ? "unblock" : "block"} {fullName}?</Typography.Text>
        </Modal>
    )
}

export default BlockUnblockAlert;