import React from "react";
import "./../userFeed.css";
import "./myprofilestyling.css";
import {
    Typography,
    CircularProgress,
    Box,
} from "@material-ui/core";

export default function ProfileRatingViewsPercentage(props: any) {

    const { title, value, subTitle, color, classes } = props;

    return (
        <React.Fragment>
            <p style={{ textAlign: 'start', fontSize: 14, fontWeight: 'bold' }}>{title}</p>
            <div className="rating_view">
                <Box position="relative" display="inline-flex">
                    <CircularProgress
                        variant="determinate"
                        value={value}
                        // color={'#0076de'}
                        size={120}
                        thickness={3}
                        style={{
                            color: color,
                            zIndex: 1,
                            transform: "rotate(90deg)"
                        }}
                    // className={classes.bottom}
                    />
                    <Box
                        top={0}
                        left={0}
                        bottom={0}
                        right={0}
                        position="absolute"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        style={{
                            background: '#e9e9e9',
                            borderRadius: '50%',
                            textAlign: 'center'
                        }}
                    >
                        <Typography
                            variant="caption"
                            component="div"
                        // color="textSecondary"
                        >
                            <span style={{ display: 'block', fontSize: "10px" }}>{subTitle.split(' ', 1)}</span>
                            <span style={{ display: 'block', fontSize: "10px" }}>View</span>
                            <p style={{ textAlign: "center", fontSize: "18px", marginBottom: "0", fontWeight: 'bold' }}>{value}%</p>
                        </Typography>
                    </Box>
                </Box>
            </div>

        </React.Fragment>
    )

}
// Customizable Area Start
// Customizable Area End
