import React from "react";
import { imgPasswordInVisible, workStation, DeleteImgPng, settingIcon, blockIcon, eyehiddenIcon } from "./assets";
import "./userFeed.css";
import {
  Modal,
  Row,
  Col,
  Switch,
  Input,
  Typography,
  Avatar,
  Button,
  Upload,
  Card,
  List,
  Menu,
  Radio,
  Breadcrumb
} from "antd";
import CommunityController, { Props } from "./CommunityController.web";
import {
  CheckCircleFilled,
} from "@ant-design/icons";
const { Title, Text } = Typography;
const { TextArea } = Input;
const postData = [
  //   {
  //     key: "posts",
  //     count: "500",
  //     image: imgPasswordInVisible,
  //     description: "UI/Ux Designer Community",
  //   },
  {
    key: "connection",
    count: "500",
    image: imgPasswordInVisible,
    description: "UI/Ux Designer Community"
  },
  {
    key: "followers",
    count: "2k",
    image: imgPasswordInVisible,
    description: "UI/Ux Designer Community"
  },
  {
    key: "following",
    count: "1100",
    image: imgPasswordInVisible,
    description: "UI/Ux Designer Community"
  }
];
// const settingList = [
//   { key: 0, label: "Manage Requests", icon: "" },
//   { key: 1, label: "Blocked Accounts", icon: "" },
//   { key: 2, label: "Hidden Posts", icon: "" },
//   { key: 3, label: "Delete Community", icon: "" }
// ];
export default class CommunitySetting extends CommunityController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    const { } = this.state;
    // Customizable Area End
  }
  // Customizable Area Start
  renderSettingList = (item: any) => {
    const menuList = [
      {
        id: 1,
        icon: settingIcon,
        label: "Manage Requests",
      },
      {
        id: 2,
        icon: blockIcon,
        label: "Blocked Accounts",
      },
      {
        id: 3,
        icon: eyehiddenIcon,
        label: "Hidden Posts",
      },
      {
        id: 4,
        icon: DeleteImgPng,
        label: "Delete Community",
      }
    ]
    return (
      <Menu
        theme="light"
        style={{
          width: "100%",
          color: "rgba(151, 151, 151, 1)",
          borderRadius: "5px",
          overflow: "hidden"
        }}
        mode="inline"
      >
        {
          menuList.map((item: any, id: number) => {
            return (
              <Menu.Item
                key={item.id}
                className="customclass"
                style={{
                  width: "100%",
                  height: "3rem",
                  borderBottom: id + 1 < menuList?.length ? "1px solid #ededed" : "none",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center"
                }}
                onClick={() => this.setState({ selectedSettingId: item.key })}
              >
                <img src={item.icon} style={{ width: "20px", height: '20px', marginRight: "10px" }} />
                <span style={{ fontWeight: 500, fontSize: 15 }}>{item.label}</span>
              </Menu.Item>
            )
          })
        }
      </Menu>
    );
  };
  renderListWithLabel = (
    label: string,
    data: any[],
    isButton: boolean,
    description: boolean
  ) => {
    return (
      <>
        <Row
          className="ant-row-middle"
          style={{ justifyContent: "space-between" }}
        >
          <Col>
            <Text style={{ fontSize: "16px", fontWeight: "bold" }}>
              {label}
            </Text>
          </Col>
          {isButton && (
            <Col>
              <Button
                type="default"
                style={{ borderColor: "#005574", width: "80px" }}
                onClick={() => { }}
                shape="round"
                size="small"
              >
                <Text className="follow-label">Save</Text>
              </Button>
            </Col>
          )}
        </Row>

        <List
          style={{
            backgroundColor: "white",
            borderRadius: 4,
            marginTop: 10
          }}
          dataSource={data}
          renderItem={(item: any) => (
            <List.Item onClick={() => { }} style={{ paddingRight: "16px" }}>
              <List.Item.Meta
                avatar={
                  <Avatar
                    src={workStation}
                    size={40}
                    style={{
                      marginLeft: "16px",
                      backgroundColor: "lightgrey"
                    }}
                  />
                }
                title={
                  <Title
                    level={4}
                    className="margin-top5"
                    style={{ marginRight: "5px" }}
                  >
                    {item.key}
                  </Title>
                }
                description={
                  description && (
                    <p className="margin-top-10">{item.description}</p>
                  )
                }
                style={{ alignItems: "center" }}
              />
              {this.state.selectedSettingId == 0 && (
                <Switch defaultChecked onChange={() => { }} />
              )}
              {this.state.selectedSettingId == 1 && (
                <Button
                  type="default"
                  style={{ borderColor: "#005574", width: "100px" }}
                  onClick={() => {
                    this.setState({ isUnblockVisible: true });
                  }}
                  shape="round"
                >
                  <Text className="follow-label">Unblock</Text>
                </Button>
              )}
              {this.state.selectedSettingId == 2 && (
                <Button
                  type="default"
                  style={{ borderColor: "#005574" }}
                  onClick={() => {
                    this.setState({ isUnblockVisible: true });
                  }}
                  shape="round"
                >
                  <Text className="follow-label">Add to Community</Text>
                </Button>
              )}
            </List.Item>
          )}
        />
      </>
    );
  };
  handleOk = (e: any) => {
    console.log(e);
    this.setState({ isUnblockVisible: false });
  };

  handleCancel = (e: any) => {
    console.log(e);
    this.setState({ isUnblockVisible: false });
  };
  renderUnBlockModal = (item: any) => {
    return (
      <Modal
        width={320}
        style={{ borderWidth: "0px", borderRadius: "4px", overflow: "hidden" }}
        centered
        // placement="bottomRight"
        title={
          <div
            style={{
              width: "100%",
              cursor: "move",
              fontWeight: "bold",
              borderWidth: "0px"
            }}
            onFocus={() => { }}
            onBlur={() => { }}
          // end
          >
            Unblock Confirmation
          </div>
        }
        visible={this.state.isUnblockVisible}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        footer={[
          <Row>
            <Col span={12}>
              <Button
                size="large"
                className="report-button"
                key="report"
                onClick={this.handleOk}
              >
                No,Don't unblock
              </Button>
            </Col>
            <Col span={12}>
              <Button
                size="large"
                className="follow-label"
                style={{ borderWidth: "0px" }}
                key="report"
                onClick={this.handleOk}
              >
                Yes, unblock
              </Button>
            </Col>
          </Row>
        ]}
      >
        <Text>{`Are you sure want to unblock ${item.key}?`}</Text>
      </Modal>
    );
  };

  renderDeleteCommunity = () => {
    let checked = "a";
    return (
      <div style={{}}>
        <Row
          className="ant-row-middle"
          style={{ justifyContent: "space-between" }}
        >
          <Col>
            <Text style={{ fontSize: "16px", fontWeight: "bold" }}>
              {"Delete Community"}
            </Text>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Card>
              <Radio.Group defaultValue="a">
                <Radio.Button
                  value="a"
                  style={{ color: "#000", borderWidth: "0px" }}
                  onClick={() => { }}
                >
                  {checked == "a" ? (
                    <CheckCircleFilled
                      translate={{}}
                      style={{
                        fontSize: "20px",
                        marginRight: "10px",
                        color: "#52c41a"
                      }}
                    />
                  ) : (
                    <Radio />
                  )}
                  No, Don't Delete
                </Radio.Button>
                <Radio.Button
                  type=""
                  value="b"
                  style={{ color: "#000", borderWidth: "0px", width: "300px" }}
                  onClick={() => { }}
                >
                  {checked == "b" ? (
                    <CheckCircleFilled
                      translate={{}}
                      style={{
                        fontSize: "20px",
                        marginRight: "10px",
                        color: "#52c41a"
                      }}
                    />
                  ) : (
                    <Radio value="a" />
                  )}
                  Yes, Delete Community
                </Radio.Button>
              </Radio.Group>
              <Row style={{ padding: "16px" }}>
                <Button
                  style={{
                    width: "40%",
                    backgroundColor: "#005574",
                    color: "#fff"
                  }}
                  size="large"
                  shape="round"
                >
                  Delete Community
                </Button>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    );
  };
  // Customizable Area End
  render() {
    const { navigation } = this.props;
    return (
      <div
        className="background"
        style={{ backgroundColor: "#f6f6f6", padding: "20px" }}
      >
        <Breadcrumb style={{}}>
          <Breadcrumb.Item>Community</Breadcrumb.Item>
          <Breadcrumb.Item>Game Development</Breadcrumb.Item>
          <Breadcrumb.Item className="follow-label">
            Community Setting
          </Breadcrumb.Item>
        </Breadcrumb>
        <Row>
          <Col span={6}>
            {/* <List
              style={{
                backgroundColor: "white",
                borderRadius: 4,
                marginTop: "10px"
              }}
              dataSource={settingList}
              renderItem={this.renderSettingList}
            /> */}
            {this.renderSettingList}
          </Col>
          {/* <Col span={14} style={{ paddingLeft: "20px", marginTop: "5px" }}>
            {this.state.selectedSettingId == 0 &&
              this.renderListWithLabel("Manage Requests", postData, true, true)}
            {this.state.selectedSettingId == 1 &&
              this.renderListWithLabel(
                "Blocked Accounts",
                postData,
                false,
                false
              )}
            {this.state.selectedSettingId == 2 &&
              this.renderListWithLabel("Hidden Posts", postData, false, false)}
            {this.state.selectedSettingId == 3 && this.renderDeleteCommunity()}
            {this.renderUnBlockModal(postData[0])}
          </Col> */}
        </Row>
      </div>
    );
  }
}
// Customizable Area Start

// Customizable Area End
