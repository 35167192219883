import React, { Component } from 'react';
import { runEngine } from '../../../../framework/src/RunEngine';
import { Message } from '../../../../framework/src/Message';
import MessageEnum, {
  getName,
} from '../../../../framework/src/Messages/MessageEnum';
import { BlockComponent } from '../../../../framework/src/BlockComponent';
import { IBlock } from '../../../../framework/src/IBlock';
import confirm from '../../../alert/src/alert.web';
import AlertError from '../../../alert/src/alertError.web';
import moment from 'moment';
import { RouterProps } from 'react-router';
import { Redirect } from "react-router-dom";
import { FormInstance } from 'antd/lib/form';
export const configJSON = require('../config');
import { Select } from 'antd';
const { Option } = Select;
export interface Props extends RouterProps {
  navigation: any;
  id: string;
}
interface S {
  loading: boolean;
  isRegistration: boolean;
  bussiness_profile: boolean;
  prev_data: any;
  pre_data: any;
  data: [AllData];
  // pre_data: any;
  token: string | null;
  isModalVisible: any;
  modalIndex: number;
  modalString: string;
  finalData: any;
  redirect: boolean;
  experienceStartDate: string;
}
interface AllData {
  company: string;
  start_date: any;
  end_date: any;
  title: string;
  description: string;
}
interface SS {
  id: any;
}

export default class EditPersonalExperienceController extends BlockComponent<
  Props,
  S,
  SS
> {
  formref = React.createRef<FormInstance>();
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.receive = this.receive.bind(this);
    this.state = {
      bussiness_profile: true,
      loading: true,
      isRegistration: true,
      finalData: {},
      pre_data: {},
      prev_data: {},
      data: [
        {
          company: '',
          title: '',
          start_date: '',
          end_date: '',
          description: '',
        },
      ],
      token: localStorage.getItem('token') ? localStorage.getItem('token') : '',
      isModalVisible: false,
      modalIndex: 0,
      modalString: '',
      redirect: false,
      experienceStartDate: ""
    };
  }
  async componentDidMount() {
    let token = await localStorage.getItem("token");
    if (token && token !== null && token !== undefined) {
      this.setState({ redirect: false });
    } else {
      this.setState({ redirect: true });
    }
    let expDetails: any = this.props.history?.location?.state;
    console.log(expDetails, 'hello world')
    if (expDetails && expDetails != null) {
      let newData = Object.assign(
        {},
        { certificate: expDetails && expDetails.certificate },
        { profile: expDetails && expDetails.profile },
        { graduation: expDetails && expDetails.graduation },
        { language: expDetails && expDetails.language },
        { contact: expDetails && expDetails.contact }
      );
      this.setState({ finalData: newData });
      // if (expDetails && typeof expDetails.experience === typeof [1, 2]) {
      // console.log(expDetails.experience,'test')
      this.setState({
        data: expDetails?.experience
      });
      expDetails?.experience?.map((item: any) => {
        this.setState({ experienceStartDate: item?.start_date })
      })
      // } else {
      //   this.setState({
      //     data: [
      //       {
      //         company: '',
      //         title: '',
      //         start_date: '',
      //         end_date: '',
      //         description: '',
      //       },
      //     ],
      //   })
      // }
    }
    this.setState({ loading: false });

  }

  redirectUser() {
    if (this.state.redirect) {
      return <Redirect to="/" />;
    }
  }

  //=================== @@show modal =========================
  showModal = (index: number, data: string) => {
    this.setState({
      modalIndex: index,
      modalString: data,
      isModalVisible: true,
    });
  };

  //==================== @@@ SET LOCAL STORAGE===============
  handleLocalStorage() {
    localStorage.setItem('experienceData', JSON.stringify(this.state.data));
  }

  // =================== @@handleChange =========================
  handleInputChange = (e: any, index: any) => {
    const { name, value } = e.target;
    const list: any = [...this.state.data];
    list[index][name] = value;
    console.log('test handle change', list);
    this.setState(list);
  };
  handleStartDate = (date: any, dateString: any, index: any) => {
    const list: any = [...this.state.data];
    list[index]['start_date'] = date?._d;
    this.setState({ experienceStartDate: date?._d })
    this.setState(list);
  };
  handleEndDate = (date: any, dateString: any, index: any) => {
    const list: any = [...this.state.data];
    list[index]['end_date'] = date?._d;
    this.setState(list);
  };

  handleExperienceData = (data: any) => {
    let actual_data: any = [...this.state.data, data];
    console.log(actual_data, 'test 123');
    // let date_data: any = {
    //   start_date: moment(actual_data.start_date._d),
    // };
    // console.log(date_data.start_date, "date");
    this.setState(
      {
        data: actual_data,
      },
      () => {
        this.formref.current!.resetFields();
        // this.handleLocalStorage();
      }
    );
  };
  onSkip = () => {
    let all_data = this.state.data;
    let newData = Object.assign(
      {},
      { certificate: this.state.finalData && this.state.finalData.certificate },
      { graduation: this.state.finalData && this.state.finalData.graduation },
      { language: this.state.finalData && this.state.finalData.language },
      { contact: this.state.finalData && this.state.finalData.contact },
      { experience: this.state?.finalData.experience ? this.state.finalData.experience : all_data },
      { profile: this.state.finalData && this.state.finalData.profile }
    );

    this.props.history.replace({ state: newData });
    this.props.history.push({
      pathname: '/edit/profile/personal/generalinformation',
      state: newData,
    });
  };

  handleNext = async (data: object) => {
    console.log('@@@ business experience test====', this.state.data);
    let all_data = this.state.data;

    let newData = Object.assign(
      {},
      { profile: this.state.finalData && this.state.finalData.profile },
      { certificate: this.state.finalData && this.state.finalData.certificate },
      { graduation: this.state.finalData && this.state.finalData.graduation },
      { language: this.state.finalData && this.state.finalData.language },
      { contact: this.state.finalData && this.state.finalData.contact },
      { experience: all_data }
    );
    this.props.history.replace({ state: newData });
    this.props.history.push({
      pathname: '/edit/profile/personal/achievements',
      state: newData,
    });
  };

  // ====================@@delete all_data values=======================
  deleteData = (value: any) => {
    if (this.state.data != null) {
      const list: any = [...this.state.data];
      list.splice(value, 1);
      this.setState({ data: list }, () => this.handleLocalStorage());
      // this.setState((state) => ((state.data = list), state));
    } else {
      console.log('error while deleting');
    }
    this.setState({
      isModalVisible: false,
    });
  };
}
