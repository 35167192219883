import React, { useState, useEffect } from "react";
import axios from "axios";
import CustomHeader from "../../CommunityForum/src/CustomHeader.web";
import { Link, Redirect } from "react-router-dom";
import {
  Badge,
  Row,
  Empty,
  Avatar,
  List,
  Divider
} from "antd";
import moment from "moment";
// @ts-ignore
import { baseURL } from '../../../framework/src/config.js';

const NotificationWeb = (props: any) => {
  // Customizable Area Start
  const [state, setState] = useState();
  const [redirect, setRedirect] = useState<boolean>(false);
  const [allNotifications, setNotifications] = useState<any>([]);
  const [readNotifications, setReadNotifications] = useState<any>([]);
  const [loadingNotification, setLoadingNotification] = useState<boolean>(false);
  const [isNotificationRead, setNotificationsRead] = useState<any>({ dataNotifications: [] });

  const getAllNotifications = async () => {

    try {
      const response = await axios({
        url:
          `${baseURL}/bx_block_notifications/notifications?today_page=1&today_per_page=50&older_page=1&older_per_page=50`,
        method: "get",
        headers: {
          token: await localStorage.getItem('token'),
          "Content-Type": "application/json"
        }
      });
      if (response?.data) {
        let totalNotifications: any = [];
        let totalNotificationsRead: any = [];
        response.data?.today_notifications?.data?.map((c: any) => {
          totalNotifications?.push(c);
          if (c?.attributes?.is_read) {
            totalNotificationsRead?.push(c?.id)
          }
        });
        response.data?.yesterday_notifications?.data?.map((c: any) => {
          totalNotifications?.push(c);
          if (c?.attributes?.is_read) {
            totalNotificationsRead?.push(c?.id)
          }
        });
        response.data?.older_notifications?.data?.map((c: any) => {
          totalNotifications?.push(c);
          if (c?.attributes?.is_read) {
            totalNotificationsRead?.push(c?.id)
          }
        });
        // console.log(count,'****count')
        // console.log(totalNotifications, '***total Notifications')
        if (totalNotifications)
          setNotifications(totalNotifications);
        setReadNotifications(totalNotificationsRead);
      }
      //   console.log(response.data, "all notification results");
    } catch (error) {
      console.error(error, 'error while calling notification api');
    }
  };

  useEffect(() => {
    let token = localStorage.getItem("token");
    if (token && token !== null && token !== undefined) {
      setRedirect(false);
    } else {
      setRedirect(true);
    }

    getAllNotifications();

    return () => {
      getAllNotifications();
    };
  }, []);

  const redirectUser = () => {
    if (redirect) {
      return <Redirect to="/" />;
    }
  };

  const updateNotification = async (id: any) => {
    if (!readNotifications.includes(id)) {
      readNotifications.push(id);
      setLoadingNotification(true);
      try {
        const response = await axios({
          url:
            `${baseURL}/bx_block_notifications/notifications/${id}`,
          method: "put",
          headers: {
            token: await localStorage.getItem('token'),
            "Content-Type": "application/json"
          }
        });
        console.log(response, "read notification");
        setLoadingNotification(false);
        setNotificationsRead({ data: response?.data });
      } catch (error) {
        console.error(error, 'error while calling api');
        setLoadingNotification(false);
      }
    }
  }

  return (
    <div
      className="searchStyles"
      style={{ background: "#f6f6f6", minHeight: "100vh" }}
    >
      <CustomHeader {...props} dotVisible={false} />
      {/* {CustomHeader(isNotificationRead)} */}
      {redirectUser()}
      <div style={{ padding: '1rem' }}>
        <h2>Notifications</h2>
        <Row>
          {allNotifications ? (
            <List
              style={{
                backgroundColor: "white",
                borderRadius: 4,
                marginTop: 10,
                width: "100%",

              }}
              dataSource={allNotifications.sort((a: any, b: any) => b.attributes?.updated_at?.localeCompare(a.attributes?.updated_at))}
              renderItem={(item: any) => {
                console.log(item, '___data')
                return (
                  <List.Item
                    onClick={() => updateNotification(item.id)}
                    style={{ width: "100%" }}
                  >
                    <List.Item.Meta
                      avatar={
                        <Avatar
                          src={item?.attributes?.photo}
                          size={40}
                          style={{
                            marginLeft: "16px",
                            backgroundColor: "lightgrey"
                          }}
                        />
                      }
                      title={item?.attributes?.headings}
                      description={
                        moment(item?.attributes?.created_at).from(Date.now())
                      }
                      style={{ alignItems: "center", width: "100%" }}
                    />
                    <div>
                      {console.log("asdaadljalsja", item)}
                      <Badge
                        dot={readNotifications.includes(item.id) ? false : true}
                        color={readNotifications.includes(item.id) ? '#fff' : '#2db7f5'}
                      />
                    </div>
                  </List.Item>
                );
              }}
            />
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%"
              }}
            >
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </div>
          )}
        </Row>
      </div>
    </div>
  );
  // Customizable Area End
};

export default NotificationWeb;
