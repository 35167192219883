Object.defineProperty(exports, "__esModule", {
  value: true,
});
exports.createAccountAPiEndPoint = "bx_block_signup/signups";
exports.matchEmailOTPApiEndPoint = "bx_block_login/otp_confirmations";

exports.apiResendOtpContentType = "application/json";
exports.apiResendOtpEndPoint = "bx_block_signup/otpresends";
exports.apiResendOtpMethod = "POST";
// Customizable Area Start
// Customizable Area End
