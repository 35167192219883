import React, { Component } from 'react';
// Customizable Area Start
import {
  PageHeader,
  Card,
  Row,
  Col,
  Form,
  Input,
  Typography,
  Button,
  Select,
} from 'antd';
import { logo } from '../assets';
const style = require(`../../../social-media-account/src/login.module.css`);
import CreateProfileOverviewController from '../ProfileSetupOverviewController.web';
import '../profile.css';
const { Title, Text, Link } = Typography;
// Customizable Area End
export default class ProfileSetupBasicBusinessOverview extends CreateProfileOverviewController {
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    return (
      <div>
        <PageHeader
          className='site-page-header'
          title={
            <span style={{ color: 'white' }}>
              <img style={{ height: '32px', marginRight: '8px' }} src={logo} />
              applied
            </span>
          }
          style={{
            backgroundImage: 'linear-gradient(#005574, #7aacd6)',
            color: 'white',
          }}
        />
        <Row
          style={{ minHeight: '90vh', alignItems: 'center', marginTop: '60px' }}
        >
          <Col xs={0} sm={0} md={3} lg={7} xl={7} />
          <Col xs={24} sm={24} md={18} lg={10} xl={10}>
            <Title
              level={2}
              style={{ textAlign: 'center', marginBottom: '15px' }}
            >
              Setup Your Basic Profile
            </Title>
            <Form
              initialValues={{
                company_overview: '',
                company_mission: '',
                company_vision: '',
                company_core_values: '',
                health_safety_environment: '',
                products_services: '',
                contact_phone: '',
                contact_email: '',
              }}
              onFinish={this.handleAddProfileOverviewData}
            >
              <Card>
                <Form.Item
                  rules={[{ required: true, message: 'Required field' }]}
                  name='company_overview'
                >
                  <Input.TextArea
                    autoSize={{ minRows: 4, maxRows: 4 }}
                    className={style.input_fields}
                    name='company_overview'
                    placeholder='Company Overview'
                    style={{ color: "#a9bac8" }}
                  />
                </Form.Item>
                <Form.Item
                  rules={[{ required: true, message: 'Required field' }]}
                  name='company_mission'
                >
                  <Input.TextArea
                    autoSize={{ minRows: 4, maxRows: 4 }}
                    className={style.input_fields}
                    name='company_mission'
                    placeholder='Company Mission'
                    style={{ color: "#a9bac8" }}
                  />
                </Form.Item>
                <Form.Item
                  rules={[{ required: true, message: 'Required field' }]}
                  name='company_vision'
                >
                  <Input.TextArea
                    autoSize={{ minRows: 4, maxRows: 4 }}
                    className={style.input_fields}
                    name='company_vision'
                    placeholder='Company Vision'
                    style={{ color: "#a9bac8" }}
                  />
                </Form.Item>
                <Form.Item
                  rules={[{ required: true, message: 'Required field' }]}
                  name='company_core_values'
                >
                  <Input.TextArea
                    autoSize={{ minRows: 4, maxRows: 4 }}
                    className={style.input_fields}
                    name='company_core_values'
                    placeholder='Company Core Values'
                    style={{ color: "#a9bac8" }}
                  />
                </Form.Item>
                <Form.Item
                  rules={[{ required: true, message: 'Required field' }]}
                  name='health_safety_environment'
                >
                  <Input.TextArea
                    autoSize={{ minRows: 4, maxRows: 4 }}
                    className={style.input_fields}
                    name='health_safety_environment'
                    placeholder='Health,Safety & Environment'
                    style={{ color: "#a9bac8" }}
                  />
                </Form.Item>
                <Form.Item
                  rules={[{ required: true, message: 'Required field' }]}
                  name='products_services'
                >
                  <Input.TextArea
                    autoSize={{ minRows: 4, maxRows: 4 }}
                    className={style.input_fields}
                    name='products_services'
                    placeholder='Product & Services'
                    style={{ color: "#a9bac8" }}
                  />
                </Form.Item>
              </Card>
              <div style={{ backgroundColor: '#e0eaf4', height: '35px' }}>
                <Title
                  level={4}
                  style={{
                    marginLeft: '15px',
                    color: 'steelblue',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  Contact Details
                </Title>
              </div>
              <Card>
                <Form.Item
                  rules={[{ required: true, message: 'Required field' }]}
                  name='contact_phone'
                >
                  <Input
                    className={style.input_fields}
                    name='contact_phone'
                    placeholder='Telephone'
                    style={{ color: "#a9bac8" }}
                    size="large"
                  />
                </Form.Item>
                <Form.Item
                  rules={[{ required: true, message: 'Required field' }]}
                  name='contact_email'
                >
                  <Input
                    className={style.input_fields}
                    name='contact_email'
                    placeholder='Email'
                    type={"email"}
                    style={{ color: "#a9bac8" }}
                    size="large"
                  />
                </Form.Item>
              </Card>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  padding: '15px',
                  background: 'whitesmoke',
                }}
              >
                <Button
                  type='primary'
                  shape='round'
                  size={'large'}
                  style={{
                    width: '200px',
                    backgroundColor: 'white',
                    borderColor: '#d9d9d9',
                  }}
                >
                  <Text type='secondary'>Previous</Text>
                </Button>
                <Button
                  className={style.btn_colors}
                  type='primary'
                  htmlType='submit'
                  shape='round'
                  size={'large'}
                  style={{ width: '200px' }}
                >
                  Next
                </Button>
              </div>
            </Form>
          </Col>
          <Col xs={0} sm={0} md={3} lg={7} xl={7} />
        </Row>
      </div>
    );
    // Customizable Area End
  }
}
// Customizable Area Start
// Customizable Area End