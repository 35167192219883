import React from "react";
import { imgPasswordInVisible } from "./assets";
import "./communityManage.css";
import {
  Row,
  Col,
  Modal,
  Form,
  Input,
  Typography,
  List,
  Tabs,
  Breadcrumb,
  Button, Avatar,
  Empty,
  message,
  Image,
  Card
} from "antd";
// import { HashRouter as Router,  Link } from 'react-router-dom';
import CommunityController, { Props } from "./CommunityController.web";
import moment from "moment";
import ReactPlayer from "react-player";
const { Text } = Typography;
const { TabPane } = Tabs;

export default class CommunityManegeRequest extends CommunityController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    const { } = this.state;
    // Customizable Area End
  }
  // Customizable Area Start

  async componentDidMount() {
    let communityInfo: any = this.props?.history?.location?.state;
    // console.log(communityId, 'communityId***');
    this.setState(({ communityData: communityInfo?.id }));
    // console.log(communityInfo);

    if (communityInfo) {
      await this.getPostReq(communityInfo?.id?.id);
      await this.getJoiningReq(communityInfo?.id?.id);
    }
  }

  comingSoonInfo = () => {
    message.info('Coming soon');
  };

  getDifference(created_at: any) {
    let difference = 0;
    let differenceStr = "";
    const dateForDifference = moment(created_at);
    difference = moment().diff(dateForDifference, "days");
    console.log("today = ", moment().diff(dateForDifference, "days"));
    if (difference === 0) {
      let hours = moment().diff(dateForDifference, "hours");
      if (hours === 0) {
        let minutes = moment().diff(dateForDifference, "minutes");
        differenceStr = minutes + " min ago";
      } else differenceStr = hours + " hr ago";
    } else {
      if (difference === 1) differenceStr = "1 day ago";
      else differenceStr = difference + " days ago";
    }

    return <span style={{ color: 'gray' }}>{differenceStr}</span>;
  }

  callback = (key: any) => {
    console.log(key);
  }
  renderImage = (id: any, isFullHeight: boolean) => {
    return (
      <Image
        src={this.state.postModalData?.attributes?.media[id]?.url}
        alt="post_img"
        width={"100%"}
        height={"100%"}
        style={{
          objectFit: "fill",
          borderRadius: "3px"
        }}
      />
    )
  }
  renderVideo = (id: any, isFullHeight: boolean) => {
    return (
      <ReactPlayer
        url={this.state.postModalData?.attributes?.media[id]?.url}
        controls
        config={{ file: { attributes: { controlsList: 'nodownload', disablePictureInPicture: true, playbackRate: false } } }}
        onContextMenu={(e: any) => e.preventDefault()}
        width={"100%"}
        height={"100%"}
        style={{
          objectFit: "fill",
          borderRadius: "3px"
        }}
      />
    )
  }
  checkMedia = (id: any, isFullHeight: boolean) => {
    return this.state.postModalData?.attributes?.media[id]?.content_type?.slice(0, 5) == "video" ?
      this.renderVideo(id, isFullHeight) :
      this.renderImage(id, isFullHeight)
  }
  renderMedia = (imgArray: any) => {
    switch (imgArray.length) {
      case 0:
        return null;
      case 1:
        return (
          <Row gutter={[2, 2]}>
            <Col span={24}>
              {this.checkMedia(0, true)}
            </Col>
          </Row>
        )
      case 2:
        return (
          <Row gutter={[2, 2]}>
            <Col span={12} >{this.checkMedia(0, true)}</Col>
            <Col span={12} >{this.checkMedia(1, true)}</Col>
          </Row>
        )
      case 3:
        return (
          <Row gutter={[2, 2]}>
            <Col span={12}>
              {this.checkMedia(0, true)}
            </Col>
            <Col span={12}>
              <Row gutter={[2, 2]}>
                <Col span={24}>
                  {this.checkMedia(1, false)}
                </Col>
                <Col span={24}>
                  {this.checkMedia(2, false)}
                </Col>
              </Row>
            </Col>
          </Row>
        )
      case 4:
        return (
          <Row gutter={[2, 2]}>
            <Col span={12} >
              {this.checkMedia(0, false)}
            </Col>
            <Col span={12} >
              {this.checkMedia(1, false)}
            </Col>
            <Col span={12} >
              {this.checkMedia(2, false)}
            </Col>
            <Col span={12} >
              {this.checkMedia(3, false)}
            </Col>
          </Row>
        )
      default:
        return (
          <Row gutter={[2, 2]}>
            <Col span={12} >
              {this.checkMedia(0, false)}
            </Col>
            <Col span={12} >
              {this.checkMedia(1, false)}
            </Col>
            <Col span={12} >
              {this.checkMedia(2, false)}
            </Col>
            <Col span={12}  >
              <Button
                size='large'
                type='text'
                style={{
                  backgroundImage: "linear-gradient(#005574, #7aacd6)",
                  height: 200,
                  width: '100%',
                  fontWeight: 'bold',
                  fontSize: '24px'
                }} >
                {`+${imgArray?.length - 3} More`} </Button>
            </Col>
          </Row>
        )
    }
  };

  renderProfileUI = () => {
    return (
      <div>
        <p>
          {this.state.postModalData?.attributes?.account?.data?.attributes?.role === "personal" ?
            <Text style={{ fontSize: 14, fontWeight: 500, color: "#141414" }}>
              {
                this.state.postModalData?.attributes?.account?.data?.attributes?.profile?.data?.attributes?.designation?.title
              }
              {
                this.state.postModalData?.attributes?.account?.data?.attributes?.profile?.data?.attributes?.designation?.company ?
                  <span><span style={{ color: "rgb(201, 200, 205)" }}> at </span>{this.state.postModalData.attributes.account.data.attributes.profile.data.attributes.designation.company}</span>
                  : null
              }
              {
                this.state.postModalData?.attributes?.account?.data?.attributes?.profile?.data?.attributes?.designation?.title ?
                  <Text style={{ color: "rgb(201, 200, 205)" }}> | </Text> : null
              }
              {
                this.state.postModalData?.attributes?.account?.data?.attributes?.profile?.data?.attributes?.domain_skills?.length ?
                  <Text style={{ color: "rgba(0, 0, 0, 0.85)", fontWeight: 400 }}>
                    {" "}
                    {this.getDomainSkills(this.state.postModalData?.attributes?.account?.data?.attributes?.profile?.data?.attributes?.domain_skills)}
                  </Text> : null
              }

            </Text> : null
          }
          {
            this.state.postModalData?.attributes?.account?.data?.attributes?.role === "business" ?
              <Text>
                {
                  this.state.postModalData?.attributes?.account?.data?.attributes?.profile?.data?.attributes?.business ?
                    this.state.postModalData?.attributes?.account?.data?.attributes?.profile?.data?.attributes?.business : null
                }
                {
                  this.state.postModalData?.attributes?.account?.data?.attributes?.profile?.data?.attributes?.business && this.state.postModalData?.attributes?.account?.data?.attributes?.profile?.data?.attributes?.business_industry ? ", " : null
                }
                {
                  this.state.postModalData?.attributes?.account?.data?.attributes?.profile?.data?.attributes?.business_industry ?
                    this.state.postModalData?.attributes?.account?.data?.attributes?.profile?.data?.attributes?.business_industry : null
                }

              </Text>
              : null
          }
        </p>
      </div>
    )
  }

  renderTagsUI = () => {
    return (
      <Col span={24}>
        {
          this.state.postModalData?.attributes?.taggings?.data.length > 0 ?
            <>
              {
                true ?
                  <Text
                    style={{
                      color: "rgb(0,0,0,0.25)"
                    }}
                  >
                    Tagged : {" "}
                    {
                      this.state.postModalData?.attributes?.taggings?.data.map((i: any, index: number) => {
                        return <Text
                          style={{
                            color: '#5C85B2',
                            fontSize: 14,
                            fontWeight: 500,
                            cursor: 'move'
                          }}
                        >
                          {i?.attributes?.first_name}
                          {this.state.postModalData?.attributes?.taggings?.data?.length !== index + 1 ? ", " : null}
                        </Text>
                      })
                    }
                  </Text>
                  :
                  <Text style={{ color: "rgb(0,0,0,0.25)" }}>
                    Tagged : {" "}
                    {
                      this.state.postModalData?.attributes?.taggings?.data?.slice(0, 2)?.map((i: any, index: number) => {
                        return (
                          <Text
                            style={{
                              color: '#5C85B2',
                              fontSize: 14,
                              fontWeight: 500,
                              cursor: 'move'
                            }}
                          >
                            {i?.attributes?.first_name}
                            {this.state.postModalData?.attributes?.taggings?.data?.length > 1 ? ", " : null}
                          </Text>
                        )
                      })
                    }
                    {
                      this.state.postModalData?.attributes?.taggings?.data.length > 2 ?
                        <Text
                          // onClick={() => setShowTag(true)}
                          style={{
                            cursor: 'pointer',
                            color: '#5C85B2'
                          }}
                        >
                          {`and ${this.state.postModalData?.attributes?.taggings?.data.length - 2} others`}
                        </Text> : null
                    }
                  </Text>
              }
            </>
            : null
        }
      </Col>
    )
  }

  viewPostModal = () => {
    return (
      <Modal
        width={550}
        style={{ borderWidth: "0px", borderRadius: "4px", overflow: "hidden" }}
        centered
        className="Logout_Popup"
        // placement="bottomRight"
        title={
          <div
            style={{
              width: "100%",
              cursor: "move",
              fontWeight: "bold",
              borderWidth: "0px"
            }}
            onFocus={() => { }}
            onBlur={() => { }}
          // end
          >
            View Post
          </div>
        }
        visible={this.state.isviewPostVisible}
        // onOk={() => this.setState({ isviewPostVisible: false })}
        onCancel={() => this.setState({ isviewPostVisible: false })}
        footer={[
          <Row>
            <Col
              span={24}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-end",
                gap: "10px"
              }}
            >
              <Button
                size="large"
                shape="round"
                className="settings-button"
                key="report"
                onClick={() => {
                  this.setState({ isviewPostVisible: false });
                  this.acceptPost(this.state.postModalData?.id);
                }}
              >
                Accept
              </Button>
              <Button
                size="large"
                className="settings-button"
                shape="round"
                key="report"
                onClick={() => {
                  this.setState({ isviewPostVisible: false, isRejectPostVisible: true })
                }}
              >
                Reject
              </Button>
            </Col>
          </Row>
        ]}
      >
        <div>
          <Row gutter={[20, 3]}>
            <Col span={24}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  justifyContent: "space-between",
                  gap: "20px"
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    gap: "5px"
                  }}
                >
                  <div
                    style={{
                      cursor: "move"
                    }}
                  >
                    <Avatar
                      size={40}
                      icon={<img src={this.state.postModalData?.attributes?.account?.data?.attributes?.photo} width="100%" />}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      gap: "0px",
                      marginTop: "-5px"
                    }}
                  >
                    <div
                      style={{
                        cursor: "move",
                        fontSize: "16px",
                        fontWeight: 500
                      }}
                    >
                      {this.state.postModalData?.attributes?.account?.data?.attributes?.role === 'business' ?
                        this.state.postModalData?.attributes?.account?.data?.attributes?.profile?.data?.attributes?.company_name
                        : this.state.postModalData?.attributes?.account?.data?.attributes?.full_name}
                    </div>
                    {this.renderProfileUI()}
                  </div>
                </div>
              </div>
            </Col>
            <Col span={24}>
              <Text
                style={{
                  color: "#8c8c8c",
                  whiteSpace: "pre-wrap"
                }}
              >
                {this.state.postModalData?.attributes?.body}
              </Text>
            </Col>
            <Col span={24}>
              {
                this.state.postModalData?.attributes?.location ?
                  <Text
                    style={{
                      color: "rgb(0,0,0,0.25)"
                    }}
                  >
                    Location : {this.state.postModalData?.attributes?.location}
                  </Text> : null
              }
            </Col>
            {this.renderTagsUI()}
            {
              this.state.postModalData?.attributes?.media?.length ?
                <Col span={24} style={{ marginTop: "5px" }}>
                  {this.renderMedia(this.state.postModalData?.attributes?.media)}
                </Col>
                : null
            }
          </Row>
          {/* <List.Item>
            <List.Item.Meta
              avatar={<Avatar src={this.state.postModalData?.attributes?.account?.data?.attributes?.photo} />}
              title={
                <div>{this.state.postModalData?.attributes?.account?.data?.attributes?.full_name}
                </div>
              }
              description={
                this.state.postModalData?.attributes?.account?.data?.attributes?.profile?.data?.attributes?.domain_skills ?
                  this.getDomainSkills(this.state.postModalData?.attributes?.account?.data?.attributes?.profile?.data?.attributes?.domain_skills)
                  : `${this.state.postModalData?.attributes?.account?.data?.attributes?.profile?.data?.attributes?.business}, ${this.state.postModalData?.attributes?.account?.data?.attributes?.profile?.data?.attributes?.business_industry}`}
            />
          </List.Item>
          <Text>{this.state.postModalData?.attributes?.body}</Text>
          <Row>
            {this.state.postModalData?.attributes?.media?.length > 0 && (
              <Card>
                {this.renderMedia(this.state.postModalData?.attributes?.media)}
              </Card>
            )}
          </Row> */}
        </div>

      </Modal>);
  }

  rejectJoiningModal = () => {
    return (
      <Modal
        width={360}
        style={{ borderWidth: "0px", borderRadius: "4px", overflow: "hidden" }}
        centered
        // placement="bottomRight"
        title={
          <div
            style={{
              width: "100%",
              cursor: "move",
              fontWeight: "bold",
              borderWidth: "0px"
            }}
            onFocus={() => { }}
            onBlur={() => { }}
          // end
          >
            Reason for Rejection
          </div>
        }
        visible={this.state.isRejectVisible}
        // onOk={() => this.setState({ isRejectVisible: false })}
        onCancel={() => this.setState({ isRejectVisible: false, reasonReject: "" })}
        footer={null}
      >
        <Text>{`You need to add reason for rejecting Joining:`}</Text>
        <Form
          onFinish={() => {
            let communityInfo: any = this.props?.history?.location?.state;
            console.log(this.state.RejectJoinRequestId, "Asdlf")
            this.rejectJoinReason(this.state.RejectJoinRequestId, communityInfo?.id?.id)

          }}
        >
          <Form.Item
            rules={[{ required: true, message: "Required field" }]}
            name="reasonReject"
            getValueProps={() => {
              return this.state.reasonReject
            }}
          >
            <Input
              name="reasonReject"
              value={this.state.reasonReject}
              type="text"
              size="large"
              style={{ borderWidth: 1, marginTop: "10px" }}
              placeholder="Enter reason for rejection"
              onChange={(e) => this.setState({ reasonReject: e.target.value })}
            />
          </Form.Item>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "15px",
              // background: "#f8f8f8"
            }}
          >
            <Button
              className='btn_colors'
              type="primary"
              htmlType="submit"
              shape="round"
              size={"large"}
            // style={{ width: "200px" }}
            >
              Submit
            </Button>
          </div>
        </Form>
      </Modal >
    );
  };

  rejectPostModal = () => {
    return (
      <Modal
        width={360}
        style={{ borderWidth: "0px", borderRadius: "4px", overflow: "hidden" }}
        centered
        // placement="bottomRight"
        title={
          <div
            style={{
              width: "100%",
              cursor: "move",
              fontWeight: "bold",
              borderWidth: "0px"
            }}
            onFocus={() => { }}
            onBlur={() => { }}
          // end
          >
            Reason for Rejection
          </div>
        }
        visible={this.state.isRejectPostVisible}
        onCancel={() => {
          this.setState({
            isRejectPostVisible: false,
            reasonPostReject: ''
          });
        }}
        footer={null}
      >
        <Text>{`You need to add reason for rejecting Post:`}</Text>
        <Form
          initialValues={{
            reasonPostReject: ''
          }}
          onFinish={() => {
            this.rejectPostReason(this.state.postModalData?.id)
            this.setState({
              reasonPostReject: ""
            })
          }}
        >
          <Form.Item
            rules={[{ required: true, message: "Required field" }]}
            name="reasonPostReject"
            getValueProps={() => {
              return this.state.reasonPostReject
            }}
          >
            <Input
              name="reasonPostReject"
              value={this.state.reasonPostReject}
              type="text"
              size="large"
              style={{ borderWidth: 1, marginTop: "10px" }}
              placeholder="Enter reason for rejection"
              onChange={(e) => this.setState({ reasonPostReject: e.target.value })}
            />
          </Form.Item>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "15px",
              background: "#f8f8f8"
            }}
          >
            <Button
              className='btn_colors'
              type="primary"
              htmlType="submit"
              shape="round"
              size={"large"}
            // style={{ width: "200px" }}
            >
              Submit
            </Button>
          </div>

        </Form>
      </Modal>
    );
  };


  // Customizable Area End
  render() {
    // const { navigation } = this.props;
    return (
      <div
        className="searchStyles"
        style={{ background: "#f6f6f6", height: "100vh" }}
      >
        {/* <CustomHeader {...this.props} dotVisible={false} /> */}
        {this.redirectUser()}
        <div className="Community_manage_Layout">
          <Breadcrumb>
            <Breadcrumb.Item>Community</Breadcrumb.Item>
            <Breadcrumb.Item className="anchor_element" onClick={() => this.props.history.push({ pathname: '/community/block', state: { communityId: this.state.communityData?.id } })}>
              <a>{this.state.communityData?.name}</a>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Manage Request</Breadcrumb.Item>
          </Breadcrumb>
          <h2>Manage Request</h2>
          <Row style={{ width: '100%' }}>
            <Col span={24}>
              <Tabs defaultActiveKey="1" onChange={this.callback}>
                <TabPane tab="Post Request" key="1">
                  {
                    this.state?.postReq?.length > 0 ? (
                      <List
                        style={{ background: '#fff' }}
                        itemLayout="horizontal"
                        dataSource={this.state?.postReq}
                        renderItem={(item: any) => (
                          <Row style={{ padding: "15px" }}>
                            <Col xs={24} sm={24} md={15} lg={15} xl={15} xxl={15}>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  justifyContent: "flex-start",
                                  gap: "10px"
                                }}
                              >
                                <div>
                                  <Avatar src={item?.attributes?.photo} size="default" />
                                </div>
                                <div>
                                  {item?.attributes?.account?.data?.attributes?.full_name}
                                  &nbsp; <span style={{ color: '#9e9e9e' }}>has posted on</span>
                                  &nbsp; {this.state.communityData?.name}
                                  .&nbsp; <span style={{ color: '#9e9e9e' }}>{this.getDifference(item?.attributes?.updated_at)}</span>
                                </div>
                              </div>
                            </Col>
                            <Col xs={24} sm={24} md={9} lg={9} xl={9} xxl={9}>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  justifyContent: "flex-end",
                                  gap: "10px"
                                }}
                              >
                                <Button size="middle" shape="round" className="settings-button" key="1"
                                  onClick={() => {
                                    this.setState({ postModalData: item, isviewPostVisible: true })
                                  }}>
                                  View Post
                                </Button>
                                <Button size="middle" shape="round" className="settings-button" key="report"
                                  onClick={() => this.acceptPost(item?.id)}>
                                  Accept
                                </Button>
                                <Button size="middle" shape="round" className="settings-button" key="report"
                                  onClick={() => this.setState({ isRejectPostVisible: true, postModalData: item })}>
                                  Reject
                                </Button>
                              </div>
                            </Col>
                          </Row>
                        )}
                      />
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          width: "100%",
                          height: '80vh',
                          background: '#fff'
                        }}
                      >
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                      </div>
                    )}
                </TabPane>
                <TabPane tab="Joining Request" key="2">
                  {this.state?.joiningReq?.length > 0 ? (
                    <List
                      itemLayout="horizontal"
                      dataSource={this.state?.joiningReq}
                      renderItem={(item: any) => (
                        <Row style={{ padding: "15px" }}>
                          <Col xs={24} sm={24} md={15} lg={15} xl={15} xxl={15}>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "flex-start",
                                gap: "10px"
                              }}
                            >
                              <div>
                                <Avatar src={item?.attributes?.photo} size="default" />
                              </div>
                              <div>
                                {item?.attributes?.role === "business" ? item?.attributes?.profile?.data?.attributes?.company_name : item?.attributes?.full_name}
                                &nbsp; <span style={{ color: '#9e9e9e' }}>wants to join</span>
                                &nbsp; {this.state.communityData?.name}
                                .&nbsp; <span style={{ color: '#9e9e9e' }}>{this.getDifference(item?.attributes?.updated_at)}</span>
                              </div>
                            </div>
                          </Col>
                          <Col xs={24} sm={24} md={9} lg={9} xl={9} xxl={9}>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "flex-end",
                                gap: "10px"
                              }}
                            >
                              <Button size="middle" shape="round" type="default" className="settings-button" key="1"
                                onClick={() => this.handleUserRequest(item?.attributes)}
                              >
                                View Profile
                              </Button>
                              <Button size="middle" shape="round" type="default" className="settings-button" key="2"
                                onClick={() => this.acceptJoining(item?.id)}>
                                Accept
                              </Button>
                              <Button size="middle" shape="round" type="default" className="settings-button" key="3"
                                onClick={() => this.setState({ isRejectVisible: true, RejectJoinRequestId: item })}>
                                Reject
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      )}
                    />
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                        height: '80vh',
                        background: '#fff'
                      }}
                    >
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    </div>
                  )}
                </TabPane>
              </Tabs>
            </Col>
          </Row>
        </div>
        {this.rejectJoiningModal()}
        {this.rejectPostModal()}
        {this.viewPostModal()}
      </div>
    );
  }

  // async componentDidMount() { }
}
// Customizable Area Start

// Customizable Area End
