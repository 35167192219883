import React, {
  FunctionComponent,
  ReactNode,
  useState,
  useEffect
} from "react";
import {
  Typography,
  Row,
  Col,
  AutoComplete,
  Avatar,
  Button,
  Badge,
  Tabs,
  Input,
  Image,
  Spin,
  Dropdown,
  Menu,
  Modal,
  Layout,
  message,
  List,
  Divider,
  Drawer,
  Space
} from "antd";
import { logo, style, user, networkIcon, homeIcon, chatIcon, communityIcon, iconImage, brandImage, bellIcon, logoutImage } from "./assets";
// import { useHistory } from "react-router-dom";
import "./userFeed.css";
import {
  UsergroupAddOutlined,
  BellOutlined,
  GlobalOutlined,
  DownOutlined,
  HomeOutlined,
  LogoutOutlined,
  MessageOutlined,
  SettingOutlined,
  UserOutlined,
  CloseOutlined,
  SearchOutlined,
  MenuOutlined
} from "@ant-design/icons";
// @ts-ignore
import { baseURL } from '../../../framework/src/config.js';
// import CommunityBlock from "./CommunityBlock.web";
// import UserFeedBlock from "./UserFeedBlock.web";
import { removeStorageData, } from "../../../framework/src/Helpers";
import { RouterProps } from "react-router";
import {
  getStorageData,
  setStorageData
} from "../../../framework/src/Utilities";
import axios from 'axios';
import { useHistory } from "react-router-dom"
import moment from "moment";
import { useCallback } from 'react';
import _debounce from 'lodash/debounce';

export interface Props extends RouterProps {
  navigation: any;
  id: string;
  history: any;
  children?: ReactNode;
  // menu?: any;
  // tabsLable?: boolean | true;
  user?: any;
  // searchPlaceHolder?: string;
  dotVisible: boolean;
  // tabIndex: string;
  // onMenuItemPress?: () => void;
  // onTabChange?: (key: string) => void;
  // onSearch?: (key: string) => void;
  // Customizable Area Start
  // Customizable Area End
}

const { Title, Text } = Typography;
const { TabPane } = Tabs;
const { Search } = Input;
const { Header } = Layout;

interface IOption {
  label: any
  options: any

}



const CustomHeader: FunctionComponent<Props> = props => {
  // console.log(window?.location, "history");
  const [option, setOption] = useState<IOption[]>([])
  const [visible, setvisible] = useState(false);
  const [loginUser, setLoginUser] = useState<any>({});
  const [searchTxt, setSearchTxt] = useState<any>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [allNotifications, setNotifications] = useState<any>([]);
  const [notificationsCount, setNotificationCount] = useState<number>(0);
  const [readNotifications, setReadNotifications] = useState<any>([]);
  const [unreadNotifications, setunReadNotifications] = useState<any>([]);
  const [loadingNotification, setLoadingNotification] = useState<boolean>(false);
  const [accountDetails, setAccountDetails] = useState<any>({});
  const [isProfileLoading, setisProfileLoading] = useState<boolean>(false)
  const [isLoadNotifications, setLoadingNotifications] = useState<boolean>(false)
  const [DrawerOn, setDrawer] = useState(false)
  const [state, setState] = useState({
    current: window?.location?.pathname?.split('/')[1]
  });
  let cancelToken: any
  let history = useHistory()
  // console.log(allNotifications);
  // console.log(notificationsCount);
  // console.log(readNotifications);
  // console.log(unreadNotifications);
  // console.log(loadingNotification);
  const handleUserRequest = (value: any) => {
    if (value?.role === "personal") {
      if (window.location.pathname === "/other/personal/profile") {
        window.location.reload()
      }
      return history.push({ pathname: "/other/personal/profile", state: value?.account_id });
    } else if (value?.role === "business") {
      if (window.location.pathname === "/other/business/profile") {
        window.location.reload()
      }
      console.log(value?.account_id)
      return history.push({ pathname: "/other/business/profile", state: value?.account_id });
    }

  }


  const renderItem = (response: any, searchPage: any, value: any) => ({
    label: (
      <>
        {response.length === 0 && (
          <div style={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>
            <span>No Data Found</span>
          </div>
        )}
        {response.slice(0, 5).map((item: any) => {
          return (
            <div
              style={{
                margin: 8,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
              onClick={() => {
                if (item.type !== undefined && item.type.includes("community")) {
                  if (window.location.pathname === "/community/block") {
                    history.push({ pathname: "/community/block", state: { communityId: item.id } })
                    window.location.reload()
                  } else {
                    history.push({ pathname: "/community/block", state: { communityId: item.id } })
                  }
                } else {
                  handleUserRequest(item?.account?.data?.attributes)
                }
              }}
            >

              <div style={{ display: 'flex', alignItems: 'center', width: '70%' }}>
                <SearchOutlined translate={undefined} />

                <Text style={{ marginLeft: 6, textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>
                  {item.type != undefined && item.type.includes("community") ? item?.attributes?.name :
                    item.type == undefined && item?.account && item.account.data.attributes.role == 'business' ?
                      item.account.data.attributes?.profile?.data?.attributes?.company_name
                      : item.account.data.attributes.full_name
                  }

                  {item.type == undefined && item?.account && item.account?.data?.attributes.role == 'business' &&
                    <span style={{ marginLeft: 6, opacity: 0.6, fontSize: 12.5 }}>{
                      item.account.data.attributes?.profile?.data?.attributes?.business_industry
                    }</span>}
                  {item.type == undefined && item?.account && item?.account?.data.attributes.role == 'personal' &&
                    <span style={{ marginLeft: 6, opacity: 0.6, fontSize: 12.5 }}>{
                      item.account.data.attributes?.profile?.data?.attributes?.designation?.title
                    }</span>
                  }
                </Text>
              </div>
              <div>
                <span style={{ marginRight: 6 }}>
                  {item.type !== undefined && item.type.includes("community") ? "community" :
                    item.type == undefined && item?.account && item.account.data.attributes.role}
                </span>
                <Avatar
                  size="small"
                  src={item.type !== undefined && item.type.includes("community") ? item?.attributes?.profile_pic :
                    item.type == undefined && item?.account && item.account.data.attributes.photo}
                />
              </div>
            </div>
          )
        })
        }
        {
          response && (
            <div style={{ textAlign: 'center' }}>
              <Button type="link" onClick={() => {
                if (window.location.pathname === "/search") {
                  history.push({ pathname: '/search', state: { "searchResults": searchPage?.data, "searchTxt": value } })
                  window.location.reload()
                }
                return history.push({ pathname: '/search', state: { "searchResults": searchPage?.data, "searchTxt": value } });
                // window.location.reload();
              }}>See All</Button>
            </div>
          )

        }
      </>
    ),
  });

  const GetProfileInformation = async () => {
    setisProfileLoading(true);
    try {
      const response = await axios({
        url:
          `${baseURL}/bx_block_profile/profiles`,
        method: "get",
        headers: {
          token: await localStorage.getItem('token'),
          "Content-Type": "application/json"
        }
      });
      if (response?.status === 200 && response?.data?.data?.attributes) {
        const res = response?.data?.data?.attributes?.profile?.data?.attributes;
        if (res?.role === "business") {
          // return setAccountDetails(res);
          if (res?.photo !== accountDetails?.photo
            ||
            accountDetails?.company_name !== res?.company_name
          ) {
            // console.log(res?.profile?.data, "userProfile__");
            setStorageData('updatedProfile', res)
            return setAccountDetails(res);
          }
        } else if (res?.full_name !== accountDetails?.full_name
          ||
          res?.photo !== accountDetails?.photo
        ) {
          setStorageData('updatedProfile', res)
          return setAccountDetails(res);
        }
        setStorageData("userProfile", res)
      }
    } catch (error) {
      console.error(error, 'error while calling api');
    }
  }

  const getProfileDetails = async () => {
    await getStorageData('updatedProfile').then((res) => {
      console.log("checking_userProfile res-->", res?.attributes?.profile?.data.attributes);
      console.log("checking_userProfile res-->", res);
      if (res?.attributes) {
        setAccountDetails(res);
      }
    });
  }

  useEffect(() => {
    // getProfileDetails();
    // let customProfileLoad=  props?.history?.location.pathname;
    // if(customProfileLoad==='/home' || 
    // customProfileLoad ==='/MyBusiness/Profile' || 
    // customProfileLoad === '/MyPersonalProfile/Profile'){
    //   GetProfileInformation();
    // }
    // console.log(props?.history?.location.pathname,'props_locations')
    //  console.log(props,'notifications__')
    setSearchTxt(props?.history?.location?.state?.searchTxt)
    if (props?.history?.location?.state?.searchTxt) {
      debounceFn(props?.history?.location?.state?.searchTxt)
    }

  }, [])



  function handleMenuClick(e: any) {
    // console.log("click", e);
    if (e.key === "3") {
      setvisible(true);
      // setDropDownVisible(true);
    }
  }
  function getItem(label: string, key: any, icon?: any, children?: any, theme?: any) {
    return {
      key,
      icon,
      children,
      label,
      theme,
    };
  }
  const items = [
    getItem('Home', 'home', <img
      src={homeIcon}
      alt='home'
      style={{ width: '18px', marginTop: '-5px', marginRight: '5px' }}
    />),
    getItem('My Network', 'myNetwork', <img
      src={networkIcon}
      alt='myNetwor'
      style={{ width: '18px', marginTop: '-5px', marginRight: '5px' }}
    />),
    getItem('Community', 'community', <img
      src={communityIcon}
      alt='Community'
      style={{ width: '18px', marginTop: '-5px', marginRight: '5px' }}
    />),
    getItem('Chat', 'chat', <img
      src={chatIcon}
      alt='chat'
      style={{ width: '18px', marginTop: '-5px', marginRight: '5px' }}
    />),
  ];

  const handleOk = async () => {
    await localStorage.removeItem("token");
    await removeStorageData("loginUser");
    await removeStorageData("userProfile");
    await removeStorageData("accountData");
    await removeStorageData("signUpUser");
    await removeStorageData("signUpUserResponse");
    await removeStorageData("verificationData");
    await removeStorageData("signUpUserID");
    await removeStorageData("isFromLogin");
    await removeStorageData("updatedProfile");
    await removeStorageData("userProfile-1");

    setvisible(false);
    // setDropDownVisible(false);
    props.history.push({
      pathname: "/"
    });
  };

  const handleCancel = (e: any) => {
    console.log(e);
    setvisible(false);
  };
  const info = () => {
    message.info('Coming soon');
  };
  const handleSearchAutoComplete = async (value: any) => {
    console.log(searchTxt)
    if (value.trim().length > 0) {
      setLoading(true);
      // if (cancelToken) {
      //   cancelToken.cancel("api request is canceled")
      // }
      // cancelToken = axios.CancelToken.source()
      try {
        const response = await axios({
          url:
            `${baseURL}/bx_block_search/search?search=${value}&type=all`,
          // cancelToken: cancelToken.token ,
          method: "get",
          headers: {
            token: await localStorage.getItem('token'),
            "Content-Type": "application/json"
          }
        });


        let personal_accounts_length = response?.data?.personal_accounts.length
        let business_accounts_length = response?.data?.business_accounts.length

        let personal_account = personal_accounts_length > 3 ?
          response?.data?.personal_accounts.slice(0, business_accounts_length >= 2 ? 3 : 3 + 2 - business_accounts_length) :
          response?.data?.personal_accounts
        let business_accounts = business_accounts_length > 2 ?
          response?.data?.business_accounts.slice(0, personal_accounts_length >= 3 ? 2 : 2 + 3 - personal_accounts_length) :
          response?.data?.business_accounts
        let communities_data = response?.data?.communities?.data
        console.log("asdfklas", response?.data?.personal_accounts, response?.data?.business_accounts, "asdjasdj", response)
        let resultSearch = [...personal_account, ...business_accounts, ...communities_data]
        console.log([...personal_account, ...business_accounts])
        if (resultSearch.length < 5) {
          console.log([...personal_account, ...business_accounts, ...communities_data])
          resultSearch = [...personal_account, ...business_accounts, ...communities_data]
        }
        setOption([

          {
            label: `results for ${value}`,
            // postCount:response?.data?.personal_accounts_count,
            options: [renderItem(resultSearch, response, value),]
            // renderItem([...response?.data?.personal_accounts])
          },

        ])

        // const response = await axios.get('');
        // this.setState({ allCommunity: response.data.communities });
        setLoading(false);
      } catch (error) {
        console.error(error, 'error while calling api');
        setLoading(false);
      }
      setLoading(false);
    }
  }

  const getUserdata = async () => {
    const userData = await getStorageData('loginUser');
    // console.log('loginData__',userData)
    setLoginUser(userData);
    await getStorageData("userProfile").then((res) => {
      // console.log("checking_userProfile res-->", res);
      // console.log("checking_userProfile res-->", res?.attributes?.profile?.data.attributes);
    });
  }
  const account = async () => {
    const role = await getStorageData('loginUser');
    console.log("checking role.....", role);
    if (role?.role === "personal") {
      props.history.push({
        pathname: "/MyPersonalProfile/Profile",
      });
    } else {
      props.history.push({
        pathname: "/MyBusiness/Profile",
      });
    }
    // else if (role === "business") {
    //   props.history.push({
    //     pathname: "/MyBusiness/Profile",
    //   });
    // }
  }

  const getInitialdata = async () => {
    let data: any = await getStorageData("loginUser").then(async res => {
      if (res) {
        setLoginUser(res);
        // console.log("loginUser", res)
      }
    });
    await getAllNotifications();

  };

  useEffect(() => {
    // if (history) {

    // setState({ current: history.state.state });
    // }
    // console.log(state)
    getInitialdata();
    getUserdata();
    let customProfileLoad = props?.history?.location.pathname;
    if (customProfileLoad === '/home' ||
      customProfileLoad === '/MyBusiness/Profile' ||
      customProfileLoad === '/MyPersonalProfile/Profile') {
      GetProfileInformation();
    }
    // getProfileDetails()
  }, [props]);

  const handleClick = (e: any) => {
    // console.log('click ', e);
    if (e.key === "myNetwork") {
      // console.log(e.key,'********',e)
      props.history.push({ pathname: "/myNetwork", state: "MyNetwork" });
    } else if (e.key === "chat") {
      props.history.push({
        pathname: "/chat",
        state: "Chat"
      });
      // info();
    } else if (e.key === "community") {
      props.history.push({ pathname: "/community/block", state: { name: "Community", searchTxt: searchTxt } });
      // info();
    } else {
      props.history.push({ pathname: "/home", state: { name: "Home", searchTxt: searchTxt } });
    }
    // setState({ current: e.key });
  };

  const handleNotifications = () => {
    null
  }

  // const updateReadAllNotification = async () => {
  //   setLoading(true);
  //   try {
  //     const response = await axios({
  //       url:
  //         `${baseURL}/read_all_notification`,
  //       method: "PUT",
  //       headers: {
  //         token: await localStorage.getItem('token'),
  //         "Content-Type": "application/json"
  //       }
  //     });
  //     console.log(response, "readAllNotification");
  //     setLoading(false);
  //   } catch (error) {
  //     console.error(error, 'error while calling api');
  //     setLoading(false);
  //   }
  // }

  const getSearchReasults = async (value: any) => {
    // console.log(value)
    setLoading(true);
    try {
      const response = await axios({
        url:
          `${baseURL}/bx_block_search/search?search=${value}&type=all`,
        method: "get",
        headers: {
          token: await localStorage.getItem('token'),
          "Content-Type": "application/json"
        }
      });
      props.history.push({ pathname: '/search', state: { "searchResults": response.data, "searchTxt": searchTxt } });
      window.location.reload();
      // const response = await axios.get('');
      // this.setState({ allCommunity: response.data.communities });
      console.log(response, "all search results");
      setLoading(false);
    } catch (error) {
      console.error(error, 'error while calling api');
      setLoading(false);
    }
    setLoading(false);
  };

  const readAllNotifications = async () => {
    setLoadingNotifications(true);
    try {
      const response = await axios({
        url:
          `${baseURL}/bx_block_notifications/notifications/read_all_notification`,
        method: "put",
        headers: {
          token: await localStorage.getItem('token'),
          "Content-Type": "application/json"
        }
      });
      setunReadNotifications([]);
      setLoadingNotifications(false);
      console.log(response, "readAllNotifications__");
    } catch (error) {
      console.error(error, 'error while calling api');
      setLoadingNotifications(false);
    }
  };

  const getAllNotifications = async () => {
    try {
      const response = await axios({
        url:
          `${baseURL}/bx_block_notifications/notifications?today_page=1&today_per_page=50&older_page=1&older_per_page=50`,
        method: "get",
        headers: {
          token: await localStorage.getItem('token'),
          "Content-Type": "application/json"
        }
      });
      if (response?.data) {
        let count = response.data.older_notifications_count + response.data.today_notifications_count + response.data.yesterday_notifications_count;
        let totalNotifications: any = [];
        let totalNotificationsRead: any = [];
        let resunreadNotifications: any = [];
        response.data?.today_notifications?.data?.map((c: any) => {
          totalNotifications?.push(c);
          if (c?.attributes?.is_read) {
            totalNotificationsRead?.push(c?.id)
          } else {
            resunreadNotifications?.push(c?.id)
          }
        });
        response.data?.yesterday_notifications?.data?.map((c: any) => {
          totalNotifications?.push(c);
          if (c?.attributes?.is_read) {
            totalNotificationsRead?.push(c?.id)
          } else {
            resunreadNotifications?.push(c?.id)
          }
        });
        response.data?.older_notifications?.data?.map((c: any) => {
          totalNotifications?.push(c);
          if (c?.attributes?.is_read) {
            totalNotificationsRead?.push(c?.id)
          } else {
            resunreadNotifications?.push(c?.id)
          }
        });
        // console.log(response?.data,' ****count')
        // console.log(unreadNotifications, 'unread ****count')
        // console.log(totalNotifications, '***total Notifications')
        if (totalNotifications)
          setNotifications(totalNotifications);
        setNotificationCount(count > 0 ? count : 0);
        setunReadNotifications(resunreadNotifications);
        setReadNotifications(totalNotificationsRead);
      }
      // console.log(response.data, "all_notification_results");
    } catch (error) {
      console.error(error, 'error while calling notification api');
    }
  };

  const updateNotification = async (id: any) => {
    if (!readNotifications.includes(id)) {
      readNotifications.push(id);
      setLoadingNotification(true);
      try {
        const response = await axios({
          url:
            `${baseURL}/bx_block_notifications/notifications/${id}`,
          method: "put",
          headers: {
            token: await localStorage.getItem('token'),
            "Content-Type": "application/json"
          }
        });
        // console.log(response, "readnotification___");
        setLoadingNotification(false);
        getAllNotifications();
      } catch (error) {
        console.error(error, 'error while calling api');
        setLoadingNotification(false);
      }
    }
  }

  const debounceFn = useCallback(_debounce(handleSearchAutoComplete, 800), []);

  // const onSearch = (value: any) => console.log(value);

  const menu = (
    <Menu
      style={{ marginTop: "15px", borderWidth: 0 }}
      onClick={handleMenuClick}
    >
      <Menu.Item
        key="1"
        style={{ paddingRight: "60px" }}
        icon={<UserOutlined translate={{}} />}
        onClick={() =>
          // info()
          account()
          // props.history.push({
          //   pathname: "/MyBusiness/Profile"
          // })
        }
      >
        <Text>Profile</Text>
      </Menu.Item>
      <Menu.Item
        key="2"
        style={{ paddingRight: "60px" }}
        icon={<SettingOutlined translate={{}} />}
        onClick={() => props.history.push({
          pathname: "/settings/tab/account-setting",
        })}
      >
        <Text>Settings</Text>
      </Menu.Item>
      <Menu.Item
        key="3"
        style={{ paddingRight: "60px" }}
      // icon={<LogoutOutlined translate={{}} />}
      >
        <img
          src={logoutImage}
          alt='logout'
          style={{
            height: 16,
            filter: 'contrast(1000%) invert(100%) sepia(100%) saturate(10000%) hue-rotate(90deg)'
          }}
        />
        <Text> Logout </Text>
      </Menu.Item>
      <Modal
        width={350}
        style={{ borderWidth: "0px", borderRadius: "4px", overflow: "hidden" }}
        centered
        className="Logout_Popup"
        // placement="bottomRight"
        title={
          <div
            style={{
              width: "100%",
              cursor: "move",
              fontWeight: "bold",
              borderWidth: "0px"
            }}
            onFocus={() => { }}
            onBlur={() => { }}
          // end
          >
            Logout
          </div>
        }
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Row>
            <Col span={12}>
              <Button
                size="large"
                className="report-button"
                key="report"
                onClick={handleCancel}
              >
                No, don't logout
              </Button>
            </Col>
            <Col span={12}>
              <Button
                size="large"
                className="follow-label"
                style={{ borderWidth: "0px" }}
                key="report"
                onClick={handleOk}
              >
                Yes, logout
              </Button>
            </Col>
          </Row>
        ]}
      >
        <Text>Are you sure want to logout?</Text>
      </Modal>
    </Menu>
  );

  const menuNotifications = (
    <Menu onMouseLeave={handleNotifications} style={{ width: '280px', minHeight: '340px' }}>
      {allNotifications?.length > 0 ? (
        <React.Fragment>
          <div style={{ display: 'flex', justifyContent: 'space-between', padding: '1rem' }}>
            <div style={{ fontWeight: 'bold', color: '#000' }}>Notifications &nbsp; <Badge count={unreadNotifications?.length} /></div>
            <div style={{ color: '#5b7183', cursor: 'pointer' }} onClick={() => readAllNotifications()}>{isLoadNotifications ? <Spin /> : 'Read All'}</div>
          </div>
          <Divider plain style={{ margin: 0 }} />
          {allNotifications?.length > 0 ? allNotifications?.length < 4 ? allNotifications.map((item: any, i: number) => {
            return i < 4 && <React.Fragment key={i}>
              <List.Item onClick={() => updateNotification(item.id)}>
                <List.Item.Meta
                  avatar={
                    <Avatar
                      src={item?.attributes?.photo}
                      // size={40}
                      style={{ marginLeft: "16px", backgroundColor: "lightgrey" }}
                    />
                  }
                  title={item?.attributes?.headings}
                  description={
                    moment(item?.attributes?.created_at).from(Date.now())
                  }
                  style={{ alignItems: "center" }}
                />
                {
                  unreadNotifications?.length ?
                    <div>
                      <Badge
                        dot={readNotifications.includes(item.id) ? false : true}
                        color={readNotifications.includes(item.id) ? '#fff' : '#2db7f5'}
                      />
                    </div>
                    : null
                }

              </List.Item>
            </React.Fragment>
          }) : allNotifications.slice(0, 4).map((item: any, i: number) => {
            return <React.Fragment key={i}>
              <List.Item onClick={() => updateNotification(item.id)}>
                <List.Item.Meta
                  avatar={
                    <Avatar
                      src={item?.attributes?.photo}
                      // size={40}
                      style={{ marginLeft: "16px", backgroundColor: "lightgrey" }}
                    />
                  }
                  title={item?.attributes?.headings}
                  description={
                    moment(item?.attributes?.created_at).from(Date.now())
                  }
                  style={{ alignItems: "center" }}
                />
                {
                  unreadNotifications?.length ?
                    <div>
                      <Badge
                        dot={readNotifications.includes(item.id) ? false : true}
                        color={readNotifications.includes(item.id) ? '#fff' : '#2db7f5'}
                      />
                    </div>
                    : null
                }
              </List.Item>
            </React.Fragment>
          }) : <></>}
          <div style={{ position: notificationsCount > 3 ? 'relative' : 'absolute', bottom: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#315572', background: '#d9e2e9', padding: '0 1rem', height: '40px', width: '100%', fontWeight: 'bold', cursor: 'pointer' }} onClick={() => props.history.push({
            pathname: "/notifications"
          })}>View All</div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', minHeight: '370px', height: '100%', width: '100%' }}>
            {/* <div> */}
            <BellOutlined style={{ fontSize: "30px", color: "#000" }} translate={undefined} />
            <h3>No Notifications Yet!</h3>
            <p style={{ textAlign: 'center' }}>You will be notified when something new arrives.</p>
          </div>
          {/* </div> */}
        </React.Fragment>
      )}
    </Menu>
  );

  return (
    <Row
      style={{
        background: 'linear-gradient(180deg, rgba(60,96,134,1) 0%, rgba(53,135,196,1) 100%)',
        minHeight: "76px"
      }}>
      <Col
        xs={8}
        lg={3}
        xl={3}
        className="customHeader_avatar"
      // style={{ display: "flex", alignItems: "center", justifyContent:'center'}}
      >
        <Image
          preview={false}
          style={{ height: "28px", width: "100px", }}
          src={brandImage}
        />
      </Col>

      {
        history?.location?.pathname?.includes("edit") ? null :
          <Col
            xs={0}
            lg={9}
            xl={9}
            xxl={10}
          >
            <Menu
              triggerSubMenuAction="click"
              selectedKeys={[state.current]}
              mode="horizontal"
              onClick={handleClick}
              style={{
                background: "transparent",
                fontSize: "16px",
                color: "#fff",
                height: "100%",
                border: "none"
              }}
            >
              <Menu.Item key="home" className="headingMenuItem">
                <img
                  src={homeIcon}
                  alt='home'
                  style={{ width: '18px', marginTop: '-5px', marginRight: '5px' }}
                />
                Home
              </Menu.Item>
              <Menu.Item key="myNetwork" className="headingMenuItem">
                <img
                  src={networkIcon}
                  alt='myNetwork'
                  style={{ width: '18px', marginTop: '-5px', marginRight: '5px' }}
                />
                My Network
              </Menu.Item>
              <Menu.Item key="community" className="headingMenuItem">
                <img
                  src={communityIcon}
                  alt='community'
                  style={{ width: '18px', marginTop: '-5px', marginRight: '5px' }}
                />
                Community
              </Menu.Item>
              <Menu.Item key="chat" className="headingMenuItem">
                <img
                  src={chatIcon}
                  alt='home'
                  style={{ width: '18px', marginTop: '-5px', marginRight: '5px' }}
                />
                Chat
              </Menu.Item>
            </Menu>
          </Col>
      }


      {/* {Web header View } */}

      {
        history?.location?.pathname?.includes("edit") ? null :
          <Col
            xs={0}
            md={0}
            // lg={6}
            // xl={6}
            lg={11}
            xl={11}
            xxl={10}
            className="header_web"
            style={{
              // border:'1px solid blue',
              // display: "flex",
              alignItems: "center",
              justifyContent: "end"
            }}
          >
            <div style={{
              //  border: '1px solid green',
              display: 'flex'
            }}>
              <div className="headercustSearch">
                <AutoComplete
                  dropdownClassName="certain-category-search-dropdown"
                  dropdownStyle={{ borderRadius: "8px", overflow: 'hidden' }}
                  dropdownMatchSelectWidth={420}
                  defaultValue={searchTxt}
                  value={searchTxt}
                  style={{
                    width: 240,
                    borderRadius: '8px'
                  }}
                  options={option}
                >
                  <Input
                    size="large"
                    placeholder="Search Contact"
                    style={{ borderRadius: '25px', maxWidth: 'fit-content' }}
                    value={searchTxt ? searchTxt : null}
                    prefix={loading ? <Spin /> : searchTxt ? <SearchOutlined translate={undefined} onClick={() => getSearchReasults(searchTxt)} /> : null}
                    suffix={searchTxt ? <CloseOutlined translate={undefined} onClick={() => {
                      setSearchTxt('')
                      setOption([])
                    }} /> : <SearchOutlined translate={undefined} style={{ color: '#d1d1d3', fontSize: 22 }} />}

                    onChange={e => {
                      setSearchTxt(e.target.value)
                      if (e.target.value.trim().length > 0) {
                        debounceFn(e.target.value)
                      } else {
                        debounceFn(e.target.value)
                        setOption([])
                      }
                    }}
                  />
                </AutoComplete>
              </div>

              <div className="headercustName" style={{ display: 'flex' }}>
                <div
                  style={{
                    //  width: "25%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    margin: '0 10px'
                    //  border:'1px solid green'
                  }}
                >
                  <Dropdown
                    overlay={menuNotifications}
                    placement="bottomRight" arrow
                  >
                    <Badge
                      dot={unreadNotifications.length > 0 ? true : false}
                      style={{
                        color: "#f5222d",
                        borderColor: "#f5222d"
                      }}
                    >
                      <Button
                        type="text"
                        style={{ color: "#fff" }}
                        icon={<img src={bellIcon} style={{ width: "18px" }} />}
                      />
                    </Badge>
                  </Dropdown>
                </div>

                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    display: 'flex',
                    alignItems: 'center',
                    // justifyContent:'flex-end'
                    justifyContent: "center"
                  }}
                >
                  <div>
                    <Avatar
                      src={accountDetails && accountDetails?.photo ? accountDetails?.photo : loginUser?.profile_pic}
                      size="default"
                    />
                  </div>
                  <Text
                    style={{
                      fontSize: "16px",
                      color: "#fff",
                      textTransform: 'capitalize',
                      padding: "0px 10px"
                    }}
                    strong
                  >
                    {loginUser?.role === "business"
                      ? accountDetails?.company_name ? accountDetails?.company_name : loginUser?.company_name
                      : accountDetails?.full_name ? accountDetails?.full_name : loginUser?.name}
                  </Text>

                  <Dropdown
                    overlay={menu}
                  >
                    <Button
                      style={{
                        backgroundColor: "transparent",
                        borderWidth: 0,
                        padding: 0,
                        marginLeft: -2,
                        color: "#fff"
                      }}
                    >
                      <DownOutlined translate={undefined} />
                    </Button>
                  </Dropdown>
                </div>
              </div>
            </div>
          </Col>
      }



      {/* {Mobile  view header} */}
      {
        history?.location?.pathname?.includes("edit") ? null :
          <Col
            xs={15}
            lg={0}
            xl={0}
            xxl={0}
            className='header_mobile'
            style={{
              // display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              // justifyContent: "space-around",
              marginLeft: '10px',
              // border:'1px solid red',
            }}
          >
            <div
              style={{
                //  width: "25%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                margin: '0 5px'
                //  border:'1px solid green'
              }}
            >
              <Dropdown
                overlay={menuNotifications}
                placement="bottomRight" arrow
              >
                <Badge
                  dot={unreadNotifications.length > 0 ? true : false}
                  style={{
                    color: "#f5222d",
                    borderColor: "#f5222d"
                  }}
                >
                  <Button
                    type="text"
                    style={{ color: "#fff" }}
                    icon={<img src={bellIcon} style={{ width: "18px" }} />}
                  />
                </Badge>
              </Dropdown>
            </div>
            <div
              style={{
                // width: "100%",
                height: "100%",
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                // justifyContent: "center"
              }}
            >
              <div>
                <Avatar
                  src={accountDetails && accountDetails?.photo ? accountDetails?.photo : loginUser?.profile_pic}
                  size="default"
                />
              </div>
              {/* <Text
            style={{
              fontSize: "15px",
              color: "#fff",
              textTransform: 'capitalize',
              padding: "0px 10px",
              width: 'max-content'
            }}
            strong
          >
            {loginUser.role === "business"
              ? accountDetails?.company_name ? accountDetails?.company_name :loginUser?.name
              : accountDetails?.full_name ?  accountDetails?.full_name :loginUser?.name}
          </Text> */}

              <Dropdown
                trigger={["click"]}
                overlay={menu}
              >
                <Button
                  style={{
                    backgroundColor: "transparent",
                    borderWidth: 0,
                    padding: '0px 10px',
                    color: "#fff"
                  }}
                >
                  <DownOutlined translate={undefined} />
                </Button>
              </Dropdown>
            </div>
          </Col>
      }

      {
        history?.location?.pathname?.includes("edit") ? null :
          <Col
            xs={21}
            md={21}
            lg={0}
            xl={0}
            style={{ padding: '5px' }}
          >
            <AutoComplete
              dropdownClassName="certain-category-search-dropdown"
              dropdownStyle={{ borderRadius: "8px", overflow: 'hidden' }}
              dropdownMatchSelectWidth={350}
              defaultValue={searchTxt}
              value={searchTxt}
              style={{
                width: 220,
                textAlign: 'center',
                justifyContent: 'center',
                borderRadius: '8px'
              }}
              options={option}
            >
              <Input
                size="middle"
                placeholder="Search Contact"
                style={{ borderRadius: '25px', width: '200px', maxWidth: 'fit-content' }}
                value={searchTxt ? searchTxt : null}
                prefix={loading ? <Spin /> : searchTxt ? <SearchOutlined translate={undefined} onClick={() => getSearchReasults(searchTxt)} /> : null}
                suffix={searchTxt ? <CloseOutlined translate={undefined} onClick={() => {
                  setSearchTxt('')
                  setOption([])
                }} /> : <SearchOutlined translate={undefined} style={{ color: '#d1d1d3', fontSize: 22 }} />}

                onChange={e => {
                  setSearchTxt(e.target.value)
                  if (e.target.value.trim().length > 0) {
                    debounceFn(e.target.value)
                  } else {
                    debounceFn(e.target.value)
                    setOption([])
                  }
                }}
              />
            </AutoComplete>
          </Col>
      }
      {
        history?.location?.pathname?.includes("edit") ? null :
          <Col
            xs={3}
            md={3}
            lg={0}
            xl={0}
            style={{ padding: '5px' }}
          >
            <MenuOutlined translate={''} style={{ color: '#fff', fontSize: 20, marginTop: '5px', marginRight: "5px" }} onClick={() => setDrawer(true)} />
            {/* <Button onClick={()=>setDrawer(true)}>Menu</Button> */}

          </Col>
      }
      {
        history?.location?.pathname?.includes("edit") ? null :
          <Drawer
            title=""
            placement={'left'}
            width={240}
            onClose={() => setDrawer(false)}
            visible={DrawerOn}
          // extra={
          //   <Space>
          //     <Button onClick={()=>setDrawer(false)}>Cancel</Button>
          //   </Space>
          // }
          >

            <Menu
              selectedKeys={[state.current]}
              mode="vertical"
              onClick={handleClick}
              style={{
                background: "transparent",
                fontSize: "16px",
                color: "black",
                height: "100%",
                marginTop: '20px',
                width: '100%',
                border: "none"
              }}
            >
              <Menu.Item key="home"
              // style={{ paddingTop: '20px' }}
              // className="headingMenuItem"
              >
                <img
                  src={homeIcon}
                  alt='home'
                  style={{
                    width: '18px', marginTop: '-5px', marginRight: '5px',
                    filter: 'contrast(1000%)invert(100%)sepia(100%)saturate(10000%)hue-rotate(90deg)'
                  }}
                />
                Home
              </Menu.Item>
              <Menu.Item key="myNetwork"
              // style={{ paddingTop: '20px' }}
              // className="headingMenuItem"
              >
                <img
                  src={networkIcon}
                  alt='myNetwork'
                  style={{
                    width: '18px', marginTop: '-5px', marginRight: '5px',
                    filter: 'contrast(1000%)invert(100%)sepia(100%)saturate(10000%)hue-rotate(90deg)'
                  }}
                />
                My Network
              </Menu.Item>
              <Menu.Item key="community"
              // style={{ paddingTop: '20px' }}
              // className="headingMenuItem"
              >
                <img
                  src={communityIcon}
                  alt='community'
                  style={{
                    width: '18px', marginTop: '-5px', marginRight: '5px',
                    filter: 'contrast(1000%)invert(100%)sepia(100%)saturate(10000%)hue-rotate(90deg)'
                  }}
                />
                Community
              </Menu.Item>
              <Menu.Item key="chat"
              // style={{ paddingTop: '20px' }}
              // className="headingMenuItem"
              >
                <img
                  src={chatIcon}
                  alt='home'
                  style={{
                    width: '18px', marginTop: '-5px', marginRight: '5px',
                    filter: 'contrast(1000%)invert(100%)sepia(100%)saturate(10000%)hue-rotate(90deg)'
                  }}
                />
                Chat
              </Menu.Item>
            </Menu>
          </Drawer>
      }

    </Row>
  )
}

export default CustomHeader;

// Customizable Area Start

// Customizable Area End
