import * as React from 'react';
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { RouterProps } from "react-router";
export const configJSON = require("./../config");
import { Redirect } from "react-router-dom";

export interface Props extends RouterProps {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface UserDataType {
  id: string | number,
  role: string,
  first_name: string,
  last_name: string,
  full_name: string,
  company_name: string,
  profile_pic: string,
  following: boolean
}

interface S {
  // Customizable Area Start
  isLoading: {
    isLoadingfollowing: boolean,
    isLoadingfollowers: boolean,
  };
  token: string | null;
  redirect: boolean;
  followers: Array<UserDataType>;
  followings: Array<UserDataType>;
  keyValue: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class FollowersFollowingController extends BlockComponent<Props, S, SS> {

  // Customizable Area Start
  followId: string = "";
  getFollowersApiCallId: string = "";
  getFollowingsApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      isLoading: {
        isLoadingfollowing: false,
        isLoadingfollowers: false,
      },
      token: localStorage.getItem("token") ? localStorage.getItem("token") : null,
      redirect: false,
      followers: [],
      followings: [],
      keyValue: ''
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }
  async componentDidMount() {
    super.componentDidMount();
    let token = await localStorage.getItem("token");
    if (token && token !== null && token !== undefined) {
      this.setState({ redirect: false });
    } else {
      this.setState({ redirect: true });
    }

    this.setState({
      isLoading: {
        ...this.state.isLoading,
        isLoadingfollowers: true,
        isLoadingfollowing: true
      },
    })
    this.getFollowers();
    this.getFollowings();
  }

  getResponseData = (responseJson: any) => responseJson.data.map((item: any) => {
    return {
      id: item.attributes.account_id,
      role: item.attributes.role,
      first_name: item.attributes.first_name,
      last_name: item.attributes.last_name,
      full_name: item.attributes.full_name,
      company_name: item.attributes.role === "business" ? item.attributes.profile.data.attributes.company_name : "",
      profile_pic: item.attributes.photo,
      following: item.attributes.following
    }
  })

  async receive(from: String, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      // get followers
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.getFollowersApiCallId) {
          let keyValue: any = this.props.history.location.state;
          this.setState({ keyValue: keyValue.keyValue })
          this.setState({
            followers: this.getResponseData(responseJson),
            isLoading: { ...this.state.isLoading, isLoadingfollowers: false }
          });
        }
      }

      // get followings
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.getFollowingsApiCallId) {
          this.setState({
            followings: this.getResponseData(responseJson),
            isLoading: { ...this.state.isLoading, isLoadingfollowing: false }
          });
        }
      }

      if (apiRequestCallId === this.followId) {
        this.getFollowers();
        this.getFollowings();
      }

    }

    // Customizable Area End
  }

  redirectUser() {
    if (this.state.redirect) {
      return <Redirect to="/" data-test-id="redirect" />;
    }
  }

  // Customizable Area Start

  handleChangeTab = (value: string) => {
    this.setState({
      keyValue: value
    })
  }

  handleClickOnUser = (item: UserDataType) => {
    if (item.role === "personal" && this.props.history) {
      this.props.history.push({ pathname: "/other/personal/profile", state: item.id });
    } else if (item.role === "business" && this.props.history) {
      this.props.history.push({ pathname: "/other/business/profile", state: item.id });
    }
  }

  // handling follow
  onHandleFollow = (id: string | number) => {
    const header = {
      "Content-Type": configJSON.followContentType,
      token: localStorage.getItem("token")
    };
    let body = {
      "account_id": id
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.followId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.followMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.followEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  // get followers
  getFollowers = async () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getFollowersApiCallId = requestMessage.messageId;
    const header = {
      "Content-Type": configJSON.getPostsApiContentType,
      token: this.state.token,
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getFollowersApiCallIdEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getPostsApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  // get followings
  getFollowings = async () => {
    const header = {
      "Content-Type": configJSON.getPostsApiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getFollowingsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getFollowingsApiCallIdEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getPostsApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  // Customizable Area End
}
