import React from "react";
import "./../userFeed.css";
import { DeleteImgPng } from "../assets";
import {
    Grid, Button
} from "@material-ui/core";
import {
    DeleteOutlined
} from '@ant-design/icons';
import { useHistory } from "react-router-dom";

export default function SavedPostsItems(props: any) {

    const { classes, item, deletePost, loginId } = props;
    const profile = item.attributes?.account.data.attributes
    const history = useHistory();
    const handleUserRequest = (value: any) => {
        console.log(value, loginId)
        if (value?.account_id === loginId) {
            return null
        } else {
            if (value?.role === "personal") {
                return history.push({ pathname: "/other/personal/profile", state: value?.account_id });
            } else if (value?.role === "business") {
                return history.push({ pathname: "/other/business/profile", state: value?.account_id });
            }
        }
    }
    const nav = (id: any) => {
        history.push({ pathname: `/post/${id}`, state: id });
    }

    return (
        <div className={classes.postContainer}>
            <Grid container spacing={2} style={{ position: 'relative' }} >
                <Grid item
                    // xs={1}
                    className={classes.Responsive}
                    style={{
                        // backgroundColor: "red"
                    }}

                >
                    <img
                        src={profile?.photo}
                        className={classes.image}
                        style={{ cursor: 'pointer' }}
                    />
                </Grid>
                <Grid item
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        // backgroundColor: "green"
                    }}
                // xs={11}
                >
                    <p className={classes.postTitle}
                        style={{ cursor: 'pointer', wordBreak: 'break-word', }}
                        onClick={() => nav(item.id)}
                    >
                        {item.attributes.body}
                    </p>

                    <p style={{ marginBottom: 0, color: "gray", fontSize: 16, marginTop: 'auto' }}>
                        {props?.hidePost ? 'Hide' : 'Saved'} from <span style={{ color: 'black', fontWeight: 600, cursor: 'pointer' }} onClick={() => handleUserRequest(item?.attributes?.account?.data?.attributes)} >{item.attributes?.account.data.attributes.role === "business" ? item.attributes?.account.data.attributes?.profile?.data?.attributes?.company_name : item.attributes?.account.data.attributes?.full_name}'s </span>Post
                    </p>
                </Grid>
                {/* @ts-ignore */}
                <Button className={classes.deletPost} onClick={() => deletePost(item.id)} style={{ position: "absolute" }}>
                    <img src={DeleteImgPng} width={10} />
                </Button>
            </Grid>
        </div>
    )

}
// Customizable Area Start
// Customizable Area End
