import React, { useState } from 'react';
import { Modal, Select, Button, Space, Typography, Form } from 'antd';
import { Grid } from "@material-ui/core";

const { Text } = Typography;
const { Option } = Select;

const SendRequestModal = (props: any) => {

  const { sendRequestRelations, handleChange, isModalVisible, handleOk, handleCancel, errorMessage, userName } = props;

  return (
    <Modal
      title={<div style={{ fontWeight: "bold", borderWidth: "0px", fontSize: "18px", paddingRight: '24px' }}>
        Select Relationship with {userName}
      </div>}
      visible={isModalVisible}
      footer={null}
      style={{ borderWidth: "0px", borderRadius: "4px", overflow: "hidden" }}
      bodyStyle={{ paddingTop: "5px" }}
      onCancel={handleCancel}
      centered
      width={450}
    >

      <Grid container style={{ padding: "0px" }}>
        <Grid item xs={12}>
          <Form.Item
            rules={[
              {
                required: true,
                message: "Required field"
              }
            ]}
            style={{ borderWidth: 1, marginBottom: "0px" }}
            name="relationValue"
          >
            <Select
              id={"select"}
              style={{
                padding: "0 !important",
                border: "2px solid #a9bac8",
                borderRadius: '27px',
                overflow: "hidden",
                width: "100%"
              }}
              placeholder="Select Relationship"
              bordered={true}
              dropdownStyle={{
                border: "2px solid #a9bac8",
                borderRadius: "10px",
                paddingTop: "0px",
                paddingBottom: "0px",
                width: "100%"
              }}
              onChange={handleChange}
            >
              {sendRequestRelations && sendRequestRelations.map((item: any, id: number) => {
                return (
                  <option key={item.id}
                    style={{
                      borderBottom: sendRequestRelations.length - 1 > id ? "2px solid #a9bac8" : "none",
                      padding: "10px 20px 10px 20px"
                    }}
                    value={item.id}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%"
                      }}
                    >
                      <div className="capital_options">{item.name}</div>
                    </div>
                  </option>
                );
              })}
            </Select>
          </Form.Item>
        </Grid>
        <Grid item xs={12} style={{ paddingTop: "15px" }}>
          <Grid container>
            <Grid item xs={6} style={{ textAlign: "start" }}>
              <Button className="report-button btn-accept-req-modal" key="accept"
                onClick={handleOk}
              >
                Send Request
              </Button>
            </Grid>
            <Grid item xs={6} style={{ textAlign: "end" }}>
              <Button
                type="primary"
                shape="round"
                className="btn-reject-req-modal"
                danger
                key="reject"
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Grid>

      </Grid>
    </Modal>
  );
};

export default SendRequestModal;