import React, { Component } from 'react';
import { runEngine } from '../../../../framework/src/RunEngine';
import { Message } from '../../../../framework/src/Message';
import MessageEnum, {
  getName,
} from '../../../../framework/src/Messages/MessageEnum';
import { BlockComponent } from '../../../../framework/src/BlockComponent';
import { IBlock } from '../../../../framework/src/IBlock';
import moment, { localeData } from 'moment';
import { getStorageData } from '../../../../framework/src/Helpers';
import { RouterProps } from 'react-router';
import { Redirect } from "react-router-dom";
export const configJSON = require('../config');
import { Select, message, Checkbox, Modal, Row, Col, Button } from 'antd';
import { profile } from 'console';
const { Option } = Select;
export interface Props extends RouterProps {
  navigation: any;
  id: string;
}
interface S {
  loading: boolean;
  isRegistration: boolean;
  bussiness_profile: boolean;
  data: any;
  token: string | null;
  category_data: [];
  domain_skills: object[];
  children: any[];
  categories_id: string | null;
  personal_skills: object[];
  skills_2: any[];
  employe_type_obj: object[];
  employment_type: any[];
  profileUrl: string;
  profilePictureFile: any;
  personalSkillVisible: boolean;
  domainSkillVisible: boolean;
  pre_data: any;
  isErrorModalShow: boolean;
  errorContent: any;
  sessionData: any;
  isSuccess: boolean;
  file: any;
  redirect: boolean;
}
interface SS {
  id: any;
}

export default class PersonalBasicProfileController extends BlockComponent<
  Props,
  S,
  SS
> {
  getCategoriesApiCallId: any;
  uploadPictureApiCallId: any;
  formref = React.createRef();
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.receive = this.receive.bind(this);
    this.state = {
      bussiness_profile: true,
      loading: true,
      isRegistration: true,
      data: {},
      token: localStorage.getItem('token') ? localStorage.getItem('token') : '',
      category_data: [],
      domain_skills: [],
      personal_skills: [],
      children: [],
      skills_2: [],
      employe_type_obj: [],
      employment_type: [],
      categories_id: '',
      profileUrl: '',
      profilePictureFile: {},
      personalSkillVisible: false,
      domainSkillVisible: false,
      pre_data: {},
      isErrorModalShow: false,
      errorContent: {},
      sessionData: {},
      isSuccess: false,
      file: {},
      redirect: false
    };
  }

  async componentDidMount() {
    let token = await localStorage.getItem("token");
    if (token && token !== null && token !== undefined) {
      this.setState({ redirect: false });
    } else {
      this.setState({ redirect: true });
    }
    this.getCategories();

    // this.setState({ loading: true });
    console.log(this.props.history.location.state, 'personaltest');
    let details: any = this.props.history.location.state;

    if (details && details.profile && details != null) {
      let newData = Object.assign(
        {},
        { experience: details && details.experience },
        { certificate: details && details.certificate },
        { graduation: details && details.graduation },
        { language: details && details.language },
        { contact: details && details.contact }
      );
      this.setState({
        data: newData,
        pre_data: details && details.profile,
        profileUrl: details.profile.profileUrl,
      });
    } else {
      this.setState({ loading: true });
      let loginDetails = await getStorageData('loginUser');
      if (loginDetails) {
        this.setState({
          sessionData: loginDetails,
          // loading: false,
        });
        console.log('personal loginDetails true login1', loginDetails);
      } else {
        let loginDetails = await getStorageData('signUpUser');
        if (loginDetails) {
          this.setState({
            sessionData: loginDetails,
            // loading: false,
          });
          console.log('personal signUpUser true signup1', loginDetails);
        } else {
          let loginDetails = await getStorageData('verificationData');
          if (loginDetails) {
            this.setState({
              sessionData: loginDetails,
              // loading: false,
            });
            console.log('personal signUpUser true signup3', loginDetails);
          }
        }
      }
      console.log('personal signUpUser true 2', loginDetails);
      this.setState({
        loading: false,
      });
    }
    // this.setState({ loading: false });

    // this.setState({ loading: false });
  }

  redirectUser() {
    if (this.state.redirect) {
      return <Redirect to="/" />;
    }
  }

  getBase64(img: any, callback: any) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }
  async beforeUpload(file: any) {
    this.setState({
      // profilePictureFile: {},
      profileUrl: '',
    });
    const isJpgOrPng = (await file.type.slice(0, 5)) == 'image';
    if (!isJpgOrPng) {
      // this.setState({ profilePictureFile: file });
      message.error('You can only upload Image file!');
    } else {
      // console.log(
      //   "isJpgOrPng===>",
      //   this.state.profilePictureFile,
      //   this.state.profileUrl
      // );
      this.getBase64(file, (imageUrl: string) => {
        this.setState({
          profileUrl: imageUrl,
          profilePictureFile: file,
          loading: false,
        });
        console.log(
          'isJpgOrPng after===>',
          this.state.profilePictureFile,
          this.state.profileUrl
        );
      });
    }
    return isJpgOrPng;
  }
  handleImageChange = (info: any) => {
    // console.log("info", info);
    this.setState({
      file: info.file.originFileObj,
    });
    // console.log("@@@uploade Image test", this.state.file);
    this.uploadProfilePicture();
  };

  uploadProfilePicture() {
    const header = {
      // "Content-Type": configJSON.contentTypeApiUploadProfilePicture,
      token: this.state.token,
    };
    var form = new FormData();
    // form.append("profile_pic", uploadfile);
    form.append('profile_pic', this.state.profilePictureFile);
    console.log('test1', this.state.profilePictureFile);
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.uploadPictureApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiUploadProfilePicture
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiUploadProfilePicture
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      form
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleData = async (update_data: any) => {
    console.log('=====>', update_data);
    delete update_data['profile_pic'];
    if (this.state.personalSkillVisible) {
      let index = update_data?.personal_skills?.indexOf('Other');
      // console.log('skill=====>', index);
      await update_data?.personal_skills?.splice(
        index,
        1,
        update_data?.other_personal_skills
      );
      delete update_data['other_personal_skills'];
    }
    if (this.state.domainSkillVisible) {
      let index = update_data?.domain_skills?.indexOf('Other');
      // console.log('skill=====>', index);
      await update_data?.domain_skills?.splice(
        index,
        1,
        update_data?.other_domain_skills
      );
      delete update_data['other_domain_skills'];
    }
    let profile = await {
      ...update_data,
      date_of_birth: moment(update_data.date_of_birth._d).format('YYYY-MM-DD'),
      // date_of_birth: moment(update_data.date_of_birth._d).format("DD-MM-YYYY"),
    };
    let data = Object.assign({}, profile, {
      profileUrl: this.state.profileUrl,
    });
    // console.log(profile, "test personal");
    let finalData = Object.assign(
      {},
      { profile: data },
      { experience: null },
      { certificate: null },
      { graduation: null },
      { language: null },
      { contact: null }
    );
    localStorage.setItem('employment_type', update_data.employment_type);
    if (update_data.employment_type == 'Student') {
      this.props.history.replace({ state: finalData });
      this.props.history.push({
        pathname: '/setup/profile/personal/achievements',
        state: finalData,
      });
    } else {
      this.props.history.replace({ state: finalData });
      this.props.history.push({
        pathname: '/setup/profile/personal/experience',
        state: finalData,
      });
    }
    // console.log('test final data', finalData);
  };
  handleCategory = () => {
    let domain_skills = [...this.state.domain_skills];
    let personal_skills = [...this.state.personal_skills];
    let employe_type_obj = [...this.state.employe_type_obj];
    const children = [...this.state.children];
    const skills_2 = [...this.state.skills_2];
    const employment_type = [...this.state.employment_type];
    this.state.category_data?.map((data: any) => {
      if (data.attributes.name === 'Domain Skills') {
        domain_skills = data.attributes.sub_categories;
      }
      if (data.attributes.name === 'Personal Skills') {
        personal_skills = data.attributes.sub_categories;
      }
      if (data.attributes.name === 'Employment Type') {
        employe_type_obj = data.attributes.sub_categories;
      }
    });
    domain_skills.forEach((data: any, index) => {
      children.push(
        <Option
          style={{
            borderBottom: '1px solid #7aacd6',
            padding: '10px 20px 10px 20px',
          }}
          value={data.name}
          key={index}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              // paddingTop: "0.7rem",
            }}
          >
            <div>{data.name}</div>

          </div>
        </Option>
      );
    });
    personal_skills.forEach((data: any, index) => {
      skills_2.push(
        <Option
          style={{
            borderBottom: '1px solid #7aacd6',
            padding: '10px 20px 10px 20px',
          }}
          value={data.name}
          key={index}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              // paddingTop: "0.7rem",
            }}
          >
            <div>{data.name}</div>

          </div>
        </Option>
      );
    });
    employe_type_obj.forEach((data: any, index) => {
      employment_type.push(
        <Option
          style={{
            borderBottom: '1px solid #7aacd6',
            padding: '10px 20px 10px 20px',
          }}
          value={data.name}
          key={index}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              // paddingTop: "0.7rem",
            }}
          >
            <div>{data.name}</div>

          </div>
        </Option>
      );
    });
    this.setState({
      children: children,
      loading: false,
      skills_2: skills_2,
      employment_type: employment_type,
    });
  };
  getCategories = () => {
    const header = {
      'Content-Type': 'application/json',
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCategoriesApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCategoryAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    this.setState({
      categories_id: requestMessage.id,
    });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  async receive(from: string, message: Message) {
    console.log('receive');
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId == this.getCategoriesApiCallId) {
          if (responseJson.data) {
            this.setState(
              {
                category_data: responseJson.data,
              },
              this.handleCategory
            );
          } else if (responseJson.errors) {
            // console.log(responseJson.errors[0].token);
            this.setState({
              isErrorModalShow: true,
              errorContent: {
                title: 'Error',
                isError: true,
                body: responseJson.errors[0].token,
              },
            });
          } else {
            console.log('something went wrong');
          }
        } else if (apiRequestCallId == this.uploadPictureApiCallId) {
          this.setState({
            isSuccess: true,
          });
          console.log(responseJson);
        }
      } else {
        console.log('test');
      }
    }
  }
  // onChange(e: any) {
  //   console.log(`checked = ${e.target.checked}`);
  // }
}
