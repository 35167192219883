import React, { Component } from "react";
import {
  PageHeader,
  Card,
  Row,
  Col,
  Form,
  Input,
  Typography,
  Button,
  Spin,
  DatePicker,
  Steps,
  Popover,
  Space,
  Select,
  Avatar,
  Upload,
  message,
  Divider,
} from "antd";
// import { logo, style, NETWORK_PROFILE_ING } from "../../";
const { Text, Link, Title } = Typography;
import {
  UserOutlined,
  EnvironmentOutlined,
  CloseCircleOutlined,
  CheckCircleTwoTone,
  PlusCircleFilled,
} from "@ant-design/icons";
import {
  style,
  logo,
  NETWORK_PROFILE_ING,
} from "../../../invitefriends/src/assets";

const UpcomingBirthAnniversaries = (props: any) => {
  console.log("get all props", props);

  return (
    <div>
      <PageHeader
        className="site-page-header"
        title={
          <span style={{ color: "white" }}>
            <img style={{ height: "32px", marginRight: "8px" }} src={logo} />
            applied
          </span>
        }
        style={{
          backgroundImage: "linear-gradient(#005574, #7aacd6)",
          color: "white",
        }}
      />
      <Row
        justify="center"
        style={{
          paddingLeft: "16px",
          paddingRight: "16px",
          paddingTop: "10px",
          backgroundColor: "#eff8fe",
        }}
      >
        <Title level={4} style={{ fontSize: 16, fontWeight: "bold" }}>
          Birth Anniversaries
        </Title>
      </Row>
      <div>
        <Row style={{ paddingLeft: "16px", paddingRight: "16px" }}>
          <Col span={12}>
            <Title level={4} style={{ fontSize: 16, fontWeight: "bold" }}>
              Today
            </Title>
          </Col>
        </Row>
        <Row
          gutter={10}
          style={{ minHeight: "38vh", padding: "0 15px 15px 15px" }}
        >
          <Col xs={0} sm={0} md={3} lg={5} xl={4.3}>
            <Card
              hoverable
              className="padding-card-network"
              style={{ borderRadius: 8 }}
            >
              <Row justify="center">
                <Avatar size={64} icon={<UserOutlined translate={{}} />} />
              </Row>
              <Row
                justify="center"
                style={{
                  paddingTop: "12px",
                  justifyContent: "center",
                  alignContent: "center",
                  alignItems: "center",
                }}
              >
                <Text style={{ fontSize: 14, fontWeight: "bold" }}>
                  Anje Keizer
                </Text>
                <br />
                <p
                  style={{
                    width: "100%",
                    marginBottom: "0",
                    fontSize: 12,
                    textAlign: "center",
                  }}
                >
                  Sr. Python Developer <Text type="secondary"> at </Text> Google
                </p>
                <br />
                <Text
                  style={{
                    width: "100%",
                    marginBottom: 10,
                    fontSize: 12,
                    textAlign: "center",
                  }}
                  type="secondary"
                >
                  13 Aug, 2021
                </Text>
              </Row>
              <Divider />
              <Row
                justify="center"
                style={{ paddingTop: "5px", paddingBottom: "10px" }}
              >
                <Button
                  className={style.skip_add_btn_anniversaries}
                  type="primary"
                  shape="round"
                  size={"small"}
                >
                  View Profile
                </Button>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default UpcomingBirthAnniversaries;
