export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const workStation = require("../assets/workStation.jpg");
export const levelUp = require("../assets/levelUp.jpg");
export const logo = require("../assets/logo.png");
export const logoutImage = require("../assets/log-out@3x.png");
export const comment = require("../assets/comment.png");
export const tag = require("../assets/tag.png");
export const style = require("../../social-media-account/src/login.module.css");
export const connectionsIcon = require("../assets/connections.png");
export const followersIcon = require("../assets/followers.png");
export const followingIcon = require("../assets/following.png");
export const postsImgsIcon = require("../assets/posts.png");
export const ratingSentIcon = require("../assets/rating-sent.png");
export const ratingsReceivedIcon = require("../assets/ratings-received.png");
export const homeIcon = require("../assets/image_home.png");
export const chatIcon = require("../assets/image_chat.png");
export const networkIcon = require("../assets/image_network.png");
export const communityIcon = require("../assets/image_community.png");
export const user = require("../assets/workStation.jpg");
export const iconImage = require("../assets/8ecd1491ec0e4d0a0924207205bd1444e738b94c.png");
export const brandImage = require("../assets/brandname.png");

export const uparrow = require("../assets/38d70c1cf508e699597388abbb6c8d3c87a2499d.png");
export const free_ac = require("../assets/43d91557e81c6917b73407072179be3f30c3cabb.png");
export const bluepoint = require("../assets/99ed3ca78c93ccd9bc5931f98b4b3106bf576f00.png");
export const bgblue = require("../assets/b5e3f6ed0c9d5a9029645c35088943bed5291d73.png");
export const orengepoint = require("../assets/daa065442d1df3fd73919d82baaf7854d9dc1c17.png");
export const pre_ac = require("../assets/e95d4091536502b28c092541e8f5395c7cc67556.png");
export const three_dot = require("../assets/three_dot.png");
export const editer = require("../assets/4d93190eb6ad54f087b798abd4de26102bf6cc74.png");
export const share = require("../assets/15f631bf5decfa74c72d6f948b860f20955c19c9.png");
export const bellIcon = require("../assets/bell@3x.png");
export const MenuImage = require("../assets/menu.png");
export const DeleteImgPng = require("../assets/delete.png");
export const createProfile = require("../assets/createProfile.png");
export const BlockedAccounts = require("../assets/blocked-account.png");
export const SettingImgPng = require("../assets/settings.png");
export const HiddenPostsImgPng = require("../assets/hidden-posts.png");
export const addCommunity = require("../assets/add-community-profile.png");
export const editCommunity = require("../assets/edit-community.png");
export const faceIcon = require("../assets/ic_Emoticon@3x.png");
export const photoIcon = require("../assets/ic_Image@3x.png");
export const locationIcon = require("../assets/placeholder@3x.png");
export const atIcon = require("../assets/at@3x.png");
export const sendIcon = require("../assets/send.png");
export const checkedIcon = require("../assets/checked-1.png");
export const settingIcon = require("../assets/settings.png");
export const blockIcon = require("../assets/blocked-account.png");
export const eyehiddenIcon = require("../assets/hidden-posts.png");
export const acceptIcon = require("../assets/accept.png");
export const rejectIcon = require("../assets/reject.png");
export const whatsappIcon = require("../assets/whatapp.png");
export const facebookIcon = require("../assets/fb.png");
export const linkdinIcon = require("../assets/in.png");
export const gmailIcon = require("../assets/gmail.png");
export const blogIcon = require("../assets/blog.png");
export const chatIconImage = require("../assets/group_chat@3x.png");
