import React, { Suspense } from "react";
import USerFeedController, { Props } from "./UserFeedController.web";
import {
  connectionsIcon,
  followersIcon,
  followingIcon,
  postsImgsIcon,
  ratingSentIcon,
  ratingsReceivedIcon,
  faceIcon,
  photoIcon,
  atIcon,
  locationIcon,
  sendIcon
} from "./assets";
import CommonListItem from "./commonListItem.web";
import {
  CloseOutlined,
} from "@ant-design/icons";
import "./userFeed.css";
import moment from 'moment';
import {
  Card,
  Row,
  Col,
  List,
  Typography,
  Avatar,
  Button,
  Select,
  Form,
  Upload, Image,
  Radio,
  Space, Modal, Spin,
  Mentions
} from "antd";
import ReactPlayer from 'react-player';
import { Grid } from "@material-ui/core";
const CustomPost = React.lazy(() => import('./CustomPost.web'));
import Editor from "@draft-js-plugins/editor";
import '@draft-js-plugins/emoji/lib/plugin.css';
import createMentionPlugin, {
  defaultSuggestionsFilter
} from "@draft-js-plugins/mention";
import "@draft-js-plugins/mention/lib/plugin.css";
import createEmojiPlugin, { defaultTheme } from '@draft-js-plugins/emoji';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from "react-places-autocomplete";
import { convertToRaw, EditorState } from "draft-js";
//emoji
const emojiPlugin = createEmojiPlugin({
  selectButtonContent: <img src={faceIcon} width={20} />,
  theme: defaultTheme
});
defaultTheme.emojiSelect = defaultTheme.emojiSelect + "emojiStyling"
const { EmojiSuggestions, EmojiSelect } = emojiPlugin;

const { Text } = Typography;


//Top or Bottom nav spaces or any extra space occupied by os e.g Status bar, Notch 

const { Option, getMentions } = Mentions;

export interface PlacesAutocompleteProps {
  getInputProps?: any;
  suggestions?: any;
  getSuggestionItemProps?: any;
  loading: any;
}
export default class UserFeedBlock extends USerFeedController {
  mentionPlugin: any;
  editor: any;
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // const { } = this.state;
    // console.log(this.state,"state")
    this.mentionPlugin = createMentionPlugin();
    // Customizable Area End
  }
  handleSelect = async (value: any) => {
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    this.setState({ address: value, location: false });
    // setCoordinates(latLng);
  };
  //locations
  getUserLocattion = () => {
    return (
      <div>
        <PlacesAutocomplete
          value={this.state.address}
          onChange={(address) => this.setState({ address })}
          onSelect={this.handleSelect}
        >
          {({ getInputProps, suggestions, getSuggestionItemProps, loading }: PlacesAutocompleteProps) => (
            <div>
              <div className="autocomplete-dropdown-container">
                {/* {loading ? <div>...loading</div> : null} */}
                {<div style={{
                  boxShadow: "0px 2px 7px 0px #888888",
                  maxHeight: '120px',
                  overflowY: "auto"
                }}>
                  {
                    suggestions.map((suggestion: {
                      terms: any;
                      formattedSuggestion: any;
                      active: any;
                      description: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined;
                    }) => {
                      const style = {
                        backgroundColor: suggestion.active ? "#41b6e6" : "#fff",
                        padding: '14px',
                        borderBottom: '1px solid rgb(0, 0, 0, 0.05)'
                      };
                      return (
                        <div className="input-suggestion"
                          {...getSuggestionItemProps(suggestion, { style })}>
                          <img src={locationIcon} alt="" width={14} />
                          {/* <span style={{ paddingLeft: "1rem" }}>{suggestion.formattedSuggestion.secondaryText}</span> */}
                          <span style={{ paddingLeft: "1rem" }}>
                            {
                              suggestion.terms.length == 1 ? suggestion.terms[0].value :
                                suggestion.terms.length == 2 ? suggestion.terms[0].value + ", " + suggestion.terms[1].value :
                                  suggestion.terms.length >= 3 && suggestion.terms[0].value + ", " + suggestion.terms[suggestion.terms.length - 2].value + ", " + suggestion.terms[suggestion.terms.length - 1].value
                            }
                          </span>
                        </div>
                      );
                    })
                  }
                </div>}
              </div>
              <div className="input-location">
                <img src={locationIcon} alt="" width={14} />
                <input {...getInputProps({
                  // placeholder: "Type address",
                  className: 'location-search-input',
                })} />
              </div>
            </div>
          )
          }
        </PlacesAutocomplete>
      </div >
    );
  }

  // Customizable Area Start
  renderUSerProfile = () => {
    var startDate = moment("2018-10-10", "YYYY.MM.DD"), // $('[name="date-start"]').val() === "13.04.2016"
      endDate = moment("2020-11-12", "YYYY.MM.DD"); // $('[name="date-end"]').val() === "28.04.2016"

    var diff = startDate.diff(endDate);

    // console.log( 'date-->',moment(diff).format('E') );
    const { userProfile } = this.state
    console.log(userProfile)
    return (
      <Card
        bordered={false}
        style={{ borderRadius: 4 }}
        bodyStyle={{
          textAlign: "center",
          paddingTop: "12px",
          paddingBottom: "0px",
        }}
      >
        <Avatar src={userProfile?.photo} size={60} style={{ backgroundColor: "grey" }} />
        <p>
          {" "}

          <Text style={{ fontSize: 20, fontWeight: "bold", color: "#141414", textTransform: "capitalize" }}>
            {this.state.loginUser.role &&
              this.state.loginUser.role !== "personal" ?
              this.state.loginUser?.company_name : userProfile?.full_name}
          </Text>
        </p>
        <p>
          <Text style={{ fontSize: 16, fontWeight: 500, color: "#141414" }}>
            {this.state.loginUser.role === "personal" && userProfile?.designation?.title}
            {userProfile?.designation?.company && <span style={{ color: "rgb(201, 200, 205)" }}> at </span>}
            {this.state.loginUser.role === "personal" && userProfile?.designation?.company}
          </Text>
        </p>
        <p>

          {this.state.loginUser.role &&
            this.state.loginUser.role !== "personal" &&
            userProfile &&
            userProfile.attributes &&
            userProfile.attributes.business ? (
            <Text style={{ fontSize: 16, color: "#141414" }}>
              {userProfile.attributes.business +
                ", " +
                userProfile.attributes.business_industry}
            </Text>
          ) : null}
          {this.state.loginUser.role &&
            this.state.loginUser.role !== "personal" &&
            userProfile?.business ? (
            <Text style={{ fontSize: 16, color: "#141414" }}>
              {userProfile?.business +
                ", " +
                userProfile?.business_industry}
            </Text>
          ) : null}
          {userProfile?.domain_skills ? (
            <Text style={{ fontSize: 16, color: "#141414" }}>
              {this.getDomainSkills(userProfile?.domain_skills)}
            </Text>
          ) : null}
          <br />
          {this.state.loginUser.role &&
            this.state.loginUser.role !== "personal" &&
            userProfile &&
            userProfile.attributes &&
            userProfile.attributes.location ? (
            <Text style={{ fontSize: 16, color: "#141414" }}>
              {userProfile.attributes.location}
            </Text>
          ) : null}
          {userProfile?.role !== "personal" &&
            userProfile?.location ? (
            <Text style={{ fontSize: 16, color: "#141414" }}>
              {userProfile?.location}
            </Text>
          ) : null}
        </p>

        <p>
          {" "}
          {userProfile?.experience_years ? (
            <Text style={{ fontSize: 16, color: "#141414" }}>{userProfile?.experience_years}+ years of experience</Text>
          ) : null}
        </p>
      </Card>
    );
  };

  renderCreateProstPopup = () => {
    return (<Modal
      footer={null}
      bodyStyle={{
        width: "100%",
        height: "80vh",
        overflowY: "auto"
      }}
      centered
      title={
        <Space
          style={{
            width: "100%",
            cursor: "move",
            fontWeight: "bold",
            color: "#414141"
          }}
        >
          Create New Post
        </Space>
      }
      visible={this.state.createPostOpen}
      onCancel={() => this.handlecreatePostOpenCancel()}
    >
      {this.renderCreatePost()}
    </Modal>
    )
  }

  //editpostpopup
  renderEditPostPopup = () => {
    return (<Modal
      footer={null}
      className="Edit_Modal_Layout"
      bodyStyle={{
        width: "100%",
        height: "100%",
        maxHeight: "60vh",
        minHeight: "40vh",
        overflowY: "auto"
      }}
      centered
      title={
        <Space
          style={{
            width: "100%",
            cursor: "move",
            fontWeight: "bold",
            color: "#414141"
          }}
        >
          Edit the post
        </Space>
      }
      visible={this.state.editPostOpen}
      onCancel={() => this.setState({
        editPostOpen: !this.state.editPostOpen,
        AllMedia: [],
        getMentionsArray: [],
        tagged_connection_ids: [],
        selectTags: [],
        createPostDescription: EditorState.createEmpty(),
        address: null
      })}
    >
      {this.rendereditPost()}
    </Modal>
    )
  };

  renderPostConnectionDetails = () => {

    const postData = [
      {
        key: "Posts",
        count:
          // this.state.accountDetails?.attributes?.posts_count ? this.state.accountDetails?.attributes?.posts_count :
          this.state?.profileInformationId?.attributes?.posts_count ? this.state?.profileInformationId?.attributes?.posts_count :
            this.state.accountDetails?.attributes?.posts_count ? this.state.accountDetails?.attributes?.posts_count : 0
        ,
        image: postsImgsIcon,
        // description: "UI/Ux Designer Community",
      },
      {
        key: "Connections",
        count: this.state.accountDetails?.attributes?.friends_count ? this.state.accountDetails?.attributes?.friends_count : 0,
        image: connectionsIcon,
        // description: "UI/Ux Designer Community",
      },
      {
        key: "Followers",
        count: this.state.accountDetails?.attributes?.followers_count ? this.state.accountDetails?.attributes?.followers_count : 0,
        image: followersIcon,
        // description: "UI/Ux Designer Community",
      },
      {
        key: "Following",
        count: this.state.accountDetails?.attributes?.following_count ? this.state.accountDetails?.attributes?.following_count : 0,
        image: followingIcon,
        // description: "UI/Ux Designer Community",
      },
      {
        key: "Ratings Received",
        count: this.state.accountDetails?.attributes?.ratings_received ? this.state.accountDetails?.attributes?.ratings_received : 0,
        image: ratingsReceivedIcon,
        // description: "UI/Ux Designer Community",
      },
      {
        key: "Ratings Sent",
        count: this.state.accountDetails?.attributes?.ratings_given ? this.state.accountDetails?.attributes?.ratings_given : 0,
        image: ratingSentIcon,
        // description: "UI/Ux Designer Community",
      },

    ];
    return (
      <Card style={{ borderRadius: 4 }}>
        <List
          className="stats_responsive"
          grid={{ gutter: 4, xl: 2, lg: 2, md: 2, sm: 2, xs: 2, xxl: 2 }}
          dataSource={postData}
          renderItem={(item) => (
            <List.Item style={{
              cursor: "pointer",

              // border:'1px solid red'
            }}
              onClick={() => this.handleFollowersAndFollowingOpen(item.key)}>
              <List.Item.Meta
                className="homePage_stats"
                avatar={
                  <div className={"post-connection-content ant-row-middle"}>
                    <Avatar style={{ padding: "5px", marginRight: '6px' }} src={item.image} size={40} />
                  </div>
                }
                title={<h4 className="post-connection-title" style={{ overflow: "hidden" }}>{item.key}</h4>}
                description={
                  <h2 className="post-connection-count">
                    <b>{this.state.loading || this.state.loadAccountData ? <Spin /> : item.count}</b>
                  </h2>
                }
                style={{ height: 64, alignItems: "center", backgroundColor: "rgb(221,230,235)", marginRight: "5px", borderRadius: "4px" }}
              />
            </List.Item>
          )}
        />
      </Card>
    );
  };

  showReportModal = () => {
    return (
      <Modal
        width={365}
        style={{ borderWidth: "0px", borderRadius: "4px", overflow: "hidden" }}
        centered
        className="Logout_Popup"
        // placement="bottomRight"
        title={
          <div style={{ width: "100%", cursor: "move", fontWeight: "bold", borderWidth: "0px" }}
            onFocus={() => { }}
            onBlur={() => { }}
          // end
          >
            Report Post
          </div>
        }
        visible={this.state.isReportIssueVisible}
        onCancel={this.handleCancel}
        footer={[
          <Button size="large" className="report-button" style={{ width: "100%" }} key="report"
            onClick={() => this.handleOk(this.state.value)}>
            Report
          </Button>
        ]}
      >
        <Radio.Group onChange={this.onChangeValue} value={this.state.value} buttonStyle="outline">
          <Space direction="vertical">
            <Radio style={{ color: "grey" }} value='Fraude or pyramid Marketing'>
              Fraude or Pyramid Marketing
            </Radio>
            <Radio style={{ color: "grey" }} value='Promoting/Selling illegal or regulated goods'>
              Promoting/Selling illegal or regulated goods
            </Radio>
            <Radio style={{ color: "grey" }} value='Promotes Terrorism'>
              Promotes Terrorism
            </Radio>
            <Radio style={{ color: "grey" }} value='Discrimination, Harrassment or Bullying'>
              Discrimination, Harrassment or Bullying
            </Radio>
            <Radio style={{ color: "grey" }} value='Child Abuse'>
              Child Abuse
            </Radio>
          </Space>
        </Radio.Group>
      </Modal>
    )
  }

  showInvitationModal = () => {
    return (
      <Modal
        width={450}
        style={{ borderWidth: "0px", borderRadius: "4px", overflow: "hidden" }}
        centered
        className="home_invitation_modal"
        // placement="bottomRight"
        title={
          <div style={{ width: "100%", cursor: "move", fontWeight: "bold", borderWidth: "0px", paddingRight: '24px' }}
            onFocus={() => { }}
            onBlur={() => { }}
          // end
          >
            {this.state?.invitationUserInfo?.name} wants to connect with you as  {" "}
            {this.state.allInvitations?.data?.map((item: any) => {
              return item?.attributes?.account_id === this.state?.invitationUserInfo?.account_id ? item?.attributes?.sender_relation : null
            })}
          </div>
        }
        visible={this.state.isShowInvitationModal}
        onCancel={() => this.setState({ isShowInvitationModal: false })}
        footer={[
          <div style={{ display: 'flex', justifyContent: 'space-between', margin: '0 10px' }}>
            <Button size="large" className="report-button" style={{ width: "45%" }} key="accept"
              onClick={() => this.handleInvitations(this.state?.relationValue, 'accepted',
                this.state.invitationUserInfo?.req_id, this.state?.invitationUserInfo?.cat_id)}>
              Accept Request
            </Button>
            <Button size="large" className="reject-button" style={{ width: "45%" }} key="reject"
              onClick={() => this.handleInvitations(this.state?.relationValue, 'rejected',
                this.state.invitationUserInfo?.req_id,
                this.state?.invitationUserInfo?.cat_id)}>
              Reject Request
            </Button>
          </div>
        ]}
      >
        <Row gutter={12}>
          <Col
            xs={24}
          >
            <p>Change relationship to</p>
            <Form.Item
              //  validateStatus={this.state?.relationValue ? 'success' : "error"}
              rules={[
                {
                  required: true,
                  message: "Required field"
                }
              ]}
              style={{ borderWidth: 1 }}
              name="relationValue"
            >
              <Select
                id={"select"}
                style={{
                  padding: "0 !important",
                  border: "2px solid #a9bac8",
                  borderRadius: '27px',
                  overflow: "hidden",
                  width: "100%"
                }}
                placeholder="Select Relationship"
                bordered={true}
                dropdownStyle={{
                  border: "2px solid #a9bac8",
                  borderRadius: "10px",
                  paddingTop: "0px",
                  paddingBottom: "0px",
                  width: "100%"
                }}
                onChange={this.handleChange}
              >
                {this.state.loadInvitationData &&
                  this.state.loadInvitationData.length === 0 ?
                  <h4>Loading...</h4> :
                  this.state.loadInvitationData.map((item: any, id: number) => {
                    // console.log("item32232332====", item);
                    return (
                      <option key={item}
                        style={{
                          borderBottom: this.state.loadInvitationData?.length - 1 > id ? "2px solid #a9bac8" : "none",
                          padding: "10px 20px 10px 20px",
                        }}
                        value={item}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <div className="capital_options" style={{ color: "#a9bac8" }}>{item}</div>
                        </div>
                      </option>
                    );
                  })}
              </Select>
            </Form.Item>
            {/* </Form> */}
            {/* )} */}
          </Col>
        </Row>
      </Modal>
    )
  }

  showConnectionModal = () => {
    return (
      <Modal
        width={450}
        style={{ borderWidth: "0px", borderRadius: "4px", overflow: "hidden" }}
        bodyStyle={{ paddingTop: "5px" }}
        centered
        title={<div style={{ fontWeight: "bold", borderWidth: "0px", fontSize: "18px", cursor: "move", paddingRight: '24px' }}>
          Select Relationship with {this.state?.invitationUserInfo?.name}
        </div>}
        visible={this.state.isShowConnectionModal}
        onCancel={() => this.setState({ isShowConnectionModal: false })}
        footer={null}
      >
        <Grid container style={{ padding: "0px" }}>
          <Grid item xs={12}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Required field"
                }
              ]}
              style={{ borderWidth: 1, marginBottom: "0px" }}
              name="relationValue"
            >
              <Select
                id={"select"}
                style={{
                  padding: "0 !important",
                  borderRadius: '15px',
                  width: "100%"
                }}
                placeholder="Select Relationship"
                bordered={true}
                dropdownStyle={{
                  paddingTop: "0px",
                  paddingBottom: "0px",
                  width: "100%"
                }}
                onChange={this.handleChange}
              >
                {this.state.loadInvitationData &&
                  this.state.loadInvitationData.length === 0 ?
                  <h4>Loading...</h4> :
                  this.state.loadInvitationData.map((item: any) => {
                    return (
                      <option key={item}
                        style={{
                          borderBottom: "1px solid #7aacd6",
                          padding: "10px 20px 10px 20px"
                        }}
                        value={item}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%"
                          }}
                        >
                          <div className="capital_options">{item}</div>
                        </div>
                      </option>
                    );
                  })}
              </Select>
            </Form.Item>
          </Grid>
          <Grid item xs={12} style={{ paddingTop: "15px" }}>
            <Grid container>
              <Grid item xs={6}>
                <Button className="report-button btn-accept-req-modal" key="accept"
                  onClick={() => this.sendRequest(this.state?.relationValue, this.state.invitationUserInfo?.req_id)}>
                  Send Request
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  type="primary"
                  shape="round"
                  className="btn-reject-req-modal"
                  danger
                  key="reject"
                  onClick={() => this.setState({ isShowConnectionModal: false })}>
                  Cancel Request
                </Button>
              </Grid>
            </Grid>
          </Grid>

        </Grid>
      </Modal>
    )
  }

  renderPost = (item: any) => {

    return (
      // <>
      //   {JSON.stringify(this.state.followedItems)}
      <Suspense fallback={<Card style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
        <Spin />
      </Card>} >
        <CustomPost
          menu={{ isCommunity: false, isCommentloader: this.state.isCommentloader }}
          handlePostList={this.handlePostList}
          loginUserId={this.state?.loginUser?.id ? this.state?.loginUser?.id : this.state?.userProfile?.account_id}
          data={item?.attributes}
          handleMenuClick={(e: any) => this.handleMenuClick(e, item.id)}
          isFollowed={this.state.followedItems.includes(item.attributes?.account?.data?.id)}
          toggleFollow={() => this.onClickFollow(item.attributes?.account?.data?.id, this.state.followedItems.includes(item.attributes?.account?.data?.id))}
          handleEditPostOpen={(id: any) => this.handleEditPostOpen(id)}
        />
      </Suspense>
      // </>
    );
  };

  renderImage = (id: any, isFullHeight: boolean) => {
    if (this.state.AllMedia[id]?.content_type) {
      return (
        <Card style={{ width: 'auto', padding: 3, height: isFullHeight ? 400 : 200, borderWidth: 0 }} bodyStyle={{ padding: '0px' }} >
          <Button style={{ backgroundColor: 'grey', right: 0, top: -5, position: 'absolute', zIndex: 100, borderRadius: 20 }} size='small' onClick={() => this.deleteItem(id)} icon={<CloseOutlined translate={{}} />} />
          <Image src={this.state.AllMedia[id].url} height={isFullHeight ? 380 : 210}
            style={{ borderRadius: '4px', backgroundColor: 'grey' }}
            wrapperStyle={{ backgroundSize: 'cover' }} />
        </Card>
      )
    } else {
      return (
        <Card style={{ width: 'auto', padding: 3, height: isFullHeight ? 400 : 200, borderWidth: 0 }} bodyStyle={{ padding: '0px' }} >
          <Button style={{ backgroundColor: 'grey', right: 0, top: -5, position: 'absolute', zIndex: 100, borderRadius: 20 }} size='small' onClick={() => this.onRemoveImage(id)} icon={<CloseOutlined translate={{}} />} />
          <Image src={this.state.AllMedia[id].filePath} height={isFullHeight ? 380 : 210}
            style={{ borderRadius: '4px', backgroundColor: 'grey' }}
            wrapperStyle={{ backgroundSize: 'cover' }} />
        </Card>
      )
    }
  }
  renderVideo = (id: any, isFullHeight: boolean) => {
    if (this.state.AllMedia[id]?.content_type) {
      return (
        <Card style={{ width: 'auto', padding: 3, height: isFullHeight ? 400 : 200, borderWidth: 0 }} bodyStyle={{ padding: '0px', backgroundColor: "#FAFAFA" }} >
          <Button style={{ backgroundColor: 'grey', right: 0, top: -5, position: 'absolute', zIndex: 100, borderRadius: 20 }} size='small' onClick={() => this.deleteItem(id)} icon={<CloseOutlined translate={{}} />} />
          <ReactPlayer url={this.state.AllMedia[id]?.url}
            controls
            height={isFullHeight ? 380 : 180}
            width={'98%'}
            style={{ borderRadius: '4px', backgroundColor: "#FAFAFA" }} />
        </Card>
      )
    } else {
      return (
        <Card style={{ width: 'auto', padding: 3, height: isFullHeight ? 400 : 200, borderWidth: 0 }} bodyStyle={{ padding: '0px', backgroundColor: "#FAFAFA" }} >
          <Button style={{ backgroundColor: 'grey', right: 0, top: -5, position: 'absolute', zIndex: 100, borderRadius: 20 }} size='small' onClick={() => this.onRemoveImage(id)} icon={<CloseOutlined translate={{}} />} />
          <ReactPlayer url={this.state.AllMedia[id].filePath} controls
            height={isFullHeight ? 380 : 180}
            width={'98%'}
            style={{ borderRadius: '4px', backgroundColor: "#FAFAFA" }} />
        </Card>
      )
    }
  }
  checkMedia = (id: any, isFullHeight: boolean) => {
    if (this.state.AllMedia[id]?.content_type) {
      return this.state.AllMedia[id].content_type.slice(0, 5) == "image" ? this.renderImage(id, isFullHeight) : this.renderVideo(id, isFullHeight);
    } else {
      return this.state.AllMedia[id].type.slice(0, 5) == "video" ? this.renderVideo(id, isFullHeight) : this.renderImage(id, isFullHeight);
    }
  }
  renderMedia = (imgArray: any) => {
    switch (imgArray.length) {
      case 0:
        return null;
      case 1:
        return (
          <Row style={{ padding: 5, width: '100%' }} >
            {/* <Col span={24}  > */}
            {this.checkMedia(0, false)}
            {/* </Col> */}
          </Row>
        )
      case 2:
        return (
          <Row className="flex-row">
            <Col span={12} >{this.checkMedia(0, false)}</Col>
            <Col span={12} >{this.checkMedia(1, false)}</Col>
          </Row>
        )
      case 3:
        return (
          <Row className="flex-row"  >
            <Col span={12} >
              {this.checkMedia(0, true)}
            </Col>
            <Col style={{ flexDirection: 'column' }} span={12} >
              {this.checkMedia(1, false)}
              {this.checkMedia(2, false)}
            </Col>
          </Row>
        )
      case 4:
        return (
          <div >
            <Row style={{ width: '100%' }}>
              <Col span={12} >
                {this.checkMedia(0, false)}
              </Col>
              <Col span={12} >
                {this.checkMedia(1, false)}
              </Col>
            </Row>
            <Row style={{ width: '100%' }}>
              <Col span={12} >{this.checkMedia(2, false)}</Col>
              <Col span={12} > {this.checkMedia(3, false)}</Col>
            </Row>
          </div>
        )
      default:
        return (
          <div >
            <Row style={{ width: '100%' }}>
              <Col span={12} >
                {this.checkMedia(0, false)}
              </Col>
              <Col span={12} >
                {this.checkMedia(1, false)}
              </Col>
            </Row>
            <Row style={{ width: '100%' }}>
              <Col span={12} >{this.checkMedia(2, false)}</Col>
              <Col span={12} >
                {this.checkMedia(3, false)}
                <Button size='large' type='text' style={{ marginTop: 8 }} >{`+${this.state.AllMedia.length - 4} More`} </Button>
              </Col>
            </Row>
          </div>
        )
    }
  };

  //tags
  onSelectTags = (option: any) => {
    console.log(option, "option");
    let mention = this.state.selectTags;
    mention.push({ value: option.value, key: option.key });
    console.log(this.state.selectTags)
    this.setState({ selectTags: mention })
  }

  onChangeTags = (value: any) => {
    const data = value.split("@").filter((item: string) => {
      if (item) {
        return item.trim();
      } else {
        return null;
      }
    })
    let getMention = getMentions(value);
    this.setState({
      getMentionsArray: getMention,
      getMentionsArrayList: data
    });
  }

  //@ts-ignore
  onEmojiClick = (event, emojiObject) => {
    const ref = this.contentRef.current
    ref.focus()
    const start = this.state.createPostDescription.substring(0, ref.selectionStart)
    const end = this.state.createPostDescription.substring(ref.selectionStart)
    const content = start + emojiObject.emoji;
    this.setState({ createPostDescription: content })
    ref.selectionEnd = start.length + emojiObject.emoji.length
  };
  //editor change
  onChangeText = (editorState: any) => {
    this.setState({ createPostDescription: editorState });
    const contentState = editorState.getCurrentContent();
    // console.log(contentState, "contentState")
    const raw = convertToRaw(contentState);
    const name = raw?.blocks.map((line: any) => line?.text);
    console.log(name)
    var stringValue = ""
    name.map((item: any) => {
      stringValue = stringValue + item + "\n"
      return stringValue
    })
    this.setState({ editortext: stringValue });
    // console.log([...new Intl.Segmenter().segment(raw?.blocks[0]?.text)].length, "dakdgaj")
    // console.log(raw?.blocks[0]?.text, "text");
    const raws = convertToRaw(contentState);
    let mentionedUsers = [];
    for (let key in raws.entityMap) {
      const ent = raws.entityMap[key];
      if (ent.type === "mention") {
        mentionedUsers.push(ent.data.mention);
      }
    }
    const mentiontag = mentionedUsers.map(function (followersItem: any) {
      return followersItem?.userId
    });
    let t = this.state.tagged_connection_ids;
    t.push(...mentiontag)
    this.setState({ tagged_connection_ids: t });
    console.log(this.state.tagged_connection_ids, "ttttttt-111")
  };
  onOpenChange = (_open: any) => {
    this.setState({
      open: _open
    });
  };
  getLengthOfSelectedText = () => {
    const currentSelection = this.state.createPostDescription.getSelection();
    const isCollapsed = currentSelection.isCollapsed();

    let length = 0;

    if (!isCollapsed) {
      const currentContent = this.state.createPostDescription.getCurrentContent();
      const startKey = currentSelection.getStartKey();
      const endKey = currentSelection.getEndKey();
      const startBlock = currentContent.getBlockForKey(startKey);
      const isStartAndEndBlockAreTheSame = startKey === endKey;
      const startBlockTextLength = startBlock.getLength();
      const startSelectedTextLength = startBlockTextLength - currentSelection.getStartOffset();
      const endSelectedTextLength = currentSelection.getEndOffset();
      const keyAfterEnd = currentContent.getKeyAfter(endKey);
      console.log(currentSelection)
      if (isStartAndEndBlockAreTheSame) {
        length += currentSelection.getEndOffset() - currentSelection.getStartOffset();
      } else {
        let currentKey = startKey;

        while (currentKey && currentKey !== keyAfterEnd) {
          if (currentKey === startKey) {
            length += startSelectedTextLength + 1;
          } else if (currentKey === endKey) {
            length += endSelectedTextLength;
          } else {
            length += currentContent.getBlockForKey(currentKey).getLength() + 1;
          }

          currentKey = currentContent.getKeyAfter(currentKey);
        };
      }
    }

    return length;
  }

  handleBeforeInput = () => {
    if (this.state?.accountDetails?.attributes?.account?.data?.attributes?.account_plan === "free") {
      const currentContent = this.state.createPostDescription.getCurrentContent();
      const currentContentLength = currentContent.getPlainText('').length;
      const selectedTextLength = this.getLengthOfSelectedText();

      if (currentContentLength - selectedTextLength > 1000 - 1) {
        // this.infoComingSoon()

        return 'handled';
      }
    }
  }

  handlePastedText = (pastedText: string | any[]) => {
    if (this.state?.accountDetails?.attributes?.account?.data?.attributes?.account_plan === "free") {
      const currentContent = this.state.createPostDescription.getCurrentContent();
      const currentContentLength = currentContent.getPlainText('').length;
      const selectedTextLength = this.getLengthOfSelectedText();

      if (currentContentLength + pastedText.length - selectedTextLength > 1000) {
        console.log('you can type max ten characters');
        // this.infoComingSoon()

        return 'handled';
      }
    }
  }

  renderCreatePost = () => {
    const { userProfile } = this.state;
    const { MentionSuggestions } = this.mentionPlugin;
    const plugins = [this.mentionPlugin, emojiPlugin];
    const { GetConnectionArrayAcc } = this.state
    const mentions = GetConnectionArrayAcc?.map(function (followersItem: any) {
      return ({
        name: ` @${followersItem?.attributes?.full_name} `,
        avatar: followersItem?.attributes?.photo,
        userId: followersItem?.attributes?.account_id
      })
    })
    const onSearchChange = ({ value }: { value: any }) => {
      console.log(value, "value")
      this.setState({
        suggestions: defaultSuggestionsFilter(value, mentions)
      });
    };
    const focus = () => {
      this.editor.focus();
    };

    return (
      <div style={{ borderWidth: "0px", borderRadius: 4 }}>
        <Card style={{ borderWidth: "0px" }}>
          <Card
            bodyStyle={{ flexDirection: "row", display: "flex", padding: "10px", alignItems: "center" }}
            style={{ borderWidth: "0px" }}>
            <Card.Meta
              style={{ width: "80%" }}
              avatar={
                <Avatar
                  // src={`${this.state.loginUser.profile_pic}`}
                  src={`${userProfile?.photo}`}
                  size={40}
                  style={{ marginTop: "8px" }}
                />
              }
              title={
                <div className="margin-top5">{`${userProfile?.role === "business" ? userProfile?.company_name : userProfile?.full_name}`}</div>
              }
              description={
                <p className="margin-top-10">
                  {userProfile?.business ? (
                    <Text style={{ color: "#757575" }}>
                      {userProfile?.business +
                        ", " +
                        userProfile?.business_industry}
                    </Text>
                  ) : null}
                  {
                    userProfile ? (
                      <Text style={{ color: "#757575" }}>
                        {this.getDomainSkills(userProfile?.domain_skills)}
                      </Text>
                    ) : null}
                  {this.state.loginUser.role &&
                    this.state.loginUser.role !== "personal" &&
                    userProfile &&
                    userProfile.attributes &&
                    userProfile.attributes.location ? (
                    <Text style={{ color: "#757575" }}>
                      {userProfile.attributes.location}
                    </Text>
                  ) : null}
                </p>
              }
            />
          </Card>
          {this.state.isloader ? (
            <Card style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
              <Spin />
            </Card>
          ) : (
            <>
              <div style={{ borderWidth: "0px", borderRadius: 4 }}>
                <div className='editor' onClick={focus}>
                  <Editor
                    editorState={this.state.createPostDescription}
                    //@ts-ignore
                    handleBeforeInput={this.handleBeforeInput}
                    //@ts-ignore
                    handlePastedText={this.handlePastedText}
                    onChange={this.onChangeText}
                    placeholder="Text here..."
                    plugins={plugins}
                    ref={(element) => {
                      this.editor = element;
                    }}
                  />
                  <MentionSuggestions
                    onSearchChange={onSearchChange}
                    suggestions={this.state.suggestions}
                    onOpenChange={this.onOpenChange}
                    open={this.state.open}
                  />
                </div>
              </div>

              {!this.state.location && <div style={{ marginLeft: 5, marginTop: 10, display: 'flex', alignItems: 'center' }}>
                {this.state.address && <img src={locationIcon} alt="" width={14} />}
                <Text style={{ color: "#757575", marginLeft: 18, fontSize: 14, fontWeight: 600 }}>  {this.state.address && this.state.address}</Text>
              </div>}
              {
                this.state.getMentionsArray.length ?
                  <div style={{ marginLeft: 5, marginTop: 10, display: 'flex', alignItems: 'center' }}>
                    {
                      this.state.getMentionsArrayList.map((item: any, index: any) =>
                        <Text key={index} style={{ color: "#757575", marginLeft: 18, fontSize: 14, fontWeight: 600 }}>
                          @{item}{this.state.getMentionsArray.length - 1 > index ? ", " : null}
                        </Text>)
                    }
                  </div> : null
              }
              <EmojiSuggestions />
              {this.state.AllMedia.length ?
                <Row style={{ paddingTop: "10px", width: '100%' }}>
                  {this.renderMedia(this.state.AllMedia)}
                </Row> : null}
              {this.state.editortext.length >= 1000 && (
                this.state.accountDetails?.attributes?.account?.data?.attributes?.account_plan === "free" &&
                <Row style={{ padding: "10px", }}>
                  <Text style={{ color: "red" }}>
                    You have used your 1000 words and one media per post. Please
                    upgrade to premium account to avail benefit to post without
                    any word or media limit.
                    <Text onClick={() => { this.props.history.push({ pathname: '/Premium/Account' }) }}
                      style={{ color: "#005574", marginLeft: "10px", cursor: "pointer" }}>
                      {"Upgrade to Premium Account."}
                    </Text>
                  </Text>
                </Row>
              )}
            </>
          )}
        </Card>
        {this.state.isloader ? (
          <Card style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
            {/* <Spin /> */}
          </Card>
        ) : (
          <React.Fragment>
            <Card
            // bodyStyle={{ flexDirection: "row", display: "flex", alignItems: "center", padding: "10px" }}
            >
              <Row style={{ alignItems: 'center', justifyContent: 'space-between' }}>
                {
                  this.state.tagsOpen || this.state.location ?

                    this.state.tagsOpen ?
                      <Col
                        xs={24}
                        xl={9}
                        md={9}
                        sm={9}
                      >

                        {this.state.tagsOpen &&
                          <div style={{ display: 'flex', width: "100%", alignItems: "center" }}>
                            <img src={atIcon} alt="" width={20} height={20} />
                            <Mentions
                              onChange={(value) => this.onChangeTags(value)}
                              onSelect={(value) => this.onSelectTags(value)}
                              placement="top"
                              style={{ border: 'none', width: '170px' }}
                            >
                              {this.state.followers && this.state.followers.map((followersItem: any) => (
                                <Option
                                  value={followersItem?.attributes?.role === "business" ?
                                    followersItem?.attributes?.profile?.data?.attributes?.company_name :
                                    followersItem?.attributes?.full_name
                                  }
                                  key={followersItem?.attributes?.account_id}
                                  style={{
                                    borderBottom: '1px solid rgb(189, 189, 189, 0.4)',
                                    // marginBottom: 5,
                                    width: '100%',
                                    minWidth: '260px',
                                    maxHeight: 280,
                                    height: '100%',
                                    padding: "4px"
                                  }}
                                >
                                  <div style={{ padding: 8 }}>
                                    <span>
                                      <Avatar
                                        src={followersItem?.attributes?.photo}
                                      />
                                    </span>
                                    <span style={{ marginLeft: '15px' }}>
                                      {followersItem?.attributes?.role === "business" ?
                                        followersItem?.attributes?.profile?.data?.attributes?.company_name :
                                        followersItem?.attributes?.full_name
                                      }
                                    </span>
                                  </div>
                                </Option>
                              ))
                              }
                            </Mentions>
                          </div>
                        }
                      </Col>
                      :
                      <Col
                        xs={24}
                        xl={12}
                        md={12}
                        sm={12}
                      >
                        {this.state.location && this.getUserLocattion()}
                      </Col>
                    :
                    <Col
                      xs={24}
                      xl={12}
                      md={12}
                      sm={12}
                      style={{ padding: "10px" }}
                    >
                      {this.state.accountDetails?.attributes?.account?.data?.attributes?.account_plan === "free" &&
                        <Text
                          style={{
                            fontSize: "14px",
                            marginRight: "5px",
                            color: "grey",
                            width: "60%",
                          }}
                        >{this.state.editortext.length >= 1000 ? `0 character left` : `${1000 -
                          this.state.editortext.length} character left`}</Text>}
                    </Col>
                }

                <Col
                  xs={24}
                  sm={12}
                  md={12}
                  xl={12}
                  style={{
                    display: "flex", justifyContent: "flex-end",
                    padding: "10px", width: '100%'
                  }}>

                  <EmojiSelect />
                  <Upload
                    disabled={
                      this.state?.accountDetails?.attributes?.account?.data?.attributes?.account_plan === "free" &&
                      this.state.AllMedia?.length == 1
                    }
                    name='file'
                    showUploadList={false}
                    // multiple
                    // maxCount={5}
                    multiple={this.state?.accountDetails?.attributes?.account?.data?.attributes?.account_plan === "free" ? false : true}
                    maxCount={this.state?.accountDetails?.attributes?.account?.data?.attributes?.account_plan === "free" ? 1 : 5}
                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                    beforeUpload={(file) => this.beforeUpload(file)}
                    onChange={this.handleImageChange}
                  >
                    <Button
                      className="post_btn"
                      type="ghost"
                      shape="circle"
                      disabled={
                        this.state?.accountDetails?.attributes?.account?.data?.attributes?.account_plan === "free" ?
                          this.state.AllMedia.length == 1 ? true : false : false
                      }
                      icon={<img src={photoIcon} alt="" width={20} />}
                    />
                  </Upload>
                  <Button
                    className="post_btn"
                    type="ghost"
                    shape="circle"
                    onClick={() => this.setState({
                      tagsOpen: !this.state.tagsOpen,
                      location: false
                    })}
                    icon={<img src={atIcon} alt="" width={20} />}
                  />

                  <Button
                    className="post_btn"
                    type="ghost"
                    shape="circle"
                    loading={this.state.loadLocation}
                    icon={<img src={locationIcon} alt="" width={14} />}
                    onClick={() => { this.setState({ tagsOpen: false, location: !this.state.location }) }}
                  />
                  <Button
                    className="post_btn"
                    type="ghost"
                    shape="circle"
                    disabled={
                      this.state.accountDetails?.attributes?.account?.data?.attributes?.account_plan === "free" ? (this.state.editortext.length > 1000 ? true : this.state?.editortext?.trim()?.length > 0 ? false : true) : this.state?.editortext?.trim()?.length > 0 ? false : true
                    }
                    icon={<img src={sendIcon} alt="" width={"100%"} />}
                    onClick={() => this.handleSubmit()}
                  />
                </Col>

              </Row>
            </Card>
          </React.Fragment>
        )}
      </div>
    );
  };
  //editpostrender
  rendereditPost = () => {
    const { MentionSuggestions } = this.mentionPlugin;
    const plugins = [this.mentionPlugin, emojiPlugin];
    const { GetConnectionArrayAcc, followersTag } = this.state

    const mentions = GetConnectionArrayAcc?.map(function (followersItem: any) {
      return ({
        name: ` @${followersItem?.attributes?.full_name} `,
        avatar: followersItem?.attributes?.photo,
        userId: followersItem?.attributes?.account_id
      })
    })
    const onSearchChange = ({ value }: { value: any }) => {
      this.setState({
        suggestions: defaultSuggestionsFilter(value, mentions)
      });
    };
    const focus = () => {
      this.editor.focus();
    };

    return (
      <div style={{ borderWidth: "0px", borderRadius: 4 }}>
        <Card style={{ borderWidth: "0px" }}>
          <Row style={{ padding: "10px" }}>
            <div style={{ borderWidth: "0px", borderRadius: 4, width: '100%' }}>
              <div className='editor' onClick={focus} >
                <Editor
                  editorState={this.state.createPostDescription}
                  //@ts-ignore
                  handleBeforeInput={this.handleBeforeInput}
                  //@ts-ignore
                  handlePastedText={this.handlePastedText}
                  placeholder="Text here..."
                  onChange={this.onChangeText}
                  plugins={plugins}
                  ref={(element) => {
                    this.editor = element;
                  }}
                />
                <MentionSuggestions
                  onSearchChange={onSearchChange}
                  suggestions={this.state.suggestions}
                  onOpenChange={this.onOpenChange}
                  open={this.state.open}
                />
              </div>
            </div>
            {this.state.location &&
              <div
                style={{
                  marginLeft: 5,
                  marginTop: 10,
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                {this.state.address && <img src={locationIcon} alt="" width={20} />}
                <Text
                  style={{
                    color: "#757575",
                    marginLeft: 18,
                    fontSize: 14,
                    fontWeight: 600
                  }}
                >
                  {this.state.address && this.state.address}
                </Text>
              </div>
            }
            {this.state.getMentionsArray.length ?
              <div
                style={{
                  width: "100%",
                  marginLeft: 5,
                  marginTop: 10,
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                {
                  this.state.getMentionsArrayList.map((item: any, index: any) =>
                    <Text
                      key={index}
                      style={{
                        color: "#757575",
                        marginLeft: 18,
                        fontSize: 14,
                        fontWeight: 600
                      }}
                    >
                      @{item}{this.state.getMentionsArray.length - 1 > index ? ", " : null}
                    </Text>)
                }
              </div> : null}
            <EmojiSuggestions />
            {this.state.AllMedia.length ?
              <Row style={{ paddingTop: "10px", width: '100%' }}>
                {this.renderMedia(this.state.AllMedia)}
              </Row> : null}
            {this.state.createPostDescription.length >= 1000 ? (
              this.state.accountDetails?.attributes?.account?.data?.attributes?.account_plan === "free" &&
              <Row style={{ padding: "10px", }}>
                <Text style={{ color: "red" }}>
                  You have used your 1000 words and one media per post. Please
                  upgrade to premium account to avail benefit to post without
                  any word or media limit.
                  <Text onClick={() => { this.props.history.push({ pathname: '/Premium/Account' }) }}
                    style={{ color: "#005574", marginLeft: "10px", cursor: "pointer" }}>
                    {"Upgrade to Premium Account."}
                  </Text>
                </Text>
              </Row>
            ) : null}

          </Row>
        </Card>
        <Card
          style={{
            // position: "absolute",
            width: "100%",
            top: "26px"
          }}
          bodyStyle={{ flexDirection: "row", display: "flex", alignItems: "center", padding: "10px" }}>
          {this.state.isloader ? <Spin /> :
            <Row style={{ alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
              {
                this.state.tagsOpen || this.state.location ?

                  this.state.tagsOpen ?
                    <Col
                      xs={24}
                      xl={12}
                      md={12}
                      sm={12}
                    >
                      {this.state.tagsOpen &&
                        <div style={{ display: 'flex', width: "100%", alignItems: "center" }}>
                          <img src={atIcon} alt="" width={20} height={20} />
                          <Mentions
                            onChange={(value) => this.onChangeTags(value)}
                            onSelect={(value) => this.onSelectTags(value)}
                            placement="top"
                            style={{ border: 'none' }}
                          >
                            {this.state.followers && this.state.followers.map((followersItem: any) => (
                              <Option
                                value={followersItem?.attributes?.role === "business" ?
                                  followersItem?.attributes?.profile?.data?.attributes?.company_name :
                                  followersItem?.attributes?.full_name
                                }
                                key={followersItem?.attributes?.account_id}
                                style={{
                                  borderBottom: '1px solid #BDBDBD',
                                  marginBottom: 5,
                                  width: '100%',
                                  minWidth: '260px',
                                  maxHeight: 280,
                                  height: '100%',
                                  padding: "4px"
                                }}
                              >
                                <div style={{ padding: 8 }}>
                                  <span>
                                    <Avatar
                                      src={followersItem?.attributes?.photo}
                                    />
                                  </span>
                                  <span style={{ marginLeft: '15px' }}>

                                    {followersItem?.attributes?.role === "business" ?
                                      followersItem?.attributes?.profile?.data?.attributes?.company_name :
                                      followersItem?.attributes?.full_name
                                    }
                                  </span>
                                </div>
                              </Option>
                            ))
                            }
                          </Mentions>
                        </div>
                      }
                    </Col>
                    :
                    <Col
                      xs={24}
                      xl={12}
                      md={12}
                      sm={12}
                    >
                      {this.state.location && this.getUserLocattion()}
                    </Col>
                  :
                  <Col
                    xs={24}
                    xl={12}
                    md={12}
                    sm={12}
                    style={{ padding: "10px" }}
                  >
                    {this.state?.accountDetails?.attributes?.account?.data?.attributes?.account_plan == "free" && <Text
                      style={{
                        fontSize: "14px",
                        marginRight: "5px",
                        color: "grey",
                      }}
                    >{(this.state.editortext).length >= 1000 ? "" : `${1000 -
                      (this.state.editortext).length} character left`}</Text>
                    }
                  </Col>
              }


              <Col
                xs={24}
                sm={12}
                md={12}
                xl={12}
                style={{
                  display: "flex", justifyContent: "flex-end",
                  padding: "10px", width: '100%'
                }}
              // className='MyProfile_createPost_Button'
              >
                {this.state.isloadEditPost ? <Spin /> :
                  <>
                    <EmojiSelect />
                    <Upload
                      name='file'
                      disabled={
                        this.state?.accountDetails?.attributes?.account?.data?.attributes?.account_plan === "free" &&
                        this.state.AllMedia?.length == 1
                      }
                      showUploadList={false}
                      multiple={false}
                      // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                      beforeUpload={(file) => this.beforeUpload(file)}
                      onChange={this.handleImageChange}
                    >
                      <Button
                        className="post_btn"
                        type="ghost"
                        shape="circle"
                        icon={<img src={photoIcon} alt="" width={20} />}
                      />
                    </Upload>
                    <Button className="post_btn"
                      type="ghost"
                      shape="circle"
                      onClick={() => this.setState({
                        tagsOpen: !this.state.tagsOpen,
                        // tagged_connection_ids: []
                      })}
                      icon={<img src={atIcon} alt="" width={20} />}
                    />
                    <Button
                      className="post_btn"
                      type="ghost"
                      shape="circle"
                      icon={<img src={locationIcon} alt="" width={14} />}
                      onClick={() => { this.setState({ location: !this.state.location }) }}
                    />
                    <Button
                      className="post_btn"
                      type="ghost"
                      shape="circle"
                      disabled={this.state.accountDetails?.attributes?.account?.data?.attributes?.account_plan === "free" ? (this.state.editortext.length >= 1000 ? true : false) : false}
                      icon={<img src={sendIcon} alt="" width={"100%"} />}
                      onClick={() => this.handleEditSubmit(this.state.editData?.id)}
                    />
                  </>
                }
              </Col>
            </Row>}
        </Card>
      </div>
    );
  };

  //@ts-ignore
  onChange = (value) => {
    let data = this.state.createPostDescription + value

    this.setState({ createPostDescription: value });
  };

  // Customizable Area End

  render() {
    return (
      <div className="_home_responsive_" style={{ backgroundColor: "#f6f6f6 !important", padding: "20px" }}>
        {this.redirectUser()}
        <Row gutter={[20, 20]}>
          {/* <div className="customHome_container"> */}
          <Col xs={24} sm={24} md={24} lg={7} xl={7}>
            {this.renderUSerProfile()}
            {this.renderPostConnectionDetails()}
            {this.state.userProfile?.description ? (<Card bodyStyle={{ textAlign: "center" }}
              style={{ borderRadius: 4 }}
            >
              <Card.Meta
                description={this.state.userProfile?.description ?
                  this.state.userProfile?.description : null}
              />
              {this.state.userProfile?.description?.length > 100 && (
                <Button type="link">
                  <Text className="learn-more">{this.btnLearnMore}</Text>
                </Button>
              )}
            </Card>) : <></>}

            <CommonListItem
              isLoading={this.state.loadcommunity}
              data={this.state?.joinnedCommunities ? this.state?.joinnedCommunities?.slice(0, 4) : []}
              onViewAll={this.communityForum}
              label={this.labelCommunityForum}
              isCommunityForum={true}
              description={true}
              count={true}
              title={true}
              onItemPress={(id: any) => this.redirectCommunity(id)}
            />
            <CommonListItem
              isLoading={this.state.loadUpcoming}
              data={this.state?.workAnniversaries ?
                this.state?.workAnniversaries : []}
              onViewAll={this.workAnniversaries}
              label={this.labelWorkAnniversaries}
              isFromWorkAnniversaries={true}
              description={true}
              count={true}
              title={true}
              onItemPress={(id: any) => this.onItemPress(id)}

            />
            <CommonListItem
              isLoading={this.state.loadUpcoming}
              data={this.state?.birthAnniversaries ?
                this.state?.birthAnniversaries : []}
              onViewAll={this.birthAnniversaries}
              label={this.labelBirthAnniversaries}
              isBirthAnniversaries={true}
              description={true}
              count={true}
              title={true}
              onItemPress={(id: any) => this.onItemPress(id)}

            />
          </Col>

          <Col xs={24} sm={24} md={24} lg={10} xl={10}>
            <Card
              style={{ borderRadius: 4 }}
              bodyStyle={{ paddingTop: "10px", paddingBottom: "10px" }}
              hidden={this.state.isCreatePostVisible}
            >
              <Row wrap={true}>
                <Col xs={24} sm={16} md={16} lg={16} xl={16}>
                  <div className="flex-row">
                    <Avatar src={this.state?.userProfile?.photo ? this.state?.userProfile?.photo : require("../assets/image_profile.png")} size={40} />
                    <Button
                      style={{ marginLeft: "10px", fontSize: "16px", width: "80%", textAlign: "left", color: "grey", }}
                      type="text"
                      shape="round"
                      onClick={() => this.setState({
                        isCreatePostVisible: true,
                        createPostDescription: EditorState.createEmpty()
                      })}>
                      {this.placeHolderWhatOnMind}
                    </Button>
                  </div>
                </Col>
                <Col span={8} xl={8} lg={8} md={8} sm={8} xs={24} className="flex-row" style={{ justifyContent: "flex-end" }}>
                  <Button style={{ borderWidth: "0px", color: "rgb(201, 200, 205)" }}
                    type="ghost"
                    shape="circle"
                    /* @ts-ignore */
                    icon={<img src={faceIcon} alt="" width={20} />}
                    size={"middle"}
                    onClick={() => this.setState({
                      isCreatePostVisible: !this.state.isCreatePostVisible,
                      isEmojiPickerEnable: !this.state.isEmojiPickerEnable
                    })}
                  />
                  <Upload
                    name='file'
                    showUploadList={false}
                    multiple={this.state?.accountDetails?.attributes?.account?.data?.attributes?.account_plan === "free" ? false : true}
                    maxCount={this.state?.accountDetails?.attributes?.account?.data?.attributes?.account_plan === "free" ? 1 : 5}
                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                    beforeUpload={async (file) => {
                      await this.beforeUpload(file)
                      this.setState({
                        isCreatePostVisible: !this.state.isCreatePostVisible,
                      })
                    }}
                    onChange={this.handleImageChange}
                  >
                    <Button style={{ borderWidth: "0px", color: "grey" }}
                      type="ghost"
                      disabled={
                        this.state?.accountDetails?.attributes?.account?.data?.attributes?.account_plan === "free" &&
                        this.state.AllMedia.length == 1
                      }
                      shape="circle"
                      icon={<img src={photoIcon} alt="" width={20} />}
                      size={"middle"}
                    />
                  </Upload>
                  <Button style={{ borderWidth: "0px", color: "rgb(201, 200, 205)" }}
                    type="ghost"
                    shape="circle"
                    icon={<img src={atIcon} alt="" width={20} />}
                    size={"middle"}
                    onClick={() => {
                      this.setState({
                        isCreatePostVisible: !this.state.isCreatePostVisible,
                        tagsOpen: !this.state.tagsOpen,
                      })
                    }}
                  />
                  <Button style={{ borderWidth: "0px", color: "rgb(201, 200, 205)" }}
                    type="ghost"
                    shape="circle"
                    icon={<img src={locationIcon} alt="" width={14} />}
                    size={"middle"}
                    onClick={() => {
                      this.setState({
                        isCreatePostVisible: !this.state.isCreatePostVisible,
                        location: !this.state.location
                      })
                    }}
                  />
                </Col>
              </Row>
            </Card>
            {(this.state.isCreatePostVisible || this.state.AllMedia.length != 0 && !this.state.editData) ? this.renderCreatePost() : null}
            {this.state.isloader ? (<div style={{ display: 'flex', justifyContent: 'center' }}>
              <Spin />
            </div>) : (
              <div >
                <List
                  className="home-posts"
                  dataSource={this.state.postList ? this.state.postList : []}
                  renderItem={(item: any, index: number) => {
                    return (

                      <div key={item} style={{ margin: '20px 0px' }}>
                        {this.renderPost(item)}

                        {this.state.postList.length - 1 === index ?
                          this.state.postPaginationLoading ? <div style={{
                            textAlign: 'center',
                            marginTop: 12
                          }}><Spin /></div> :
                            <div
                              style={{
                                textAlign: 'center',
                                marginTop: 12,
                                cursor: 'pointer'
                              }}
                              onClick={() => {
                                this.setState({
                                  postPagination: this.state.postPagination + 1,
                                  postPaginationLoading: true,
                                }, () => this.getHomeFeedPosts())
                              }}>
                              Click to see more posts
                            </div> : null
                        }
                      </div>
                    )
                  }}
                />
              </div>

            )}
            {this.showReportModal()}
            {this.showInvitationModal()}
            {this.showConnectionModal()}
          </Col>

          <Col xs={24} sm={24} md={24} lg={7} xl={7}>
            <CommonListItem
              isLoading={this.state.loadpeopleKnow}
              data={this.state?.peoplemayKnow ? this.state?.peoplemayKnow : []}
              onReload={() => this.getPeopleYouknow()}
              onViewAll={this.onViewAllInvite}
              label={this.labelKnownPeople}
              isFromKnownPeople={true}
              onClickInvitation={this.handleConnectionModal}
              onItemPress={(id: any) => this.onItemPress(id)}
            />
            <CommonListItem
              isLoading={this.state.loadInvitation}
              data={this.state?.allInvitations?.data ? this.state?.allInvitations.data : []}
              // data={this.state.knownPeople}
              onViewAll={this.onViewAllInvitations}
              label={this.labelInvitations}
              isInvitation={true}
              onClickInvitation={this.handleInvitationModal}
              onItemPress={(id: any) => this.onItemPress(id)}
            />
          </Col>

          {/* </div> */}
          {this.renderCreateProstPopup()}
          {this.renderEditPostPopup()}
        </Row>
      </div>

    );
  }
}
// Customizable Area Start
// Customizable Area End
