import React, { Component } from "react";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import confirm from "../../alert/src/alert.web";
import AlertError from "../../alert/src/alertError.web";
import { RouterProps } from "react-router";
export const configJSON = require("./config");
export interface Props extends RouterProps {
  navigation: any;
  id: string;
}
interface S {
  loading: boolean;
  isRegistration: boolean;
  bussiness_profile: boolean;
  data: any;
}
interface SS {
  id: any;
}

export default class CreateProfileOverviewController extends BlockComponent<
  Props,
  S,
  SS
> {
  createAccountApiCallId: any;
  formref = React.createRef();
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.receive = this.receive.bind(this);
    this.state = {
      bussiness_profile: true,
      loading: true,
      isRegistration: true,
      data: this.props.history ? this.props.history.location.state : "",
    };
  }
  handleAddProfileOverviewData = (data: object) => {
    console.log(data);
    let updated_data = { ...this.state.data, ...data };
    this.props.history.push({
      pathname: "/profile_history",
      state: updated_data,
    });
  };
}
