import React from "react"
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { RouterProps } from "react-router";
import { Redirect } from "react-router-dom";
import { getStorageData } from "../../../../framework/src/Utilities";
export const configJSON = require("./../config");
export interface Props extends RouterProps {
    navigation: any;
    id: string;
    // Customizable Area Start
    classes: any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    redirect: boolean;
    tabValue: string;
    pageLoader: boolean;
    tabData: any;
    questionData: any;
    questionName: any;
    disabledfield: number;
    alertView: boolean;
    saveAndNextButton: boolean;
    accountDetails: any
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    // Customizable Area End
}

export default class QuestionnaireController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getQuestionnaireDetailsApiId: string = "";
    questionnaireApiId: string = "";
    // Customizable Area End 
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.CountryCodeMessage),
        ];

        this.state = {
            redirect: false,
            tabValue: '',
            pageLoader: true,
            tabData: [],
            questionData: [],
            questionName: [],
            disabledfield: 0,
            alertView: false,
            saveAndNextButton: false,
            accountDetails: {}
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }
    async componentDidMount() {
        super.componentDidMount();
        const rest = await getStorageData("accountData")
        this.setState({ accountDetails: rest });

        let token = await localStorage.getItem("token");
        if (token && token !== null && token !== undefined) {
            this.setState({ redirect: false });
            this.getQuestionnaireDetails();
        } else {
            this.setState({ redirect: true });
        }
    }

    redirectUser() {
        if (this.state.redirect) {
            return <Redirect to="/" />;
        }
    }

    async receive(from: String, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("on recieive==>" + JSON.stringify(message));

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            var errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if (apiRequestCallId && responseJson) {
                if (apiRequestCallId === this.getQuestionnaireDetailsApiId) {
                    console.log("getData", responseJson);
                    if (responseJson.data) {
                        const tabObj = responseJson.data.attributes?.rating;
                        const filteredObj = Object.entries(tabObj).filter((val) => val[0] !== "General Business Rating" && val[0] !== "General Personal Rating")
                        const customerArr = Object.entries(tabObj).filter((val) => val[0] == "Personal Customer");
                        const comunityArr = Object.entries(tabObj).filter((val) => val[0] == "Personal Customer");
                        console.log("filtered obj", filteredObj)
                        const tabNewData = {
                            leaderShip: tabObj?.Leader || tabObj?.Employee || [],
                            team: tabObj?.Team || [],
                            customer: customerArr[0] ? customerArr[0][1] : [],
                            community: comunityArr[0] ? comunityArr[0][1] : []
                        }
                        this.setState({
                            tabData: filteredObj,
                            tabValue: filteredObj[0][0],
                            pageLoader: false
                        })
                    }
                } else if (apiRequestCallId === this.questionnaireApiId) {
                    console.log("update", responseJson);
                    this.handleAlert(this.state.tabValue)

                }
            }
        } else if (getName(MessageEnum.SessionResponseMessage) === message.id) {
            let requesterId = message.getData(
                getName(MessageEnum.SessionRequestedBy)
            );
        }

        // Customizable Area End

    }

    getQuestionnaireDetails = async () => {
        const token = localStorage.getItem("token");
        const header = {
            "Content-Type": configJSON.getPostsApiContentType,
            token: token,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        console.log("22222222", requestMessage)

        this.getQuestionnaireDetailsApiId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            'bx_block_accountscoreranking/ratings/account_rating'
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            header
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            'get'
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    };

    handleSaveAndNext = (isEdit: boolean) => {
        if (isEdit) {
            this.setState({ saveAndNextButton: false })
        } else {
            this.setState({ saveAndNextButton: true })
        }
    }

    saveChangedQuestionnaire = (data: any, tabname: string) => {
        console.log(data);
        const token = localStorage.getItem("token");
        const header = {
            "Content-Type": configJSON.getPostsApiContentType,
            token: token,
        };
        let body = {
            data: {
                "attributes": {
                    "rating": {
                        [tabname]: [{ ...data }]
                    }
                }
            },
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.questionnaireApiId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_customizablequestionnaire/questionnaire/update_questionnaire`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            header
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            'put'
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(body)
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    };
    // Customizable Area Start

    handleChanges = (val: any) => {
        if (val === "edit")
            this.setState({ disabledfield: this.state.disabledfield + 1 })
        if (val === "save")
            this.setState({ disabledfield: this.state.disabledfield - 1 })
    }
    account = async () => {
        const role = await getStorageData('loginUser');
        console.log("checking role.....", role);
        if (role?.role === "personal") {
            this.props.history.push({
                pathname: "/MyPersonalProfile/Profile",
            });
        } else {
            this.props.history.push({
                pathname: "/MyBusiness/Profile",
            });
        }
        // else if (role === "business") {
        //   props.history.push({
        //     pathname: "/MyBusiness/Profile",
        //   });
        // }
    }

    handleAlert = (val: string) => {
        if (val) {
            this.setState({ alertView: !this.state.alertView })
        } else {
            this.setState({ alertView: !this.state.alertView })
            this.account();
        }
    }
    // Customizable Area End
}
