Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "SelfdestructMessaging";
exports.labelBodyText = "SelfdestructMessaging Body";

exports.btnExampleTitle = "CLICK ME";

// chat 

exports.GETMethod = "GET";
exports.POSTMethod = "POST";
exports.PUTMethod = "PUT";
exports.DELETEMethod = "DELETE";
exports.APPLICATIONJSONContentType = "application/json";
exports.MULTIPARTFormData = "multipart/form-data";

exports.getHiddenPostsApiEndpoint = "bx_block_posts/posts/hidden_posts";
exports.unHideApiEndpoint = "bx_block_posts/posts";

exports.getCategoriesEndPoint = "bx_block_categories/categories";
exports.getNetConnectionEndPoint = "bx_block_request_management/requests";
exports.unFollowEndPoint = "bx_block_follow/follows";
exports.removeConnectionEndPoint = "bx_block_request_management/requests/";
exports.accountEndpoint = "account_block/accounts/";
exports.sendLinkEndPoint = "send_link";


// Customizable Area End