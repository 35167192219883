import React, { useEffect, useState } from 'react';
import { Space, Typography, Row, Col, Spin, Empty } from 'antd';
const { Title, Text } = Typography;
const AppliedPolicy = (props: any) => {
    // Customizable Area Start
    const { policiesData, isloader } = props;

    const [readMore, setReadMore] = useState([
        {
            policy: true
        },
        {
            policy: true
        },
        {
            policy: true
        },
        {
            policy: true
        },
        {
            policy: true
        },
        {
            policy: true
        },
    ]);

    const handleReadMore = (id: number) => {
        let newArr = [...readMore];
        newArr[id].policy = !newArr[id].policy;
        setReadMore(newArr);
    }

    return (
        <Space direction='vertical' style={{ width: "100%" }}>
            {isloader ? <Spin />
                : <Row gutter={[16, 16]}>
                    {
                        policiesData?.map((item: any, id: number) => {
                            return (
                                <Col xs={{ span: 24 }} lg={{ span: 24 }} style={{ width: "100%", display: "flex", flexDirection: "column" }} key={item?.id}>
                                    <Title level={5} style={{ fontWeight: 500, fontSize: 16 }}>
                                        {item?.attributes?.title}
                                    </Title>
                                    <div style={{ width: "100%", backgroundColor: "#ffffff", padding: "20px 20px 10px 20px" }}>
                                        {
                                            (readMore[id].policy ? item?.attributes?.subheadings?.data?.slice(0, 2) : item?.attributes?.subheadings?.data)?.map((value: any, id: number) => {
                                                return (
                                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                                        <Text strong style={{ fontSize: "15px", fontWeight: 500 }}>{value?.attributes?.title}</Text>
                                                        <div dangerouslySetInnerHTML={{ __html: value?.attributes?.description }} style={{
                                                            margin: "0px", color: "rgba(151, 151, 151, 1)",
                                                            fontSize: '14px', fontWeight: 500
                                                        }}></div>
                                                    </div>
                                                )
                                            })
                                        }
                                        {
                                            readMore[id].policy && <span style={{ color: "#00698f", cursor: "pointer" }} onClick={() => handleReadMore(id)}>Read more</span>
                                        }
                                        {
                                            readMore[id].policy === false && <span style={{ color: "#00698f", cursor: "pointer" }} onClick={() => handleReadMore(id)}>Read less</span>
                                        }
                                    </div>
                                </Col>
                            )
                        })
                    }
                    {/* {
                        policiesData ?
                            <Col xs={{ span: 24 }} lg={{ span: 24 }} style={{ width: "100%", display: "flex", flexDirection: "column" }} key={policiesData?.id}>
                                <Title level={5}>
                                    {policiesData?.attributes?.title}
                                </Title>
                                <div style={{ width: "100%", backgroundColor: "#ffffff", padding: "20px 20px 10px 20px" }}>
                                    {
                                        policiesData?.attributes?.subheadings?.data?.map((item: any) => {
                                            return (
                                                <div style={{ display: "flex", flexDirection: "column" }}>
                                                    <Text strong style={{ fontSize: "14px" }}>{item?.attributes?.title}</Text>
                                                    <div dangerouslySetInnerHTML={{ __html: item?.attributes?.description }} style={{ margin: "0px", color: "rgba(151, 151, 151, 1)" }}></div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </Col>
                            : !isloader && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    } */}

                </Row>
            }
        </Space>
    )
    // Customizable Area End
}

export default AppliedPolicy;
