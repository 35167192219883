import React, { Component } from "react";
// Customizable Area Start
import {
  PageHeader,
  Card,
  Row,
  Col,
  Form,
  Input,
  Typography,
  Button,
  Spin,
  Space,
  Select,
  Avatar,
  Upload,
  message
} from "antd";
const { Title } = Typography;
import { PlusCircleFilled, AimOutlined } from "@ant-design/icons";
import { logo, NETWORK_PROFILE_ING, editLogo } from "../assets";
const style = require(`../../../social-media-account/src/login.module.css`);
import "../profile.css";
import { phoneRegExp } from "../../../../framework/src/Globals";
import EditBusinessBasicController from "../EditBusinessBasicController.web";
const { Option } = Select;
// Customizable Area End
export default class EditBusinessProfileBasic extends EditBusinessBasicController {
  // Customizable Area Start
  renderBusnissProfileV2 = () => {
    console.log("1", this.state.company_nane);
    return (
      <div>
        <Row
          style={{
            minHeight: "90vh",
            alignItems: "center",
            backgroundColor: "#eff8fe"
          }}
        >
          <Col xs={0} sm={0} md={3} lg={6} xl={4} />
          <Col xs={23} sm={22} md={18} lg={15} xl={17}>
            <Title
              level={2}
              style={{
                textAlign: "center",
                marginBottom: "15px",
                marginTop: "50px"
              }}
            >
              Edit Your Business Profile
            </Title>
            <Col span={24} offset={1} className="basic_profile_card">
              {this.state.profileLoading ? (
                <Spin />
              ) : (
                <Form
                  initialValues={{
                    company_name: this.state.updateProfile.company_name
                      ? this.state.updateProfile.company_name
                      : this.state.prev_data?.company_name,
                    business: this.state.updateProfile.business
                      ? this.state.updateProfile.business
                      : this.state.updateProfile?.industry_type
                        ? this.state.updateProfile?.industry_type
                        : this.state.prev_data?.industry_type,
                    business_industry: this.state.updateProfile.business_industry
                      ? this.state.updateProfile.business_industry
                      : this.state.prev_data?.business_industry ? this.state.prev_data?.business_industry : '',
                    ownership_type: this.state.updateProfile.ownership_type
                      ? this.state.updateProfile.ownership_type
                      : this.state.prev_data?.ownership_type,
                    established_year:
                      this.state.updateProfile?.established_year
                        ? this.state.updateProfile?.established_year
                        : this.state.prev_data?.established_year,
                    founder: this.state.updateProfile.founder
                      ? this.state.updateProfile.founder
                      : this.state.prev_data?.founder,
                    contact_person: this.state.updateProfile.contact_person
                      ? this.state.updateProfile.contact_person
                      : this.state.prev_data?.contact_person,
                    num_of_employees: this.state.updateProfile.num_of_employees
                      ? this.state.updateProfile.num_of_employees
                      : this.state?.prev_data?.num_of_employees,
                    location: this.state.updateProfile?.location
                      ? this.state.updateProfile?.location
                      : this.state.prev_data?.location,
                    website: this.state.updateProfile.website
                      ? this.state.updateProfile.website
                      : this.state.prev_data?.website,
                    profile_pic: this.state.profileUrl
                      ? this.state.profileUrl : this.state?.updateProfile?.photo
                  }}
                  ref={this.profile_formref}
                  onFinish={this.handleAddProfileData}
                >
                  <Card>
                    <div
                      style={{
                        justifyContent: "center",
                        display: "flex",
                        marginBottom: "16px"
                      }}
                    >
                      <Form.Item
                        rules={[{ required: true, message: "Required field" }]}
                        name="profile_pic"
                      >
                        <Row justify="center">
                          <Upload
                            name="file"
                            showUploadList={false}
                            multiple={false}
                            beforeUpload={file => this.beforeUpload(file)}
                            onChange={this.handleImageChange}
                            onRemove={() => {
                              this.setState({ profileUrl: "" });
                            }}
                          >
                            <div
                              style={{
                                position: "relative"
                              }}
                            >
                              {this.state.profileUrl ? <Avatar size={64} src={this.state.profileUrl} style={{}} /> : <Avatar size={64} src={this.state?.updateProfile?.photo} />}
                              <img
                                src={editLogo}
                                width={22}
                                style={{
                                  position: "absolute",
                                  top: "41px",
                                  left: "45px"
                                }}
                              />
                            </div>
                            {/* {this.state.profileUrl ? (
                              <Avatar size={64} src={this.state.profileUrl} />
                            ) : (
                              <>
                                <Avatar size={64} src={this.state?.updateProfile?.photo} />
                                <Avatar
                                  style={{
                                    top: "25px",
                                    left: "-23px",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                  size={20}
                                  icon={
                                    <PlusCircleFilled translate={{}}
                                      style={{
                                        fontSize: "20px",
                                        fontWeight: "bold",
                                        color: "#005574",
                                      }}
                                    />
                                  }
                                />
                              </>
                            )} */}
                          </Upload>
                        </Row>
                      </Form.Item>
                    </div>
                    <Row gutter={12}>
                      <Col
                        span={12}
                        xs={24}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        xxl={12}
                      >
                        <Form.Item
                          rules={[
                            { required: true, message: "Required field" },
                            {
                              pattern: new RegExp(/^[a-zA-Z ]+$/i),
                              message: "input accept only alphabets"
                            }
                          ]}
                          name="company_name"
                        >
                          <Input
                            className={style.input_fields}
                            placeholder="Company Name"
                            style={{ borderWidth: 1, color: "#a9bac8" }}
                            size="large"
                          />
                        </Form.Item>
                      </Col>
                      <Col
                        span={12}
                        xs={24}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        xxl={12}
                      >
                        <Form.Item
                          rules={[
                            { required: true, message: "Required field" }
                          ]}
                          name="business"
                        >
                          <Select
                            id={"select"}
                            // style={{
                            //   padding: "0 !important", border: '1px solid #a9bac8', color: "#a9bac8", overflow: "hidden", borderRadius: "27px"
                            // }}
                            // size="large"
                            className={style.input_fields_select}
                            placeholder="Business"
                            bordered={true}
                            dropdownStyle={{
                              borderRadius: "15px",
                              border: "1px solid #a9bac8",
                              paddingTop: "0px",
                              paddingBottom: "0px",
                            }}
                            notFoundContent={
                              this.state.loading ? (
                                <Spin size="small" />
                              ) : (
                                "No Data Found "
                              )
                            }
                            onChange={this.handleSubCategory}
                          >
                            {this.state.businessIndustryList.map(
                              (item: any) => {
                                return (
                                  <option
                                    style={{
                                      borderBottom: "1px solid #7aacd6",
                                      padding: "10px 20px 10px 20px"
                                    }}
                                    value={item.name}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        width: "100%"
                                        // paddingTop: "0.7rem",
                                      }}
                                    >
                                      <div>{item.name}</div>
                                    </div>
                                  </option>
                                );
                              }
                            )}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={12}>
                      <Col
                        span={12}
                        xs={24}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        xxl={12}
                      >
                        {this.state.loadSubCategory ? (
                          <Spin />
                        ) : (
                          <Form.Item
                            rules={[
                              {
                                // required: this.state.data.business_industry
                                //   ? false
                                //   : true,
                                required: true,
                                message: "Required field"
                              }
                            ]}
                            style={{ borderWidth: 1 }}
                            name="business_industry"
                          >
                            <Select
                              id={"select"}
                              // style={{
                              //   padding: "0 !important", border: '1px solid #a9bac8', color: "#a9bac8", overflow: "hidden", borderRadius: "27px"
                              // }}
                              // size="large"
                              className={style.input_fields_select}
                              // defaultValue={
                              //   this.state.data.business_industry
                              //     ? this.state.data.business_industry
                              //     : undefined
                              // }
                              placeholder="Business Industry / Category"
                              bordered={true}
                              dropdownStyle={{
                                borderRadius: "15px",
                                border: "1px solid #a9bac8",
                                paddingTop: "0px",
                                paddingBottom: "0px",
                              }}
                              // allowClear
                              notFoundContent={
                                this.state.loadSubCategory ? (
                                  <Spin size="small" />
                                ) : (
                                  "No Data Found"
                                )
                              }
                              onChange={this.handleChange}
                            >
                              {this.state.sub_category &&
                                this.state.sub_category.map((item: any) => {
                                  // console.log("item32232332====", item);
                                  return (
                                    <option
                                      style={{
                                        borderBottom: "1px solid #7aacd6",
                                        padding: "10px 20px 10px 20px"
                                      }}
                                      value={item.name}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          width: "100%"
                                          // paddingTop: "0.7rem",
                                        }}
                                      >
                                        <div>{item.name}</div>
                                      </div>
                                    </option>
                                  );
                                })}
                            </Select>
                          </Form.Item>
                        )}
                      </Col>
                      <Col
                        span={12}
                        xs={24}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        xxl={12}
                      >
                        <Form.Item
                          rules={[
                            { required: true, message: "Required field" }
                          ]}
                          name="ownership_type"
                        >
                          <Select
                            placeholder="Ownership Type"
                            // style={{
                            //   padding: "0 !important", border: '1px solid #a9bac8', color: "#a9bac8", overflow: "hidden", borderRadius: "27px"
                            // }}
                            // size="large"
                            className={style.input_fields_select}
                            allowClear
                            bordered={true}
                            dropdownStyle={{
                              borderRadius: "15px",
                              border: "1px solid #a9bac8",
                              paddingTop: "0px",
                              paddingBottom: "0px",
                            }}
                            onChange={this.handleChange}
                          >
                            {this.state.businessOwnership.map(
                              (item: any, index: any) => {
                                // console.log("item32232332====", item);
                                return (
                                  <option
                                    style={{
                                      borderBottom: "1px solid #7aacd6",
                                      padding: "10px 20px 10px 20px"
                                      // display: 'flex',
                                    }}
                                    key={index}
                                    value={item.name}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        width: "100%"
                                        // paddingTop: "0.7rem",
                                      }}
                                    >
                                      <div>{item.name}</div>
                                    </div>
                                  </option>
                                );
                              }
                            )}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={12}>
                      <Col
                        span={12}
                        xs={24}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        xxl={12}
                      >
                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: "Required field"
                            }
                          ]}
                          style={{ borderWidth: 1 }}
                          name="established_year"
                        >
                          <Select
                            id={"select"}
                            // style={{
                            //   padding: "0 !important", border: '1px solid #a9bac8', color: "#a9bac8", overflow: "hidden", borderRadius: "27px"
                            // }}
                            // size="large"
                            className={style.input_fields_select}
                            placeholder="Established Year"
                            bordered={true}
                            dropdownStyle={{
                              borderRadius: "15px",
                              border: "1px solid #a9bac8",
                              paddingTop: "0px",
                              paddingBottom: "0px",
                            }}
                          >
                            {this.state.yearList.map((item: number) => {
                              return (
                                <option
                                  style={{
                                    borderBottom: "1px solid #7aacd6",
                                    padding: "10px 20px 10px 20px"
                                  }}
                                  value={item}
                                  key={item}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      width: "100%"
                                    }}
                                  >
                                    <div>{item}</div>
                                  </div>
                                </option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col
                        span={12}
                        xs={24}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        xxl={12}
                      >
                        <Form.Item
                          rules={[
                            { required: true, message: "Required field" },
                            {
                              pattern: new RegExp(/^[a-zA-Z ]+$/i),
                              message: "input accept only alphabets"
                            }
                          ]}
                          name="founder"
                        >
                          <Input
                            className={style.input_fields}
                            placeholder="Founder"
                            style={{ borderWidth: 1, color: "#a9bac8" }}
                            size="large"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={12}>
                      <Col
                        span={12}
                        xs={24}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        xxl={12}
                      >
                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: "Required field"
                            }
                            // ({ getFieldValue }) => ({
                            //   validator(_, value) {
                            //     if (
                            //       !value ||
                            //       getFieldValue("contact_person").match(
                            //         phoneRegExp
                            //       )
                            //     ) {
                            //       return Promise.resolve();
                            //     }
                            //     return Promise.reject(
                            //       new Error("Incorrect Phone Number")
                            //     );
                            //   },
                            // }),
                          ]}
                          getValueFromEvent={e => e.target.value.replace(/[^A-Za-z]/gi, "")}
                          name="contact_person"
                        >
                          <Input
                            className={style.input_fields}
                            placeholder="Contact Person"
                            style={{ borderWidth: 1, color: "#a9bac8" }}
                            size="large"
                          />
                        </Form.Item>
                      </Col>
                      <Col
                        span={12}
                        xs={24}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        xxl={12}
                      >
                        <Form.Item
                          rules={[
                            { required: true, message: "Required field" }
                          ]}
                          name="num_of_employees"
                          style={{ borderWidth: 1 }}
                        >
                          <Select
                            id={"select"}
                            bordered={true}
                            dropdownStyle={{
                              borderRadius: "15px",
                              border: "1px solid #a9bac8",
                              paddingTop: "0px",
                              paddingBottom: "0px",
                            }}
                            // style={{
                            //   padding: "0 !important", border: '1px solid #a9bac8', color: "#a9bac8", overflow: "hidden", borderRadius: "27px",
                            // }}
                            className={style.input_fields_select}
                            // size="large"
                            placeholder="Number of Employees"
                            onChange={this.handleChange}
                            value={this.state.selectedNoOfEmployee}
                          >
                            {this.state.noOfEmployee.map((item: any, id: number) => {
                              // console.log(
                              //   "@@@ item32232332========",
                              //   item
                              //   // this.state.noOfEmployee
                              // );
                              return (
                                <option
                                  style={{
                                    borderBottom: "1px solid #7aacd6",
                                    padding: "10px 20px 10px 20px"
                                  }}
                                  value={item.name}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      width: "100%"
                                      // paddingTop: "0.7rem",
                                    }}
                                  >
                                    <div>{item.name}</div>
                                  </div>
                                </option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={12}>
                      <Col
                        span={12}
                        xs={24}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        xxl={12}
                      >
                        {this.state.loadLocation ? (
                          <Spin />
                        ) : (
                          <Form.Item shouldUpdate>
                            {() => {
                              return (
                                <Form.Item rules={[
                                  { required: true, message: "Required field" },
                                ]}
                                  name="location">
                                  <Input
                                    className={style.input_fields}
                                    placeholder="Location"
                                    onChange={e => {
                                      const value = e.target.value;
                                      setTimeout(() => {
                                        this.profile_formref?.current?.setFieldsValue({
                                          location: value
                                        });
                                      }, 0);
                                    }}
                                    style={{ borderWidth: 1, color: "#a9bac8" }}
                                    size="large"
                                    suffix={
                                      <AimOutlined
                                        translate={{}}
                                        style={{ color: "#005574" }}
                                        onClick={() => this.getLocation()}
                                      />
                                    }
                                  />
                                </Form.Item>
                              );
                            }}
                          </Form.Item>
                        )}
                      </Col>
                      <Col
                        span={12}
                        xs={24}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        xxl={12}
                      >
                        <Form.Item
                          rules={[
                            {
                              // required: true,
                              message: "eg: http://www.sample.com or https://www.sample.com/xyz",
                              pattern: new RegExp(/^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/)
                            }
                          ]}
                          name="website"
                        >
                          <Input
                            className={style.input_fields}
                            placeholder="Website"
                            style={{ borderWidth: 1, color: "#a9bac8" }}
                            size="large"
                            type={"url"}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Card>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      padding: "15px",
                      background: "#f8f8f8"
                    }}
                  >
                    <Button
                      className={style.btn_colors}
                      type="primary"
                      htmlType="submit"
                      shape="round"
                      size={"large"}
                    >
                      Next
                    </Button>
                  </div>
                </Form>
              )}
            </Col>
            <Col xs={0} sm={0} md={3} lg={7} xl={8} />
          </Col>
        </Row>
      </div>
    );
  };
  // Customizable Area End
  render() {
    // Customizable Area Start
    return <div>{this.renderBusnissProfileV2()}</div>;
    // Customizable Area End
  }

}
// Customizable Area Start
//Customizable Area End
