import React from 'react';
import { runEngine } from '../../../framework/src/RunEngine';
import { Message } from '../../../framework/src/Message';
import MessageEnum, {
  getName,
} from '../../../framework/src/Messages/MessageEnum';
import { BlockComponent } from '../../../framework/src/BlockComponent';
import { IBlock } from '../../../framework/src/IBlock';
// import confirm from "../../alert/src/alert.web";
// import AlertError from "../../alert/src/alertError.web";
import { RouterProps } from 'react-router';
import { Redirect } from "react-router-dom";
// import { runInThisContext } from "vm";
import { FormInstance } from 'antd/lib/form';
import {
  Row,
  Col,
  Typography,
  Button,
  Modal,
} from 'antd';
import { removeStorageData, setStorageData, getStorageData } from '../../../framework/src/Helpers';
import axios from "axios"
const { Title, Text } = Typography;
export const configJSON = require('./config');
// Customizable Area Start
import { appId, api_Token } from '../../SelfdestructMessaging/src/ChatScreenController.web';
// Customizable Area End
export interface Props extends RouterProps {
  navigation: any;
  id: string;
}
export interface AllData {
  history: [
    {
      year: string;
      description: string;
    }
  ];
  expansion: [
    {
      year: string;
      description: string;
    }
  ];
  core_team: [
    {
      name: string;
      designation: string;
    }
  ];
  client_references: [
    {
      reference: string;
    }
  ];
}
interface S {
  loading: boolean;
  isRegistration: boolean;
  bussiness_profile_Loading: boolean;
  bussiness_profile: boolean;
  data: any;
  all_data: AllData;
  token: string | null;
  historyYears: any;
  historyDescription: any;
  expansionYears: any;
  expansionDescription: any;
  teamMemberName: any;
  teamMemberDesignation: any;
  ClientReference: any;
  prev_state: any;
  isModalVisible: any;
  modalIndex: number;
  modalString: string;
  isSuccess: boolean;
  isError: boolean;
  redirect: boolean;
  errorData: any;
  errorInProfileForm: boolean;
  yearList: number[];
  currentYear: number;
}
interface SS {
  id: any;
}

export default class CreateProfileBasicHistoryController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  businessProfileHistoryApiCallId: any;
  // formref = React.createRef();
  year_formref = React.createRef<FormInstance>();
  Expansion_formref = React.createRef<FormInstance>();
  Core_Teams_formref = React.createRef<FormInstance>();
  References_formref = React.createRef<FormInstance>();

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.receive = this.receive.bind(this);
    this.state = {
      bussiness_profile_Loading: false,
      bussiness_profile: true,
      loading: true,
      isRegistration: true,
      // data: this.props.history ? this.props.history.location.state : '',
      data: [],
      yearList: [],
      currentYear: new Date().getFullYear(),
      all_data: {
        history: [
          {
            year: '',
            description: '',
          },
        ],
        expansion: [
          {
            year: '',
            description: '',
          },
        ],
        core_team: [
          {
            name: '',
            designation: '',
          },
        ],
        client_references: [
          {
            reference: '',
          },
        ],
      },
      token: localStorage.getItem('token'),
      historyYears: '',
      historyDescription: '',
      expansionYears: '',
      expansionDescription: '',
      teamMemberName: '',
      teamMemberDesignation: '',
      ClientReference: '',
      prev_state: '',
      isModalVisible: false,
      modalIndex: 0,
      modalString: '',
      isSuccess: false,
      isError: false,
      redirect: false,
      errorData: {},
      errorInProfileForm: false
    };
    this.alertModal = this.alertModal.bind(this);
  }

  async componentDidMount() {
    let token = await localStorage.getItem("token");
    if (token && token !== null && token !== undefined) {
      this.setState({ redirect: false });
    } else {
      this.setState({ redirect: true });
    }
    console.log('hello', this.props.history.location.state);
    // this.setState({data:this.props.history.location.state})
    let details: any = this.props.history.location.state;

    if (details != null) {
      let newData = Object.assign(
        {},
        {
          industry_type:
            details && details.industry_type
        },
        { profile: details && details.profile },
        { overview: details && details.overview }
      );
      this.setState({
        data: newData,
      });
      if (details && details.history) {
        this.setState({
          all_data: details && details.history,
        });
      }
    }

    this.setState({ loading: false });
    let startYear = 1801;
    let year = [];
    while (startYear <= this.state.currentYear) {
      year.push(startYear);
      startYear = startYear + 1;
    }
    this.setState({ yearList: year.reverse() });
  }

  redirectUser() {
    if (this.state.redirect) {
      return <Redirect to="/" />;
    }
  }

  //=================== @@show modal =========================
  showModal = (index: number, data: string) => {
    this.setState({
      modalIndex: index,
      modalString: data,
      isModalVisible: true,
    });
  };

  // =================== @@handleChange =========================
  handleInputChange = (e: any, index: any, data: string) => {
    const { name, value } = e.target ? e.target : e;
    // let yearError: any = "", errorInProfileForm = false;
    // if (data === 'History') {
    //   const list: any = [...this.state.all_data.history];
    //   if (name === "year") {
    //     if (value) {
    //       yearError = value.length > 4 ? "Input accept maximum 4 digits" : "";
    //       errorInProfileForm = value.length > 4 ? true : false;
    //     } else {
    //       yearError = "Required field";
    //       errorInProfileForm = true
    //     }
    //   }
    //   list[index][name] = value;
    //   list[index]["yearError"] = yearError;
    //   this.setState({ ...list, errorInProfileForm: errorInProfileForm });
    // } else if (data === 'Expansion') {
    //   if (name === "year") {
    //     if (value) {
    //       yearError = value.length > 4 ? "Input accept maximum 4 digits" : "";
    //     } else {
    //       yearError = "Required field";
    //     }
    //   }
    //   const list: any = [...this.state.all_data.expansion];
    //   list[index][name] = value;
    //   list[index]["yearError"] = yearError;
    //   this.setState(list);
    if (data === 'History') {
      if (name === "description") {
        const list: any = [...this.state.all_data.history];
        list[index][name] = value;
        this.setState(list);
      } else {
        const list: any = [...this.state.all_data.history];
        list[index]["year"] = e;
        this.setState(list);
      }
    } else if (data === 'Expansion') {
      if (name === "description") {
        const list: any = [...this.state.all_data.expansion];
        list[index][name] = value;
        this.setState(list);
      } else {
        const list: any = [...this.state.all_data.expansion];
        list[index]["year"] = e;
        this.setState(list);
      }
    } else if (data === 'Core Team') {
      const list: any = [...this.state.all_data.core_team];
      list[index][name] = value;
      this.setState(list);
    } else if (data === 'Client References') {
      const list: any = [...this.state.all_data.client_references];
      list[index][name] = value;
      this.setState(list);
    } else {
      console.log('error while handiling data');
    }
  };

  // ====================@@delete all_data values=======================
  deleteData = (value: any) => {
    if (
      this.state.all_data.history != null &&
      this.state.modalString === 'History'
    ) {
      const list: any = [...this.state.all_data.history];
      list.splice(value, 1);
      this.setState(
        (state) => ((state.all_data.history = list), state),
        () => {
          // this.handleLocalStorage();
        }
      );
    } else if (
      this.state.all_data.expansion != null &&
      this.state.modalString === 'Expansion'
    ) {
      const list: any = [...this.state.all_data.expansion];
      list.splice(value, 1);
      this.setState(
        (state) => ((state.all_data.expansion = list), state),
        () => {
          // this.handleLocalStorage();
        }
      );
    } else if (
      this.state.all_data.core_team != null &&
      this.state.modalString === 'Core Team'
    ) {
      const list: any = [...this.state.all_data.core_team];
      list.splice(value, 1);
      this.setState(
        (state) => ((state.all_data.core_team = list), state),
        () => {
          // this.handleLocalStorage();
        }
      );
    } else if (
      this.state.all_data.client_references != null &&
      this.state.modalString === 'Client References'
    ) {
      const list: any = [...this.state.all_data.client_references];
      list.splice(value, 1);
      this.setState(
        (state) => ((state.all_data.client_references = list), state),
        () => {
          // this.handleLocalStorage();
        }
      );
    } else {
      console.log('error while deleting');
    }
    this.setState({
      isModalVisible: false,
    });
  };

  //==================== @@@ SET LOCAL STORAGE===============
  // handleLocalStorage() {
  //   localStorage.setItem(
  //     'businessHistory',
  //     JSON.stringify(this.state.all_data)
  //   );
  // }

  //==================== @@@ Handle Data of History Screen===============

  handleHistoryData = (values: any) => {
    let all_data = this.state.all_data;
    if (all_data.history) {
      all_data.history.push({ year: '', description: '' });
    } else {
      Object.assign(all_data, { history: [values] });
    }

    console.log(
      '@@@ history data====',
      values,
      '@@@ history data====',
      all_data
    )
    // this.handleLocalStorage();
    this.setState({ all_data: all_data }, () => {
      // this.year_formref.current!.resetFields();
    });
  };

  handleExpansionData = (values: any) => {
    let all_data = this.state.all_data;
    if (all_data.expansion) {
      all_data.expansion.push({ year: '', description: '' });
    } else {
      Object.assign(all_data, { expansion: [values] });
    }
    console.log(
      '@@@ expansion data=====',
      values,
      '@@@ expansion data=====',
      all_data
    ),
      // this.handleLocalStorage();
      this.setState({ all_data: all_data }, () => {
        // this.Expansion_formref.current!.resetFields();
      });
  };

  handleCoreTeamData = (values: any) => {
    console.log(values);
    let all_data = this.state.all_data;
    if (all_data.core_team) {
      all_data.core_team.push(values);
    } else {
      Object.assign(all_data, { core_team: [values] });
    }
    console.log(
      '@@@ core team data======',
      values,
      '@@@ core team data======',
      all_data
    ),
      // this.handleLocalStorage();
      this.setState({ all_data: all_data }, () => {
        this.Core_Teams_formref.current!.resetFields();
      });
  };

  handleClientRefData = (values: any) => {
    let all_data = this.state.all_data;
    if (all_data.client_references) {
      all_data.client_references.push(values);
    } else {
      Object.assign(all_data, { client_references: [values] });
    }
    console.log(
      '@@@ client ref. data=====',
      values,
      '@@@ client ref. data=====',
      all_data
    ),
      // this.handleLocalStorage();
      this.setState({ all_data: all_data }, () => {
        this.References_formref.current!.resetFields();
      });
  };

  //==================== @@@ Receive Function =========================

  async receive(from: string, message: Message) {
    // console.log('@@@ API Response ==========', message);
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.businessProfileHistoryApiCallId !== null &&
      this.businessProfileHistoryApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      // this.apiGetUserOtpCallId = null;

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      console.log('@@@ API Response ==========', responseJson);


      if (responseJson?.data?.attributes?.errors) {
        // console.log('error 1', responseJson?.data)
        this.setState({ isError: true, errorData: responseJson?.data?.attributes?.errors?.company_name ? 'Company Name is already taken' : 'Try again' });
        // this.setState({ isSuccess: true });
        this.setState({ bussiness_profile_Loading: false });

      } else if (responseJson?.errors) {
        // console.log('errorsssss 2',responseJson.errors)
        this.setState({ isError: true, errorData: responseJson?.errors[0]?.profile });
        // this.businessProfileHistoryFailureCallBack(responseJson);
        this.setState({ bussiness_profile_Loading: false });

      } else if (responseJson.error) {
        this.setState({ isError: true, errorData: 'Try again' });
        this.setState({ bussiness_profile_Loading: false });

      } else {
        this.setState({ isSuccess: true, bussiness_profile_Loading: false });
        this.businessProfileHistorySuccessCallBack(responseJson);
      }
      // this.parseApiCatchErrorResponse(errorReponse);
    }
  }

  addUserInChatBot = async (UpdateuserId?: string, nickname?: string, profile_url?: string, designation?: string) => {
    const formData = new FormData();
    if (UpdateuserId) {
      formData.append('user_id', UpdateuserId);
    }
    if (nickname) {
      formData.append('nickname', nickname);
    }
    if (profile_url) {
      formData.append('profile_url', profile_url);
    }
    await fetch("https://api-" + appId + ".sendbird.com/v3/users",
      {
        method: 'POST',
        body: formData,
        headers: {
          "Accept": "application/json",
          "Api-Token": api_Token,
          Authorization:
            "Bearer " +
            api_Token
        }
      },
    ).then((data) => data.json()).then((friendRes) => {
      console.log(friendRes)
    }).catch(error => { })
    await axios(
      {
        url: "https://api-" + appId + ".sendbird.com/v3/users/" + UpdateuserId + "/metadata",
        method: 'POST',
        data: {
          "metadata": { "designation": designation }
        },
        headers: {
          "Accept": "application/json",
          "Api-Token": api_Token,
          Authorization:
            "Bearer " +
            api_Token
        }
      }
    ).then((data: any) => console.log(data)).catch((error: any) => { })
  }

  //========================= @@@  Success And Failure  Call Back ====================

  businessProfileHistorySuccessCallBack = async (responseJson: any) => {
    // let userToken = responseJson;
    console.log(
      '@@@ business Profile History Success call Back ========',
      responseJson
    );
    // signUpUserRespons
    const loginUser = await getStorageData('loginUser')
    const setData = {
      ...loginUser, profile_pic: responseJson?.data?.attributes?.photo,
      company_name: responseJson?.data?.attributes?.company_name
    }
    const designation = responseJson?.data?.attributes?.business + "," + responseJson?.data?.attributes?.business_industry
    console.log(designation, "designation")
    this.addUserInChatBot(setData.id, setData.company_name, setData.profile_pic, designation)
    await setStorageData("loginUser", setData)
    await setStorageData("signUpUserID", { id: responseJson?.data?.attributes?.account_id });
    // Customizable Area Start
    await setStorageData("userProfile-1", responseJson);
    // Customizable Area End
    await removeStorageData("signUpUserResponse");
    localStorage.removeItem('businessAbout');
    localStorage.removeItem('businessProfile');
    localStorage.removeItem('businessprofileUrl');
    localStorage.removeItem('industry_type');
    localStorage.removeItem('businessHistory');
    this.setState({ bussiness_profile_Loading: false });

    this.alertModal();

    // this.renderCloseModal();
    // alert('Business Profile Created');
  };

  businessProfileHistoryFailureCallBack = (responseJson: any) => {
    console.log(
      '@@@ business Profile History Failure call Back ========',
      responseJson
    );
    // alert('Business Profile Not Created');
    this.errorAlertModal();
  };

  //======================== @@@ Call Post Api ==============================

  handleAddProfileData = () => {


    if (this.state.all_data && Object.keys(this.state.all_data).length > 0) {
      const header = {
        'Content-Type': 'application/json',
        token: this.state.token,
      };

      let updated_data = Object.assign({},
        { history: this.state.all_data?.history[0].year ? this.state.all_data?.history : [] },
        { expansion: this.state.all_data?.expansion[0].year ? this.state.all_data?.expansion : [] },
        { core_team: this.state.all_data?.core_team[0].name ? this.state.all_data?.core_team : [] },
        { client_references: this.state.all_data?.client_references[0].reference ? this.state.all_data?.client_references : [] },
        this.state.data.profile,
        this.state.data.overview ? this.state.data.overview : '')
      delete updated_data["profileUrl"];

      if (updated_data?.client_references[0]?.reference == '' || !updated_data?.client_references[0]?.reference) {
        delete updated_data['client_references'];
      }
      if (updated_data?.core_team[0]?.name == ''
        &&
        updated_data?.core_team[0]?.designation == ''
        ||
        !updated_data?.core_team[0]?.name
      ) {
        delete updated_data['core_team'];
      }
      if (updated_data?.expansion[0]?.year == '' &&
        updated_data?.expansion[0]?.description == '' ||
        !updated_data?.expansion[0]?.year) {
        delete updated_data['expansion'];
      }
      if (updated_data?.history[0]?.year == '' && updated_data?.history[0]?.description == '' ||
        !updated_data?.history[0]?.year) {
        delete updated_data['history'];
      }

      const json_data = {
        data: { attributes: updated_data },
      };
      const httpBody = json_data;
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.businessProfileHistoryApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        'bx_block_profile/business_profiles'
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        'POST'
      );
      // console.log(
      //   '@@@ Request ===========',
      //   requestMessage,
      //   this.businessProfileHistoryApiCallId
      // );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  };

  errorAlertModal = () => {
    return (
      <Modal
        title='Business Profile is Not Created'
        visible={this.state.isError}
        onCancel={() =>
          this.setState({
            isError: false,
          })
        }
        footer={null}
        style={{ borderRadius: '0.5rem' }}
      >
        {/* <p>{this.state.modalIndex}</p> */}
        <p>{JSON.stringify(this.state.errorData)}</p>
        <Row
          style={{
            display: 'flex',
            justifyContent: 'space-evenly',
            paddingTop: '0.5rem',
            paddingBottom: '1rem',
          }}
        >
          <Col>
            <Button
              type='primary'
              shape='round'
              size={'large'}
              style={{
                backgroundImage: 'linear-gradient(#00536c, #007491)',
                borderColor: '#005574 !important',
                width: '200px',
              }}
              onClick={() => {
                this.setState({
                  isError: false,
                });
              }}
            >
              Ok
            </Button>
          </Col>
        </Row>
      </Modal>
    );
  };

  alertModal = () => {
    return (
      <Modal
        title='Business Profile'
        visible={this.state.isSuccess}
        onCancel={() =>
          this.setState({
            isSuccess: false,
          })
        }
        footer={null}
        style={{ borderRadius: '0.5rem' }}
      >
        {/* <p>{this.state.modalIndex}</p> */}
        <p>Business Profile Created</p>
        <Row
          style={{
            display: 'flex',
            justifyContent: 'space-evenly',
            paddingTop: '0.5rem',
            paddingBottom: '1rem',
          }}
        >
          <Col>
            <Button
              type='primary'
              shape='round'
              size={'large'}
              style={{
                backgroundImage: 'linear-gradient(#00536c, #007491)',
                borderColor: '#005574 !important',
                width: '200px',
              }}
              onClick={() => {
                this.setState({
                  isSuccess: false,
                });
                this.props.history.push({
                  pathname: '/home',
                });
              }}
            >
              Ok
            </Button>
          </Col>
        </Row>
      </Modal>
    );
  };

  handlePrevious() {
    let newData = Object.assign(
      {},
      {
        industry_type:
          this.state.data.industry_type && this.state.data.industry_type
      },
      { profile: this.state.data && this.state.data.profile },
      { overview: this.state.data && this.state.data.overview },
      { history: this.state.all_data && this.state.all_data }
    );
    console.log('@@@handlePrevious hello ', newData);
    this.props.history.replace({
      state: newData,
    });
    this.props.history.push({
      pathname: '/setup/profile/business/about',
      state: newData,
    });
  }
  // Customizable Area End
}
