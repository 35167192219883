import React from "react";
import CommonSettingController from "./CommonSettingsController.web";
import "./settings.css";
import { Row, Col } from 'antd';
import AppliedPolicy from "./AppliedPolicy.web";


export default class AcceptableUsePolicy extends CommonSettingController {

  // Customizable Area Start
  render() {
    const { policiesData } = this.state;

    return (
      <div style={{ width: "100%", minHeight: "86vh", backgroundColor: "rgba(245, 245, 245, 1)", boxSizing: "border-box", }}>
        <Row style={{ width: "100%" }}>
          <Col xs={{ span: 24 }} lg={{ span: 24 }} style={{ padding: "1rem" }}>
            {
              <AppliedPolicy
                policiesData={policiesData[5]}
                isloader={this.state.isloader}
              />
            }
          </Col>
        </Row>
      </div>
    );
  }
  // Customizable Area End
}
