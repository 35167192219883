import React from "react";
import {
    Typography,
    CircularProgress,
    Box,
} from "@material-ui/core";

export default function ProfileRatingViewsPercentage(props: any) {

    const { title, value, subTitle, color, size, classes } = props;

    // console.log(subTitle.split(" "));
    return (
        <React.Fragment>
            <Box style={{ width: "100%", padding: '26px 24px' }}>
                <h3 style={{ color: "rgba(0, 0, 0, 0.85)", fontSize: 14, textAlign: 'center' }}>{title}</h3>
                <div
                    style={{
                        // background: 'white',
                        borderRadius: '5px',
                        // padding: "15px 0",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                    }}
                >
                    <Box position="relative" display="inline-flex">
                        <CircularProgress

                            variant="determinate"
                            value={value}
                            // color={'#0076de'}
                            size={size}
                            thickness={2}
                            style={{
                                color: color,
                                zIndex: 1,
                                transform: 'rotate(90deg)'
                            }}
                        // className={classes.bottom}
                        />
                        <Box
                            top={0}
                            left={0}
                            bottom={0}
                            right={0}
                            position="absolute"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            style={{
                                background: '#cfcece',
                                borderRadius: '50%'
                            }}
                        >
                            <Box
                                component="div"
                                style={{ lineHeight: "1rem", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}
                            // color="textSecondary"
                            >
                                {
                                    subTitle.split(" ").map((item: string, id: number) => {
                                        return (
                                            <Typography
                                                key={id}
                                                variant="caption"
                                                style={{ fontSize: "11px" }}
                                            >
                                                {item}
                                            </Typography>
                                        )
                                    })
                                }

                                <Typography
                                    variant="h6"
                                    style={{ fontWeight: "bold", fontSize: 14 }}
                                >
                                    {Math.round(value)}%
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </div>
            </Box>

        </React.Fragment>
    )

}
// Customizable Area Start
// Customizable Area End
