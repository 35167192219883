/* eslint-disable react/no-did-mount-set-state */
import React, { Component } from "react";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
// import confirm from '../../alert/src/alert.web';
// import AlertError from '../../alert/src/alertError.web';
import { RouterProps } from "react-router";
import { Redirect } from "react-router-dom";
export const configJSON = require("./config");
export interface Props extends RouterProps {
  navigation: any;
  id: string;
}
interface S {
  loading: boolean;
  isRegistration: boolean;
  bussiness_profile: boolean;
  data: any;
  companyOverView: any;
  companyMission: any;
  companyVision: any;
  companyCoreValues: any;
  healthSafetyEnviroment: any;
  productServices: any;
  contactPhone: any;
  contactEmail: any;
  userInfoBasis: any;
  prev_data: any;
  redirect: boolean;
}
interface SS {
  id: any;
}

export default class CreateProfileOverviewControllerV2 extends BlockComponent<
  Props,
  S,
  SS
> {
  postbusinessOverviewApiCallId: any;

  formref = React.createRef();
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.receive = this.receive.bind(this);
    this.state = {
      bussiness_profile: true,
      loading: true,
      isRegistration: true,
      data: {},
      // data: this.props.history ? this.props.history.location.state : '',
      companyOverView: "",
      companyMission: "",
      companyVision: "",
      companyCoreValues: "",
      healthSafetyEnviroment: "",
      productServices: "",
      contactPhone: "",
      contactEmail: "",
      userInfoBasis: "",
      prev_data: {},
      redirect: false
    };
  }

  // @@@ Receive Data of Profile Basic Screen==============
  async componentDidMount() {
    let token = await localStorage.getItem("token");
    if (token && token !== null && token !== undefined) {
      this.setState({ redirect: false });
    } else {
      this.setState({ redirect: true });
    }
    // console.log("Profile OverView========== ", this.props);
    // console.log("hello ========== ", this.props.history.location.state);
    let profileDetails: any = this.props.history.location.state;
    if (profileDetails && profileDetails != null) {
      let newData = Object.assign(
        {},
        { industry_type: profileDetails && profileDetails.industry_type },
        { history: profileDetails && profileDetails.history },
        { profile: profileDetails && profileDetails.profile }
      );
      this.setState({
        data: newData,
        prev_data: profileDetails && profileDetails.overview
      });
    }
    this.setState({ loading: false });
  }

  redirectUser() {
    if (this.state.redirect) {
      return <Redirect to="/" />;
    }
  }

  // @@@ Handle Data of Profile Overview Screen skip==============

  onSkipScreen = () => {
    // let updated_data = { ...this.state.data, ...data };
    let newData = Object.assign(
      {},
      {
        industry_type:
          this.state.data.industry_type && this.state.data.industry_type
      },
      { profile: this.state.data.profile && this.state.data.profile },
      { history: this.state.data.history && this.state.data.history },
      { overview: this.state.prev_data && this.state.prev_data }
    );
    this.props.history.replace({
      state: newData
    });
    this.props.history.push({
      pathname: "/setup/profile/business/overview",
      state: newData
    });
  };

  //next

  handlePrevious() {
    let newData = Object.assign(
      {},
      { profile: this.state.data.profile && this.state.data.profile },
      { history: this.state.data.history && this.state.data.history },
      { overview: this.state.prev_data && this.state.prev_data }
    );
    // console.log("@@@handlePrevious hello ", newData);
    this.props.history.replace({
      state: newData
    });
    this.props.history.push({
      pathname: "/setup/profile/business",
      state: newData
    });
  }

  handleAddProfileOverviewData = (data: object) => {
    console.log("@@@ business overview====", data);
    let newData = Object.assign(
      {},
      { overview: data },
      { profile: this.state.data && this.state.data.profile },
      { history: this.state.data && this.state.data.history }
    );
    this.props.history.replace({
      state: newData
    });
    this.props.history.push({
      pathname: "/setup/profile/business/overview",
      state: newData
    });

    // let updated_data = { ...this.state.data, ...data };
  };

  // Customizable Area End
}
