import React, { useState } from 'react';
import { List, Avatar, Typography, Button, Empty, Modal, Row, Col, Card } from "antd";
import { ArrowLeftOutlined } from '@ant-design/icons';
const { Title, Text } = Typography;

const BlockedAccountList = (props: any) => {
    const { handleBack, blockedAccountList, handleUnblock } = props;

    const [visible, setVisible] = useState(false);
    const [fullName, setFullName] = useState<string | null>(null);
    const [accountId, setAccountId] = useState<number | null>(null);

    const handleOpen = (name: string, id: number) => {
        setFullName(name);
        setAccountId(id);
        setVisible(true);
    }

    const handleCancel = () => {
        setVisible(false);
    }
    return (
        <Row>
            <Col xs={{ span: 24 }} lg={{ span: 20 }}  >
                <div style={{ display: "flex", flexDirection: "row", alignItems: "baseline" }}>
                    <ArrowLeftOutlined translate={{}} style={{ cursor: "pointer" }} onClick={() => handleBack("Blocked Accounts")} />
                    <Title level={5} style={{ margin: "5px 20px" }}>
                        Blocked Accounts
                    </Title>
                </div>
                {
                    blockedAccountList?.length ?
                        <List
                            style={{ width: "83.5%" }}
                            itemLayout="vertical"
                            dataSource={blockedAccountList}
                            renderItem={(item: any) => (
                                <List.Item
                                    key={item?.id}
                                >
                                    <div style={{ width: "100%", padding: "0px 20px", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                                        <div>
                                            <Avatar size="large" src={item?.attributes?.photo} />
                                            <Text strong style={{ fontSize: "14px", margin: "0px 15px" }}>{item?.attributes?.role === "business" ? item?.attributes?.profile?.data?.attributes?.company_name : item?.attributes?.full_name}</Text>
                                        </div>
                                        <div>
                                            <Button
                                                shape='round'
                                                style={{
                                                    padding: "0px 20px",
                                                    color: "rgb(0, 85, 116)",
                                                    border: "1px solid rgb(0, 85, 116)"
                                                }}
                                                onClick={() => handleOpen(item?.attributes?.role === "business" ? item?.attributes?.profile?.data?.attributes?.company_name : item?.attributes?.full_name, item?.id)}
                                            >
                                                Unblock
                                            </Button>
                                        </div>
                                    </div>
                                </List.Item>
                            )}
                        />
                        : <Card>
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        </Card>
                }
                <Modal
                    width={350}
                    className="Block_modal"
                    style={{ borderWidth: "0px", borderRadius: "4px", overflow: "hidden" }}
                    centered
                    title={"Unblock Confirmation"}
                    visible={visible}
                    onOk={() => {
                        handleUnblock(accountId);
                        setVisible(false);
                    }}
                    onCancel={handleCancel}
                    footer={[
                        <Row style={{ padding: "0px 12px" }}>
                            <Col span={12}>
                                <Button
                                    size="large"
                                    className="report-button"
                                    key="report"
                                    onClick={handleCancel}
                                >
                                    No, Don't unblock
                                </Button>
                            </Col>
                            <Col span={12}>
                                <Button
                                    size="large"
                                    className="follow-label"
                                    style={{ borderWidth: "0px" }}
                                    key="report"
                                    onClick={() => {
                                        handleUnblock(accountId);
                                        setVisible(false);
                                    }}
                                >
                                    Yes, unblock
                                </Button>
                            </Col>
                        </Row>
                    ]}
                >
                    <Text>Are you sure want to unblock {fullName}?</Text>
                </Modal>
            </Col>
        </Row>
    )
}

export default BlockedAccountList;