import React, { Component } from "react";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import confirm from "../../alert/src/alert.web";
export const configJSON = require("./config");
import { RouterProps } from "react-router";
import { setStorageData } from "../../../framework/src/Helpers";
import { Redirect } from "react-router-dom";

export interface Props extends RouterProps {
  navigation: any;
  id: string;
}
interface S {
  loading: boolean;
  isRegistration: boolean;
  bussiness_profile: boolean;
  data: {};
  countryCode: any | string;
  countryPlaceHolder: any | string;
  isMobile: boolean;
  isErrorModalShow: boolean;
  errorContent: any;
  redirect: boolean;
}
interface SS {
  id: any;
}

export default class ForgotController extends BlockComponent<Props, S, SS> {
  createAccountApiCallId: any;
  formref = React.createRef();
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.CountryCodeMessage),
      // Customizable Area End
    ];
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.receive = this.receive.bind(this);
    this.state = {
      bussiness_profile: true,
      loading: true,
      isRegistration: true,
      data: {},
      countryPlaceHolder: "🇮🇳 | +91",
      countryCode: "+91",
      isMobile: false,
      isErrorModalShow: false,
      errorContent: {},
      redirect: false
    };
  }

  async componentDidMount() {
    let token = await localStorage.getItem('token');
    if (token && token !== null && token !== undefined) {
      this.setState({ redirect: true })
    } else {
      this.setState({ redirect: false })
    }
  }
  redirectUser() {
    if (this.state.redirect) {
      return <Redirect to='/home' />;
    } else {
      return <Redirect to='/forgot/password' />;
    }
  };

  handleProfile = () => {
    this.setState({
      bussiness_profile: !this.state.bussiness_profile,
    });
  };
  handleOtp = (data: any | {}) => {
    const isPN = isNaN(data.full_phone_number);
    console.log(isPN, data.full_phone_number);
    setStorageData(
      "pn",
      !isPN
        ? this.state.countryCode + data.full_phone_number
        : data.full_phone_number
    );
    const header = {
      "Content-Type": "application/json",
    };
    let otpData = {};
    if (!isPN) {
      otpData = {
        // attributes: {
        full_phone_number: this.state.countryCode + data.full_phone_number,
        // }
      };
    } else {
      otpData = {
        // attributes: {
        email: data.full_phone_number,
        // }
      };
    }
    const json_data = {
      data: otpData,
    };
    const httpBody = json_data;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.sendOtpAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  async receive(from: string, message: Message) {
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson) {
        console.log(responseJson);
        if (responseJson.meta) {
          // confirm("Otp has been sent on your mobile");
          this.setState({
            isErrorModalShow: true,
            errorContent: {
              title: "Success",
              isError: false,
              body: "Otp has been sent on your mobile",
            },
          });
          this.props.history.push({
            pathname: "/verify/password/otp",
            state: responseJson,
          });
        } else if (responseJson.errors) {
          this.setState({
            isErrorModalShow: true,
            errorContent: {
              title: "error",
              isError: true,
              body: responseJson.errors[0].otp,
            },
          });
        } else {
          console.log("something went wrong");
        }
      } else {
        console.log("hello");
      }
    }
    if (message.id === getName(MessageEnum.CountryCodeMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.CountyCodeDataMessage)
      );
      if (apiRequestCallId.isValue) {
        this.setState({
          countryPlaceHolder: `${apiRequestCallId.result.flag}   | +${apiRequestCallId.result.value
            }`,
          countryCode: apiRequestCallId.result.value,
        });
      }
    }
  }
  handleSignup = () => {
    this.props.navigation.navigate("CreateAccount");
  };
}
