import React from 'react';
import { Space, Checkbox, Button, Typography, Card, Row, Col, Switch, Form, Input } from 'antd';
import AlertMessage from './AlertMessage.web';
const { Title, Text, Paragraph } = Typography;
const { TextArea } = Input;
const emailReg = RegExp(
    /^([a-zA-Z0-9_\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/
);

const ContactUs = (props: any) => {
    // Customizable Area Start
    const { onFinish, onOk, isModalVisible, failureModal, body } = props;
    const [form] = Form.useForm();

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <Space direction='vertical' style={{ width: "100%" }}>
            <Row gutter={[1, 1]}>
                <Col xs={{ span: 24 }} lg={{ span: 20 }} style={{ display: "flex", flexDirection: "column" }}>
                    <Title
                        style={{ fontSize: 16, fontWeight: 500 }}
                        level={5}

                    >
                        Contact Us
                    </Title>
                    <Space direction='vertical' style={{ width: "100%", backgroundColor: "#ffffff", padding: "1rem" }}>
                        <Paragraph style={{ fontWeight: 500, fontSize: "14px", color: "rgba(151, 151, 151, 0.6)" }}>Send us your feedback. We will get in touch with you within 3 business days.</Paragraph>
                        <Form
                            form={form}
                            name="basic"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 10 }}
                            onFinish={(value) => onFinish(value, form)}
                            onFinishFailed={onFinishFailed}
                        >
                            <Form.Item
                                name="name"
                                rules={[{ required: true, message: 'Please input your name!' }]}
                                getValueFromEvent={e => e.target.value.replace(/[^A-Za-z]/gi, "")}
                            >
                                <Input style={{ borderRadius: "25px", border: "1px solid rgb(0, 105, 143,0.5)" }} type={"text"} placeholder={"Name"} />
                            </Form.Item>

                            <Form.Item
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your email!'
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || emailReg.test(value)) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(
                                                new Error("Please Enter Valid Email!")
                                            );
                                        },
                                    }),
                                ]}
                            >
                                <Input style={{ borderRadius: "25px", border: "1px solid rgb(0, 105, 143,0.5)" }} type={"email"} placeholder="Email" />
                            </Form.Item>
                            <Form.Item
                                name="subject"
                                rules={[{ required: true, message: 'Please input your subject!' }]}
                                getValueFromEvent={e => e.target.value.replace(/[^A-Za-z0-9]/gi, "")}
                            >
                                <Input style={{ borderRadius: "25px", border: "1px solid rgb(0, 105, 143,0.5)" }} type={"text"} placeholder="Subject" />
                            </Form.Item>
                            <Form.Item
                                name="description"
                                rules={[{ required: true, message: 'Please input your description!' }]}
                            >
                                <TextArea
                                    autoSize={{ minRows: 3, maxRows: 3 }}
                                    style={{ borderRadius: "5px", border: "1px solid rgb(0, 105, 143,0.5)" }}
                                    placeholder="Description"
                                />
                            </Form.Item>


                            <Form.Item>
                                <Button style={{ width: "100%", height: "2.5rem", fontSize: "14px", backgroundColor: "#00698f", borderRadius: "18px" }} type="primary" htmlType="submit">
                                    Submit
                                </Button>
                            </Form.Item>
                        </Form>
                    </Space>
                </Col>
            </Row>
            <AlertMessage
                isModalVisible={isModalVisible}
                subBody="We will get back to you"
                body={body}
                failureModal={failureModal}
                onOk={onOk}
            />
        </Space>
    )
    // Customizable Area End
}

export default ContactUs;
