import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { Redirect } from "react-router-dom";
import React from "react";
// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { message } from "antd";
import {
  getStorageData,
  setStorageData
} from "../../../framework/src/Utilities";
import { RouterProps } from "react-router";
import SendBird from "sendbird";

export const appId = 'C46CB398-030E-49E1-9540-A2F6E662817D';
export const sb = new SendBird({
  appId: appId,
  localCacheEnabled: true
});
export const api_Token = 'ab6a3e950583de9ee4200dac0274cd85cdcd6b26';
// Customizable Area End

export const configJSON = require("./config");
export interface Props extends RouterProps {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start

  isMenuVisible: boolean;
  tabIndex: string;
  routes: any[];
  isCommunityJoined: boolean;
  isAdmin: boolean;
  isCreatePostVisible: boolean;
  isCreateCommunityVisible: boolean;
  imageUrlCreateCommunity: any;
  imgUrlCreatePost: any;
  loading: boolean;
  token: string | null;
  userProfile: any;
  loginUser: any;
  redirect: boolean;
  // isDropdownVisible: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class HomeController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  labelLogOut: string;
  labelProfile: string;
  labelSetting: string;
  labelApplied: string;
  labelHome: string;
  labelMyNetwork: string;
  labelCommunity: string;
  labelChat: string;
  labelAt: string;
  labelCommunityForum: string;
  labelWorkAnniversaries: string;
  labelBirthAnniversaries: string;
  labelKnownPeople: string;
  labelInvitations: string;
  placeHolderSearchContact: string;
  placeHolderWhatOnMind: string;
  btnLearnMore: string;

  isMenuVisible: boolean;
  isCreatePostVisible: boolean;
  isCreateCommunityVisible: boolean;
  routes: any[];
  isCommunityJoined: boolean;
  isAdmin: boolean;
  imageUrlCreateCommunity: any;
  imgUrlCreatePost: any;
  loading: boolean;
  GetProfielInformationId: any;
  GetAccountDetailsApiId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];

    this.state = {
      isMenuVisible: false,
      tabIndex: "1",
      routes: [
        {
          key: "RecentActivities",
          title: "Activities",
          icon: imgPasswordInVisible
        },
        { key: "Members", title: "Members", icon: imgPasswordInVisible }
      ],
      isCommunityJoined: false,
      isAdmin: false,
      isCreatePostVisible: false,
      isCreateCommunityVisible: false,
      imageUrlCreateCommunity: "",
      imgUrlCreatePost: "",
      loading: false,
      token: localStorage.getItem("token") ? localStorage.getItem("token") : "",
      userProfile: {},
      loginUser: {},
      redirect: false
    };
    this.btnLearnMore = configJSON.btnLearnMore;
    this.placeHolderWhatOnMind = configJSON.placeHolderWhatOnMind;
    this.placeHolderSearchContact = configJSON.placeHolderSearchContact;
    this.labelLogOut = configJSON.labelLogOut;
    this.labelProfile = configJSON.labelProfile;
    this.labelSetting = configJSON.labelSetting;
    this.labelApplied = configJSON.labelApplied;
    this.labelHome = configJSON.labelApplied;
    this.labelMyNetwork = configJSON.labelMyNetwork;
    this.labelCommunity = configJSON.labelCommunity;
    this.labelChat = configJSON.labelChat;
    this.labelAt = configJSON.labelAt;
    this.labelCommunityForum = configJSON.labelCommunityForum;
    this.labelWorkAnniversaries = configJSON.labelWorkAnniversaries;
    this.labelBirthAnniversaries = configJSON.labelBirthAnniversaries;
    this.labelKnownPeople = configJSON.labelKnownPeople;
    this.labelInvitations = configJSON.labelInvitations;

    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async componentDidMount() {
    let token = await localStorage.getItem("token");
    if (token && token !== null && token !== undefined) {
      this.setState({ redirect: false });
    } else {
      this.setState({ redirect: true });
    }
    await this.GetProfileInformation();
    // console.log("user details", this.state.token);
    await getStorageData("loginUser").then(res => {
      if (res?.id) {
        this.setState({
          loginUser: res
        });
        return this.getAccountDetails(res.id);
      } else {
        let data: any = getStorageData("signUpUserID").then((res) => {
          // console.log(res, 'signUpUserResponse');
          if (res?.id) {
            return this.getAccountDetails(res.id);
          } else {
            let data: any = getStorageData("signUpUserResponse").then((res) => {
              // console.log(res, 'signUpUserResponse');
              if (res?.data?.attributes?.profile_setup === false) {
                if (res?.data?.attributes?.role === "business") {
                  return this.props.history.replace('/setup/profile/business');
                } else {
                  return this.props.history.replace('/setup/profile/personal');
                }
              } else {
                let data: any = getStorageData("verificationData").then((res) => {
                  console.log(res, 'res***')
                  if (res?.profile_setup === false) {
                    if (res?.role === "business") {
                      return this.props.history.replace('/setup/profile/business');
                    } else {
                      return this.props.history.replace('/setup/profile/personal');
                    }
                  }
                })
              }
              // console.log("loginUser",res)
            });
          }
        });
      }
    });
  }

  redirectUser() {
    if (this.state.redirect) {
      return <Redirect to="/" />;
    }
  }

  async receive(from: String, message: Message) {
    // Customizable Area Start
    // runEngine.debugLog("on recieive==>" + JSON.stringify(message));

    if (getName(MessageEnum.CountryCodeMessage) === message.id) {
      var selectedCode = message.getData(
        getName(MessageEnum.CountyCodeDataMessage)
      );
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.GetProfielInformationId) {
          this.setState({ userProfile: JSON.stringify(responseJson) });
          // console.log("userProfileData", responseJson.data);
          // this.createUser(responseJson?.data?.attributes?.profile?.data?.attributes)
          setStorageData("userProfile", responseJson.data);
          // localStorage.setItem("userProfileee", JSON.stringify(responseJson.data))
        } else if (apiRequestCallId == this.GetAccountDetailsApiId) {
          // console.log("Accountdata", responseJson.data);
          setStorageData("accountData", responseJson.data);
        }
      } else {
        this.parseApiErrorResponse(responseJson);
      }
    } else if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let requesterId = message.getData(
        getName(MessageEnum.SessionRequestedBy)
      );
    }

    // Customizable Area End
  }

  // Customizable Area Start
  // connect user 
  // createUser = async(user:any)=> {
  //   // console.log(user,'user**')
  //   await sb.connect(
  //     user?.account_id.toString(),
  //     async (response, error) => {
  //       if (error) {
  //         return;
  //       }
  //       console.log(response,'createUser');
  //       let data = {
  //         user_id: user?.account_id.toString(),
  //         nickname: user?.full_name,
  //         profile_url: user?.photo,
  //       };
  //       console.log("userdatadatadatadata", user, data);
  //       await fetch("https://api-"+appId+".sendbird.com/v3/users",
  //     {
  //       method: 'POST',
  //       headers: {
  //         "Accept": "application/json",
  //         "Api-Token": api_Token,
  //       },
  //     body: JSON.stringify(data),
  //     },
  //   ).then((data) => data.json()).then((res) => {
  //     console.log(res,'resToken 1')
  //     if(res?.error){
  //       sb.updateCurrentUserInfo(user?.full_name, user?.photo)
  //       if (user?.domain_skills)
  //       {const domainSkillsPresent = user?.domain_skills;
  //       const domainSkillsStr = domainSkillsPresent.join(", ");
  //           var data = {
  //             designation: domainSkillsStr,
  //           };

  //           var currentUser = sb.currentUser;
  //           currentUser.createMetaData(data, function(metadata, error) {
  //             console.log("createMetaData, metadata, error", metadata, error);
  //             if (error) {
  //               // Handle error.
  //             }
  //           });}
  //     }
  //     if (user?.domain_skills)
  //     {
  //       const domainSkillsPresent = user?.domain_skills;
  //     const domainSkillsStr = domainSkillsPresent.join(", ");
  //         var data = {
  //           designation: domainSkillsStr,
  //         };

  //         var currentUser = sb.currentUser;
  //         currentUser.createMetaData(data, function(metadata, error) {
  //           console.log("createMetaData, metadata, error", metadata, error);
  //           if (error) {
  //             // Handle error.
  //           }
  //         });
  //         return res.data;
  //       }else{
  //     console.log(user?.full_name, user?.photo,'resToken 2')
  //       }
  //       // sb.disconnect()
  //       }).catch(async(err) => {
  //         console.log("Error in create user resToken 3", err.response);
  //          let respo =  await sb.updateCurrentUserInfo(user?.full_name, user?.photo)
  //     console.log(user?.full_name, user?.photo,'resToken',respo)

  //           if (user?.domain_skills)
  //           {const domainSkillsPresent = user?.domain_skills;
  //           const domainSkillsStr = domainSkillsPresent.join(", ");
  //               var data = {
  //                 designation: domainSkillsStr,
  //               };

  //               var currentUser = sb.currentUser;
  //               currentUser.createMetaData(data, function(metadata, error) {
  //                 console.log("createMetaData, metadata, error", metadata, error);
  //                 if (error) {
  //                   // Handle error.
  //                 }
  //               });}
  //               // sb.disconnect()
  //           return;
  //         });
  //   });
  // }


  handleChange = (newValue: any) => {
    // console.log(this.props.history,'data is triggered');
    if (window.location.pathname == "/home") {
      this.setState({ tabIndex: newValue });
    } else {
      this.props.history.push("/home");
      this.setState({ tabIndex: newValue });
    }
    // if(this.props.history)
  };
  async GetProfileInformation() {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GetProfielInformationId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.GetProfileInfoEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.SearchAPIMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // console.log("GetProfileInfo--->" + JSON.stringify(requestMessage));
    return true;
  }
  getAccountDetails = async (id: string) => {
    // console.log("account", id);
    const header = {
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GetAccountDetailsApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAccountDetailsEndPoint}/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAccountDetailsApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // console.log("getAccount--->" + JSON.stringify(requestMessage));
    return true;
  };
  // Customizable Area End
}
function axios(arg0: { url: string; method: string; headers: { Accept: string; "Api-Token": any; }; data: { user_id: any; nickname: any; profile_url: any; }; }) {
  throw new Error("Function not implemented.");
}

