import React from 'react';
import { Typography, Modal, Button, Space, Input, Form } from 'antd';
const { Text } = Typography;
const { TextArea } = Input;

const ReportUser = (props: any) => {
    const [form] = Form.useForm();
    const { onFinish, handleCancel, visible } = props;
    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };
    return (
        <Modal
            footer={null}
            bodyStyle={{
                width: "100%",
                maxHeight: "80vh",
                borderRadius: "5px",
            }}
            title={
                <Space
                    style={{
                        width: "100%",
                        cursor: "move",
                        fontWeight: "bold",
                        color: "rgba(0, 0, 0, 0.85)"
                    }}
                >
                    Add Report
                </Space>
            }
            visible={visible}
            onCancel={() => {
                handleCancel()
                form.resetFields()
            }}
        >
            <Form
                form={form}
                name="basic"
                initialValues={{ anonymous: false }}
                onFinish={(value) => {
                    onFinish(value)
                    form.resetFields();
                }}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item
                    name="context"
                    rules={[{ required: true, message: 'please add context!' }]}
                >
                    <TextArea
                        placeholder="Add Context"
                        autoSize={{ minRows: 4, maxRows: 4 }}
                        style={{
                            borderRadius: "0.4rem",
                            // color: "rgb(0, 85, 116)"
                        }}
                    />
                </Form.Item>

                <Form.Item>
                    <Button
                        htmlType="submit"
                        style={{
                            width: "100%",
                            height: "2.5rem",
                            borderRadius: "25px",
                            backgroundColor: "rgb(0, 85, 116)",
                            color: "#ffffff"
                        }}
                        onClick={() => handleCancel()}
                    >
                        Report
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default ReportUser;