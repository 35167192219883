import React, { Component } from "react";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Redirect } from "react-router-dom";
import confirm from "../../alert/src/alert.web";
import AlertError from "../../alert/src/alertError.web";
export const configJSON = require("./config");
import { RouterProps } from "react-router";
export interface Props extends RouterProps {
  navigation: any;
  id: string;
}
interface S {
  loading: boolean;
  isRegistration: boolean;
  bussiness_profile: boolean;
  data: any;
  selectedIndustry: string;
  redirect: boolean;
}
interface SS {
  id: any;
}

export default class SelectIndustryController extends BlockComponent<
  Props,
  S,
  SS
> {
  createAccountApiCallId: any;
  formref = React.createRef();
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.receive = this.receive.bind(this);
    this.state = {
      bussiness_profile: true,
      loading: true,
      isRegistration: true,
      data: {},
      selectedIndustry: "",
      redirect: false
    };
    this.handleIndustry = this.handleIndustry.bind(this);
  }

  async componentDidMount() {
    let token = await localStorage.getItem("token");
    if (token && token !== null && token !== undefined) {
      this.setState({ redirect: false });
    } else {
      this.setState({ redirect: true });
    }
    let details: any = this.props.history.location.state;
    // console.log(details, 'hello');
    if (details && details != null) {
      let newData = Object.assign(
        {},
        { history: details && details.history },
        { profile: details && details.profile },
        { overview: details && details.overview }
      );
      this.setState({
        data: newData
      });
      if (details.industry_type) {
        this.setState({
          selectedIndustry: details && details.industry_type
        });
      }
    }
    this.setState({ loading: false });
  }
  redirectUser() {
    if (this.state.redirect) {
      return <Redirect to="/" />;
    }
  }

  handleProfile = () => {
    this.setState({
      bussiness_profile: !this.state.bussiness_profile
    });
  };
  handleIndustry = (data: any) => {
    if (this.state.selectedIndustry.length > 2) {
      // let newData = Object.assign({}, data, {
      //   industry_type: this.state.selectedIndustry
      // });

      let finalData = Object.assign(
        {},
        { industry_type: data.industry },
        { profile: this.state.data && this.state.data.profile },
        { overview: this.state.data && this.state.data.overview },
        { history: this.state.data && this.state.data.history }
      );
      this.props.history.replace({
        state: finalData
      });
      this.props.history.push({
        pathname: "/setup/profile/business",
        state: finalData
      });
    }
    // else {
    //   // console.log(data);
    //   this.setState({ selectedIndustry: '' });
    //   localStorage.setItem('industry_type', JSON.stringify(data));
    //   this.props.history.push({
    //     pathname: '/setup/profile/business',
    //   });
    // }
  };
}
