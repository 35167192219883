import React, { Component } from "react";
// Customizable Area Start
import {
  PageHeader,
  Card,
  Row,
  Col,
  Form,
  Input,
  Typography,
  Button,
  Spin,
  DatePicker,
  Steps,
  Popover,
  Space,
  Select,
  Avatar,
  Upload,
  message,
  Divider,
} from "antd";
import { logo, style, NETWORK_PROFILE_ING } from "../assets";
const { Text, Link, Title } = Typography;
import MyNetworkController from "./myNetworkController.web";
import ViewNetwork from "./componant/network.web";
// Customizable Area End

export default class AllViewNetwork extends MyNetworkController {
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    return (
      <div>
        {this.redirectUser()}
        <PageHeader
          className="site-page-header"
          title={
            <span style={{ color: "white" }}>
              <img style={{ height: "32px", marginRight: "8px" }} src={logo} />
              applied
            </span>
          }
          style={{
            backgroundImage: "linear-gradient(#005574, #7aacd6)",
            color: "white",
          }}
        />
        <Row
          justify="center"
          style={{
            paddingLeft: "16px",
            paddingRight: "16px",
            paddingTop: "10px",
            backgroundColor: "#eff8fe",
          }}
        >
          <Title level={4} style={{ fontSize: 16, fontWeight: "bold" }}>
            My Network
          </Title>
        </Row>
        <Col style={{ marginLeft: 10, marginTop: 0 }}>
          {/* <ViewNetwork networkList={this.state.acceptedConnectionList} /> */}
          <ViewNetwork />
        </Col>
      </div>
    );
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
}
// Customizable Area Start
// Customizable Area End