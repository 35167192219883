Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Groups";
exports.labelBodyText = "Groups Body";
// Delete Community
exports.deleteCommunityApiEndPoint = "bx_block_groups/community_forums";
exports.deleteCommunityApiContentType = "application/json";
exports.deleteCommunityApiMethod = "DELETE";
// Get All Community End Point With Method
exports.getMyCommunityForumApiEndPoint = "bx_block_groups/community_forums/?admin=true&joined=true";
exports.getCommunityForumApiEndPoint = "bx_block_groups/community_forums/?admin=false&joined=true";
exports.getAllCommunityForumApiEndPoint = "bx_block_groups/community_forums";
exports.getCommunityPeopleAlsoFollowApiEndPoint = "bx_block_communityforum/community_forums";
exports.getCommunityApiMethodType="GET";
exports.getCommunityApiContentType = "application/json";
// Create and Edit Community
exports.createCommunityEndPoint="bx_block_groups/community_forums"
exports.EditCommunityEndPoint="bx_block_groups/community_forums"
exports.createCommunityPostApiMethodType="POST"
exports.createCommunityPutApiMethodType="PUT"
exports.btnExampleTitle = "CLICK ME";
// Customizable Area End