import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { removeStorageData } from "../../../../framework/src/Helpers";
import { getStorageData, isEmpty } from "../../../../framework/src/Utilities";
import { message } from 'antd';
import MessageEnum, {
    getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
export const configJSON = require("../config");

// Customizable Area Start

// Customizable Area End

var FormData = require('form-data');

export interface Props {
    navigation: any;
    id: string;
    route?: any;
    match?: any;
    history?: any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    account_id: string;
    isModal: boolean;
    GetCategoriesArray: any;
    initialDetails: any;
    usertype: string;
    failureModal: boolean;
    modalContent: {
        title: string;
        content: string;
    };
    userData: any;
    accountSettingData: any;
    isloader: boolean;
    //profile viewing options
    chkBxProfileViewFriends: boolean;
    chkBxProfileViewFriendsOfFriends: boolean;
    chkBxProfileViewPublic: boolean;
    //post viewing options
    chkBxPostViewFriends: boolean;
    chkBxPostViewFriendsOfFriends: boolean;
    chkBxPostViewPublic: boolean;
    //rating option
    chkBxRatingFriends: boolean;
    chkBxRatingFriendsOfFriends: boolean;
    chkBxRatingPublic: boolean;

    //notification settings
    push_notifications: boolean;
    email_notifications: boolean;

    //policies
    policiesData: any;
    currentPolicyName: string;
    isWebViewLoading: boolean;
    currentPolicyURL: any;
    paramsData: string | null;

    //contact Us
    isOkayContactUs: boolean;
    modalBody: string | null;

    //logout
    isLogoutModal: boolean;

    menuKey: string | null;
    blockedAccountList: any;
    purchaseHistoryData: any;
    blockedAccountStatus: string | null;
    suspendMessage: string | null;
    userAccountDetails: any;
    user_id: any
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class CommonSettingsController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getSettingsAPIId: any;
    updateSettingsAPIId: any;
    getPoliciesAPIId: any;
    getUserAPIId: any;
    createContactUsAPIId: any;
    suspendDeleteConfirmApiId: any;
    getBlockedAPIId: any;
    getPurchaseHistoryAPIId: any;
    postBlockedAPIId: any;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        // this.GetCategories("");

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            account_id: "",
            isModal: false,
            GetCategoriesArray: [],
            initialDetails: [],
            usertype: "business",
            failureModal: false,
            modalContent: {
                title: "",
                content: "",
            },
            userData:
                this.props.route &&
                    this.props.route.params &&
                    this.props.route.params.userData
                    ? this.props.route.params.userData.attributes
                    : [],
            accountSettingData: null,
            isloader: false,
            chkBxProfileViewFriends: false,
            chkBxProfileViewFriendsOfFriends: false,
            chkBxProfileViewPublic: false,

            chkBxPostViewFriends: false,
            chkBxPostViewFriendsOfFriends: false,
            chkBxPostViewPublic: false,

            chkBxRatingFriends: false,
            chkBxRatingFriendsOfFriends: false,
            chkBxRatingPublic: false,

            push_notifications: false,
            email_notifications: false,

            policiesData: [],
            userAccountDetails: {},
            currentPolicyName:
                this.props.route &&
                    this.props.route.params &&
                    this.props.route.params.currentPolicyName
                    ? this.props.route.params.currentPolicyName
                    : "",

            isWebViewLoading: true,
            currentPolicyURL:
                this.props.route &&
                    this.props.route.params &&
                    this.props.route.params.currentPolicyURL
                    ? this.props.route.params.currentPolicyURL
                    : "",
            paramsData: null,
            isOkayContactUs: false,
            modalBody: null,
            isLogoutModal: false,
            menuKey: null,
            blockedAccountList: [],
            purchaseHistoryData: [],
            blockedAccountStatus: null,
            suspendMessage: null,
            user_id: {}
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start

        // Customizable Area End
    }

    async componentDidMount() {
        this.setState({ paramsData: this.props?.match?.params?.tabId });
        this.setState({ isloader: true }, () => {
            this.getSettings();
            this.getPolicies();
            this.handleMenuKey();
            this.getBlockedListAccount();
        });
        const userProfile = await localStorage.getItem('userProfile');
        await getStorageData("userProfile").then((res: any) => {
            this.setState({ user_id: res });
        });
        if (this.state.user_id) {
            this.getUseraccounts();
        }
        this.getPurchaseHistoryList();
    }

    handleParams = () => {
        this.setState({ paramsData: this.props?.match?.params?.tabId });
    }
    info = () => {
        message.info('Coming soon');
    };

    handleLogoutCancel = () => {
        this.setState({ isLogoutModal: false });
    }

    handleMenuKey = () => {
        if (this.state.paramsData === "account-setting") {
            this.setState({ menuKey: "1" });
        } else if (this.state.paramsData === "applied-policy") {
            this.setState({ menuKey: "2" });
        } else if (this.state.paramsData === "about-applied") {
            this.setState({ menuKey: "3" });
        } else if (this.state.paramsData === "contact-us") {
            this.setState({ menuKey: "4" });
        } else if (this.state.paramsData === "delete-account") {
            this.setState({ menuKey: "6" });
        } else if (this.state.paramsData === "suspend-account") {
            this.setState({ menuKey: "5" });
        }
    }

    handleLogoutOk = async () => {
        if (this.state.suspendMessage?.trim() === "Suspend date should be older than current") {
            this.setState({ suspendMessage: null })
        } else {
            await localStorage.removeItem("token");
            await removeStorageData("loginUser");
            await removeStorageData("userProfile");
            await removeStorageData("accountData");
            await removeStorageData("signUpUser");
            await removeStorageData("signUpUserResponse");
            await removeStorageData("verificationData");
            await removeStorageData("signUpUserID");
            await removeStorageData("isFromLogin");

            this.setState({ isLogoutModal: false });
            // setDropDownVisible(false);
            this.props.history.push({
                pathname: "/"
            });
        }
    }

    handleOnClickOnListItem = (name: string, key: number) => {
        if (name === "Account Settings") {
            this.props.history.push("/settings/tab/account-setting");
            this.setLocalData(this.state.accountSettingData);
            this.setState({ paramsData: "account-setting", menuKey: JSON.stringify(key) });
        }
        else if (name === "Applied Policy") {
            this.props.history.push("/settings/tab/applied-policy");
            this.setState({ paramsData: "applied-policy", menuKey: JSON.stringify(key) });
        }
        else if (name === "About Applied") {
            // this.info();
            this.props.history.push("/settings/tab/about-applied");
            this.setState({ paramsData: "about-applied", menuKey: JSON.stringify(key) });
        }
        else if (name === "Contact Us") {
            this.props.history.push("/settings/tab/contact-us");
            this.setState({ paramsData: "contact-us", menuKey: JSON.stringify(key) });
        }
        else if (name === "Delete Account") {
            this.props.history.push("/settings/tab/delete-account");
            this.setState({ paramsData: "delete-account", menuKey: JSON.stringify(key) });
        }
        else if (name === "Suspend Account") {
            this.props.history.push("/settings/tab/suspend-account");
            this.setState({ paramsData: "suspend-account", menuKey: JSON.stringify(key) });
        }
        else if (name === "Logout") {
            this.setState({ isLogoutModal: true });
        }
    }

    async receive(from: string, message: Message) {
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );

        var responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        var errorReponse = message.getData(
            getName(MessageEnum.RestAPIResponceErrorMessage)
        );

        // Customizable Area Start

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            if (apiRequestCallId && responseJson && !responseJson.errors) {
                if (apiRequestCallId === this.getSettingsAPIId) {
                    console.log("responseJson getSettingsAPIId = ", responseJson);

                    if (
                        responseJson &&
                        responseJson.data &&
                        responseJson.data.attributes
                    ) {
                        const respData = responseJson.data.attributes;
                        this.setState({
                            accountSettingData: respData
                        })
                        this.setLocalData(respData);
                    } else this.setState({ isloader: false });
                } else if (apiRequestCallId === this.updateSettingsAPIId) {
                    if (
                        responseJson &&
                        responseJson.data &&
                        responseJson.data.attributes
                    ) {
                        const respData = responseJson.data.attributes;
                        // console.log(respData);
                        this.setState({
                            accountSettingData: respData
                        })
                        this.setLocalData(respData);
                    } else this.setState({ isloader: false });
                } else if (apiRequestCallId === this.getPoliciesAPIId) {
                    if (responseJson && responseJson.data) {
                        console.log('__res', responseJson.data)
                        this.setState({ isloader: false, policiesData: responseJson.data });
                    } else this.setState({ isloader: false });
                } else if (apiRequestCallId === this.getUserAPIId) {
                    if (responseJson && responseJson.data) {
                        this.setState({ isloader: false, userAccountDetails: responseJson.data });
                    } else this.setState({ isloader: false });
                }
                else if (apiRequestCallId === this.getBlockedAPIId) {
                    if (responseJson && responseJson.data) {
                        this.setState({
                            blockedAccountList: responseJson.data,
                            isloader: false,
                        });
                    }
                }
                else if (apiRequestCallId === this.getPurchaseHistoryAPIId) {
                    if (responseJson && responseJson.data) {
                        this.setState({
                            purchaseHistoryData: responseJson.data,
                            isloader: false,
                        });
                    }
                }
                else if (apiRequestCallId === this.postBlockedAPIId) {
                    this.getBlockedListAccount();
                }
                else if (apiRequestCallId === this.suspendDeleteConfirmApiId) {
                    this.setState({ suspendMessage: responseJson?.message });
                }
                else if (apiRequestCallId === this.createContactUsAPIId) {
                    debugger
                    if (errorReponse && errorReponse === "An error has occuured. Please try again later.") {
                        this.setState({
                            isOkayContactUs: true,
                            failureModal: false,
                            modalBody: errorReponse
                        });
                    }
                    else {
                        this.setState({
                            isOkayContactUs: true,
                            failureModal: true,
                            modalBody: responseJson?.message
                        });
                    }
                }
                else {
                    // console.log("in else community list controller = ", responseJson);
                    this.setState({ isloader: false });
                }
            }
        }

        // Customizable Area End
    }

    setLocalData(respData: any) {
        let firstProVal = false;
        let secondProVal = false;
        let firstPoVal = false;
        let secondPoVal = false;
        let firstRatVal = false;
        let secondRatVal = false;
        let thirdRatVal = false;

        if (respData.profile_view === "private") firstProVal = true;
        else if (respData.profile_view === "public") secondProVal = true;

        if (respData.post_view === "private") firstPoVal = true;
        else if (respData.post_view === "public") secondPoVal = true;

        if (respData.rating_view === "friends") firstRatVal = true;
        else if (respData.rating_view === "friends_of_friends") secondRatVal = true;
        else if (respData.rating_view === "public_view") thirdRatVal = true;

        this.setState({
            chkBxProfileViewFriends: firstProVal,
            chkBxProfileViewPublic: secondProVal,

            chkBxPostViewFriends: firstPoVal,
            chkBxPostViewPublic: secondPoVal,

            chkBxRatingFriends: firstRatVal,
            chkBxRatingFriendsOfFriends: secondRatVal,
            chkBxRatingPublic: thirdRatVal,

            email_notifications: respData.email_notifications,
            push_notifications: respData.push_notifications,
            isloader: false,
            account_id: respData.id,
        });
    }

    async getSettings() {
        this.setState({ isloader: true });
        const token = localStorage.getItem("token");
        const header = {
            "Content-Type": configJSON.getSettingsApiContentType,
            token: token,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        this.getSettingsAPIId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getSettingsApiEndpoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getSettingsApiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    updateSettings = async () => {
        this.setState({ isloader: true }, async () => {
            const header = {
                "Content-Type": configJSON.newUpdateSettingsApiContentType,
                token: localStorage.getItem("token"),
            };

            let profile_view = "",
                post_view = "",
                rating_view = "";

            if (this.state.chkBxProfileViewFriends) profile_view = "private";
            if (this.state.chkBxProfileViewPublic) profile_view = "public";

            if (this.state.chkBxPostViewFriends) post_view = "private";
            if (this.state.chkBxPostViewPublic) post_view = "public";

            if (this.state.chkBxRatingFriends) rating_view = "friends";
            if (this.state.chkBxRatingFriendsOfFriends) rating_view = "friends_of_friends";
            if (this.state.chkBxRatingPublic) rating_view = "public_view";

            let body = {
                profile_view: profile_view,
                post_view: post_view,
                rating_view: rating_view,
                email_notifications: this.state.email_notifications ? "true" : "false",
                push_notifications: this.state.push_notifications ? "true" : "false"
            }
            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );
            this.updateSettingsAPIId = requestMessage.messageId;
            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                configJSON.updateSettingsApiEndpoint + "/" + this.state.account_id
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(body)
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.updateSettingsApiMethod
            );
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        });
    };

    handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.name === "pushNotification") {
            this.setState({ push_notifications: !this.state.push_notifications })
        } else if (event.target.name === "emailNotification") {
            this.setState({ email_notifications: !this.state.email_notifications })
        }
    }

    handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name } = e.target;
        if (name === "profileViewOption1") {
            this.setState({ chkBxProfileViewFriends: true, chkBxProfileViewPublic: false });
        } else if (name === "profileViewOption2") {
            this.setState({ chkBxProfileViewFriends: false, chkBxProfileViewPublic: true });
        }
        if (name === "postViewOption1") {
            this.setState({ chkBxPostViewFriends: true, chkBxPostViewPublic: false });
        } else if (name === "postViewOption2") {
            this.setState({ chkBxPostViewFriends: false, chkBxPostViewPublic: true });
        }
        if (name === "ratingOption1") {
            this.setState({ chkBxRatingFriends: true, chkBxRatingFriendsOfFriends: false, chkBxRatingPublic: false });
        } else if (name === "ratingOption2") {
            this.setState({ chkBxRatingFriends: false, chkBxRatingFriendsOfFriends: true, chkBxRatingPublic: false });
        } else if (name === "ratingOption3") {
            this.setState({ chkBxRatingFriends: false, chkBxRatingFriendsOfFriends: false, chkBxRatingPublic: true });
        }
    }

    handleOnOk = () => {
        this.setState({ isOkayContactUs: false });
    }

    handleOnFinish = (value: any, form: any) => {
        const headers = {
            "Content-Type": configJSON.newCreateContactUsApiContentType,
            token: localStorage.getItem("token"),
        };

        let body = value;

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.createContactUsAPIId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.createContactUsApiEndpoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(body)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.createContactUsApiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        form.resetFields();
    }

    handleOnfinishSuspendAccount = (values: any) => {
        // console.log(values);
        const headers = {
            "Content-Type": configJSON.newSuspendDeleteConfirmApiContentType,
            token: localStorage.getItem("token"),
        };

        let body = values;

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.suspendDeleteConfirmApiId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.suspendDeleteConfirmApiEndpoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(body)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.suspendDeleteConfirmApiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    async getPolicies() {
        this.setState({ isloader: true });
        const token = localStorage.getItem("token");
        const header = {
            "Content-Type": configJSON.getPoliciesApiContentType,
            // token: token,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            header
        );

        this.getPoliciesAPIId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getPoliciesApiEndpoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getPoliciesApiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }
    async getUseraccounts() {
        this.setState({ isloader: true });
        console.log(this.state.user_id?.attributes?.profile?.data?.attributes?.account_id, "iiiii")
        const token = localStorage.getItem("token");
        const header = {
            "Content-Type": configJSON.getPoliciesApiContentType,
            token: token,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            header
        );

        this.getUserAPIId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            // configJSON.getUserAccountsApiEndpoint + "/" + this.state.account_id
            `${configJSON.getUserAccountsApiEndpoint}/${this.state.user_id?.attributes?.profile?.data?.attributes?.account_id}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getPoliciesApiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    handleUnblock = (value: number) => {
        const token = localStorage.getItem("token");
        const header = {
            "Content-Type": configJSON.blockedAccountPostApiContentType,
            token: token,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            header
        );

        this.postBlockedAPIId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.blockedAccountPostApiEndpoint}/${value}/block`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.blockedAccountPostApiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    async getBlockedListAccount() {
        this.setState({ isloader: true });
        const token = localStorage.getItem("token");
        const header = {
            "Content-Type": configJSON.blockedAccountApiContentType,
            token: token,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            header
        );

        this.getBlockedAPIId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.blockedAccountApiEndpoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.blockedAccountApiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    async getPurchaseHistoryList() {
        this.setState({ isloader: true });
        const token = localStorage.getItem("token");
        const header = {
            "Content-Type": configJSON.blockedAccountApiContentType,
            token: token,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            header
        );

        this.getPurchaseHistoryAPIId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.purchaseHistoryApiEndpoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.blockedAccountApiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    // Customizable Area End
}
