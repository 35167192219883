import React from "react";
import {
    Grid, Button
} from "@material-ui/core";

export default function AnniversarieCard(props: any) {

    const { classes, item, today } = props;
    // console.log(item.account?.data?.attributes?.designation)
    return (
        <Grid container style={{ position: 'relative' }}>
            <p className={classes.daysLeft}>{today ? "Today" : item.days_left > 0 && `in ${item.days_left} days`}</p>

            <Grid item xs={12} style={{ display: "flex" }}>
                <img
                    src={item?.account?.data?.attributes?.photo}
                    className={classes.image}
                />
            </Grid>

            <p style={{ fontSize: "22px", fontWeight: 700, textAlign: "center" }} className={classes.userName}>
                {item.account?.data?.attributes?.full_name}
            </p>
            {item.account?.data?.attributes?.designation && <p style={{ fontSize: "18px", fontWeight: 700, textAlign: "center" }} className={classes.userName}>{item.account?.data?.attributes?.designation.title} <span style={{ fontWeight: "normal" }}>at</span> {item.account?.data?.attributes?.designation.company}</p>}

            <p style={{ color: "gray", fontSize: 16, textAlign: "center" }} className={classes.userName}>{item.title}</p>
            <Grid item xs={12}>
                <hr style={{ margin: "20px 0px" }} />
            </Grid>
            <Grid container>
                <Grid item style={{ display: today ? "block" : "flex" }} xs={today ? 8 : 12}>
                    <Button className={classes.viewProfile} >View Profile </Button>
                </Grid>
                {today && <Grid item>
                    <Button className={classes.readpost} >Msg</Button>
                </Grid>}
            </Grid>
        </Grid>
    )

}
// Customizable Area Start
// Customizable Area End
