import React, { useState } from 'react';
import { Space, Checkbox, Button, Typography, List, Row, Col, message, Spin } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import Switch from '@material-ui/core/Switch';
import { makeStyles } from '@material-ui/core';
import BlockedAccountList from './BlockedAccountList.web';
import PurchaseHistory from './PurchaseHistory.web';
const { Title, Text } = Typography;

const useStyles = makeStyles((theme) => ({
    switch_track: {
        backgroundColor: "#595959",
    },
    switch_base: {
        color: "#f0f0f0",
        "&.Mui-disabled": {
            color: "#f0f0f0"
        },
        "&.Mui-checked": {
            color: "#4080c0"
        },
        "&.Mui-checked + .MuiSwitch-track": {
            backgroundColor: "#8db8d9",
        }
    },
    switch_primary: {
        "&.Mui-checked": {
            color: "#4080c0",
        },
        "&.Mui-checked + .MuiSwitch-track": {
            backgroundColor: "#8db8d9",
        },
    },
}));

const AccountSettings = (props: any) => {
    // Customizable Area Start

    const { chkBxProfileViewFriends, chkBxProfileViewPublic, chkBxPostViewFriends, chkBxPostViewPublic, chkBxRatingFriends, chkBxRatingFriendsOfFriends, chkBxRatingPublic, push_notifications, email_notifications, handleChange, handleSwitchChange, onClickOnSave, blockedAccountList, purchaseHistoryData, handleUnblock, isloader } = props;

    const [blockedAccount, setBlockedAccount] = useState(false);
    const [purchaseHistory, setPurchaseHistory] = useState(false);

    const info = () => {
        message.info('Coming soon');
    };

    const classes = useStyles();

    const handleBlockedAndPurchaseTab = async (value: string) => {
        if (value === "Blocked Accounts") {
            setBlockedAccount(true);
        } else if (value === "Purchase History") {
            setPurchaseHistory(true);
            // info();
        }
    }

    const handleBack = (value: string) => {
        if (value === "Blocked Accounts") {
            setBlockedAccount(false);
        } else if (value === "Purchase History") {
            setPurchaseHistory(false);
        }
    }

    const privacyList = [
        {
            id: 1,
            optionView: "Profile Viewing Option",
            desc: "Choose who can view your profile",
            name1: "profileViewOption1",
            name2: "profileViewOption2",
            option1: "Private",
            option2: "Public",
            value1: chkBxProfileViewFriends,
            value2: chkBxProfileViewPublic
        },
        {
            id: 2,
            optionView: "Post Viewing Option",
            desc: "Choose who can view your post",
            name1: "postViewOption1",
            name2: "postViewOption2",
            option1: "Private",
            option2: "Public",
            value1: chkBxPostViewFriends,
            value2: chkBxPostViewPublic
        },
        {
            id: 3,
            optionView: "Rating Option",
            desc: "Choose who can rate you",
            name1: "ratingOption1",
            name2: "ratingOption2",
            name3: "ratingOption3",
            option1: "Friends",
            option2: "Friends of Friends",
            option3: "Public",
            value1: chkBxRatingFriends,
            value2: chkBxRatingFriendsOfFriends,
            value3: chkBxRatingPublic,
        }
    ]

    const notificationViewList = [
        {
            id: 1,
            heading: "Push Notification",
            desc: "You can receive push notification",
            value: push_notifications,
            name: "pushNotification"
        },
        {
            id: 2,
            heading: "Email Notification",
            desc: "You can receive email notification",
            value: email_notifications,
            name: "emailNotification"
        }
    ]

    return (
        <Space direction='vertical' style={{ width: "100%" }}>
            {isloader ? <Spin />
                :
                (blockedAccount || purchaseHistory) ?
                    blockedAccount ?
                        <BlockedAccountList
                            handleBack={handleBack}
                            blockedAccountList={blockedAccountList}
                            handleUnblock={handleUnblock} /> :
                        purchaseHistory ?
                            <PurchaseHistory
                                handleBack={handleBack}
                                purchaseHistoryData={purchaseHistoryData}
                            // handleUnblock={handleUnblock}
                            /> : null
                    :
                    (blockedAccount || purchaseHistory) ?
                        blockedAccount ?
                            <BlockedAccountList
                                handleBack={handleBack}
                                blockedAccountList={blockedAccountList}
                                handleUnblock={handleUnblock} /> :
                            purchaseHistory ?
                                <PurchaseHistory
                                    handleBack={handleBack}
                                    purchaseHistoryData={purchaseHistoryData}
                                // handleUnblock={handleUnblock}
                                /> : null
                        :
                        <div>
                            <Row>
                                <Col xs={{ span: 24 }} lg={{ span: 20 }}  >
                                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                                        <Title
                                            style={{}}
                                            level={5}
                                        >
                                            Privacy Settings
                                        </Title>
                                        <Button
                                            shape="round"
                                            style={{ border: "1px solid #00698f", color: "#00698f", marginBottom: "5px", width: "5rem", height: "1.8rem", padding: "1px" }}
                                            onClick={onClickOnSave}
                                        >
                                            Save
                                        </Button>
                                    </div>
                                    <List
                                        itemLayout="vertical"
                                        dataSource={privacyList}
                                        renderItem={item => (
                                            <List.Item
                                                key={item.id}
                                                style={{ padding: "10px 20px" }}
                                            >
                                                <div style={{ lineHeight: "25px", display: "flex", flexDirection: "column" }}>
                                                    <Text strong style={{ fontSize: "14px", color: "rgba(105, 105, 116, 1)" }}>{item.optionView}</Text>
                                                    <Text style={{ fontWeight: 500, fontSize: "12px", color: "rgba(151, 151, 151, 0.6)" }}>
                                                        {item.desc}
                                                    </Text>
                                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                                        <Checkbox
                                                            style={{ margin: "0px", fontWeight: 400, fontSize: "14px", color: "rgba(151, 151, 151, 1)" }}
                                                            checked={item.value1}
                                                            name={item.name1}
                                                            onChange={(e) => handleChange(e)}
                                                        >
                                                            {item.option1}
                                                        </Checkbox>
                                                        <Checkbox
                                                            style={{ margin: "0px", fontWeight: 400, fontSize: "14px", color: "rgba(151, 151, 151, 1)" }}
                                                            checked={item.value2}
                                                            name={item.name2}
                                                            onChange={(e) => handleChange(e)}
                                                        >
                                                            {item.option2}
                                                        </Checkbox>
                                                        {
                                                            item.option3 ?
                                                                <Checkbox
                                                                    style={{ margin: "0px", fontWeight: 400, fontSize: "14px", color: "rgba(151, 151, 151, 1)" }}
                                                                    checked={item.value3}
                                                                    name={item.name3}
                                                                    onChange={(e) => handleChange(e)}
                                                                >
                                                                    {item.option3}
                                                                </Checkbox>
                                                                : null
                                                        }
                                                    </div>
                                                </div>
                                            </List.Item>
                                        )}
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={{ span: 24 }} lg={{ span: 20 }}  >
                                    <div style={{ marginTop: "20px", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                                        <Title
                                            style={{ color: "rgba(105, 105, 116, 1)", }}
                                            level={5}
                                        >
                                            Notification Settings
                                        </Title>
                                        <Button
                                            shape="round"
                                            style={{ border: "1px solid #00698f", color: "#00698f", marginBottom: "5px", width: "5rem", height: "1.8rem", padding: "1px" }}
                                            onClick={onClickOnSave}
                                        >
                                            Save
                                        </Button>
                                    </div>
                                    <List
                                        itemLayout="vertical"
                                        dataSource={notificationViewList}
                                        renderItem={item => (
                                            <List.Item
                                                key={item.id}
                                                style={{ padding: "10px 20px" }}
                                            >
                                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                                    <div style={{ lineHeight: "25px", display: "flex", flexDirection: "column" }}>
                                                        <Text strong style={{ fontSize: "14px", color: "rgba(105, 105, 116, 1)" }}>{item.heading}</Text>
                                                        <Text style={{ fontWeight: 500, fontSize: "12px", color: "rgba(151, 151, 151, 0.6)" }}>
                                                            {item.desc}
                                                        </Text>
                                                    </div>
                                                    <div>
                                                        <Switch
                                                            classes={{
                                                                track: classes.switch_track,
                                                                switchBase: classes.switch_base,
                                                                colorPrimary: classes.switch_primary,
                                                            }}
                                                            checked={item.value}
                                                            onChange={handleSwitchChange}
                                                            name={item.name}
                                                            color="primary"
                                                        />
                                                    </div>
                                                </div>
                                            </List.Item>
                                        )}
                                    />
                                </Col>
                            </Row>
                            <Row gutter={[1, 1]}>
                                <Col xs={{ span: 24 }} lg={{ span: 20 }} style={{ marginTop: "1rem", paddingLeft: "1rem ", borderRadius: "5px", paddingRight: "1rem ", paddingTop: "1rem", paddingBottom: "1rem", backgroundColor: "#ffffff", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", cursor: "pointer" }} onClick={() => handleBlockedAndPurchaseTab("Blocked Accounts")}>
                                    <Text style={{ fontWeight: 500, fontSize: "14px", color: "rgba(105, 105, 116, 1)" }}>Blocked Accounts</Text>
                                    <RightOutlined translate={undefined} />
                                </Col>
                            </Row>
                            <Row gutter={[1, 1]}>
                                <Col xs={{ span: 24 }} lg={{ span: 20 }} style={{ marginTop: "1rem", paddingLeft: "1rem ", borderRadius: "5px", paddingRight: "1rem ", paddingTop: "1rem", paddingBottom: "1rem", backgroundColor: "#ffffff", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", cursor: "pointer" }}
                                    onClick={() => handleBlockedAndPurchaseTab("Purchase History")}>
                                    <Text style={{ fontWeight: 500, fontSize: "14px", color: "rgba(105, 105, 116, 1)" }}>Purchase History</Text>
                                    <RightOutlined translate={undefined} />
                                </Col>
                            </Row>
                        </div>

            }
        </Space >
    )
    // Customizable Area End
}

export default AccountSettings;
