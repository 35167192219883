import React from "react";
import { Modal, Button, Space } from "antd";
// import { ExclamationCircleOutlined } from "@ant-design/icons";

export default function AlertError(props: any) {
  return Modal.error({
    title: "Error",
    content: props,
    okText: "Ok",
  });
}
