import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import axios from "axios";
import { getStorageData, setStorageData, isEmpty } from "../../../framework/src/Utilities";
import { Redirect } from "react-router-dom";
// Customizable Area Start
import { createRef } from "react";
import { message, Mentions } from "antd";
import { cloneDeep } from "lodash";
import React from "react"

const { Option, getMentions } = Mentions;
// @ts-ignore
// import dynamicLinks from "@react-native-firebase/dynamic-links";
// @ts-ignore
import { baseURL } from '../../../framework/src/config.js';
import { RouterProps } from "react-router";
import { sb, api_Token } from "../../SelfdestructMessaging/src/ChatScreenController.web";
// Customizable Area End

export const configJSON = require("./config");
export interface Props extends RouterProps {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isEditCommunityVisible: boolean;
  isMenuVisible: boolean;
  isUnblockVisible: boolean;
  tabIndex: string;
  deleteExistingMedias: any;
  selectedSettingId: number;
  isCommunityJoined: boolean;
  isAdmin: boolean;
  isCreatePostVisible: boolean;
  isCreateCommunityVisible: boolean;
  isCreateCommunity: boolean;
  imageUrlCreateCommunity: any;
  imgUrlCreatePost: any;
  loading: boolean;
  allCommunity: any;
  accountDetails: any;
  userProfile: any;
  loginUser: any;
  isBusinessUser: boolean;
  token: string | null;
  isloader: boolean;
  redirect: boolean;
  peoplemayKnow: any;
  allInvitations: any;
  businessConnection: any;
  personalConnection: any;
  invitationUserInfo: any;
  loadInvitationData: any;
  isShowInvitationModal: boolean;
  isShowConnectionModal: boolean;
  relationValue: any;
  isMounted: boolean;
  loadCommunity: boolean;
  communityPosts: any;
  profile_pic: any;
  cover_pic: any;
  topics: any;
  description: any;
  name: string;
  likedItems: number[];
  followedItems: number[];
  isReportIssueVisible: boolean;
  reportPostID: any;
  value: any;
  postComments: any;
  joinedCommunities: number[],
  followingCommunities: number[],
  joinRequestSent: number[],
  createDP: any,
  createCoverDP: any,
  toggleCommunityProfile: any,
  AllMedia: any,
  createPostImages: any;
  videoArrayList: any;
  createPostDescription: any;
  communityData: any;
  joiningReq: any;
  postReq: any;
  isRejectVisible: any;
  reasonReject: any;
  reasonPostReject: any;
  isRejectPostVisible: any;
  isviewPostVisible: any;
  mediaVisible: any;
  postModalData: any;
  isPostloader: any;
  loadCommunityPost: any;
  initialRenderCommunity: number | string | null;
  adminCommunities: any,
  joinnedCommunityData: any,
  newCommunities: any,
  isBlockedMessage: any;
  isBlocked: boolean;
  loadJoinCommunity: boolean;
  loadpeopleKnow: boolean;
  loadInvitation: boolean;
  initialloadCommunityPost: boolean;
  customFollow: any;
  fetchCommunityPostData: boolean;
  loadFollowCommunity: boolean;
  postInfo: any;
  isPostedPost: boolean;
  postlocation: any;
  loadLocation: boolean;
  latitude: any;
  longitude: any;
  communityHeading: any;
  communityList: any;
  isEditPost: boolean;
  editPostMedia: any;
  editPostId: string;
  isEmojiPickerEnable: boolean;
  isInviteCommunity: boolean;
  isLoadInviteFnds: boolean
  inviteFriendslist: any
  deleteId: any
  sendinvitationsAdmin: any
  isAdminInvite: any
  RejectJoinRequestId: any
  likedPostId: any;
  tagsOpen: boolean;
  selectTags: any;
  getMentionsArray: Array<any>;
  followers: any;
  tagged_connection_ids: any;
  openModal: boolean;
  openModalStatus: boolean;
  heading: string;
  fetchMessage: string;
  GroupChannel: any;
  postLocationOpen: boolean;
  loadMutualCommunity: boolean;
  paginationOfPageCount: number;
  communityId: number | string | null;
  clickToSeeMoreLoading: boolean;
  clickToSeeMoreShow: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class CommunityController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  contentRef = createRef<any>();
  formRef = createRef<any>();
  customClose = createRef<any>();
  // topicsField = React.createRef<FormInstance>();
  GetProfielInformationId: any;
  GetAccountDetailsApiId: any;
  getAcceptedConnectionsId: any;
  sendInviteConnectionId: any;
  deletePostApiCallId: any;
  labelLogOut: string;
  labelProfile: string;
  labelSetting: string;
  labelApplied: string;
  labelHome: string;
  labelMyNetwork: string;
  labelCommunity: string;
  labelChat: string;
  labelAt: string;
  labelCommunityForum: string;
  labelWorkAnniversaries: string;
  labelBirthAnniversaries: string;
  labelKnownPeople: string;
  labelInvitations: string;
  placeHolderSearchContact: string;
  placeHolderWhatOnMind: string;
  btnLearnMore: string;
  isMenuVisible: boolean;
  isUnblockVisible: boolean;
  isCreatePostVisible: boolean;
  isCreateCommunityVisible: boolean;
  routes: any[];
  isCommunityJoined: boolean;
  isAdmin: boolean;
  imageUrlCreateCommunity: any;
  imgUrlCreatePost: any;
  loading: boolean;
  addCommentInPost: any;
  getCommentInPostApiCallID: any;
  GetCategorySelectionByNetwork: any;
  GetCategorySelectionByNetworkforConnection: any;
  getConnectionApiCallId: any;
  commentLikeId: string;
  commentDislikeId: string;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];

    this.state = {
      GroupChannel: undefined,
      followers: [],
      tagged_connection_ids: [],
      getMentionsArray: [],
      selectTags: [],
      isMenuVisible: false,
      isUnblockVisible: false,
      tabIndex: "1",
      selectedSettingId: 1,
      isCommunityJoined: false,
      isAdmin: false,
      isCreatePostVisible: false,
      isCreateCommunityVisible: false,
      isEditCommunityVisible: false,
      imageUrlCreateCommunity: "",
      imgUrlCreatePost: "",
      loading: false,
      isCreateCommunity: true,
      allCommunity: [],
      isBusinessUser: false,
      loginUser: {},
      userProfile: {},
      accountDetails: {},
      isloader: true,
      deleteExistingMedias: [],
      token: localStorage.getItem("token") ? localStorage.getItem("token") : "",
      redirect: false,
      peoplemayKnow: [],
      allInvitations: [],
      businessConnection: [],
      personalConnection: [],
      invitationUserInfo: {},
      loadInvitationData: [],
      isShowInvitationModal: false,
      isShowConnectionModal: false,
      relationValue: '',
      isMounted: false,
      loadCommunity: false,
      communityPosts: [],
      profile_pic: '',
      cover_pic: '',
      topics: '',
      description: '',
      name: '',
      likedItems: [],
      followedItems: [],
      isReportIssueVisible: false,
      reportPostID: "",
      value: 'Fraude or pyramid Marketing',
      postComments: [],
      joinedCommunities: [],
      followingCommunities: [],
      joinRequestSent: [],
      createDP: {},
      createCoverDP: {},
      toggleCommunityProfile: {},
      AllMedia: [],
      videoArrayList: [],
      createPostImages: [],
      createPostDescription: '',
      communityData: {},
      joiningReq: [],
      postReq: [],
      isRejectVisible: false,
      reasonReject: '',
      reasonPostReject: '',
      isRejectPostVisible: '',
      isviewPostVisible: false,
      mediaVisible: false,
      postModalData: {},
      isPostloader: false,
      loadCommunityPost: false,
      initialRenderCommunity: null,
      adminCommunities: [],
      joinnedCommunityData: [],
      newCommunities: [],
      isBlockedMessage: '',
      isBlocked: false,
      loadJoinCommunity: false,
      loadpeopleKnow: true,
      loadInvitation: true,
      initialloadCommunityPost: true,
      customFollow: [],
      fetchCommunityPostData: true,
      loadFollowCommunity: false,
      postInfo: [],
      isPostedPost: false,
      postlocation: '',
      loadLocation: false,
      latitude: null,
      longitude: null,
      communityList: [],
      communityHeading: '',
      isEditPost: false,
      editPostMedia: [],
      editPostId: "",
      isEmojiPickerEnable: false,
      isInviteCommunity: false,
      isLoadInviteFnds: false,
      inviteFriendslist: {},
      deleteId: '',
      sendinvitationsAdmin: [],
      isAdminInvite: false,
      RejectJoinRequestId: {},
      likedPostId: [],
      tagsOpen: false,
      openModal: false,
      openModalStatus: false,
      heading: "",
      fetchMessage: "",
      postLocationOpen: false,
      loadMutualCommunity: true,
      paginationOfPageCount: 1,
      communityId: null,
      clickToSeeMoreLoading: false,
      clickToSeeMoreShow: true,
    };
    this.getUserLocation = this.getUserLocation.bind(this);
    this.getCordinates = this.getCordinates.bind(this);
    this.reverseGeoCodeCordinates = this.reverseGeoCodeCordinates.bind(this);
    this.handleLocationError = this.handleLocationError.bind(this);

    this.btnLearnMore = configJSON.btnLearnMore;
    this.placeHolderWhatOnMind = configJSON.placeHolderWhatOnMind;
    this.placeHolderSearchContact = configJSON.placeHolderSearchContact;
    this.labelLogOut = configJSON.labelLogOut;
    this.labelProfile = configJSON.labelProfile;
    this.labelSetting = configJSON.labelSetting;
    this.labelApplied = configJSON.labelApplied;
    this.labelHome = configJSON.labelApplied;
    this.labelMyNetwork = configJSON.labelMyNetwork;
    this.labelCommunity = configJSON.labelCommunity;
    this.labelChat = configJSON.labelChat;
    this.labelAt = configJSON.labelAt;
    this.labelCommunityForum = configJSON.labelCommunityForum;
    this.labelWorkAnniversaries = configJSON.labelWorkAnniversaries;
    this.labelBirthAnniversaries = configJSON.labelBirthAnniversaries;
    this.labelKnownPeople = configJSON.labelKnownPeople;
    this.labelInvitations = configJSON.labelInvitations;

    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async componentDidMount() {
    // super.componentDidMount();
    this.setState({ isMounted: true });
    await getStorageData("accountData").then(res => {
      this.setState({
        accountDetails: res,
        userProfile: res?.attributes?.account?.data
      });

    });
    let data: any = await getStorageData("loginUser")
    // .then(res => {
    // if (res) {
    this.setState({
      loginUser: data,
      isBusinessUser: data?.role === "business"
    });
    // this.getAccountDetails(data.id);
    let token = await localStorage.getItem("token");
    if (token && token !== null && token !== undefined) {
      this.setState({ redirect: false });
    } else {
      this.setState({ redirect: true });
    }
    const initialValues: any = this.props?.history?.location?.state;
    if (initialValues?.communityId) {
      await this.setState({
        initialRenderCommunity: initialValues?.communityId,
      });
      await this.getAllCommunity();
      await this.getCommunityPosts(initialValues?.communityId);
      await this.getSuggestedCommunity(initialValues?.communityId);
      await this.getPeopleYouknow();
      await this.getInvitations();
      await this.getAcceptedConnections();
    } else {
      if (this.state.isMounted) {
        await this.getPeopleYouknow();
        await this.getInvitations();
        await this.getConnections();
        await this.getAcceptedConnections();
        await this.getAllCommunity();
        // await this.GetProfileInformation();
      }
    }

    const checkIfClickedOutside = (e: any) => {
      if (this.state.isEmojiPickerEnable && this.customClose.current && !this.customClose.current.contains(e.target)) {
        this.setState({ isEmojiPickerEnable: false })
      }
    }
    document.addEventListener("mousedown", checkIfClickedOutside)
  }

  redirectUser() {
    if (this.state?.redirect) {
      return <Redirect to="/" />;
    }
  }

  async componentWillUnmount() {
    this.setState({ isMounted: false });
  }

  getUserLocation() {
    this.setState({ loadLocation: true });
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        this.getCordinates,
        this.handleLocationError
      );
    } else {
      // console.log("geo location is not supported in browser");
      this.setState({
        loadLocation: false,
      });
    }
  }

  getCordinates(position: any) {
    // console.log(position.coords.latitude);
    if (position.coords.latitude && position.coords.longitude) {
      this.setState({
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
        // userAddress
      });
      this.reverseGeoCodeCordinates();
    } else {
      this.setState({
        loadLocation: false
      });
    }
  }

  handleUserRequest = (value: any) => {
    if (value?.account_id === this.state.loginUser?.id) {
      return null
    } else {
      if (value?.account?.data?.attributes?.role === "personal") {
        return this.props.history.push({ pathname: "/other/personal/profile", state: value?.account_id });
      } else if (value?.account?.data?.attributes?.role === "business") {
        return this.props.history.push({ pathname: "/other/business/profile", state: value?.account_id });
      }
    }
  }

  async reverseGeoCodeCordinates() {
    let key = "AIzaSyCBH92YX2oCbI7PChqFNrgzw-PolHAiKZc";
    await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${this.state.latitude
      },${this.state.longitude}&sensor=false&key=${key}`
    )
      .then((response) => response.json())
      .then((data) => {
        let newData = data.results[0].address_components;
        let city = newData.filter(
          (ac: { types: string | string[] }) => ~ac.types.indexOf("locality")
        )[0].long_name;
        let country = newData.filter(
          (ac: { types: string | string[] }) => ~ac.types.indexOf("country")
        )[0].long_name;
        this.setState({
          postLocationOpen: true,
          postlocation: city + ", " + country
        })
        // this.setState(
        //   (state) => ((state.prev_data.location = city + ", " + country), state)
        // );
      })
      .catch((error) => {
        this.setState({ loadLocation: false });
        alert(`error while location, ${error}`);
      });
    this.setState({ loadLocation: false });
  }

  handleLocationError(error: any) {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        this.setState({

          loadLocation: false
        });
        alert("User denied the request for Geolocation.");
        break;
      case error.POSITION_UNAVAILABLE:
        this.setState({

          loadLocation: false
        });
        alert("Location information is unavailable.");
        break;
      case error.TIMEOUT:
        this.setState({

          loadLocation: false
        });
        alert("The request to get user location timed out.");
        break;
      case error.UNKNOWN_ERROR:
        this.setState({

          loadLocation: false
        });
        alert("An unknown error occurred.");
        break;
    }
  }

  // *************** api calls *******************************
  // get connections
  getConnections = async () => {
    const header = {
      "Content-Type": configJSON.getPostsApiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getConnectionApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getConnectionApiCallIdEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getPostsApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleInviteFriendModal = () => {
    this.setState({ openModal: false });
  }



  // get all communities
  getAllCommunity = async () => {
    this.setState({ loadCommunity: true })
    try {
      const responseForAdminCommunity = await axios({
        url:
          `${baseURL}/bx_block_groups/community_forums/?admin=true&joined=true`,
        method: "get",
        headers: {
          token: this.state.token,
          "Content-Type": "application/json"
        }
      });
      const responseForNotAdminCommunity = await axios({
        url:
          `${baseURL}/bx_block_groups/community_forums/?admin=false&joined=true`,
        method: "get",
        headers: {
          token: this.state.token,
          "Content-Type": "application/json"
        }
      });
      if (!this.state.initialRenderCommunity) {
        if (responseForAdminCommunity?.data?.communities?.data?.length || responseForNotAdminCommunity?.data?.communities?.data?.length) {
          await this.setState({
            loadCommunity: false,
            adminCommunities: responseForAdminCommunity?.data?.communities?.data,
            joinnedCommunityData: responseForNotAdminCommunity?.data?.communities?.data,
          });
          if (responseForAdminCommunity?.data?.communities?.data?.length) {
            await this.setState({
              toggleCommunityProfile: responseForAdminCommunity?.data?.communities?.data[0]
            });
            await this.getCommunityPosts(responseForAdminCommunity?.data?.communities?.data[0].id);
            await this.getSuggestedCommunity(responseForAdminCommunity?.data?.communities?.data[0].id);
          } else {
            await this.setState({
              toggleCommunityProfile: responseForNotAdminCommunity?.data?.communities?.data[0]
            });
            await this.getCommunityPosts(responseForNotAdminCommunity?.data?.communities?.data[0].id);
            await this.getSuggestedCommunity(responseForNotAdminCommunity?.data?.communities?.data[0].id)
          }
        } else {
          this.props.history.push({
            pathname: '/community/all', state: {
              heading: 'All Community Forum'
            }
          })
        }
      } else {
        await this.updateUnseenCount(this.state.initialRenderCommunity)
        await this.setState({
          loadCommunity: false,
          adminCommunities: responseForAdminCommunity?.data?.communities?.data,
          joinnedCommunityData: responseForNotAdminCommunity?.data?.communities?.data,
        });
      }
    } catch (error) {
      await this.setState({
        loadCommunity: false,
        adminCommunities: [],
        joinnedCommunityData: [],
        communityList: [],
        communityHeading: "No any community"
      });
    }
  };

  async receive(from: String, message: Message) {
    // Customizable Area Start
    // runEngine.debugLog("on recieive==>" + JSON.stringify(message));

    if (getName(MessageEnum.CountryCodeMessage) === message.id) {
      var selectedCode = message.getData(
        getName(MessageEnum.CountyCodeDataMessage)
      );
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.addCommentInPost) {
          this.setState({
            isloader: false,
          });
          this.getCommunityPosts(this.state?.toggleCommunityProfile?.attributes?.id);
        } else if (apiRequestCallId === this.GetCategorySelectionByNetworkforConnection) {
          this.setState({
            isShowConnectionModal: true,
            loadInvitationData: responseJson.relations
          })

        } else if (apiRequestCallId === this.GetCategorySelectionByNetwork) {
          this.setState({
            isShowInvitationModal: true,
            loadInvitationData: responseJson.relations
          })

        } else if (apiRequestCallId === this.getCommentInPostApiCallID) {
          let list = this.state.communityPosts.map((value: any) =>
            value?.attributes?.comments?.data[0]?.id === responseJson?.data[0]?.id
              ? { ...value, comments: responseJson?.data }
              : value
          );
          this.setState({
            isloader: false,
            communityPosts: list
          });
        } else if (apiRequestCallId === this.getConnectionApiCallId) {
          this.setState({ followers: responseJson.data });
        } else if (apiRequestCallId === this.GetProfielInformationId) {
          this.setState({ userProfile: JSON.stringify(responseJson) });
          // console.log("userProfileData", responseJson.data);
          setStorageData("userProfile", responseJson.data);
          this.setState({ userProfile: responseJson.data });
        } else if (apiRequestCallId == this.GetAccountDetailsApiId) {
          // console.log("Accountdata", responseJson.data);
          setStorageData("accountData", responseJson.data);
          this.setState({ accountDetails: responseJson.data });
        } else if (apiRequestCallId === this.getAcceptedConnectionsId) {
          // console.log('friendsList_ api', responseJson)
          this.setState({
            isLoadInviteFnds: false,
            inviteFriendslist: responseJson.data ? responseJson.data?.map((item: any) => { return { ...item, checked: false } }) : []
          });
        } else if (apiRequestCallId === this.deletePostApiCallId) {
          if (responseJson) {
            const data = { attributes: { id: this.state.deleteId } }
            this.getCommunityPosts(this.state?.toggleCommunityProfile?.attributes?.id);
            this.updateUnseenCount(this.state?.toggleCommunityProfile?.attributes?.id);
          }
        } else if (apiRequestCallId == this.sendInviteConnectionId) {

          if (responseJson.message === "Accounts does not exist" || responseJson.error) {
            this.setState({
              isAdminInvite: false,
              isInviteCommunity: false,
              openModal: true,
              openModalStatus: false,
              heading: "Invite Friends",
              fetchMessage: responseJson?.message
            });
          } else {

            this.setState({
              isAdminInvite: false,
              isInviteCommunity: false,
              openModal: true,
              openModalStatus: true,
              heading: "Invite Friends",
              fetchMessage: responseJson?.message
            });
            const user = await getStorageData("loginUser")
            this.createChannel(user.id, this.state.sendinvitationsAdmin[0])
          }

        }
        else if (apiRequestCallId == this.commentLikeId) {
          this.getComments(this.state.likedPostId);
        }
        else if (apiRequestCallId == this.commentDislikeId) {
          this.getComments(this.state.likedPostId);
        }
      }
    } else if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let requesterId = message.getData(
        getName(MessageEnum.SessionRequestedBy)
      );
    }

    // Customizable Area End
  }

  // Customizable Area Start
  getBase64(img: any, callback: any) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }
  async beforeUploadProfile(file: any) {
    console.log("file==>", file);
    // this.setState({profilePic:})

    const isJpgOrPng = (await file.type.slice(0, 5)) == "image";
    if (!isJpgOrPng) {
      //  message.error("You can only upload Image file!");
    } else {
      console.log("isJpgOrPng===>", isJpgOrPng);
      this.getBase64(file, (imageUrl: string) => {
        console.log("imageUrl===>", imageUrl);
        console.log("fileUrl===>", imageUrl);
        this.setState(
          {
            createDP: {
              filePath: imageUrl,
              // filePath: "data:image/jpeg;base64," + info.file.originFileObj,
              uri: imageUrl,
              type: file.type,
              name: file.name,
              data: file,
            },
            profile_pic: imageUrl,
            loading: false,
          },
          () => { }
        );
      });
    }
    return isJpgOrPng;
  }

  async beforeUploadCover(file: any) {
    console.log("file==>", file);
    const isJpgOrPng = (await file.type.slice(0, 5)) == "image";
    if (!isJpgOrPng) {
      //  message.error("You can only upload Image file!");
    } else {
      console.log("isJpgOrPng===>", isJpgOrPng);
      this.getBase64(file, (imageUrl: string) => {
        console.log("imageUrl===>", imageUrl);
        this.setState(
          {
            createCoverDP: {
              filePath: imageUrl,
              // filePath: "data:image/jpeg;base64," + info.file.originFileObj,
              uri: imageUrl,
              type: file.type,
              name: file.name,
              data: file,
            },
            cover_pic: imageUrl,
            loading: false,
          },
          () => { }
        );
      });
    }
    return isJpgOrPng;
  }

  handleImageCoverChange = (info: any) => {
    if (info?.file?.status === "done") {
      console.log(this.state?.cover_pic, 'image uploded');
    } else if (info?.file?.status === "error") {
      console.log("FILE INFO error", info, info?.file);
      // message.error(`${info.file.name} file upload failed.`);
    } else {
      console.log("FILE INFO else", info);
      // message.error("failed");
    }
  };

  handleImageChange = (info: any) => {
    if (info.file.status === "done") {
      console.log("FILE INFO done", info);
    } else if (info.file.status === "error") {
      console.log("FILE INFO", info, info.file);
      // message.error(`${info.file.name} file upload failed.`);
    } else {
      console.log("FILE INFO else", info);
      // message.error("failed");
    }
  };

  async beforeUploadPost(file: any) {
    console.log("filetype", file);
    const isJpgOrPng =
      file.type.slice(0, 5) == "image" ||
      file.type.slice(0, 5) == "video";

    let isLt2M = false;
    if (file.type.slice(0, 5) == "image") {
      isLt2M = file.size / 1024 / 1024 < 5;
      if (!isLt2M) {
        message.error('Image must smaller than 5MB!');
      }
    } else if (file.type.slice(0, 5) == "video") {
      isLt2M = file.size / 1024 / 1024 < 10;
      if (!isLt2M) {
        message.error('Video must smaller than 10MB!');
      }
    }

    this.getBase64(file, (imageUrl: string) => {
      let ImageArrayList = [...this.state.createPostImages];
      let VideoArray = [...this.state.videoArrayList];
      let AllMedia = [...this.state.AllMedia]
      if (isLt2M && (file.type.slice(0, 5) == "video" || file.type.slice(0, 5) == "image")) {

        AllMedia.push({
          filePath: imageUrl,
          // filePath: "data:image/jpeg;base64," + info.file.originFileObj,
          uri: imageUrl,
          type: file.type,
          name: file.name,
          data: file,
        });
      }
      if (file.type.slice(0, 5) == "video" && isLt2M) {
        VideoArray.push({
          filePath: imageUrl,
          // filePath: "data:image/jpeg;base64," + info.file.originFileObj,
          uri: imageUrl,
          type: file.type,
          name: file.name,
          data: file,
        });
      } else if (file.type.slice(0, 5) == "image" && isLt2M) {
        ImageArrayList.push({
          filePath: imageUrl,
          // filePath: "data:image/jpeg;base64," + info.file.originFileObj,
          uri: imageUrl,
          type: file.type,
          name: file.name,
          data: file,
        });
      } else {
        message.error("invalid file type!!!");
      }

      this.setState({
        createPostImages: ImageArrayList,
        videoArrayList: VideoArray,
        AllMedia,
        loading: false,
      });

      // console.log("arrays" + JSON.stringify(this.state.createPostImages));
    });

    return true;
  }

  handleCancelOfCreateAndEditCommunity = async () => {
    await this.setState({
      isCreateCommunityVisible: false,
      isEditCommunityVisible: false,
      profile_pic: '',
      cover_pic: '',
      name: '',
      topics: '',
      description: '',
    });
  }
  handleOkOfCreateAndEditCommunity = async () => {
    await this.setState({
      isCreateCommunityVisible: false,
      isEditCommunityVisible: false
    });
  }

  handleCreateAndEditCommunity = async (community_id: number | string) => {
    if (community_id) {
      if (this.state.isCreateCommunity) {
        await this.updateUnseenCount(community_id)
        await this.setState({
          communityPosts: [],
          isEditCommunityVisible: false,
          isCreateCommunityVisible: false,
          loadCommunityPost: false,
        });
        await this.getCommunityPosts(community_id);
        if (this.props.history.location.pathname === '/community/block') {
          await this.getSuggestedCommunity(community_id);
        } else {
          await this.props.history.push({
            pathname: '/community/block', state: {
              communityId: community_id
            }
          });
        }
      } else {

        await this.setState({
          communityPosts: [],
          isEditCommunityVisible: false,
          isCreateCommunityVisible: false,
          loadCommunityPost: false
        });
        await this.getCommunityPosts(community_id);
        await this.updateUnseenCount(community_id)
      }
    } else {
      await this.setState({
        isEditCommunityVisible: false,
        isCreateCommunityVisible: false,
        loadCommunityPost: false,
        isCreateCommunity: false
      });
    }
  }

  getCommunityPosts = async (id: any) => {
    try {
      const response = await axios({
        url: `${baseURL}/bx_block_posts/posts/fetch_community_posts/?community_forum_id=${id}&page=${this.state.paginationOfPageCount}&per_page=5`,
        method: 'get',
        headers: {
          'token': this.state.token,
          'Content-Type': 'application/json'
        }
      })
      if (response?.data?.data?.length && response?.data?.data?.length % 5 === 0) {
        await this.setState({
          clickToSeeMoreShow: true
        })
      } else {
        await this.setState({
          clickToSeeMoreShow: false
        })
      }
      await this.setState({
        fetchCommunityPostData: false,
        communityPosts: [...this.state.communityPosts, ...response?.data?.data],
        clickToSeeMoreLoading: false
      })
    } catch (error) {
      this.setState({
        fetchCommunityPostData: false,
        clickToSeeMoreShow: true,
        communityPosts: [],
      });
    }
  }

  likedComment = async (id: number, itemId?: any) => {
    this.setState({ isloader: true, likedPostId: itemId });
    const token = await this.state.token;
    const header = {
      // "Content-Type": configJSON.getPostsApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.commentLikeId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createComment}/${id}/like`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.likeDislikeApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({ isloader: false });
    return true;
  };
  dislikedComment = (id: number, itemId?: any) => {
    this.setState({ isloader: true, likedPostId: itemId });
    const token = this.state.token;
    const header = {
      // "Content-Type": configJSON.getPostsApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.commentDislikeId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createComment}/${id}/dislike`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.likeDislikeApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({ isloader: false });
    return true;
  };

  toggleCommentLike = (id: number, liked: boolean, itemId?: any) => {
    if (liked) {
      this.dislikedComment(id, itemId);
    } else {
      this.likedComment(id, itemId);
    }
  };

  getJoinedCommunityData = async () => {
    this.setState({
      // joinnedCommunityData: [],
      loadCommunity: true
    });
    try {
      const response = await axios({
        url: `${baseURL}/bx_block_groups/community_forums/joined_communities?token=${this.state.token}`,
        method: 'get',
        headers: {
          'token': this.state.token,
          'Content-Type': 'application/json'
        }
      })
      if (response?.data?.communities?.data) {
        this.setState({ joinnedCommunityData: response?.data?.communities?.data, loadCommunity: false });
      }

      // this.setState({ peoplemayKnow: response.data, fetchCommunityPostData: false });
      // console.log(response.data, 'get all joined community data');
    } catch (error) {
      console.error(error);
      // this.setState({ fetchCommunityPostData: false });
    }
  }
  //People you know
  getPeopleYouknow = async () => {
    this.setState({ peoplemayKnow: [], loadpeopleKnow: true });
    try {
      const response = await axios({
        url: `${baseURL}/bx_block_request_management/requests/suggestions/?page=1&per_page=4`,
        method: 'get',
        headers: {
          'token': this.state.token,
          'Content-Type': 'application/json'
        }
      })
      // const response = await axios.get('');
      this.setState({
        loadpeopleKnow: false,
        peoplemayKnow: response.data.accounts,
      });
    } catch (error) {
      console.error(error);
      this.setState({ loadpeopleKnow: false });
    }
  }

  // Invitations
  getInvitations = async () => {
    this.setState({ allInvitations: [], loadInvitation: true });
    try {
      const response = await axios({
        url: `${baseURL}/bx_block_request_management/requests/?status=pending&page=1&per_page=4`,
        method: 'get',
        headers: {
          'token': this.state.token,
          'Content-Type': 'application/json'
        },
      })
      this.setState({
        allInvitations: response.data,
        loadInvitation: false
      });
      await this.getCategories();
    } catch (error) {
      console.error(error);
      this.setState({
        loadInvitation: false,
        allInvitations: []
      })
    }
  }

  getCategories = async () => {
    try {
      const response = await axios({
        url: `${baseURL}/bx_block_categories/categories`,
        method: 'get',
        headers: {
          'token': this.state.token,
          'Content-Type': 'application/json'
        },
        // data: JSON.stringify(httpBody)
      })
      if (response && response?.data) {
        let dropDownDetail = response && response.data;
        // console.log(dropDownDetail,' ****')
        let checkBusinessConnc = dropDownDetail.data.filter(
          (item: any) => item.attributes.name === "Business Connection");

        let checkPersonalConnc = dropDownDetail.data.filter(
          (item: any) => item.attributes.name === "Personal Connection");

        this.setState(
          {
            businessConnection: checkBusinessConnc[0].attributes.sub_categories,
            personalConnection: checkPersonalConnc[0].attributes.sub_categories,
          },
        );
      }
    } catch (error) {
      console.error(error);
    }
  }

  // Invitations
  updateInvitations = async (status: any, senderId: any) => {
    let httpBody = {
      data: {
        status: status,
        sender_category_id: senderId
      }
    }
    try {
      const response = await axios({
        url: `${baseURL}/bx_block_request_management/requests`,
        method: 'put',
        headers: {
          'token': this.state.token,
          'Content-Type': 'application/json'
        },
        data: JSON.stringify(httpBody)
      })
      // console.log(response.data,'******** res for updated invitations')
      // this.setState({allInvitations:response.data},()=> console.log(this.state.allInvitations,'all Invitations'))
    } catch (error) {
      console.error(error);
    }
  }

  handleInvitationModal = (data: any, role: any) => {
    // console.log(role , '*****************');
    this.handleNetworkRelation(data?.account_id, "accept");
    // console.log(role , '*******');
    if (role && role === 'business') {
      this.setState({ invitationUserInfo: data, });
    } else {
      this.setState({ invitationUserInfo: data, });
    }
  }

  closeInvitationModal = () => {
    // console.log(status, catId);
    this.setState({ isShowInvitationModal: false });
  }

  handleChange = (event: any) => {
    // console.log("@@@ e======hello", event);
    this.setState({ relationValue: event });
  }

  handleInvitations = async (value: any, status: any, req_id: any, cat_id: any) => {
    // console.log(status, cat_id, req_id, 'test 123')
    let httpBody = {
      data: {
        status: status,
        sender_relation: this.state.relationValue
      }
    }
    // console.log(value,'******', status,'******', id);
    try {
      const response = await axios({
        url: `${baseURL}/bx_block_request_management/requests/${req_id}`,
        method: 'put',
        headers: {
          'token': this.state.token,
          'Content-Type': 'application/json'
        },
        data: JSON.stringify(httpBody)
      })
      if (response?.data) {
        // window.location.reload();
        this.getInvitations();
        return this.setState({ isShowInvitationModal: false });
      }
      // console.log(response.data, 'report submitted');
    } catch (error) {
      console.error(error);
      this.setState({ isShowInvitationModal: false });
    }
    this.setState({ isShowInvitationModal: false });
  }

  //addConnection
  addConnection = async (value: any, status: any, req_id: any, cat_id: any) => {
    // console.log(value, status, req_id, cat_id, 'test 123');
    this.setState({ isShowConnectionModal: false });
    let httpBody = {
      data: {
        account_id: req_id,
        sender_relation: this.state.relationValue
      }
    }
    // console.log(value,'******', status,'******', id);
    try {
      const response = await axios({
        url: `${baseURL}/bx_block_request_management/requests`,
        method: 'post',
        headers: {
          'token': this.state.token,
          'Content-Type': 'application/json'
        },
        data: JSON.stringify(httpBody)
      })
      if (response?.data) {
        // window.location.reload();
        this.getPeopleYouknow();
        return this.setState({ isShowConnectionModal: false });
      }
      // console.log(response.data, 'report submitted');
    } catch (error) {
      console.error(error);
      this.setState({ isShowConnectionModal: false });
    }
    this.setState({ isShowConnectionModal: false });
  }

  //handle add connections
  handleConnectionModal = (data: any, role: any) => {
    // console.log(role , '*****************');
    this.handleNetworkRelationforConnection(data?.account_id, "send")
    if (role && role === 'business') {
      this.setState({ invitationUserInfo: data, });
    } else {
      this.setState({ invitationUserInfo: data, });
    }
  }
  handleNetworkRelation = async (account_id: any, action: string) => {
    const user = await getStorageData('loginUser')
    const header = {
      "Content-Type": configJSON.getRequestsApiContentType,
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GetCategorySelectionByNetwork = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getNetworkRelation}?account_id=${account_id}&action_type=${action}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getRequestsApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleNetworkRelationforConnection = async (account_id: any, action: string) => {
    const user = await getStorageData('loginUser')
    const header = {
      "Content-Type": configJSON.getRequestsApiContentType,
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GetCategorySelectionByNetworkforConnection = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getNetworkRelation}?account_id=${account_id}&action_type=${action}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getRequestsApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  // join community
  onJoinCommunity = async (id: any, status: boolean) => {
    this.setState({ loadJoinCommunity: true });
    try {
      const response = await axios({
        url:
          `${baseURL}/bx_block_follow/community_joins?community_forum_id=${id}`,
        method: "post",
        headers: {
          token: localStorage.getItem('token'),
          // "Content-Type": "application/json"
        },
      });
      // console.log(response, "join community");
      if (response) {
        if (response?.data?.message === "Joining request sent") {
          this.setState((prevState) => ({
            joinRequestSent: [...prevState.joinRequestSent, id],
            loadJoinCommunity: false,
            communityPosts: [],
            fetchCommunityPostData: true,
            loadMutualCommunity: true
          }));
          this.updateUnseenCount(id);
          this.getSuggestedCommunity(id);
          this.getCommunityPosts(id);
          this.setState({
            openModal: true,
            openModalStatus: true,
            heading: `${this.state.toggleCommunityProfile?.attributes?.name} Community`,
            fetchMessage: response?.data?.message,
            loadJoinCommunity: false
          })
        } else if (response?.data?.message === "Joined the community") {
          this.setState((prevState) => ({
            joinedCommunities: [...prevState.joinedCommunities, id],
            newCommunities: this.state.newCommunities.filter(function (el: any) {
              return +el.id !== +id;
            }),
            loadJoinCommunity: false,
            communityPosts: [],
            fetchCommunityPostData: true,
            loadMutualCommunity: true
          }));
          this.updateUnseenCount(id);
          this.getSuggestedCommunity(id);
          this.getCommunityPosts(id);
          this.setState({
            openModal: true,
            openModalStatus: true,
            heading: `${this.state.toggleCommunityProfile?.attributes?.name} Community`,
            fetchMessage: response?.data?.message,
            loadJoinCommunity: false
          })
        } else if (response?.data?.message === "Disenrolled from the community") {
          this.setState((prevState) => ({
            joinedCommunities: [...prevState.joinedCommunities, id],
            newCommunities: this.state.newCommunities.filter(function (el: any) {
              return +el.id !== +id;
            }),
            loadJoinCommunity: false,
            communityPosts: [],
            fetchCommunityPostData: true,
            loadMutualCommunity: true
          }));
          this.updateUnseenCount(id);
          this.getSuggestedCommunity(id);
          this.getCommunityPosts(id);
          this.setState({
            openModal: true,
            openModalStatus: true,
            heading: `${this.state.toggleCommunityProfile?.attributes?.name} Community`,
            fetchMessage: response?.data?.message,
            loadJoinCommunity: false
          })
        } else if (response?.data?.message === "Joining request removed") {
          this.updateUnseenCount(id);
          this.getSuggestedCommunity(id);
          this.getCommunityPosts(id);
          this.setState({
            joinRequestSent: this.state.joinRequestSent.filter(function (el) {
              return el !== id;
            }),
            loadJoinCommunity: false,
            communityPosts: [],
            fetchCommunityPostData: true,
            loadMutualCommunity: true
          });
        } else {
          this.setState({ loadJoinCommunity: false });
          // console.log(response, 'join community');
        }
      }
    } catch (error: any) {
      if (error?.response?.status) {
        this.setState({
          joinedCommunities: this.state.joinedCommunities?.filter(function (el) {
            return el !== id;
          }),
          isBlockedMessage: error?.response?.data?.message,
          loadJoinCommunity: false
        }, () => this.setState({ isBlocked: true }));
      }
      this.setState({ loadJoinCommunity: false });
      console.error(error, 'error while calling api follow REQUEST');
    }
  }

  // Accept and Reject Community
  onClickAcceptOrRejectCommunity = async (id: number, joinstatus: string, communityId: number) => {

    try {
      const response = await axios({
        url:
          `${baseURL}/bx_block_follow/community_joins/${id}/manage_admin_invitation/?status=${joinstatus}`,
        method: "PUT",
        headers: {
          token: localStorage.getItem('token'),
          // "Content-Type": "application/json"
        },
      });
      this.updateUnseenCount(communityId);
      if (joinstatus === "accept") {
        this.setState({
          openModal: true,
          openModalStatus: true,
          heading: `${this.state.toggleCommunityProfile?.attributes?.name} Community`,
          fetchMessage: response?.data?.message
        })
      } else {
        this.setState({
          openModal: true,
          openModalStatus: false,
          heading: `${this.state.toggleCommunityProfile?.attributes?.name} Community`,
          fetchMessage: response?.data?.message
        })
      }
    } catch (error: any) {
      console.error(error, 'error while calling api follow REQUEST');
    }
  }

  // Follow community
  onFollowCommunity = async (id: any) => {
    this.setState({ loadFollowCommunity: true });
    try {
      const response = await axios({
        url:
          `${baseURL}/bx_block_follow/community_joins/follow?community_forum_id=${id}`,
        method: "put",
        headers: {
          token: localStorage.getItem('token'),
          // "Content-Type": "application/json"
        },
      });
      if (response?.data) {
        this.updateUnseenCount(id);
        this.setState({ loadFollowCommunity: false });
      }
      // console.log(response, "follow community");
    } catch (error) {
      console.error(error, 'error while calling api follow REQUEST');
    }
  }

  //handle Likes
  likePost = async (id: number) => {
    this.setState({ isloader: true });
    const token = this.state.token;
    const header = {
      "Content-Type": configJSON.getPostsApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getPostsApiEndpoint}/${id}/like`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.likeDislikeApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  dislikePost = async (id: number) => {
    this.setState({ isloader: true });
    const token = await this.state.token;
    const header = {
      "Content-Type": configJSON.getPostsApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getPostsApiEndpoint}/${id}/dislike`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.likeDislikeApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  // toggle likes
  toggleLike = (id: number, liked: boolean) => {
    if (liked) {
      // console.log("Unlike");
      this.setState({
        // postLike: false
        likedItems: this.state.likedItems.filter(function (el) {
          return el !== id;
        }),
      });
      this.dislikePost(id);
    } else {
      // console.log("like");
      // this.setState({postLike:true})
      this.setState((prevState) => ({
        likedItems: [...prevState.likedItems, id],
      }));
      this.likePost(id);
    }
  };

  // follow user
  onClickFollow = async (id: number, isFollowed: boolean) => {
    try {
      const response = await axios({
        url:
          `${baseURL}/bx_block_follow/follows?account_id=${id}`,
        method: "POST",
        headers: {
          token: await localStorage.getItem('token'),
          "Content-Type": "application/json"
        }
      });
      if (isFollowed) {
        this.setState({
          // postLike: false
          followedItems: this.state.followedItems.filter(function (el) {
            return el !== id;
          }),
        });
      } else {
        this.setState((prevState) => ({
          followedItems: [...prevState.followedItems, id],
        }));
      }
    } catch (error) {
      console.error(error, 'error while calling api follow REQUEST');
    }
  };

  //get all comments
  getComments = async (id: number) => {
    this.setState({ isloader: true });
    const token = this.state.token;
    const header = {
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCommentInPostApiCallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createComment}?post_id=${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getPostsApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  blockAccount = async (communityId: any, userId: any) => {
    try {
      let formdata = new FormData();
      formdata.append("community_forum_id", communityId);
      formdata.append("account_id", userId);
      const response = await axios({
        url: `${baseURL}/bx_block_follow/community_blocks`,
        method: 'post',
        headers: {
          'token': this.state.token,
          'Content-Type': 'application/json'
        },
        data: formdata
      });
      // console.log(response.data, 'block account')
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  }

  // mutual communities
  getSuggestedCommunity = async (communityId: any) => {
    try {
      const responseForMutualCommunityData = await axios({
        url: `${baseURL}/bx_block_communityforum/community_forums/${communityId}/mutual_communities/?page=1&per_page=4`,
        method: 'get',
        headers: {
          'token': this.state.token,
          'Content-Type': 'application/json'
        },
      })
      await this.setState({
        loadMutualCommunity: false,
        newCommunities: responseForMutualCommunityData?.data?.communities?.data,
      });
    } catch (error) {
      await this.setState({
        loadMutualCommunity: false,
        newCommunities: [],
      });
    }
  }

  joinnedCommunities = async () => {
    try {
      const response = await axios({
        url: `${baseURL}/bx_block_groups/community_forums/joined_communities?token=${this.state.token}`,
        method: 'get',
        headers: {
          'token': this.state.token,
          'Content-Type': 'application/json'
        },
      });
      // console.log(response.data, 'block account')
      // window.location.reload();
    } catch (error) {
      console.log(error);
    }
  }

  adminCommunities = async (communityId: any, userId: any) => {
    try {
      const response = await axios({
        url: `${baseURL}/bx_block_follow/community_blocks`,
        method: 'post',
        headers: {
          'token': this.state.token,
          'Content-Type': 'application/json'
        },
        // data: formdata
      });
      // console.log(response.data, 'block account')
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  }

  // handle menu
  handleMenuClick(e: any, id: any) {
    if (e === "hide") {
      this.savePost(id, "hide");
    } else if (e === "report") {
      this.setState({ isReportIssueVisible: true, reportPostID: id });
    } else {
      this.blockAccount(e, id);
      // console.log('block')
    }
  }

  handlePostList = (data: any, status: string) => {
    const postData = this.state.communityPosts;
    if (status === "update") {
      console.log(status, data);
      const updatePostList = postData?.map((item: any) => {
        if (item.id === data.id) {
          return data
        } else {
          return item
        }
      })
      this.setState({ communityPosts: updatePostList });
    } else if (status === "delete") {
      const updatePostList = postData?.filter((item: any) => {
        return item.id != data?.id
      })
      this.setState({ communityPosts: updatePostList });
    }
  }

  // report posts
  handleReportOk = async (value: any) => {
    console.log(value, 'data');
    // await this.savePost(this.state.reportPostID, "report");
    let httpBody = {
      data: {
        attributes: {
          context: value
        }
      }
    }
    try {
      const response = await axios({
        url: `${baseURL}/bx_block_posts/posts/${this.state.reportPostID}/report`,
        method: 'post',
        headers: {
          'token': this.state.token,
          'Content-Type': 'application/json'
        },
        data: JSON.stringify(httpBody)
      })
      // console.log(response.data, 'report submitted');
      this.setState({ isReportIssueVisible: false });
    } catch (error) {
      console.error(error);
      this.setState({ isReportIssueVisible: false });
    }
    this.setState({ isReportIssueVisible: false });
    // setDropDownVisible(false);
  };

  handleCancel = (e: any) => {
    // console.log(e);
    this.setState({ isReportIssueVisible: false, reportPostID: "" });
  };
  onChangeValue = (e: any) => {
    // console.log("radio checked", e.target.value);
    this.setState({ value: e.target.value });
  };

  // save posts
  savePost = async (id: number, endString: string) => {
    this.setState({ isloader: true });
    const token = this.state.token;
    const header = {
      "Content-Type": configJSON.getPostsApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getPostsApiEndpoint}/${id}/${endString}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  // add comments
  addComment = (id: number, comment: any) => {
    // console.log(id, comment);
    this.setState({ isloader: true });
    const token = this.state.token;
    const header = {
      "Content-Type": configJSON.getPostsApiContentType,
      token: token,
    };
    let body = {
      data: {
        attributes: {
          post_id: id,
          comment: comment,
          // "taggings_attributes": [
          //     {
          //         "account_id": 2
          //     }
          // ]
        },
      },
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addCommentInPost = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createComment}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  onItemPress = async (id: string) => {
    let adminData: any = this.state?.adminCommunities?.find((c: any) => +c.id === +id);
    let joinData: any = this.state?.joinnedCommunityData?.find((c: any) => +c.id === +id);
    await this.setState({
      communityPosts: [],
      paginationOfPageCount: 1,
      fetchCommunityPostData: true
    })
    if (adminData) {
      await this.setState({
        toggleCommunityProfile: adminData
      })
      await this.getCommunityPosts(id);
      await this.getSuggestedCommunity(id);
    } else if (joinData) {
      await this.setState({
        toggleCommunityProfile: joinData
      })
      await this.getCommunityPosts(id);
      await this.getSuggestedCommunity(id);
    } else {
      await this.updateUnseenCount(id);
      await this.getCommunityPosts(id);
    }
  }
  // unseen post count
  updateUnseenCount = async (id: any) => {
    try {
      const response = await axios({
        url: `${baseURL}/bx_block_groups/community_forums/${id}`,
        method: 'get',
        headers: {
          'token': this.state.token,
          'Content-Type': 'application/json'
        },
      })
      await this.setState({
        toggleCommunityProfile: response?.data?.data
      })
      if (response?.data?.data?.attributes?.admin) {
        const updateData = await this.state.adminCommunities?.filter((curElem: any) => {
          return curElem?.id != id;
        });
        updateData.unshift(response?.data?.data);
        await this.setState({ adminCommunities: updateData });
      }
      if (!response?.data?.data?.attributes?.admin && response?.data?.data?.attributes?.joined) {
        const updateData = await this.state.joinnedCommunityData?.filter((curElem: any) => {
          return curElem?.id != id;
        })
        updateData.unshift(response?.data?.data);
        await this.setState({ joinnedCommunityData: updateData });
      } else if (!response?.data?.data?.attributes?.admin && !response?.data?.data?.attributes?.joined) {
        const updateData = await this.state.joinnedCommunityData?.filter((curElem: any) => {
          return curElem?.id != id;
        })
        await this.setState({ joinnedCommunityData: updateData });
      }
    } catch (error) {
      await this.setState({
        toggleCommunityProfile: []
      })
    }
  }

  onItemPressCheck = (id: any) => {
    console.log(id, 'onItemPressCheck***')
  }

  getDomainSkills(userDataHere: any) {
    if (
      isEmpty(userDataHere)
      // isEmpty(userDataHere.attributes) ||
      // isEmpty(userDataHere.attributes.domain_skills)
    )
      return "";
    const domainSkillsPresent = userDataHere;
    const domainSkillsStr = domainSkillsPresent.join(", ");
    // console.log("domainSkillsStr = ", domainSkillsStr);
    return domainSkillsStr;
  }

  onRemoveImage = (index: number) => {
    let imageData = this.state.createPostImages.filter((id: number) => {
      return id !== index;
    })
    let videoData = this.state.videoArrayList.filter((id: number) => {
      return id !== index;
    })
    let data = this.state.AllMedia;
    data.splice(index, 1);
    this.setState({
      AllMedia: data,
      createPostImages: imageData,
      videoArrayList: videoData
    });
    // let data = this.state.AllMedia;
    // data.splice(index, 1);
    // this.setState({ AllMedia: data });
  };

  deleteItem = (index: any) => {
    let deleteExistingMedias: any = cloneDeep(this.state.deleteExistingMedias);
    this.state.AllMedia.forEach((item: any, indexHere: any) => {
      if (index === indexHere && item.blob_id) {
        deleteExistingMedias.push(item);
      }
    });

    console.log("Deleting: ", index);
    let clonedArr = [...this.state.AllMedia].filter(
      (el: any, i: number) => index !== i
    );
    this.setState({ AllMedia: clonedArr, deleteExistingMedias });
  };

  createPostCommunity = async () => {
    var ii: any = this.state.getMentionsArray;
    console.log(this.state.getMentionsArray, this.state.selectTags)
    var jj: any = this.state.selectTags;
    var tags = [];
    for (var i = 0; i < ii.length; i++) {
      for (var j = 0; j < jj.length; j++) {
        let a = ii[i].value;
        let b = jj[j].value;
        let c = b.substring(0, b?.split(" ")[0].length)
        if (a == c) {
          tags.push(jj[j].key)
        }
      }
    }
    let t = this.state.tagged_connection_ids;
    t.push(...tags);
    this.setState({ tagged_connection_ids: t });
    console.log("value", this.state.tagged_connection_ids)

    this.setState({
      isPostloader: true,
    })
    let trimmedStr = this.state.createPostDescription.trim();
    var formdata = new FormData();

    formdata.append("body", trimmedStr);
    if (this.state.AllMedia.length > 0) {
      this.state.AllMedia.forEach((item: any) => {
        if (item.hasOwnProperty("blob_id")) delete item.blob_id;
        if (item.data) formdata.append("media[]", item.data);
        if (item.content_type && item.uri) formdata.append("media[]", item);
      });
    }
    if (this.state.tagged_connection_ids) {
      const uniqueTaggedIds = Array.from(
        new Set(this.state.tagged_connection_ids)
      );
      uniqueTaggedIds.forEach((taggedId: any) => {
        formdata.append("taggings_attributes[]account_id", taggedId);
      });
    }
    if (this.state.postlocation && this.state.postlocation !== "") {
      formdata.append("location", this.state.postlocation);
    }
    if (this.state.deleteExistingMedias.length > 0) {
      this.state.deleteExistingMedias.forEach((item: any) => {
        if (item.blob_id) formdata.append("blob_ids[]", item.blob_id);
      });
    }

    formdata.append(
      "community_forum_id",
      this.state?.toggleCommunityProfile?.attributes?.id
    );

    try {
      const response = await axios({
        url:
          `${baseURL}/bx_block_posts/posts`,
        method: "POST",
        headers: {
          token: localStorage.getItem('token'),
          // "Content-Type": "multipart/form-data"
          // "Content-Type": "application/json"
        },
        data: formdata,
      });
      // console.log(response, "create post");
      if (response?.data?.data?.attributes?.status === 'pending') {
        this.setState({
          isCreatePostVisible: false,
          createPostDescription: '',
          AllMedia: [],
          isPostloader: false,
          isPostedPost: true,
          postlocation: '',
          postLocationOpen: false,
          tagsOpen: false,
          tagged_connection_ids: [],
        }
        );
        // console.log(this.formRef.current, 'test***');
        // this.formRef.current.resetFields();
      } else {
        this.setState({
          isCreatePostVisible: false,
          createPostDescription: '',
          AllMedia: [],
          isPostloader: false,
          postlocation: '',
          postLocationOpen: false,
          communityPosts: [],
          tagsOpen: false,
          tagged_connection_ids: [],
        });
        // window.scrollTo(0, 0);
        // console.log(this.formRef.current, 'test***');
        // this.formRef.current.resetFields();
        this.getCommunityPosts(this.state?.toggleCommunityProfile?.attributes?.id);
        this.updateUnseenCount(this.state?.toggleCommunityProfile?.attributes?.id);
      }
    } catch (error) {
      console.error(error, 'error while calling api follow REQUEST');
      this.setState({
        isPostloader: false,
      })
    }
  }

  //get joining req
  getJoiningReq = async (param: any) => {
    try {
      const response = await axios({
        url: `${baseURL}/bx_block_follow/community_joins?community_forum_id=${param}`,
        method: 'get',
        headers: {
          'token': this.state.token,
          'Content-Type': 'application/json'
        }
      })
      this.setState({ joiningReq: response?.data?.data })
      // console.log(response.data, 'joining req', response?.data?.data);
    } catch (error) {
      console.error(error);
    }
  }

  // get post req
  getPostReq = async (param: any) => {
    try {
      const response = await axios({
        url: `${baseURL}/bx_block_communityforum/community_forums/${param}/settings/?pending_posts=true`,
        method: 'get',
        headers: {
          'token': this.state.token,
          'Content-Type': 'application/json'
        }
      })
      this.setState({ postReq: response?.data?.pending_posts?.data })
      // console.log(response.data, 'post req');
    } catch (error) {
      console.error(error);
    }
  }

  rejectJoinReason = async (param: any, communityInfo: any) => {
    var FormData = require('form-data');
    var data = new FormData();
    data.append('message', this.state?.reasonReject);
    try {
      const response = await axios({
        url: `${baseURL}/bx_block_follow/community_joins/${param.id}/reject`,
        method: 'PUT',
        headers: {
          'token': this.state.token,
          'Content-Type': 'application/json'
        },
        data: data
      })

      await this.getJoiningReq(communityInfo);
      this.setState({ isRejectVisible: false, reasonReject: '' })
      // this.setState({ postReq: response?.data?.pending_posts?.data })
      // console.log(response.data, 'rejectJoinReason');
    } catch (error) {
      console.error(error);
    }
  }

  rejectPostReason = async (param: any) => {
    // console.log(param, 'reject', this.state?.reasonPostReject);
    // this.setState({ isRejectPostVisible: false, reasonPostReject: '' });
    try {
      const response = await axios({
        url: `${baseURL}/bx_block_posts/posts/${param}/reject`,
        method: 'POST',
        headers: {
          'token': this.state.token,
          'Content-Type': 'application/json'
        },
        data: { message: this.state?.reasonPostReject }
      })
      window.location.reload();
      // this.setState({ postReq: response?.data?.pending_posts?.data })
      // console.log(response.data, 'rejectPostReason');
    } catch (error) {
      console.error(error);
    }
  }

  acceptJoining = async (param: any) => {
    try {
      const response = await axios({
        url: `${baseURL}/bx_block_follow/community_joins/${param}/accept`,
        method: 'PUT',
        headers: {
          'token': this.state.token,
          'Content-Type': 'application/json'
        },
      })
      window.location.reload();
      // this.setState({ postReq: response?.data?.pending_posts?.data })
      // console.log(response.data, 'acceptJoining');
    } catch (error) {
      console.error(error);
    }
  }

  //accept communitiy posts
  acceptPost = async (param: any) => {
    try {
      const response = await axios({
        url: `${baseURL}/bx_block_posts/posts/${param}/approve`,
        method: 'POST',
        headers: {
          'token': this.state.token,
          'Content-Type': 'application/json'
        },
      })
      window.location.reload();
      // this.setState({ postReq: response?.data?.pending_posts?.data })
      // console.log(response.data, 'acceptJoining');
    } catch (error) {
      console.error(error);
    }
  }

  async GetProfileInformation() {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GetProfielInformationId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.GetProfileInfoEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.SearchAPIMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // console.log("GetProfileInfo--->" + JSON.stringify(requestMessage));
    return true;
  }
  getAccountDetails = async (id: string) => {
    // console.log("account", id);
    const header = {
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GetAccountDetailsApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAccountDetailsEndPoint}/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAccountDetailsApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // console.log("getAccount--->" + JSON.stringify(requestMessage));
    return true;
  };

  customFollowCount = () => {
    let data: any = this.state?.customFollow?.find((c: any) => +c.id === +this.state.toggleCommunityProfile?.attributes?.id)
    if (data) {
      return data?.follower_count;
    } else {
      return this.state.toggleCommunityProfile?.attributes?.follower_count;
    }
  }

  customPostCount = () => {
    let data: any = this.state?.postInfo?.find((c: any) => +c.id === +this.state.toggleCommunityProfile?.attributes?.id)
    if (data) {
      return data?.posts_data?.count;
    } else {
      return this.state.toggleCommunityProfile?.attributes?.posts_data?.count;
    }
  }

  handleEdit = (item: any) => {
    let tags: Array<any> = [];
    let getTags: Array<any> = [];
    item?.attributes?.taggings?.data.map((item: any) => {
      const name = item?.attributes?.role === "business" ?
        `${item?.attributes?.profile?.data?.attributes?.company_name}` :
        `${item?.attributes?.full_name}`

      tags = [...tags, { value: name, key: item.id }]
      console.log(name, getMentions(`@${name}`))
      getTags = [...getTags, { prefix: '@', value: name }]
    })
    this.setState({
      isEditPost: true,
      editPostId: item?.id,
      createPostDescription: item.attributes?.body,
      createPostImages: [],
      videoArrayList: [],
      deleteExistingMedias: [],
      tagged_connection_ids: [],
      selectTags: tags,
      getMentionsArray: getTags,
      tagsOpen: getTags.length > 0 ? true : false,
      postlocation: item.attributes.location,
      postLocationOpen: item.attributes.location ? true : false,
      AllMedia: item.attributes?.media
    })
  }

  editPostCommunity = async () => {
    var ii: any = this.state.getMentionsArray;
    var jj: any = this.state.selectTags;
    console.log(this.state.getMentionsArray, this.state.selectTags)
    var tags = [];
    for (var i = 0; i < ii.length; i++) {
      for (var j = 0; j < jj.length; j++) {
        let a = ii[i].value;
        let b = jj[j].value;
        let c = b.substring(0, b?.split(" ")[0].length)
        if (a == c) {
          tags.push(jj[j].key)
        }
      }
    }
    let t = this.state.tagged_connection_ids;
    t.push(...tags);
    this.setState({ tagged_connection_ids: t });
    console.log("value", this.state.tagged_connection_ids)

    this.setState({
      isPostloader: true,
    })
    let trimmedStr = this.state.createPostDescription.trim();
    var formdata = new FormData();

    formdata.append("body", trimmedStr);

    if (this.state.AllMedia.length > 0) {
      this.state.AllMedia.forEach((item: any) => {
        if (item.hasOwnProperty("blob_id")) delete item.blob_id;
        if (item.data) formdata.append("media[]", item.data);
        if (item.content_type && item.uri) formdata.append("media[]", item);
      });
    }
    if (this.state.tagged_connection_ids) {
      const uniqueTaggedIds = Array.from(
        new Set(this.state.tagged_connection_ids)
      );
      uniqueTaggedIds.forEach((taggedId: any) => {
        formdata.append("taggings_attributes[]account_id", taggedId);
      });
    }
    if (this.state.postlocation && this.state.postlocation !== "") {
      formdata.append("location", this.state.postlocation);
    }
    if (this.state.deleteExistingMedias.length > 0) {
      this.state.deleteExistingMedias.forEach((item: any) => {
        if (item.blob_id) formdata.append("blob_ids[]", item.blob_id);
      });
    }

    try {
      const response = await axios({
        url: `${baseURL}/bx_block_posts/posts/${this.state.editPostId}`,
        method: "PUT",
        headers: {
          token: localStorage.getItem('token'),
          "Content-Type": "multipart/form-data"
        },
        data: formdata,
      });
      if (response?.data?.data?.attributes?.status === 'pending') {
        this.setState({
          isEditPost: false,
          createPostDescription: '',
          AllMedia: [],
          isPostloader: false,
          isPostedPost: true,
          tagsOpen: false,
          tagged_connection_ids: [],
          postlocation: '',
          postLocationOpen: false,
        });
      } else {
        this.setState({
          isEditPost: false,
          createPostDescription: '',
          AllMedia: [],
          isPostloader: false,
          tagsOpen: false,
          tagged_connection_ids: [],
          postlocation: '',
          postLocationOpen: false,
          communityPosts: []
        });
        this.getCommunityPosts(this.state?.toggleCommunityProfile?.attributes?.id);
        this.updateUnseenCount(this.state?.toggleCommunityProfile?.attributes?.id);
      }
    } catch (error) {
      console.error(error, 'error while calling api follow REQUEST');
      this.setState({
        isPostloader: false,
      })
    }
  }

  openCreateEditModal = () => {
    // if (data === 'edit') {
    // return
    return this.setState({
      profile_pic: this.state.toggleCommunityProfile?.attributes?.profile_pic,
      cover_pic: this.state.toggleCommunityProfile?.attributes?.cover_pic,
      name: this.state.toggleCommunityProfile?.attributes?.name,
      topics: this.state.toggleCommunityProfile?.attributes?.topics,
      description: this.state.toggleCommunityProfile?.attributes?.description,
      isCreateCommunity: false,
      isEditCommunityVisible: true,
    }, () => console.log(
      this.state.isCreateCommunity,
      this.state.isEditCommunityVisible,
      this.state.profile_pic,
      this.state.cover_pic,
      this.state.name,
      this.state.topics,
      this.state.description))
    // }
  }

  inviteCommunity = async (id: string) => {
    // console.log("account", id);
    const header = {
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GetAccountDetailsApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAccountDetailsEndPoint}/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAccountDetailsApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // console.log("getAccount--->" + JSON.stringify(requestMessage));
    return true;
  };

  handleInviteFriends = async () => {
    let inviteFriendslistObject = await this.state.inviteFriendslist?.map((item: any, index: number) => {
      if (item?.checked) {
        return { ...item, checked: !item.checked }
      } else {
        return item
      }
    })
    await this.setState({
      isInviteCommunity: false,
      inviteFriendslist: inviteFriendslistObject
    })
  }

  onCheckChange = (e: any, data: any) => {
    // console.log(data, 'checkchange__')
    let ListDataObject = []
    let inviteFriendslistObject = this.state.inviteFriendslist?.map((item: any, index: number) => {
      if (item?.attributes?.account_id === data?.attributes?.account_id) {
        return { ...item, checked: !item.checked }
      } else if (item.checked) {
        return { ...item, checked: !item.checked }
      } else {
        return item
      }
    })
    let listData: any = this.state.sendinvitationsAdmin.find((item: any) => item?.toString() == data?.attributes?.account_id?.toString())
    // console.log(listData, "dkakjglkajsga alksgjal")
    if (listData) {
      ListDataObject = this.state.sendinvitationsAdmin.filter((ele: any, i: any) => {
        console.log(ele);
        return ele.toString() != data?.attributes?.account_id?.toString()
      })
      this.setState({
        sendinvitationsAdmin: ListDataObject,
        inviteFriendslist: inviteFriendslistObject
      })
    } else {
      ListDataObject.push(data?.attributes?.account_id)
      this.setState({
        sendinvitationsAdmin: ListDataObject,
        inviteFriendslist: inviteFriendslistObject
      })

    }
    // if (data?.checked) {
    //   let listData: any = this.state.sendinvitationsAdmin;
    //   listData.push(data?.attributes?.account_id)
    //   this.setState({ sendinvitationsAdmin: listData })
    // } else {
    //   let listData: any = this.state.sendinvitationsAdmin.filter((ele: any, i: any) => {
    //     return ele.toString() != data?.attributes?.account_id?.toString()
    //   })
    //   this.setState({ sendinvitationsAdmin: listData })
    // }
  };

  getAcceptedConnections = async () => {
    // console.log('friendsList_ started')
    this.setState({
      isLoadInviteFnds: true
    })
    const header = {
      "Content-Type": configJSON.APPLICATIONJSONContentType,
      token: await localStorage.getItem("token"),
    };

    let endpoint = configJSON.getNetConnectionEndPoint + "/?status=accepted";
    // if (this.state.searchTxt) {
    //   endpoint = endpoint + `&search=${this.state.searchTxt}`;
    // }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAcceptedConnectionsId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    //   requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),httpBody);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.SearchAPIMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  sendInvitationsAdminApi = async () => {
    // console.log('friendsList_ started')
    this.setState({
      isAdminInvite: true
    })
    const header = {
      // "Content-Type": configJSON.APPLICATIONJSONContentType,
      token: await localStorage.getItem("token"),
    };

    let endpoint = configJSON.postAdmininviteCommunity;

    console.log('checkchange__', this.state.sendinvitationsAdmin, this.state.toggleCommunityProfile)

    var form = new FormData();

    await form.append("community_forum_id", this.state.toggleCommunityProfile?.id)

    if (this.state.sendinvitationsAdmin.length > 0) {
      this.state.sendinvitationsAdmin.forEach((item: any) => {
        form.append("account_ids[]", item);
      });
    }

    // console.log(form ,'checkchange__ form')
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.sendInviteConnectionId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      form);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.sendInvitePost
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  // delete post 
  deletePost = async (id: any) => {
    this.setState({
      deleteId: id
    })
    const header = {
      "Content-Type": configJSON.getPostsApiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deletePostApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deletePostEndPoint}/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.DELETEMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  // ======= Invite Friends =========
  async createChannel(userId: any, friendRes: any) {
    // console.log(friendRes, 'friendRes')
    // await sb.connect(userId.toString(), function (user, error) {
    //   if (error) {
    //     return;
    //   }
    // });
    await sb.connect(userId.toString(), api_Token, function (user, error) {
      if (error) {
        return;
      }
      // console.log(user, id, 'get connect')
    });
    var params = new sb.GroupChannelParams();
    params.isPublic = false;
    params.isEphemeral = false;
    params.isDistinct = true;
    params.isSuper = false;
    // params. = false;
    params.addUserIds([userId.toString(), friendRes.toString()]);
    params.operatorUserIds = [userId.toString(), friendRes.toString()];   // Or .operators(Array<User>)
    // params.name = NAME;
    // params.channelUrl = UNIQUE_CHANNEL_URL; // In a group channel, you can create a new channel by specifying its unique channel URL in a 'GroupChannelParams' object.
    // params.coverImage = FILE;       // Or .coverUrl = COVER_URL;
    // params.data = DATA;
    // params.customType = CUSTOM_TYPE;

    sb.GroupChannel.createChannel(params, (groupChannel, error) => {
      // console.log("GroupChannel.createChannel", groupChannel, error);
      // console.log("GroupChannel.createChannel",groupChannel, error, groupChannel.getUndeliveredMemberCount())
      if (error) {
        // Handle error.
        // console.log(error)
      }

      // groupChannel.members;
      this.setState({ GroupChannel: groupChannel })

      const link = window.location.origin + `/community/block?id=${this.state.toggleCommunityProfile?.id}`
      this.sendMessage(link, userId.toString())
      // const channelUrl = groupChannel?.url;
      // sb.markAsReadAll((mess, err) => {
      //   console.log("markAsReadAll mess, err", mess, err)
      // })

      // sb.addFriends(['1685'], (friends, error)=>{
      //   console.log("friends, error",friends, error)
      // })
      // var channelHandler = new sb.ChannelHandler();
      // this.getMessages(groupChannel)
    });
  }

  async sendMessage(data: any, myId: any) {
    let value = data.trim();
    // console.log("Is connected?", value, state.isConnected ,this.state.GroupChannel);

    // NetInfo.fetch().then(async state => {
    // console.log("Is connected?", value, state.isConnected ,this.state.GroupChannel);
    // if (state.isConnected) {
    //   console.log('send messages from online')
    // let offmessage = this.state.offlineMessageList;
    // offmessage.push({ type: 'text', message: value, isMessage: "offline" })

    const params = new sb.UserMessageParams();
    params.message = value;
    params.mentionType = 'users';
    params.translationTargetLanguages = ['en', 'de'];
    params.pushNotificationDeliveryOption = 'default';


    this.state.GroupChannel.sendUserMessage(params, async (userMessage: any, error: any) => {
      console.log(
        "this.state.GroupChannel.sendUserMessage",
        userMessage,
        error
      );
      if (error) {
        return;
      }
      const message = userMessage;
      // SendBirdDesk.Ticket.getUrlPreview(userMessage.message, (response, error) => {
      //   if (error) {
      //     // Handle error.
      //   }
      // })

      // let subtitle = myId.profile.data.attributes.domain_skills[0];
      // const link = await dynamicLinks().buildShortLink({
      //   link:
      //     window.location.origin +
      //     "/chat?id=" +
      //     myId.account_id +
      //     "&name=" +
      //     myId.full_name +
      //     "&subTitle=" +
      //     subtitle,
      //   domainUriPrefix: "https://appliedapp.page.link",
      //   ios: {
      //     bundleId: 'com.applied.LinkedInLikeApp',
      //   },
      //   android: {
      //     packageName: 'com.LinkedInLikeApp',
      //   },
      //   navigation: {
      //     forcedRedirectEnabled: true,
      //   }
      // });
      // console.log("linklinklinklink", link);
      // this.sendMail(link);
      // }
    });
  }

  // Customizable Area End
}
