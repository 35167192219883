import React, { FunctionComponent } from 'react';
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { Typography, Space, Modal, Divider } from 'antd';
import 'antd/dist/antd.css';
const { Title, Text } = Typography;

export const ViewAllDialog = (props: any) => {
    const { title, visible, body, handleCancel } = props;
    return (
        <Modal
            bodyStyle={{
                width: "100%",
                maxHeight: "80vh",
                borderRadius: "5px",
                overflowY: "auto",
            }}
            style={{ borderWidth: "0px", borderRadius: "4px", overflow: "hidden" }}
            centered
            // placement="bottomRight"
            title={
                <div
                    style={{
                        width: "100%",
                        cursor: "move",
                        fontWeight: "bold",
                        borderWidth: "0px"
                    }}
                    onFocus={() => { }}
                    onBlur={() => { }}
                // end
                >
                    {title}
                </div>
            }
            visible={visible}
            onCancel={handleCancel}
            footer={null}
        >
            <>
                {
                    body?.map((item: any, id: number) => {
                        const { name, year, description, designation } = item;
                        if (id + 1 < body.length) {
                            return <div key={id}>
                                <Space
                                    align="start"
                                    direction="vertical"
                                >
                                    <Text style={{ fontWeight: 500, fontSize: "14px", color: "rgba(0, 0, 0, 0.85)" }}>{year} {name}</Text>
                                    <Text style={{ fontWeight: 400, fontSize: "14px", color: "rgb(117, 117, 117)" }}>{description}{designation}</Text>
                                </Space>
                                <Divider style={{ margin: "7px 0px" }} />
                            </div>
                        } else {
                            return <div key={id}>
                                <Space
                                    align="start"
                                    direction="vertical"
                                >
                                    <Text style={{ fontWeight: 500, fontSize: "14px", color: "rgba(0, 0, 0, 0.85)" }}>{year} {name}</Text>
                                    <Text style={{ fontWeight: 400, fontSize: "14px", color: "rgb(117, 117, 117)" }}>{description}{designation}</Text>
                                </Space>
                            </div>
                        }
                    })
                }
            </>
        </Modal>
    );
};
