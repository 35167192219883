import React, { Component } from "react";
// Customizable Area Start
import {
  PageHeader,
  Card,
  Row,
  Col,
  Form,
  Input,
  Typography,
  Button,
  Spin,
  DatePicker,
  Space,
  Select,
  Avatar,
  Upload,
  message,
} from "antd";
const { Title } = Typography;
import { editLogo } from "../assets";
const style = require(`../../../social-media-account/src/login.module.css`);
import "../profile.css";
import { PlusCircleFilled } from "@ant-design/icons";
import EditPersonalProfileController from "./EditPersonalProfileController.web";
import { AlertMessage } from "../../../alert/src/AlertMessage.web";
import moment from "moment";
import { GoogleLogin } from 'react-google-login';
const { Option } = Select;
const userRegex = /^[a-zA-Z ]*$/;
const emailReg = RegExp(
  /^([a-zA-Z0-9_\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/
);
// Customizable Area End

export default class EditPersonalProfile extends EditPersonalProfileController {
  // Customizable Area Start
  render() {
    // console.log(this.state.children);
    const { sessionData } = this.state;
    return (
      <div style={{ backgroundColor: "#eff8fe", height: '100%' }} >
        {this.redirectUser()}
        <Row
          style={{
            // minHeight: "90vh",
            padding: "60px 0",
            backgroundColor: "#eff8fe",
          }}
        >
          <Col span={24}  >
            <Title
              level={2}
              style={{ textAlign: "center", marginBottom: "15px" }}
            >
              Edit Your Personal Profile
            </Title>
          </Col>
          <Col span={6} xs={2} sm={2} md={3} lg={3} xl={6} xxl={6} />
          <Col
            span={12} xs={20} sm={20} md={18} lg={18} xl={12} xxl={12}
            style={{ height: "100%" }}
            className="basic_profile_card"
          >
            {this.state.profileLoading ? <Spin /> :

              <Form
                // initialValues={{
                //   full_name: this.state.pre_data?.full_name
                //     ? this.state.pre_data.full_name
                //     : sessionData && sessionData ? sessionData.first_name ? sessionData.first_name : sessionData.full_name : "",
                //   primary_email: this.state.pre_data?.primary_email
                //     ? this.state.pre_data.primary_email
                //     : sessionData && sessionData ? sessionData.email : "",
                //   secondary_email: this.state.pre_data?.secondary_email
                //     ? this.state.pre_data.secondary_email
                //     : this.state.updateProfile.secondary_email,
                //   employment_type: this.state.pre_data?.employment_type
                //     ? this.state.pre_data.employment_type
                //     : this.state.updateProfile?.employment_type,
                //   domain_skills: this.state.pre_data?.domain_skills
                //     ? this.state.pre_data.domain_skills
                //     : this.state.updateProfile?.domain_skills,
                //   personal_skills: this.state.pre_data?.personal_skills
                //     ? this.state.pre_data.personal_skills
                //     : this.state.updateProfile?.personal_skills,
                //   profile_pic: this.state.profileUrl ? this.state.profileUrl : this.state.updateProfile?.photo,
                //   date_of_birth: this.state.pre_data?.date_of_birth ? moment(this.state.pre_data.date_of_birth) : moment(new Date(this.state.updateProfile?.date_of_birth))
                // }}
                initialValues={{
                  full_name: this.state.pre_data?.profile ? this.state.pre_data?.profile?.full_name :
                    this.state.pre_data?.full_name,
                  primary_email: this.state.pre_data?.profile ? this.state.pre_data?.profile?.primary_email :
                    this.state.pre_data?.email,
                  secondary_email: this.state.pre_data?.profile ? this.state.pre_data?.profile?.secondary_email :
                    this.state.pre_data?.secondary_email,
                  employment_type: this.state.pre_data?.profile ? this.state.pre_data?.profile?.employment_type :
                    this.state.pre_data?.employment_type,
                  domain_skills: this.state.pre_data?.profile ? this.state.pre_data?.profile?.domain_skills :
                    this.state.pre_data?.domain_skills,
                  personal_skills: this.state.pre_data?.profile ? this.state.pre_data?.profile?.personal_skills :
                    this.state.pre_data?.personal_skills,
                  profile_pic: this.state.pre_data?.profile?.profileUrl ? this.state.pre_data?.profile?.profileUrl :
                    this.state.pre_data?.photo,
                  date_of_birth: this.state.pre_data?.profile ? moment(this.state.pre_data?.profile?.date_of_birth) :
                    moment(this.state.pre_data?.date_of_birth)
                }}
                onFinish={this.handleData}
              >
                <Card>
                  <div
                    style={{
                      justifyContent: "center",
                      display: "flex",
                      marginBottom: "16px",
                    }}
                  >
                    <Form.Item
                      rules={[{ required: true, message: "Required field" }]}
                      name="profile_pic"
                    >
                      <Upload
                        name="file"
                        showUploadList={false}
                        multiple={false}
                        // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                        beforeUpload={(file) => this.beforeUpload(file)}
                        onChange={this.handleImageChange}
                        onRemove={() => {
                          this.setState({
                            profileUrl: "",
                            profilePictureFile: {},
                          });
                        }}
                      >
                        <div
                          style={{
                            position: "relative"
                          }}
                        >
                          <Avatar size={64} src={this.state.profileUrl} style={{}} />
                          <img
                            src={editLogo}
                            width={22}
                            style={{
                              position: "absolute",
                              top: "41px",
                              left: "45px"
                            }}
                          />
                        </div>
                      </Upload>
                    </Form.Item>
                  </div>
                  <Row
                    gutter={15}
                  >
                    <Col span={12} xs={24} sm={12} md={12} lg={12} xl={12} xxl={12} >
                      <Form.Item
                        rules={[
                          { required: true, message: "Required field" },
                          {
                            min: 1,
                            max: 40,
                            message: "Max length should be 40.",
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (
                                !value ||
                                getFieldValue("full_name").match(userRegex)
                              ) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                new Error("can enter only alphabets")
                              );
                            },
                          }),
                        ]}
                        name="full_name"
                      >
                        <Input
                          className={style.input_fields}
                          name="full_name"
                          placeholder="Full Name"
                          // disabled={true}
                          style={{ borderWidth: 1 }}
                        />
                      </Form.Item>

                    </Col>
                    <Col span={12} xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                      <Form.Item
                        rules={[
                          { required: true, message: "Required field" },
                        ]}
                        name="employment_type"
                      >
                        <Select
                          id={"select"}
                          className={style.input_fields_select}
                          // style={{ padding: "0 !important",border:'1px solid #6cb4cc' }}
                          placeholder="Employment Type"
                          bordered={true}
                          dropdownStyle={{
                            borderRadius: "15px",
                            border: "1px solid #7aacd6",
                            paddingTop: "0px",
                            paddingBottom: "0px",
                          }}

                          notFoundContent={
                            this.state.loading ? (
                              <Spin size="small" />
                            ) : (
                              "No Data Found "
                            )
                          }
                          size="large"

                        >
                          {this.state.employment_type}

                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row
                    gutter={15}
                  >
                    <Col span={12} xs={24} sm={12} md={12} lg={12} xl={12} xxl={12} >
                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: "Required field",
                          },
                          {
                            type: "email",
                            message: "It is not valid email",
                          },
                        ]}
                        name="primary_email"
                      >
                        <Input
                          className={style.input_fields}
                          name="primary_email"
                          placeholder="Primary Email"
                          disabled={true}
                          style={{ borderWidth: 1 }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12} xs={24} sm={12} md={12} lg={12} xl={12} xxl={12} >
                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: "Required field",
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (!value || emailReg.test(value)) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                new Error("Please Enter Valid Email!")
                              );
                            },
                          }),
                        ]}
                        name="secondary_email"
                      >
                        <Input
                          className={style.input_fields}
                          name="secondary_email"
                          placeholder="Secondary Email"
                          style={{ borderWidth: 1 }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row
                    gutter={15}
                  >
                    <Col span={12} xs={24} sm={12} md={12} lg={12} xl={12} xxl={12} >

                      {this.state.domainSkillVisible && (
                        <Form.Item
                        // name="other_domain_skills"
                        // rules={[
                        //   { required: true, message: "Required field" },
                        //   {
                        //     min: 1,
                        //     max: 20,
                        //     message: "Max length should be 20.",
                        //   },
                        //   ({ getFieldValue }) => ({
                        //     validator(_, value) {
                        //       if (
                        //         !value ||
                        //         value.match(userRegex)
                        //       ) {
                        //         return Promise.resolve();
                        //       }
                        //       return Promise.reject(
                        //         new Error("can enter only alphabets")
                        //       );
                        //     },
                        //   }),
                        // ]}
                        >
                          <Input
                            className={style.input_fields}
                            // name="other_domain_skills"
                            placeholder="Other Domain skills"
                            style={{ borderWidth: 1 }}
                            autoFocus
                            onChange={(e: any) => {
                              this.setState(
                                {
                                  other_domain_skill: e.target.value
                                }
                              )
                            }}
                          />
                        </Form.Item>
                      )}
                      <Form.Item
                        rules={[
                          { required: true, message: "Required field" },
                        ]}
                        name="domain_skills"
                      >
                        {this.state.pre_data && this.state.pre_data.domain_skills ? (
                          <Select
                            mode="multiple"
                            className={style.input_fields_select}
                            placeholder="Domain skills"
                            // style={{ padding: "0 !important", border: '1px solid #6cb4cc' }}
                            allowClear
                            size="large"
                            bordered={true}
                            value={this.state.pre_data.domain_skills}
                            // defaultValue={this.state.pre_data.domain_skills}
                            dropdownStyle={{
                              borderRadius: "15px",
                              border: "1px solid #7aacd6",
                              paddingTop: "0px",
                              paddingBottom: "0px",
                            }}
                            showArrow
                            onSelect={(value) => {
                              console.log("val", value);
                              if (value == "Other") {
                                this.setState({ domainSkillVisible: true });
                              }
                            }}
                            onDeselect={(val) => {
                              console.log("diselect", val);
                              if (val == "Other") {
                                this.setState({
                                  domainSkillVisible: false,
                                });
                              }
                            }}
                            onClear={() => {
                              console.log("clear");
                              this.setState({
                                domainSkillVisible: false,
                              });
                            }}
                            maxTagCount={"responsive"}
                          >
                            {/* {console.log(this.state.children, "CHild1")} */}
                            {this.state.children}
                          </Select>
                        ) : (
                          <Select
                            mode="multiple"
                            className={style.input_fields_select}
                            placeholder="Domain skills"
                            style={{ padding: "0 !important", border: '1px solid #6cb4cc' }}
                            allowClear
                            size="large"
                            bordered={true}
                            dropdownStyle={{
                              borderRadius: "15px",
                              border: "1px solid #7aacd6",
                              paddingTop: "0px",
                              paddingBottom: "0px",
                            }}
                            showArrow
                            onSelect={(value) => {
                              console.log("val", value);
                              if (value == "Other") {
                                this.setState({ domainSkillVisible: true });
                              }
                            }}
                            onDeselect={(val) => {
                              console.log("diselect", val);
                              if (val == "Other") {
                                this.setState({
                                  domainSkillVisible: false,
                                });
                              }
                            }}
                            onClear={() => {
                              console.log("clear");
                              this.setState({
                                domainSkillVisible: false,
                              });
                            }}
                            maxTagCount={"responsive"}
                          >
                            {/* {console.log(this.state.children, "CHild1")} */}
                            {this.state.children}
                          </Select>
                        )}
                      </Form.Item>
                    </Col>
                    <Col span={12} xs={24} sm={12} md={12} lg={12} xl={12} xxl={12} >

                      {this.state.personalSkillVisible && (
                        <Form.Item
                        // name="other_personal_skills"
                        // rules={[
                        //   { required: true, message: "Required field" },
                        //   {
                        //     min: 1,
                        //     max: 20,
                        //     message: "Max length should be 20.",
                        //   },
                        //   ({ getFieldValue }) => ({
                        //     validator(_, value) {
                        //       if (
                        //         !value ||
                        //         value.match(userRegex)
                        //       ) {
                        //         return Promise.resolve();
                        //       }
                        //       return Promise.reject(
                        //         new Error("can enter only alphabets")
                        //       );
                        //     },
                        //   }),
                        // ]}
                        >
                          <Input
                            className={style.input_fields}
                            // name="other_personal_skills"
                            placeholder="Other Personal skills"
                            onChange={(e: any) => {
                              this.setState({
                                other_personal_skill: e.target.value
                              })
                            }}
                            style={{ borderWidth: 1 }}
                            autoFocus
                          />
                        </Form.Item>
                      )}
                      <Form.Item
                        rules={[
                          { required: true, message: "Required field" },
                        ]}
                        name="personal_skills"
                      >
                        {this.state.pre_data && this.state.pre_data.personal_skills ? (
                          <Select
                            mode="multiple"
                            className={style.input_fields_select}
                            placeholder="Personal skills"
                            // style={{ padding: "0 !important", border: '1px solid #6cb4cc' }}
                            // defaultValue={this.state.pre_data.personal_skills}
                            value={this.state.pre_data.personal_skills}
                            allowClear
                            showArrow
                            size="large"
                            bordered={true}
                            dropdownStyle={{
                              borderRadius: "15px",
                              border: "1px solid #7aacd6",
                              paddingTop: "0px",
                              paddingBottom: "0px",
                            }}
                            onSelect={(value) => {
                              // console.log("val", value);
                              if (value == "Other") {
                                this.setState({ personalSkillVisible: true });
                              }
                            }}
                            onDeselect={(val) => {
                              // console.log("diselect", val);
                              if (val == "Other") {
                                this.setState({
                                  personalSkillVisible: false,
                                });
                              }
                            }}
                            onClear={() => {
                              console.log("clear");
                              this.setState({
                                personalSkillVisible: false,
                              });
                            }}
                            maxTagCount={"responsive"}
                          >
                            {this.state.skills_2}
                          </Select>
                        ) : <Select
                          mode="multiple"
                          className={style.input_fields_select}
                          placeholder="Personal skills"
                          // style={{
                          //   padding: "0 !important",
                          //   border: '1px solid #6cb4cc'
                          // }}
                          allowClear
                          size="large"
                          showArrow
                          bordered={true}
                          dropdownStyle={{
                            borderRadius: "15px",
                            border: "1px solid #7aacd6",
                            paddingTop: "0px",
                            paddingBottom: "0px",
                          }}
                          onSelect={(value) => {
                            // console.log("val", value);
                            if (value == "Other") {
                              this.setState({ personalSkillVisible: true });
                            }
                          }}
                          onDeselect={(val) => {
                            // console.log("diselect", val);
                            if (val == "Other") {
                              this.setState({
                                personalSkillVisible: false,
                              });
                            }
                          }}
                          onClear={() => {
                            console.log("clear");
                            this.setState({
                              personalSkillVisible: false,
                            });
                          }}
                          maxTagCount={"responsive"}
                        >
                          {this.state.skills_2}
                        </Select>}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row
                    gutter={15}
                  >
                    <Col span={12} xs={24} sm={12} md={12} lg={12} xl={12} xxl={12} >
                      <Form.Item
                        rules={[
                          { required: true, message: "Date can't be in under the past 18 years" },

                        ]}
                        name="date_of_birth"
                      >
                        {this.state.updateProfile?.date_of_birth ? (
                          <DatePicker
                            name="date_of_birth"
                            placeholder={"Date of Birth"}
                            className={style.input_fields}
                            defaultValue={moment(new Date(this.state.updateProfile.date_of_birth))}
                            format="DD-MM-YYYY"
                            disabledDate={(current: any) => {
                              console.log(current, moment().add(0, "days"),)
                              var a = moment().add(-100, 'years')
                              var b = moment().add(-18, 'years')

                              return a.diff(current) <= 0 && b.diff(current) >= 0 ? false : true

                              // moment().add(0, "days") <= current ||
                              //   current >= moment().add(-100, 'years') &&
                              //   moment().add(-18, 'years') <= current
                            }}
                          />
                        ) : (
                          <DatePicker
                            name="date_of_birth"
                            placeholder={"Date of Birth"}
                            className={style.input_fields}
                            format="DD-MM-YYYY"
                            disabledDate={(current: any) => {
                              var a = moment().add(-100, 'years')
                              var b = moment().add(-18, 'years')

                              return a.diff(current) <= 0 && b.diff(current) >= 0 ? false : true

                              // moment().add(0, "days") <= current ||
                              //   current >= moment().add(-100, 'years') &&
                              //   moment().add(-18, 'years') <= current
                            }}
                          />
                        )}
                      </Form.Item>
                    </Col>
                    <Col span={12} xs={24} sm={12} md={12} lg={12} xl={12} xxl={12} ></Col>
                  </Row>

                </Card>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "15px",
                    background: "#f8f8f8",
                  }}
                >
                  <Button
                    className={style.btn_colors}
                    type="primary"
                    htmlType="submit"
                    shape="round"
                    size={"large"}
                  >
                    Next
                  </Button>
                </div>
              </Form>
            }
          </Col>
          <Col span={6} xs={2} sm={2} md={3} lg={3} xl={6} xxl={6} />
        </Row>
        <AlertMessage
          isModalVisible={this.state.isErrorModalShow}
          title={this.state.errorContent.title}
          body={this.state.errorContent.body}
          isError={this.state.errorContent.isError}
          onOk={() =>
            this.setState({ isErrorModalShow: false, errorContent: {} })
          }
        />
      </div>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End