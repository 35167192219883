import React, { useEffect, useState } from "react"
import { Redirect } from "react-router-dom";
import moment from "moment";
import "./../userFeed.css";
import { editer } from "../assets";
import {
  Card,
  Row,
  Col,
  List,
  Input,
  Typography,
  Avatar,
  Button,
  Upload,
  Image,
  Badge,
  Radio,
  Tabs,
  Space,
  Modal, Form, Checkbox, Empty, message, Spin
} from "antd";
import axios from "axios";
// @ts-ignore
import { baseURL } from '../../../../framework/src/config.js';
import SliderView from "./SliderView.web";
import ProfileRatingAppliedProgressBar from "./profileRatingAplliedProgressBar.web";
import { useLocation } from "react-router-dom"

export const configJSON = require("./../config");

const { Title, Text } = Typography;

const RatingSentRecieved: React.FC = () => {

  const [redirect, setRedirect] = useState(false)
  const [rating, setRating] = useState<any>(null)
  const [Received, setReceived] = useState<any>(null)
  const [ratingReceived, setRatingReceived] = useState<any>(null)
  const [userReceivedRating, setUserReceivedRating] = useState<any>(null)
  const [userRating, setUserRating] = useState<any>()
  const [editRating, setEditRating] = useState<any>(null)
  const [editUserRating, setEditUserRating] = useState<any>(null)
  const [openEditRating, setOpenEditRating] = useState<boolean>(false)
  const [openEditUserRating, setOpenEditUserRating] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const location: any = useLocation().state

  const getUserAuthentication = async () => {
    let token = await localStorage.getItem("token");
    if (token && token !== null && token !== undefined) {
      setRedirect(false)
    } else {
      setRedirect(true)
    }
  }
  const redirectUser = () => {
    if (redirect) {
      return <Redirect to="/" />;
    }
  }

  useEffect(() => {
    console.log(location, "dfad")
    getUserAuthentication()
    if (location.isSentRating) {
      getRatingSendToUser()
    } else {
      setReceived(location?.ratings)
      ratingReceivedObjects(location?.ratings)
    }
  }, [])

  const ratingReceivedObjects = (receivedObject: any) => {
    let first_rating = {}
    let second_rating = {}
    console.log(location.rating?.data?.attributes?.rating, "ratingReceivedObjects")
    Object.keys(receivedObject).map((rateObj: any, index: number) => {
      console.log(rateObj)
      if (rateObj === "General Personal Rating" || rateObj === "General Business Rating") {
        const getFirstRatingItems = Object.keys(receivedObject[rateObj]).map((item: any) => ({ value: receivedObject[rateObj][item] ? Number(receivedObject[rateObj][item]) : 0, name: item }));
        first_rating = { name: rateObj, array: getFirstRatingItems }
      }
      else {
        const getFirstRatingItems = Object.keys(receivedObject[rateObj]).map((item: any) => ({ value: receivedObject[rateObj][item] ? Number(receivedObject[rateObj][item]) : 0, name: item }));
        second_rating = { name: rateObj, array: getFirstRatingItems }
      }
    })
    setRatingReceived(first_rating)
    setUserReceivedRating(second_rating)
  }

  const handleClose = () => {
    setOpenEditRating(false)
    // editRatingData: null
    setOpenEditUserRating(true)
  }

  const getRatingSendToUser = async () => {
    setLoading(true)
    try {
      const response = await axios({
        url: `${baseURL}/bx_block_accountscoreranking/ratings/${location.account_id}`,
        method: 'get',
        headers: {
          'token': localStorage.getItem("token"),
          'Content-Type': 'application/json'
        },

      })
      if (response && response?.data) {
        console.log(response.data.data, "asdkjasd")
        setRating(response.data.data)
        ratingUserFetch(response.data.data)
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      console.error(error);
    }
  }

  const ratingUserFetch = (rateingObject: any) => {
    console.log(rateingObject)
    let rateName = location?.rate_as;
    var second_rating = {}
    var first_rating = {}
    var account_id = rateingObject?.attributes?.account_id
    // console.log(location.rating?.data?.attributes?.rating, "ratingReceivedObjects")
    // console.log(account_id,"asdkjal als")
    // console.log(a,"sdfgklsd")
    const ratingArray = rateingObject?.attributes.rating
    Object.entries(ratingArray).map((rateObj: any) => {
      // console.log(rateObj,"asdlghas dglkasjdlakgd",ratingArray)
      if (rateObj[0] === "General Personal Rating") {
        let desc = location.rating?.data?.attributes?.rating
        const getFirstRatingItems = Object.keys(rateObj[1]).map((item: any, ind: number) => ({ value: rateObj[1][item] ? Number(rateObj[1][item]) : 0, name: item, description: desc[rateObj[0]][ind].description }));
        console.log(getFirstRatingItems)
        first_rating = { name: "General Personal Rating", array: getFirstRatingItems }
      }
      if (rateObj[0] === "General Business Rating") {
        let desc = location.rating?.data?.attributes?.rating
        const getFirstRatingItems = Object.keys(rateObj[1]).map((item: any, ind: number) => ({ value: rateObj[1][item] ? Number(rateObj[1][item]) : 0, name: item, description: desc[rateObj[0]][ind].description }));
        first_rating = { name: "General Business Rating", array: getFirstRatingItems }
      }
      if (rateObj[0] === rateName) {
        let desc = location.rating?.data?.attributes?.rating
        const getSecondRatingItems = Object.keys(rateObj[1]).map((item: any, ind: number) => ({ value: rateObj[1][item] ? Number(rateObj[1][item]) : 0, name: item, description: desc[rateObj[0]][ind].description }))
        second_rating = { name: rateName, array: getSecondRatingItems }
      }
      if (rateName === "Leadership") {

        if (rateObj[0] === "Leader") {
          let desc = location.rating?.data?.attributes?.rating
          const getSecondRatingItems = Object.keys(rateObj[1]).map((item: any, ind: number) => ({ value: rateObj[1][item] ? Number(rateObj[1][item]) : 0, name: item, description: desc[rateObj[0]][ind].description }))
          second_rating = { name: "Leader", array: getSecondRatingItems }
        }
      }
      if (rateName === "Community") {
        if (rateObj[0] === "Personal Community") {
          let desc = location.rating?.data?.attributes?.rating
          const getSecondRatingItems = Object.keys(rateObj[1]).map((item: any, ind: number) => ({ value: rateObj[1][item] ? Number(rateObj[1][item]) : 0, name: item, description: desc[rateObj[0]][ind].description }))
          second_rating = { name: "Personal Community", array: getSecondRatingItems }
        }
        if (rateObj[0] === "Business Community") {
          let desc = location.rating?.data?.attributes?.rating
          const getSecondRatingItems = Object.keys(rateObj[1]).map((item: any, ind: number) => ({ value: rateObj[1][item] ? Number(rateObj[1][item]) : 0, name: item, description: desc[rateObj[0]][ind].description }))
          second_rating = { name: "Business Community", array: getSecondRatingItems }
        }
      }
      if (rateName === "Customer") {
        if (rateObj[0] === "Business Customer") {
          let desc = location.rating?.data?.attributes?.rating
          const getSecondRatingItems = Object.keys(rateObj[1]).map((item: any, ind: number) => ({ value: rateObj[1][item] ? Number(rateObj[1][item]) : 0, name: item, description: desc[rateObj[0]][ind].description }))
          second_rating = { name: "Business Customer", array: getSecondRatingItems }
        }
        if (rateObj[0] === "Personal Customer") {
          console.log(rateObj)
          let desc = location.rating?.data?.attributes?.rating
          const getSecondRatingItems = Object.keys(rateObj[1]).map((item: any, ind: number) =>
            ({ value: rateObj[1][item] ? Number(rateObj[1][item]) : 0, name: item, description: desc[rateObj[0]][ind].description })
          )
          second_rating = { name: "Personal Customer", array: getSecondRatingItems }
        }
      }
    })
    // console.log(second_rating);
    // console.log(first_rating);
    setUserRating(second_rating)
    setEditRating(first_rating)
    setEditUserRating(second_rating)
  }

  const editRatingChange = async (obj: any) => {
    const editRatingData = { ...editRating };
    editRatingData["array"][obj.index]["value"] = obj.value
    setEditRating(editRatingData)
    // this.setState({ editRatingData: editRatingData });
  }

  const userRatingChange = async (obj: any) => {
    const userRating = { ...editUserRating };
    userRating["array"][obj.index]["value"] = obj.value;
    console.log("userRating", userRating);
    setEditUserRating(userRating)
    // this.setState({ generalRating: generalRating});
  }

  const submitRating = async () => {
    setOpenEditRating(false)
    setOpenEditUserRating(false)
    setLoading(true)
    const editRatingData = { ...editRating };
    const userRating = { ...editUserRating };
    const ratingObj = editRatingData?.array.reduce((prevObj: any, newObject: any) => {
      if (newObject.name) {
        return ({ ...prevObj, [newObject.name]: newObject.value || 0 });
      } else {
        return prevObj;
      }
    }, {});
    // console.log("ratingObj", ratingObj)

    const userRatingObj = userRating.array.reduce((prevObj: any, newObject: any) => {
      if (newObject.name) {
        return ({ ...prevObj, [newObject.name]: newObject.value || 0 });
      } else {
        return prevObj;
      }
    }, {});

    const httpBody = {
      data: {
        attributes: {
          account_id: location.account_id,
          rating: {
            [userRating.name]: userRatingObj,
            [editRatingData.name]: ratingObj
          }
        }
      }
    }
    try {
      const response = await axios({
        url: `${baseURL}/bx_block_accountscoreranking/ratings`,
        method: 'post',
        headers: {
          'token': localStorage.getItem("token"),
          'Content-Type': 'application/json'
        },
        data: JSON.stringify(httpBody)
      })
      if (response && response?.data) {
        setEditUserRating(null)
        setEditRating(null)
        getRatingSendToUser()
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      console.error(error);
    }

  }

  return (
    <>
      <div
        className="background"
        style={{
          backgroundColor: "rgb(246, 246, 246)",
        }}
      >
        {redirectUser}
        <Row
          style={{
            paddingTop: "15px", marginBottom: 7, marginLeft: 20
          }}
        >
          <Text
            className="textlabel_subheadingText"
          >
            {location.home ? null : "My Profile / "} Rating /
          </Text>
          <Text
            className="textlabel_subheadingText2"
          >
            {" "} {location.isSentRating ? 'Rating Sent' : 'Rating Received'}
          </Text>
        </Row>
        {location.isSentRating ? loading ? <Spin /> : <div>
          {rating && <Row gutter={[16, 16]} style={{ margin: "15px" }}>
            <Col
              xs={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 10 }}
              style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}
            >
              <Card
                style={{ width: "100%", height: "100%", borderRadius: "5px" }}
              >
                <div className='d-f'>
                  <div className="follow_profile">

                    <Avatar className="avatar_rating_sent_received" src={location?.photo} size={40} />

                    <div style={{ marginLeft: 20 }}>
                      {/* <Text style={{ fontSize: 15, wordBreak: 'break-word', width: '-webkit-fill-available' }}> */}
                      <span style={{
                        display: 'block',
                        fontSize: 16,
                        fontWeight: 700,
                        width: "-webkit-fill-available",
                        wordBreak: "break-word"
                      }}>
                        {location?.full_name}
                      </span>
                      <span style={{ fontWeight: 500, fontSize: "14px", color: "rgba(0, 0, 0, 0.85)" }}>
                        {
                          location?.designation?.title ?
                            <>
                              {location?.designation?.title}
                              {
                                location?.designation?.company ?
                                  <span style={{ color: "#919191" }}> at | </span> : null
                              }
                              {location?.designation?.company}
                            </>
                            : null
                        }
                      </span>
                      <span className="domain_skill">
                        {
                          location?.domain_skills ?
                            <>
                              {
                                location?.domain_skills?.map((item: any, id: number) => {
                                  if (id + 1 < location?.domain_skills.length) {
                                    return `${item}, `
                                  } else {
                                    return `${item}`
                                  }
                                })
                              }
                            </>
                            : null
                        }
                      </span>
                      {/* <span style={{}}>{location?.designation?.title} at {location?.designation?.company} | {location?.profile?.data?.attributes?.domain_skills}</span> */}
                      {/* </Text> */}
                    </div>
                  </div>
                  <div className='follow_profile_time'>
                    <span>{moment(rating.attributes.created_at).format("Do")} {moment(rating.attributes?.created_at).format("MMMM")}, {moment(rating.attributes?.created_at).format("YYYY")}</span>
                    {location?.isSentRating && <img
                      src={editer}
                      style={{ width: 18, height: 18, marginLeft: 7, marginBottom: 5 }}
                      onClick={() => {
                        setOpenEditRating(true)
                        ratingUserFetch(rating)
                        // console.log(account_id,"asdkjal als")
                        // console.log(a,"sdfgklsd")

                      }}
                    />
                    }

                  </div>

                </div>

                {rating?.attributes?.rating['General Business Rating'] ? (
                  Object.keys(rating?.attributes?.rating['General Business Rating']).map((item: any, index) => {
                    let desc = location.rating?.data?.attributes?.rating
                    return <ProfileRatingAppliedProgressBar
                      key={index}
                      description={desc['General Business Rating'][index].description}
                      title={item}
                      value={rating?.attributes?.rating['General Business Rating'][item] === null ? 0 : Math.trunc(rating?.attributes?.rating['General Business Rating'][item])}
                      color={rating?.attributes?.rating['General Business Rating'][item] <= 50 ? "#b24040" : rating?.attributes?.rating['General Business Rating'][item] <= 75 ? "#3a87a8" : "#3c9b83"}
                    />
                  }
                  )
                ) : (
                  Object.keys(rating?.attributes?.rating['General Personal Rating']).map((item: any, index) => {
                    let desc = location.rating?.data?.attributes?.rating
                    return <ProfileRatingAppliedProgressBar
                      key={index}
                      description={desc['General Personal Rating'][index].description}
                      title={item}
                      value={rating?.attributes?.rating['General Personal Rating'][item] === null ? 0 : Math.trunc(rating?.attributes?.rating['General Personal Rating'][item])}
                      color={rating?.attributes?.rating['General Personal Rating'][item] <= 50 ? "#b24040" : rating?.attributes?.rating['General Personal Rating'][item] <= 75 ? "#3a87a8" : "#3c9b83"}
                    />
                  }
                  )
                )
                }
              </Card>
            </Col>

            {userRating?.name && <Col
              xs={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 10 }}
              style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", }}
            >
              <Card
                style={{ width: "100%", height: "100%", borderRadius: "5px" }}
              >
                <div className='d-f'>
                  <div className="follow_profile">
                    <Text style={{ fontSize: 16, fontWeight: 600, paddingTop: '10px', paddingBottom: 10 }}>Rated As {location?.rate_as !== null && location?.rate_as} </Text>
                  </div>
                </div>
                {console.log(userRating, location)}
                {userRating?.name &&
                  Object.keys(rating?.attributes?.rating[`${userRating?.name}`]).map((item: any, index) => {
                    let desc = location.rating?.data?.attributes?.rating
                    // console.log(rating)
                    return <ProfileRatingAppliedProgressBar
                      key={index}
                      description={desc[userRating?.name][index].description}
                      title={item}
                      value={rating?.attributes?.rating[`${userRating?.name}`][item] === null ? 0 : Math.trunc(rating?.attributes?.rating[`${userRating?.name}`][item])}
                      color={rating?.attributes?.rating[`${userRating?.name}`][item] <= 50 ? "#b24040" : rating?.attributes?.rating[`${userRating?.name}`][item] <= 75 ? "#3a87a8" : "#3c9b83"}
                    />
                  }

                  )
                }
              </Card>
            </Col>}
          </Row>}
        </div> :
          <div>
            {ratingReceived && <Row gutter={[16, 16]} style={{ margin: "15px" }}>
              <Col
                xs={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 10 }}
                style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}
              >
                <Card
                  style={{ width: "100%", height: "100%", borderRadius: "5px" }}
                >
                  <div className='d-f'>
                    <div className="follow_profile">

                      <Avatar className="avatar_rating_sent_received" src={location?.photo} size={40} />

                      <div style={{ marginLeft: 20 }}>
                        {/* <Text style={{ fontSize: 15, wordBreak: 'break-word', width: '-webkit-fill-available' }}> */}
                        <span style={{ display: 'block', fontSize: 16, fontWeight: 700 }}>{location?.full_name}</span>
                        <span style={{ fontWeight: 500, fontSize: "14px", color: "rgba(0, 0, 0, 0.85)" }}>
                          {
                            location?.designation?.title ?
                              <>
                                {location?.designation?.title}
                                {
                                  location?.designation?.company ?
                                    <span style={{ color: "#919191" }}> at | </span> : null
                                }
                                {location?.designation?.company}
                              </>
                              : null
                          }
                        </span>
                        <span className="domain_skill">
                          {
                            location?.domain_skills ?
                              <>
                                {
                                  location?.domain_skills?.map((item: any, id: number) => {
                                    if (id + 1 < location?.domain_skills.length) {
                                      return `${item}, `
                                    } else {
                                      return `${item}`
                                    }
                                  })
                                }
                              </>
                              : null
                          }
                        </span>
                        {/* <span style={{}}>{location?.designation?.title} at {location?.designation?.company} | {location?.profile?.data?.attributes?.domain_skills}</span> */}
                        {/* </Text> */}
                      </div>
                    </div>
                    <div className='follow_profile_time'>
                      <span>{moment(location.created_at).format("Do")} {moment(location.created_at).format("MMMM")}, {moment(location.created_at).format("YYYY")}</span>
                    </div>

                  </div>
                  {ratingReceived &&
                    Object.keys(Received[ratingReceived?.name]).map((item: any, index) => {
                      let desc = location.rating?.data?.attributes?.rating
                      console.log(desc)
                      return <ProfileRatingAppliedProgressBar
                        key={index}
                        // description={desc[ratingReceived?.name][index].description}
                        title={item}
                        value={Received[ratingReceived?.name][item] === null ? 0 : Math.trunc(Received[ratingReceived?.name][item])}
                        color={Received[ratingReceived?.name][item] <= 50 ? "#b24040" : Received[ratingReceived?.name][item] <= 75 ? "#3a87a8" : "#3c9b83"}
                      />
                    }
                    )
                  }
                </Card>
              </Col>

              <Col
                xs={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 10 }}
                style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", }}
              >
                <Card
                  style={{ width: "100%", height: "100%", borderRadius: "5px" }}
                >
                  <div className='d-f'>
                    <div className="follow_profile">
                      <Text style={{ fontSize: 16, fontWeight: 600, paddingTop: '10px', paddingBottom: 10 }}>Rated As {location?.rate_as} </Text>
                    </div>
                  </div>

                  {userReceivedRating &&
                    Object.keys(Received[userReceivedRating?.name]).map((item: any, index) => {
                      let desc = location.rating?.data?.attributes?.rating
                      console.log(desc)
                      return <ProfileRatingAppliedProgressBar
                        key={index}
                        // description={desc[userReceivedRating?.name][index].description}
                        title={item}
                        value={Received[userReceivedRating?.name][item] === null ? 0 : Math.trunc(Received[userReceivedRating?.name][item])}
                        color={Received[userReceivedRating?.name][item] <= 50 ? "#b24040" : Received[userReceivedRating?.name][item] <= 75 ? "#3a87a8" : "#3c9b83"}
                      />
                    }
                    )
                  }
                </Card>
              </Col>
            </Row>}
          </div>
        }

      </div>
      {openEditRating && <Modal
        footer={null}
        centered
        bodyStyle={{
          width: "100%",
          height: "80vh",
          borderRadius: "5px",
          overflowY: "auto",
          overflowX: 'hidden'
        }}
        title={
          <Space
            style={{
              width: "100%",
              cursor: "move",
              fontWeight: "bold",
              color: "#414141"
            }}
          >
            Edit Rating
          </Space>
        }
        visible={openEditRating}
        onCancel={() => {
          setOpenEditRating(false)
          setEditRating(null)
          setEditUserRating(null)
          setOpenEditUserRating(false)
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Title
            style={{
              color: "#414141",
              marginBottom: "1rem"
            }}
            level={5}
          >
            Applied View Rating
          </Title>
          {
            editRating
              ?.array?.map((item: any, index: any) => item.name && <Space
                direction="vertical"
                key={item.name}
              >
                <SliderView
                  name={item.name}
                  description={item.description}
                  //  changeHandler={async(value:any)=>{
                  //      const m= {...this.state.editRatingData.array}     
                  //       m[index].value = value
                  //       console.log(m)
                  //     await this.setState({
                  //       editRatingData : m 
                  //     })
                  //  }}
                  changeHandler={(val: any) => editRatingChange({ name: item.name, value: val, index: index })}
                  v={item.value}
                />
              </Space>)
          }
        </div>
        <Button
          style={{
            width: "100%",
            height: "2.5rem",
            borderRadius: "25px",
            backgroundColor: "#006285",
            color: "#ffffff",
            marginTop: "1rem"
          }}
          onClick={() => handleClose()}
        >
          Save
        </Button>
      </Modal>}
      {openEditUserRating && <Modal
        footer={null}
        centered
        bodyStyle={{
          width: "100%",
          height: "80vh",
          borderRadius: "5px",
          overflowY: "auto",
          overflowX: 'hidden'
        }}
        title={
          <Space
            style={{
              width: "100%",
              cursor: "move",
              fontWeight: "bold",
              color: "rgba(0, 0, 0, 0.85)"
            }}
          >
            Edit Rating
          </Space>
        }
        visible={openEditUserRating}
        onCancel={() => setOpenEditUserRating(false)}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column"
          }}
        >
          <Title
            style={{
              color: "rgba(0, 0, 0, 0.85)",
              marginBottom: "1rem"
            }}
            level={5}
          >
            Rate as {editUserRating?.name}
          </Title>
          {

            editUserRating?.array?.map((item: any, index: number) => item.name && <Space
              direction="vertical"
              key={item.name}
            >
              <SliderView
                name={item.name}
                description={item.description}
                //   changeHandler={(value:any)=>{
                //     const m= this.state.userRating.array     
                //      m[index].value = value
                //    this.setState({
                //     userRating : m 
                //    })
                // }}
                changeHandler={(val: any) => userRatingChange({ name: item.name, value: val, index: index })}
                v={item.value}
              />
            </Space>)
          }
        </div>
        <div
          style={{
            width: "100%",
            marginTop: "1rem",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between"
          }}
        >
          <Button
            type="default"
            style={{
              width: "45%",
              height: "2.5rem",
              borderRadius: "25px",
              border: "1px solid #4080c0",
              color: "#006285"
            }}
            onClick={() => {
              setOpenEditUserRating(false)
              setOpenEditRating(true)
            }}
          >
            Back
          </Button>
          <Button
            type="primary"
            style={{
              width: "45%",
              height: "2.5rem",
              borderRadius: "25px",
              backgroundColor: "#006285",
              color: "#ffffff"
            }}
            onClick={submitRating}
          >
            Submit
          </Button>
        </div>
      </Modal>}

    </>
  )
}

export default RatingSentRecieved
